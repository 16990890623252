import { ErrorTypes } from '../components/Outcome/ErrorOutcome';

export enum PageName {
  ADDITIONAL_DETAILS = 'Additional Details',
  ACP_FLOW_PAGE = 'ACP Flow Page',
}

export enum ButtonName {
  CANCEL = 'Cancel',
  CONTINUE = 'I have an ACP Application ID',
  GET_VERIFIED = 'I need to qualify',
  BACK = 'Back to My Viasat overview',
}

export enum BenefitAnswer {
  TRANSFER_YES = 'Yes, I am and I would like to transfer my ACP discount to my Viasat account',
  TRANSFER_NO = 'No, I am not using my ACP discount with another provider',
  TRIBAL_YES = 'Yes, I have qualifed for the ACP Enhanced Tribal Benefit',
  TRIBAL_NO = 'No, I am not eligible/have not qualified for the ACP Enhanced Tribal Benefit',
}

export enum BenefitQuestion {
  TRANSFER = 'Are you receiving the ACP monthly discount through another provider?',
  TRIBAL = 'Have you qualified for the ACP Enhanced Tribal Benefit?',
}

export enum Benefits {
  BOTH = 'Another Provider - Yes, transfer;Tribal Benefit - Yes, qualified',
  TRANSFER = 'Another Provider - Yes, transfer;Tribal Benefit - No, not qualified',
  TRIBAL = 'Another Provider - No, not using;Tribal Benefit - Yes, qualified',
  NONE = 'Another Provider - No, not using;Tribal Benefit - No, not qualified',
}

const ERROR_MESSAGE = {
  [ErrorTypes.SERVICE_UNAVAILABLE]: 'Service not available',
  [ErrorTypes.NV_UNAVAILABLE]: 'Service not available',
  [ErrorTypes.VIASAT_SERVICE_UNAVAILABLE]: 'Service not available',
  [ErrorTypes.APPLICATION_NOT_FOUND]: 'Application not found',
  [ErrorTypes.APPLICATION_EXPIRED]: 'Application expired',
  [ErrorTypes.DUPLICATE_SUBSCRIBER_NLAD]: 'Benefit already in use',
  [ErrorTypes.NOT_ELIGIBLE_ACP]: 'Not eligible',
  [ErrorTypes.APPLICATION_NOT_COMPLETE]: 'Application is not complete',
  [ErrorTypes.APPLICATION_PENDING]: 'Application pending',
};
