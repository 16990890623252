import { Platform } from 'react-native';
import {
  BillingEventHandlerMethods,
  BillingEventTypes,
  GeneralEventTypes,
  MicroFrontendDomains,
  TokenInfo,
  WindowMessageData,
} from '../../services/microFrontend';
import { externalLink, fetchInvoice } from '../../utils';
import { HelpLink, PayBillLink, StackLink } from '../../utils/Navigation';

const handleAutoPayNavigation = (
  goTo: any,
  changePayment: boolean,
  isMobile?: boolean
): void => {
  if (!window?.location?.pathname?.includes(PayBillLink.PayBill))
    goTo(PayBillLink.PayBill);
  if (isMobile) goTo(PayBillLink.AutoPay, { params: { changePayment } });
  else {
    goTo(PayBillLink.AutoPay, {
      drawer: Platform.OS === 'web',
      append: Platform.OS === 'web',
      params: { changePayment },
    });
  }
};

const handleOneTimePaymentNavigation = (
  goTo: any,
  method: any,
  isCardAvailable: boolean,
  isCardExpired: boolean
): void => {
  const existingMethod = isCardAvailable && !isCardExpired && method !== null;
  if (!window?.location?.pathname?.includes(PayBillLink.PayBill))
    goTo(PayBillLink.PayBill);

  goTo(PayBillLink.OneTimePayment, {
    drawer: Platform.OS === 'web',
    append: Platform.OS === 'web',
    params: { existingMethod },
  });
};

export const handleChangeBillDateNavigation = (goTo: any): void => {
  if (!window?.location?.pathname?.includes(PayBillLink.PayBill))
    goTo(PayBillLink.PayBill);
};

export const handleViewStatement = (
  data: { url?: string | null; invoiceNumber?: string | null },
  token: TokenInfo | null | undefined,
  onCompleted: (invoiceNumber?: string | null, popupMessage?: boolean) => void
) => {
  fetchInvoice(
    data,
    { token: token?.accessToken, tokenType: token?.type },
    (popupMessage) => onCompleted(data.invoiceNumber, popupMessage)
  );
};

export interface BillingContext {
  next_bill_period_start_date: string;
  bill_cycle_day: number;
  payment_due_date: string;
  autopay_method: any;
  last_payment_made_date: string;
  credit_class: string;
}

export const billingMicroFrontendMessageHandler = ({
  logout,
  goTo,
  token,
  maximizeWidget,
  statementLinkEndLoading,
  setBillingContext,
  copyText,
}: BillingEventHandlerMethods): any => {
  const handleInvalidToken = (data: WindowMessageData): void => {
    const message = data?.data?.message || 'Invalid User Token';
    console.error(message);
    logout();
  };

  const handleMessage = (data: WindowMessageData): void => {
    const messageData = data.data;
    if (data.domain === MicroFrontendDomains.General)
      switch (data.eventType as GeneralEventTypes) {
        case GeneralEventTypes.Profile:
          goTo(StackLink.Profile, { drawer: true });
          break;
        case GeneralEventTypes.CopyText:
          copyText?.(messageData);
          break;
      }
    if (data.domain === MicroFrontendDomains.Billing) {
      switch (data.eventType as BillingEventTypes) {
        case BillingEventTypes.ChatWithUs:
          if (Platform.OS === 'web' && maximizeWidget) {
            maximizeWidget();
          } else {
            goTo(StackLink.LiveChat);
          }
          break;
        case BillingEventTypes.FAQSpecificQuestion:
          if (messageData) {
            goTo(HelpLink.Main, {
              params: {
                section: messageData.section,
                question: messageData.question,
              },
            });
          }
          break;
        case BillingEventTypes.InvalidToken:
          handleInvalidToken(data);
          break;
        // TODO: need additional inputs for navs
        case BillingEventTypes.Autopay:
          handleAutoPayNavigation(goTo, true);
          break;
        case BillingEventTypes.PayBill:
          goTo(PayBillLink.PayBill);
          break;
        case BillingEventTypes.OneTimePayment:
          handleOneTimePaymentNavigation(goTo, true, true, false);
          break;
        case BillingEventTypes.ChangeBillDate:
          handleChangeBillDateNavigation(goTo);
          break;
        case BillingEventTypes.ViewStatement:
          if (messageData) {
            if (Platform.OS === 'web') {
              externalLink(
                `${window.location.origin}/${PayBillLink.Invoice}/${messageData.url}`
              );
              break;
            }
            handleViewStatement(messageData, token, statementLinkEndLoading);
          }
          break;
        case BillingEventTypes.BillingContext:
          if (messageData) {
            setBillingContext(messageData.billingContext);
          }
          break;
        case BillingEventTypes.Loaded:
          break;
        case BillingEventTypes.OpenInNewTab:
          externalLink(messageData.url);
          break;
      }
    }
  };

  return handleMessage;
};
