import React from 'react';
import { Button } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { useToken } from '@mfe/legacy/mv/views/Auth';
import styles from './mobile-bottom-nav.module.scss';

const MobileBottomNav = () => {
  const { t } = useTranslation('Nav');
  const { logout } = useToken();

  return (
    <div className={styles.buttonCenter}>
      <Button data-cy="mobileSignOut" onClick={logout} variant="secondary">
        {t('SignOut')}
      </Button>
    </div>
  );
};

export default MobileBottomNav;
