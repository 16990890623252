import { postProfileMessage } from '@mfe/to-be-migrated/redux/utils';
import { AuthEventTypes } from '@mfe/services/window-messages';
import { Button, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './SignoutButton.module.scss';
import {
  AnalyticsAction,
  Categories,
  structuredEvent,
} from '@mfe/to-be-migrated/redux/analytics';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { Issuer, selectConfig } from '@mfe/shared/redux/config';
import {
  getDisplayContext,
  getProfileContext,
} from '../../../../analytics-utils';

const SignoutButton = (): JSX.Element => {
  const { t } = useTranslation('NewProfile');
  const dispatch = useDispatch();
  const {
    user: {
      auth: {
        tokenInfo: { type: tokenType },
      },
    },
    loading,
  } = useSelector(selectUser);
  const { issuer } = useSelector(selectConfig);

  const isSalesforce = issuer === Issuer.Salesforce;

  const onClick = () => {
    dispatch(
      structuredEvent({
        category: Categories.Profile,
        action: AnalyticsAction.BUTTON_CLICKED,
        params: {
          property: 'signout',
          context: [
            getDisplayContext(tokenType),
            getProfileContext('NA', undefined),
          ],
        },
      })
    );
    dispatch(postProfileMessage({ eventType: AuthEventTypes.Logout }));
  };
  return (
    <Button
      variant="secondary"
      theme="light"
      buttonSize="medium"
      onClick={onClick}
      className={styles['signoutButton']}
      disabled={isSalesforce || loading}
    >
      <Txt
        variant="labelMedium"
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {t('signout')}
      </Txt>
    </Button>
  );
};

export default SignoutButton;
