import { Dispatch, SetStateAction } from 'react';

export type StepInstanceProps = {
  isOpen: boolean;
  openStep: number;
  setOpenStep: Dispatch<SetStateAction<number>>;
};

export enum CountryCode {
  US = 'US',
}
