import { Txt, Button, Surface } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { postProfileMessage } from '@mfe/to-be-migrated/redux/utils';
import { EventTypes } from '@mfe/services/window-messages';
import StorageManager from '@mfe/legacy/mv/services/localStorage';

import styles from './styles.module.scss';
import { sendMessageToMobileApp } from '@mfe/shared/cross-platform-events';
import { PushNotificationImage } from './PushNotificationImage';

export const FIRST_TIME_PUSH = 'FIRST_TIME_PUSH';
export const PUSH_ENABLED = 'PUSH_ENABLED';

const AcceptPush = ({ onAccept }: { onAccept?: () => void }): JSX.Element => {
  const dispatch = useDispatch();
  const {
    user: { accountNumber },
  } = useSelector(selectUser);
  const { t } = useTranslation('AcceptPush');

  const requestPermission = () => {
    dispatch(
      postProfileMessage({
        eventType: EventTypes.PushNotification,
        data: true,
      })
    );
    dispatch(
      sendMessageToMobileApp({
        type: 'set-push-notification-status',
        data: { shouldSubscribeToPushNotifications: true },
      })
    );
  };

  const onButtonClick = () => {
    StorageManager.set(`${FIRST_TIME_PUSH}${accountNumber}`, '1', {
      secure: true,
      expiresInDays: 999,
    });
    onAccept && onAccept();
  };

  return (
    <Surface className={styles['main']} data-cy="pushNotification">
      <Surface className={styles['contentWrapper']}>
        <Surface className={styles['imageWrapper']}>
          <PushNotificationImage />
        </Surface>
        <Surface>
          <Txt
            variant="heading4"
            style={{ textAlign: 'center', marginBottom: 16 }}
          >
            {t('GetNotifications')}
          </Txt>
          <Txt variant="bodyLargeRegular" style={{ textAlign: 'center' }}>
            {t('NotificationInformation')}
          </Txt>
        </Surface>
      </Surface>
      <Surface className={styles['buttonWrapper']}>
        <Button
          onClick={(): void => {
            requestPermission();
            onButtonClick();
          }}
          style={{ marginBottom: 12 }}
        >
          {t('AllowNotifications')}
        </Button>
        <Button
          data-cy="rejectPush"
          variant="secondary"
          onClick={(e): void => {
            onButtonClick();
          }}
        >
          {t('DeclineNotifications')}
        </Button>
      </Surface>
    </Surface>
  );
};

export default AcceptPush;
