import React, { useRef, useState } from 'react';
import {
  NativeSyntheticEvent,
  Platform,
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputFocusEventData,
  TextInputProps,
  TextStyle,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Text } from '../Text';
import { Color, colorStepUp } from '../../utils';
import { TextInputMask } from 'react-native-masked-text';
import NumberFormat from 'react-number-format';
import { PasswordIcon, PasswordMustMatch, PasswordText } from './Icons';
import {
  checkAndSetPhone,
  getDateFormatMask,
  getDateFormatOptions,
  validateDate,
} from './utils';
import { isDefined } from '../../utils';
import { DateFormat } from './types';
export * from './types';

// this is used for both the padding inside the view (around the input field) and the hitSlop so mobile users
// can click outside of the input field but inside the view.
const viewPadding = (showTitle: boolean) => ({
  top: showTitle ? 11 : 21,
  bottom: showTitle ? 6 : 10,
  left: 14,
  right: 18,
});

const styles = (color: Color, showTitle: boolean) =>
  StyleSheet.create({
    activeText: {
      color: colorStepUp(color, 100),
      marginBottom: -4,
    },
    checkIcon: {
      padding: 4,
      paddingLeft: 12,
      paddingRight: 7,
    },
    editField: {
      backgroundColor: Color.white,
      borderColor: Color.gray400,
      borderWidth: 1,
    },
    editFieldBorderless: {
      backgroundColor: Color.white,
    },
    hintText: {
      color: Color.red400,
      marginTop: 6,
      marginLeft: 10,
    },
    numberFormat: {
      fontStyle: 'italic',
      fontSize: 16,
    },
    readOnly: {
      backgroundColor: Color.gray100,
      borderColor: Color.gray400,
      borderWidth: 1,
    },
    row: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    sharedEditField: {
      paddingTop: viewPadding(showTitle).top,
      paddingBottom: viewPadding(showTitle).bottom,
      paddingLeft: viewPadding(showTitle).left,
      paddingRight: viewPadding(showTitle).right,
      borderRadius: 6,
      minHeight: 54,
      justifyContent: 'center',
      borderColor: color,
    },
    standardText: {
      color: Color.gray500,
      marginBottom: -4,
    },

    // default (no content)
    textInputNoContent: {
      fontFamily: 'SourceSansPro-Light',
      fontSize: 14,
    },
    textInputNoContentItalic: {
      fontFamily: 'SourceSansPro-Light',
      fontStyle: 'italic',
      fontSize: 14,
    },
    // default (content)
    textTitleEdit: {
      color: Color.gray800,
      fontFamily: 'SourceSansPro-Light',
      fontSize: 12,
    },
    textInputEdit: {
      fontFamily: 'SourceSansPro-SemiBold',
      fontSize: 16,
    },
    // focused
    selectedEditField: {
      backgroundColor: Color.nonStandardTeal,
      borderWidth: 1,
    },
    // error
    errorField: {
      backgroundColor: '#FEFAFA',
      borderColor: Color.red400,
      borderWidth: 1,
    },
    errorText: {
      color: Color.red400,
      marginBottom: -4,
    },
  });

export interface PasswordInput {
  exists?: boolean;
  uppercase?: boolean;
  lowercase?: boolean;
  charLength?: boolean;
  number?: boolean;
  mustMatch?: boolean;
}
type RNTextProps = Omit<
  TextInputProps,
  | 'testID'
  | 'value'
  | 'onPress'
  | 'onChange'
  | 'onSubmitEditing'
  | 'onFocus'
  | 'style'
>;
interface TextInputFieldProps extends RNTextProps {
  title?: string;
  focusedPlaceholder?: string;
  content?: string;
  country?: string;
  testID?: string;
  value?: string;
  icon?: boolean;
  onPress?(e: any): void;
  onChange?(e: any): void;
  overrideOnChange?(e: any): void;
  onHideText?(): void;
  onBlur?(): void;
  onFocus?(): void;
  onSubmitEditing?(e: any): void;
  hintText?: string;
  error?: boolean;
  errorFieldHighlight?: boolean;
  style?: StyleProp<ViewStyle>;
  readOnly?: boolean;
  password?: PasswordInput;
  dateType?: boolean;
  requiredText?: string;
  phoneType?: boolean;
  color?: Color;
  borderless?: boolean;
  obscuredText?: boolean;
  format?: string;
  dateFormat?: DateFormat;
  yearTranslation?: string;
}

const isWeb = Platform.OS === 'web';

export const TextInputField = ({
  title,
  focusedPlaceholder,
  content,
  country = 'US',
  value,
  icon = false,
  testID,
  onPress,
  onChange,
  overrideOnChange,
  onHideText,
  onBlur,
  onFocus,
  onSubmitEditing,
  hintText,
  error,
  errorFieldHighlight = false,
  style,
  readOnly,
  password = { exists: false },
  dateType,
  requiredText,
  phoneType,
  color = Color.teal500,
  borderless = false,
  obscuredText,
  format,
  dateFormat = DateFormat.MDY,
  yearTranslation,
  ...otherProps
}: TextInputFieldProps): JSX.Element => {
  const [contentChange, setContentChange] = useState<boolean>(false);
  const [secureTextEntry, setSecureTextEntry] = useState<boolean>(
    !!password.exists
  );
  const [validDate, setValidDate] = useState<boolean>(true);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [contentFocused, setContentFocused] = useState<boolean>(false);

  const textInputRef = useRef<TextInput>(null);

  const handleBlurAndFocus = (
    focus: boolean,
    event?: NativeSyntheticEvent<TextInputFocusEventData>
  ): void => {
    if (focus && event) {
      const content = event.nativeEvent.text;
      let length: number;
      if (content === '' || content === undefined) {
        length = 0;
      } else {
        length = content.length;
      }
      setContentChange(length !== 0);
    }
    setContentFocused(focus);
    if (!focus && onBlur) {
      if (phoneType) {
        try {
          const phoneNumber = parsePhoneNumber(value!, country as any);
          isDefined(onChange) && onChange(phoneNumber.formatInternational());
        } catch (err) {
          console.log(err);
        }
      }
      onBlur();
    }
  };

  const handleTouchablePress = (): void => {
    if (textInputRef !== null && textInputRef.current !== null) {
      textInputRef.current.focus();
    }
    if (dateType) {
      setContentFocused(true);
    }
  };

  const checkIfEmpty = (text: string): void => {
    const contentIsEmpty = text.length === 0;
    setIsEmpty(contentIsEmpty);
    setContentChange(!contentIsEmpty);
  };

  const noContentFocusedInput = !content && contentFocused;
  // show title if content exists, or if no content but user presses input to start typing
  const showTitle = Boolean(content) || noContentFocusedInput || contentChange;

  let fieldStyle: ViewStyle = borderless
    ? styles(color, showTitle).editFieldBorderless
    : styles(color, showTitle).editField;
  if (contentFocused || errorFieldHighlight) {
    fieldStyle =
      error || !validDate
        ? styles(color, showTitle).errorField
        : styles(color, showTitle).selectedEditField;
  }

  let textStyle: TextStyle = styles(color, showTitle).standardText;
  if (contentFocused || errorFieldHighlight) {
    textStyle =
      error || !validDate
        ? styles(color, showTitle).errorText
        : styles(color, showTitle).activeText;
  }

  const textTitleEdit = styles(color, showTitle).textTitleEdit;
  const textInputEdit = styles(color, showTitle).textInputEdit;
  const inputStyle = showTitle ? textInputEdit : [textInputEdit, textTitleEdit];
  const inputNoContentStyle: TextStyle = contentFocused
    ? styles(color, showTitle).textInputNoContentItalic
    : styles(color, showTitle).textInputNoContent;

  // check for content on mount
  React.useEffect(() => {
    if (value && value.length > 0) setContentChange(true);
  }, []);

  const placeholder = value ? undefined : focusedPlaceholder;

  return (
    <>
      <View
        testID={testID}
        style={[
          styles(color, showTitle).sharedEditField,
          readOnly ? styles(color, showTitle).readOnly : fieldStyle,
          {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
        ]}
      >
        <View style={{ flex: 1 }}>
          {showTitle && (
            <Text sourceSans light tiny fixed style={textStyle}>
              {title}
            </Text>
          )}
          <TouchableWithoutFeedback
            style={[style]}
            hitSlop={{
              top: viewPadding(showTitle).top,
              bottom: viewPadding(showTitle).bottom,
              left: viewPadding(showTitle).left,
              right: viewPadding(showTitle).right,
            }}
          >
            <>
              {dateType &&
                (isWeb ? (
                  <NumberFormat
                    testID="web-date-input"
                    aria-label={`${testID}-TextInput`}
                    format="##/##/####"
                    onValueChange={({ formattedValue }) =>
                      onChange && onChange(formattedValue)
                    }
                    value={value}
                    placeholder={
                      contentFocused || showTitle ? placeholder : title
                    }
                    mask={getDateFormatMask(dateFormat, yearTranslation)}
                    placeholderTextColor={Color.gray800}
                    customInput={TextInput}
                    style={
                      (content || contentChange
                        ? inputStyle
                        : inputNoContentStyle) as unknown as StyleProp<TextStyle>
                    }
                    onChange={(event: any): void => {
                      setValidDate(validateDate(event.target.value));
                      checkIfEmpty(event.target.value);
                    }}
                    onFocus={() => !readOnly && handleBlurAndFocus(true)}
                    onBlur={() => !readOnly && handleBlurAndFocus(false)}
                  />
                ) : (
                  <TextInputMask
                    type={'datetime'}
                    aria-label={`${testID}-TextInput`}
                    options={{
                      format: getDateFormatOptions(dateFormat, yearTranslation),
                    }}
                    value={value}
                    onChangeText={(value) => onChange && onChange(value)}
                    placeholder={title}
                    placeholderTextColor={Color.gray800}
                    onFocus={handleTouchablePress}
                    onBlur={() => setContentFocused(false)}
                  />
                ))}

              {format &&
                (isWeb ? (
                  <NumberFormat
                    testID="web-format-input"
                    aria-label={`${testID}-TextInput`}
                    format={format}
                    onValueChange={({ formattedValue }): void => {
                      if (onChange) onChange(formattedValue);
                    }}
                    value={value}
                    placeholder={
                      contentFocused || showTitle ? placeholder : title
                    }
                    mask={'x'}
                    placeholderTextColor={Color.gray500}
                    customInput={TextInput}
                    style={
                      (content || contentChange
                        ? inputStyle
                        : inputNoContentStyle) as unknown as StyleProp<TextStyle>
                    }
                    onChange={(event: any): void => {
                      if (overrideOnChange) {
                        checkIfEmpty(event.target.value);
                        overrideOnChange(event);
                      }
                    }}
                    onFocus={(): void => {
                      !readOnly && handleBlurAndFocus(true);
                    }}
                    onBlur={(): void => {
                      !readOnly && handleBlurAndFocus(false);
                    }}
                  />
                ) : (
                  <TextInputMask
                    type={'custom'}
                    aria-label={`${testID}-TextInput`}
                    options={{ format: format }}
                    value={value}
                    onChange={(event): void => {
                      if (overrideOnChange) {
                        checkIfEmpty(event.nativeEvent.text);
                        overrideOnChange(event);
                      }
                    }}
                    placeholder={title}
                    placeholderTextColor={Color.gray500}
                    onFocus={handleTouchablePress}
                    onBlur={(): void => setContentFocused(false)}
                  />
                ))}

              {phoneType && (
                <TextInput
                  ref={textInputRef}
                  placeholderTextColor={Color.gray800}
                  placeholder={
                    contentFocused || showTitle ? placeholder : title
                  }
                  style={
                    content || contentChange ? inputStyle : inputNoContentStyle
                  }
                  onChangeText={(text) => {
                    checkIfEmpty(text);
                    if (onChange)
                      checkAndSetPhone(value, onChange, text, country);
                  }}
                  onChange={(evt) => overrideOnChange && overrideOnChange(evt)}
                  textContentType="telephoneNumber"
                  aria-label={`${testID}-TextInput`}
                  value={value}
                  onFocus={(event) =>
                    !readOnly && handleBlurAndFocus(true, event)
                  }
                  onBlur={() => !readOnly && handleBlurAndFocus(false)}
                  editable={!readOnly}
                  onSubmitEditing={onSubmitEditing}
                />
              )}

              {!dateType && !phoneType && obscuredText && !format && (
                <TextInput
                  ref={textInputRef}
                  placeholderTextColor={Color.gray800}
                  placeholder={
                    contentFocused || showTitle ? placeholder : title
                  }
                  style={[
                    content || contentChange ? inputStyle : inputNoContentStyle,
                    icon && { width: '94%' },
                  ]}
                  onChange={(evt) => {
                    if (overrideOnChange) {
                      checkIfEmpty(evt.nativeEvent.text);
                      overrideOnChange(evt);
                    }
                  }}
                  value={value}
                  onFocus={(event) =>
                    !readOnly && handleBlurAndFocus(true, event)
                  }
                  onBlur={() => !readOnly && handleBlurAndFocus(false)}
                  editable={!readOnly}
                  autoCorrect={false}
                  autoCapitalize={'none'}
                  aria-label={`${testID}-TextInput`}
                  {...otherProps}
                />
              )}

              {!dateType && !phoneType && !obscuredText && !format && (
                <TextInput
                  ref={textInputRef}
                  placeholderTextColor={Color.gray800}
                  placeholder={
                    contentFocused || showTitle ? placeholder : title
                  }
                  style={[
                    content || contentChange ? inputStyle : inputNoContentStyle,
                    icon && { width: '94%' },
                  ]}
                  onChangeText={(text) => {
                    onChange && onChange(text);
                    checkIfEmpty(text);
                  }}
                  onChange={(evt) => {
                    if (overrideOnChange) {
                      checkIfEmpty(evt.nativeEvent.text);
                      overrideOnChange(evt);
                    }
                  }}
                  value={value}
                  onFocus={(event) => {
                    onFocus && onFocus();
                    !readOnly && handleBlurAndFocus(true, event);
                  }}
                  onBlur={() => !readOnly && handleBlurAndFocus(false)}
                  editable={!readOnly}
                  secureTextEntry={secureTextEntry}
                  autoCorrect={false}
                  autoCapitalize={'none'}
                  aria-label={`${testID}-TextInput`}
                  {...otherProps}
                />
              )}
            </>
          </TouchableWithoutFeedback>
        </View>
        {password.exists && !readOnly && (
          <PasswordIcon
            testID={testID}
            size={22}
            active={contentFocused}
            error={error}
            secureTextEntry={secureTextEntry}
            onPress={(): void => {
              if (onHideText) onHideText();
              setSecureTextEntry(!secureTextEntry);
            }}
          />
        )}
      </View>

      {(error || !validDate) && !(isEmpty && requiredText) && hintText && (
        <Text
          caption
          style={styles(color, showTitle).hintText}
          testID={`${testID}-Error`}
        >
          {hintText}
        </Text>
      )}
      {isEmpty && requiredText && (
        <Text
          caption
          style={styles(color, showTitle).hintText}
          testID={`${testID}-Required`}
        >
          {requiredText}
        </Text>
      )}
      {password.exists && PasswordText(password)}
      {password.mustMatch && PasswordMustMatch(password.mustMatch)}
    </>
  );
};
