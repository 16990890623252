import React from 'react';
import { WebRoutes, HelpLink } from '@mfe/legacy/mv/utils/Navigation';
import { RouteRenderProps } from '../utils';
import { WebHome } from '../../views/WebTabs';
import LiveChat from '../../views/ContactUs';

export const homeRoutes: RouteRenderProps[] = [
  {
    path: WebRoutes.Main,
    render: <WebHome />,
    fromParent: 0,
  },
  {
    path: HelpLink.AgentChat,
    render: <LiveChat />,
    exact: true,
    fromParent: 1,
  },
];
