import { Txt, Surface, StandAloneLink } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { OrderDetailsSection } from './OrderDetailsSection';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { colors } from '@vst/beam';
import { UpdateAddress } from '@mfe/shared/components';
import { useState } from 'react';

export const EquipmentShipping = () => {
  const { t } = useTranslation('NewChangePlan', {
    keyPrefix: 'orderReview.details.equipment',
  });
  const [isOpen, setIsOpen] = useState(false);

  const {
    userInfo: {
      address: { shipping },
    },
  } = useSelector(selectUserInfo);

  return (
    <Surface>
      <OrderDetailsSection id="equipment" title={t('title')}>
        <Txt variant="bodySmallRegular">{t('content')}</Txt>
      </OrderDetailsSection>
      <div
        style={{ margin: '40px 0', borderTop: `1px solid ${colors.gray[200]}` }}
      />
      <OrderDetailsSection id="equipment" title={'Shipping address'}>
        <Txt variant="bodySmallRegular">{shipping?.addressLines}</Txt>
        <Txt variant="bodySmallRegular">
          {shipping?.municipality}, {shipping?.region} {shipping?.postalCode}
        </Txt>
        <StandAloneLink
          href=""
          style={{ marginTop: 16 }}
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(!isOpen);
          }}
        >
          {t('updateAddress')}
        </StandAloneLink>
      </OrderDetailsSection>
      <UpdateAddress
        type={'shipping'}
        isOpen={isOpen}
        toggleModal={() => setIsOpen(!isOpen)}
      />
    </Surface>
  );
};
