import { CSSProperties } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';
import { Color } from '../../Color';

export interface DrawerContainerProps {
  children: React.ReactNode;
  closeIconColor?: Color | string;
}

export type TransitionStyle = Record<TransitionStatus, CSSProperties | null>;

export const initialTransition: TransitionStyle = {
  entering: null,
  entered: null,
  exiting: null,
  exited: null,
  unmounted: null,
};
