import {
  AEMContentProvider,
  createAemUrl,
  AemFeature,
} from '@mfe/shared/components';
import { useSelector } from 'react-redux';
import { selectConfig } from '@mfe/shared/redux/config';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';

const MvAppAd = () => {
  const { aemBaseUrl } = useSelector(selectConfig);
  const {
    locale: { userLocale },
  } = useSelector(selectLocale);
  const {
    loading,
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  if (loading) {
    return null;
  }

  return (
    <AEMContentProvider
      src={createAemUrl({
        baseUrl: aemBaseUrl,
        locale: userLocale,
        feature: AemFeature.MV_APP_AD,
        accountType,
      })}
    />
  );
};

export default MvAppAd;
