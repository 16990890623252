import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import {
  purchaseAddOn,
  selectAddOns,
  selectCurrentShopAddon,
} from '@mfe/to-be-migrated/redux/addOns';
import {
  ConfirmPurchaseLoading,
  useHasFilledOutCustomAddress,
  useShowAddonsShop,
} from '@mfe/shared/components';
import { Checkout, OrderError, OrderSuccess } from '@mfe/features/add-ons';
import { ADDON_PRODUCT_KIND } from '@mfe/shared/util';
import { VoiceAddonPhoneNumberType } from '@mfe/shared/schema-types';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { CountryCode } from '@mfe/features/add-ons';

const CheckoutPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('ShopAddons');

  const showAddonsShop = useShowAddonsShop();
  const { submitOrder, voiceConfig, voiceEquipmentError } =
    useSelector(selectAddOns);
  const currentShopAddon = useSelector(selectCurrentShopAddon);

  const isVoice = currentShopAddon?.kind === ADDON_PRODUCT_KIND.voice;
  const {
    userInfo: {
      firstName,
      lastName,
      address: { service: serviceAddress },
    },
  } = useSelector(selectUserInfo);
  const { hasFilledOutCustomAddress } = useHasFilledOutCustomAddress();

  useEffect(() => {
    if (!showAddonsShop) {
      history.replace('/');
    }
  }, [history, showAddonsShop]);

  const handlePurchaseSingleStepAddon = () => {
    dispatch(
      purchaseAddOn({
        productTypeId: currentShopAddon?.id || '',
        shoppingCartId: currentShopAddon?.offer_id || '',
      })
    );
  };

  const handlePurchaseVoiceAddon = () => {
    const purchasePayload = {
      shoppingCartId: currentShopAddon?.offer_id ?? '',
      productTypeId: currentShopAddon?.id ?? '',
      voice: JSON.stringify({
        id: currentShopAddon?.id,
        telephoneNumber: voiceConfig.selectedPhoneNumber,
        firstName,
        lastName,
        callerId: voiceConfig.callerId,
        blockCallerId: `${voiceConfig.blockCallerId || false}`,
        directoryListType: voiceConfig.directoryListingType,
        numberType: VoiceAddonPhoneNumberType.New,
        emergencyAddress: !hasFilledOutCustomAddress
          ? serviceAddress
          : {
              addressLines: [
                voiceConfig.emergencyAddress?.streetAddress,
                voiceConfig.emergencyAddress.aptUnit,
              ],
              municipality: voiceConfig.emergencyAddress.cityAddress,
              region: voiceConfig.emergencyAddress.stateProvince,
              countryCode: CountryCode.US,
              postalCode: voiceConfig.emergencyAddress.zipCode,
            },
        publishDirectoryListAddress: `${
          voiceConfig.publishDirectoryListAddress || false
        }`,
      }),
    };
    dispatch(purchaseAddOn(purchasePayload));
  };

  const handleSubmit = () => {
    if (isVoice) {
      handlePurchaseVoiceAddon();
    } else {
      handlePurchaseSingleStepAddon();
    }
  };

  if (!showAddonsShop) {
    return <></>;
  }

  if (submitOrder.loading) {
    return (
      <ConfirmPurchaseLoading
        title={'confirmPurchase.title'}
        description={'confirmPurchase.description'}
      />
    );
  }

  if (voiceEquipmentError) {
    return <OrderError title={t('voiceEquipmentError.title')} />;
  }

  if (submitOrder.error) {
    return <OrderError title={t('error.title')} />;
  }

  if (submitOrder.success) {
    return <OrderSuccess />;
  }

  return <Checkout handleSubmit={handleSubmit} />;
};

export default CheckoutPage;
