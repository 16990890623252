import { Surface, Txt, SectionAlert } from '@vst/beam';
import { CalendarToday } from '@vst/beam-icons/icons';
import styles from './current-schedule.module.scss';
import { selectScheduleInstallation } from '@mfe/to-be-migrated/redux/scheduleInstallation';
import { addHours, format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CurrentSchedule = () => {
  const { installDate } = useSelector(selectScheduleInstallation);
  const { t } = useTranslation('ScheduleInstallation', {
    keyPrefix: 'currentSchedule',
  });

  if (!installDate) return null;

  const formattedDate = format(new Date(installDate), 'MMMM dd');
  const from = new Date(installDate);
  const to = new Date(addHours(parseISO(installDate), 3));

  return (
    <Surface
      data-cy="current-schedule"
      radius="8px"
      className={styles['container']}
    >
      <SectionAlert icon={CalendarToday} variant="infoSecondary" size="small">
        <Txt
          data-cy="current-schedule-message"
          variant="smallRegular"
          color="regular"
        >
          {t('message')}
          <Txt variant="smallBold" component="span">
            {t('date', { formattedDate, from, to })}
          </Txt>
        </Txt>
      </SectionAlert>
    </Surface>
  );
};

export default CurrentSchedule;
