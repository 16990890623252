import styles from './loading-content.module.scss';

export const LoadingContent = () => {
  return (
    <div data-cy="loading-content" className={styles['balanceCard']}>
      <div className={styles['titleAndBadge']}>
        <div className={styles['loadingTitle']} />
        <div className={styles['loadingBadge']} />
      </div>
      <div className={styles['loadingBalance']} />
      <div className={styles['loadingPaymentDue']} />
      <div className={styles['loadingAutoPay']} />
      <div className={styles['loadingSeparator']} />
      <div className={styles['loadingFooter']} />
    </div>
  );
};
