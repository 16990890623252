import React, { createContext, useContext } from 'react';
import { useWindowDimensions } from 'react-native';

export enum ScreenSize {
  XS,
  SM,
  MD,
  LG,
  XL,
}

export interface DimensionContext {
  screenSize: ScreenSize;
  isMobile: boolean;
  isTablet: boolean;
  width: number;
  height: number;
}

export const DimensionContext = createContext<DimensionContext>({
  screenSize: ScreenSize.MD,
  height: 0,
  width: 0,
  isMobile: false,
  isTablet: false,
});

export const getSize = (width: number): ScreenSize => {
  switch (true) {
    case width <= 359:
      return ScreenSize.XS;
    case width <= 767:
      return ScreenSize.SM;
    case width <= 1023:
    default:
      // If width is invalid, default to Medium.
      return ScreenSize.MD;
    case width <= 1339:
      return ScreenSize.LG;
    case width <= Infinity:
      return ScreenSize.XL;
  }
};

export const DimensionContextProvider = ({
  children,
  fixscreenSize,
}: {
  children: any;
  fixscreenSize?: ScreenSize;
}) => {
  const { width, height } = useWindowDimensions();
  let screenSize: ScreenSize | undefined;
  if (fixscreenSize !== undefined) screenSize = fixscreenSize;
  else screenSize = getSize(width);
  return (
    <DimensionContext.Provider
      value={{
        screenSize,
        height,
        width,
        isMobile: screenSize <= ScreenSize.SM,
        isTablet: screenSize === ScreenSize.MD,
      }}
    >
      {children}
    </DimensionContext.Provider>
  );
};

export const useDimensionContext = (): DimensionContext =>
  useContext<DimensionContext>(DimensionContext);
