import React from 'react';
import { View, StyleSheet, ViewStyle, TextStyle } from 'react-native';
import Svg, { Defs, LinearGradient, Stop, Circle } from 'react-native-svg';
import { Text } from './Text';
import { Color } from '../utils/Color/consts';
import { containerStyles } from '../styles/styles';

const styles = {
  textWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
};

const svgPropsByStep = {
  otherStep: {
    height: 21,
    width: 21,
    style: {
      marginLeft: 2.5,
      marginRight: 2.5,
    },
  },
  currentStep: {
    height: 62,
    width: 62,
  },
  currentStepShadow: {
    borderRadius: 50,
    boxShadow: '0 2px 20px -5px rgba(0,0,0,0.5)',
    marginLeft: 2,
    marginRight: 2,
  } as ViewStyle,
};

const circlePropsByStep = {
  base: {
    stroke: Color.white,
    strokeWidth: 3,
    cx: 11,
    cy: 11,
    r: 7.5,
  },
  earlierStep: {
    fill: Color.white,
  },
  currentStep: {
    strokeWidth: 4,
    cx: 31,
    cy: 31,
    r: 28,
    fill: 'url(#gradient)',
  },
  laterStep: {
    fill: 'transparent',
  },
};

const getStep = (stepNum: number, currentStepNum: number) => {
  const isCurrentStep = stepNum === currentStepNum;
  const svgProps = isCurrentStep
    ? svgPropsByStep.currentStep
    : svgPropsByStep.otherStep;

  let circleProps = circlePropsByStep.base;
  if (stepNum < currentStepNum) {
    circleProps = { ...circleProps, ...circlePropsByStep.earlierStep };
  } else if (isCurrentStep) {
    circleProps = { ...circleProps, ...circlePropsByStep.currentStep };
  } else if (stepNum > currentStepNum) {
    circleProps = { ...circleProps, ...circlePropsByStep.laterStep };
  }

  return isCurrentStep ? (
    <View key={stepNum} style={svgPropsByStep.currentStepShadow}>
      <Svg {...svgProps}>
        <Defs>
          <LinearGradient id="gradient" x1="13%" y1="0" x2="87%" y2="100%">
            <Stop offset="0" stopColor={Color.teal400} stopOpacity="1" />
            <Stop offset="1" stopColor={Color.blue600} stopOpacity="1" />
          </LinearGradient>
        </Defs>
        <Circle {...circleProps} />
      </Svg>
      <View style={[StyleSheet.absoluteFill, styles.textWrapper]}>
        <Text.H2 style={{ color: Color.white } as TextStyle}>{stepNum}</Text.H2>
      </View>
    </View>
  ) : (
    <Svg key={`${stepNum}-${currentStepNum}`} {...svgProps}>
      <Circle {...circleProps} />
    </Svg>
  );
};

export interface StepIndicatorProps {
  numSteps: number;
  currentStepNum: number;
}

export const StepIndicator = ({
  numSteps,
  currentStepNum,
}: StepIndicatorProps) => (
  <View
    style={[
      containerStyles.flexColumnToRow as ViewStyle,
      containerStyles.container as ViewStyle,
    ]}
  >
    {Array(numSteps)
      .fill(0)
      .map((e, i) => getStep(i + 1, currentStepNum))}
  </View>
);
