import { ModalTypeEnum, ModalComponent } from '@mfe/shared/components';
import { useTranslation } from 'react-i18next';
import Outcome from './Outcome';
import { useScreenResolution } from '@mfe/shared/util';
import { Alert } from '@vst/beam-icons/illustrative-icons';

interface ApplicationExpiredErrorProps {
  openNationalVerifierInNewTab: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  goBackToOverviewPage: () => void;
}

export const ApplicationExpiredError = (
  props: ApplicationExpiredErrorProps
) => {
  const { openNationalVerifierInNewTab, goBackToOverviewPage } = props;
  const { t } = useTranslation('ACP', { keyPrefix: 'Error' });
  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;

  const renderOutcome = () => {
    return (
      <Outcome
        iconName={Alert}
        data-cy="acp-application-expired-error"
        title={t('applicationExpired.title')}
        text={t('applicationExpired.description')}
        secondaryText={t('applicationExpired.secondaryDescription')}
        primaryButton={{
          label: t('applicationExpired.buttons.goToNationalVerifier'),
          handleClick: (e) => openNationalVerifierInNewTab(e),
          isLink: true,
        }}
        secondaryButton={{
          label: t('applicationExpired.buttons.backToMyViasat'),
          handleClick: goBackToOverviewPage,
        }}
      />
    );
  };

  return isMobile ? (
    renderOutcome()
  ) : (
    <ModalComponent type={ModalTypeEnum.ACP}>{renderOutcome()}</ModalComponent>
  );
};
