import React from 'react';

const ViasatShield = () => (
  <svg
    width="26"
    height="32"
    viewBox="0 0 26 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4321 31.5214L12.7631 31.1904V30.8594L12.2114 31.4111C12.3218 31.4111 12.3953 31.4847 12.4321 31.5214Z"
      fill="#202E39"
    />
    <path
      d="M11.3655 30.8596L12.7632 29.4619V29.1309L11.1816 30.7125C11.2552 30.7493 11.3288 30.786 11.3655 30.8596Z"
      fill="#202E39"
    />
    <path
      d="M10.3359 30.16L12.7635 27.7324V27.4014L10.1152 30.0496C10.1888 30.0496 10.2624 30.1232 10.3359 30.16Z"
      fill="#202E39"
    />
    <path
      d="M9.3426 29.4242L12.8001 25.9668V25.6357L9.15869 29.2771C9.19547 29.3507 9.26904 29.3875 9.3426 29.4242Z"
      fill="#202E39"
    />
    <path
      d="M8.3496 28.6888L12.8002 24.2383V23.9072L8.12891 28.5417C8.20247 28.6153 8.27603 28.6521 8.3496 28.6888Z"
      fill="#202E39"
    />
    <path
      d="M7.35627 27.9538L12.7632 22.5469V22.2158L7.17236 27.8066C7.24593 27.8434 7.28271 27.8802 7.35627 27.9538Z"
      fill="#202E39"
    />
    <path
      d="M6.40022 27.1816L12.7634 20.8183V20.4873L6.21631 26.9977C6.28987 27.0712 6.36344 27.108 6.40022 27.1816Z"
      fill="#202E39"
    />
    <path
      d="M5.48029 26.3726L12.7631 19.0898V18.7588L5.29639 26.1887C5.36995 26.2622 5.44351 26.299 5.48029 26.3726Z"
      fill="#202E39"
    />
    <path
      d="M4.59781 25.5258L12.7633 17.3603V17.0293L4.45068 25.3419C4.48747 25.3787 4.56103 25.4523 4.59781 25.5258Z"
      fill="#202E39"
    />
    <path
      d="M3.75178 24.643L12.7633 15.6315V15.2637L3.56787 24.4591C3.64143 24.4959 3.715 24.5694 3.75178 24.643Z"
      fill="#202E39"
    />
    <path
      d="M2.94254 23.6869L12.7632 13.8662V13.5352L2.79541 23.503C2.83219 23.5765 2.90575 23.6501 2.94254 23.6869Z"
      fill="#202E39"
    />
    <path
      d="M2.2067 22.7308L12.7998 12.1377V11.8066L2.05957 22.5101C2.09635 22.5837 2.13313 22.6572 2.2067 22.7308Z"
      fill="#202E39"
    />
    <path
      d="M1.50797 21.6643L12.7631 10.4092V10.0781L1.36084 21.4804C1.4344 21.5172 1.47119 21.5908 1.50797 21.6643Z"
      fill="#202E39"
    />
    <path
      d="M0.919427 20.5243L12.7631 8.68064V8.34961L0.809082 20.3036C0.845864 20.3772 0.882645 20.4508 0.919427 20.5243Z"
      fill="#202E39"
    />
    <path
      d="M0.478348 19.2362L12.7634 6.95115V6.62012L0.404785 18.9787C0.404785 19.0523 0.441567 19.1626 0.478348 19.2362Z"
      fill="#202E39"
    />
    <path
      d="M0.257324 17.7281L12.7631 5.22231V4.85449L0.257324 17.3602V17.7281Z"
      fill="#202E39"
    />
    <path
      d="M0.257324 15.9995L12.7631 3.49379V3.12598L0.257324 15.6317V15.9995Z"
      fill="#202E39"
    />
    <path
      d="M0.257324 14.271L12.7631 1.76528V1.39746L0.257324 13.9032V14.271Z"
      fill="#202E39"
    />
    <path
      d="M0.257324 12.5418L9.26882 3.53027C9.01135 3.6774 8.75387 3.86131 8.45962 4.00843L0.257324 12.174V12.5418Z"
      fill="#202E39"
    />
    <path
      d="M0.257324 10.8138L6.14238 4.92871C5.99525 4.96549 5.81135 5.00227 5.66422 5.07584L0.257324 10.446V10.8138Z"
      fill="#202E39"
    />
    <path
      d="M0.257324 9.08506L3.97227 5.37012C3.86192 5.37012 3.7148 5.4069 3.60445 5.4069L0.294106 8.71724V9.08506H0.257324Z"
      fill="#202E39"
    />
    <path
      d="M0.257324 7.356L2.16997 5.44336C2.05962 5.44336 1.94928 5.44336 1.83893 5.44336L0.294106 6.98819V7.356H0.257324Z"
      fill="#202E39"
    />
    <path
      d="M0.257324 5.62693L0.551577 5.33268C0.441232 5.33268 0.367669 5.2959 0.257324 5.2959V5.62693Z"
      fill="#202E39"
    />
    <path
      d="M12.8 32C12.7632 32 12.6897 32 12.6529 31.9632C12.6161 31.9632 9.45287 30.1241 6.36322 27.4759C2.17011 23.908 0 20.6345 0 17.7287V5.29655C0 5.22299 0.0367813 5.14943 0.110345 5.07586C0.183908 5.03908 0.257471 5.0023 0.331034 5.0023C4.5977 5.62759 7.68736 4.26667 9.52644 3.01609C11.5494 1.65517 12.5425 0.147126 12.5425 0.110345C12.5793 0.0367816 12.6897 0 12.7632 0C12.8736 0 12.9471 0.0367816 12.9839 0.110345C12.9839 0.110345 13.977 1.65517 16 2.97931C17.8391 4.22989 20.9287 5.5908 25.2322 4.96552C25.3057 4.96552 25.3793 4.96552 25.4529 5.03908C25.5264 5.07586 25.5632 5.14943 25.5632 5.25977V17.692C25.5632 20.5977 23.4299 23.8713 19.2 27.4391C16.0736 30.0506 12.9471 31.8897 12.9103 31.8897C12.9103 31.9632 12.8368 32 12.8 32ZM0.551724 5.62759V17.7287C0.551724 23.8713 11.4391 30.5655 12.8 31.3747C14.1609 30.5655 25.0115 23.908 25.0115 17.7287V5.62759C17.7655 6.47356 13.7563 2.02299 12.8 0.772414C11.8437 2.02299 7.7977 6.51034 0.551724 5.62759Z"
      fill="#202E39"
    />
  </svg>
);
export default ViasatShield;
