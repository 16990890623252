import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Pages,
  selectStreamOnPromo,
} from '@mfe/to-be-migrated/redux/streamOnPromo';
import { ErrorPage } from './error';
import { OrderReview } from './orderReview';
import { OrderSummary } from './orderSummary';
import { UnavailablePage } from './unavailable';
import {
  selfDescribingEvent,
  AnalyticsEventNames,
} from '@mfe/to-be-migrated/redux/analytics';
import { AnalyticsEventSource } from '../lib/analytics/pixel-analytics-type';

export const StreamOnNavigator = () => {
  const {
    streamEligibility: { isEligible },
    currentPage,
    offerDetails,
  } = useSelector(selectStreamOnPromo);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      if (!offerDetails.loading)
        switch (currentPage) {
          case Pages.orderReview:
          case Pages.orderSummary:
            dispatch(
              selfDescribingEvent({
                vendor: 'com.viasat.care',
                eventName: AnalyticsEventNames.AddonsOrderFlowAbandoned,
                context: [
                  {
                    schema: 'addons_context',
                    data: {
                      product_name: offerDetails.name,
                      product_type_id: offerDetails.productTypeId,
                      product_family: offerDetails.productFamily,
                      source: AnalyticsEventSource.OVERVIEW_PAGE,
                      product_price: offerDetails.price,
                      discount_amount: offerDetails.discountValue ?? 0,
                      discount_duration: offerDetails.discountDuration ?? 0,
                    },
                  },
                ],
              })
            );
            break;
        }
    };
  }, [offerDetails]);

  if (!isEligible) {
    return <UnavailablePage />;
  }

  switch (currentPage) {
    case Pages.orderReview:
      return <OrderReview />;
    case Pages.orderSummary:
      return <OrderSummary />;
    case Pages.error:
      return <ErrorPage />;
    case Pages.unavailable:
      return <UnavailablePage />;
    case Pages.None:
    default:
      return null;
  }
};

export default StreamOnNavigator;
