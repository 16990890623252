import React, { useReducer, useState, useRef, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import {
  Text,
  Color,
  Loading,
  useDimensionContext,
  ScreenSize,
  useParams,
} from '@mfe/legacy/andromeda';
import { useTranslation } from 'react-i18next';
import {
  faqReducer,
  faqInitialState,
  NAV_BAR_HEIGHT,
  initialMap,
} from './utils';
import { SectionValue, LayoutMap, FiltersProps, FaqSelection } from './types';
import { useTrackIsInViewport } from '../../containers';
import Status from '../../components/Status';
import { Filters } from './Filters';
import { Accordion } from './Accordion';
import { useDynamicData } from './utils';
import { GetFaqPayload } from '@viasat/res-apps-lib/build/types/mv';

const styles = StyleSheet.create({
  bottomPanel: {
    flex: 1,
    paddingBottom: 72,
    backgroundColor: Color.gray100,
  },
  instructionText: {
    marginLeft: 48,
    marginBottom: 54,
  },
  leftPanel: {
    width: 478,
    backgroundColor: Color.white,
  },
  loadingContainer: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightPanel: {
    flex: 1,
    minHeight: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
    backgroundColor: Color.gray100,
  },
  title: {
    marginTop: 72,
    marginBottom: 24,
    marginLeft: 48,
  },
  topPanel: {
    flex: 1,
    backgroundColor: Color.white,
  },
});

const Header = ({ ...props }: FiltersProps): JSX.Element => {
  const { t } = useTranslation('FAQ');
  const { screenSize } = useDimensionContext();
  const isSmallScreen = screenSize <= ScreenSize.MD;
  return (
    <View style={isSmallScreen ? styles.topPanel : styles.leftPanel}>
      <Text.H1 heavy style={styles.title}>
        {t('title')}
      </Text.H1>
      <Text large light style={styles.instructionText}>
        {t('instructions')}
      </Text>
      <Filters {...props} />
    </View>
  );
};

export const Faq = (): JSX.Element => {
  const { t } = useTranslation('FAQ');
  const { screenSize, width, height } = useDimensionContext();
  const isSmallScreen = screenSize <= ScreenSize.MD;
  const analyticsFaqRef = useRef<View>(null);
  useTrackIsInViewport(analyticsFaqRef, 'FAQ', 'viewed');

  const [faqSelection, faqDispatch] = useReducer(faqReducer, faqInitialState);
  const { question: existingQuestion, section: existingSection } = faqSelection;

  const [layoutMap, setLayoutMap] = useState<LayoutMap>(initialMap);

  // Lazy load default export of faqs file
  const { data, isLoading, error } = useDynamicData<{
    default: GetFaqPayload;
  }>(import('./faqs'));

  const handleSelect = ({
    section,
    question,
    resetQuestion,
  }: FaqSelection): void => {
    const clearSelectedQuestion = () => faqDispatch({ type: 'Question' });
    if (resetQuestion) clearSelectedQuestion();
    if (section) {
      const isSame = existingSection === section;
      if (!isSame)
        faqDispatch({ type: 'Section', section: section as SectionValue });
    }
    if (question) {
      const isSame = existingQuestion === question;
      // If a card is already expanded, first close the open card.
      if (existingQuestion !== undefined) clearSelectedQuestion();
      if (!isSame) faqDispatch({ type: 'Question', question });
    }
  };

  /**
   * Handles the navigation redirect from the Faq Card.
   * On load the section & question is selected, and after obtaining
   * the proper values the navigation params are cleared.
   */
  const { params, clear } = useParams();
  useEffect((): void => {
    try {
      const { section, question } = params;
      if (section !== undefined) {
        setTimeout((): void => {
          faqDispatch({ type: 'Section', section });
          if (question !== undefined)
            faqDispatch({ type: 'Question', question });
        }, 1);
        clear();
      }
    } catch (e) {
      console.error(e);
    }
  }, [params]);

  /* On resizing window from desktop to tablet || mobile,
   * reset to 'All Topics'.
   */
  useEffect((): void => {
    handleSelect({ section: SectionValue.All, resetQuestion: true });
  }, [isSmallScreen]);

  if (isLoading)
    return (
      <Loading
        size={120}
        style={[styles.loadingContainer, { width, height }]}
      />
    );

  const faqs = data?.default;
  if (error || !faqs) return <Status customBody={t('dataError')} />;

  return (
    <View
      ref={analyticsFaqRef}
      style={{
        flexDirection: isSmallScreen ? 'column' : 'row',
        alignItems: 'stretch',
      }}
      testID="faq"
    >
      <Header {...{ faqSelection, layoutMap, handleSelect, faqs }} />
      <View style={isSmallScreen ? styles.bottomPanel : styles.rightPanel}>
        <Accordion
          {...{
            faqSelection,
            handleSelect,
            setLayoutMap,
            faqs,
          }}
        />
      </View>
    </View>
  );
};
