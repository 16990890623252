//@typescript-eslint/no-empty-function
import React, { useContext, createContext, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import FileViewer from 'react-native-file-viewer';
import packageJson from '../../myversion.json';
import { CustomFields, RCMethods } from './common';
import { useToken } from '../../views/Auth';
import { createRCMethods } from './rcMethods';
import { useSelector } from 'react-redux';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { Locale } from '@mfe/shared/schema-types';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';

/*
 * Documentation for the RocketChat livechat API: https://docs.rocket.chat/api/livechat-api#usage
 */

const isWeb = Platform.OS === 'web';
let rocketChatMethods: RCMethods = {
  restartMobileWidget: () => undefined,
  maximizeWidget: () => undefined,
  setCustomFields: (fields: CustomFields[]) => undefined,
  setTheme: () => undefined,
  setGuestName: (name: string) => undefined,
  setGuestEmail: (email: string) => undefined,
  showPrint: () => undefined,
  setRocketChatDataAfterLoad: () => undefined,
};

const APP_NAME = Platform.select({
  android: 'MyViasatMobileAND',
  ios: 'MyViasatMobileIOS',
  web: 'MyViasatWebAmer',
  default: 'MyViasatWebAmer',
});

const SCRIPT_ID = 'rocketchat-script';

const uninitalizedRocketChatFn = (): void => {
  console.error('RocketChat did not load properly');
};

const Context = createContext({
  maximizeWidget: uninitalizedRocketChatFn,
  setCustomFields: (fields: CustomFields[]) => {
    console.error('RocketChat did not load properly');
  },
  isRocketChatEnabled: false,
  loadRocketChatMobile: (webViewRef: any) => {
    console.error('RocketChat did not load properly');
  },
  handleRNMessage: (message: any, webview: any) => {
    console.error('RocketChat did not load properly');
  },
});

const loadRocketChatScript = (
  hostUrl: string,
  livechatUrl: string,
  name: string,
  email: string,
  setIsScriptLoaded: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const w: any = window;
  const existingScript = document.getElementById(SCRIPT_ID);

  if (!existingScript) {
    return new Promise(function (resolve, reject) {
      w.RocketChat = (params: any) => w.RocketChat._.push(params);
      w.RocketChat._ = [];
      w.RocketChat.url = `${livechatUrl}`;
      const tag = document.createElement('script');
      tag.async = true;
      tag.src = `${hostUrl}`;
      tag.id = SCRIPT_ID;
      tag.onload = resolve;
      tag.onerror = reject;
      const scriptElement = document.getElementsByTagName('script')[0];
      scriptElement.parentNode?.insertBefore(tag, scriptElement);
    })
      .then(() => {
        rocketChatMethods = createRCMethods({ rocketChat: w.RocketChat });
        setDefaultsOnLoad(name, email);
        setIsScriptLoaded(true);
      })
      .catch((err) => {
        rocketChatMethods?.setCustomFields([
          { name: 'logPoint1', value: `loadRocketChatScript: err ${err} ` },
        ]);
        console.error(err);
      });
  }
  return null;
};

const loadRocketChatMobileScript = (
  hostUrl: string,
  livechatUrl: string,
  webViewRef: any
) => {
  const injectRocketChatScript = `(function () {
    var existingScript = document.getElementById("${SCRIPT_ID}");
    if (!existingScript) {
      window.RocketChat = function (c) { window.RocketChat._.push(c) };
      window.RocketChat._ = [];
      window.RocketChat.url = "${livechatUrl}";

      var tag = document.createElement('script');
      tag.async = true;
      tag.src = "${hostUrl}";
      tag.id = "${SCRIPT_ID}";
      var head = document.getElementsByTagName("head")[0];
      head.append(tag);
    }

    var retryCallback = setInterval(function(){
      if(window.RocketChat) {
        window.ReactNativeWebView.postMessage(JSON.stringify({eventType: "isRocketChatLoaded", data: { value: window.RocketChat ? true : false }}));
        clearInterval(retryCallback);
      }
    }, 1000)

    window.addEventListener("message", function (event) {
      if (event.data?.fn === 'postMessage') {
        window.ReactNativeWebView.postMessage(event.data?.args);
      }
    }, false);

   })();
   `;

  webViewRef.current.injectJavaScript(injectRocketChatScript);
};

const setDefaultsOnLoad = (name: string, email: string) => {
  rocketChatMethods?.setTheme();
  rocketChatMethods?.setGuestName(name);
  rocketChatMethods?.setGuestEmail(email);
};

export const RocketChatProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element | null => {
  // Will not take any scope other than global(at least from what I can tell).
  // Mobile can only be passed a function to access the global scope there.
  const { token } = useToken();
  const [isScriptLoaded, setIsScriptLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (isScriptLoaded) {
      rocketChatMethods?.setCustomFields([
        { name: 'appName', value: APP_NAME },
        { name: 'appVersion', value: packageJson.version },
      ]);
    }
  }, [isScriptLoaded]);

  useEffect(() => {
    if (isScriptLoaded) {
      if (!token?.accessToken) {
        rocketChatMethods?.setCustomFields([
          {
            name: 'logPoint2',
            value:
              'useEffect(accessToken): RC Widget is loaded but accessToken is undefined',
          },
        ]);
      } else if (token?.accessToken == 'Error') {
        rocketChatMethods?.setCustomFields([
          {
            name: 'logPoint3',
            value:
              'useEffect(accessToken): RC Widget is loaded but failed to get accessToken(Error)',
          },
        ]);
      }
    }

    if (isScriptLoaded && token?.accessToken) {
      rocketChatMethods?.setCustomFields([
        {
          name: 'logPoint4',
          value:
            'useEffect(accessToken): Got token and RC Widget is loaded as well. If token and appName are still missing then that means issue at RC Widget side',
        },
      ]);
      rocketChatMethods?.setCustomFields([
        { name: 'token', value: token?.accessToken ?? '' },
      ]);

      rocketChatMethods?.setRocketChatDataAfterLoad();

      if (!isWeb) {
        rocketChatMethods?.showPrint();
        rocketChatMethods?.restartMobileWidget();
      }
    }
  }, [token?.accessToken, isScriptLoaded]);

  const { locale, localeFetched } = useSelector(selectLocale);
  const { userInfo, loading } = useSelector(selectUserInfo);

  // TODO: Hook this back up if we ever bring back RC
  const isRCEnabled = false;
  const liveChatUrl = 'http://google.com';
  const hostUrl = 'http://google.com';
  if (
    Platform.OS === 'web' &&
    isRCEnabled &&
    !loading &&
    localeFetched &&
    locale.userLocale !== Locale.PtBr
  ) {
    loadRocketChatScript(
      hostUrl,
      liveChatUrl,
      userInfo.fullName,
      userInfo.email,
      setIsScriptLoaded
    );
  }

  const loadRocketChatMobile = (webViewRef: any) => {
    loadRocketChatMobileScript(hostUrl, liveChatUrl, webViewRef);
  };

  const handleTranscript = async (fileName: string, transcript: string) => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const RNFS = require('react-native-fs');
    const path = `${RNFS.DocumentDirectoryPath}/${fileName}`;

    await RNFS.writeFile(path, transcript, 'base64');

    FileViewer.open(path, {
      // ios
      displayName: fileName,
      // android
      showAppsSuggestions: true,
      showOpenWithDialog: true,
    }).catch((error) => {
      console.error(error);
    });
  };

  const handleRNMessage = async (messageData: any, webview: any) => {
    if (Platform.OS !== 'web') {
      const message = JSON.parse(messageData.nativeEvent.data);

      if (message.eventType === 'isRocketChatLoaded' && message.data.value) {
        rocketChatMethods = createRCMethods({ webview });
        setDefaultsOnLoad(userInfo.fullName, userInfo.email);
        setIsScriptLoaded(true);
      } else if (
        message.eventType === 'transcript' &&
        message.data.name &&
        message.data.value
      ) {
        let transcript = message.data.value;
        transcript = transcript.replace(/^data:\w+\/[-+.\w]+;base64,/, '');
        await handleTranscript(message.data.name, transcript);
      }
    }
  };

  return (
    <Context.Provider
      value={{
        maximizeWidget: rocketChatMethods?.maximizeWidget,
        setCustomFields: rocketChatMethods?.setCustomFields,
        isRocketChatEnabled: isRCEnabled,
        loadRocketChatMobile,
        handleRNMessage,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useRocketChatContext = () => useContext(Context);
