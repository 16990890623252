import React, { useMemo } from 'react';
import Cookies from 'js-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { selectLocale, setLocaleState } from '@mfe/to-be-migrated/redux/locale';
import { Locale } from '@mfe/shared/schema-types';
import { useTranslation } from 'react-i18next';
import {
  GeneralEventTypes,
  MicroFrontendDomains,
} from '@mfe/legacy/mv/services/microFrontend';

export enum DisplayLanguage {
  Portuguese = 'pt-BR',
  Spanish = 'es-MX',
  English = 'en-US',
  Italian = 'it-IT',
}

export type LanguageOption = {
  short: string;
  long: string;
};

export const languageOptions: Record<DisplayLanguage, LanguageOption> = {
  [DisplayLanguage.English]: {
    short: 'EN',
    long: 'English',
  },
  [DisplayLanguage.Portuguese]: {
    short: 'PT',
    long: 'Portuguese',
  },
  [DisplayLanguage.Italian]: {
    short: 'IT',
    long: 'Italiano',
  },
  [DisplayLanguage.Spanish]: {
    short: 'ES',
    long: 'Spanish',
  },
};

// Which languages are availble to which users?
export const availableLanguagesMap: Record<Locale, DisplayLanguage[]> = {
  [Locale.EnUs]: [DisplayLanguage.English],
  [Locale.PtBr]: [DisplayLanguage.English, DisplayLanguage.Portuguese],
  [Locale.ItIt]: [DisplayLanguage.English, DisplayLanguage.Italian],
  [Locale.EsMx]: [DisplayLanguage.English],
};

export const useSwitchLanguage = () => {
  const dispatch = useDispatch();
  const {
    locale: { userLocale },
  } = useSelector(selectLocale);
  const { i18n } = useTranslation('');

  const switchLanguage = useMemo(() => {
    return (desiredLanguage: DisplayLanguage): { wasSuccessful: boolean } => {
      // Check if this language is available to this user given their locale
      const availableLanguages = availableLanguagesMap[userLocale];
      if (!availableLanguages.includes(desiredLanguage)) {
        return { wasSuccessful: false };
      }

      // Change to the desired language
      Cookies.set('displayLanguage', desiredLanguage, { expires: 14 });

      i18n.changeLanguage(desiredLanguage);
      dispatch(setLocaleState({ displayLanguage: desiredLanguage }));

      postChangeLanguageMessage(desiredLanguage);

      return { wasSuccessful: true };
    };
  }, [userLocale, dispatch, i18n]);

  return switchLanguage;
};

function postChangeLanguageMessage(displayLanguage: DisplayLanguage) {
  const mainframe = document.getElementById('webIframe');
  const wnd = (mainframe as HTMLIFrameElement)?.contentWindow;

  if (mainframe && wnd) {
    wnd.postMessage(
      {
        domain: MicroFrontendDomains.General,
        data: displayLanguage,
        eventType: GeneralEventTypes.ChangeLanguage,
      },
      '*'
    );
  }
}
