import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import {
  homeRoutes,
  billingRoutes,
  planRoutes,
  helpRoutes,
  networkRoutes,
  shopAddonsRoutes,
} from './NestedSwitch';
import {
  WebHome,
  WebRoutes,
  PayBillLink,
  PlanLink,
  HelpLink,
  NetworkLink,
  ShopAddonsLink,
} from '@mfe/legacy/mv/utils/Navigation';
import { Txt } from '@vst/beam';

export interface RouteRenderProps {
  path: string;
  render: JSX.Element;
  exact?: boolean;
  fromParent: number;
  param?: string;
}

export const Placeholder = (): JSX.Element => (
  <div style={{ height: 1000 }}>
    <Txt style={{ marginBottom: 900 }}>Placeholder</Txt>
    <Txt>Placeholder</Txt>
  </div>
);

export const PlaceholderError = (): JSX.Element => (
  <div style={{ height: 1000 }}>
    <Txt style={{ marginBottom: 900 }}>Placeholder 404</Txt>
    <Txt>Error 404</Txt>
  </div>
);

export const errorRoute: RouteRenderProps[] = [
  {
    path: WebRoutes.Error,
    render: <PlaceholderError />,
    fromParent: 0,
  },
];

const getEnumKeyByEnumValue = (
  enumerator: any,
  currentPath: string
): boolean => {
  const match = Object.keys(enumerator).filter(
    (path: string): boolean => enumerator[path] === currentPath
  );
  return match.length === 1;
};

const getParentRoute = (pathname: string): RouteRenderProps[] => {
  switch (true) {
    case getEnumKeyByEnumValue(WebHome, pathname):
      return homeRoutes;
    case getEnumKeyByEnumValue(PayBillLink, pathname):
      return billingRoutes;
    case getEnumKeyByEnumValue(PlanLink, pathname):
      return planRoutes;
    case getEnumKeyByEnumValue(NetworkLink, pathname):
      return networkRoutes;
    case getEnumKeyByEnumValue(HelpLink, pathname):
      return helpRoutes;
    case getEnumKeyByEnumValue(ShopAddonsLink, pathname):
      return shopAddonsRoutes;
    default:
      return errorRoute;
  }
};

const getRouteIndex = (path: string, routes: RouteRenderProps[]): number => {
  const currentRoute = routes.find(
    (route: RouteRenderProps): boolean => route.path === path
  );
  return currentRoute?.fromParent ?? -1;
};

/* Web Routing Validation
 * Prohibits a user from accessing mid-flow URLs by recording react-router's history location & destination within andromeda's useNavigate function.
 * This works by categorizing Parent & nested routes. Parents are defined as Tab pages (ie: Home, Billing & Payments, Plan, Help). Nested routes
 * are defined as any internal views within the Parent. Each route has a numerical index reference from the Parent's page (0) labeled fromParent.
 * The routing history must follow the proper indexing sequence otherwise react-router will redirect the user to the Parent page.
 *
 * This function works by locating the parent route via matching the destination route with shared/utils/Navigation enums. Once the parent route is
 * obtained, both the current & prior paths are compared to ensure logical indexing order.
 * Parent routes must sit at the head of the given routing array.
 */
const redirectRoute = (
  location: ReturnType<typeof useHistory>['location'],
  inputParent?: RouteRenderProps[]
): boolean => {
  const paths = location.pathname.split('/');
  let currentPath = paths[paths.length - 1];

  let parentRoute = inputParent;
  if (parentRoute === undefined) {
    parentRoute = getParentRoute(currentPath);
  }

  if (parentRoute === errorRoute) {
    currentPath = paths[paths.length - 2];
    parentRoute = getParentRoute(currentPath);
  }

  // Current path doesn't require historical location to access
  const currentIndex = getRouteIndex(currentPath, parentRoute);
  if (currentIndex === 0) {
    return false;
  }

  // No history location exists.
  const priorLocation = location.state;
  if (priorLocation === undefined) {
    return true;
  }

  // Both history location & nested navigation exist.
  const { priorRoute } = priorLocation as any;
  if (priorRoute !== undefined && currentIndex !== 0) {
    const priorPath = priorRoute.slice(priorRoute.lastIndexOf('/') + 1);
    const priorIndex = getRouteIndex(priorPath, parentRoute);

    return !(currentIndex - 1 === priorIndex);
  }

  return false;
};

interface NestedRoutesProps {
  routes: RouteRenderProps[];
}

export const NestedRoutes = ({ routes }: NestedRoutesProps): JSX.Element => {
  const match = useRouteMatch();

  return (
    <Switch>
      {routes.map(
        ({
          exact,
          path: endPath,
          render,
          param = '',
        }: RouteRenderProps): JSX.Element => {
          const path = `${match.url}/${
            endPath === routes[0].path ? WebRoutes.Main : endPath
          }`;

          return (
            <Route
              exact={exact}
              path={path + param}
              render={({ location }): JSX.Element => {
                const redirect = redirectRoute(location);

                return redirect ? (
                  <Redirect to={`/${routes[0].path}`} />
                ) : (
                  render
                );
              }}
              key={path}
            />
          );
        }
      )}
      <Route
        render={(): JSX.Element => <Redirect to={`/${routes[0].path}`} />}
      />
    </Switch>
  );
};

interface SingleRouteProps {
  path: string;
  render: JSX.Element;
  validation: RouteRenderProps[];
}

export const SingleRoute = ({
  path,
  render,
  validation,
}: SingleRouteProps): JSX.Element => {
  const match = useRouteMatch();

  //"path" used to be "to"
  return (
    <Switch>
      <Route
        path={`${match.url}${path}`}
        render={({ location }): JSX.Element => {
          const redirect = redirectRoute(location, validation);

          return redirect ? <Redirect to={`/${validation[0].path}`} /> : render;
        }}
      />
    </Switch>
  );
};

export interface ScreenEntry {
  screen: (any?: any) => JSX.Element;
  label: string;
  path: string;
  exact?: boolean;
}
