import { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Surface, Txt, InlineLink } from '@vst/beam';

import useNavigate from '@mfe/services/navigation';
import { NetworkLink } from '@mfe/legacy/mv/utils/Navigation';
import { HeaderWithBackNavigation } from '@mfe/shared/components';
import { selectDiagnostics } from '@mfe/to-be-migrated/redux/network';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';

import RebootModemModal from '../reboot-modem-modal';
import DiagnosticsList from './diagnostics-list';
import DiagnosticsHeader from './diagnostics-header';

import styles from './styles.module.scss';

export type DiagnosticResultProps = {
  name: string;
  isOK: boolean;
};

const DiagnosticResults = () => {
  const { t } = useTranslation('Network');
  const { goBack } = useNavigate();
  const dispatch = useDispatch();

  const { homeAssessments, viasatAssessments, diagnosticIssues, runDate } =
    useSelector(selectDiagnostics);

  const openLinkInNewTab = (e: React.MouseEvent<HTMLElement>, url: string) => {
    e.preventDefault();
    dispatch(openInNewTab(url));
  };

  const helpCenterUrl = t('Global:helpCenterLink');

  return (
    <>
      <RebootModemModal redirectRoute={NetworkLink.Reboot} />
      <div className={styles['pageLayout']}>
        <HeaderWithBackNavigation
          title={t('DiagnosticResults.title')}
          goBackLabel={t('DiagnosticResults.goBack')}
          additionalClassName={styles['pageHeader']}
          handleBackNavigation={() => goBack()}
        />
        <Surface className={styles['resultsCard']}>
          <DiagnosticsHeader
            issueDetected={Boolean(diagnosticIssues?.length)}
            lastRun={runDate ?? ''}
          />
          <div data-cy="diagnostics-list" className={styles['body']}>
            <DiagnosticsList
              title={t('DiagnosticResults.homeNetwork')}
              assessments={homeAssessments}
            />
            <DiagnosticsList
              title={t('DiagnosticResults.viasatNetwork')}
              assessments={viasatAssessments}
            />
          </div>
          <Txt variant="smallRegular" className={styles['callCare']}>
            <Trans
              i18nKey="Network:DiagnosticResults.callCare"
              components={[
                <InlineLink
                  href={helpCenterUrl}
                  onClick={(e) => openLinkInNewTab(e, helpCenterUrl)}
                  children={{} as ReactNode}
                />,
              ]}
              values={{
                phoneNumber: t('Global:callCustomerSupportPhoneNumberDisplay'),
              }}
            />
          </Txt>
        </Surface>
      </div>
    </>
  );
};

export default DiagnosticResults;
