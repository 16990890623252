import { StyleSheet } from 'react-native';
import { sharedStyles } from './styles.shared';
import { Color } from '../../utils/Color';

export const styles = StyleSheet.create({
  ...sharedStyles,
  borderContainer: {
    borderTopWidth: 1,
    borderTopColor: Color.teal500,
  },
  defaultItem: {
    backgroundColor: 'transparent',
  },
  defaultItemStyle: {
    paddingHorizontal: 20,
    paddingVertical: 8,
    backgroundColor: Color.white,
  },
  defaultPickerItem: {
    paddingVertical: 8,
    paddingHorizontal: 20,
  },
  defaultContainerStyle: {
    minHeight: 64,
    borderWidth: 1,
    borderRadius: 6,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    backgroundColor: Color.white,
    borderColor: Color.gray400,
  },
  dropdownIcon: {
    position: 'absolute',
    alignSelf: 'flex-end',
    top: 22,
  },

  itemRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelWidth: {
    width: '90%',
  },
  onHover: {
    backgroundColor: Color.gray100,
  },
  pickerTouchable: {
    height: '100%',
    minHeight: 64,
    width: '100%',
    paddingHorizontal: 20,
    paddingVertical: 11,
    borderRadius: 6,
    zIndex: 2,
  },
});
