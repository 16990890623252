import { useTranslation } from 'react-i18next';

import { Button, IllustrativeIcon, Txt } from '@vst/beam';
import { Wifi } from '@vst/beam-icons/illustrative-icons';

import styles from './styles.module.scss';

interface DiagnosticDefaultProps {
  runDiagnosticHandler: (e: React.MouseEvent<HTMLElement>) => void;
}
export const DiagnosticDefault = ({
  runDiagnosticHandler,
}: DiagnosticDefaultProps) => {
  const { t } = useTranslation('Network', { keyPrefix: 'diagnostic' });

  return (
    <div className={styles['content']}>
      <IllustrativeIcon
        data-cy="update-bill-cycle-error"
        name="error-illustration"
        icon={Wifi}
        color="teal_600"
        style={{ height: '100px', width: '100px' }}
      />

      <div className={styles['titleAndDescription']}>
        <Txt variant="heading5" mb="8px" data-cy="run-diagnostic-title">
          {t('default.title')}
        </Txt>
        <Txt
          variant="bodySmallRegular"
          color="subtle"
          data-cy="run-diagnostic-description"
        >
          {t('default.description')}
        </Txt>
      </div>

      <Button
        variant="primary"
        fluid
        data-cy="run-diagnostic-button"
        style={{ maxWidth: '400px' }}
        onClick={runDiagnosticHandler}
      >
        {t('buttons.runDiagnostic')}
      </Button>
    </div>
  );
};
