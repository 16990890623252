import StorageManager from '../../services/localStorage';
import {
  BillingEventTypes,
  GeneralEventTypes,
  MicroFrontendDomains,
  UsageEventHandlerMethods,
  UsageEventTypes,
  WindowMessageData,
} from '../../services/microFrontend';
import { Platform } from 'react-native';
import {
  HelpLink,
  PayBillLink,
  PlanLink,
  StackLink,
} from '../../utils/Navigation';
import { retrieveAuthCode } from '../../views/Auth/utils';
import { updatePushNotificationStatus } from '../../views/Profile/utils';
import {
  MicroFrontendEventHandlerMethods,
  OverviewEventHandlerMethods,
  TokenInfo,
} from './types';
import { handleChangeBillDateNavigation } from '../../views/PayBill/utils';

const handleUsageEvents = (
  data: WindowMessageData,
  {
    goTo,
    maximizeWidget,
    sendMVClientInfo,
    sendOverviewMFEmessage,
    openInNewTab,
  }: UsageEventHandlerMethods,
  handleInvalidToken: (data: WindowMessageData) => void
) => {
  const messageData = data.data;
  switch (data.eventType as UsageEventTypes) {
    case UsageEventTypes.RefetchPlanInfo:
      sendOverviewMFEmessage &&
        sendOverviewMFEmessage({}, UsageEventTypes.RefetchPlanInfo);
      break;
    case UsageEventTypes.ChangePlan:
      goTo(
        Platform.OS === 'web'
          ? `${PlanLink.Plan}/${PlanLink.ChoosePlanFilter}`
          : PlanLink.ChoosePlanFilter
      );
      break;
    case UsageEventTypes.ChatWithUs:
      if (Platform.OS === 'web') {
        maximizeWidget && maximizeWidget();
      } else {
        goTo(StackLink.LiveChat);
      }
      break;
    case UsageEventTypes.FAQSpecificQuestion:
      if (messageData) {
        goTo(HelpLink.Main, {
          params: {
            section: messageData.section,
            question: messageData.question,
          },
        });
      }
      break;
    case UsageEventTypes.InvalidToken:
      handleInvalidToken(data);
      break;
    case UsageEventTypes.Loaded:
      sendMVClientInfo && sendMVClientInfo();
      break;
    case UsageEventTypes.VisitPlanUsage:
      goTo(PlanLink.Plan);
      break;
    case UsageEventTypes.OpenInNewTab:
      openInNewTab && openInNewTab(messageData.url);
      break;
  }
};

const handleGeneralEvents = (
  data: WindowMessageData,
  {
    sendMFEmessage,
    setIsAuthenticated,
    setToken,
    setIsAuthMFELoaded,
    goTo,
    updatePushNotificationStatusProps,
    copyText,
    scrollTop,
    sendToken,
    setCookieLoginFailed,
    setRedirectUrl,
    setLoginUrl,
    setLogoutUrl,
    logout,
    trackLoginEvent,
    openInNewTab,
  }: MicroFrontendEventHandlerMethods
) => {
  const messageData = data.data;
  switch (data.eventType) {
    case GeneralEventTypes.GetToken:
      sendToken && sendToken();
      break;
    case GeneralEventTypes.GetCookie:
      StorageManager.get(messageData.key).then((cookie) => {
        sendMFEmessage &&
          sendMFEmessage(
            { key: messageData.key, value: cookie },
            GeneralEventTypes.GetCookie
          );
      });
      break;
    case GeneralEventTypes.SetCookie:
      //log in if a tokenInfo cookie was set
      switch (messageData.key) {
        case 'tokenInfo':
          if ((messageData.value as string) && setToken && setIsAuthenticated) {
            const stringifiedTokenInfo = messageData.value as string;
            const tokenInfoCookie: TokenInfo = JSON.parse(stringifiedTokenInfo);
            setToken(tokenInfoCookie);
            setIsAuthenticated(true);
          }
          break;
      }
      Platform.OS === 'web'
        ? StorageManager.set(messageData.key, messageData.value, {
            secure: true,
          })
        : StorageManager.set(messageData.key, messageData.value);
      break;
    case GeneralEventTypes.LoginEvent:
      trackLoginEvent && trackLoginEvent(messageData.loginType ?? '');
      setLogoutUrl && setLogoutUrl(messageData.logoutUrl);
      break;
    case GeneralEventTypes.GetAuthParams:
      if (setIsAuthMFELoaded) setIsAuthMFELoaded(true);
      break;
    case GeneralEventTypes.SendAuthParams: {
      const { loginUrl, client } = messageData;
      setRedirectUrl && setRedirectUrl(client.redirectUrl);
      setLoginUrl && setLoginUrl(loginUrl);
      //check url for code, depending on code found cause loginFromCookie Event or loginFromCode event
      let url = '';
      if (Platform.OS === 'web') {
        url = window.location.href;
      }
      const code = retrieveAuthCode(url, client.redirectUrl);
      if (!code) {
        //trigger login from Cookie Event
        StorageManager.get('tokenInfo').then((cookie) => {
          sendMFEmessage &&
            sendMFEmessage(
              { key: 'tokenInfo', value: cookie },
              GeneralEventTypes.GetCookie
            );
        });
        break;
      }
      //trigger login from code event
      sendMFEmessage &&
        sendMFEmessage(
          { code, tokenType: 'Okta' },
          GeneralEventTypes.LoginFromCode
        );
      break;
    }
    case GeneralEventTypes.Logout:
      logout && logout();
      break;
    case GeneralEventTypes.GoToUrl:
      //Won't work on mobile
      if (Platform.OS === 'web') {
        window.location.assign(messageData.url);
      } else {
        // we only goToUrl if logging out of the app
        setCookieLoginFailed && setCookieLoginFailed(true);
      }

      break;
    case GeneralEventTypes.OpenInNewTab:
      openInNewTab && openInNewTab(messageData.url);
      break;
    case GeneralEventTypes.Profile:
      goTo(StackLink.Profile);
      break;
    case GeneralEventTypes.PushNotification:
      if (Platform.OS === 'ios' || Platform.OS === 'android') {
        const updateStatusInput = updatePushNotificationStatusProps && {
          pushNotificationStatus: messageData,
          ...updatePushNotificationStatusProps,
        };
        updatePushNotificationStatus &&
          updateStatusInput &&
          updatePushNotificationStatus(updateStatusInput);
      }
      break;
    case GeneralEventTypes.ScrollTop:
      scrollTop?.();
      break;
    case GeneralEventTypes.CopyText:
      copyText?.(messageData);
      break;
    case GeneralEventTypes.VisitPlanUsage:
      goTo(PlanLink.Plan);
      break;
  }
};

const handleOverviewEvents = (
  data: WindowMessageData,
  {
    goTo,
    maximizeWidget,
    sendMVClientInfo,
    openInNewTab,
  }: OverviewEventHandlerMethods,
  handleInvalidToken: (data: WindowMessageData) => void
) => {
  const messageData = data.data;
  if (data.eventType === BillingEventTypes.PayBill) {
    goTo(PayBillLink.PayBill);
  }
  switch (data.eventType) {
    case UsageEventTypes.ChatWithUs:
      if (Platform.OS === 'web') {
        maximizeWidget && maximizeWidget();
      } else {
        goTo(StackLink.LiveChat);
      }
      break;
    case UsageEventTypes.FAQSpecificQuestion:
      if (messageData) {
        goTo(HelpLink.Main, {
          params: {
            section: messageData.section,
            question: messageData.question,
          },
        });
      }
      break;
    case UsageEventTypes.InvalidToken:
      handleInvalidToken(data);
      break;
    case UsageEventTypes.Loaded:
      sendMVClientInfo && sendMVClientInfo();
      break;
    case UsageEventTypes.VisitPlanUsage:
      goTo(PlanLink.Plan);
      break;
    case BillingEventTypes.ChangeBillDate:
      handleChangeBillDateNavigation(goTo);
      break;
    case UsageEventTypes.OpenInNewTab:
      openInNewTab && openInNewTab(messageData.url);
      break;
    case UsageEventTypes.ChangePlan:
      goTo(`${PlanLink.Plan}/${PlanLink.ChoosePlanFilter}`);
      break;
  }
};

export const microFrontendMessageHandler = (
  eventHandlerMethods: MicroFrontendEventHandlerMethods
): ((data: WindowMessageData) => void) => {
  const handleInvalidToken = (data: WindowMessageData): void => {
    const message = data?.data?.message || 'Invalid User Token';
    console.error(message);
    eventHandlerMethods.logout();
  };
  const handleMessage = (data: WindowMessageData): void => {
    if (
      data.domain === MicroFrontendDomains.General ||
      data.domain === MicroFrontendDomains.Profile
    ) {
      handleGeneralEvents(data, eventHandlerMethods);
    }
    if (data.domain === MicroFrontendDomains.Usage) {
      handleUsageEvents(
        data,
        eventHandlerMethods as UsageEventHandlerMethods,
        handleInvalidToken
      );
    }
    if (
      data.domain === MicroFrontendDomains.Overview ||
      data.domain === MicroFrontendDomains.Billing
    ) {
      handleOverviewEvents(
        data,
        eventHandlerMethods as OverviewEventHandlerMethods,
        handleInvalidToken
      );
    }
  };

  return handleMessage;
};

export const authMFEMessageHandler = (
  eventHandlerMethods: MicroFrontendEventHandlerMethods
): ((data: WindowMessageData) => void) => {
  const handleMessage = (data: WindowMessageData): void => {
    if (data.domain === MicroFrontendDomains.General) {
      handleGeneralEvents(data, eventHandlerMethods);
    }
  };
  return handleMessage;
};
