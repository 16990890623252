import { ADDON_PRODUCT_KIND } from '@mfe/shared/util';

export const getTermsAndConditions = (kind: string) => {
  if (!kind) return null;

  switch (kind) {
    case ADDON_PRODUCT_KIND.easyCare:
      return 'EasyCare';
    case ADDON_PRODUCT_KIND.voice:
      return 'Voice';
    case ADDON_PRODUCT_KIND.shield:
      return 'ViasatShieldPremium';
    case ADDON_PRODUCT_KIND.staticIp:
      return 'StaticIp';
    case ADDON_PRODUCT_KIND.officeHours:
      return 'OfficeHours';
    default:
      return '';
  }
};
