import React from 'react';
import { BaseCard, BaseCardType } from '../BaseCard';
import { isDefined } from '../../../utils';

export type SvgType = Omit<BaseCardType, 'children'> & {
  svg: React.ReactNode;
  children?: React.ReactNode;
};

export class Svg extends React.Component<SvgType> {
  override render() {
    const { svg, children, ...props } = this.props;

    return (
      <BaseCard {...props}>
        {svg}
        {isDefined(children) && children}
      </BaseCard>
    );
  }
}
