import { TypePolicies } from '@apollo/client';

export const BillingTypePolicies: TypePolicies = {
  BillHistory: {
    keyFields: [],
    fields: {
      payments: {
        keyArgs: false,
      },
    },
  },
  GetBillingAccountPayload: {
    keyFields: [],
    fields: {
      currency: {
        merge: true,
      },
    },
  },
  GetPaymentMethodPayload: {
    keyFields: [],
  },
  PaymentEvent: {
    keyFields: false,
  },
  GetAvailableBillCycleDaysPayload: {
    keyFields: [],
  },
};
