import {
  Accordion,
  AccordionGroup,
  Button,
  StandAloneLink,
  Txt,
} from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AccountType, Locale } from '@mfe/shared/schema-types';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { HelpLink } from '@mfe/legacy/mv/utils/Navigation';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import useNavigate from '@mfe/services/navigation';

import styles from './home.module.scss';

type Link = {
  label: string;
  url: string;
};

type Question = {
  header: string;
  description: string[];
  links?: Link[];
};

const CommonQuestions = () => {
  const dispatch = useDispatch();
  const { goTo } = useNavigate();
  const { t } = useTranslation(['CommonQuestionsUpdated', 'Global']);

  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const translationsLocale =
    userLocale === Locale.PtBr ? Locale.EnUs : userLocale;

  // For US, displayed questions are the same for residential and SMB
  const questions: Question[] = t(
    `CommonQuestionsUpdated:Billing.${translationsLocale}`,
    { returnObjects: true }
  );

  const navigateToFaq = (e: React.MouseEvent<HTMLElement>) => {
    const url = t('Global:SMBSupportLink');

    accountType === AccountType.Smb
      ? openLinkInNewTab(e, url)
      : goTo(HelpLink.Main, { section: 'Billing' });
  };

  const openLinkInNewTab = (e: React.MouseEvent<HTMLElement>, url: string) => {
    e.preventDefault();
    dispatch(openInNewTab(url));
  };

  return (
    <div className={styles['commonQuestionsWrapper']}>
      <Txt variant="heading5" mb="16px">
        {t('CommonQuestionsUpdated:title')}
      </Txt>
      <AccordionGroup singleExpanded={true} size="small">
        {questions.map(({ header, description, links }, questionKey) => (
          <Accordion title={header} key={questionKey}>
            <div className={styles['accordionDescription']}>
              {description?.map((line: string) => (
                <Txt variant="bodySmallRegular">{line}</Txt>
              ))}
              {links &&
                links?.map(({ label, url }, linkKey) => (
                  <StandAloneLink
                    href={url}
                    key={linkKey}
                    onClick={(e) => openLinkInNewTab(e, url)}
                  >
                    {label}
                  </StandAloneLink>
                ))}
            </div>
          </Accordion>
        ))}
      </AccordionGroup>
      {userLocale === Locale.EnUs && (
        <Button
          fluid
          variant="secondary"
          mt="16px"
          onClick={(e) => navigateToFaq(e)}
        >
          {t('CommonQuestionsUpdated:viewFaqs')}
        </Button>
      )}
    </div>
  );
};

export default CommonQuestions;
