import React from 'react';
import { useDispatch } from 'react-redux';
import { TouchableOpacity, Image } from 'react-native';

import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';

import { urls } from '../../configs/url';

import Appstore from './Appstore.png';
import Playstore from './Playstore.png';

type OS = 'Apple' | 'Google';

interface DownloadAppIconProps {
  os: OS;
  width?: number;
  overrideLocation?: string | null;
}

export const DownloadAppIcon = ({
  os,
  width = 150,
  overrideLocation,
}: DownloadAppIconProps): JSX.Element => {
  const dispatch = useDispatch();
  const isApple = os === 'Apple';
  // Dynamically scale .png's based on Dev input. (Unfortunately the aspect ratios are different between os)
  const height = width / (isApple ? 2.4 : 2.3);

  const handleURL = (): void => {
    overrideLocation
      ? dispatch(openInNewTab(overrideLocation))
      : dispatch(openInNewTab(isApple ? urls.appStore : urls.playStore));
  };

  return (
    <TouchableOpacity onPress={(): void => handleURL()}>
      <Image
        source={{ uri: isApple ? Appstore : Playstore }}
        style={{ width, height }}
        resizeMode="contain"
      />
    </TouchableOpacity>
  );
};
