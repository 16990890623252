import { BOLETO } from '@mfe/shared/util';
import type { AutopayMethod } from '@mfe/shared/schema-types';
import { RadioButton, Txt } from '@vst/beam';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CardLogo } from '@mfe/shared/components';

import styles from '../vpp.module.scss';

export enum MethodType {
  'pof' = 'payment-on-file',
  'other' = 'other',
}

interface Props {
  autoPay?: AutopayMethod | null;
  methodType: MethodType;
  setMethodType: React.Dispatch<React.SetStateAction<MethodType>>;
}

const Label = ({ autoPay }: Pick<Props, 'autoPay'>) => {
  const { t } = useTranslation('VPP');

  return (
    <>
      <CardLogo name={autoPay?.paymentMethod} width="70px" />
      <Txt variant="bodySmallRegular">
        {t('paymentMethod', {
          paymentMethod: autoPay?.paymentMethod,
          last4Digits: autoPay?.last4Digits,
          count: autoPay?.last4Digits?.length,
        })}
      </Txt>
    </>
  );
};

const PaymentMethod = ({ autoPay, methodType, setMethodType }: Props) => {
  return (
    <div className={styles['radioGroupContainer']} data-cy="payment-method">
      <RadioButton
        label={<Label autoPay={autoPay} />}
        checked={methodType === MethodType.pof}
        className={styles['radioButton']}
        onChange={() => setMethodType(MethodType.pof)}
        disabled={autoPay?.paymentMethod === BOLETO}
      />
    </div>
  );
};

export default PaymentMethod;
