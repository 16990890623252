import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { InlineLink, Txt } from '@vst/beam';

import { CardLogo } from '@mfe/shared/components';
import { EventTypes } from '@mfe/services/window-messages';
import { getAutopayTranslationContext } from '@mfe/shared/util';
import { postOverviewMessage } from '@mfe/to-be-migrated/redux/utils';
import { selectBillingInfo } from '@mfe/to-be-migrated/redux/billingInfo';
import { Route, setBillingNav } from '@mfe/to-be-migrated/redux/billingNav';

import styles from './autopay-method.module.scss';

export const OverviewAutoPayMethod = (): JSX.Element => {
  const { t } = useTranslation('Overview', { keyPrefix: 'balanceCard' });
  const dispatch = useDispatch();
  const {
    billingInfo: { autoPay },
  } = useSelector(selectBillingInfo);

  const navigateToAddPaymentMethod = (e: any) => {
    e.preventDefault();

    dispatch(postOverviewMessage({ eventType: EventTypes.PayBill }));
    dispatch(setBillingNav({ route: Route.Autopay }));
  };

  return (
    <div id="autoPay" className={styles['container']}>
      <CardLogo
        width="36px"
        name={autoPay?.isExpired ? null : autoPay?.paymentMethod}
      />

      <Txt variant="smallRegular" ml="16px" data-cy="overview-autoPay">
        <Trans
          ns="Summary"
          i18nKey="overviewPage.autoPay"
          values={getAutopayTranslationContext(autoPay, true)}
        />{' '}
        {!autoPay && (
          <InlineLink
            variant="secondary"
            theme="dark"
            href=""
            onClick={navigateToAddPaymentMethod}
            data-cy="enroll"
          >
            {t('enrollLink')}
          </InlineLink>
        )}
      </Txt>
    </div>
  );
};
