import { InlineLink } from '@vst/beam';
import { ModalTypeEnum, ModalComponent } from '@mfe/shared/components';
import { useScreenResolution } from '@mfe/shared/util';
import Outcome from './Outcome';
import { useTranslation } from 'react-i18next';

interface DuplicateSubscriberErrorProps {
  goBackToOverviewPage: () => void;
  closeModal: () => void;
}
export const DuplicateSubscriberError = (
  props: DuplicateSubscriberErrorProps
) => {
  const { goBackToOverviewPage, closeModal } = props;
  const { t } = useTranslation('ACP', { keyPrefix: 'Error' });
  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;

  const hrefLink = `tel:${t(
    'duplicateSubscriber.secondaryDescription.phoneNumber'
  )}`;

  const callUs = (
    <>
      {t('duplicateSubscriber.secondaryDescription.first')}{' '}
      <InlineLink
        href={hrefLink}
        style={{ textDecoration: 'none' }}
        data-cy="acp-call-us-duplicate-subscriber"
      >
        {t('duplicateSubscriber.secondaryDescription.phoneNumber')}
      </InlineLink>
      .
    </>
  );

  const renderOutcome = () => {
    return (
      <Outcome
        data-cy="acp-duplicate-subscriber-error"
        title={t('duplicateSubscriber.title')}
        text={t(`duplicateSubscriber.description`)}
        secondaryText={callUs}
        primaryButton={{
          label: t('duplicateSubscriber.buttons.backToMyViasat'),
          handleClick: goBackToOverviewPage,
        }}
        hasCloseButton
        closeButton={{ handleClick: closeModal }}
      />
    );
  };

  return isMobile ? (
    renderOutcome()
  ) : (
    <ModalComponent type={ModalTypeEnum.ACP}>{renderOutcome()}</ModalComponent>
  );
};
