import React from 'react';
import { Spinner } from '@vst/beam';

import styles from './vpp.module.scss';

const DifferentMethod = ({
  children,
  frameReady,
}: React.PropsWithChildren<{ frameReady: boolean }>) => {
  return (
    <div className={styles['differentMethodContent']}>
      {!frameReady && (
        <div className={styles['vppFrameLoading']}>
          <Spinner />
        </div>
      )}

      <div style={{ display: frameReady ? 'flex' : 'none' }}>{children}</div>
    </div>
  );
};

export default DifferentMethod;
