import { ModalTypeEnum, ModalComponent } from '@mfe/shared/components';
import Outcome from './Outcome';
import { InlineLink } from '@vst/beam';
import { useScreenResolution } from '@mfe/shared/util';
import { useTranslation } from 'react-i18next';

interface ContactUsProps {
  goBackToOverviewPage: () => void;
  closeModal: () => void;
}
export const ContactUs = (props: ContactUsProps) => {
  const { goBackToOverviewPage, closeModal } = props;
  const { t } = useTranslation('ACP', { keyPrefix: 'Error' });
  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;

  const hrefLink = `tel:${t('contactUs.description.phoneNumber')}`;

  const callUs = (
    <>
      {t('contactUs.description.first')}{' '}
      <InlineLink
        data-cy="acp-call-number"
        href={hrefLink}
        style={{
          textDecoration: 'none',
          display: isMobile ? 'inline' : 'block',
        }}
      >
        {t('contactUs.description.phoneNumber')}
      </InlineLink>
    </>
  );

  const renderOutcome = () => {
    return (
      <Outcome
        data-cy="acp-contactus-popup"
        title={t('contactUs.title')}
        text={callUs}
        primaryButton={{
          label: t('contactUs.buttons.backToMyViasat'),
          handleClick: goBackToOverviewPage,
        }}
        secondaryButton={{
          label: t('contactUs.buttons.closeModal'),
          handleClick: closeModal,
        }}
        hasCloseButton
        closeButton={{ handleClick: closeModal }}
      />
    );
  };

  return isMobile ? (
    renderOutcome()
  ) : (
    <ModalComponent type={ModalTypeEnum.ACP}>{renderOutcome()}</ModalComponent>
  );
};
