// use the style guide when adding new colors
// http://ux.viasat.io/wp-content/uploads/Documents/PDFs/Viasat_Digital_Style_Guide_v2.2.pdf

export enum Color {
  blue100 = '#CCF0FF',
  blue200 = '#9BE1FF',
  blue300 = '#64CEFB',
  blue400 = '#14B9FF',
  blue500 = '#009DE0',
  blue600 = '#006EAD',
  blue700 = '#00528C',
  blue800 = '#003F73',
  gray100 = '#F2F5F8',
  gray200 = '#DEE4E8',
  gray300 = '#C3CDD5',
  gray400 = '#9FAFBC',
  gray500 = '#677A89',
  gray600 = '#465967',
  gray700 = '#32424E',
  gray800 = '#202E39',
  gray900 = '#141D24',
  green100 = '#DDFA96',
  green200 = '#C5F042',
  green300 = '#A4D810',
  green400 = '#7DBE00',
  green500 = '#5EA215',
  green600 = '#358000',
  green700 = '#1D5700',
  teal100 = '#C6FCFF',
  teal200 = '#8BF6FF',
  teal300 = '#18ECFC',
  teal400 = '#00C2DE',
  teal500 = '#00A2C0',
  teal600 = '#00819D',
  teal700 = '#006A85',
  teal800 = '#005B75',
  yellow100 = '#FFE9B3',
  yellow200 = '#FFDB7D',
  yellow300 = '#FFB029',
  yellow400 = '#EF8201',
  red100 = '#FFE5DF',
  red200 = '#CF4520',
  red300 = '#A82200',
  red400 = '#86090F',
  red500 = '#FF4747',
  pink100 = '#FCCFEF',
  pink200 = '#F9AAE4',
  pink300 = '#EA7BCE',
  pink400 = '#D147AF',
  pink500 = '#AD258B',
  pink600 = '#7D1364',
  pink700 = '#570A46',
  purple100 = '#D9D1FA',
  purple200 = '#BAA8F0',
  purple300 = '#9678F2',
  purple400 = '#724AE8',
  purple500 = '#562BDA',
  purple600 = '#3A1B98',
  purple700 = '#29126D',
  white = '#FFFFFF',
  black = '#000000',
  brown = '#965202',
  blue = '#009FD6',
  nonStandardTeal = '#F3FFFE',
  redFFEDE9 = '#FFEDE9',
  yellowFFF5DD = '#FFF5DD',
  blueE8F8FF = '#E8F8FF',
  orange965202 = '#965202',
  green849d00 = '#849d00',
}
