import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { InlineLink, StandardModal } from '@vst/beam';

import {
  selectNetwork,
  setIsRebootModemModalOpen,
} from '@mfe/to-be-migrated/redux/network';
import useNavigate from '@mfe/services/navigation';
import { useScreenResolution } from '@mfe/shared/util';

import styles from './styles.module.scss';
import { selectConfig } from '@mfe/shared/redux/config';
import { Platform } from '@mfe/shared/schema-types';

const RebootModemModal = ({ redirectRoute }: { redirectRoute: string }) => {
  const { t } = useTranslation('Network', { keyPrefix: 'rebootModem' });

  const { platform } = useSelector(selectConfig);
  const isWeb = platform === Platform.Web;

  const { goTo } = useNavigate();
  const dispatch = useDispatch();

  const rebootModemHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    goTo(redirectRoute);
    dispatch(setIsRebootModemModalOpen(false));
  };

  const closeRebootModemModal = () => {
    dispatch(setIsRebootModemModalOpen(false));
  };

  const { isRebootModemModalOpen, isModemOnline } = useSelector(selectNetwork);

  const { isExtraSmall, isSmall } = useScreenResolution();

  const isMobile = isExtraSmall || isSmall;

  const modalContentClass = isWeb
    ? styles['modalContent']
    : `${styles['modalContent']} ${styles['mobilePadding']}`;

  const phoneHref = `tel:${t(
    'offlineModemModal.callCustomerSupportPhoneNumber'
  )}`;

  const callUs = (
    <>
      {t('offlineModemModal.descriptionFirst')}
      {isMobile ? (
        <InlineLink href={phoneHref} data-cy="acp-call-service-unavailable">
          {t('offlineModemModal.callCustomerSupportPhoneNumberDisplay')}
        </InlineLink>
      ) : (
        t('offlineModemModal.callCustomerSupportPhoneNumberDisplay')
      )}
      {t('offlineModemModal.descriptionLast')}
    </>
  );

  if (!isModemOnline) {
    return (
      <StandardModal
        isOpen={isRebootModemModalOpen}
        onClose={closeRebootModemModal}
        size="medium"
        header={t('offlineModemModal.title')}
        actions={{
          primary: {
            label: t('offlineModemModal.closeButton'),
            onClick: closeRebootModemModal,
          },
        }}
        surfaceProps={{ className: modalContentClass }}
      >
        {callUs}
      </StandardModal>
    );
  }

  return (
    <StandardModal
      isOpen={isRebootModemModalOpen}
      onClose={closeRebootModemModal}
      size="medium"
      header={t('onlineModemModal.title')}
      actions={{
        primary: {
          label: t('onlineModemModal.rebootButton'),
          onClick: rebootModemHandler,
        },
        secondary: {
          label: t('onlineModemModal.cancelButton'),
          onClick: closeRebootModemModal,
        },
      }}
      surfaceProps={{ className: modalContentClass }}
    >
      {t('onlineModemModal.description')}
    </StandardModal>
  );
};

export default RebootModemModal;
