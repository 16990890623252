import React, { Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import FullPageLoading from './FullPageLoading';

const RegistrationProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const { pathname } = useLocation();

  if (pathname.toLocaleLowerCase().includes('/registration')) {
    const Registration = React.lazy(
      () =>
        import('@mfe/to-be-migrated/shared-platform-react/pages/Registration')
    );
    const BrazilRegistration = React.lazy(
      () =>
        import(
          '@mfe/to-be-migrated/shared-platform-react/pages/Registration/br'
        )
    );

    const ItalyRegistration = React.lazy(
      () =>
        import(
          '@mfe/to-be-migrated/shared-platform-react/pages/Registration/it'
        )
    );

    const getRegistrationPage = () => {
      switch (pathname.toLocaleLowerCase()) {
        case '/registration/br':
        case '/br/registration':
          return <BrazilRegistration />;
        case '/it/registration':
          return <ItalyRegistration />;
        default:
          return <Registration />;
      }
    };

    return (
      <Suspense fallback={<FullPageLoading />}>
        {getRegistrationPage()}
      </Suspense>
    );
  }

  return children;
};

export default RegistrationProvider;
