import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, IllustrativeIcon, Surface, Txt } from '@vst/beam';
import { CheckMark } from '@vst/beam-icons/illustrative-icons';

import { ModemAlert } from '../ModemAlert/ModemAlert';
import { Container } from '@mfe/shared/components';
import { useScreenResolution } from '@mfe/shared/util';
import {
  Alert,
  AlertAction,
  setAlerts,
} from '@mfe/to-be-migrated/redux/alerts';
import { NotificationLevel } from '@mfe/shared/schema-types';

import styles from './ConfirmationWithoutEquipmentChange.module.scss';
import { NavigationHandlers } from '../../types';
import { selectChangePlan } from '@mfe/to-be-migrated/redux/changePlan';

export const ConfirmationWithoutEquipmentChange = ({
  navigateToOverview,
}: Pick<NavigationHandlers, 'navigateToOverview'>) => {
  const dispatch = useDispatch();
  const {
    cart: { hasEquipmentShipping },
  } = useSelector(selectChangePlan);

  const { t } = useTranslation(['NewChangePlan', 'Alerts']);

  const { isLarge, isExtraLarge } = useScreenResolution();
  const isDesktop = isLarge || isExtraLarge;
  const description = hasEquipmentShipping
    ? t('NewChangePlan:orderConfirmation.withoutEquipmentChange.altDescription')
    : t('NewChangePlan:orderConfirmation.withoutEquipmentChange.description');
  const goToOverview = () => {
    const alerts: Alert[] = [
      {
        level: NotificationLevel.Success,
        title: t('Alerts:changePlan.orderAlert.title'),
        caption: t('Alerts:changePlan.orderAlert.caption'),
        button: {
          action: AlertAction.Refresh,
          label: t('Alerts:changePlan.orderAlert.button.text'),
        },
      },
    ];

    dispatch(setAlerts(alerts));
    navigateToOverview();
  };

  return (
    <Container>
      <Surface
        className={styles['container']}
        my={{ xs: undefined, sm: '40px' }}
        px={{ xs: '16px', sm: '24px', md: '40px' }}
        radius="16px"
      >
        <IllustrativeIcon
          data-cy="CheckMark-Icon"
          name="CheckMark"
          icon={CheckMark}
          color="green_600"
          size={95}
        />
        <Txt
          data-cy="orderConfirmation-title"
          mt="48px"
          mb="12px"
          variant="bodyLargeBold"
        >
          {t('NewChangePlan:orderConfirmation.withoutEquipmentChange.title')}
        </Txt>
        <Txt
          data-cy="orderConfirmation-description"
          mb="40px"
          variant="bodyLargeRegular"
          color="subtle"
        >
          {description}
        </Txt>
        <div data-cy="orderConfirmation-Alert">
          <ModemAlert />
        </div>
        <Button
          className={styles['backToMyViasat']}
          data-cy="orderConfirmation-button"
          fluid
          onClick={goToOverview}
          mt={isDesktop ? undefined : '40px'}
        >
          {t('NewChangePlan:orderConfirmation.withoutEquipmentChange.button')}
        </Button>
      </Surface>
    </Container>
  );
};
