import styles from './styles.module.scss';

const InvoiceHistoryLoading = (): JSX.Element => {
  return (
    <div className={styles['card']}>
      <div className={styles['billingStatement']} />
      <div className={styles['tabTitles']} />

      <div className={styles['solidLine']} />

      <div className={styles['detail']} />
      <div className={styles['payment']} />
      <div className={styles['date']} />

      <div className={styles['solidLine']} />

      <div className={styles['detail']} />
      <div className={styles['payment']} />
      <div className={styles['date']} />

      <div className={styles['solidLine']} />

      <div className={styles['detail']} />
      <div className={styles['payment']} />
      <div className={styles['date']} />
    </div>
  );
};

export default InvoiceHistoryLoading;
