import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectBillingAccount } from '@mfe/to-be-migrated/redux/billingInfo';
import { selectUpdateBillCycle } from '@mfe/to-be-migrated/redux/updateBillCycle';

import Error from './outcome/error';
import Success from './outcome/success';
import { Loading } from './loading-state';
import GradientCard from './gradient-card';
import { ChangeBillDateForm } from './change-bill-date-form';

export const ChangeBillDate = ({
  navigateToPreviousPage,
}: {
  navigateToPreviousPage: () => void;
}) => {
  const handleCancel = () => navigateToPreviousPage();

  const { days, loading, error, newBillCycleDay } = useSelector(
    selectUpdateBillCycle
  );
  const billingAccountState = useSelector(selectBillingAccount);

  React.useEffect(() => {
    if (!days || !billingAccountState?.billingCycleDayOfMonth) {
      navigateToPreviousPage();
    }
  }, []);

  React.useEffect(() => {
    const body = document.body;

    if (body.classList.contains('global--limit-height')) {
      body.classList.remove('global--limit-height');
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error navigateToBilling={navigateToPreviousPage} />;
  }

  if (newBillCycleDay) {
    return <Success navigateToBilling={navigateToPreviousPage} />;
  }

  return (
    <GradientCard>
      <ChangeBillDateForm handleCancel={handleCancel} />
    </GradientCard>
  );
};
