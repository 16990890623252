import { useTranslation } from 'react-i18next';
import { Icon, Tooltip, Txt, colors } from '@vst/beam';
import {
  SpeedOutlined,
  UploadOutlined,
  AddOutlined,
  HelpOutline,
} from '@vst/beam-icons/icons';

import { hasOfficeHoursTitleAddOns } from '@mfe/shared/util';

import styles from '../BodyContent.module.scss';
import loadingStyles from './Loading.module.scss';

const Loading = () => (
  <>
    <div className={loadingStyles['smallDetail']} />
    <div className={loadingStyles['smallDetail']} />
    <div className={loadingStyles['smallDetail']} />
    <div className={loadingStyles['largeDetail']} />
  </>
);

type Props = {
  loading: boolean;
  isGoodFor: string;
  bestFor: string;
  titleAddOns: string;
  dataCap: string;
  uploadSpeed: string;
};

export const Characteristics = ({
  loading,
  isGoodFor,
  bestFor,
  titleAddOns,
  dataCap,
  uploadSpeed,
}: Props) => {
  const { t } = useTranslation(['NewProfile', 'Global']);

  const showGoodFor = isGoodFor?.trim().length > 0;
  const showBestFor = bestFor?.trim().length > 0;
  const showPlanRecommendation = showGoodFor || showBestFor;
  const showTitleAddOnsTooltip = hasOfficeHoursTitleAddOns(titleAddOns);

  const tooltip = showTitleAddOnsTooltip ? (
    <Tooltip
      position="top"
      bodyText={t('Global:officeHours_titleAddOns_tooltip')}
      data-cy="tooltip"
      style={{ textAlign: 'left' }}
    >
      <Icon
        icon={HelpOutline}
        data-cy="tooltip-icon"
        size={16}
        color="gray_600"
      />
    </Tooltip>
  ) : null;

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {dataCap && (
        <div className={styles['text-spacing']}>
          <Icon icon={SpeedOutlined} size={20} color="gray_600" />
          <Txt variant={'smallBold'} ml="8px" mr="4px">
            {t('InternetPlan.dataCap', { dataCap })}
          </Txt>
          <Txt variant={'smallRegular'}>{t('InternetPlan.highSpeedData')}</Txt>
        </div>
      )}

      {uploadSpeed && (
        <div className={styles['text-spacing']}>
          <Icon icon={UploadOutlined} size={20} color="gray_600" />
          <Txt variant={'smallRegular'} ml="8px" mr="4px">
            {t('InternetPlan.uploadSpeedUpTo')}
          </Txt>
          <Txt variant="smallBold">
            {t('InternetPlan.uploadSpeed', { uploadSpeed })}
          </Txt>
        </div>
      )}

      {titleAddOns && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            color: colors.teal[600],
          }}
        >
          <Icon icon={AddOutlined} size={20} color="inherit" />
          <Txt variant="smallRegular" ml="8px" mr="4px" color="inherit">
            {titleAddOns}
          </Txt>
          {tooltip}
        </div>
      )}

      {showPlanRecommendation && (
        <div>
          <Txt variant={'smallBold'}>
            {t('InternetPlan.planRecommendation')}
          </Txt>
          {showGoodFor && <Txt variant="smallRegular">{isGoodFor}.</Txt>}
          {showBestFor && (
            <Txt variant="smallRegular" ml="4px">
              {t('InternetPlan.planBestFor')}
              {bestFor}.
            </Txt>
          )}
        </div>
      )}
    </>
  );
};
