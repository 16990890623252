import { useEffect, useMemo } from 'react';
import { Button, StandAloneLink, Surface, colors } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getAvailableDates,
  selectScheduleInstallation,
} from '@mfe/to-be-migrated/redux/scheduleInstallation';
import {
  selectChangePlan,
  submitOrder,
  selectChosenPlan,
  setChangePlanStep,
  ChangePlanSteps,
  setError,
} from '@mfe/to-be-migrated/redux/changePlan';
import {
  AlertAction,
  HeaderWithBackNavigation,
  ScheduleInstallationContext,
  SelectedDate,
  useScheduleInstallation,
} from '@mfe/shared/components';
import { useScreenResolution } from '@mfe/shared/util';
import { ScheduleInput } from '@mfe/shared/schema-types';

import { CollapsibleCartSummary } from '../OrderReview/CollapsibleCartSummary';
import { CartSummaryContainer } from '../OrderReview/CartSummaryContainer';
import { CartSummary } from '../CartSummary';
import { Form } from './form';
import NoAppointments from './no-appointments';

import styles from './styles.module.scss';

interface ScheduleInstallationProps {
  navigateToPreviousPage: () => void;
}

export const ScheduleInstallation = ({
  navigateToPreviousPage,
}: ScheduleInstallationProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('NewChangePlan');

  const scheduleInstallationProps = useScheduleInstallation();
  const {
    setShowAlert,
    selectedDate,
    selectedInterval,
    textInput,
    phoneNumberInput,
    textAreaInput,
  } = scheduleInstallationProps;

  const validationMessageType = useMemo(
    () => computeValidationMessage(selectedDate, selectedInterval),
    [selectedDate, selectedInterval]
  );

  const { isExtraSmall, isSmall, isMedium } = useScreenResolution();
  const isMobile = isSmall || isExtraSmall;
  const isMobileOrTablet = isMobile || isMedium;

  const {
    cart: { id: cartId },
    requiresWorkOrder,
    loading: changePlanLoading,
    error: changePlanError,
  } = useSelector(selectChangePlan);

  const {
    loading: availableDatesLoading,
    availableDates,
    error: availableDatesError,
  } = useSelector(selectScheduleInstallation);

  const loading =
    (availableDatesLoading && !changePlanError) || changePlanLoading;
  const selectedPlan = useSelector(selectChosenPlan);

  const isAvailableDatesObjectEmpty =
    availableDates && Object.keys(availableDates).length === 0;
  const shouldSkipScheduleAppointment =
    isAvailableDatesObjectEmpty || availableDatesError || changePlanError;

  useEffect(() => {
    dispatch(getAvailableDates({ cartId, refetch: true }));
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setShowAlert(true);

    if (
      validationMessageType !== AlertAction.DateAndTimeConfirmed &&
      Object.keys(availableDates ?? {}).length > 0
    ) {
      return;
    }
    placeOrder();
  };

  const placeOrder = () => {
    const content = `${textInput} ${phoneNumberInput} ${textAreaInput}` ?? '';

    dispatch(
      submitOrder({
        notes: content,
        appointment: {
          endTime: selectedInterval?.to ?? '',
          startTime: selectedInterval?.from ?? '',
        },
      })
    );
  };

  const goToPlanSelection = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(setError(null));
    dispatch(setChangePlanStep(ChangePlanSteps.PLAN_SELECTION));
  };

  const goToPlanReview = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(setChangePlanStep(ChangePlanSteps.ORDER_REVIEW));
    dispatch(setError(null));
  };

  const smallScreenLoadingStyles =
    isMobile && loading
      ? {
          marginTop: 0,
          borderTop: `1px solid ${colors['gray'][200]}`,
        }
      : {};

  return (
    <ScheduleInstallationContext.Provider value={scheduleInstallationProps}>
      <Surface
        style={{ flex: 1, maxWidth: 1248, margin: '0 auto 48px' }}
        variant="secondary"
      >
        {isMobileOrTablet && (
          <CollapsibleCartSummary
            selectedPlan={selectedPlan}
            handleBackNavigation={goToPlanSelection}
          />
        )}

        <HeaderWithBackNavigation
          title={t('scheduleInstallation.title')}
          goBackLabel={t('scheduleInstallation.goBack')}
          handleBackNavigation={goToPlanReview}
          additionalClassName={styles['schedule-install-page-header']}
          style={smallScreenLoadingStyles}
        />
        <div className={styles['page-content']}>
          <Surface className={styles['form-container']} radius="16px">
            {shouldSkipScheduleAppointment ? (
              <NoAppointments handleBackNavigation={goToPlanReview} />
            ) : (
              <Form
                validationMessageType={validationMessageType}
                handleSubmit={handleSubmit}
                handleBackNavigation={goToPlanReview}
              />
            )}
          </Surface>

          {isMobileOrTablet ? (
            <CartSummaryContainer>
              <CartSummary
                selectedPlan={selectedPlan}
                handleBackNavigation={goToPlanSelection}
                requiresWorkOrder={requiresWorkOrder}
              />

              <hr style={{ marginTop: '24px' }} />

              <div
                data-cy="submit-section"
                className={styles['mobile-submit-section']}
              >
                <Button
                  disabled={loading}
                  form="schedule-installation-form"
                  type="submit"
                  onClick={() => {
                    if (shouldSkipScheduleAppointment) {
                      placeOrder();
                      return;
                    }

                    setShowAlert(true);
                  }}
                  data-cy="place-order-button"
                >
                  {t('orderReview.cartDetails.placeOrderButton')}
                </Button>
                <StandAloneLink
                  data-cy="back-to-plan-review-link"
                  onClick={goToPlanReview}
                  href="#"
                  underline={true}
                  linkSize="medium"
                >
                  {t('scheduleInstallation.backToPlanReview')}
                </StandAloneLink>
              </div>
            </CartSummaryContainer>
          ) : (
            <CartSummary
              selectedPlan={selectedPlan}
              handleBackNavigation={goToPlanSelection}
              requiresWorkOrder={requiresWorkOrder}
            />
          )}
        </div>
      </Surface>
    </ScheduleInstallationContext.Provider>
  );
};

function computeValidationMessage(
  selectedDate: SelectedDate,
  selectedInterval: ScheduleInput | null
) {
  if (!selectedDate.day) {
    return AlertAction.DateAndTimeRequired;
  }

  if (!selectedInterval?.from) {
    return AlertAction.TimeRequired;
  }

  return AlertAction.DateAndTimeConfirmed;
}
