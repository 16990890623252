import { useScreenResolution } from '@mfe/shared/util';
import { Txt } from '@vst/beam';
import styles from './banner.module.scss';
import { useTranslation } from 'react-i18next';

export const Banner = () => {
  const { t } = useTranslation('StreamOnPromo');
  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobileSize = isSmall || isExtraSmall;
  return (
    <div className={styles['imageContainer']}>
      <Txt variant={isMobileSize ? 'subHeading1' : 'display3'} color="inherit">
        {t('Banner.title')}
      </Txt>
      <Txt
        variant={isMobileSize ? 'smallRegular' : 'bodyLargeRegular'}
        color="inherit"
      >
        {t('Banner.description')}
      </Txt>
    </div>
  );
};
