import { Surface, Txt, Button } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import useNavigate from '@mfe/services/navigation';

export const NoAddons = (): JSX.Element => {
  const { t } = useTranslation(['Overview', 'Global']);
  const { goTo } = useNavigate();

  return (
    <Surface
      data-cy="no-addons-container"
      className={styles['no-addons-container']}
    >
      <Txt variant="bodySmallRegular">{t('Overview:addOns.noAddons')}</Txt>
      <Button
        variant="secondary"
        onClick={(e) => {
          e.preventDefault();
          goTo('ShopAddons');
        }}
      >
        {t('Overview:addOns.shopAddons')}
      </Button>
    </Surface>
  );
};
