import React from 'react';

const IllustrationBrazilPix = () => (
  <svg
    width="160"
    height="160"
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M138.662 8.24414H21.3385C13.1023 8.24414 6.42566 14.9208 6.42566 23.1569V126.799C6.42566 135.035 13.1023 141.712 21.3385 141.712H138.662C146.898 141.712 153.574 135.035 153.574 126.799V23.1569C153.574 14.9208 146.898 8.24414 138.662 8.24414Z"
      fill="#518FFB"
    />
    <path
      d="M43.1121 25.3896H120.335C125.613 25.3896 129.898 29.6745 129.898 34.9529V151.756H33.549V34.9529C33.549 29.6745 37.8337 25.3896 43.1121 25.3896V25.3896Z"
      fill="#F8F9FA"
    />
    <path
      d="M33.4417 151.756V34.7055C33.4417 29.6223 37.5625 25.5015 42.6457 25.5015H120.586C125.67 25.5015 129.79 29.6223 129.79 34.7055V151.756"
      stroke="#272425"
      strokeWidth="4.677"
      strokeMiterlimit="10"
    />
    <path
      d="M54.8857 24.9385H107.155V29.6465C107.155 32.4817 104.853 34.7841 102.018 34.7841H60.0233C57.1881 34.7841 54.8857 32.4817 54.8857 29.6465V24.9385V24.9385Z"
      fill="#272425"
    />
    <path
      d="M31.1337 71.3496H30.3889C29.7721 71.3496 29.2721 71.8496 29.2721 72.4664V94.5064C29.2721 95.1232 29.7721 95.6232 30.3889 95.6232H31.1337C31.7505 95.6232 32.2505 95.1232 32.2505 94.5064V72.4664C32.2505 71.8496 31.7505 71.3496 31.1337 71.3496Z"
      fill="#272425"
    />
    <path
      d="M132.769 66.7178H132.768C131.946 66.7178 131.279 67.3843 131.279 68.2066V79.7282C131.279 80.5504 131.946 81.217 132.768 81.217H132.769C133.591 81.217 134.258 80.5504 134.258 79.7282V68.2066C134.258 67.3843 133.591 66.7178 132.769 66.7178Z"
      fill="#272425"
    />
    <path
      d="M132.769 88.3735H132.768C131.946 88.3735 131.279 89.0401 131.279 89.8623V101.384C131.279 102.206 131.946 102.873 132.768 102.873H132.769C133.591 102.873 134.258 102.206 134.258 101.384V89.8623C134.258 89.0401 133.591 88.3735 132.769 88.3735Z"
      fill="#272425"
    />
    <path
      d="M95.7648 87.1108C93.7808 87.1108 91.916 86.3388 90.5136 84.9364L82.9304 77.3532C82.3984 76.8196 81.4704 76.8212 80.9376 77.3532L73.3264 84.9644C71.924 86.366 70.0584 87.1388 68.0744 87.1388H66.58L76.184 96.7428C79.1832 99.742 84.0464 99.742 87.0464 96.7428L96.6784 87.1108H95.7648Z"
      fill="#69BAAB"
    />
    <path
      d="M68.0751 60.2886C70.0591 60.2886 71.9239 61.0614 73.3263 62.463L80.9375 70.075C81.4855 70.623 82.3807 70.6254 82.9303 70.075L90.5135 62.491C91.9159 61.0886 93.7815 60.3166 95.7655 60.3166H96.6791L87.0479 50.6854C84.0487 47.6862 79.1855 47.6862 76.1855 50.6854L66.5815 60.2894H68.0759L68.0751 60.2886Z"
      fill="#69BAAB"
    />
    <path
      d="M104.645 68.283L98.824 62.4622C98.696 62.5134 98.5568 62.5454 98.4104 62.5454H95.764C94.396 62.5454 93.0568 63.0998 92.0896 64.0678L84.5064 71.651C83.7968 72.3606 82.8648 72.7158 81.9328 72.7158C81.0008 72.7158 80.0688 72.3606 79.3592 71.6518L71.748 64.0406C70.7808 63.0734 69.4424 62.5182 68.0736 62.5182H64.82C64.6816 62.5182 64.5512 62.4854 64.4288 62.439L58.5856 68.2822C55.5864 71.2814 55.5864 76.1446 58.5856 79.1446L64.4288 84.9878C64.5512 84.9414 64.6816 84.9086 64.82 84.9086H68.0744C69.4424 84.9086 70.7816 84.3542 71.7488 83.3862L79.36 75.775C80.736 74.4006 83.1336 74.3998 84.508 75.775L92.0912 83.3574C93.0576 84.3254 94.3968 84.8798 95.7656 84.8798H98.4112C98.5576 84.8798 98.696 84.9118 98.8248 84.963L104.645 79.143C107.644 76.143 107.644 71.2806 104.645 68.2806V68.283Z"
      fill="#69BAAB"
    />
    <path
      d="M54.7064 137.644C54.4064 137.644 54.1624 137.401 54.1624 137.1V119.445C54.1624 115.902 57.0448 113.02 60.588 113.02L65.8016 113.028C69.3328 113.035 72.2056 115.913 72.2056 119.445V123.202C72.2056 126.745 69.3231 129.628 65.78 129.628H58.4143C58.1143 129.628 57.8703 129.385 57.8703 129.084C57.8703 128.783 58.1135 128.54 58.4143 128.54H65.78C68.7232 128.54 71.1184 126.145 71.1184 123.202V119.445C71.1184 116.512 68.732 114.121 65.7992 114.115L60.5872 114.107C57.6448 114.107 55.2495 116.501 55.2495 119.445V137.099C55.2495 137.399 55.0064 137.643 54.7056 137.643L54.7064 137.644ZM77.7935 129.707C77.4935 129.707 77.2495 129.464 77.2495 129.163V115.975C77.2495 114.944 76.4104 114.105 75.3792 114.105H73.1184C72.8184 114.105 72.5744 113.861 72.5744 113.561C72.5744 113.26 72.8176 113.017 73.1184 113.017H75.3792C77.0096 113.017 78.3368 114.344 78.3368 115.974V129.162C78.3368 129.462 78.0935 129.706 77.7927 129.706L77.7935 129.707Z"
      fill="#919396"
    />
    <path
      d="M77.3087 111.517L76.2831 110.492C76.0287 110.237 76.0287 109.825 76.2831 109.57L77.3079 108.545C77.5623 108.29 77.9759 108.29 78.2303 108.545L79.2551 109.57C79.5095 109.825 79.5095 110.237 79.2551 110.492L78.2295 111.517C77.9751 111.772 77.5623 111.772 77.3079 111.517H77.3087Z"
      fill="#69BAAB"
    />
    <path
      d="M84.3743 129.591H82.1319C81.8319 129.591 81.5879 129.348 81.5879 129.047C81.5879 128.746 81.8311 128.503 82.1319 128.503H84.3743C85.3911 128.503 86.3471 128.107 87.0655 127.389L92.3103 122.144C93.2007 121.253 94.6487 121.253 95.5399 122.144L100.765 127.369C101.484 128.088 102.44 128.484 103.457 128.484H105.28C105.58 128.484 105.824 128.727 105.824 129.028C105.824 129.329 105.581 129.572 105.28 129.572H103.457C102.149 129.572 100.921 129.063 99.9967 128.139L94.7711 122.913C94.3047 122.447 93.5463 122.447 93.0799 122.913L87.8351 128.158C86.9111 129.082 85.6823 129.592 84.3751 129.592L84.3743 129.591ZM93.9247 121.121C93.3151 121.121 92.7415 120.884 92.3103 120.453L87.0655 115.208C86.3463 114.489 85.3911 114.093 84.3743 114.093H82.1319C81.8319 114.093 81.5879 113.85 81.5879 113.549C81.5879 113.249 81.8311 113.005 82.1319 113.005H84.3743C85.6815 113.005 86.9103 113.514 87.8343 114.439L93.0791 119.684C93.3055 119.91 93.6055 120.034 93.9247 120.034C94.2439 120.034 94.5447 119.909 94.7703 119.684L99.9959 114.458C100.92 113.534 102.149 113.025 103.456 113.025H105.279C105.579 113.025 105.822 113.269 105.822 113.569C105.822 113.869 105.579 114.113 105.278 114.113H103.455C102.439 114.113 101.483 114.508 100.764 115.227L95.5383 120.453C95.1071 120.884 94.5335 121.121 93.9239 121.121H93.9247Z"
      fill="#919396"
    />
    <path
      d="M59.1896 135.599C58.9456 135.599 58.6623 135.657 58.3815 135.722V136.798C58.5759 136.869 58.7976 136.903 59.0104 136.903C59.5496 136.903 59.8056 136.721 59.8056 136.246C59.8056 135.799 59.596 135.599 59.1888 135.599H59.1896ZM58.2 137.597V135.469H58.3479L58.3632 135.562C58.6128 135.504 58.9583 135.426 59.2047 135.426C59.4055 135.426 59.5968 135.457 59.7568 135.586C59.9416 135.737 60.0008 135.981 60.0008 136.246C60.0008 136.524 59.908 136.786 59.6552 136.931C59.4792 137.029 59.2416 137.069 59.0288 137.069C58.8096 137.069 58.5999 137.035 58.3815 136.971V137.597H58.2V137.597Z"
      fill="#919396"
    />
    <path
      d="M61.3544 135.593C60.8144 135.593 60.5744 135.762 60.5744 136.237C60.5744 136.697 60.812 136.904 61.3544 136.904C61.8912 136.904 62.1312 136.737 62.1312 136.262C62.1312 135.803 61.8936 135.593 61.3544 135.593V135.593ZM62.048 136.903C61.8696 137.033 61.6288 137.069 61.3544 137.069C61.0736 137.069 60.8336 137.029 60.6576 136.903C60.46 136.765 60.38 136.536 60.38 136.249C60.38 135.965 60.46 135.735 60.6576 135.593C60.8336 135.466 61.0744 135.426 61.3544 135.426C61.632 135.426 61.8696 135.466 62.048 135.593C62.2488 135.735 62.3256 135.966 62.3256 136.246C62.3256 136.533 62.2456 136.765 62.048 136.903Z"
      fill="#919396"
    />
    <path
      d="M64.6023 137.026L64.0007 135.735H63.9887L63.3967 137.026H63.2335L62.5919 135.469H62.7919L63.3255 136.776H63.3383L63.9175 135.469H64.0839L64.6791 136.776H64.6919L65.2127 135.469H65.4071L64.7655 137.026H64.6023Z"
      fill="#919396"
    />
    <path
      d="M66.5703 135.589C66.0711 135.589 65.9015 135.811 65.8703 136.132H67.2703C67.2551 135.777 67.0727 135.589 66.5703 135.589ZM66.5639 137.069C66.2647 137.069 66.0703 137.026 65.9159 136.897C65.7343 136.74 65.6727 136.512 65.6727 136.249C65.6727 136 65.7559 135.734 65.9623 135.583C66.1351 135.463 66.3479 135.426 66.5695 135.426C66.7703 135.426 67.0015 135.448 67.1927 135.577C67.4175 135.729 67.4607 135.993 67.4607 136.296H65.8631C65.8695 136.617 65.9743 136.901 66.5783 136.901C66.8655 136.901 67.1335 136.854 67.3831 136.811V136.974C67.1239 137.021 66.8375 137.07 66.5631 137.07L66.5639 137.069Z"
      fill="#919396"
    />
    <path
      d="M67.9831 137.026V135.469H68.1311L68.1463 135.562C68.4759 135.479 68.6303 135.426 68.9199 135.426H68.9415V135.599H68.8983C68.6543 135.599 68.5071 135.633 68.1647 135.722V137.026H67.9823H67.9831Z"
      fill="#919396"
    />
    <path
      d="M69.9992 135.589C69.5 135.589 69.3304 135.811 69.2992 136.132H70.6992C70.684 135.777 70.5016 135.589 69.9992 135.589ZM69.9928 137.069C69.6936 137.069 69.4992 137.026 69.3448 136.897C69.1632 136.74 69.1016 136.512 69.1016 136.249C69.1016 136 69.1848 135.734 69.3912 135.583C69.564 135.463 69.7768 135.426 69.9984 135.426C70.1992 135.426 70.4304 135.448 70.6208 135.577C70.8456 135.729 70.8888 135.993 70.8888 136.296H69.292C69.2984 136.617 69.4032 136.901 70.0072 136.901C70.2944 136.901 70.5624 136.854 70.812 136.811V136.974C70.5528 137.021 70.2664 137.07 69.992 137.07L69.9928 137.069Z"
      fill="#919396"
    />
    <path
      d="M72.8831 135.698C72.6887 135.627 72.4671 135.593 72.2543 135.593C71.7143 135.593 71.4583 135.776 71.4583 136.25C71.4583 136.7 71.6679 136.897 72.0751 136.897C72.3191 136.897 72.6023 136.839 72.8831 136.777V135.698ZM72.9167 137.027L72.9015 136.934C72.6519 136.993 72.3063 137.07 72.0599 137.07C71.8599 137.07 71.6679 137.042 71.5079 136.91C71.3231 136.759 71.2639 136.516 71.2639 136.25C71.2639 135.973 71.3567 135.71 71.6095 135.569C71.7855 135.467 72.0223 135.427 72.2383 135.427C72.4543 135.427 72.6639 135.464 72.8823 135.525V134.813H73.0639V137.027H72.9159H72.9167Z"
      fill="#919396"
    />
    <path
      d="M75.732 135.599C75.488 135.599 75.2048 135.657 74.924 135.722V136.795C75.1216 136.869 75.34 136.903 75.5528 136.903C76.092 136.903 76.348 136.721 76.348 136.246C76.348 135.799 76.1384 135.599 75.7312 135.599H75.732ZM76.1976 136.931C76.0216 137.029 75.7848 137.069 75.572 137.069C75.3408 137.069 75.1096 137.029 74.8752 136.952L74.8664 137.026H74.7432V134.812H74.9256V135.556C75.1752 135.501 75.5112 135.426 75.7488 135.426C75.9496 135.426 76.1408 135.456 76.3008 135.586C76.4856 135.737 76.5448 135.98 76.5448 136.246C76.5448 136.524 76.452 136.786 76.1992 136.931H76.1976Z"
      fill="#919396"
    />
    <path
      d="M76.7847 137.624V137.455C76.8743 137.464 76.9575 137.47 77.0159 137.47C77.2407 137.47 77.3767 137.405 77.5031 137.149L77.5615 137.026L76.7479 135.469H76.9575L77.6543 136.813H77.6663L78.3295 135.469H78.5359L77.6599 137.217C77.4999 137.535 77.3271 137.64 77.0095 137.64C76.9383 137.64 76.8615 137.633 76.7847 137.624Z"
      fill="#919396"
    />
    <path
      d="M81.0432 136.138H80.4392V136.684H81.0464C81.4624 136.684 81.62 136.637 81.62 136.409C81.62 136.165 81.404 136.138 81.044 136.138H81.0432ZM80.932 135.253H80.4384V135.809H80.9352C81.3456 135.809 81.5088 135.759 81.5088 135.528C81.5088 135.281 81.3024 135.253 80.932 135.253ZM81.8696 136.879C81.6472 137.021 81.3792 137.027 80.8888 137.027H79.9672V134.915H80.8672C81.2896 134.915 81.5488 134.921 81.7648 135.051C81.9192 135.144 81.9808 135.285 81.9808 135.47C81.9808 135.692 81.888 135.84 81.648 135.939V135.951C81.9192 136.013 82.0952 136.152 82.0952 136.451C82.0952 136.654 82.0208 136.787 81.8704 136.88L81.8696 136.879Z"
      fill="#919396"
    />
    <path
      d="M83.9392 136.373C83.7576 136.357 83.572 136.348 83.3784 136.348C83.0608 136.348 82.9496 136.413 82.9496 136.557C82.9496 136.693 83.0424 136.767 83.2856 136.767C83.4896 136.767 83.7328 136.721 83.9392 136.674V136.373ZM84.032 137.026L84.02 136.933C83.7552 136.998 83.4464 137.069 83.1752 137.069C83.0088 137.069 82.8296 137.048 82.7032 136.955C82.5864 136.872 82.5304 136.736 82.5304 136.579C82.5304 136.403 82.6072 136.24 82.7928 136.157C82.956 136.08 83.1752 136.073 83.376 136.073C83.5392 136.073 83.7584 136.082 83.94 136.095V136.067C83.94 135.824 83.78 135.744 83.3416 135.744C83.172 135.744 82.9656 135.753 82.768 135.771V135.457C82.9872 135.438 83.2336 135.426 83.4368 135.426C83.708 135.426 83.9888 135.448 84.1616 135.571C84.34 135.697 84.3744 135.873 84.3744 136.105V137.027H84.032V137.026Z"
      fill="#919396"
    />
    <path
      d="M86.4063 137.026V136.165C86.4063 135.882 86.2615 135.781 86.0023 135.781C85.8111 135.781 85.5711 135.829 85.3671 135.879V137.026H84.9327V135.469H85.2871L85.3023 135.568C85.5767 135.497 85.8823 135.426 86.1351 135.426C86.3263 135.426 86.5207 135.453 86.6687 135.586C86.7919 135.697 86.8383 135.851 86.8383 136.073V137.026H86.4063Z"
      fill="#919396"
    />
    <path
      d="M88.0664 137.069C87.8664 137.069 87.6472 137.041 87.4864 136.906C87.2952 136.752 87.24 136.509 87.24 136.246C87.24 136 87.32 135.731 87.5576 135.581C87.752 135.454 87.992 135.426 88.2424 135.426C88.4208 135.426 88.5968 135.439 88.7912 135.457V135.789C88.6336 135.774 88.4456 135.761 88.2952 135.761C87.8816 135.761 87.688 135.891 87.688 136.249C87.688 136.585 87.8328 136.729 88.172 136.729C88.3696 136.729 88.6008 136.693 88.8256 136.649V136.97C88.5816 137.02 88.3168 137.069 88.0672 137.069L88.0664 137.069Z"
      fill="#919396"
    />
    <path
      d="M90.1095 135.753C89.6959 135.753 89.5143 135.882 89.5143 136.237C89.5143 136.591 89.6935 136.742 90.1095 136.742C90.5199 136.742 90.6983 136.616 90.6983 136.261C90.6983 135.905 90.5231 135.752 90.1095 135.752V135.753ZM90.8559 136.906C90.6647 137.036 90.4151 137.069 90.1095 137.069C89.7983 137.069 89.5487 137.032 89.3599 136.906C89.1439 136.765 89.0671 136.53 89.0671 136.249C89.0671 135.969 89.1439 135.731 89.3599 135.589C89.5479 135.463 89.7975 135.426 90.1095 135.426C90.4175 135.426 90.6647 135.463 90.8559 135.589C91.0719 135.731 91.1455 135.969 91.1455 136.246C91.1455 136.527 91.0687 136.765 90.8559 136.906Z"
      fill="#919396"
    />
    <path
      d="M93.6551 137.069C93.3935 137.069 93.1095 137.026 92.8967 136.85C92.6439 136.641 92.5671 136.317 92.5671 135.969C92.5671 135.657 92.6655 135.287 92.9951 135.071C93.2511 134.905 93.5687 134.871 93.8895 134.871C94.1239 134.871 94.3639 134.886 94.6263 134.908V135.287C94.4007 135.269 94.1239 135.253 93.9079 135.253C93.3063 135.253 93.0503 135.481 93.0503 135.968C93.0503 136.465 93.2879 136.686 93.7319 136.686C94.0215 136.686 94.3455 136.628 94.6727 136.56V136.936C94.3455 137.001 94.0031 137.069 93.6551 137.069V137.069Z"
      fill="#919396"
    />
    <path
      d="M95.9504 135.704C95.5896 135.704 95.4504 135.833 95.4232 136.071H96.4719C96.4591 135.818 96.3112 135.704 95.9512 135.704H95.9504ZM95.8856 137.069C95.6296 137.069 95.3983 137.039 95.2255 136.897C95.0407 136.743 94.976 136.511 94.976 136.246C94.976 136.009 95.0527 135.744 95.2688 135.589C95.46 135.453 95.7032 135.426 95.9504 135.426C96.172 135.426 96.4343 135.451 96.6255 135.583C96.8751 135.759 96.8968 136.03 96.9 136.351H95.42C95.4296 136.589 95.5559 136.743 95.9935 136.743C96.2647 136.743 96.5672 136.703 96.8232 136.663V136.969C96.524 137.018 96.1976 137.07 95.8856 137.07V137.069Z"
      fill="#919396"
    />
    <path
      d="M98.8343 137.026V136.165C98.8343 135.882 98.6895 135.781 98.4303 135.781C98.2391 135.781 97.9991 135.829 97.7951 135.879V137.026H97.3607V135.469H97.7151L97.7303 135.568C98.0047 135.497 98.3095 135.426 98.5631 135.426C98.7543 135.426 98.9487 135.453 99.0967 135.586C99.2199 135.697 99.2663 135.851 99.2663 136.073V137.026H98.8343Z"
      fill="#919396"
    />
    <path
      d="M100.503 137.069C100.294 137.069 100.102 137.011 99.9975 136.847C99.9207 136.736 99.8831 136.585 99.8831 136.375V135.792H99.5687V135.469H99.8831L99.9295 134.997H100.315V135.469H100.929V135.792H100.315V136.292C100.315 136.412 100.324 136.513 100.358 136.588C100.405 136.693 100.506 136.733 100.642 136.733C100.744 136.733 100.867 136.717 100.954 136.702V137.013C100.812 137.041 100.648 137.069 100.504 137.069L100.503 137.069Z"
      fill="#919396"
    />
    <path
      d="M101.353 137.026V135.469H101.707L101.722 135.568C102.01 135.488 102.222 135.426 102.494 135.426C102.506 135.426 102.525 135.426 102.549 135.429V135.8C102.499 135.797 102.441 135.797 102.398 135.797C102.185 135.797 102.024 135.821 101.787 135.877V137.026H101.353Z"
      fill="#919396"
    />
    <path
      d="M104.13 136.373C103.948 136.357 103.762 136.348 103.568 136.348C103.25 136.348 103.139 136.413 103.139 136.557C103.139 136.693 103.232 136.767 103.475 136.767C103.679 136.767 103.922 136.721 104.129 136.674V136.373H104.13ZM104.222 137.026L104.21 136.933C103.945 136.998 103.637 137.069 103.366 137.069C103.199 137.069 103.02 137.048 102.894 136.955C102.777 136.872 102.721 136.736 102.721 136.579C102.721 136.403 102.798 136.24 102.983 136.157C103.146 136.08 103.365 136.073 103.566 136.073C103.729 136.073 103.949 136.082 104.13 136.095V136.067C104.13 135.824 103.97 135.744 103.532 135.744C103.362 135.744 103.156 135.753 102.958 135.771V135.457C103.178 135.438 103.424 135.426 103.628 135.426C103.899 135.426 104.18 135.448 104.353 135.571C104.531 135.697 104.566 135.873 104.566 136.105V137.027H104.223L104.222 137.026Z"
      fill="#919396"
    />
    <path
      d="M105.123 134.812H105.558V137.026H105.123V134.812Z"
      fill="#919396"
    />
  </svg>
);

export default IllustrationBrazilPix;
