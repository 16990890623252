import { Platform } from 'react-native';

import env from '../../env';
import {
  Locale,
  MvEnv,
  Platform as MvPlatform,
} from '@mfe/shared/schema-types';
import { snakeToCamelCase } from '@mfe/shared/util';

export const rnPlatformToAuthPlatform = (
  rnPlatform: Platform['OS']
): MvPlatform | null => {
  switch (rnPlatform.toLocaleLowerCase()) {
    case 'web':
      return MvPlatform.Web;
    case 'ios':
      return MvPlatform.Ios;
    case 'android':
      return MvPlatform.Android;
  }

  return null;
};

export const getPlatform = (): MvPlatform | null => {
  if (Platform.OS !== 'web') return rnPlatformToAuthPlatform(Platform.OS);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const platform = window.NATIVE_APP_INFO?.platform ?? 'web';
  return rnPlatformToAuthPlatform(platform);
};

export const getEnv = (): MvEnv => {
  return snakeToCamelCase(env.env) as MvEnv;
};

export const getAuthVars = () => {
  const platform = getPlatform() as MvPlatform;
  const env = getEnv();
  const mfeEnv = env;
  let locale: Locale = Locale.EnUs;

  if (
    Platform.OS === 'web' &&
    env === MvEnv.Prod &&
    window.location.hostname.includes('minha.viasat.com')
  ) {
    locale = Locale.PtBr;
  }

  if (Platform.OS === 'web' && window.location.href.includes('/it')) {
    locale = Locale.ItIt;
  }

  return { platform, env, mfeEnv, locale };
};

export const getURLParams = (url: string): Record<string, string> => {
  const re = /[?&]?([^=]+)=([^&]*)/g;
  const qs = url.replace(/.*\?/, '').replace(/\+/g, ' ');
  const params: Record<string, string> = {};
  let tokens: RegExpExecArray | null;

  // Loop over entire url
  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
};

export const retrieveAuthCode = (
  url: string,
  redirectUrl: string
): string | null => {
  if (!url) return null;
  const params = getURLParams(url);
  if (url.startsWith(redirectUrl) && params['code']) {
    return params['code'];
  }

  return null;
};
