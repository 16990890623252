import Gradient from '../../../shared-ui/gradient';

import styles from './styles.module.scss';

const SummaryLoading = (): JSX.Element => {
  return (
    <div className={styles['loadingContainer']}>
      <Gradient />
      <div className={styles['summary']}>
        <div className={styles['smallLoading']} />
        <div className={styles['largeLoading']} />
        <div className={styles['mediumLoading']} />
        <div className={styles['mediumLoading']} />
      </div>
      <div className={styles['link']}>
        <div className={styles['mediumLoading']} />
      </div>
      <div className={styles['link']}>
        <div className={styles['mediumLoading']} />
      </div>
    </div>
  );
};

export default SummaryLoading;
