import * as React from 'react';
import { Icon, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { MoreHoriz } from '@vst/beam-icons/icons';
import { useDispatch, useSelector } from 'react-redux';

import type { Invoice } from '@mfe/shared/schema-types';
import { makeSelectIsInvoiceMenuDisabled } from '@mfe/to-be-migrated/redux/billingInfo';

import { goToPayment } from '../../../../shared/navigation';

import styles from './styles.module.scss';

const InvoiceMenu = ({ invoice }: { invoice?: Invoice }) => {
  const { t } = useTranslation('BillingStatement');
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = React.useState(false);
  const isDisabled = useSelector(makeSelectIsInvoiceMenuDisabled(invoice));

  const onClose = () => setIsOpen(false);
  const toggle = () => {
    if (isDisabled) {
      onClose();
    } else {
      setIsOpen((open) => !open);
    }
  };

  let statementMenuButtonStyles = styles['statementMenuButton'];
  if (isDisabled) {
    statementMenuButtonStyles += ` ${styles['disabled']}`;
  }

  const popupId = 'account-menu';

  return (
    <>
      {isOpen && <div className={styles['overlay']} onClick={onClose} />}
      <div className={styles['statementMenuContainer']}>
        <div
          className={statementMenuButtonStyles}
          onClick={toggle}
          aria-controls={isOpen ? popupId : undefined}
          aria-haspopup="menu"
          aria-expanded={isOpen ? 'true' : undefined}
        >
          <Icon icon={MoreHoriz} />
        </div>
        {isOpen && (
          <div id={popupId} role="menu" className={styles['statementDropdown']}>
            <div
              className={styles['dropdownItem']}
              role="menuitem"
              onClick={() => goToPayment(dispatch, 'billing_page', invoice)}
            >
              <Txt variant="smallRegular" color="subtle" component="p">
                {t('makeOneTimePayment')}
              </Txt>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default InvoiceMenu;
