import { Color } from './consts';

const colorOptions = Object.keys(Color).map((color) => color.toLowerCase());

interface extracted {
  colorName: string;
  step: number;
  isUpOK: boolean;
  isDownOK: boolean;
}

export const opacity = (color: Color, decimal: number): string => {
  const hexValue = () => {
    switch (true) {
      case decimal < 0.05:
        return '00';
      case decimal < 0.1:
        return '0D';
      case decimal < 0.15:
        return '1A';
      case decimal < 0.2:
        return '25';
      case decimal < 0.25:
        return '33';
      case decimal < 0.3:
        return '40';
      case decimal < 0.35:
        return '4D';
      case decimal < 0.4:
        return '59';
      case decimal < 0.45:
        return '66';
      case decimal < 0.5:
        return '73';
      case decimal < 0.55:
        return '80';
      case decimal < 0.6:
        return '8C';
      case decimal < 0.65:
        return '99';
      case decimal < 0.7:
        return 'A6';
      case decimal < 0.75:
        return 'B3';
      case decimal < 0.8:
        return 'BF';
      case decimal < 0.85:
        return 'CC';
      case decimal < 0.9:
        return 'D9';
      case decimal < 0.95:
        return 'E6';
      case decimal < 1:
        return 'F2';
      case decimal === 1:
      default:
        return 'FF';
    }
  };
  return color + hexValue();
};

export function colorExtract(base: Color, factor = 100): extracted {
  const key = Object.keys(Color).find(
    // @ts-ignore
    (name) => Color[name] === base.toString()
  );
  if (!key) {
    throw new Error(
      `Unable to step color. ${base} does not exist in color enums`
    );
  }
  const match = key.match(/((?:[a-z]|[A-Z])+)(\d{3})/);
  if (!match || match.length !== 3) {
    throw new Error(`Unable to step color. ${key} contains no value`);
  }
  const [, colorName, number] = match;
  const step = Number(number);
  const { isUpOK, isDownOK } = colorOptions.reduce(
    ({ isUpOK, isDownOK }, color) => ({
      isUpOK: isUpOK || color === `${colorName}${step + factor}`,
      isDownOK: isDownOK || color === `${colorName}${step - factor}`,
    }),
    {
      isUpOK: false,
      isDownOK: false,
    }
  );
  return {
    colorName,
    step,
    isUpOK,
    isDownOK,
  };
}

export function colorStepUp(base: Color, factor = 100): Color {
  const { colorName, step, isUpOK } = colorExtract(base, factor);
  if (!isUpOK) {
    throw new Error(`Unable to further step up ${colorName}`);
  }
  // @ts-ignore
  return Color[`${colorName}${step + factor}`];
}

export function colorStepDown(base: Color, factor = 100): Color {
  const { colorName, step, isDownOK } = colorExtract(base, factor);
  if (!isDownOK) {
    throw new Error(`Unable to further step down ${colorName}`);
  }
  // @ts-ignore
  return Color[`${colorName}${step - factor}`];
}
