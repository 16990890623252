import { Spinner, Surface } from '@vst/beam';
import { useTranslation } from 'react-i18next';

export const CalendarLoading = () => {
  const { t } = useTranslation('NewScheduleInstallation');

  return (
    <Surface>
      <Spinner description={t('calendar.loading')} />
    </Surface>
  );
};
