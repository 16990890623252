import { useTranslation } from 'react-i18next';

import { EmptyState, Surface } from '@vst/beam';
import { Alert } from '@vst/beam-icons/illustrative-icons';

import styles from './balance-card.module.scss';

export const BalanceCardError = () => {
  const { t } = useTranslation('Overview', { keyPrefix: 'balanceCard' });
  return (
    <div
      className={`${styles['balanceCard']} ${styles['error']}`}
      data-cy="error-content"
    >
      <Surface theme="dark" className={styles['content']}>
        <EmptyState
          title={t('error.title')}
          icon={Alert}
          description={t('error.description')}
        />
      </Surface>
    </div>
  );
};
