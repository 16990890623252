import { Icon, Txt } from '@vst/beam';
import { ArrowBack } from '@vst/beam-icons/icons';
import styles from './styles.module.scss';

const MobileStackNavBar = ({
  title,
  onBack,
}: {
  title: string;
  onBack: () => void;
}) => {
  return (
    <>
      <header className={styles.header} data-cy="mobile-stack-nav-bar">
        <span className={styles.iconContainer} onClick={onBack}>
          <Icon
            className={styles.backIcon}
            icon={ArrowBack}
            size={19}
            color="blue_500"
          />
        </span>
        <Txt className={styles.title} variant="subHeading4">
          {title}
        </Txt>
      </header>
      <div className={styles.spacer} />
    </>
  );
};

export default MobileStackNavBar;
