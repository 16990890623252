import React, { useState, useEffect } from 'react';
import {
  View,
  StyleSheet,
  ViewProps,
  TouchableOpacity,
  TouchableOpacityProps,
  StyleProp,
  ViewStyle,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { Text, Card, Expandable, CardProps, AndromedaTextProps } from '.';
import { Color } from '../utils/Color';
import { IconProps } from 'react-native-vector-icons/Icon';

const styles = StyleSheet.create({
  cardContentStyle: {
    paddingVertical: 32,
    paddingHorizontal: 23,
  },
  cardMarginStyle: { marginBottom: 8 },
  visibleContentRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  visibleContentTitle: {
    fontSize: 24,
    flex: 1,
  },
  activeContentTitle: { color: Color.teal500 },
});

export interface AccordionCard {
  title: string;
  content: React.ReactNode;
}

export interface AccordionProps extends ViewProps {
  data: AccordionCard[];
  cardProps?: CardProps;
  visibleContentCustomization?: VisibleContentCustomization;
  initialActiveIndex?: number | null;
  onCardPressed?: (index: number, isExpanded: boolean) => void;
}

export interface VisibleContentProps {
  title: string;
  onPress: () => void;
  expanded: boolean;
  customization?: VisibleContentCustomization;
  testID?: string;
}

export interface VisibleContentCustomization {
  props?: TouchableOpacityProps;
  iconProps?: Omit<IconProps, 'name'>;
  wrapperStyle?: StyleProp<ViewStyle>;
  textProps?: Omit<AndromedaTextProps, 'children'>;
}

export const VisibleContent = ({
  title,
  onPress,
  expanded,
  customization = {},
  testID = '',
}: VisibleContentProps): JSX.Element => {
  const {
    props = {},
    iconProps = { size: 33, color: Color.teal600 },
    wrapperStyle = styles.visibleContentRow,
    textProps = {
      light: true,
      style: [
        styles.visibleContentTitle,
        expanded ? styles.activeContentTitle : {},
      ],
    },
  } = customization;
  const [hover, setHover] = useState(false);
  const hoverProps = {
    onMouseEnter: (): void => setHover(true),
    onMouseLeave: (): void => setHover(false),
  };
  const hoverStyle = hover || expanded ? { color: Color.teal600 } : {};

  return (
    <TouchableOpacity
      onPress={onPress}
      {...hoverProps}
      {...props}
      testID={testID}
    >
      <View style={wrapperStyle}>
        <Text {...textProps} style={[textProps.style || {}, hoverStyle]}>
          {title}
        </Text>
        <Icon name={`chevron-${expanded ? 'up' : 'down'}`} {...iconProps} />
      </View>
    </TouchableOpacity>
  );
};

const defaultCardProperties: CardProps = {
  contentStyle: styles.cardContentStyle,
  marginStyle: styles.cardMarginStyle,
  removeMaxWidth: true,
};

export const Accordion = ({
  data,
  cardProps = defaultCardProperties,
  visibleContentCustomization,
  initialActiveIndex = null,
  testID = '',
  onCardPressed = (): void => {},
}: AccordionProps): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState<number | null>(
    initialActiveIndex
  );
  useEffect(
    (): void => setActiveIndex(initialActiveIndex),
    [initialActiveIndex]
  );

  return (
    <>
      {data.map(({ title, content }: AccordionCard, index): JSX.Element => {
        const expanded = activeIndex === index;
        return (
          <Card key={title} testID={`${testID}-${title}`} {...cardProps}>
            <VisibleContent
              title={title}
              expanded={expanded}
              onPress={(): void => {
                setActiveIndex(expanded ? null : index);
                onCardPressed(index, !expanded);
              }}
              customization={visibleContentCustomization}
              testID={`${testID}-${title}-Visible`}
            />
            <Expandable
              trigger={expanded}
              testID={`${testID}-${title}-Expandable`}
            >
              <View testID={`${testID}-${title}-Hidden`}>{content}</View>
            </Expandable>
          </Card>
        );
      })}
    </>
  );
};
