import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Locale } from '@mfe/shared/schema-types';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import {
  ArticleCard,
  useQuestionArticleCardProps,
} from '@mfe/shared/components';

import ArticleImage from './assets/ArticleImage';
import IllustrationBrazilBill from './assets/IllustrationBrazilBill';
import IllustrationItalyBilling from './assets/IllustrationItalyBilling';
import IllustrationBrazilCreditCard from './assets/IllustrationBrazilCreditCard';

import styles from './article-banner.module.scss';

const useEnUsArticle = () => {
  const { t } = useTranslation('ArticleBanner');

  return useQuestionArticleCardProps({
    title: t('title'),
    caption: t('description'),
    websiteLink: t('websiteLink'),
    linkText: t('linkName'),
  });
};

const useItItArticle = () => {
  const { t } = useTranslation('ArticleBannerItaly');

  return useQuestionArticleCardProps({
    title: t('title'),
    caption: t('description'),
    websiteLink: t('websiteLink'),
    linkText: t('linkName'),
  });
};

const useBr0Article = () => {
  const { t } = useTranslation('Overview');

  return useQuestionArticleCardProps({
    title: t('QuestionBrazil.0.title'),
    caption: t('QuestionBrazil.0.caption'),
  });
};

const useBr1Article = () => {
  const { t } = useTranslation('Overview');

  return useQuestionArticleCardProps({
    title: t('QuestionBrazil.1.title'),
    caption: t('QuestionBrazil.1.caption'),
  });
};

const ArticleBanner = (): JSX.Element | null => {
  const {
    locale: { userLocale: locale },
  } = useSelector(selectLocale);

  const enUsArticle = useEnUsArticle();
  const itItArticle = useItItArticle();
  const ptBrArticle0 = useBr0Article();
  const ptBrArticle1 = useBr1Article();

  if (!locale) {
    return null;
  }

  if (locale === Locale.EnUs) {
    return <ArticleCard {...enUsArticle} image={<ArticleImage />} />;
  }

  if (locale === Locale.ItIt) {
    return (
      <ArticleCard {...itItArticle} image={<IllustrationItalyBilling />} />
    );
  }

  return (
    <div className={styles['articlesRow']}>
      <ArticleCard
        isPortrait
        {...ptBrArticle0}
        image={<IllustrationBrazilCreditCard />}
      />
      <ArticleCard
        isPortrait
        {...ptBrArticle1}
        image={<IllustrationBrazilBill />}
      />
    </div>
  );
};

export default ArticleBanner;
