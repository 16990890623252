import { HelpLink, PayBillLink } from '@mfe/legacy/mv/utils/Navigation';
import LiveChat from '../../views/ContactUs';
import InvoiceLoading from '@mfe/legacy/mv/views/PayBill/Invoice';
import { WebPayBill } from '../../views/WebTabs';
import { NestedRoutes, RouteRenderProps } from '../utils';
import UpdateBillCycle from '../../views/UpdateBillCycle';
import SelectBillCycle from '../../views/UpdateBillCycle/SelectBillCycle';

export const billingRoutes: RouteRenderProps[] = [
  {
    path: PayBillLink.PayBill,
    render: <WebPayBill />,
    exact: true,
    fromParent: 0,
  },
  {
    path: PayBillLink.UpdateBillCycle,
    render: <UpdateBillCycle />,
    exact: true,
    fromParent: 0,
  },
  {
    path: HelpLink.AgentChat,
    render: <LiveChat />,
    exact: true,
    fromParent: 1,
  },
  {
    path: PayBillLink.Invoice,
    render: <InvoiceLoading />,
    exact: true,
    fromParent: 0,
    param: '/:invoice',
  },
  {
    path: PayBillLink.SelectBillCycle,
    render: <SelectBillCycle />,
    exact: true,
    fromParent: 1,
  },
];

export const BillingSwitch = (): JSX.Element => {
  return <NestedRoutes routes={billingRoutes} />;
};
