import { ViewStyle } from 'react-native';
import { Color } from '../utils/Color/consts';

export const imageStyles = {
  voiceIcon: {
    width: 45,
    height: 70,
  },
  easyCareIconSm: {
    width: 59,
    height: 35,
  },
  movingIcon: {
    width: 53,
    height: 38,
  },
  vacationIcon: {
    width: 57,
    height: 44,
  },
  easyCareIconMd: {
    width: 88,
    height: 52,
  },
  addOnFeature: {
    width: 50,
    height: 68,
  },
};

export const containerStyles = {
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  } as ViewStyle,
  flexColumnToRow: {
    flexDirection: 'row',
  } as ViewStyle,
  flexFixWrapForRowChild: {
    flexShrink: 1,
  } as ViewStyle,
  header: {
    marginTop: 20,
    marginBottom: 30,
  } as ViewStyle,
  alignCenter: {
    alignItems: 'center',
  } as ViewStyle,
  spaceBetween: {
    justifyContent: 'space-between',
  } as ViewStyle,
  centeredRow: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  fullHeight: {
    height: '100%',
  } as ViewStyle,
};

export const pageStyles = {
  topMargin: {
    marginTop: 10,
  },
  padding: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  whiteBackground: {
    backgroundColor: Color.white,
  },
};

export const elementStyles = {
  alignSelfCenter: {
    alignSelf: 'center',
  },
  alignSelfLeft: {
    alignSelf: 'flex-start',
  },
  alignSelfRight: {
    alignSelf: 'flex-end',
  },
  alignSelfStretch: {
    alignSelf: 'stretch',
  },
  hidden: {
    opacity: 0,
  },
};
