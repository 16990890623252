import { useState } from 'react';
import { Spinner, Surface, colors } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  addPlanToCart,
  submitOrder,
  selectChangePlan,
  setChangePlanStep,
  ChangePlanSteps,
  selectChosenPlan,
} from '@mfe/to-be-migrated/redux/changePlan';
import { selectConfig } from '@mfe/shared/redux/config';
import { useScreenResolution } from '@mfe/shared/util';
import { Platform } from '@mfe/shared/schema-types';
import { HeaderWithBackNavigation } from '@mfe/shared/components';
import { sendMessageToMobileApp } from '@mfe/shared/cross-platform-events';

import { CartSummary } from '../CartSummary';
import { SubmitSection } from './SubmitSection';
import { OrderDetailsCard } from './OrderDetailsCard';
import OrderErrorContent from '../errors/OrderErrorContent';
import { ErrorWithRefresh } from '../errors/ErrorWithRefresh';
import { CartSummaryContainer } from './CartSummaryContainer';
import { CollapsibleCartSummary } from './CollapsibleCartSummary';

import styles from './OrderReview.module.scss';

interface OrderReviewProps {
  handleBackNavigation: () => void;
}

export type ModalTypes = 'terms&conditions' | 'calculationModal';

export const OrderReview = ({ handleBackNavigation }: OrderReviewProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('NewChangePlan');

  const [type, setType] = useState<ModalTypes | null>(null);
  const isTermsAndConditionsModalOpen = type === 'terms&conditions';
  const isCalculationModalOpen = type === 'calculationModal';
  const isModalOpen = isTermsAndConditionsModalOpen || isCalculationModalOpen;

  const { isExtraSmall, isSmall, isMedium } = useScreenResolution();
  const isMobile = isSmall || isExtraSmall;
  const isMobileOrTablet = isMobile || isMedium;

  const { requiresWorkOrder, loading, error } = useSelector(selectChangePlan);
  const selectedPlan = useSelector(selectChosenPlan);
  const { platform } = useSelector(selectConfig);

  const smallScreenLoadingStyles =
    isMobile && loading
      ? {
          marginTop: 0,
          borderTop: `1px solid ${colors['gray'][200]}`,
        }
      : {};

  const handleNextStepNavigation = () => {
    if (requiresWorkOrder) {
      dispatch(setChangePlanStep(ChangePlanSteps.SELECT_SCHEDULE));
    } else {
      dispatch(submitOrder(undefined));
      dispatch(setChangePlanStep(ChangePlanSteps.ORDER_OUTCOME));
    }
  };

  const handleRefreshOnErrorPage = () => {
    dispatch(addPlanToCart({ newProductTypeId: selectedPlan.id }));
  };

  const openModal = (
    e: React.MouseEvent<HTMLElement>,
    modalType: ModalTypes
  ) => {
    e.preventDefault();
    dispatch(
      sendMessageToMobileApp({
        type: 'set-refresh-control-status',
        data: {
          refreshControlEnabled: false,
        },
      })
    );
    setType(modalType);
  };

  if (error) {
    return (
      <ErrorWithRefresh
        handleRefresh={handleRefreshOnErrorPage}
        handleGoBack={handleBackNavigation}
      >
        <OrderErrorContent />
      </ErrorWithRefresh>
    );
  }

  const handleModalClose = () => {
    setType(null);
    if (platform !== Platform.Web && isModalOpen) {
      dispatch(
        sendMessageToMobileApp({
          type: 'set-refresh-control-status',
          data: {
            refreshControlEnabled: true,
          },
        })
      );
    }
  };

  if (!selectedPlan) return null;

  return (
    <Surface className={styles['wrapper']} variant="secondary">
      {isMobileOrTablet && !loading && (
        <CollapsibleCartSummary
          selectedPlan={selectedPlan}
          handleBackNavigation={handleBackNavigation}
        />
      )}
      <HeaderWithBackNavigation
        handleBackNavigation={handleBackNavigation}
        title={t('orderReview.title')}
        goBackLabel={t('goBack')}
        additionalClassName={styles['page-header']}
        style={smallScreenLoadingStyles}
      />
      {loading ? (
        <div className={styles['spinner-wrapper']}>
          <Spinner description={t('orderReview.loading')} />
        </div>
      ) : (
        <div className={styles['page-content']}>
          <OrderDetailsCard
            openModal={openModal}
            closeModal={handleModalClose}
            handlePlacePlanOrder={handleNextStepNavigation}
            isCalculationModalOpen={isCalculationModalOpen}
            isTermsAndConditionsModalOpen={isTermsAndConditionsModalOpen}
            requiresWorkOrder={requiresWorkOrder}
          />
          {isMobileOrTablet ? (
            !requiresWorkOrder && (
              <CartSummaryContainer>
                <CartSummary
                  selectedPlan={selectedPlan}
                  handleBackNavigation={handleBackNavigation}
                  requiresWorkOrder={requiresWorkOrder}
                />

                <hr style={{ marginTop: '24px' }} />

                <SubmitSection
                  handlePlacePlanOrder={handleNextStepNavigation}
                  handleBackNavigation={handleBackNavigation}
                  requiresWorkOrder={requiresWorkOrder}
                />
              </CartSummaryContainer>
            )
          ) : (
            <CartSummary
              selectedPlan={selectedPlan}
              handleBackNavigation={handleBackNavigation}
              requiresWorkOrder={requiresWorkOrder}
            />
          )}
        </div>
      )}
    </Surface>
  );
};
