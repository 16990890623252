import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { format, parse } from 'date-fns';
import {
  Badge,
  Icon,
  SectionAlert,
  Surface,
  Txt,
  colors,
  StandAloneLink,
  IconComponentType,
} from '@vst/beam';
import { Offer, OpenInNew } from '@vst/beam-icons/icons';
import { allBeamColors } from '@vst/beam/src/react/common';

import { GetCurrentAddOnsPayload } from '@mfe/shared/schema-types';
import { ModalType } from '@mfe/shared/components';
import { ADD_ON_STATE } from '@mfe/shared/util';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';
import { getBadgeForAddOn, getProps } from './utils';

import { AddonManagementModal } from './AddonManagementModal';
import styles from './styles.module.scss';

type AddOnCardProps = {
  id?: string;
  addOnName?: string | null;
  addOnDescription?: string;
  addOnPrice?: GetCurrentAddOnsPayload['price'];
  priceWithDiscount?: GetCurrentAddOnsPayload['priceWithDiscount'];
  discountName?: GetCurrentAddOnsPayload['discountName'];
  discountEndDate?: GetCurrentAddOnsPayload['discountEndDate'];
  isVoiceAddon?: boolean;
  displaySimplifiedCard?: boolean;
  showPricesAndDiscounts?: false;
  addOnState: string;
  showManageLink?: boolean;
  isBundleAddOn?: boolean;
  showModal?: ModalType;
};

export const AddOnCard: React.FC<AddOnCardProps> = (props) => {
  const {
    id,
    addOnName,
    addOnDescription,
    addOnState,
    addOnPrice,
    priceWithDiscount,
    discountName,
    discountEndDate,
    isVoiceAddon,
    displaySimplifiedCard,
    showManageLink,
    showModal,
    isBundleAddOn,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const addonProps = getProps(addOnName as string);
  const { iconBackground, iconName, iconColor } = addonProps ?? {
    iconBackground: undefined,
    iconName: undefined,
    iconColor: 'inherit',
  };

  // The parse function reads converts the given string into a Date object representing midnight on the given day, in the local time zone of the JS environment
  const parsedEndDate = discountEndDate
    ? parse(discountEndDate, 'yyyy-MM-dd', new Date())
    : null;

  const formattedEndDate = parsedEndDate
    ? format(parsedEndDate, 'MMMM dd, yyyy')
    : '';

  const isProductFree = Number(addOnPrice) === 0;
  const pendingDeactivation = addOnState === ADD_ON_STATE.PENDING;
  if (!addOnName) return null;

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const badgeState = getBadgeForAddOn(addOnState);

  return displaySimplifiedCard ? (
    <SimplifiedCard
      iconBackground={iconBackground}
      iconName={iconName}
      iconColor={iconColor}
      addOnName={addOnName}
      pendingDeactivation={pendingDeactivation}
      isVoiceAddon={isVoiceAddon}
      addOnState={addOnState}
      badgeState={badgeState}
    />
  ) : (
    <div className={styles['add-on-card-container']} data-cy="addons-container">
      <Surface
        style={{ background: iconBackground }}
        className={styles['icon-container']}
      >
        {iconName && <Icon size={'24'} icon={iconName} color={iconColor} />}
      </Surface>
      <div className={styles['add-on-content']} data-cy="add-on-content">
        <div className={styles['title-and-badge']}>
          <Txt variant="bodyLargeBold">{addOnName}</Txt>
          {pendingDeactivation ? (
            <Badge
              state="infoSecondary"
              label="Pending Deactivation"
              showIcon={false}
            />
          ) : (
            <Badge state={badgeState} label={addOnState} showIcon={false} />
          )}
        </div>
        {addOnDescription && (
          <Txt variant="smallRegular" color="regular">
            {addOnDescription}
          </Txt>
        )}
        {priceWithDiscount && addOnPrice ? (
          <div className={styles['container']}>
            <DiscountedPrices
              addOnPrice={addOnPrice as string}
              priceWithDiscount={priceWithDiscount}
            />
            {showManageLink && !pendingDeactivation && (
              <ManageLink onOpenModal={handleOpenModal} />
            )}
            <AddonManagementModal
              id={id ?? ''}
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              addOnName={addOnName ?? ''}
              isBundleAddOn={isBundleAddOn}
              showModal={showModal}
            />
          </div>
        ) : (
          addOnPrice && (
            <Txt
              variant="bodySmallBold"
              color="subtle"
              style={
                isProductFree ? { color: colors['green'][700] } : undefined
              }
            >
              <Trans
                ns="Profile"
                i18nKey={isProductFree ? 'addOns.freePrice' : 'addOns.price'}
                values={{
                  addOnPrice: addOnPrice,
                }}
              />
              {showManageLink && !pendingDeactivation && (
                <ManageLink onOpenModal={handleOpenModal} />
              )}
              <AddonManagementModal
                id={id ?? ''}
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                addOnName={addOnName}
                isBundleAddOn={isBundleAddOn}
                showModal={showModal}
              />
            </Txt>
          )
        )}
        {discountName && discountEndDate && (
          <SectionAlert variant="success" icon={Offer}>
            <Txt variant="smallRegular" color="regular">
              <Trans
                ns="Profile"
                i18nKey="addOns.offerAlert"
                values={{
                  discountName: discountName,
                  discountEndDate: formattedEndDate,
                  addOnPrice: addOnPrice,
                }}
              />
            </Txt>
          </SectionAlert>
        )}
      </div>
    </div>
  );
};

const ManageLink = ({ onOpenModal }: { onOpenModal: () => void }) => {
  const { t } = useTranslation('Profile');
  return (
    <StandAloneLink
      variant="primary"
      href="#"
      onClick={(e) => {
        e.preventDefault();
        onOpenModal();
      }}
      ml="4px"
      style={{ textDecoration: 'none', display: 'inline-block' }}
    >
      {t('manage.link')}
    </StandAloneLink>
  );
};

type DiscountedPricesProps = {
  addOnPrice: string;
  priceWithDiscount: string;
};

const DiscountedPrices = ({
  addOnPrice,
  priceWithDiscount,
}: DiscountedPricesProps) => {
  const { t } = useTranslation('Profile');

  return Number(priceWithDiscount) > 0 ? (
    <div>
      <Txt variant="bodySmallBold" color="subtle" component="span" mr="4px">
        <Trans
          ns="Profile"
          i18nKey={'addOns.priceWithDiscount'}
          values={{
            discountPrice: priceWithDiscount,
          }}
        />
      </Txt>
      <Txt variant="bodySmallRegular" color="subtle" component="span">
        <s>
          <Trans
            ns="Profile"
            i18nKey={'addOns.price'}
            values={{
              addOnPrice: addOnPrice,
            }}
          />
        </s>
      </Txt>
    </div>
  ) : (
    <div>
      <Txt
        variant="bodySmallBold"
        color="subtle"
        component="span"
        mr="4px"
        style={{ color: colors['green'][700] }}
      >
        {t('addOns.freePrice')}
      </Txt>
      <Txt variant="linkBodySmall" component="span">
        <s>
          <Trans
            ns="Profile"
            i18nKey={'addOns.price'}
            values={{
              addOnPrice,
            }}
          />
        </s>
      </Txt>
    </div>
  );
};

type SimplifiedAddOnCardProps = {
  iconBackground: string | undefined;
  iconName: IconComponentType | undefined;
  iconColor: allBeamColors;
  addOnName: string;
  pendingDeactivation?: boolean;
  isVoiceAddon?: boolean;
  addOnState: string;
  badgeState: 'infoSecondary' | 'positive';
};

const SimplifiedCard: React.FC<SimplifiedAddOnCardProps> = (props) => {
  const {
    iconBackground,
    iconName,
    iconColor,
    addOnName,
    pendingDeactivation,
    isVoiceAddon,
    addOnState,
    badgeState,
  } = props;

  const { t } = useTranslation('Overview', { keyPrefix: 'addOns' });
  const dispatch = useDispatch();

  return (
    <div
      className={styles['addons-section']}
      data-cy="simplified-addons-container"
    >
      <div data-cy="simplified-add-on-content">
        <Surface
          style={{ background: iconBackground }}
          className={styles['icon-container']}
        >
          {iconName && <Icon size={'24'} icon={iconName} color={iconColor} />}
        </Surface>
        <div className={styles['title-and-badge']}>
          <Txt variant="bodyLargeBold">{addOnName}</Txt>
          {pendingDeactivation ? (
            <Badge
              state="infoSecondary"
              label="Pending Deactivation"
              showIcon={false}
            />
          ) : (
            <Badge state={badgeState} label={addOnState} showIcon={false} />
          )}
        </div>
      </div>
      {isVoiceAddon && (
        <StandAloneLink
          data-cy="manage-link"
          variant="secondary"
          icon={OpenInNew}
          iconPos="right"
          href=""
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            dispatch(openInNewTab(t('alianzaLink')));
          }}
        >
          {t('manage')}
        </StandAloneLink>
      )}
    </div>
  );
};
