import React from 'react';
import {
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  StyleSheet,
} from 'react-native';
import { CloseIconType, CloseIcon } from '../../Icons';
import { Color } from '../../../utils';

export type CloseType = React.ComponentProps<typeof TouchableOpacity> &
  CloseIconType & {
    leading?: boolean;
  };

export class Close extends React.Component<CloseType> {
  private root: TouchableOpacity | undefined | null;

  setNativeProps(args: any) {
    return this.root && this.root.setNativeProps(args);
  }

  override render() {
    const {
      color = Color.teal500,
      style: styleProps,
      leading = false,
      ...touchableProps
    } = this.props;
    const style: StyleProp<ViewStyle> = [styles.default];
    if (leading) style.push(styles.leading);
    if (styleProps) style.push(styleProps);

    return (
      <TouchableOpacity
        ref={(c) => {
          this.root = c;
        }}
        style={style}
        {...touchableProps}
      >
        <CloseIcon {...{ color }} />
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  default: {
    padding: 10,
    alignSelf: 'flex-end',
  },
  leading: {
    alignSelf: 'flex-start',
  },
});
