import { Txt } from '@vst/beam';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectChangePlan } from '@mfe/to-be-migrated/redux/changePlan';

import { OrderDetailsSection } from './OrderDetailsSection';

export const Contract = () => {
  const { t } = useTranslation('NewChangePlan', {
    keyPrefix: 'orderReview',
  });

  const {
    cart: {
      cartItems: { plan },
    },
  } = useSelector(selectChangePlan);

  const contractTypeDetails = plan?.contractTerms?.includes('24')
    ? t('sateliteMigrationDetails.contract.24MonthContractDetails')
    : t('sateliteMigrationDetails.contract.monthToMonthContractDetails');

  const contractContent = plan?.contractTerms
    ? contractTypeDetails
    : t('details.contract.content');

  const badgeText = plan?.contractTerms
    ? (plan?.contractTerms || '').replace(' contract', '')
    : undefined;

  return (
    <OrderDetailsSection
      id="contract"
      badge={badgeText}
      title={t('details.contract.title')}
    >
      <Txt variant="bodySmallRegular">{contractContent}</Txt>
    </OrderDetailsSection>
  );
};
