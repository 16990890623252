import React from 'react';

const CaretExpandIcon = () => (
  <svg
    width="12"
    height="6"
    viewBox="0 0 12 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.219399 0.374958C0.137291 0.477498 0.0761917 0.595213 0.0395928 0.721375C0.00299392 0.847537 -0.00838755 0.979674 0.00609873 1.11024C0.020585 1.2408 0.0606551 1.36723 0.12402 1.4823C0.187384 1.59737 0.272801 1.69883 0.37539 1.78087L5.37509 5.78064C5.55245 5.92263 5.77287 6 6.00006 6C6.22725 6 6.44767 5.92263 6.62502 5.78064L11.6247 1.78087C11.8318 1.61512 11.9646 1.37388 11.9939 1.11022C12.0231 0.846562 11.9465 0.58208 11.7807 0.374958C11.615 0.167836 11.3737 0.0350414 11.1101 0.00578741C10.8464 -0.0234665 10.5819 0.0532163 10.3748 0.218967L6.00006 3.71876L1.62532 0.218967C1.52278 0.136859 1.40506 0.0757601 1.2789 0.0391612C1.15274 0.00256227 1.0206 -0.00881998 0.890037 0.0056663C0.759475 0.0201526 0.633047 0.0602234 0.517976 0.123588C0.402905 0.186952 0.301447 0.272369 0.219399 0.374958V0.374958Z"
      fill="#465967"
    />
  </svg>
);

export default CaretExpandIcon;
