import { ModalTypeEnum, ModalComponent } from '@mfe/shared/components';
import { useTranslation } from 'react-i18next';
import Outcome from './Outcome';
import { useScreenResolution } from '@mfe/shared/util';
import { Alert } from '@vst/beam-icons/illustrative-icons';

interface ApplicationPendingErrorProps {
  goBackToOverviewPage: () => void;
}
export const ApplicationPendingError = (
  props: ApplicationPendingErrorProps
) => {
  const { goBackToOverviewPage } = props;
  const { t } = useTranslation('ACP', { keyPrefix: 'Error' });
  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;

  const renderOutcome = () => {
    return (
      <Outcome
        iconName={Alert}
        data-cy="acp-application-pending-error"
        title={t('applicationPending.title')}
        text={t('applicationPending.description')}
        thirdText={t('applicationPending.thirdDescription')}
        primaryButton={{
          label: t('applicationPending.buttons.backToMyViasat'),
          handleClick: goBackToOverviewPage,
        }}
      />
    );
  };

  return isMobile ? (
    renderOutcome()
  ) : (
    <ModalComponent type={ModalTypeEnum.ACP}>{renderOutcome()}</ModalComponent>
  );
};
