import { Home, CreditCard, Speed, HelpOutline } from '@vst/beam-icons/icons';
import { useTranslation } from 'react-i18next';
import TabBar from './TabBar';
import { selectConfig } from '@mfe/shared/redux/config';
import { useSelector } from 'react-redux';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { ProductInstanceTypes } from '@mfe/shared/graphql/PSM/types';

const MobileTabBar = (): JSX.Element => {
  const { t } = useTranslation('Nav');
  const { showNetworkPage } = useSelector(selectConfig);
  const {
    user: { productKind },
  } = useSelector(selectUser);

  const overview = {
    label: t('Overview'),
    path: '/',
    testId: 'Overview',
    icon: Home,
  };

  const billing = {
    label: t('BillPay'),
    path: '/PayBill',
    testId: 'PayBill',
    icon: CreditCard,
  };

  const usage = {
    label: t('Plan'),
    path: '/PlanUsage',
    testId: 'PlanUsage',
    icon: Speed,
  };

  const help = {
    label: t('Help'),
    path: showNetworkPage ? '/Network' : '/Troubleshooting',
    testId: showNetworkPage ? 'Network' : 'Help',
    icon: HelpOutline,
  };

  const defaultNavItems = [overview, billing, usage, help];
  const fixedWirelessNavItems = [overview, billing];

  const isFixedWirelessUser =
    productKind === ProductInstanceTypes.WirelessInternet;

  const navItems = isFixedWirelessUser
    ? fixedWirelessNavItems
    : defaultNavItems;

  return <TabBar navItems={navItems} />;
};

export default MobileTabBar;
