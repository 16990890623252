import React from 'react';
import { Surface } from '@vst/beam';
import { Address as IRAAddress } from '@mfe/shared/schema-types';

import { EditAddress, EditAddressProps } from '../EditAddress';
import { StaticAddress } from '../StaticAddress';
import { useSelector } from 'react-redux';
import { selectStreamOnPromo } from '@mfe/to-be-migrated/redux/streamOnPromo';

interface AddressProps extends EditAddressProps {
  address: IRAAddress;
}

export const Address = ({ address, onSubmit, onCancel }: AddressProps) => {
  const { isEdittingAddress } = useSelector(selectStreamOnPromo);
  return (
    <Surface variant="primary" radius="16px" theme="light">
      {isEdittingAddress ? (
        <EditAddress onSubmit={onSubmit} onCancel={onCancel} />
      ) : (
        <StaticAddress address={address} />
      )}
    </Surface>
  );
};

export default Address;
