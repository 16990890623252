import { EditableCardProps, EditableCardType } from './EditableCard';
import styles from '../BodyContent.module.scss';
import { Txt } from '@vst/beam';
import LoadingAddress from './LoadingAddress';
import { Suspense } from 'react';
import React from 'react';
import ContactInformation from './ContactInformation';
import LoadingContactInformation from './LoadingContactInformation';

const EditableAddress = React.lazy(() => import('./EditableAddress'));
const EditableContactInformation = React.lazy(
  () => import('./EditableContactInformation')
);

interface BodyElementProps
  extends Omit<EditableCardProps, 'setEditingCard' | 'editingCard'> {
  loadingDiv: JSX.Element;
  editingOpen: boolean;
  clearOnClick: ((type: EditableCardType) => void) | undefined;
  cancelOnClick: (cancel: boolean) => void;
}

const EditableBodyElement = (props: BodyElementProps): JSX.Element => {
  if (props.loading) return props.loadingDiv;
  if (!props.editingOpen || props.closeAfterReload) {
    if (props.setCloseAfterReload && props.closeAfterReload) {
      props.setCloseAfterReload(false);
      props.cancelOnClick(false);
    }
    if (props.type === EditableCardType.ContactInformation)
      return <ContactInformation />;
    return (
      <div className={styles['addressBody']}>
        <Txt variant="bodySmallRegular">{props.text}</Txt>
      </div>
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  props.clearOnClick ??= () => {};

  if (props.editingOpen && !props.closeAfterReload)
    switch (props.type) {
      case EditableCardType.Billing:
      case EditableCardType.Shipping:
        return (
          <Suspense fallback={<LoadingAddress />}>
            <EditableAddress
              currentTab={props.currentTab}
              type={props.type}
              setRestrictSwitchTabs={props.setRestrictSwitchTabs}
              cancelOnClick={props.cancelOnClick}
              clearOnClick={props.clearOnClick}
              setShowModal={props.setShowModal}
            />
          </Suspense>
        );
      case EditableCardType.ContactInformation:
        return (
          <Suspense fallback={<LoadingContactInformation />}>
            <EditableContactInformation
              setRestrictSwitchTabs={props.setRestrictSwitchTabs}
              clearOnClick={props.clearOnClick}
              cancelOnClick={props.cancelOnClick}
            />
          </Suspense>
        );
      default:
        return <div />;
    }
  return <div />;
};

export default EditableBodyElement;
