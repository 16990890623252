import styles from './orderSummary.module.scss';

const Icon = () => {
  return (
    <svg
      className={styles['iconContainer']}
      xmlns="http://www.w3.org/2000/svg"
      width="300"
      height="175"
      fill="none"
      viewBox="0 0 300 175"
    >
      <g clipPath="url(#clip0_199_18109)">
        <g clipPath="url(#clip1_199_18109)" opacity="0.5">
          <path
            fill="#9BE1FF"
            d="M139.851 28.84l-33.44-20.415c-21.09-12.891-46.386 6.88-41.92 32.708 1.05 6.06.408 12.324-1.84 17.986-2.25 5.663-6.003 10.467-10.781 13.796-3.76 2.615-6.901 6.154-9.17 10.332-2.269 4.177-3.602 8.875-3.89 13.715a33.317 33.317 0 002.227 14.147c1.754 4.46 4.449 8.41 7.867 11.531l26.45 24.141c5.209 4.756 11.201 8.395 17.659 10.723l15.102 5.485c26.163 9.456 54.338 10.146 80.866 1.981 26.528-8.166 50.183-24.809 67.892-47.768l10.884-14.063c21.068-36.33-6.742-82.82-45.868-76.655l-52.761 8.32c-10.097 1.563-20.387-.533-29.277-5.964z"
          ></path>
        </g>
        <path fill="#C3CDD5" d="M16.25 151.25H276.25V152.479H16.25z"></path>
        <g clipPath="url(#clip2_199_18109)">
          <path
            fill="#9FAFBC"
            d="M113.449 151.25h-2.655v-.418l2.852-68.872h5.9l-6.097 69.29zm-1.794-.776h1.202l6.062-67.738h-4.538l-2.726 67.738z"
          ></path>
          <path
            fill="#9FAFBC"
            d="M116.431 82.88l-4.583 68.086.697.058 4.583-68.086-.697-.058zM197.866 151.25h-2.565l-6.205-69.29h5.99v.358l2.78 68.932zm-1.937-.776h1.201l-2.815-67.738h-4.43l6.044 67.738z"
          ></path>
          <path
            fill="#9FAFBC"
            d="M192.401 82.718l-.698.058 4.637 68.082.697-.059-4.636-68.081z"
          ></path>
          <path
            fill="#677A89"
            d="M190.925 147.908h-2.582l-6.188-69.27h5.883v.378l2.887 68.892zm-1.937-.796h1.202l-2.816-67.639h-4.448l6.062 67.639z"
          ></path>
          <path
            fill="#677A89"
            d="M185.448 79.37l-.697.058 4.636 68.082.698-.059-4.637-68.081zM120.389 147.908h-2.564v-.418l2.869-68.852h5.901l-6.206 69.27zm-1.793-.796h1.166l6.044-67.639h-4.448l-2.762 67.639z"
          ></path>
          <path
            fill="#677A89"
            d="M123.376 79.529l-4.583 68.086.697.058 4.583-68.086-.697-.058z"
          ></path>
          <path
            fill="#9FAFBC"
            d="M190.889 99.03l-71.952-12.713.107-.776 71.953 12.712-.108.776z"
          ></path>
          <path
            fill="#9FAFBC"
            d="M117.878 99.486l-.125-.756 71.988-12.752.126.776-71.989 12.732zM200.699 76.012h-93.348v7.082h94.048v-7.082h-.7z"
          ></path>
          <path
            fill="#677A89"
            d="M201.399 82.836h-94.048v1.353h94.048v-1.353z"
          ></path>
        </g>
        <g clipPath="url(#clip3_199_18109)">
          <path
            fill="#677A89"
            d="M212.693 107.22l21.229.069 9.268-40.165-20.546-.691-9.951 40.787z"
          ></path>
          <path
            fill="#9FAFBC"
            d="M213.634 106.753l10.337-40.925s-2.634-1.393-2.812-.633l-10.337 40.924 2.812.634zM241.24 152.5h2.634l-10.199-43.419-3.228.868L241.24 152.5z"
          ></path>
          <path
            fill="#677A89"
            d="M189.503 152.5h-2.634l9.674-43.419 3.238.361-10.278 43.058zM222.407 152.5h2.634l-4.021-43.419-3.228.868 4.615 42.551z"
          ></path>
          <path
            fill="#9FAFBC"
            d="M210.346 152.5h-2.634l4.02-43.419 3.228.361-4.614 43.058z"
          ></path>
          <path
            fill="#9FAFBC"
            d="M235.18 105.807h-46.371v4.239h45.618l.753-4.239z"
          ></path>
          <path
            fill="#9FAFBC"
            d="M241.565 65.37l2.81.627-9.724 42.152-2.81-.628 9.724-42.151z"
          ></path>
          <path
            fill="#677A89"
            d="M187.195 90.753h27.894v2.534h-27.894v-2.534z"
          ></path>
          <path
            fill="#677A89"
            d="M189.562 92.117h2.594v13.798h-2.594V92.117z"
          ></path>
          <path
            fill="#9FAFBC"
            d="M209.037 90.52h28.75v3.04h-28.75v-3.04z"
          ></path>
          <path
            fill="#9FAFBC"
            d="M211.432 92.344h2.396v17.621h-2.396V92.344z"
          ></path>
        </g>
        <g clipPath="url(#clip4_199_18109)">
          <path
            fill="#006A85"
            d="M41.553 119.692s-4.71-27.25-4.245-41.645c.756-24.051 4.865-26.737 4.865-26.737s1.28 35.919.737 50.018c-.36 10.979.107 21.97 1.396 32.877l-2.753-14.513z"
          ></path>
          <path
            fill="#03485C"
            d="M44.984 146.171a129.133 129.133 0 00-3.314-22.155c-2.598-9.557-8.936-22.945-10.739-41.349-1.803-18.403 1.938-21.464 1.938-21.464s4.556 29.62 7.444 46.265c2.888 16.647 5.233 34.537 5.408 38.664l-.737.039z"
          ></path>
          <path
            fill="#006A85"
            d="M44.984 146.171a129.133 129.133 0 00-3.314-22.155c-2.598-9.557-8.936-22.037-10.739-40.44-1.803-18.404 1.938-22.373 1.938-22.373s-2.345 14.533 5.641 44.133a266.166 266.166 0 017.21 40.796l-.736.039z"
          ></path>
          <path
            fill="#006A85"
            d="M44.655 145.993s2.093-17.455 2.713-32.917c.62-15.46 7.134-27.644 7.657-42.099.523-14.454-4.09-21.602-4.09-21.602s.756 22.866-2.345 36.748c-3.102 13.881-2.695 23.695-2.772 36.195a161.81 161.81 0 01-1.939 23.695l.776-.02z"
          ></path>
          <path
            fill="#03485C"
            d="M46.903 146.112s.62-21.543-3.024-35.958c-3.45-13.664-1.725-36.728 2.617-41.171 0 0 .233 11.591.62 20.28.388 8.688.892 42.079.388 56.849h-.6z"
          ></path>
          <path
            fill="#006A85"
            d="M46.903 146.112s.66-22.392-1.337-37.084c-2.152-15.797-.892-33.568.93-40.045 0 0 .233 11.591.62 20.28.388 8.688.892 42.079.388 56.849h-.6z"
          ></path>
          <path
            fill="#03485C"
            d="M43.918 146.211s-2.249-15.699-4.361-25.039c-2.113-9.34-8.626-20.003-11.805-30.587 0 0-1.396 11.73 2.384 19.49 3.78 7.76 7.21 9.182 9.847 18.64a89.566 89.566 0 013.16 17.535l.775-.039zM48.415 146.132A185.243 185.243 0 0151.42 126.8c2.17-10.071 7.094-12.894 8.18-26.973a74.522 74.522 0 00-.989-22.037s-5.078 26.855-6.125 34.733c-1.047 7.879-2.113 16.686-3.276 24.111-1.027 6.753-1.531 9.557-1.531 9.557l.736-.059z"
          ></path>
          <path
            fill="#03485C"
            d="M48.415 146.132A185.243 185.243 0 0151.42 126.8c2.17-10.071 7.094-12.894 8.18-26.973a74.522 74.522 0 00-.989-22.037 89.717 89.717 0 01-.523 14.869 124.482 124.482 0 01-3.315 18.64 169.37 169.37 0 00-5.563 25.335c-1.027 6.753-1.531 9.557-1.531 9.557l.736-.059z"
          ></path>
          <path
            fill="#677A89"
            d="M57.447 142.271H34.392a12.712 12.712 0 00-1.267 5.534 12.82 12.82 0 003.744 9.056 12.768 12.768 0 0018.076 0 12.82 12.82 0 003.744-9.056 12.712 12.712 0 00-1.242-5.534z"
          ></path>
        </g>
        <g clipPath="url(#clip5_199_18109)">
          <path
            fill="#677A89"
            d="M166.235 76.146h7.49l.988-9.008h-7.505l-.973 9.008z"
          ></path>
          <path
            fill="#677A89"
            d="M161.374 75.66h23.655v1.215h-23.655V75.66z"
          ></path>
          <path
            fill="#F2F5F8"
            d="M160.067 69.188h25.676a1.076 1.076 0 001.063-1.063l-1.762-16.118a1.061 1.061 0 00-1.064-1.063h-25.66a1.065 1.065 0 00-1.063 1.063l1.686 16.118a1.08 1.08 0 001.124 1.063z"
          ></path>
          <path
            fill="#9FAFBC"
            d="M160.067 69.507h25.675a1.383 1.383 0 001.383-1.382l-1.762-16.148a1.38 1.38 0 00-1.383-1.352h-25.66a1.384 1.384 0 00-1.383 1.382l1.748 16.148a1.384 1.384 0 001.382 1.352zm23.913-18.229a.746.746 0 01.745.745l1.762 16.132a.745.745 0 01-.745.714h-25.675a.725.725 0 01-.678-.46.725.725 0 01-.051-.284l-1.762-16.148a.746.746 0 01.744-.714l25.66.015z"
          ></path>
          <path
            fill="#32424E"
            d="M160.371 53.131h21.938c.26 0 .471.211.471.471v.016c0 .26-.211.47-.471.47h-21.938a.471.471 0 01-.471-.47v-.016c0-.26.211-.47.471-.47z"
          ></path>
          <path
            fill="#C3CDD5"
            d="M176.673 60.423h6.715l-.638-4.922h-6.715l.638 4.922zM163.44 66.165h20.646l-.44-4.937h-20.844l.638 4.937zM168.818 60.423h6.715l-.653-4.922h-6.7l.638 4.922z"
          ></path>
        </g>
        <g clipPath="url(#clip6_199_18109)">
          <path
            fill="#465967"
            d="M136.361 74.126h11.204v2.124h-11.204v-2.124z"
          ></path>
          <path
            fill="#677A89"
            d="M142.699 58.553v15.573h-1.472V59.424l1.472-.87z"
          ></path>
          <path
            fill="#677A89"
            d="M148.546 52.817l.566.668-14.701 12.181-.566-.667 14.701-12.182z"
          ></path>
          <path
            fill="#DAEE6B"
            d="M136.311 62.763l.753.888-2.561 2.123-.753-.889 2.561-2.122z"
          ></path>
          <path
            fill="#C3CDD5"
            d="M158.75 57.569l-4.894.375-2.095 4.73s3.552-.549 6.989-5.105z"
          ></path>
          <path
            fill="#DAEE6B"
            d="M154.561 61.79c-1.337 0-2.42-1.07-2.42-2.392 0-1.321 1.083-2.392 2.42-2.392 1.336 0 2.419 1.07 2.419 2.392 0 1.321-1.083 2.392-2.419 2.392zM151.354 52.101l-.623-.851a5.667 5.667 0 00-2.617 1.836l.746 1.16 2.494-2.145z"
          ></path>
          <path
            fill="#111920"
            d="M151.355 52.1l.935 1.24c.549.51 3.688 1.032 6.46 4.228a31.468 31.468 0 00-6.989 5.106c-2.236-3.303-1.823-6.794-2.209-7.37l-.868-1.34a5.526 5.526 0 012.671-1.863z"
          ></path>
        </g>
        <g clipPath="url(#clip7_199_18109)">
          <path
            fill="#AE917A"
            d="M213 41.41c3.086 2.473 8.224-.284 8.224-.284s-2.503 3.71.176 8.396l.513.897s.288.532.413.84c.125.31.257.619.257.619v.062c0 1.502.187 6.492 2.434 6.622.92 1.75-1.959 3.574-3.567 4.038-1.609.463-3.856.97-5.828-.44-2.128-1.527.626-3.759.626-3.759s1.834.897.989-4.44a6.392 6.392 0 01-1.452.174c-2.91 0-3.849-5.349-4.15-8.805-.544-4.124 1.365-3.92 1.365-3.92zM223.791 47.587c.056.229.175.476.444.476a1.969 1.969 0 001.118-.763c.27-.377.395-.836.353-1.296 0-1.014-.388-2.047-1.346-2.09-.369 0-.626.489-.626.958a15.667 15.667 0 000 1.787c-.006.31.013.62.057.928z"
          ></path>
          <path
            fill="#8E0D13"
            d="M213.951 49.794c-.626.075-.313.922-.313.922a1.958 1.958 0 001.559.927c1.014 0 2.259-1.62 2.178-1.62-.081 0-1.79-.31-2.103-.383-.313-.074-.701.228-.701.228a.855.855 0 00-.62-.074z"
            opacity="0.3"
          ></path>
          <path
            fill="#590409"
            d="M213.951 49.794c-.626.075-.313.922-.313.922s.557 0 1.34-.075a13.434 13.434 0 002.397-.618c-.075 0-1.79-.31-2.103-.383-.313-.074-.701.228-.701.228a.855.855 0 00-.62-.074z"
          ></path>
          <path
            fill="#000"
            d="M214.64 48.385l-1.371-.353a4.54 4.54 0 001.196.618c.275-.012.175-.265.175-.265z"
            opacity="0.3"
          ></path>
          <path
            fill="#1C262F"
            d="M211.566 45.3c.222.147.475.244.739.284a.276.276 0 01.194-.08.283.283 0 01.282.277.283.283 0 01-.564 0 .267.267 0 01.044-.105 1.434 1.434 0 01-.695-.377zM215.954 45.3c.222.147.474.244.739.284a.276.276 0 01.194-.08.275.275 0 01.199.475.285.285 0 01-.398 0 .276.276 0 01-.083-.198.24.24 0 01.044-.105 1.434 1.434 0 01-.695-.377z"
          ></path>
          <path
            fill="#194C5E"
            d="M213.557 44.675v-.495s-2.504-.983-3.18-.29a10.07 10.07 0 013.18.785zM215.485 44.495a4.985 4.985 0 014.175-.618s-1.527-1.305-4.237 0l.062.618z"
          ></path>
          <path
            fill="#194C5E"
            d="M223.728 46.647c.006.305.033.608.082.909.05.223.175.464.432.464a1.92 1.92 0 001.1-.744c.265-.37.388-.82.346-1.272 0-.995-.382-2.01-1.321-2.053-.363.031-.626.483-.626.946a14.805 14.805 0 00-.013 1.75zm-2.535-11.494a2.63 2.63 0 01.232-.946c.789-1.688 3.292-2.214 5.589-1.169 2.298 1.045 3.531 3.259 2.748 4.947a2.834 2.834 0 01-1.521 1.39c.088.093.169.192.244.285 3.756 4.631-2.209 9.838-4.212 10.598.09.715.339 1.401.73 2.01a4.902 4.902 0 001.53 1.508 4.689 4.689 0 01-1.466-1.273 4.62 4.62 0 01-.819-1.75c.181 1.304.444 5.1-2.297 6.684 0 0 2.554-2.758-.388-7.556-2.942-4.798-.445-8.657-.445-8.657s-5.007 2.746-8.062.279c0 0-1.877-.204-1.339 3.914 0 0-3.13-2.566-2.954-5.442.231-3.376 4.3-7.104 12.43-4.822z"
          ></path>
          <path
            fill="#000"
            d="M222.32 51.315c-.626.952-2.504 3.481-4.901 4.044l-.169-1.385c2.297-.513 3.862-2.108 4.913-3.06 0 .018.013.03.157.401z"
            opacity="0.2"
          ></path>
          <path
            fill="#000"
            d="M203.25 140.251l-4.736 14.124-1.322-.443 4.737-14.124 1.321.443zM153.806 132.524l-14.841-1.288.12-1.388 14.842 1.288-.121 1.388z"
          ></path>
          <path
            fill="#006A85"
            d="M194.387 143.563l2.327 5.335s-.493 4.668.5 5.014l4.742-14.136c-1.027-.347-4.728.353-4.728.353l-.667 1.961-2.174 1.473zM148.525 124.682l-4.668 3.514s-4.634.613-4.754 1.667l14.85 1.287c.093-1.08-1.454-4.508-1.454-4.508l-2.06-.18-1.914-1.78z"
          ></path>
          <path
            stroke="#1C262F"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="0.1"
            d="M196.021 147.297l.914-.686M195.581 146.244l.914-.687M195.141 145.19l.914-.687M145.252 127.149l.88.727M146.172 126.475l.88.727M147.092 125.802l.88.727"
          ></path>
          <path
            fill="#00A2C0"
            d="M177.599 91.687c-7.169.666-21.159 21.338-24.64 25.713l8.436 7.335 17.104-21.265 34.001 4.655s10.312 0 12.004-8.176a43.414 43.414 0 00-.54-13.624l.506-2.834-15.337-2 .22 2.594-5.521 5.188c-10.009.86-23.373 2.007-26.233 2.414z"
          ></path>
          <path
            fill="#006A85"
            d="M211.875 108.125l-25.313 1.25 10.579 23.372-7.622 4.541-15.227-25.15s-1.921-4.114 0-7.775c.32-.6 2.7-2.627 6.895-1.594 3.387.667 7.613 1.147 10.688 1.594 10.169 1.447 20 3.762 20 3.762z"
          ></path>
          <path
            stroke="#03485C"
            strokeMiterlimit="10"
            strokeWidth="0.1"
            d="M223.857 86.752l-14.396-2.714M217.442 85.558c-.4 2.161-.32 2.701 1.027 3.055 1.198.254 2.409.441 3.628.56M210.594 84.298s0 2.667-2 2.18l-1.107-.28M215.355 89.593s-6.801-.714-11.496-.3M212.108 84.578a46.79 46.79 0 00-1.02 4.668M214.122 84.918l-1.327 4.461"
          ></path>
          <path
            fill="#DAEE6B"
            d="M225.144 56.485c.914.133 1.668.853 2.948 1.867 2.054 1.594 2.487 3.127 2.527 4.168.073 1.74-.547 3.654-1.487 5.654-2.114 7.375-5.515 15.37-5.421 16.671.146 1.94-13.83 2.28-13.924-2.987-.053-2.981-2.92-6.349-2.807-11.89 0 0-6.035-2.34-5.908-2.927.127-.587 4.994-8.73 4.994-8.73 2.708-4.247 9.489-2.186 9.489-2.186.827 1.38 2.714 1.847 3.708 1.813a16.367 16.367 0 005.881-1.453z"
          ></path>
          <path
            stroke="#1B4805"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="0.1"
            d="M206.98 69.935c-.127-2.26.82-7.916 3.381-10.67M223.511 60.74s-5.142 5.074-7.896 9.121l5.889 5.102s5.174-3.534 7.541-7.789"
          ></path>
          <path
            fill="#AE917A"
            d="M194.54 134.328c.88 2.227 1.8 4.561 2.561 6.194l-.527 1.567-1.507 1.014-4.461-6.442s3.921-2.373 3.934-2.333zM157.387 121.227c-2.001 1.861-4.168 3.895-5.522 5.335l-1.42-.12-1.374-1.274 4.708-7.028s3.601 3.094 3.608 3.087zM180.153 69.841a1.993 1.993 0 00-.06-.286h.466l18.472 1.373 2.407-3.467c.788.474 1.61.889 2.461 1.24 1.84.794 3.054 1.274 3.054 1.274v.34c-3.335 2.76-6.849 5.521-7.416 5.414l-19.391-3.767c.027-.727.033-1.427.007-2.12zM172.477 71.942c-.42.953-1.1 3.4-1.1 3.4s.467.98 1.08-.326c.553-1.187 1.394-2.754 1.534-3.02-.314-.348-1.407-.294-1.514-.054z"
          ></path>
          <path
            fill="#AE917A"
            d="M172.57 75.636s.554.333.667-.573c-.133.48.393.373.514.1 0-.034.433-2.828 1.333-3.054.9-.227-2.147.806-2.854 1.006-.073.027.34 2.521.34 2.521z"
          ></path>
          <path
            fill="#AE917A"
            d="M169.43 74.176s1.887-1.7 2.754-2.281a28.259 28.259 0 014.914-2.034 30.61 30.61 0 013.435-.34h.033v2.487l-.534-.1a2.15 2.15 0 00-.666 0 5.54 5.54 0 00-1.154 2c-.227.608-.567.721-1.18 1.488-.667.873-1.514 2.047-1.514 2.047a1.454 1.454 0 01-.087-1.787 3.104 3.104 0 00.627-1.82c.087-2.001-2.56-1.127-2.56-1.127s-1.741.98-2.961 1.753c-1.221.774-1.107-.286-1.107-.286zM178.765 79.23c.174-.62.394-2.36 1.334-2.394-.12-.427.393-.5.393-.5.792.004 1.582-.063 2.361-.2a2.53 2.53 0 011.594.227s2.047.607 3.2.893c1.154.287 6.269.827 6.269.827s3.067 2.26-.774 1.954c-2.03.093-4.037.46-5.968 1.094-.76.326-2.667-.427-2.667-.427l-2.741-.667s-1.801.667-2.001.794c-.2.126-.553-.08-.553-.08a.341.341 0 01-.246.137.349.349 0 01-.267-.09c-.387-.254.066-1.568.066-1.568zm1.054.5l1.567-.78c-.266-.07-.539-.1-.814-.093-.273.267-.521.56-.74.873h-.013z"
          ></path>
          <path
            fill="#AE917A"
            d="M189.215 80.224c-.187-.794 1.727-2.44 2.054-2.487l18.611-.12 5.808-7.682 5.495 4.667s-8.969 7.829-9.636 7.762l-20.472-2-1.86-.14z"
          ></path>
          <path
            stroke="#624834"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="0.1"
            d="M182.827 76.963l-2.741-.127M182.607 77.797l-1.807-.167a.86.86 0 00-.854.353l-.787 1.014-.5.887M182.92 79.01l-1.52-.06s-2.347 1.1-2.361 1.334a.935.935 0 00.12.453M172.491 74.963a12.45 12.45 0 011.301-2.314"
          ></path>
          <path
            stroke="#624834"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="0.1"
            d="M171.925 73.636c.8-.474 1.3-.8 1.3-.8a4.031 4.031 0 011.334-.28M173.232 75.143c.033-.26.26-2.04.666-2.514"
          ></path>
        </g>
        <g clipPath="url(#clip8_199_18109)">
          <path
            fill="#775C4D"
            d="M99.605 41.14c-4.915 0-6.098-5.735-6.098-5.735v-5.16s.146-.122-.636-.912H92.7c-1.024.09-1.456 1.23-1.463 2.364a2.227 2.227 0 001.64 2.351c.04.007.082.007.122 0 0 0 .089 6.372 0 9.106 2.645 5.165 8.366 4.298 9.476 1.7.122-.286.189-.594.189-.916 0-1.18-.083-3.875-.089-3.875a4.39 4.39 0 01-2.97 1.077z"
          ></path>
          <path
            fill="#775C4D"
            d="M100.292 35.272c-2.295.102-3.097.675-3.103 1.707a1.475 1.475 0 001.024 1.612c.763.319 1.545-.223 2.06-.223.515 0 1.45.364 1.946.128a1.773 1.773 0 00.814-1.491c0-.459-.452-1.835-2.741-1.733z"
          ></path>
          <path
            fill="#775C4D"
            d="M105.099 29.836c-.057.153-.045-.114-.045-.114l-.508-4.595s-5.475-1.21-9.538-.095c0 0-.674-.115-.687 2.606-.013 2.72-.248 5.34 0 6.589.248 1.248 1.628 1.828 1.628 1.828s4.852-2.816 7.63 0l1.31-1.274a9.541 9.541 0 00.28-2.32c.051-1.127-.032-1.81-.07-2.625z"
          ></path>
          <path
            fill="#000"
            d="M98.047 36.196c0 .777.916 1.408 2.061 1.414 1.144.007 2.066-.51 2.072-1.274a13.16 13.16 0 01-2.066.044c-1.176-.063-2.067-.184-2.067-.184zM100.591 33.59c.102-.746 1.202-.44 1.831-.568-.591.247-1.204.437-1.831.568z"
            opacity="0.3"
          ></path>
          <path
            fill="#1C262F"
            d="M103.147 30.76a.394.394 0 100-.788.394.394 0 000 .788zM98.429 30.741a.395.395 0 10-.001-.79.395.395 0 000 .79z"
          ></path>
          <path
            fill="#131C24"
            d="M102.104 28.218c0-.249 1.717-.09 2.9.057 0 0 .044.338 0 .344-1.908.312-2.836.363-2.836.363a3.565 3.565 0 01-.064-.764zM99.573 28.983a40.758 40.758 0 01-4.374-.638s4.45-.605 4.45-.184c.008.276-.018.551-.076.822z"
          ></path>
          <path
            fill="#131C24"
            d="M99.027 17.506c-8.566-.044-8.597 8.979-6.314 11.827.775.79.635 1.013.635 1.013v5.263s1.317 5.608 6.232 5.627a4.363 4.363 0 003.014-1.103c.155-.134.302-.279.439-.433 1.716-1.95 1.945-5.047 1.945-5.047l-.051.052-1.271 1.274c-2.785-2.81-7.63-.032-7.63-.032s-1.45-.567-1.667-1.803c-.216-1.236 0-3.823 0-6.493 0-2.67.687-2.55.687-2.55 4.025-1.095 9.385.046 9.385.046l.535 4.931.095-.235c.693-1.72 4.343-12.292-6.034-12.337zm1.271 17.804c2.239-.096 2.677 1.274 2.671 1.695a1.701 1.701 0 01-.789 1.453c-.483.229-1.475-.121-1.907-.121-.432 0-1.272.528-2.016.21a1.431 1.431 0 01-.998-1.568c.006-1.007.788-1.567 3.027-1.67h.012z"
          ></path>
          <path
            fill="#000"
            d="M81.412 168.43l14.249 1.063v.425l-11.422.05-2.82-.437v.45L76.97 170l-.006-.848 4.447-.722zM103.094 168.43l14.242 1.063.007.425-11.422.05-2.827-.437.007.45-4.454.019v-.848l4.447-.722z"
          ></path>
          <path
            fill="#590409"
            d="M84.43 163.89l5.456 3.394s5.743.861 5.743 1.785v.424l-11.275.051-2.967-.45-4.467.057c0-.949 2.291-3.799 2.291-3.799h2.597l2.623-1.462zM106.106 163.89l5.456 3.394s5.743.861 5.781 1.785v.424l-11.275.051-2.967-.45-4.467.057c0-.949 2.285-3.799 2.285-3.799h2.597l2.59-1.462z"
          ></path>
          <path
            fill="#00C2DE"
            d="M84.143 163.225l.096.766-2.399 1.33-2.559.006.772-1.558.051-.608 4.04.064zM105.5 160.774l.542 4.103-3.905 1.412-1.576-.114 1.174-2.406.466-4.983 3.299 1.988z"
          ></path>
          <path
            stroke="#32424E"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="0.1"
            d="M87.844 166.036l-1.231.355M86.856 165.409l-1.232.355M85.866 164.789l-1.231.354M109.52 166.036l-1.231.355M108.531 165.409l-1.231.355M107.542 164.789l-1.231.354"
          ></path>
          <path
            fill="#32424E"
            d="M111.607 92.713l-1.8 36.104-3.222 34.364-6.304.031-1.175-32.463-1.537-21.263-5.239 20.452-7.249 33.3-6.176-.089 3.413-33.319 2.195-42.86 24.439 2.026 2.655 3.717z"
          ></path>
          <path
            fill="#000"
            d="M97.569 109.486l2.157-6.376-.638 27.373-1.52-20.997z"
            opacity="0.6"
          ></path>
          <path
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="0.1"
            d="M99.413 104.87l1.033-13.918M102.552 90.82s.14 8.617-2.757 8.548M89.044 94.638s.217 5.065-1.187 5.914c-.734.437-1.276.753-3.082.196M107.612 94.517s-.638 6.763 3.19 5.262"
          ></path>
          <path
            fill="#00528C"
            d="M108.155 46.477s3.662 2.609 4.128 3.248c.466.64 3.05 19.566 3.05 19.566l8.997 17.546-4.677 2.57-12.124-18.628-7.229-20.116 7.855-4.186z"
          ></path>
          <path
            fill="#00528C"
            d="M109.807 50.935l1.2 9.852 1.142 32.458s-4.186 2.368-6.732 2.368l-1.563-4.521s-1.627 4.432-1.837 4.654c-1.915 0-13.317 1.013-18.632-3.242l1.843-15.919-6.106-27.676s7.357-3.704 12.296-5.566c.702-.266 3.254.962 3.254.962s3.771 3.8 5.743 4.198c.581.108 2.642-4.128 3.107-4.001 1.404.344 2.752.885 4.001 1.608 2.91 1.406 2.284 4.825 2.284 4.825z"
          ></path>
          <path
            fill="#00528C"
            d="M75.096 73.749c.35 2.495 2.405 18.995 2.405 18.995l5.609-.341-.364-20.307 3.829-11.606s-6.381-12.727-7.44-11.543c-1.06 1.184-.115 2.09-2.668 12.08-.523 2.027-1.818 9.594-1.371 12.722z"
          ></path>
          <path
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.1"
            d="M94.596 80.492s-5.794-.14-8.474.532M94.551 80.53c-.147 1.9-.255 2.913-1.544 2.913h-6.138M96.063 59.844c-4.11.677-6.662-.273-6.662-.273M83.62 69.386l2.815-12.664M105.838 45.502l.549 3.406-1.984-.132 1.984 2.026s.708 7.016-3.114 10.53C98.877 56.899 93.491 51.2 93.491 51.2l1.844-1.9-3.113-.133-3.095-4.3M111.435 76.718l.701-23.447M103.286 61.332s1.557 16.938.708 29.893"
          ></path>
          <path
            fill="#DEE4E8"
            d="M91.532 42.5c1.525.14 8.213 3.167 8.213 3.167s3.279-1.995 3.547-1.9c.268.095.268 1.171.268 1.171s.721 8.675-.555 12.19c-3.682-4.319-12.162-13.076-12.162-13.076s.262-1.59.69-1.551z"
          ></path>
          <path
            stroke="#465967"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="0.1"
            d="M91.43 44.381l6.54 4.122 1.774-2.83 2.336 2.229 1.423-2.755"
          ></path>
          <path
            fill="#00C2DE"
            d="M101.372 47.281l-.058 1.9 2.38 3.533a18.178 18.178 0 01-.682 4.432l-3.829-4.337.817-3.425-1.487-1.685 1.276-2.026 1.583 1.608z"
          ></path>
          <path
            fill="#775C4D"
            d="M120.872 88.736v.241a4.39 4.39 0 000 2c.198.54.842 1.9.944 2.217.103.317 1.072 3.065 1.072 3.065s1.181.304 1.002-1.102a8.777 8.777 0 00-.536-2.223s.287-.398.925.235c.836.779 1.605 1.626 2.298 2.532.197.444-.441 2.052-.064 2.995.376.944.874.482.874.482s.676-2.875.74-3.3c.064-.424-1.767-4.007-1.857-4.286-.089-.278-2.188-4.04-2.265-4.3a.916.916 0 00-.108-.208s-3.031 1.659-3.025 1.652z"
          ></path>
          <path
            fill="#775C4D"
            d="M125.619 94.517c.061.412.192.81.389 1.178 0 0-.114.982.319 1.387l.415.26.217-1.812-1.34-1.013zM83.238 93.207s.715.83.868 1.089c.153.26.408 1.494.517 1.766.108.273 0 .843.153 1.267.153.424.638.956-.262 1.013-.9.057-1.34-4.433-1.276-4.692a.428.428 0 000-.361s-.16-.298 0-.082z"
          ></path>
          <path
            fill="#775C4D"
            d="M82.606 92.466c.314.27.564.605.734.981.097.681.244 1.354.44 2.014.083.297.294 1.463.409 1.792.18.55.332 1.11.453 1.678 0 .234.274 1.355.293 1.665.02.31-.09.715-.485.633-.395-.082-.72-1.393-.797-1.633l-.428-1.127-.447-1.153c-.14-.367-.255-.399-.331-.367-.077.032 0 .424.032.525.031.102.453 1.463.472 1.514.121.684.842 1.361.83 1.602-.014.24-.313.329-.396.31l-.6-.139a1.923 1.923 0 01-.638-.456 1.639 1.639 0 01-1.36-.551.726.726 0 01-.523.057 3.5 3.5 0 01-.95-.868 17.256 17.256 0 01-.855-2.532c-.14-.729-.307-2.73-.39-3.667-.019 0 4.537-.297 4.537-.278z"
          ></path>
          <path
            fill="#624834"
            d="M125.587 94.486l.536.652.459.557-.115 1.463"
          ></path>
          <path
            stroke="#624834"
            strokeMiterlimit="10"
            strokeWidth="0.1"
            d="M125.587 94.486l.536.652.459.557-.115 1.463M125.976 95.663l.147-.525"
          ></path>
          <path
            fill="#624834"
            d="M84.507 98.367c-.312-1.266-.714-2.995-1.116-4.185.497 1.359.871 2.76 1.116 4.185zM82.16 100.26c-.824-.969-.85-2.387-1.315-3.514.587 1.083.523 2.533 1.314 3.514zM80.877 99.842a4.053 4.053 0 01-.728-1.475 9.248 9.248 0 01-.44-1.596c.191.513.293 1.064.491 1.584.13.533.36 1.037.677 1.487z"
          ></path>
        </g>
        <path
          fill="#9FAFBC"
          d="M209.037 89.896h28.75v3.038h-28.75v-3.038z"
        ></path>
        <path
          fill="#9FAFBC"
          d="M211.432 91.719h2.396v17.621h-2.396V91.719z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_199_18109">
          <path fill="#fff" d="M0 0H300V175H0z"></path>
        </clipPath>
        <clipPath id="clip1_199_18109">
          <path
            fill="#fff"
            d="M0 0H236.25V166.25H0z"
            transform="translate(38.75 4.375)"
          ></path>
        </clipPath>
        <clipPath id="clip2_199_18109">
          <path
            fill="#fff"
            d="M0 0H98.75V98.75H0z"
            transform="translate(105 52.5)"
          ></path>
        </clipPath>
        <clipPath id="clip3_199_18109">
          <path
            fill="#fff"
            d="M0 0H57.5V87.5H0z"
            transform="matrix(-1 0 0 1 244.375 65)"
          ></path>
        </clipPath>
        <clipPath id="clip4_199_18109">
          <path
            fill="#fff"
            d="M0 0H115V115H0z"
            transform="translate(-11.875 45.625)"
          ></path>
        </clipPath>
        <clipPath id="clip5_199_18109">
          <path
            fill="#fff"
            d="M0 0H41.563V35H0z"
            transform="matrix(-1 0 0 1 192.812 41.875)"
          ></path>
        </clipPath>
        <clipPath id="clip6_199_18109">
          <path
            fill="#fff"
            d="M0 0H25V25H0z"
            transform="matrix(-1 0 0 1 158.75 51.25)"
          ></path>
        </clipPath>
        <clipPath id="clip7_199_18109">
          <path
            fill="#fff"
            d="M0 0H112.5V135H0z"
            transform="matrix(-1 0 0 1 241.25 21.25)"
          ></path>
        </clipPath>
        <clipPath id="clip8_199_18109">
          <path
            fill="#fff"
            d="M0 0H80V160H0z"
            transform="translate(59.375 10)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;
