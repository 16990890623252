import { HelperText, TextField } from '@vst/beam';

import { FormFieldEnum } from '../../utils';
import { useTranslation } from 'react-i18next';

import styles from './Form.module.scss';

interface ApplicationIdFieldProps {
  value: string;
  error: string;
  isValid: boolean;
  handleChange: React.FormEventHandler<HTMLInputElement>;
}

export const ApplicationIdField = (props: ApplicationIdFieldProps) => {
  const { value, handleChange, error, isValid } = props;
  const { t } = useTranslation('ACP', { keyPrefix: 'Form' });

  const hasError = error !== '' && !isValid;

  return (
    <>
      <TextField
        data-cy="acp-application-id"
        fluid
        name={FormFieldEnum.APPLICATION_ID}
        labelProps={{ labelText: t('fields.applicationId') }}
        value={value}
        onChange={handleChange}
      />

      {hasError && (
        <HelperText
          state="error"
          helpMessage={error}
          className={styles['helper-text']}
        />
      )}
    </>
  );
};
