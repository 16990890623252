import { Button, StandAloneLink } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import styles from './SubmitSection.module.scss';

interface SubmitSectionProps {
  handlePlacePlanOrder: () => void;
  handleBackNavigation: () => void;
  requiresWorkOrder: boolean;
}

export const SubmitSection = ({
  handlePlacePlanOrder,
  handleBackNavigation,
  requiresWorkOrder,
}: SubmitSectionProps) => {
  const { t } = useTranslation('NewChangePlan', {
    keyPrefix: 'orderReview.cartDetails',
  });

  const handleClick = () => {
    handlePlacePlanOrder();
  };

  return (
    <div data-cy="submit-section" className={styles['submitContainer']}>
      <Button
        onClick={handleClick}
        data-cy="submit-order-button"
        style={{ maxWidth: '400px', width: '100%', alignSelf: 'center' }}
      >
        {requiresWorkOrder ? t('next') : t('placeOrderButton')}
      </Button>

      {requiresWorkOrder && (
        <StandAloneLink
          data-cy="back-to-plan-review-link"
          onClick={(event) => {
            event.preventDefault();
            handleBackNavigation && handleBackNavigation();
          }}
          href="#"
          underline={true}
          linkSize="medium"
          style={{ textAlign: 'center', alignSelf: 'center' }}
        >
          {t('backToPlanReview')}
        </StandAloneLink>
      )}
    </div>
  );
};
