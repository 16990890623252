import { useTranslation } from 'react-i18next';

import { Txt, Icon, colors } from '@vst/beam';
import { NightsStay, PlayCircleOutline } from '@vst/beam-icons/icons';
import { Card } from '@mfe/shared/components';

import styles from './BrazilServiceCard.module.scss';

const BrazilServiceCard: React.FC = () => {
  const { t } = useTranslation('Overview');

  const benefits = [
    {
      icon: (
        <div
          className={styles['icon-wrapper']}
          style={{ backgroundColor: colors['blue'][700] }}
        >
          <Icon icon={NightsStay} color="blue_100" />
        </div>
      ),
      title: t('serviceCard.benefits.freeNightMode.title'),
      description: t('serviceCard.benefits.freeNightMode.description'),
    },
    {
      icon: (
        <div
          className={styles['icon-wrapper']}
          style={{ backgroundColor: colors['blue'][100] }}
        >
          <Icon icon={PlayCircleOutline} color="blue_700" />
        </div>
      ),
      title: t('serviceCard.benefits.imageQuality.title'),
      description: t('serviceCard.benefits.imageQuality.description'),
    },
  ];

  return (
    <Card testID="brazilServiceCard" className={styles['service-card']}>
      <Txt variant="heading5" className={styles['title']}>
        {t('serviceCard.title')}
      </Txt>
      <div className={styles['benefits-list']}>
        {benefits.map((benefit: ServiceBenefit, index) => (
          <ServiceBenefit key={index} benefit={benefit} />
        ))}
      </div>
    </Card>
  );
};

export type ServiceBenefit = {
  icon: React.ReactElement;
  title: string;
  description: string;
};

interface ServiceBenefitProps {
  benefit: ServiceBenefit;
}

export const ServiceBenefit: React.FC<ServiceBenefitProps> = (props) => {
  const {
    benefit: { icon, title, description },
  } = props;

  return (
    <div className={styles['benefit']}>
      {icon}
      <div className={styles['benefit-text']}>
        <Txt variant="bodyLargeBold">{title}</Txt>
        <Txt mt="8px" variant="bodyLargeRegular">
          {description}
        </Txt>
      </div>
    </div>
  );
};

export default BrazilServiceCard;
