import React from 'react';
import {
  View,
  StyleSheet,
  ViewProps,
  StyleProp,
  TextStyle,
  ViewStyle,
} from 'react-native';
import { Text } from './Text';
import { Color } from '../utils';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cell: { padding: 8 },
  headerText: {
    textTransform: 'uppercase',
    fontSize: 13,
  },
});

interface RowProps {
  row: string[];
  index: number;
  cellStyle?: StyleProp<TextStyle>;
  rowStyle?: StyleProp<ViewStyle>;
  columnStyles?: StyleProp<TextStyle>[];
  headerStyle?: StyleProp<TextStyle>;
  testID?: string;
}

const Row = ({
  row,
  index,
  cellStyle = {},
  rowStyle = {},
  headerStyle = {},
  columnStyles = [],
  testID = '',
}: RowProps): JSX.Element => (
  <View style={rowStyle} key={index}>
    {row.map((text: string, columnIndex): JSX.Element => {
      return (
        <Text
          small
          fixed
          style={[
            cellStyle,
            columnStyles[columnIndex],
            index === 0 ? headerStyle : {},
          ]}
          key={text}
          testID={`${testID}-col${columnIndex}`}
        >
          {text}
        </Text>
      );
    })}
  </View>
);

export interface TableProps extends ViewProps {
  rows: string[][];
  cellStyle?: StyleProp<TextStyle>;
  rowStyles?: StyleProp<ViewStyle>[];
  columnStyles?: StyleProp<TextStyle>[];
  headerStyle?: StyleProp<TextStyle>;
  bgColors?: string[];
}

export const Table = ({
  rows,
  cellStyle = styles.cell,
  rowStyles,
  columnStyles,
  headerStyle = styles.headerText,
  bgColors = [Color.white, Color.gray200],
  testID = '',
  ...otherProps
}: TableProps): JSX.Element => {
  const numColors = bgColors.length;
  const rowStyling: StyleProp<TextStyle>[] =
    rowStyles || new Array(rows.length).fill(styles.row);
  const columnStyling: StyleProp<TextStyle>[] =
    columnStyles || new Array(rows[0].length).fill({ flex: 1 });

  return (
    <View testID={testID} {...otherProps}>
      {rows.map(
        (row: string[], index): JSX.Element => (
          <Row
            {...{ row, cellStyle, headerStyle, index }}
            rowStyle={[
              rowStyling[index],
              { backgroundColor: bgColors[index % numColors] },
            ]}
            columnStyles={columnStyling}
            testID={`${testID}-row${index}`}
            key={index}
          />
        )
      )}
    </View>
  );
};
