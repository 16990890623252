import { SequenceType } from './types';

export const getTransitionSequence = (
  primary: boolean,
  secondary: boolean
): SequenceType => {
  // Using unary operation
  if (+primary + +secondary > 1) {
    console.error('Transition received more than on transition sequence prop.');
  }

  switch (true) {
    case secondary:
      return 'secondary';
    default:
      return 'primary';
  }
};
