import { useEffect } from 'react';
import { useMobileEventHandler } from '@mfe/services/cross-platform-communication';
import {
  receivePushNotificationState,
  getPushNotificationState,
  persistPushNotificationState,
} from '@mfe/to-be-migrated/redux/push-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { selectConfig } from '@mfe/shared/redux/config';

export const useMobileAppEvents = () => {
  const {
    user: { accountNumber },
  } = useSelector(selectUser);
  const { platform } = useSelector(selectConfig);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPushNotificationState({ accountNumber }));
  }, [dispatch, accountNumber]);

  useMobileEventHandler({
    receivePushNotificationStatus: (enabled) => {
      dispatch(
        receivePushNotificationState({
          enabled,
          platform,
          accountNumber,
        })
      );
    },
    subscribeToPushNotifications: (data) => {
      dispatch(
        persistPushNotificationState({
          shouldSubscribeToPushNotifications: true,
          platform,
          accountNumber,
          ...data,
        })
      );
    },
    unsubscribeToPushNotifications: (data) => {
      dispatch(
        persistPushNotificationState({
          shouldSubscribeToPushNotifications: false,
          platform,
          accountNumber,
          ...data,
        })
      );
    },
  });
};
