import React, { useState } from 'react';
import { Txt, Icon } from '@vst/beam';
import {
  LanguageOutlined,
  ChevronDownCurved,
  Check,
} from '@vst/beam-icons/icons';
import { useSelector } from 'react-redux';

import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import styles from './language-toggle.module.scss';
import {
  useSwitchLanguage,
  DisplayLanguage,
  availableLanguagesMap,
  languageOptions,
} from '../useSwitchLanguage';
import { useScreenResolution } from '@mfe/shared/util';

const SelectedItem = ({ name, testID }: { name: string; testID: string }) => {
  return (
    <div className={styles.selectedItem} data-cy={testID + '-selected'}>
      <Txt variant="smallRegular" data-cy={testID}>
        {name}
      </Txt>
      <Icon icon={Check} ml="8px" size={20} />
    </div>
  );
};

const LanguageDropdown = () => {
  const switchLanguage = useSwitchLanguage();
  const {
    locale: { displayLanguage: currentDisplayLanguage, userLocale },
  } = useSelector(selectLocale);

  return (
    <div className={styles.dropdownContainer} data-cy="languageDropdown">
      {availableLanguagesMap[userLocale].map(
        (displayLanguage: DisplayLanguage) => {
          const { long } = languageOptions[displayLanguage];
          if (currentDisplayLanguage === displayLanguage) {
            return (
              <SelectedItem testID={displayLanguage} name={long} key={long} />
            );
          }

          return (
            <Txt
              key={long}
              className={styles.item}
              variant="smallRegular"
              data-cy={displayLanguage}
              onClick={() => switchLanguage(displayLanguage)}
            >
              {long}
            </Txt>
          );
        }
      )}
    </div>
  );
};

const LanguageToggle = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    locale: { displayLanguage },
  } = useSelector(selectLocale);
  const { isLarge, isExtraLarge } = useScreenResolution();
  const isMobile = !isLarge && !isExtraLarge;

  let chevronStyles = styles.chevron;
  if (isOpen) chevronStyles += ` ${styles.flip}`;

  const onToggleClicked = () => {
    setIsOpen((open) => !open);
  };

  return (
    <>
      {isOpen && <div className={styles.overlay} onClick={onToggleClicked} />}
      <div className={styles.container}>
        <div
          className={styles.toggleContainer}
          onClick={onToggleClicked}
          data-cy="languageToggle"
        >
          <Icon
            icon={LanguageOutlined}
            color={isMobile ? 'gray_700' : 'gray_500'}
            size={24}
          />
          {!isMobile && (
            <Txt
              variant="bodySmallRegular"
              color="subtle"
              className={styles.languageText}
              data-cy="currentLanguage"
            >
              {languageOptions[displayLanguage].short}
            </Txt>
          )}
          <Icon
            icon={ChevronDownCurved}
            size={26}
            color={isMobile ? 'gray_700' : 'gray_500'}
            className={chevronStyles}
          />
        </div>
        {isOpen && <LanguageDropdown />}
      </div>
    </>
  );
};

export default LanguageToggle;
