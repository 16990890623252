import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button, StandAloneLink, PageAlert } from '@vst/beam';

import {
  STORAGE_ALERTS_KEY,
  Storage,
  saveAlertNameInStorage,
} from '@mfe/shared/util';
import {
  AlertAction,
  selectAlerts,
  Alert as AlertType,
  removeAlert,
} from '@mfe/to-be-migrated/redux/alerts';
import { EventTypes } from '@mfe/services/window-messages';
import { Issuer, selectConfig } from '@mfe/shared/redux/config';
import {
  openInNewTab,
  postBillingMessage,
} from '@mfe/to-be-migrated/redux/utils';
import { useAlertDescription } from '@mfe/shared/components';
import useNavigate from '@mfe/services/navigation';

import { goToAutopay, goToPayment } from '../shared/navigation';

import sharedStyles from '../shared/styles.module.scss';

const AlertsSection = (): JSX.Element => {
  const { t } = useTranslation('Alerts');

  const { alerts, billingAlerts } = useSelector(selectAlerts);

  const [displayAlerts, setDisplayAlerts] = React.useState<AlertType[]>([]);

  React.useEffect(() => {
    const accountAlerts = alerts.filter(
      (alert) => alert.button?.action !== AlertAction.ScheduleInstall
    );
    const allAlerts = [...accountAlerts, ...billingAlerts];
    const storageData = Storage.getItem(STORAGE_ALERTS_KEY);

    const alertsToDisplay = allAlerts.filter((alert) => {
      const wasDismissed = Object.keys(storageData || {}).includes(
        alert.title as string
      );
      const shouldDisplay =
        alert.type === 'global' ||
        (alert.title !== t('AccountStatus.allDataUsedNextGen.title') &&
          alert.title !== t('AccountStatus.dataAllUsed.title'));

      return !wasDismissed && shouldDisplay;
    });

    setDisplayAlerts(alertsToDisplay);
  }, [alerts, billingAlerts, t]);

  return (
    <div
      className={sharedStyles['notificationsContainer']}
      data-cy="billing-notifications"
    >
      {displayAlerts?.map((alert, index) => (
        <React.Fragment key={index}>
          <Alert alert={alert} />
        </React.Fragment>
      ))}
    </div>
  );
};

const Alert = ({ alert }: { alert: AlertType }): JSX.Element | null => {
  const dispatch = useDispatch();
  const { issuer } = useSelector(selectConfig);
  const isSalesforce = issuer === Issuer.Salesforce;
  const { goTo } = useNavigate();

  const onEvent = (e: React.MouseEvent, action?: AlertAction | EventTypes) => {
    e.preventDefault();
    if (!action) {
      if (alert.button?.url) {
        if (!alert.button?.openInSameTab) {
          e.preventDefault();
          dispatch(openInNewTab(alert.button.url));
        } else {
          goTo(alert.button.url);
        }
      }
    }

    if (
      action === AlertAction.OneTimePayment ||
      action === AlertAction.GoToBilling
    ) {
      goToPayment(dispatch, 'billing_page');
    } else if (action === AlertAction.AutoPay) {
      goToAutopay(dispatch, 'billing_page');
    } else if (action === AlertAction.RescheduleServiceCall) {
      goTo('ScheduleInstallation');
    } else {
      dispatch(postBillingMessage({ eventType: action as EventTypes }));
    }
  };

  const alertDescription = useAlertDescription(alert);

  return (
    <PageAlert
      data-cy="billing-alert"
      title={alert.title}
      variant={alert.level}
      description={alertDescription}
      className={sharedStyles['notification']}
      preventDefaultOnClose={true}
      onClose={() => {
        saveAlertNameInStorage(alert.title);
        dispatch(removeAlert(alert.title));
      }}
    >
      <>
        {alert.button && (
          <Button
            variant="secondary"
            buttonSize="small"
            mr="16px"
            onClick={(e) => onEvent(e, alert.button?.action)}
            disabled={isSalesforce}
          >
            {alert.button?.label}
          </Button>
        )}
        {alert?.link?.url && (
          <StandAloneLink
            variant="secondary"
            linkSize="small"
            href={alert.link.url}
            disabled={isSalesforce}
          >
            {alert.link?.label}
          </StandAloneLink>
        )}
      </>
    </PageAlert>
  );
};

export default AlertsSection;
