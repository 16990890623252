import React from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
  StyleProp,
} from 'react-native';
import { Color } from '../utils/Color';
import { Text } from './Text';
import { useDimensionContext } from '../utils';
import { getMaxWidth } from './Card';

export enum NotificationType {
  Global,
  InCard,
}

export enum NotificationColor {
  Red,
  Yellow,
  Blue,
}

export interface NotificationLink {
  label: string;
  onPress(): void;
}

export interface NotificationProps {
  type: NotificationType;
  color: NotificationColor;
  text: string;
  testID?: string;
  style?: StyleProp<ViewStyle>;
  link?: NotificationLink;
}

interface ContainerColor {
  backgroundColor: string;
  borderColor: string;
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 6,
    borderLeftWidth: 6,
  },
  interior: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  link: {
    color: Color.blue600,
    textDecorationLine: 'underline',
    textDecorationColor: Color.blue600,
    textAlignVertical: 'center',
  },
});

const getContainerColor = (color: NotificationColor): ContainerColor => {
  switch (color) {
    case NotificationColor.Red:
      return {
        borderColor: Color.red400,
        backgroundColor: Color.redFFEDE9,
      };
    case NotificationColor.Yellow:
      return {
        borderColor: Color.yellow300,
        backgroundColor: Color.yellowFFF5DD,
      };
    default:
      return {
        borderColor: Color.blue400,
        backgroundColor: Color.blueE8F8FF,
      };
  }
};

const getTextColor = (color: NotificationColor): string => {
  switch (color) {
    case NotificationColor.Red:
      return Color.red400;
    case NotificationColor.Yellow:
      return Color.orange965202;
    default:
      return Color.blue600;
  }
};

const getPadding = (
  isMobile: boolean,
  type: NotificationType
): { paddingLeft: number; paddingRight: number; paddingVertical: number } => {
  if (type === NotificationType.Global)
    return { paddingLeft: 19, paddingRight: 28, paddingVertical: 24 };
  else if (isMobile && type === NotificationType.InCard)
    return { paddingLeft: 15, paddingRight: 24, paddingVertical: 15 };
  else return { paddingLeft: 19, paddingRight: 28, paddingVertical: 14 };
};

const getShadow = (type: NotificationType): any => {
  // eslint-disable-next-line eqeqeq
  if (type == NotificationType.Global) {
    return {
      shadowColor: Color.black,
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 0.3,
      shadowRadius: 20,
      elevation: 5,
    };
  } else {
    return {};
  }
};

export const Notification = ({
  type,
  color,
  link,
  text,
  style: inputStyle,
  testID,
}: NotificationProps): JSX.Element | null => {
  const { isMobile, screenSize } = useDimensionContext();
  const style: StyleProp<ViewStyle> = [styles.container];
  style.push(
    getContainerColor(color),
    getPadding(isMobile, type),
    getShadow(type),
    getMaxWidth(screenSize)
  );
  if (inputStyle) style.push(inputStyle);
  const textColor = getTextColor(color);

  const body = (
    <View
      style={styles.interior}
      pointerEvents={link !== undefined ? 'auto' : 'none'}
    >
      <Text
        italic
        small
        style={{ color: textColor, textAlignVertical: 'center' }}
      >
        {text}
      </Text>
      {link && (
        <TouchableOpacity style={{ paddingTop: 1 }} onPress={link.onPress}>
          <Text small fixed style={styles.link}>
            {link.label}
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );

  return link ? (
    <TouchableOpacity
      onPress={link.onPress}
      activeOpacity={0.75}
      style={style}
      testID={testID}
    >
      {body}
    </TouchableOpacity>
  ) : (
    <View style={style} testID={testID}>
      {body}
    </View>
  );
};
