import React from 'react';
import { Text } from 'react-native';
import { AndromedaTextProps } from './index';
import {
  TextSize,
  getFont,
  getColor,
  getWeight,
  getFontFamily,
  createTextComponent,
} from './utils';
import { DimensionContext } from '../../utils';
import { TextStyleArray as StyleArray, SizeProps } from '../types';

export class H3 extends React.Component<Omit<AndromedaTextProps, SizeProps>> {
  private root: Text | undefined | null;

  setNativeProps(args: any) {
    return this.root && this.root.setNativeProps(args);
  }

  override render() {
    const {
      style: styleProp,
      sourceSans = false,
      uniNeue = false,
      light = false,
      book = false,
      semiBold = false,
      bold = false,
      heavy = false,
      italic = false,
      white = false,
      gray = false,
      link = false,
      fixed = false,
      ...props
    } = this.props;

    const font = getFont(sourceSans, uniNeue);
    const fontStyle = italic && font !== 'UniNeue' ? 'Italic' : '';
    const weight = getWeight(light, book, semiBold, bold, heavy);
    const textColor = getColor(white, gray, link);
    const fontFamily = getFontFamily(font, weight, fontStyle);

    return (
      <DimensionContext.Consumer>
        {(value: DimensionContext): JSX.Element => {
          const style: StyleArray = [fontFamily, textColor, styleProp];
          return createTextComponent(
            this.root,
            TextSize.H3,
            value,
            style,
            fixed,
            props
          );
        }}
      </DimensionContext.Consumer>
    );
  }
}
