import { useTranslation } from 'react-i18next';
import { Icon, Txt } from '@vst/beam';
import { DownloadOutlined } from '@vst/beam-icons/icons';

import styles from '../BodyContent.module.scss';
import loadingStyles from './Loading.module.scss';

type Props = {
  loading: boolean;
  downloadSpeed: string;
  downloadUnits: string;
};

export const DownloadSpeed = ({
  loading,
  downloadSpeed,
  downloadUnits,
}: Props) => {
  const { t } = useTranslation(['NewProfile', 'Global']);

  if (loading) {
    return <div className={loadingStyles['downloadSpeed']} />;
  }

  return downloadSpeed && downloadUnits ? (
    <div className={styles['text-spacing']}>
      <Icon icon={DownloadOutlined} size={20} color="gray_600" />
      <Txt variant={'smallRegular'} ml="8px" mr="4px">
        {t('InternetPlan.downloadSpeedUpTo')}
      </Txt>
      <Txt variant="smallBold">
        {t('InternetPlan.downloadSpeed', { downloadSpeed, downloadUnits })}
      </Txt>
    </div>
  ) : null;
};
