import React from 'react';
import { Button, Surface, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import useNavigate from '@mfe/services/navigation';
import { HelpLink } from '@mfe/legacy/mv/utils/Navigation';
import { useSelector } from 'react-redux';
import { selectConfig } from '@mfe/shared/redux/config';
import { Platform } from '@mfe/shared/schema-types';
import { useScreenResolution } from '@mfe/shared/util';

const MobileHelp = () => {
  const { t } = useTranslation('Network', { keyPrefix: 'help' });
  const { goTo } = useNavigate();
  const { platform } = useSelector(selectConfig);
  const { isSmall, isExtraSmall } = useScreenResolution();

  const isMobile = isSmall || isExtraSmall;
  if (platform === Platform.Web || !isMobile) return null;

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    goTo(`${HelpLink.Main}`);
  };

  return (
    <Surface data-cy="MobileHelp" className={styles['container']}>
      <div className={styles['textContainer']}>
        <Txt variant="bodyLargeBold" component="span">
          {t('stillHaveQuestions')}
        </Txt>
        <Txt variant="bodyLargeRegular" component="span">
          {t('learnMore')}
        </Txt>
      </div>
      <Button
        variant="secondary"
        className={styles['learnMoreButton']}
        onClick={onClick}
      >
        {t('visitHelpCenter')}
      </Button>
    </Surface>
  );
};

export default MobileHelp;
