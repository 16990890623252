import * as React from 'react';
import { useToast } from '@vst/beam';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectUtil,
  postGeneralMessage,
  setBillingPageStatistics,
} from '@mfe/to-be-migrated/redux/utils';
import {
  Route,
  resetBillingNav,
  selectBillingNav,
} from '@mfe/to-be-migrated/redux/billingNav';
import {
  setBoletoInfo,
  fetchBillingInfo,
  selectBillingInfo,
} from '@mfe/to-be-migrated/redux/billingInfo';
import i18n from '@mfe/services/translations-service';
import { scrollToTop } from '@mfe/shared/redux/utils';
import { EventTypes } from '@mfe/services/window-messages';
import { SettlementStatus } from '@mfe/shared/schema-types';
import { UpdateBillCycleModal } from '@mfe/features/change-bill-date';

import BillingHome from './home';
import AutoPay from './vpp/auto-pay';
import PaymentHistory from './payment-history';
import OneTimePayment from './vpp/one-time-payment';
import {
  getWorkOrders,
  selectWorkOrders,
} from '@mfe/to-be-migrated/redux/workOrders';

const NavSwitch = (): JSX.Element => {
  const {
    billingInfo: { invoices },
  } = useSelector(selectBillingInfo);
  const { route, props } = useSelector(selectBillingNav);
  const dispatch = useDispatch();

  React.useLayoutEffect(() => {
    dispatch(
      postGeneralMessage({
        eventType: EventTypes.ScrollTop,
      })
    );
    dispatch(scrollToTop());
  }, [route, dispatch]);

  React.useEffect(() => {
    return () => {
      dispatch(resetBillingNav());
    };
  }, [dispatch]);

  const defaultInvoice = invoices
    ?.slice()
    ?.reverse()
    ?.find(
      (invoice) => invoice.invoiceSettlementStatus !== SettlementStatus.Paid
    );
  const invoice = props.invoice ?? defaultInvoice;
  const brInvoice =
    invoice?.invoiceAmount?.alphabeticCode === 'BRL' ? invoice : undefined;

  switch (route) {
    case Route.Autopay:
      return <AutoPay />;
    case Route.OneTimePayment:
      return <OneTimePayment invoice={brInvoice} />;
    case Route.Payments:
      return <PaymentHistory />;
    case Route.Home:
    default:
      return <BillingHome />;
  }
};

const ManageBilling = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const { toasts, removeOldestToast, addToast } = useToast();
  const { workOrders } = useSelector(selectWorkOrders);

  const {
    boletoInfo: { isError },
  } = useSelector(selectBillingInfo);

  const {
    pageStats: {
      pages: {
        billing: { lastUpdatedTime },
      },
    },
  } = useSelector(selectUtil);

  React.useEffect(() => {
    if (isError) {
      if (toasts.length) removeOldestToast();

      addToast({
        liteMode: true,
        theme: 'light', //this feels backwards but this is actually how it works on litemode
        variant: 'error',
        body: i18n.t('Alerts:Billing.newBoletoRequestFailed.title'),
        disableAutoDismiss: true,
        onClose: () => {
          dispatch(setBoletoInfo({ isError: undefined, loading: false }));
        },
      });
    }
  }, [addToast, dispatch, isError, removeOldestToast, toasts.length]);

  React.useEffect(() => {
    return () => {
      if (toasts.length) {
        removeOldestToast();
      }

      dispatch(setBoletoInfo({ isError: undefined, loading: false }));
    };
  }, []);

  React.useEffect(() => {
    if (!lastUpdatedTime) {
      dispatch(setBillingPageStatistics());
    }

    dispatch(fetchBillingInfo('billing'));
  }, [dispatch, lastUpdatedTime]);

  React.useEffect(() => {
    if (workOrders === null) dispatch(getWorkOrders());
  }, []);

  return (
    <>
      <NavSwitch />
      <UpdateBillCycleModal />
    </>
  );
};

export default ManageBilling;
