import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { InlineLink, SectionAlert, Txt } from '@vst/beam';

import {
  selectUpdateBillCycle,
  setIsConfirmationModalOpen,
} from '@mfe/to-be-migrated/redux/updateBillCycle';
import { BillCycleErrorTypes } from '@mfe/shared/schema-types';
import { Route, setBillingNav } from '@mfe/to-be-migrated/redux/billingNav';

export const ModalSectionAlert = (): JSX.Element => {
  const { t } = useTranslation('UpdateBillCycle', { keyPrefix: 'modal' });
  const dispatch = useDispatch();

  const { reason, billingCycleDayOfMonthUpdateDate } = useSelector(
    selectUpdateBillCycle
  );

  const goToPayBill = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    dispatch(setIsConfirmationModalOpen(false));
    dispatch(setBillingNav({ route: Route.OneTimePayment }));
  };

  const goToAutopay = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    dispatch(setIsConfirmationModalOpen(false));
    dispatch(setBillingNav({ route: Route.Autopay }));
  };

  let alertContent: JSX.Element | null = null;
  switch (reason) {
    case BillCycleErrorTypes.RecentlyChanged:
      alertContent = (
        <Txt color="regular" variant="linkBodySmall">
          {t('billDateChangedTooRecently', {
            billingCycleDayOfMonthUpdateDate,
          })}
        </Txt>
      );
      break;
    case BillCycleErrorTypes.NoValidPaymentMethod:
      alertContent = (
        <Txt variant="linkBodySmall" color="regular">
          {t('invalidPayment')}
          <InlineLink
            href="#"
            //TODO Implement this
            onClick={goToAutopay}
          >
            {t('addPaymentMethod')}
          </InlineLink>
        </Txt>
      );
      break;
    case BillCycleErrorTypes.OutstandingBalance:
      alertContent = (
        <Txt variant="linkBodySmall" color="regular">
          {t('balanceNotPaid')}
          <InlineLink href="#" onClick={goToPayBill}>
            {t('payBill')}
          </InlineLink>
        </Txt>
      );
      break;
    case BillCycleErrorTypes.BillDateIsToday:
      alertContent = (
        <Txt color="regular" variant="linkBodySmall">
          {t('billDateIsToday')}
        </Txt>
      );
      break;
    case BillCycleErrorTypes.AccountNotActive:
      alertContent = (
        <Txt color="regular" variant="linkBodySmall">
          {t('accountNotActive')}
        </Txt>
      );
      break;
  }

  return (
    <SectionAlert size="large" variant="error" contentSlot={alertContent} />
  );
};
