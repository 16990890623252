import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from '../../../Text';
import { buildPlanDetails } from './utils';
import { DetailType, Style, DetailProps, PlanDetailsProps } from './types';
import { DetailsBG } from '../../../../assets';
import { useDimensionContext } from '../../../../utils';
import { Expandable } from '../../../Expandable';
import { BaseCard } from '../../BaseCard';

const Detail = ({ t, detail }: DetailType): JSX.Element => {
  const {
    PlanDetails: { asterisk },
  } = t;
  const {
    title,
    text,
    tiny = false,
    titleAsterisk = false,
    testID = '',
  } = detail;
  const SurroundingView = titleAsterisk ? View : React.Fragment;
  const props: Style = titleAsterisk ? { style: { flexDirection: 'row' } } : {};

  return (
    <View style={{ zIndex: 1 }}>
      {title && (
        <SurroundingView {...props}>
          <Text
            uniNeue
            large
            fixed
            heavy
            white
            style={{ marginBottom: 2 }}
            testID={`${testID}-Title`}
          >
            {title}
          </Text>
          {titleAsterisk && (
            <Text
              uniNeue
              large
              fixed
              white
              style={{ marginBottom: 2 }}
              testID={`${testID}-Asterisk`}
            >
              {asterisk}
            </Text>
          )}
        </SurroundingView>
      )}
      <Text
        fixed
        white
        caption={!tiny}
        tiny={tiny}
        style={{ marginBottom: 17 }}
        testID={`${testID}-Text`}
      >
        {text}
      </Text>
    </View>
  );
};

export const PlanDetails = ({
  t,
  plan,
  onChoose,
  isExpanded,
  testID = '',
}: PlanDetailsProps): JSX.Element => {
  const { isMobile, isTablet } = useDimensionContext();
  const desktopScreen = !isMobile && !isTablet;
  const planDetails = buildPlanDetails(t, plan, testID);
  const svg = (
    <DetailsBG
      width={247}
      preserveAspectRatio="xMidYMin slice"
      style={{ ...StyleSheet.absoluteFillObject }}
      testID={`${testID}-BG`}
    />
  );

  const interiorComponent = (
    <>
      {!onChoose && <View style={{ height: 77 }} />}
      <BaseCard.Svg
        removeShadow
        svg={svg}
        style={styles.wrapper}
        testID={`${testID}-Details`}
      >
        {planDetails.map(
          (detail: DetailProps): JSX.Element => (
            <Detail t={t} detail={detail} key={detail.text} />
          )
        )}
      </BaseCard.Svg>
    </>
  );

  return desktopScreen ? (
    interiorComponent
  ) : (
    // @ts-ignore
    <Expandable trigger={isExpanded} opacityTimeout={isExpanded ? 1500 : 150}>
      {interiorComponent}
    </Expandable>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    paddingTop: 67,
    paddingHorizontal: 28,
    paddingBottom: 36,
    borderRadius: 8,
    overflow: 'hidden',
  },
});
