import { Txt, SectionAlert } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { CalendarToday } from '@vst/beam-icons/icons';

import { FlowSuccessOutcome, Container } from '@mfe/shared/components';

export interface ConfirmationWithEquipmentChangeProps {
  goToOverview: () => void;
  content: string;
}

export const ConfirmationWithEquipmentChange = ({
  goToOverview,
  content,
}: ConfirmationWithEquipmentChangeProps) => {
  const { t } = useTranslation('NewChangePlan', {
    keyPrefix: 'orderConfirmation.withEquipmentChange',
  });

  return (
    <Container mt="56px">
      <FlowSuccessOutcome goToOverview={goToOverview}>
        <Txt data-cy="appointmend-scheduled-title" mt="48px" variant="heading4">
          {t('title')}
        </Txt>

        <Txt variant="bodyLargeRegular" mt="12px" mb="40px">
          {t('description')}
        </Txt>

        <SectionAlert
          data-cy="appointmend-scheduled-SectionAlert"
          variant="infoPrimary"
          mb="40px"
          contentSlot={
            <div style={{ textAlign: 'left' }}>
              <Txt color="regular" component="span" variant="smallBold">
                {t('alertTitle')}
              </Txt>
              <Txt color="regular" component="span" variant="smallRegular">
                {content}
              </Txt>
            </div>
          }
          icon={CalendarToday}
        />
      </FlowSuccessOutcome>
    </Container>
  );
};
