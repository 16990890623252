import { Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import Desktop from '../../assets/Desktop';

const OrderErrorContent = () => {
  const { t } = useTranslation('NewChangePlan');

  return (
    <>
      <Desktop data-cy="change-plan-page-error-svg" />
      <div>
        <Txt
          data-cy="change-plan-page-error-title"
          variant="heading4"
          mb="12px"
        >
          {t('errorPage.title.mutationError')}
        </Txt>
        <Txt
          data-cy="change-plan-page-error-description"
          component="p"
          variant="bodyLargeRegular"
          color="subtle"
        >
          {t('errorPage.description.mutationError')}
        </Txt>
      </div>
    </>
  );
};

export default OrderErrorContent;
