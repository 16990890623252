import React from 'react';
import {
  View,
  TouchableOpacity,
  Animated,
  Easing,
  StyleProp,
  ViewStyle,
  TextStyle,
  Platform,
  LayoutChangeEvent,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { Text } from '../Text';
import { Expandable } from '../Expandable';
import { Color, DimensionContext } from '../../utils';
import { Item } from './Item';
import { styles } from './styles.web';
import { PickerProps, WebState, PickerItem } from './types';
import { buildTitleAnimation, getMaxCharacterCount } from './utils.web';
export * from './Item';
export * from './types';

export class Picker extends React.Component<PickerProps, WebState> {
  private root: View | undefined | null;

  override state: WebState = {
    currentLabel: '',
    currentValue: '',
    expanded: false,
    zIndex: 0,
    width: 311,
    animatedValue: new Animated.Value(0),
  };

  containerRef: React.RefObject<View> = React.createRef();
  borderView: React.RefObject<View> = React.createRef();
  titleRef: React.RefObject<Text> = React.createRef();
  touchableRef: React.RefObject<TouchableOpacity> = React.createRef();

  static Item = Item;

  setNativeProps(args: Record<string, any>): void | null | undefined {
    return this.root && this.root.setNativeProps(args);
  }

  setContainerStyle = (isFocus = false): void => {
    if (this.containerRef && this.containerRef.current) {
      const style = isFocus ? styles.focusContainer : styles.blurContainer;
      this.containerRef.current.setNativeProps({
        style,
      });
    }
    if (this.borderView && this.borderView.current) {
      const style = isFocus ? styles.focusBorder : styles.blurBorder;
      this.borderView.current.setNativeProps({
        style,
      });
    }
    if (this.touchableRef && this.touchableRef.current) {
      const style = isFocus ? styles.focusTouchable : styles.blurTouchable;
      this.touchableRef.current.setNativeProps({
        style,
      });
    }
    if (this.titleRef && this.titleRef.current) {
      const style = isFocus ? styles.focusTitle : styles.blurTitle;
      this.titleRef.current.setNativeProps({
        style,
      });
    }
  };

  onFocus(): void {
    this.setContainerStyle(true);
    if (this.touchableRef && this.touchableRef.current)
      this.touchableRef.current.focus();
  }

  onBlur(): void {
    if (this.touchableRef && this.touchableRef.current)
      this.touchableRef.current.blur();
    this.setContainerStyle();
  }

  override render(): JSX.Element {
    const {
      selection,
      style: styleProp,
      itemStyle: itemStyleProp,
      enabled = true,
      title = undefined,
      selectedValue = undefined,
      onValueChange = undefined,
      width: widthProps = undefined,
      testID,
      onPress = undefined,
    } = this.props;

    const {
      currentLabel,
      currentValue,
      expanded,
      zIndex,
      width: widthState,
      animatedValue,
    } = this.state;
    const width = widthProps || widthState;
    const isValidValue = currentValue !== '';

    const style: StyleProp<ViewStyle> = [styles.defaultContainerStyle];
    if (styleProp) style.push(styleProp);

    const itemStyle: StyleProp<TextStyle> = [styles.defaultItemStyle];
    if (itemStyleProp) itemStyle.push(itemStyleProp);

    const touchableStyle: StyleProp<TextStyle> = [styles.pickerTouchable];
    if (title === undefined) touchableStyle.push({ justifyContent: 'center' });

    const { scale, translateX, translateY } = buildTitleAnimation(
      animatedValue,
      isValidValue,
      width
    );
    const animateTitle = (label: string, value: string | number): void => {
      Animated.timing(animatedValue, {
        toValue: 1,
        duration: 50,
        easing: Easing.quad,
        useNativeDriver: true,
      }).start((): void => {
        this.setState({
          currentLabel: label,
          currentValue: value,
          expanded: false,
          zIndex,
          width,
          animatedValue: new Animated.Value(0),
        });
      });
    };

    const handleSelect = (label: string, value: string | number): void => {
      if (expanded) {
        if ((!isValidValue && value !== '') || (isValidValue && value === ''))
          animateTitle(label, value);
        else
          this.setState({
            currentLabel: label,
            currentValue: value,
            expanded: false,
            zIndex,
            width,
            animatedValue,
          });
        if (onValueChange !== undefined) onValueChange(value);
      }
    };

    const handlePress = (): void => {
      if (!expanded && onPress) {
        onPress();
      }

      this.setState({
        currentLabel,
        currentValue,
        expanded: !expanded,
        zIndex,
        width,
        animatedValue,
      });
    };

    const onFocusProps =
      Platform.OS === 'web' ? { onFocus: (): void => this.onFocus() } : {};
    const onBlurProps =
      Platform.OS === 'web' ? { onBlur: (): void => this.onBlur() } : {};
    const webProps =
      Platform.OS === 'web'
        ? {
            onEnter: (): void => {
              this.setState({
                currentLabel,
                currentValue,
                expanded,
                zIndex: 1,
                animatedValue,
              });
            },
            onExited: (): void => {
              this.setState({
                currentLabel,
                currentValue,
                expanded,
                zIndex: 0,
                animatedValue,
              });
            },
          }
        : {};

    return (
      <DimensionContext.Consumer>
        {(value: DimensionContext): JSX.Element => {
          const { screenSize } = value;
          const characterCount = getMaxCharacterCount(screenSize);
          const shorthandLabel =
            currentLabel.length >= characterCount
              ? currentLabel.slice(0, characterCount) + '...'
              : currentLabel;

          return (
            <View
              testID={testID}
              style={[style, { zIndex }]}
              ref={this.containerRef}
              onLayout={(event: LayoutChangeEvent): void => {
                const { width } = event.nativeEvent.layout;
                this.setState({
                  currentLabel,
                  currentValue,
                  expanded,
                  zIndex,
                  width,
                  animatedValue,
                });
              }}
              {...onFocusProps}
              {...onBlurProps}
            >
              <TouchableOpacity
                onPress={handlePress}
                disabled={!enabled}
                ref={this.touchableRef}
                activeOpacity={0.9}
                style={touchableStyle}
                {...onFocusProps}
              >
                {title && (
                  <Animated.View
                    style={{
                      transform: [{ scale }, { translateX }, { translateY }],
                    }}
                  >
                    <Text small fixed ref={this.titleRef}>
                      {title}
                    </Text>
                  </Animated.View>
                )}
                {isValidValue && (
                  <Text small fixed bold style={styles.labelWidth}>
                    {shorthandLabel}
                  </Text>
                )}
                <Icon
                  name={`chevron-${expanded ? 'up' : 'down'}`}
                  size={20}
                  color={Color.teal500}
                  style={styles.dropdownIcon}
                />
              </TouchableOpacity>
              <Expandable
                trigger={expanded}
                opacityTimeout={expanded ? 1100 : 150}
                {...webProps}
              >
                <View
                  ref={this.borderView}
                  pointerEvents={expanded ? 'auto' : 'none'}
                  style={styles.borderContainer}
                >
                  {selection.map((select: PickerItem): JSX.Element => {
                    const { label, value } = select;
                    return (
                      <Picker.Item
                        label={label}
                        value={value}
                        selected={
                          isValidValue
                            ? currentValue === value
                            : selectedValue === value
                        }
                        onPress={(): void => handleSelect(label, value)}
                        expanded={expanded}
                        key={label}
                      />
                    );
                  })}
                </View>
              </Expandable>
            </View>
          );
        }}
      </DimensionContext.Consumer>
    );
  }
}
