import { Spinner, Surface } from '@vst/beam';
import { useTranslation } from 'react-i18next';

export const ScheduleLoading = () => {
  const { t } = useTranslation('NewScheduleInstallation');
  return (
    <Surface
      radius="16px"
      style={{
        width: '100%',
        maxWidth: '612px',
        height: '450px',
        display: 'flex',
        justifyContent: 'center',
        margin: '56px auto 56px',
        alignItems: 'center',
      }}
    >
      <Spinner description={t('submit.loading')} />
    </Surface>
  );
};
