import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { UseNavigateProps } from './utils';
import { useWebDrawer, useDimensionContext } from '../Context';

// Note not importing selectLocale or localeState to avoid
// code dependency from andromeda to libs/to-be-migrated/redux
type LocaleState = {
  locale: {
    urlPrefix: string | null;
  };
};

// Same as use navigate but without any awkward append logic
export const useSimpleNavigate = (): any => {
  const history = useHistory();
  const { setDrawer, setCloseIconColor } = useWebDrawer();
  const { isMobile } = useDimensionContext();
  const {
    locale: { urlPrefix },
  } = useSelector((state) => (state as { locale: LocaleState }).locale);

  return (
    destination: string,
    config?: Omit<UseNavigateProps, 'append'>
  ): void => {
    const {
      drawer = false,
      params = undefined,
      closeIconColor = null,
    } = config || {};

    const priorRoute = history.location.pathname;
    const currentRoute = `${urlPrefix}${destination}`;

    if (closeIconColor) setCloseIconColor(closeIconColor);
    if (drawer) setDrawer(destination);

    history.push({
      pathname: drawer && !isMobile ? undefined : currentRoute,
      state: { priorRoute, currentRoute, component: destination, params },
    });
  };
};

export const useNavigate = (): any => {
  const history = useHistory();
  const { setDrawer, setCloseIconColor } = useWebDrawer();
  const { isMobile } = useDimensionContext();
  const {
    locale: { urlPrefix },
  } = useSelector((state) => (state as { locale: LocaleState }).locale);

  return (destination: string, config?: UseNavigateProps): void => {
    const {
      append = false,
      drawer = false,
      params = undefined,
      closeIconColor = null,
    } = config || {};

    const priorRoute = history.location.pathname;
    const currentRoute = `${
      append ? `${priorRoute}/` : `${urlPrefix}/`
    }${destination}`;

    if (closeIconColor) setCloseIconColor(closeIconColor);
    if (drawer) setDrawer(destination);

    history.push({
      pathname: drawer && !isMobile ? undefined : currentRoute,
      state: { priorRoute, currentRoute, component: destination, params },
    });
  };
};
