import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Confirm } from '@mfe/shared/components';

export const AddonsGridErrorState = () => {
  const history = useHistory();
  const { t } = useTranslation('ShopAddons');

  return (
    <Confirm
      title={t('errorState.title')}
      description={t('errorState.description')}
      buttons={[
        {
          children: t('errorState.reloadPageLabel'),
          variant: 'secondary',
          onClick: () => {
            window.location.reload();
          },
        },
        {
          children: t('errorState.goBackLabel'),
          onClick: () => {
            history.goBack();
          },
        },
      ]}
    />
  );
};
