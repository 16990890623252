import { useTranslation } from 'react-i18next';

import { Txt } from '@vst/beam';

import type {
  AutopayMethod as AutopayMethodType,
  Payment,
} from '@mfe/shared/schema-types';
import { CardLogo } from '@mfe/shared/components';

import styles from './styles.module.scss';
import { getAutopayTranslationContext } from '@mfe/shared/util';

type AutoPay = AutopayMethodType | Payment | null;

type Props = {
  autoPay?: AutoPay;
};

export const BillingAutoPayMethod = ({ autoPay }: Props): JSX.Element => {
  const { t } = useTranslation('Summary');

  const { context, count, last4Digits } = getAutopayTranslationContext(autoPay);

  return (
    <div id="autoPay" className={styles['container']}>
      <CardLogo name={autoPay?.isExpired ? null : autoPay?.paymentMethod} />

      <Txt variant="bodySmallItalic" color="regular" ml="16px">
        {t('autoPay', { context, count, last4Digits })}
      </Txt>
    </div>
  );
};
