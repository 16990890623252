import React from 'react';
import { Button, Txt } from '@vst/beam';
import { useTranslation } from '../../utils';
import { useSelector } from 'react-redux';
import { selectStreamOnPromo } from '@mfe/to-be-migrated/redux/streamOnPromo';
import { SpinnerLoader } from '../SpinnerLoader';

export const LoadingAnimatedButton = () => {
  const { isOrderSubmitting } = useSelector(selectStreamOnPromo);
  const { t: placeOrderTranslate } = useTranslation('PlaceOrder');

  return (
    <Button fluid disabled={isOrderSubmitting} data-cy="completeOrderButton">
      <Txt variant="bodyLargeBold" data-cy="completeOrderButtonLoading">
        <SpinnerLoader size="16px" lineWeight={5} />
        {placeOrderTranslate('Loading')}
      </Txt>
    </Button>
  );
};
