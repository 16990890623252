import React, { CSSProperties, useState, useEffect } from 'react';
import { LayoutRectangle, Platform } from 'react-native';
import { SectionValue, FaqSelection, FaqAction, LayoutMap } from './types';
import { FaqSection } from '@viasat/res-apps-lib/build/types/mv';

export const NAV_BAR_HEIGHT = 87;

export const webSmallAccordion: CSSProperties = {
  marginLeft: 32,
  marginRight: 32,
  marginBottom: 48,
  flexDirection: 'column',
  alignItems: 'stretch',
};

export const webDefaultAccordion: CSSProperties = {
  marginLeft: 72,
  marginRight: 72,
  marginBottom: 48,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
};

export const faqInitialState: FaqSelection = {
  section: SectionValue.All,
  previousSection: undefined,
  question: undefined,
  previousQuestion: undefined,
};

export const faqReducer = (
  state: FaqSelection,
  action: FaqAction
): FaqSelection => {
  switch (action.type) {
    case 'Section':
      return {
        ...state,
        previousSection: state.section,
        section: action.section,
      };
    case 'Question':
      return {
        ...state,
        previousQuestion: state.question,
        question: action.question,
      };
    default:
      throw new Error('Action type does not exist on faqReducer.');
  }
};

export const filterSections = (
  sections: FaqSection[],
  selection?: SectionValue
): FaqSection[] => {
  return sections.filter((section: FaqSection): boolean => {
    const { title } = section;
    return title === selection;
  });
};

export const initialMap: LayoutMap = {
  [SectionValue.All]: { x: 0, y: 0 },
  [SectionValue.Billing]: undefined,
  [SectionValue.Connectivity]: undefined,
  [SectionValue.Speeds]: undefined,
  [SectionValue.AccountAdmin]: undefined,
  [SectionValue.Usage]: undefined,
};

type UpdateLayoutMapArgs = {
  layout: LayoutRectangle;
  section: NonNullable<SectionValue>;
  priorState: LayoutMap;
  shouldScroll?: boolean;
  handleScroll?: () => void;
};

export const updateLayoutMap = ({
  layout,
  section,
  priorState,
  shouldScroll,
  handleScroll,
}: UpdateLayoutMapArgs): LayoutMap => {
  if (layout) {
    const { x, y } = layout;
    priorState[section] = { x, y: y + 650 };
  }
  if (shouldScroll && handleScroll) handleScroll();
  return priorState;
};

/*
 * Auto Scrolling as seen on Native & Mobile / Tablet Web Breakpoints:
 *
 * A seamless solution for Native & Browser support. Both use DOM references to obtain the filter location.
 * Native uses ScrollView's built in Native virtual DOM support & Web uses window API for scrolling.
 */
interface AutoScrollArgs {
  ref?: any;
  section?: SectionValue;
  previousSection?: SectionValue;
  previousQuestion?: string;
  layoutMap?: LayoutMap;
  isSmallScreen?: boolean;
}

export const autoScroll = ({
  ref,
  section,
  previousSection,
  previousQuestion,
  layoutMap,
  isSmallScreen = false,
}: AutoScrollArgs): void => {
  const isWeb = Platform.OS === 'web';
  // An open question alters the scroll height;
  // This gives time to finish the 'close' animation.
  const timeout = !isWeb || previousQuestion ? 250 : 1;
  if (ref && section) {
    if (isWeb) {
      if (isSmallScreen && ref[section] && ref[section].current) {
        setTimeout(() => {
          const top =
            ref[section].current.getBoundingClientRect().top -
            8 -
            NAV_BAR_HEIGHT;
          window.scrollTo({ top, left: 0, behavior: 'smooth' });
        }, timeout);
      } else {
        setTimeout(
          () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }),
          timeout
        );
      }
    } else if (
      ref &&
      section &&
      ref.current &&
      layoutMap &&
      layoutMap[section]
    ) {
      if (previousSection === section) return;
      setTimeout(
        () => ref.current.scrollTo({ ...layoutMap[section], animated: true }),
        timeout
      );
    }
  }
};

interface DynamicData<TDynamicData> {
  data: TDynamicData | undefined;
  isLoading: boolean;
  error: boolean;
  errorDescription?: string;
  refetch: () => void;
}

/**
 * Takes a dynamic import or any other type of promise and wraps it with some standard loading utils
 * like loading, error, errorDescription, and a retry
 * @param importPromise An import('./path/to/data') promise or any other promise
 * @returns The DynamicData type with data being populated with the result of the import
 */

export function useDynamicData<TDynamicData = any>(
  importPromise: Promise<any>
): DynamicData<TDynamicData> {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<TDynamicData>({} as TDynamicData);
  const [error, setError] = useState(false);
  const [errorDescription, setErrorDescription] = useState<string | undefined>(
    undefined
  );
  const [retry, setRetry] = useState(false);

  const refetch = () => {
    // Change the value of retry which will retrigger the useEffect
    setRetry((retry) => !retry);
  };

  useEffect(() => {
    importPromise
      .then((dynamicData) => {
        setData(dynamicData as TDynamicData);
        setError(false);
      })
      .catch((e: any) => {
        setError(true);
        setErrorDescription(`${e}`);
      })
      .finally(() => setIsLoading(false));
  }, [retry, importPromise]);

  return {
    isLoading,
    error,
    data,
    refetch,
    ...(errorDescription ? { errorDescription } : {}),
  };
}
