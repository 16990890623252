import { useSelector } from 'react-redux';

import { selectConfig } from '@mfe/shared/redux/config';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import {
  selectNetwork,
  selectNetworkIssues,
} from '@mfe/to-be-migrated/redux/network/networkSlice';

import { LoadingContent } from './loading-content';
import { DiagnosticCheck } from './diagnostic-check';
import { DiagnosticError } from './diagnostic-error';
import { DiagnosticDefault } from './diagnostic-default';
import { DiagnosticSuccess } from './diagnostic-success';

interface DiagnosticCardContentProps {
  runDiagnosticHandler: (e: React.MouseEvent<HTMLElement>) => void;
  navigateToDiagnosticReport: (e: React.MouseEvent<HTMLElement>) => void;
}

export const DiagnosticCardContent = ({
  runDiagnosticHandler,
  navigateToDiagnosticReport,
}: DiagnosticCardContentProps) => {
  const { loading: loadingUser } = useSelector(selectUser);
  const { loadingConfig } = useSelector(selectConfig);

  const loading = loadingUser || loadingConfig;

  const {
    diagnosticData,
    loading: diagnosticResultLoading,
    error: diagnosticRunError,
  } = useSelector(selectNetwork);

  const networkIssues = useSelector(selectNetworkIssues);

  if (loading) {
    return <LoadingContent />;
  }

  if (diagnosticResultLoading) {
    return <DiagnosticCheck />;
  }

  if (diagnosticRunError) {
    return <DiagnosticError />;
  }

  if (diagnosticData && !networkIssues) {
    return (
      <DiagnosticSuccess
        navigateToDiagnosticReport={navigateToDiagnosticReport}
        runDiagnosticHandler={runDiagnosticHandler}
      />
    );
  }

  return <DiagnosticDefault runDiagnosticHandler={runDiagnosticHandler} />;
};
