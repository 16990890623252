import { Badge, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

const DiagnosticsHeader = ({
  issueDetected,
  lastRun,
}: {
  issueDetected: boolean;
  lastRun: string;
}) => {
  const { t } = useTranslation('Network');
  return (
    <div data-cy="diagnostics-results-card-header" className={styles['header']}>
      <div className={styles['left']}>
        <Txt variant={'labelSmall'}>{t('DiagnosticResults.lastRun')}</Txt>
        <Txt variant={'heading4'}>{lastRun}</Txt>
      </div>
      <Badge
        state={issueDetected ? 'negative' : 'positive'}
        emphasis="heavy"
        size="large"
        label={
          issueDetected
            ? t('DiagnosticResults.issuesDetectedHeader')
            : t('DiagnosticResults.noIssuesHeader')
        }
        showIcon={false}
      />
    </div>
  );
};

export default DiagnosticsHeader;
