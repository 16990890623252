import React from 'react';
import { Text as RNText, StyleProp, TextStyle } from 'react-native';
import { Display } from './Display';
import { H1 } from './H1';
import { H2 } from './H2';
import { H3 } from './H3';
import { H4 } from './H4';
import {
  getWeight,
  getType,
  getColor,
  getFontFamily,
  getFont,
  getBreakpoint,
  getFontSize,
} from './utils';
import { DimensionContext } from '../../utils';
import { TextStyleArray as StyleArray } from '../types';

export type AndromedaTextProps = React.ComponentProps<typeof RNText> & {
  children: any;
  style?: StyleProp<TextStyle>;
  // types
  large?: boolean;
  body?: boolean;
  small?: boolean;
  caption?: boolean;
  tiny?: boolean;
  // weights
  light?: boolean;
  semiBold?: boolean;
  book?: boolean;
  bold?: boolean;
  heavy?: boolean;
  // styles
  italic?: boolean;
  white?: boolean;
  gray?: boolean;
  link?: boolean;
  // Font
  sourceSans?: boolean;
  uniNeue?: boolean;
  // Arguments
  fixed?: boolean;
};

export class Text extends React.Component<AndromedaTextProps> {
  private root: RNText | undefined | null;

  setNativeProps(args: any) {
    return this.root && this.root.setNativeProps(args);
  }

  static Display = Display;
  static H1 = H1;
  static H2 = H2;
  static H3 = H3;
  static H4 = H4;

  override render() {
    const {
      style: styleProp,
      large = false,
      body = false,
      small = false,
      caption = false,
      tiny = false,
      light = false,
      book = false,
      bold = false,
      semiBold = false,
      heavy = false,
      italic = false,
      gray = false,
      white = false,
      link = false,
      fixed = false,
      sourceSans = false,
      uniNeue = false,
      ...props
    } = this.props;

    const font = getFont(sourceSans, uniNeue, true);
    const fontStyle = italic && font !== 'UniNeue' ? 'Italic' : '';
    const weight = getWeight(light, book, semiBold, bold, heavy);
    const type = getType(tiny, caption, small, body, large);
    const textColor = getColor(white, gray, link);
    const fontFamily = getFontFamily(font, weight, fontStyle);

    return (
      <DimensionContext.Consumer>
        {(value: DimensionContext): JSX.Element => {
          const style: StyleArray = [fontFamily, textColor, styleProp];
          const { screenSize } = value;
          const typographyBreakpoint = getBreakpoint(screenSize);
          const fontSize = getFontSize(type, typographyBreakpoint, fixed);
          style.push(fontSize);

          return (
            <RNText
              ref={(c) => {
                this.root = c;
              }}
              style={style}
              {...props}
            />
          );
        }}
      </DimensionContext.Consumer>
    );
  }
}
