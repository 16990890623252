import { createContext, useContext } from 'react';

export type StoreValue = {
  hasExternalI18n: boolean;
  partyId: string;
  stateCode: string;
};

interface StoreProviderProps {
  children: React.ReactNode;
  value: StoreValue;
}

const StoreContext = createContext<StoreValue | null>(null);

export const StoreProvider: React.FC<StoreProviderProps> = (props) => {
  const { value, children } = props;

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};

export const useStoreContext = () => {
  const value = useContext(StoreContext);

  if (!value) {
    throw new Error(
      '[useStoreContext] - make sure you provide a value to the StoreProvider'
    );
  }

  return value;
};
