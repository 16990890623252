import React, { useContext, useState } from 'react';
import {
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
// @ts-ignore
import memoize from 'memoizee';
import { Color, colorStepUp, opacity } from '../../utils/Color';
import type { ButtonProps } from './utils';
import {
  ButtonCtx,
  ButtonDefaultColor,
  ButtonSize,
  ButtonTextProps,
  getButtonSize,
  getButtonType,
  IconPosition,
} from './utils';

export * from './TandemButton';

const button: ViewStyle = {
  alignItems: 'center',
};

const disabledCursor = Platform.OS === 'web' ? { cursor: 'not-allowed' } : {};

export { ButtonProps };
export const ButtonStyles = StyleSheet.create({
  buttonView: {
    ...button,
    justifyContent: 'center',
    alignSelf: 'stretch',
  },
  noFill: { alignSelf: 'center' },
  buttonText: {
    alignSelf: 'center',
    marginBottom: -2,
  },
});

export const buttonDisabledStyles = memoize((color: Color) =>
  StyleSheet.create({
    primary: {
      ...button,
      backgroundColor: opacity(color, 0.3),
    },
    secondary: {
      ...button,
      borderColor: opacity(color, 0.3),
      borderWidth: 1,
    },
  })
);

export const buttonTextDisabledStyle = memoize((color: Color) =>
  StyleSheet.create({
    primary: {
      color: Color.white,
    },
    secondary: {
      color: opacity(color, 0.3),
    },
    tertiary: {
      color: opacity(color, 0.3),
    },
  })
);

export const buttonTypeStyle = memoize((color: Color) =>
  StyleSheet.create({
    primary: {
      ...button,
      backgroundColor: color,
      borderWidth: 0,
    },
    secondary: {
      ...button,
      borderColor: color,
      borderWidth: 1,
    },
    tertiary: {
      ...button,
      borderWidth: 0,
    },
  })
);

export const buttonSizeStyle = StyleSheet.create({
  large: {
    borderRadius: 27,
    paddingVertical: 16,
    paddingHorizontal: 32,
  },
  small: {
    borderRadius: 26,
    paddingVertical: 14,
    paddingHorizontal: 32,
  },
  wide: {
    borderRadius: 8,
    paddingVertical: 12,
  },
  tandem: {
    borderRadius: 26,
    paddingVertical: 14,
    paddingHorizontal: 14,
  },
});

const ButtonTextSizeRAW = {
  large: {
    fontFamily: 'SourceSansPro-SemiBold',
    fontSize: 14,
    letterSpacing: 0.26,
  } as TextStyle,
  small: {
    fontFamily: 'SourceSansPro-SemiBold',
    fontSize: 12,
    letterSpacing: 0.23,
  } as TextStyle,
  wide: {
    fontFamily: 'SourceSansPro-Bold',
    fontSize: 14,
    letterSpacing: 0.26,
  } as TextStyle,
} as ViewStyle;
export const buttonTextSizeStyle = StyleSheet.create(
  ButtonTextSizeRAW as object
);

export const buttonTextStyle = memoize((color: Color) =>
  StyleSheet.create({
    primary: {
      color: Color.white,
    } as TextStyle,
    secondary: {
      color,
    } as TextStyle,
    tertiary: {
      color,
    } as TextStyle,
  })
);

export const ButtonText = ({
  children,
  color,
  disabled = false,
  style,
  testID,
}: ButtonTextProps) => {
  const {
    color: colorContext = color,
    primary = false,
    secondary = false,
    tertiary = false,
    small = false,
    large = false,
    wide = false,
    disabled: disabledContext = false,
    allowLowercase = false,
  } = useContext(ButtonCtx);

  const type = getButtonType(primary, secondary, tertiary);
  const size = getButtonSize(large, small, wide);
  const TextColor = color || colorContext;
  const TextDisabled = disabled || disabledContext;
  // @ts-ignore
  const styles = [ButtonStyles.buttonText, buttonTextSizeStyle[size]];
  if (TextDisabled) {
    styles.push(buttonTextDisabledStyle(TextColor as Color)[type]);
  } else {
    styles.push(buttonTextStyle(color as Color)[type]);
  }
  if (style) {
    styles.push(style);
  }
  return (
    <Text testID={testID} style={styles}>
      {typeof children === 'string' && !allowLowercase
        ? children.toUpperCase()
        : children}
    </Text>
  );
};

export const ButtonIconContainer = ({
  children,
  IconPosition = 'right',
  IconOffset = -27,
}: {
  children: React.ReactNode;
  IconPosition?: IconPosition;
  IconOffset?: number;
}) => (
  <View
    style={[
      {
        [IconPosition]: IconOffset,
        position: 'absolute',
        opacity: 100,
        justifyContent: 'center',
        alignSelf: 'center',
      },
    ]}
  >
    {children}
  </View>
);

export const Button = ({
  children,
  primary = false,
  secondary = false,
  tertiary = false,
  small = false,
  large = false,
  wide = false,
  tandem = false,
  Icon,
  IconPosition = 'right',
  IconOffset,
  style: styleOverride,
  onPress = () => null,
  testID = undefined,
  disabled = false,
  fill = false,
  color = ButtonDefaultColor,
  DisableHover = false,
  allowLowercase = false,
}: ButtonProps) => {
  const [isHover, setHover] = useState(false);

  const type = getButtonType(primary, secondary, tertiary);
  const size = getButtonSize(large, small, wide, tandem);
  const containerStyle: StyleProp<ViewStyle> = [{}];
  if (size === ButtonSize.wide) containerStyle.push({ flexGrow: 1 });
  if (fill && Platform.OS === 'web')
    containerStyle.push({ alignSelf: 'stretch' });

  const style = [
    ButtonStyles.buttonView as ViewStyle | ViewStyle[],
    buttonSizeStyle[size],
  ];
  if (disabled) {
    style.push(buttonDisabledStyles(color)[type], disabledCursor as ViewStyle);
  } else if (isHover) {
    if (color !== Color.white)
      style.push(buttonTypeStyle(colorStepUp(color, 100))[type]);
  } else {
    style.push(buttonTypeStyle(color)[type]);
  }
  if (!fill && Platform.OS !== 'web') {
    style.push(ButtonStyles.noFill);
  }
  if (styleOverride) {
    style.push(styleOverride);
  }

  return (
    <ButtonCtx.Provider
      value={{
        primary,
        secondary,
        tertiary,
        wide,
        small,
        large,
        disabled,
        color,
        allowLowercase,
      }}
    >
      <View style={containerStyle}>
        <TouchableOpacity
          style={style}
          disabled={disabled}
          onPress={onPress}
          testID={testID}
          {...(Platform.OS === 'web' && !DisableHover
            ? {
                onMouseEnter: () => setHover(true),
                onMouseLeave: () => setHover(false),
              }
            : {})}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {Icon && (
              <ButtonIconContainer
                IconOffset={IconOffset}
                IconPosition={IconPosition}
              >
                {Icon}
              </ButtonIconContainer>
            )}
            {typeof children === 'string' ? (
              <ButtonText color={color}>{children}</ButtonText>
            ) : (
              children
            )}
          </View>
        </TouchableOpacity>
      </View>
    </ButtonCtx.Provider>
  );
};
