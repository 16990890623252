import * as React from 'react';

import useNavigate from '@mfe/services/navigation';
import { ACPPage } from '@mfe/to-be-migrated/shared-platform-react/pages/ACP';

import { useMessageHandler } from '../useMessageHandler';

const ACP = (): JSX.Element => {
  const ref = React.useRef<any>();

  const { goTo } = useNavigate();

  const redirectToOverview = () => {
    goTo('/');
  };

  useMessageHandler(ref);

  return <ACPPage redirectToOverview={redirectToOverview} />;
};

export default ACP;
