import { Trans, useTranslation } from 'react-i18next';
import { Txt, Button, Icon } from '@vst/beam';
import { Close } from '@vst/beam-icons/icons';
import { ModalTypeEnum, ModalComponent } from '@mfe/shared/components';
import { useScreenResolution } from '@mfe/shared/util';
import styles from './TermsAndConditions.module.scss';

interface TermsAndConditionsModalProps {
  closeModal: () => void;
}

const TermsAndConditionsModal = (props: TermsAndConditionsModalProps) => {
  const { closeModal } = props;

  const { t } = useTranslation('ACP', { keyPrefix: 'TermsAndConditions' });
  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;

  const renderContent = () => (
    <div className={styles['wrapper']} data-cy="acp-terms-conditions-popup">
      <div className={styles['close-button-wrapper']}>
        <button
          className={styles['close']}
          onClick={closeModal}
          data-cy="acp-terms-conditions-x"
        >
          <Icon icon={Close} surface="primary" />
        </button>
      </div>
      <div className={styles['content']} data-cy="acp-terms-conditions-text">
        <Txt variant="heading4" mb="16px">
          {t('title')}
        </Txt>
        <Txt variant="paragraphRegular" color="subtle" mb="16px">
          <Trans ns="ACP" i18nKey="TermsAndConditions.text" />
        </Txt>
      </div>
      <div className={styles['spacing']}></div>
      <div className={styles['footer']}>
        <Button
          variant="primary"
          fluid={isMobile}
          onClick={closeModal}
          data-cy="acp-terms-conditions-close"
        >
          <Txt variant="labelMedium">{t('closeModal')}</Txt>
        </Button>
      </div>
    </div>
  );

  return isMobile ? (
    renderContent()
  ) : (
    <ModalComponent type={ModalTypeEnum.ACP}>{renderContent()}</ModalComponent>
  );
};

export default TermsAndConditionsModal;
