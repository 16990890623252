import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  runDiagnostic,
  setRunDate,
  resetDiagnosticData,
  selectNetworkIssues,
} from '@mfe/to-be-migrated/redux/network/networkSlice';
import { NetworkLink } from '@mfe/legacy/mv/utils/Navigation';
import useNavigate from '@mfe/services/navigation';
import { NetworkIssues } from './network-issues';
import { RunLimitNotifier } from './run-limit-notifier';
import { DiagnosticCardContent } from './diagnostic-card-content';

import styles from './styles.module.scss';

const MAX_BUTTON_CLICKS = 3;

const TIME_LIMIT = 15 * 60 * 1000;

export const RunDiagnosticCard = () => {
  const dispatch = useDispatch();

  const { goTo } = useNavigate();
  const networkIssues = useSelector(selectNetworkIssues);

  const timeStampsFromStorage = localStorage.getItem(
    'diagnosticRunsTimeStamps'
  );

  const [timeStamps, setTimeStamps] = useState<number[]>(
    JSON.parse(timeStampsFromStorage || '[]')
  );
  const [buttonLimitExceded, setButtonLimitExceded] = useState(false);

  useEffect(() => {
    localStorage.setItem(
      'diagnosticRunsTimeStamps',
      JSON.stringify(timeStamps)
    );
  }, [timeStamps]);

  const navigateToDiagnosticReport = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    goTo(`${NetworkLink.Network}/${NetworkLink.DiagnosticResults}`);
  };

  const _runDiagnostic = () => {
    dispatch(runDiagnostic());

    const runDate = format(new Date(Date.now()), 'MMM do HH:mm a zzz');
    dispatch(setRunDate(runDate));
  };

  const runDiagnosticHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const clickTime = Date.now();

    const cannotRunAgain = clickTime - timeStamps[0] < TIME_LIMIT;

    const runLimitReached =
      timeStamps.length >= MAX_BUTTON_CLICKS && cannotRunAgain;

    if (runLimitReached) {
      dispatch(resetDiagnosticData());
      setButtonLimitExceded(true);
      return;
    }

    const newTimeStamps = timeStamps.slice();
    newTimeStamps.push(clickTime);
    newTimeStamps.splice(0, newTimeStamps.length - MAX_BUTTON_CLICKS);

    setTimeStamps(newTimeStamps);

    _runDiagnostic();
  };

  if (buttonLimitExceded) {
    return (
      <RunLimitNotifier
        navigateToDiagnosticReport={navigateToDiagnosticReport}
      />
    );
  }

  if (networkIssues) {
    return (
      <NetworkIssues
        navigateToDiagnosticReport={navigateToDiagnosticReport}
        runDiagnosticHandler={runDiagnosticHandler}
        networkIssues={networkIssues}
      />
    );
  }

  return (
    <div className={styles['card']}>
      <DiagnosticCardContent
        runDiagnosticHandler={runDiagnosticHandler}
        navigateToDiagnosticReport={navigateToDiagnosticReport}
      />
    </div>
  );
};
