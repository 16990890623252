import { useTranslation } from 'react-i18next';

import { Txt, Icon, colors } from '@vst/beam';
import { WhatsappOutlined, DownloadOutlined } from '@vst/beam-icons/icons';
import { Card } from '@mfe/shared/components';

import styles from './ItalyServiceCard.module.scss';
import { ServiceBenefit } from '../BrazilServiceCard/BrazilServiceCard';

const ItalyServiceCard: React.FC = () => {
  const { t } = useTranslation('Overview');

  const benefits = [
    {
      icon: (
        <div
          className={styles['icon-wrapper']}
          style={{ backgroundColor: colors['gray'][100] }}
        >
          <Icon icon={WhatsappOutlined} color="teal_600" />
        </div>
      ),
      title: t('serviceCardItaly.benefits.whatsApp.title'),
      description: t('serviceCardItaly.benefits.whatsApp.description'),
    },
    {
      icon: (
        <div
          className={styles['icon-wrapper']}
          style={{ backgroundColor: colors['gray'][100] }}
        >
          <Icon icon={DownloadOutlined} color="teal_600" />
        </div>
      ),
      title: t('serviceCardItaly.benefits.downloads.title'),
      description: t('serviceCardItaly.benefits.downloads.description'),
    },
  ];

  return (
    <Card>
      <Txt variant="heading5" className={styles['title']}>
        {t('serviceCardItaly.title')}
      </Txt>
      <div className={styles['benefits-list']}>
        {benefits.map((benefit: ServiceBenefit, index) => (
          <ServiceBenefit key={index} benefit={benefit} />
        ))}
      </div>
    </Card>
  );
};

export default ItalyServiceCard;
