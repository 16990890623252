import {
  LogEventFunction,
  SetUserIDFunction,
  TrackScreenFunction,
  LogUnstructuredEventFunction,
  AddGlobalContexts,
} from '@mfe/legacy/mv/containers/analytics';
import env from '@mfe/legacy/mv/env';

const loadSnowPlow = () => {
  const w: any = window;
  if (!w['pixel']) {
    w.GlobalSnowplowNamespace = w.GlobalSnowplowNamespace || [];
    w.GlobalSnowplowNamespace.push('pixel');
    w['pixel'] = function (): void {
      // eslint-disable-next-line prefer-rest-params
      (w['pixel'].q = w['pixel'].q || []).push(arguments);
    };
    w['pixel'].q = w['pixel'].q || [];
    const scriptTag = document.createElement('script');
    const firstScriptTagInPage = document.getElementsByTagName('script')[0];
    scriptTag.async = true;
    scriptTag.src = 'https://atg-sp.viasat.io/pixel/2.14.0/pixel_core.js';
    firstScriptTagInPage?.parentNode?.insertBefore(
      scriptTag,
      firstScriptTagInPage
    );
  }

  const pixel = w['pixel'];

  const cookieDomain = env.pixel.cookieDomain;
  pixel('newTracker', env.pixel.namespace, 'mvr.atg-sp.viasat.io', {
    appId: `${env.pixel.namespace}-Web`,
    cookieDomain,
    eventMethod: 'get',
    forceUnsecureTracker: false,
  });

  return pixel;
};

export const pixel = loadSnowPlow();

export const pixelTrackScreenFunction: TrackScreenFunction = (
  name,
  id,
  params
): void => {
  if (env.debug.showAnalytics) {
    console.log('In Pixel track screen function');
    console.log('custom url: ', name, 'referrer url:', id, 'page Name', params);
  }
  pixel('setCustomUrl', name);
  pixel('setReferrerUrl', id);
  pixel('trackPageView', params);
};
export const pixelEventLogFunction: LogEventFunction = (
  category,
  action,
  params = {}
): void => {
  if (params) {
    if (env.debug.showAnalytics) {
      console.log('In Pixel events functions');
      console.log(
        category,
        action,
        params.label,
        params.property,
        params.value
      );
    }
    pixel(
      'trackStructEvent',
      category,
      action,
      params.label,
      params.property,
      params.value
    );
  }
};

export const pixelLogUnstructuredEventFunction: LogUnstructuredEventFunction = (
  schema,
  data,
  context = []
): void => {
  if (env.debug.showAnalytics) {
    console.log('In Pixel events functions');
    console.log(schema, data, context);
  }
  pixel(
    'trackSelfDescribingEvent',
    {
      schema,
      data,
    },
    context
  );
};

export const pixelSetUserId: SetUserIDFunction = (userId: string): void => {
  pixel('setUserId', userId);
};

export const pixelAddGlobalContexts: AddGlobalContexts = (
  context = []
): void => {
  pixel('addGlobalContexts', context);
};
