import { Surface } from '@vst/beam';

import styles from './styles.module.scss';

const GradientCard = (props: React.PropsWithChildren) => (
  <Surface variant="primary" className={styles['content-wrapper']}>
    {props.children}
  </Surface>
);

export default GradientCard;
