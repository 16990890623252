import * as React from 'react';
import { Surface } from '@vst/beam';
import { useDispatch, useSelector } from 'react-redux';

import {
  getInstallationWorkOrder,
  selectScheduleInstallation,
  refetchScheduleInstallation,
} from '@mfe/to-be-migrated/redux/scheduleInstallation';

import { ScheduleInstallationForm } from './schedule-installation-form';

const ScheduleInstallation = () => {
  const dispatch = useDispatch();
  const { error, submitSuccess } = useSelector(selectScheduleInstallation);

  React.useEffect(() => {
    if (error || submitSuccess) {
      dispatch(refetchScheduleInstallation());
      return;
    }
    dispatch(getInstallationWorkOrder());
  }, [dispatch]);

  return (
    <Surface
      style={{ flex: 1 }}
      variant="secondary"
      data-cy="schedule-installation-page"
    >
      <ScheduleInstallationForm />
    </Surface>
  );
};

export default ScheduleInstallation;
