import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';

import { Platform } from '@mfe/shared/schema-types/src';
import { selectConfig } from '@mfe/shared/redux/config';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import {
  PushNotificationsPopUp,
  FIRST_TIME_PUSH,
} from '@mfe/features/push-notifications';
import FullPageLoading from '../views/FullPageLoading';
import { TermsOfService } from '@mfe/features/terms-of-service';

const WrapFullScreen = ({ children }: { children: React.ReactNode }) => (
  <div
    style={{
      position: 'absolute',
      inset: 0,
      zIndex: 12347,
      backgroundColor: '#FFF',
      height: '100vh',
      width: '100vw',
    }}
  >
    {children}
  </div>
);

const useCheckUserSeenOneTimePrompts = (): {
  loading: boolean;
  legal: [acceptedLegal: boolean, setAcceptedLegal: (_: boolean) => void];
  push: [seenPush: boolean, setSeenPush: (_: boolean) => void];
} => {
  const [acceptedLegal, setAcceptedLegal] = useState(false);
  const [seenPush, setSeenPush] = useState(false);
  const [loading, setLoading] = useState(true);

  const { platform } = useSelector(selectConfig);
  const {
    user: { accountNumber },
  } = useSelector(selectUser);

  useEffect(() => {
    if (platform === Platform.Web) return;
    const pushCookie = Cookies.get(`${FIRST_TIME_PUSH}${accountNumber}`);
    setSeenPush(Boolean(pushCookie));
    setLoading(false);
  }, [platform, accountNumber]);

  return {
    loading,
    legal: [acceptedLegal, setAcceptedLegal],
    push: [seenPush, setSeenPush],
  };
};

const TermsAndPushContainer = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const { platform } = useSelector(selectConfig);
  const {
    user: { accountNumber },
  } = useSelector(selectUser);
  const {
    loading,
    legal: [acceptedLegal, setAcceptedLegal],
    push: [seenPush, setSeenPush],
  } = useCheckUserSeenOneTimePrompts();

  if (platform === Platform.Web || !accountNumber) return children;
  if (loading) return <FullPageLoading />;

  if (!acceptedLegal)
    return <TermsOfService setAcceptedLegal={setAcceptedLegal} />;

  if (!seenPush)
    return (
      <WrapFullScreen>
        <PushNotificationsPopUp
          onAccept={() => {
            setSeenPush(true);
          }}
        />
      </WrapFullScreen>
    );

  return children;
};

export default TermsAndPushContainer;
