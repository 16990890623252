import { getDisplayPlatform } from '@mfe/to-be-migrated/redux/analytics';
import { TokenType } from '@mfe/shared/schema-types';
import { createContext, useContext } from 'react';
import { EditableCardType } from './components/Body/Content/EditableComponents/EditableCard';

export const ProfileAnalyticsContext = createContext<{
  displayContext: any;
  profileContext: any;
}>({
  displayContext: {},
  profileContext: {},
});

export const useProfileAnalyticsContext = () =>
  useContext(ProfileAnalyticsContext);

const getFieldFromType = (type: EditableCardType): string => {
  switch (type) {
    case EditableCardType.Billing:
      return 'billing_address';
    case EditableCardType.Shipping:
      return 'shipping_address';
    default:
      return 'undefined_type';
  }
};

export const profileContext = (
  type: EditableCardType | 'NA',
  currentSelectedCard?: EditableCardType
) => {
  switch (type) {
    case EditableCardType.Billing:
      return {
        ira_label: 'Invoice',
        profile_field: getFieldFromType(type),
      };
    case EditableCardType.Shipping:
      return {
        ira_label: 'Primary',
        profile_field: getFieldFromType(type),
      };
    case 'NA':
      return {
        ira_label: 'NA',
        profile_field: 'NA',
      };
    default:
      return {
        ira_label: 'cancel',
        profile_field: currentSelectedCard
          ? getFieldFromType(currentSelectedCard)
          : 'NA',
      };
  }
};

export const getDisplayContext = (tokenType: TokenType | undefined) => ({
  schema: 'display_context',
  data: {
    display_zone: 'profile_page', //This is because billing is using a different one
    display_platform: getDisplayPlatform(tokenType),
    display_location: 'body',
  },
});

export const getProfileContext = (
  editingCard: EditableCardType | 'NA',
  currentField: EditableCardType | undefined
) => ({
  schema: 'profile_context',
  data: profileContext(editingCard, currentField),
});
