import { StandAloneLink, Txt } from '@vst/beam';
import { Create } from '@vst/beam-icons/icons';
import { ModalType, ModalTypeEnum } from '@mfe/shared/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../BodyContent.module.scss';
import EditableBodyElement from './EditableBodyElement';
import LoadingAddress from './LoadingAddress';
import { useSelector } from 'react-redux';
import { Issuer, selectConfig } from '@mfe/shared/redux/config';
import { ProfileTab } from '../../../..';
import LoadingContactInformation from './LoadingContactInformation';

export enum EditableCardType {
  Billing = 'Billing',
  ContactInformation = 'ContactInformation',
  Shipping = 'Shipping',
  None = 'None',
}

export interface EditableCardProps {
  type: EditableCardType;
  text?: string;
  loading: boolean;
  currentTab: ProfileTab;
  setRestrictSwitchTabs: (restrict: boolean) => void;
  closeAfterReload?: boolean;
  setCloseAfterReload?: (close: boolean) => void;
  setEditingCard?: (type: EditableCardType) => void;
  editingCard?: EditableCardType;
  setShowModal: (type: ModalType) => void;
  id?: string;
}
type InformationDetails = {
  title: string;
  loading: JSX.Element;
};

function cardProps(type: EditableCardType): InformationDetails {
  switch (type) {
    case EditableCardType.Billing:
      return {
        title: 'billingAddress',
        loading: <LoadingAddress />,
      };
    case EditableCardType.Shipping:
      return {
        title: 'shippingAddress',
        loading: <LoadingAddress />,
      };
    case EditableCardType.ContactInformation:
      return {
        title: 'nav_ResidentialContact',
        loading: <LoadingContactInformation />,
      };
    default:
      return {
        title: '',
        loading: <LoadingContactInformation />,
      };
  }
}

const EditableCard = ({
  type,
  text,
  loading,
  currentTab,
  setRestrictSwitchTabs,
  setEditingCard,
  editingCard,
  setShowModal,
  closeAfterReload,
  setCloseAfterReload,
  id,
}: EditableCardProps): JSX.Element => {
  const { t } = useTranslation('NewProfile');
  const { issuer } = useSelector(selectConfig);
  const [editAddress, setAddress] = useState<boolean>(false);
  const isSalesforce = issuer === Issuer.Salesforce;

  const editOnClick = (event: React.MouseEvent<HTMLElement>) => {
    if (event) event.preventDefault();
    if (editingCard === EditableCardType.None) {
      setAddress(true);
      setEditingCard && setEditingCard(type);
      setRestrictSwitchTabs(true);
    } else {
      if (editingCard !== type) {
        setShowModal(ModalTypeEnum.Unsaved);
      }
    }
  };

  const cardDetails = cardProps(type);
  const className =
    type === EditableCardType.Billing
      ? styles['billing']
      : styles['addressCard'];

  return (
    <div className={className}>
      <div className={styles['cardTitle']}>
        <Txt variant="heading5" component="span" color="regular">
          {t(cardDetails.title)}
        </Txt>
        {!isSalesforce && !editAddress && (
          <div className={styles['edit']}>
            <StandAloneLink
              icon={Create}
              iconPos="left"
              data-cy="edit-link"
              variant="primary"
              linkSize="medium"
              href=""
              ml="8px"
              onClick={editOnClick}
              style={loading ? { pointerEvents: 'none' } : {}}
            >
              {t('edit')}
            </StandAloneLink>
          </div>
        )}
      </div>
      <EditableBodyElement
        id={id}
        loading={loading}
        loadingDiv={cardDetails.loading}
        currentTab={currentTab}
        editingOpen={editAddress}
        type={type}
        text={text}
        setRestrictSwitchTabs={setRestrictSwitchTabs}
        clearOnClick={setEditingCard}
        cancelOnClick={setAddress}
        setShowModal={setShowModal}
        closeAfterReload={closeAfterReload}
        setCloseAfterReload={setCloseAfterReload}
      />
    </div>
  );
};

export default EditableCard;
