import React from 'react';
import { Txt } from '@vst/beam';

import styles from './ProfileHeader.module.scss';
import { useTranslation } from 'react-i18next';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { useSelector } from 'react-redux';
import { useScreenResolution } from '@mfe/shared/util';
import Park from '../../img/Park';

const ProfileHeader = (): JSX.Element => {
  const { t } = useTranslation('NewProfile');
  const {
    userInfo: { accountNumber },
    loading,
  } = useSelector(selectUserInfo);
  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobileSize = isSmall || isExtraSmall;

  return (
    <div data-cy="profile-header" className={styles['headerMargin']}>
      <div className={styles['headerTextContainer']}>
        <Txt variant="heading3" component="span">
          {t('accountSettings')}
        </Txt>
        {loading ? (
          <div className={styles['loadingContainer']}>
            <Txt
              variant="bodyLargeRegular"
              component="span"
              mt="12px"
              mb="12px"
              mr="8px"
            >
              {t('accountNumberLoading')}
            </Txt>
            <div className={styles['loading']} />
          </div>
        ) : (
          <Txt variant="bodyLargeRegular" component="span" mt="12px">
            {t('accountNumber', { account_number: accountNumber })}
          </Txt>
        )}
      </div>
      {!isMobileSize && <Park />}
    </div>
  );
};

export default ProfileHeader;
