import { addMonths, setDate } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CheckMark } from '@vst/beam-icons/illustrative-icons';
import { Button, IllustrativeIcon, Txt } from '@vst/beam';

import {
  getBillCycleDays,
  selectUpdateBillCycle,
  reset as resetUpdateBillCycle,
} from '@mfe/to-be-migrated/redux/updateBillCycle';
import { getUsage } from '@mfe/to-be-migrated/redux/plan';
import { selectBillingAccount } from '@mfe/to-be-migrated/redux/billingInfo';

import Container from './container';
import GradientCard from '../gradient-card';
import ProrateNotice from '../prorate-notice';

const Success = ({ navigateToBilling }: { navigateToBilling: () => void }) => {
  const { t } = useTranslation(['UpdateBillCycle', 'Global']);
  const dispatch = useDispatch();

  const { newBillCycleDay } = useSelector(selectUpdateBillCycle);
  const billingAccount = useSelector(selectBillingAccount);

  if (!newBillCycleDay) {
    return null;
  }

  const newBillCycleDate = setDate(addMonths(new Date(), 1), newBillCycleDay);

  return (
    <GradientCard>
      <Container>
        <IllustrativeIcon
          data-cy="update-bill-cycle-success"
          name="success-illustration"
          icon={CheckMark}
          color="teal_600"
          style={{ height: '100px', width: '100px' }}
          mt="72px"
          mb="24px"
        />
        <div style={{ textAlign: 'center' }}>
          <Txt variant="heading4" mb="12px">
            {t('success.title')}
          </Txt>
          <Txt variant="bodyLargeRegular" color="subtle">
            <Trans
              ns="UpdateBillCycle"
              i18nKey="success.subtitle"
              values={{ date: newBillCycleDate }}
            />
          </Txt>
        </div>
        {billingAccount?.billingCycleDayOfMonth && (
          <ProrateNotice
            newBillCycleDay={newBillCycleDay}
            oldBillCycleDay={billingAccount.billingCycleDayOfMonth}
          />
        )}
        <Button
          mb="72px"
          onClick={() => {
            dispatch(resetUpdateBillCycle());
            dispatch(getUsage());
            dispatch(getBillCycleDays());

            navigateToBilling();
          }}
        >
          {t('buttons.backToBilling')}
        </Button>
      </Container>
    </GradientCard>
  );
};

export default Success;
