import { useTranslation } from 'react-i18next';

import { IllustrativeIcon, Txt } from '@vst/beam';
import { Alert } from '@vst/beam-icons/illustrative-icons';

import styles from './styles.module.scss';

export const DiagnosticError = () => {
  const { t } = useTranslation('Network', { keyPrefix: 'diagnostic' });
  return (
    <div className={styles['content']}>
      <IllustrativeIcon
        data-cy="CheckMark-Icon"
        name="CheckMark"
        icon={Alert}
        color="teal_600"
        width={100}
        height={100}
      />
      <div className={styles['titleAndDescription']}>
        <Txt variant="heading5" mb="8px" data-cy="run-diagnostic-title">
          {t('error.title')}
        </Txt>
        <Txt
          variant="bodySmallRegular"
          color="subtle"
          data-cy="run-diagnostic-description"
        >
          {t('error.description')}
        </Txt>
      </div>
    </div>
  );
};
