import React from 'react';
import {
  View,
  TouchableHighlight,
  StyleProp,
  ViewStyle,
  Platform,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { Text } from '../Text';
import { Color } from '../../utils';
import { styles } from './styles.web';
import { PickerItem } from './types';

export class Item extends React.Component<PickerItem> {
  private root: View | undefined | null;

  itemRef: React.RefObject<TouchableHighlight> = React.createRef();
  textRef: React.RefObject<Text> = React.createRef();

  setNativeProps(args: Record<string, any>): void | null | undefined {
    return this.root && this.root.setNativeProps(args);
  }

  setHoverStyle(hover = false): void {
    if (this.itemRef && this.itemRef.current) {
      const style = hover ? styles.onHover : styles.defaultItem;
      this.itemRef.current.setNativeProps({
        style,
      });
    }
  }

  setTextColor(color: Color): void {
    if (this.textRef && this.textRef.current) {
      const style = { color };
      this.textRef.current.setNativeProps({
        style,
      });
    }
  }

  override componentDidMount(): void {
    this.setHoverStyle();
  }

  override componentDidUpdate(): void {
    this.setHoverStyle();
  }

  override render(): JSX.Element {
    const { label, value, selected, expanded, onPress, style, testID } =
      this.props;
    const defaultTextColor = selected ? Color.gray500 : Color.gray800;

    const webHoverProps =
      Platform.OS === 'web'
        ? {
            onMouseEnter: (): void => this.setHoverStyle(true),
            onMouseLeave: (): void => this.setHoverStyle(),
          }
        : {};

    const handlePress = (value: string | number): void => {
      if (onPress !== undefined && !selected) onPress(value);
    };

    const containerStyle: StyleProp<ViewStyle> = [styles.defaultPickerItem];
    if (style) containerStyle.push(style);

    const expandedProps = expanded
      ? {
          onPress: (): void => handlePress(value),
          onPressIn: (): void => this.setTextColor(Color.white),
          onPressOut: (): void => this.setTextColor(defaultTextColor),
        }
      : {};

    return (
      <TouchableHighlight
        {...webHoverProps}
        {...expandedProps}
        accessible={expanded}
        ref={this.itemRef}
        underlayColor={Color.teal600}
        style={containerStyle}
        testID={testID}
      >
        <View style={styles.itemRow}>
          <Text
            small
            fixed
            ref={this.textRef}
            style={{ flex: 1, color: defaultTextColor }}
          >
            {label}
          </Text>
          {selected && <Icon name="check" size={20} color={Color.gray500} />}
        </View>
      </TouchableHighlight>
    );
  }
}
