import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Surface } from '@vst/beam';
import { useEffect } from 'react';

import {
  selectAddOns,
  selectCurrentShopAddon,
  setVoiceFormValues,
} from '@mfe/to-be-migrated/redux/addOns';
import useNavigate from '@mfe/services/navigation';
import { useScreenResolution } from '@mfe/shared/util';
import { HeaderWithBackNavigation } from '@mfe/shared/components';
import { ShopAddonsLink } from '@mfe/legacy/mv/utils/Navigation';
import { setShowLastStepError } from '@mfe/to-be-migrated/redux/addOns';
import { ConfigureVoiceCard } from './card';
import { AddonCart, CollapsibleCart } from '../cart';
import { useHasFilledOutCustomAddress } from '@mfe/shared/components';

import Steps from '../steps';
import styles from './styles.module.scss';
import { getTermsAndConditions } from '../components/utils';
import { selectConfig } from '@mfe/shared/redux/config';

export const ConfigureVoice = ({
  handleHeaderBackNavigation,
  handleFooterBackNavigation,
  handlePurchase,
}: {
  handleHeaderBackNavigation: () => void;
  handleFooterBackNavigation: () => void;
  handlePurchase: () => void;
}) => {
  const dispatch = useDispatch();
  const { replace } = useNavigate();
  const { t } = useTranslation('ShopAddons');

  const currentShopAddon = useSelector(selectCurrentShopAddon);
  const { availableNumbersLoading, voiceConfig } = useSelector(selectAddOns);
  const { hasFilledOutCustomAddress } = useHasFilledOutCustomAddress();

  const { isMedium, isLarge, isExtraLarge } = useScreenResolution();
  const isDesktop = isLarge || isExtraLarge;
  const isMobile = !isDesktop;

  const { isPWA } = useSelector(selectConfig);

  useEffect(() => {
    dispatch(setShowLastStepError(false));
    return () => {
      dispatch(setVoiceFormValues({ directoryListingType: null }));
    };
  }, []);

  useEffect(() => {
    if (!isPWA) return;
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!currentShopAddon) {
      replace(`/${ShopAddonsLink.ShopAddons}`);
    }
  }, [replace, currentShopAddon]);

  if (!currentShopAddon) {
    return null;
  }

  const hasCompletedRequiredFields =
    voiceConfig.selectedPhoneNumber &&
    (voiceConfig.useServiceAddressForE911 || hasFilledOutCustomAddress) &&
    voiceConfig.callerId &&
    voiceConfig.directoryListingType;

  const handleSubmit = () => {
    if (!voiceConfig.directoryListingType) {
      dispatch(setShowLastStepError(true));
    }

    if (hasCompletedRequiredFields) {
      handlePurchase();
    }
    return;
  };

  const termsAndConditionsContent = t(
    `termsAndConditions.${getTermsAndConditions(currentShopAddon.kind)}`
  );
  const isSubmitDisabled = availableNumbersLoading;
  const submitButtonLabel = t('subscribeNow');
  return (
    <>
      {isMobile && (
        <CollapsibleCart
          handleClick={handleSubmit}
          addon={currentShopAddon}
          submitButtonLabel={submitButtonLabel}
        />
      )}
      <div className={styles['wrapper']}>
        <HeaderWithBackNavigation
          handleBackNavigation={handleHeaderBackNavigation}
          title={currentShopAddon.name}
          goBackLabel={t('allAddOns')}
          additionalClassName={styles['voiceHeader']}
        />
        <Surface
          variant={isDesktop || isMedium ? 'secondary' : 'primary'}
          mb={isDesktop || isMedium ? '64px' : '8px'}
        >
          <Steps step={2} maxSteps={2} />
        </Surface>
        <div className={styles['container']}>
          <div className={styles.leftColumn}>
            <ConfigureVoiceCard
              content={termsAndConditionsContent}
              handleBack={handleFooterBackNavigation}
              handleSubmit={handleSubmit}
              isSubmitDisabled={isSubmitDisabled}
            />
          </div>
          {isDesktop && (
            <AddonCart
              handleClick={handleSubmit}
              addon={currentShopAddon}
              submitButtonLabel={submitButtonLabel}
              isSubmitDisabled={isSubmitDisabled}
            />
          )}
        </div>
      </div>
    </>
  );
};
