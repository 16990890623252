const dashOrUnderscoreAndFirstLetter = /([-_][a-z])/g;
const dashOrUnderscore = /[-_]/;

export const snakeToCamelCase = (string: string): string =>
  string
    .toLowerCase()
    .replace(dashOrUnderscoreAndFirstLetter, (group) =>
      group.toUpperCase().replace(dashOrUnderscore, '')
    );

export const formatCurrency = (value?: number | null, currency?: string) => {
  if (value === undefined || value === null || !currency) return String(value);

  let locale;
  switch (currency) {
    case 'EUR':
      locale = 'it-IT';
      break;
    case 'BRL':
      locale = 'pt-BR';
      break;
    default:
      locale = 'en-US';
      break;
  }
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  });

  return formatter.format(value);
};
