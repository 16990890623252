import { Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import DeskLaptop from '../../assets/DeskLaptop';

const PlanErrorContent = () => {
  const { t } = useTranslation('NewChangePlan');

  return (
    <>
      <DeskLaptop data-cy="change-plan-page-error-svg" />
      <div>
        <Txt
          data-cy="change-plan-page-error-title"
          variant="heading4"
          mb="12px"
        >
          {t('errorPage.title.planError')}
        </Txt>
        <Txt
          data-cy="change-plan-page-error-description"
          component="p"
          variant="bodyLargeRegular"
          color="subtle"
        >
          {t('errorPage.description.planError')}
        </Txt>
      </div>
    </>
  );
};

export default PlanErrorContent;
