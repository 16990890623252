import { useTranslation } from 'react-i18next';

import { Button, Txt } from '@vst/beam';

import styles from './styles.module.scss';

export const DiagnosticCheck = () => {
  const { t } = useTranslation('Network', { keyPrefix: 'diagnostic' });
  return (
    <div className={styles['content']}>
      <WifiAnimation />
      <div className={styles['titleAndDescription']}>
        <Txt variant="heading5" mb="8px" data-cy="run-diagnostic-title">
          {t('check.title')}
        </Txt>
        <Txt
          variant="bodySmallRegular"
          color="subtle"
          data-cy="run-diagnostic-description"
        >
          {t('check.description')}
        </Txt>
      </div>

      <Button
        variant="primary"
        fluid
        data-cy="run-diagnostic-button"
        style={{ maxWidth: '400px' }}
        disabled={true}
      >
        {t('buttons.inProcess')}
      </Button>
    </div>
  );
};

const WifiAnimation = () => {
  return (
    <div>
      <svg
        width="30"
        height="30"
        viewBox="0 0 76 58"
        className={styles['wifiAnimation']}
        xmlns="http://www.w3.org/2000/svg"
        fill="#00819d"
      >
        <path
          className={styles['path1']}
          d="M38.084 -1.10707e-05C52.039 -0.0260111 65.5 5.30199 75.827 14.94L76 15.062L74.927 16.225L74.744 16.057C64.656 6.73999 51.568 1.58499 38 1.58499C24.432 1.58499 11.344 6.73999 1.256 16.056L1.073 16.224L0 15.061L0.188 14.89C10.526 5.27199 23.986 -0.0380111 37.936 -1.10707e-05H38.084Z"
        />
        <path
          className={styles['path2']}
          d="M12.126 28.93C19.301 22.513 28.499 18.985 38.015 19.003L38.01 18.977C47.53 18.965 56.731 22.502 63.904 28.93L64.092 29.103L63.033 30.296L62.855 30.134C55.964 23.987 47.144 20.601 38.014 20.601C28.887 20.601 20.065 23.986 13.174 30.134L12.996 30.296L11.938 29.103L12.126 28.93Z"
        />
        <path
          className={styles['path3']}
          d="M24.42 42.819C28.297 39.693 33.085 37.998 38.015 38.005V37.979C42.935 37.987 47.711 39.692 51.575 42.819L51.773 42.976L50.784 44.221L50.591 44.063C47.002 41.159 42.565 39.579 37.995 39.579C33.425 39.579 28.989 41.159 25.399 44.063L25.212 44.221L24.222 42.976L24.42 42.819Z"
        />
        <path
          className={styles['path4']}
          d="M41.0014 54.6669C40.934 53.968 40.6285 53.3135 40.136 52.813C39.57 52.237 38.806 51.914 38.01 51.914C37.4142 51.9213 36.8327 52.0975 36.333 52.422C35.836 52.752 35.447 53.228 35.216 53.788C34.9848 54.3438 34.923 54.9556 35.0382 55.5464C35.1535 56.1372 35.4408 56.6809 35.864 57.109C36.1795 57.4258 36.5608 57.6692 36.981 57.822C37.3994 57.9739 37.8466 58.0301 38.2896 57.9865C38.7326 57.9428 39.1602 57.8005 39.541 57.57C39.9256 57.3378 40.2549 57.0245 40.506 56.652C40.8938 56.0667 41.0687 55.3658 41.0014 54.6669Z"
        />
      </svg>
    </div>
  );
};
