import { useTranslation } from 'react-i18next';
import { InfoOutline } from '@vst/beam-icons/icons';
import { Icon, Surface, Tooltip, Txt, colors } from '@vst/beam';

import { PlanCharacteristics } from '@mfe/shared/schema-types';
import {
  hasUnlimitedUsageMeter,
  isUnleashed,
  useScreenResolution,
} from '@mfe/shared/util';

import styles from './styles.module.scss';

type Props = {
  characteristics: Pick<
    PlanCharacteristics,
    'dataCapGB' | 'downloadSpeed' | 'downloadUnits' | 'usageMeter' | 'family'
  >;
  disclosure?: string;
  isCollapsibleCurrentPlan?: boolean;
};

export const PlanCardBody = ({
  characteristics,
  disclosure,
  isCollapsibleCurrentPlan,
}: Props) => {
  const { t } = useTranslation('NewChangePlan');
  const { isExtraSmall, isSmall, isLarge } = useScreenResolution();

  const headingSize =
    isExtraSmall || isSmall || isLarge ? 'heading4' : 'heading3';

  const { dataCapGB, downloadSpeed, downloadUnits, usageMeter, family } =
    characteristics;

  const isUnlimited = hasUnlimitedUsageMeter(usageMeter);
  const isPlanUnleashed = isUnleashed(family);

  const hasDownloadSpeedAndDataCap =
    dataCapGB && downloadSpeed && !isPlanUnleashed;

  const showDataCap = dataCapGB || isPlanUnleashed;

  const showHighSpeedDataTooltip = disclosure;

  return (
    <Surface
      className={styles['data-section']}
      style={{
        gap: 8,
        justifyContent: hasDownloadSpeedAndDataCap ? 'space-around' : 'center',
      }}
      py={'4px'}
    >
      {downloadSpeed && !isPlanUnleashed && (
        <Surface data-cy="download-section">
          <Txt variant={headingSize} style={{ whiteSpace: 'nowrap' }}>
            {downloadSpeed} {downloadUnits}
          </Txt>
          <Txt variant={'smallRegular'}>{t('downloadSpeed')}</Txt>
        </Surface>
      )}

      {hasDownloadSpeedAndDataCap && (
        <Surface
          data-cy="separator"
          style={{ borderRight: `1px solid ${colors['gray'][200]}` }}
        />
      )}

      {showDataCap && (
        <Surface data-cy="data-section">
          <Surface
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Txt variant={headingSize} style={{ whiteSpace: 'nowrap' }}>
              {isPlanUnleashed ? t('unlimited') : t('dataCapGB', { dataCapGB })}
            </Txt>
            {showHighSpeedDataTooltip && (
              <Tooltip
                data-cy="disclosure-tooltip-text"
                bodyText={disclosure}
                style={{ textAlign: 'left' }}
                beakPosition={isPlanUnleashed ? 'center' : 'end'}
                position={
                  isCollapsibleCurrentPlan || isUnlimited ? 'bottom' : undefined
                }
                enableAutoPositioning
              >
                <Icon
                  icon={InfoOutline}
                  color="gray_500"
                  size="16px"
                  style={{ marginLeft: '4px' }}
                />
              </Tooltip>
            )}
          </Surface>
          <Txt
            variant={'smallRegular'}
            style={{ color: isPlanUnleashed ? colors['blue'][700] : undefined }}
          >
            {t('highSpeedData')}
          </Txt>
        </Surface>
      )}
    </Surface>
  );
};
