/**
 * Do not export this BaseCard or BaseCardType in src/components/index.ts
 *
 * This BaseCard component should only be accessible within Andromeda & is the foundation for extending variant card types.
 */
import React from 'react';
import {
  StyleProp,
  ViewStyle,
  Platform,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import { Close, Svg } from './Components';
import { getMaxWidth, DEFAULT_SHADOW } from './utils';
import { DimensionContext, isDefined } from '../../utils';
import { Color } from '../../utils/Color';

export type BaseCardType = React.ComponentProps<typeof TouchableOpacity> & {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  removeShadow?: boolean;
};

export class BaseCard extends React.Component<BaseCardType> {
  private root: TouchableOpacity | undefined | null;

  static Close = Close;
  static Svg = Svg;

  private touchableRef: React.RefObject<TouchableOpacity> = React.createRef();

  setNativeProps(args: any) {
    return this.root && this.root.setNativeProps(args);
  }

  override render() {
    const {
      children,
      style: styleProps,
      removeShadow = false,
      onPress,
      ...props
    } = this.props;

    const setHover = (disable: boolean): void => {
      if (
        this.touchableRef &&
        this.touchableRef.current &&
        Platform.OS === 'web' &&
        !isDefined(onPress)
      ) {
        const cursor = { cursor: disable ? 'default' : 'auto' };
        this.touchableRef.current.setNativeProps({ style: cursor });
      }
    };

    return (
      <DimensionContext.Consumer>
        {(value: DimensionContext): JSX.Element => {
          const { screenSize } = value;
          const maxWidth = getMaxWidth(screenSize);
          const style: StyleProp<ViewStyle> = [maxWidth, styles.default];
          if (!removeShadow) style.push(DEFAULT_SHADOW);
          if (styleProps) style.push(styleProps);

          const touchableProps =
            Platform.OS === 'web'
              ? {
                  onMouseEnter: (): void => setHover(true),
                  onMouseLeave: (): void => setHover(false),
                }
              : {};

          return (
            <TouchableOpacity
              ref={this.touchableRef}
              style={style}
              onPress={onPress}
              activeOpacity={isDefined(onPress) ? 0.7 : 1}
              {...touchableProps}
              {...props}
            >
              {children}
            </TouchableOpacity>
          );
        }}
      </DimensionContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  default: {
    borderRadius: 12,
    backgroundColor: Color.white,
  },
});
