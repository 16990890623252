import { Button, TextField, StandAloneLink, Txt } from '@vst/beam';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { Address, Locale } from '@mfe/shared/schema-types';
import {
  selectStreamOnPromo,
  setEditAddress,
  setShippingAddress,
} from '@mfe/to-be-migrated/redux/streamOnPromo';

import styles from './EditAddress.module.scss';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';

export interface EditAddressProps {
  onSubmit?: (address: Address) => void;
  onCancel?: () => void;
}

const validatePostalCode = (value = '', userLocale = Locale.EnUs) => {
  if (value.trim().length === 0) return true;
  if (userLocale === Locale.EnUs) return /^\d{5}([ -]\d{4})?$/.test(value);
  if (userLocale === Locale.PtBr) return /^\d{5}([ -]\d{3})?$/.test(value);
  return true;
};

export const EditAddress = ({ onSubmit, onCancel }: EditAddressProps) => {
  const dispatch = useDispatch();

  const { userInfo } = useSelector(selectUserInfo);

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const { service: serviceAddress } = userInfo.address;

  const {
    editAddress,
    offerDetails: { shouldDisplaySms },
  } = useSelector(selectStreamOnPromo);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    const { name, value } = target;

    let newEditAddress = { ...editAddress };
    if (name === 'addressLine1') {
      newEditAddress = {
        ...editAddress,
        addressLines: [value, editAddress.addressLines[1] ?? ''],
      };
    } else if (name === 'addressLine2') {
      newEditAddress = {
        ...editAddress,
        addressLines: [editAddress.addressLines[0] ?? '', value],
      };
    } else {
      newEditAddress = {
        ...editAddress,
        [name]: value,
      };
    }

    dispatch(setEditAddress(newEditAddress));
  };
  const { t } = useTranslation('ShippingAddress');

  const submitForm: SubmitHandler<FieldValues> = (data) => {
    if (onSubmit) {
      const addressLines = [
        editAddress.addressLines[0],
        editAddress.addressLines[1],
      ];
      if (!editAddress.addressLines[1])
        addressLines.push(editAddress.addressLines[1]);
      onSubmit({
        addressLines,
        municipality: '',
        region: '',
        postalCode: editAddress.postalCode,
        countryCode: '',
      });
    }
  };

  const handleReset: React.FormEventHandler<HTMLFormElement> = (event) => {
    if (onCancel) {
      onCancel();
    }
    event.preventDefault();
    dispatch(setShippingAddress(serviceAddress as Address));
  };

  return (
    <div
      className={
        shouldDisplaySms
          ? styles['addressForm']
          : styles['addressFormWithoutSms']
      }
    >
      <Txt variant="bodyLargeBold" data-cy="addressTitle" mb="12px">
        {t('Address.Title')}
      </Txt>
      <Txt variant="bodySmallRegular" data-cy="addressSubtitle" mb="12px">
        {t('Address.Subtitle')}
      </Txt>
      <form
        onSubmit={handleSubmit(submitForm)}
        onReset={handleReset}
        data-cy="form"
      >
        <TextField
          labelProps={{ labelText: t('StreetAddress') }}
          value={editAddress.addressLines[0] ?? ''}
          name="addressLine1"
          onChange={handleChange}
          data-cy="addressLine1"
          inputSize="100%"
          mb="8px"
          error={
            errors['addressLine1'] &&
            errors['addressLine1']?.type === 'required'
              ? (t('Address.ValidationMessages.StreetAddress') as string)
              : undefined
          }
        />

        <div className={styles['inputContainer']}>
          <div className={styles['zipCodeContainer']}>
            <TextField
              labelProps={{ labelText: t('Zip') }}
              value={editAddress.postalCode as string}
              name="postalCode"
              onChange={handleChange}
              data-cy="zipCode"
              inputSize="100%"
              mb="8px"
              error={
                !validatePostalCode(
                  editAddress.postalCode as string,
                  userLocale
                )
                  ? (t('Address.ValidationMessages.InvalidZipcode') as string)
                  : undefined
              }
            />
          </div>
          <TextField
            labelProps={{ labelText: t('Apt') }}
            value={editAddress.addressLines[1] ?? ''}
            name="addressLine2"
            onChange={handleChange}
            data-cy="addressLine2"
            inputSize="100%"
          />
        </div>
        <div className={styles['buttonContainer']}>
          <StandAloneLink
            variant="primary"
            linkSize="medium"
            href=""
            onClick={(e: any) => {
              e.preventDefault();
              handleReset(e);
            }}
            openInNewLocation={false}
            data-cy="useServiceAddress"
          >
            {t('Button.UseServiceAddress')}
          </StandAloneLink>
          <div className={styles['wrapper']}>
            <Button
              fluid
              variant="secondary"
              aria-placeholder="Save new address"
              value={t<string>('Button.Save')}
              data-cy="saveNewAddress"
              disabled={
                !validatePostalCode(
                  editAddress.postalCode as string,
                  userLocale
                )
              }
            >
              {t('Button.Save')}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
