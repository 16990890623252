import { useLocation } from 'react-router';

export const useParams = (): any => {
  const location: any = useLocation();

  const params = location?.state?.params ?? undefined;
  const clear = (): void => {
    if (params) location.state.params = undefined;
  };
  return { params, clear };
};
