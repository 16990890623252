import AppColors from '../../styles/colors';

export interface CustomFields {
  name: string;
  value: string;
  disableOverwrite?: boolean;
}

export const theme = {
  color: AppColors.darkGray32424E,
  fontColor: AppColors.white,
  iconColor: AppColors.white,
};

export interface CreateRCMethodsArgs {
  rocketChat?: any;
  webview?: any;
}

type RCMethod = () => void;
export interface RCMethods {
  restartMobileWidget: RCMethod;
  maximizeWidget: RCMethod;
  setCustomFields: (_: CustomFields[]) => void;
  setTheme: RCMethod;
  setGuestName: (_: string) => void;
  setGuestEmail: (_: string) => void;
  showPrint: RCMethod;
  setRocketChatDataAfterLoad: RCMethod;
}
