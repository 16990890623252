import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';
import { OpenInNew, Phone } from '@vst/beam-icons/icons';

import {
  IconComponentType,
  Surface,
  Txt,
  StandAloneLink,
  StandardModal,
  InlineLink,
} from '@vst/beam';

import styles from './styles.module.scss';
import { TransitionWrapper } from '../configure-voice/transition-wrapper';
import {
  CancelSubscriptionErrorModal,
  CancelSubscriptionModal,
} from '../remove-add-ons';
import { selectConfig } from '@mfe/shared/redux/config';
import { Platform } from '@mfe/shared/schema-types';
import { ModalType, ModalTypeEnum } from '@mfe/shared/components';
import { setShowModal } from '@mfe/to-be-migrated/redux/addOns';

type SupportOptionItemProps = {
  text: string;
  linkText: string;
  href: string;
  icon: IconComponentType;
  dataCy: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

const SupportOptionItem = ({
  icon,
  text,
  linkText,
  onClick,
  href,
  dataCy,
}: SupportOptionItemProps) => (
  <Surface
    className={styles['support-option-item']}
    radius="16px"
    px={'24px'}
    py={'24px'}
  >
    <Txt variant={'bodyLargeRegular'} color={'regular'} component="span">
      {text}
    </Txt>
    <StandAloneLink
      variant="primary"
      href={href}
      onClick={onClick}
      icon={icon}
      style={{ textDecoration: 'none', fontWeight: '600' }}
      data-cy={dataCy}
    >
      {linkText}
    </StandAloneLink>
  </Surface>
);

type AddonManagementModalProps = {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  addOnName: string;
  showModal?: ModalType;
  isBundleAddOn?: boolean;
};

export const AddonManagementModal = ({
  id,
  isOpen,
  onClose,
  addOnName,
  showModal,
  isBundleAddOn,
}: AddonManagementModalProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Profile');
  const [isCancelSubscriptionOpen, setIsCancelSubscriptionOpen] =
    useState(false);

  const { platform } = useSelector(selectConfig);
  const isMobile = platform !== Platform.Web;
  const isCancelSubscriptionErrorModal =
    showModal === ModalTypeEnum.CancelSubscriptionError;

  const handleHelpCenterClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const url = t('visitHelpCenter.url');
    dispatch(openInNewTab(url));
  };

  const handleCancelSubscription = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setIsCancelSubscriptionOpen(true);
    onClose();
  };

  const modalContentClass = isMobile ? `${styles['mobilePadding']}` : '';

  if (isCancelSubscriptionErrorModal) {
    return (
      <CancelSubscriptionErrorModal
        isOpen={isCancelSubscriptionErrorModal}
        onClose={() => {
          dispatch(setShowModal(null));
        }}
        addOnName={addOnName}
      />
    );
  }

  return (
    <>
      <StandardModal
        isOpen={isOpen}
        header={t('manageAddonModal.header', { addOnName })}
        actions={{
          primary: { label: t('manageAddonModal.label'), onClick: onClose },
        }}
        onClose={onClose}
        size="medium"
        surfaceProps={{ className: modalContentClass }}
      >
        <div>
          <Txt variant={'bodyLargeRegular'} color={'regular'}>
            {t('manageAddonModal.description', { addOnName })}
          </Txt>
          <Surface mb={'24px'} className={styles['support-options-container']}>
            <SupportOptionItem
              icon={OpenInNew}
              text={t('visitHelpCenter.text')}
              linkText={t('visitHelpCenter.link')}
              onClick={handleHelpCenterClick}
              dataCy="visit-help-center"
              href={'#'}
            />
            <SupportOptionItem
              icon={Phone}
              text={t('callCustomerCare.text')}
              linkText={t('callCustomerCare.link')}
              href={`tel:${t('Global:callCustomerSupportPhoneNumber')}`}
              dataCy="call-customer-care"
            />
          </Surface>
          {!isBundleAddOn && (
            <Txt
              variant={'bodySmallRegular'}
              color={'regular'}
              component="span"
            >
              {t('cancelSubscription.title')}
              <InlineLink
                data-cy="manage-addon-link"
                variant="primary"
                href="#"
                onClick={handleCancelSubscription}
                style={{ textDecoration: 'none' }}
                ml={'4px'}
              >
                {t('cancelSubscription.link')}
              </InlineLink>
            </Txt>
          )}
        </div>
      </StandardModal>
      <TransitionWrapper isOpen={isOpen}>
        {isCancelSubscriptionOpen && (
          <CancelSubscriptionModal
            id={id}
            isOpen={isCancelSubscriptionOpen}
            onClose={() => setIsCancelSubscriptionOpen(false)}
            addOnName={addOnName}
          />
        )}
      </TransitionWrapper>
    </>
  );
};
