import React from 'react';

const defaultStyles = {
  display: 'inline-block',
  verticalAlign: 'middle',
  margin: 2,
};

interface IconProps {
  size?: number;
  color?: string;
  icon: IconTypes;
  viewBox?: string;
  style?: React.CSSProperties;
}

type IconTypes = 'expandMore' | 'expandLess';

const IconsLib: Record<IconTypes, string> = {
  expandMore: 'M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z',
  expandLess: 'M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z',
};

export const Icon = ({
  size = 24,
  color = '#000000',
  icon,
  style = {},
  viewBox,
}: IconProps) => {
  const styles = {
    ...defaultStyles,
    ...style,
    color: color,
  };
  return (
    <svg
      style={styles}
      viewBox={viewBox}
      width={`${size}px`}
      height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path fill={color} d={IconsLib[icon]} />
    </svg>
  );
};
