import { getI18n } from './customUseTranslation';
import { parsePhoneNumber } from 'libphonenumber-js';

export interface PhoneStruct {
  formattedNumber: string;
  countryCode?: string;
  phonePrefix?: number;
}

export const createPhoneNumber = (phoneInput: string): PhoneStruct => {
  const t = getI18n().getFixedT('en-US', 'SMS');

  if (!phoneInput) {
    return { formattedNumber: `${t('PhoneNumber.NotFound')}` };
  }

  const phoneNumber = parsePhoneNumber(phoneInput);
  const phonePrefix = Number(phoneNumber.countryCallingCode);

  let formattedNumber;
  if (phonePrefix === 1) {
    formattedNumber = `+1 ${phoneNumber.format('NATIONAL')}`;
  } else {
    formattedNumber = phoneNumber.format('INTERNATIONAL');
  }

  return {
    formattedNumber,
  };
};

export * from './customUseTranslation';
