import React, { useMemo, useEffect } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { ScreenEntry } from './utils';

// Serves all children at / as well as at all language prefixes /prefix/
const CountryRouter = ({
  routes,
  fallback,
}: {
  routes: ScreenEntry[];
  fallback: React.ComponentType;
}) => {
  const location = useLocation();
  const history = useHistory();
  const {
    locale: { urlPrefix },
  } = useSelector(selectLocale);

  // Redirect users to /urlPrefix if they should have one
  // i.e. => /PayBill => /it/PayBill
  useEffect(() => {
    if (!location.pathname.startsWith(urlPrefix)) {
      const newPath = `${urlPrefix}${location.pathname}`;

      history.replace({ pathname: newPath });
    }
  }, [urlPrefix, history, location]);

  const CreateCountryRoutes = useMemo(() => {
    return (prefix: typeof urlPrefix) => {
      return routes.map(
        ({ path, screen, exact }: ScreenEntry): JSX.Element => (
          <Route
            exact={exact}
            path={`${prefix}${path}`}
            component={screen}
            key={path}
          />
        )
      );
    };
  }, [routes]);

  return (
    <Switch>
      {CreateCountryRoutes(urlPrefix)}
      {CreateCountryRoutes('')}
      <Route component={fallback} path="*" key={'no-match'} />
    </Switch>
  );
};

export default CountryRouter;
