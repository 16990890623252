import React from 'react';
import { Txt } from '@vst/beam';
import styles from './tab-item.module.scss';
import { useSelector } from 'react-redux';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { useScreenResolution } from '@mfe/shared/util';

type NavItem = {
  label: string;
  path: string;
};

const useIsActiveRoute = (route: string) => {
  const {
    locale: { urlPrefix },
  } = useSelector(selectLocale);

  const currentLocation = window.location.pathname;
  if (route === '/' || route === urlPrefix || route === `${urlPrefix}/`) {
    return (
      currentLocation === '/' ||
      currentLocation === urlPrefix ||
      currentLocation === `${urlPrefix}/`
    );
  }

  return currentLocation.includes(route);
};

export const TabItem = ({
  label,
  path,
  onClick,
}: {
  onClick: (e: any) => void;
} & NavItem) => {
  const { isLarge, isExtraLarge } = useScreenResolution();
  const isMobile = !isLarge && !isExtraLarge;
  const isActive = useIsActiveRoute(path);

  let NavItem = <></>;
  let className = styles.navItem;

  className += isActive ? ` ${styles.selected}` : '';
  NavItem = (
    <div className={styles.tabContainer}>
      <a
        href={path}
        className={className}
        onClick={onClick}
        data-cy={label}
        id={path}
      >
        <Txt
          variant={isActive && isMobile ? 'labelMedium' : 'bodySmallRegular'}
          color={isActive ? 'regular' : 'inherit'}
        >
          {label}
        </Txt>
      </a>
      {!isMobile && (
        <div className={isActive ? styles.activeTab : styles.inactiveTab} />
      )}
    </div>
  );

  return NavItem;
};
