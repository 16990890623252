import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Txt, InlineLink } from '@vst/beam';

import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';

import { LINKS } from '../../constants';
import styles from './Introduction.module.scss';

export const IntroductionContent = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation('ACP', { keyPrefix: 'Introduction' });

  const openNewTab =
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => (link: string) => {
      e.preventDefault();
      dispatch(openInNewTab(link));
    };

  return (
    <>
      <Txt variant="bodySmallRegular">{t('eligibility')}</Txt>
      <Txt variant="bodyLargeBold" className={styles['subheading']}>
        {t('application.heading')}
      </Txt>
      <div className={styles['applicationId']}>
        <Txt variant="bodySmallRegular">
          {t('application.qualifying.text')}

          <InlineLink
            data-cy="acp-national-verifier-website"
            href="#"
            onClick={(e) => openNewTab(e)(LINKS.NATIONAL_VERIFIER)}
          >
            {t('application.qualifying.linkLabel')}
          </InlineLink>
        </Txt>
        <Txt variant="bodySmallRegular">
          {t('application.learnMore.text')}
          <InlineLink
            data-cy="acp-learn-more"
            href="#"
            onClick={(e) => openNewTab(e)(LINKS.ACP_VIASAT)}
          >
            {t('application.learnMore.linkLabel')}
          </InlineLink>
        </Txt>
      </div>
    </>
  );
};
