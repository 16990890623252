import * as React from 'react';

function useBoxSize(element: Element | null) {
  const [boxSize, setBoxSize] = React.useState<ResizeObserverSize>();

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.borderBoxSize) {
          setBoxSize(entry.borderBoxSize[0]);
        }
      }
    });

    if (element) {
      resizeObserver.observe(element);
    }

    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
    };
  }, [element]);

  return boxSize;
}

function useElementHeight(selector: string) {
  const [height, setHeight] = React.useState<number>();
  const element = document.querySelector(selector);
  const boxSize = useBoxSize(element);

  React.useEffect(() => {
    if (boxSize?.blockSize) {
      setHeight(boxSize.blockSize);
    }
  }, [boxSize?.blockSize]);

  return height;
}

export default useElementHeight;
