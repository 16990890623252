export const getTranslateX = (width: number): number => {
  switch (true) {
    case width >= 300:
      return -31;
    case width >= 270:
      return -28;
    case width >= 245:
      return -25;
    case width >= 223:
      return -22;
    case width >= 201:
      return -20;
    case width >= 188:
      return -18;
    default:
      return -16;
  }
};
