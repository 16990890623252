import React from 'react';
import styles from './SpinnerLoader.module.scss';

interface SpinnerLoaderProps {
  size: string;
  lineWeight: number;
}

export const SpinnerLoader = ({ size, lineWeight }: SpinnerLoaderProps) => {
  return (
    <svg
      className={styles['ring']}
      viewBox="25 25 50 50"
      strokeWidth={lineWeight}
      width={size}
      height={size}
    >
      <circle cx="50" cy="50" r="20" />
    </svg>
  );
};
