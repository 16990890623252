import styles from '../BodyContent.module.scss';

const LoadingAccountInfo = () => (
  <div className={styles['loadingContainer']}>
    <div className={styles['loading']} style={{ marginTop: '4px' }} />
    <div className={styles['loading']} style={{ marginTop: '4px' }} />
  </div>
);

export default LoadingAccountInfo;
