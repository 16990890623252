import styles from '../BodyContent.module.scss';

const LoadingAddress = () => (
  <div className={styles['loadingContainerWithPadding']}>
    <div className={styles['loading']} style={{ marginBottom: '8px' }} />
    <div className={styles['loading']} />
  </div>
);

export default LoadingAddress;
