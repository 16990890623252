import React, { ReactNode } from 'react';
import { useTranslation } from '../../utils';
import styles from './Page.module.scss';

export interface PageProps {
  disableNext?: boolean;
  nextLabel?: string;
  onNext?: () => void;
  children: ReactNode;
}

export const Page = ({
  disableNext,
  nextLabel,
  onNext,
  children,
}: PageProps) => {
  const { t } = useTranslation('Global');
  const myNextLabel = nextLabel ?? 'next';

  const handleOnNext = (e: React.MouseEvent<HTMLElement>) => {
    if (onNext) {
      e.preventDefault();
      onNext();
    }
  };

  return (
    <div className={styles['container']}>
      {children}
      {onNext && (
        <button
          className={styles['button']}
          disabled={disableNext}
          onClick={handleOnNext}
        >
          {t(myNextLabel)}
        </button>
      )}
    </div>
  );
};

export default Page;
