import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SectionAlert, Txt } from '@vst/beam';

import { selectBillingAccount } from '@mfe/to-be-migrated/redux/billingInfo';

import styles from './styles.module.scss';

export const InfoNotice = () => {
  const { t } = useTranslation('UpdateBillCycle', { keyPrefix: 'infoNotice' });

  const billingAccount = useSelector(selectBillingAccount);
  const paymentDueDays = billingAccount?.paymentDueDays;

  return (
    <SectionAlert
      data-cy="info-notice"
      variant="infoSecondary"
      showIcon={false}
      contentSlot={
        <div className={styles['info-notice-content']}>
          <Txt variant="smallRegular">
            <Trans
              ns="UpdateBillCycle"
              i18nKey="infoNotice.monthlyPayment"
              values={{ count: paymentDueDays }}
            />
          </Txt>
          <Txt variant="smallRegular">{t('resetDate')}</Txt>
          <Txt variant="smallRegular">
            <Trans ns="UpdateBillCycle" i18nKey="infoNotice.warning" />
          </Txt>
        </div>
      }
    />
  );
};
