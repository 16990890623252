import { Surface } from '@vst/beam';

import styles from './styles.module.scss';

export const AddOnsLoading = () => {
  return (
    <Surface data-cy="loading" className={styles['profile-loading-container']}>
      <div className={styles['loading-title-container']}>
        <div className={styles['loading-title']} />
      </div>

      <div className={styles['loading-content']}>
        <div className={styles['icon']} />
        <div className={styles['text-content']}>
          <div className={styles['title']} />
          <div className={styles['desc']} />
          <div className={styles['price']} />
        </div>
      </div>

      <div className={styles['loading-content']}>
        <div className={styles['icon']} />
        <div className={styles['text-content']}>
          <div className={styles['title']} />
          <div className={styles['desc']} />
          <div className={styles['price']} />
        </div>
      </div>
    </Surface>
  );
};
