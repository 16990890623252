import styles from './styles.module.scss';

export const AddOnCardLoading = (): JSX.Element => {
  return (
    <div data-cy="add-on-card-loading" className={styles['addOnLoading']}>
      <div className={styles['addOnLoading']}>
        <div className={styles['addOnCardTitle']}>
          <div className={styles['titleLoading']} />
        </div>
        <div className={styles['captionsLoading']}>
          <div className={styles['captionLoading']} />
          <div className={styles['captionLoading']} />
        </div>
        <div className={styles['linkLoading']} />
      </div>
    </div>
  );
};
