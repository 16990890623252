import React, { useContext } from 'react';
import { useIsInViewPortEffect } from 'react-native-viewport-helpers';
import { View, Platform } from 'react-native';
import env from '../env';

export type JSONPrimitive = string | number | boolean | null;
export type JSON = JSONPrimitive | JSONObject | JSON[];
export type JSONObject = { [member: string]: JSON };

export interface ContextSchema {
  schema: string;
  data: JSON;
}

export interface EventLogParams {
  label?: string;
  property?: string;
  value?: number;
  context?: ContextSchema[];
}

export enum Vendors {
  Care = 'com.viasat.care',
  Usage = 'com.viasat.care.usage',
  MyViasat = 'com.viasat.care.myviasat',
}

export enum AnalyticsSchemaContext {
  UsageContext = 'usage_context',
  ChangePlanContext = 'change_plan_context',
  BillingContext = 'billing_context',
}

export enum EventNames {
  BillBalanceDisplayed = 'bill__balance_displayed',
  BillCycleDateDisplayed = 'bill__bill_cycle_date_displayed',
  BillStatementDisplayed = 'bill__statement_displayed',
  BillStatementSelected = 'bill__statement_selected',
  BillStatementHistoryViewed = 'bill__statement_history_viewed',
  PendingPaymentNotified = 'payments__pending_payment_notified',
  MakePaymentSuccessful = 'payments__make_payment_successful',
  MakePaymentFailed = 'payments__make_payment_failed',
  MakePaymentSelected = 'payments__make_payment_selected',
  MakePaymentSubmitted = 'payments__make_payment_submitted',
  ElementLoaded = 'payments__element_loaded',
  BillCycleDateChangeSuccessful = 'bill__bill_cycle_date_change_successful',
  BillCycleDateChangeFailed = 'bill__bill_cycle_date_change_failed',
  UpdatePaymentMethodSuccess = 'payment_method__payment_method_update_successful',
  UpdatePaymentMethodFailed = 'payment_method__payment_method_update_failed',
  UpdatePaymentMethodUpdateSelected = 'payment_method__payment_method_update_selected',
  UpdatePaymentMethodUpdateSubmitted = 'payment_method__payment_method_update_submitted',
  BillCycleDateChangeSelected = 'bill__bill_cycle_date_change_selected',
  BillCycleDateNewDateSelected = 'bill__bill_cycle_date_new_date_selected',
  BillCycleDateChangeSubmitted = 'bill__bill_cycle_date_change_submitted',
  ChangePlanInitiated = 'change_plan_initiated',
  ChangePlanSelected = 'change_plan_selected',
  ChangePlanOrderSubmitted = 'change_plan_order_submitted',
  ChangePlanOrderCancelled = 'change_plan_order_cancelled',
  ChangePlanOrderSuccess = 'change_plan_order_successful',
  ChangePlanOrderFailed = 'change_plan_order_failed',
  ChangePlanNotSupported = 'change_plan_order_not_supported',
  ChangePlanChatInitiated = 'change_plan_chat_initiated',
  ErrorMessageDisplayed = 'Error Message',
}

export enum UsageEventNames {
  DataCardExpanded = 'data_card_expanded',
}

export type Versions = '1-0-0' | '1-0-1';

export type LogEventFunction = (
  category: string,
  action: string,
  params?: EventLogParams
) => void | Promise<void>;
export type LogUnstructuredEventFunction = (
  schema: string,
  data: JSON,
  context?: ContextSchema[]
) => void | Promise<void>;
export type TrackScreenFunction = (
  name: string,
  id: string,
  params?: ContextSchema[]
) => void | Promise<void>;
export type SetUserIDFunction = (userId: string) => void | Promise<void>;
export type AddGlobalContexts = (
  context?: ContextSchema[]
) => void | Promise<void>;

export interface AnalyticsContainer {
  logDebugEvents?: LogEventFunction[];
  logEvents: LogEventFunction[];
  logUnstructuredEvents: LogUnstructuredEventFunction[];
  trackScreens: TrackScreenFunction[];
  startTime: Date;
  setUserId: SetUserIDFunction[];
  crashlytics?: (error: Error) => void;
}

const noop = [
  (): void => {
    if (__DEV__) console.error('Undefined Analytics Context Container');
  },
];

const noopDate = new Date();

const AnalyticsContext = React.createContext<AnalyticsContainer>({
  logDebugEvents: noop,
  logEvents: noop,
  logUnstructuredEvents: noop,
  trackScreens: noop,
  startTime: noopDate,
  setUserId: noop,
  crashlytics: noop[0],
});

export function logAllEvents(loggers: LogEventFunction[]): LogEventFunction {
  return (category, action, params): void => {
    loggers.forEach((func): void => {
      try {
        func(category, action, params);
      } catch (e) {
        // Don't blow up app if analytics call does not work
      }
    });
  };
}

export function logAllUnstructuredEvents(
  loggers: LogUnstructuredEventFunction[]
): LogUnstructuredEventFunction {
  return (schema, data, context): void => {
    loggers.forEach((func): void => {
      try {
        func(schema, data, context);
      } catch (e) {
        // Don't blow up app if analytics call does not work
      }
    });
  };
}

export const AnalyticsProvider = ({
  logDebugEvents,
  logEvents,
  logUnstructuredEvents,
  trackScreens,
  startTime,
  setUserId,
  children,
  crashlytics,
}: AnalyticsContainer & {
  children?: React.ReactNode;
}): JSX.Element => {
  return (
    <AnalyticsContext.Provider
      value={{
        logDebugEvents,
        logEvents,
        logUnstructuredEvents,
        trackScreens,
        startTime,
        setUserId,
        crashlytics,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
export function triggerAfterUserIdSet(onUserIdSet: any): void {
  let isPixelUserIdSet = false;
  if (Platform.OS === 'web') {
    const interval = setInterval(() => {
      const w: any = window;
      w.pixel(function () {
        isPixelUserIdSet = Boolean(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this[`${env.pixel.namespace}`].getUserId()
        );
      });
      if (isPixelUserIdSet) {
        onUserIdSet();
        clearInterval(interval);
      }
    }, 2000);
  } else {
    setTimeout(() => {
      onUserIdSet();
    }, 3000);
  }
}

export function useStartTime(): Date {
  const { startTime } = useContext(AnalyticsContext);
  return startTime;
}

export function useTrackDebugEventFunction(): LogEventFunction {
  const { logDebugEvents } = useContext(AnalyticsContext);
  if (logDebugEvents) return logAllEvents(logDebugEvents);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return (() => {}) as LogEventFunction;
}

export function useTrackEventFunction(): LogEventFunction {
  const { logEvents } = useContext(AnalyticsContext);
  return logAllEvents(logEvents);
}

export function useTrackUnstructuredEventFunction(): LogUnstructuredEventFunction {
  const { logUnstructuredEvents } = useContext(AnalyticsContext);
  return logAllUnstructuredEvents(logUnstructuredEvents);
}

export function useSetUserID(): SetUserIDFunction {
  const { setUserId } = useContext(AnalyticsContext);
  return (userId): void => {
    setUserId.forEach((func): void => {
      try {
        func(userId);
      } catch {
        // Don't blow up app if analytics call does not work
      }
    });
  };
}

export function useTrackScreenFunction(): TrackScreenFunction {
  const { trackScreens } = useContext(AnalyticsContext);
  return (name, id, params): void => {
    trackScreens.forEach((func): void => {
      try {
        func(name, id, params);
      } catch {
        // Don't blow up app if analytics call does not work
      }
    });
  };
}

export const useTrackIsInViewport = (
  ref: React.MutableRefObject<View | null>,
  category: string,
  action: string,
  params?: EventLogParams
): void => {
  const trackEvent = useTrackEventFunction();
  useIsInViewPortEffect(
    ref,
    (): void => {
      trackEvent(category, action, params);
    },
    [],
    1000
  );
};

export const useUnstructEventsTrackIsInViewport = (
  ref: React.MutableRefObject<View | null>,
  vendor: Vendors,
  eventName: EventNames,
  version: Versions,
  data: JSON,
  context?: ContextSchema[] | undefined
): void => {
  const trackUnstructuredEvent = useTrackUnstructuredEventFunction();
  useIsInViewPortEffect(
    ref,
    (): void => {
      trackUnstructuredEvent(
        buildIgluSchema(vendor, eventName, version),
        data,
        context
      );
    },
    [],
    1000
  );
};

export function errorLogger(): (error: Error) => void {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { crashlytics } = useContext(AnalyticsContext);
  return (error: Error): void => {
    if (crashlytics) crashlytics(error);
  };
}

export const buildIgluSchema = (
  vendor: Vendors,
  eventName: EventNames | UsageEventNames | AnalyticsSchemaContext,
  version: Versions
): string => {
  return `iglu:${vendor}/${eventName}/jsonschema/${version}`;
};

export const useTrackBillingEvent = () => {
  const trackUnstructuredEvent = useTrackUnstructuredEventFunction();

  return (
    eventName: EventNames,
    version: Versions,
    data: any,
    billingContext: any | null = null
  ) => {
    trackUnstructuredEvent(
      buildIgluSchema(Vendors.Care, eventName, version),
      data,
      [
        {
          schema: buildIgluSchema(
            Vendors.Care,
            AnalyticsSchemaContext.BillingContext,
            '1-0-0'
          ),
          data: billingContext,
        },
      ]
    );
  };
};
