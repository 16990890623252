import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ProfilePageAlert.module.scss';
import { PageAlert } from '@vst/beam';
import {
  selectUserInfo,
  resetUpdateStatus,
} from '@mfe/to-be-migrated/redux/userInfo';

export const ProfilePageAlert = (): JSX.Element | null => {
  const { t: profileT } = useTranslation('NewProfile');
  const { t: commonT } = useTranslation('Common');

  const { wasUpdateSuccessful } = useSelector(selectUserInfo);

  const dispatch = useDispatch();

  if (wasUpdateSuccessful === null) return null;

  return (
    <div className={styles['alertSection']}>
      <PageAlert
        data-cy="profile-update-alert"
        key="profile-update-alert"
        title={commonT(wasUpdateSuccessful ? 'Success' : 'Error')}
        variant={wasUpdateSuccessful ? 'success' : 'error'}
        description={
          wasUpdateSuccessful
            ? profileT('contactUpdateSuccess')
            : profileT('contactUpdateError')
        }
        closeable={true}
        onClose={() => dispatch(resetUpdateStatus())}
      />
    </div>
  );
};
