import React from 'react';
import styles from './styles.module.scss';
import { Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

interface StepsProps {
  step: number;
  maxSteps: number;
}

const Steps = ({ step, maxSteps }: StepsProps) => {
  const { t } = useTranslation('ShopAddons');
  const width = `${(step / maxSteps) * 100}%`;
  return (
    <div data-cy="steps-container" className={styles['steps-container']}>
      <Txt data-cy="steps-verbiage" variant="bodySmallRegular" color="subtle">
        {t('stepsHeader', { step, maxSteps })}
      </Txt>
      <div className={styles['steps-background']}>
        <div
          data-cy="steps-bar"
          className={styles['steps-bar']}
          style={{ width }}
        />
      </div>
    </div>
  );
};

export default Steps;
