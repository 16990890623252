import React from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { Button } from './index';
import { ButtonProps } from './utils';

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
  },
  grow: {
    flexGrow: 1,
  },
});

export interface TandemButtonProps {
  buttonProps: ButtonProps[];
  containerStyle?: StyleProp<ViewStyle>;
}

export const TandemButton = ({
  buttonProps,
  containerStyle,
}: TandemButtonProps): JSX.Element => {
  if (buttonProps.length !== 2)
    throw new Error(
      'TandemButton requires two buttons. If one or more than 2 buttons are being used, use Button.'
    );

  const style: StyleProp<ViewStyle> = [styles.buttonContainer];
  if (containerStyle) style.push(containerStyle);

  return (
    <View style={style}>
      {buttonProps.map(
        (button: ButtonProps, index: number): JSX.Element => (
          <View
            style={[
              styles.grow,
              { marginLeft: index === 1 ? 8 : undefined, width: '50%' },
            ]}
            key={index}
          >
            <Button fill {...button} />
          </View>
        )
      )}
    </View>
  );
};
