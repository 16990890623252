import { Button, Txt, StandAloneLink } from '@vst/beam';

import { AnalyticsContext } from '@mfe/services/analytics';
import { useScreenResolution } from '@mfe/shared/util';

import { LINKS } from '../../constants';
import { PageName, ButtonName } from '../../analytics';

import {
  structuredEvent,
  AnalyticsAction,
  Categories,
} from '@mfe/to-be-migrated/redux/analytics';

import styles from './Introduction.module.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';

interface IntroductionFooterProps {
  handleContinue: () => void;
  handleBackToOverview: () => void;
  analyticsContext: AnalyticsContext[];
}

export const IntroductionFooter = (props: IntroductionFooterProps) => {
  const { handleContinue, handleBackToOverview, analyticsContext } = props;
  const { t } = useTranslation('ACP', { keyPrefix: 'Introduction' });
  const dispatch = useDispatch();
  const { isExtraSmall, isSmall } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;

  const handleGetVerified = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    dispatch(
      structuredEvent({
        category: Categories.ACP,
        action: AnalyticsAction.BUTTON_CLICKED,
        params: {
          context: analyticsContext,
          label: PageName.ADDITIONAL_DETAILS,
          property: ButtonName.GET_VERIFIED,
        },
      })
    );

    e.preventDefault();
    dispatch(openInNewTab(LINKS.NATIONAL_VERIFIER));
  };

  return (
    <div className={styles['footer']}>
      <Button
        data-cy="acp-application-button"
        variant="primary"
        onClick={handleContinue}
        fluid={isMobile}
      >
        <Txt variant="labelMedium">{t('footer.buttons.confirm')}</Txt>
      </Button>

      <Button variant="secondary" fluid={isMobile} style={{ padding: 0 }}>
        <StandAloneLink
          style={{ fontWeight: 600 }}
          className={styles['i-need-to-qulify-link']}
          linkSize="medium"
          variant="secondary"
          href="#"
          data-cy="acp-need-to-qualify-button"
          onClick={(e) => handleGetVerified(e)}
        >
          {t('footer.buttons.cancel')}
        </StandAloneLink>
      </Button>

      <Button
        data-cy="acp-back-to-overview-button"
        variant="tertiary"
        onClick={handleBackToOverview}
        fluid={isMobile}
      >
        <Txt variant="labelMedium">{t('footer.buttons.redirect')}</Txt>
      </Button>
    </div>
  );
};
