import { Lock } from '@vst/beam-icons/icons';
import { Button, Icon, SpinnerInline, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import type { Money } from '@mfe/shared/schema-types';

import { formatCurrency } from '../utils';

import styles from '../vpp.module.scss';

type Props = {
  txnAmount?: number;
  submit: boolean;
  balance?: Money | null;
  disabled: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};

const PaymentButtons = ({
  txnAmount,
  submit,
  balance,
  disabled,
  onSubmit,
  onCancel,
}: Props) => {
  const { t } = useTranslation('VPP');

  const amount = formatCurrency(
    txnAmount,
    balance?.alphabeticCode ?? undefined
  );

  return (
    <div className={styles['paymentButtonsContainer']}>
      <Button
        data-cy="payment-cancel-button"
        className={styles['cancelButton']}
        onClick={onCancel}
        variant="secondary"
      >
        <Txt variant="labelMedium" color="regular">
          {t('cancelButton')}
        </Txt>
      </Button>

      <Button
        data-cy="payment-submit-button"
        className={styles['paymentButton']}
        disabled={disabled}
        onClick={onSubmit}
      >
        {submit ? (
          <SpinnerInline />
        ) : (
          <Icon
            mr="8px"
            size={20}
            className={styles['paymentIcon']}
            icon={Lock}
          />
        )}

        <Txt
          variant="labelMedium"
          theme={disabled ? 'light' : 'dark'}
          color={disabled ? 'disabled' : 'regular'}
        >
          {submit ? t('processing') : t('payButton', { amount })}
        </Txt>
      </Button>
    </div>
  );
};

export default PaymentButtons;
