import { Button, StandAloneLink, PageAlert as BeamPageAlert } from '@vst/beam';
import { useDispatch } from 'react-redux';

import {
  Alert,
  AlertAction,
  AlertButton,
  removeAlert,
} from '@mfe/to-be-migrated/redux/alerts';
import { Route, setBillingNav } from '@mfe/to-be-migrated/redux/billingNav';
import {
  openInNewTab,
  postOverviewMessage,
} from '@mfe/to-be-migrated/redux/utils';
import {
  ContextSchema,
  structuredEvent,
  selfDescribingEvent,
  AnalyticsAction,
  AnalyticsEventNames,
  Categories,
} from '@mfe/to-be-migrated/redux/analytics';
import { EventTypes } from '@mfe/services/window-messages';
import {
  saveAlertNameInStorage,
  Storage,
  STORAGE_ALERTS_KEY,
  useTrackTiming,
} from '@mfe/shared/util';
import { createBuyMoreInitiated } from '@mfe/features/buy-more';

import styles from './styles.module.scss';
import * as React from 'react';
import { useAlertDescription } from '../../hooks';
import useNavigate from '@mfe/services/navigation';

type AnalyticsContexts = Record<
  'displayContext' | 'usageContext',
  ContextSchema
>;

interface PageAlertProps {
  analyticsContexts?: AnalyticsContexts;
  setBuyMoreDialog?: () => void;
  navigateToPage?: (link: string) => void;
  loading?: boolean;
  alert: Alert;
}

export const wasAlertDismissed = (alert: Alert) => {
  const storageData = Storage.getItem(STORAGE_ALERTS_KEY);
  return Object.keys(storageData || {}).includes(alert.title as string);
};

export const PageAlert = (props: PageAlertProps) => {
  const { alert, loading } = props;

  useTrackTiming({ eventName: 'AlertsLoaded', isLoading: loading ?? false });

  const dispatch = useDispatch();

  const alertDescription = useAlertDescription(alert);

  return (
    <BeamPageAlert
      className={`alerts-section ${styles['alerts-section']}`}
      data-cy="user-state-alert"
      title={alert.title}
      variant={alert.level}
      description={alertDescription}
      preventDefaultOnClose={true}
      onClose={() => {
        saveAlertNameInStorage(alert.title);
        dispatch(removeAlert(alert.title));
      }}
    >
      <AlertButtons {...props} />
    </BeamPageAlert>
  );
};

interface PageAlertButtonsProps {
  alert: Alert;
  analyticsContexts?: AnalyticsContexts;
  setBuyMoreDialog?: () => void;
  navigateToPage?: (link: string) => void;
}

export const AlertButtons = (props: PageAlertButtonsProps) => {
  const { analyticsContexts, setBuyMoreDialog, navigateToPage, alert } = props;
  const { goTo } = useNavigate();
  const dispatch = useDispatch();

  const handleButtonClick = (
    event: React.MouseEvent<HTMLElement>,
    alert: Alert
  ) => {
    if (
      alert.button?.action === AlertAction.RescheduleServiceCall ||
      alert.button?.action === AlertAction.ScheduleInstall
    ) {
      goTo('ScheduleInstallation');
    }

    if (alert.button) {
      getButtonAction(
        event,
        goTo,
        dispatch,
        alert.button,
        navigateToPage,
        analyticsContexts,
        setBuyMoreDialog
      );
    }
  };

  const handleLinkClick = (
    event: React.MouseEvent<HTMLElement>,
    alert: Alert
  ) => {
    if (alert.link) {
      getLinkAction(
        event,
        goTo,
        dispatch,
        alert.link,
        navigateToPage,
        analyticsContexts,
        setBuyMoreDialog
      );
    }
  };

  if (!alert.button && !alert.link) {
    return null;
  }

  return (
    <div className={styles['buttonsWrapper']}>
      {alert.button && (
        <Button
          data-cy="alert-button"
          variant="secondary"
          buttonSize="small"
          onClick={(event: React.MouseEvent<HTMLElement>) =>
            handleButtonClick(event, alert)
          }
        >
          {alert.button?.label}
        </Button>
      )}
      {alert.link && (
        <StandAloneLink
          variant="secondary"
          href="#"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            handleLinkClick(event, alert);
          }}
          linkSize="small"
          className={styles['alertsLink']}
          underline={true}
        >
          {alert.link?.label}
        </StandAloneLink>
      )}
    </div>
  );
};

function dispatchStructuredEvent(
  dispatch: React.Dispatch<any>,
  event: Parameters<typeof structuredEvent>[0],
  analyticsContexts?: AnalyticsContexts
) {
  if (analyticsContexts) {
    const { displayContext, usageContext } = analyticsContexts;

    dispatch(
      structuredEvent({
        ...event,
        params: {
          ...event.params,
          context: [displayContext, usageContext],
        },
      })
    );
  }
}

function getButtonAction(
  event: React.MouseEvent<HTMLElement>,
  goTo: (url: string) => void,
  dispatch: React.Dispatch<any>,
  button: AlertButton,
  navigateToPage?: (link: string) => void,
  analyticsContexts?: AnalyticsContexts,
  setBuyMoreDialog?: () => void
) {
  const buyMoreInitiated = createBuyMoreInitiated('usage_page');

  switch (button?.action) {
    case AlertAction.GoToBilling:
      if (navigateToPage) {
        navigateToPage('PayBill');
      }
      dispatch(setBillingNav({ route: Route.OneTimePayment }));
      dispatchStructuredEvent(
        dispatch,
        {
          category: Categories.Alert,
          action: AnalyticsAction.CTA_SELECTED,
          params: {
            property: 'Pay Balance',
          },
        },
        analyticsContexts
      );
      break;
    case AlertAction.BuyMore:
      if (setBuyMoreDialog) {
        dispatch(
          selfDescribingEvent({
            eventName: AnalyticsEventNames.BUY_MORE_INITIATED,
            context: [buyMoreInitiated.context],
          })
        );
        setBuyMoreDialog();
        dispatch(postOverviewMessage({ eventType: EventTypes.BuyMore }));
        dispatchStructuredEvent(
          dispatch,
          {
            category: Categories.Alert,
            action: AnalyticsAction.CTA_SELECTED,
            params: {
              property: 'Buy More',
            },
          },
          analyticsContexts
        );
      }
      break;
    case AlertAction.PlanUsage:
      dispatch(postOverviewMessage({ eventType: EventTypes.VisitPlanUsage }));
      if (navigateToPage) {
        navigateToPage('PlanUsage');
      }
      dispatchStructuredEvent(
        dispatch,
        {
          category: Categories.Alert,
          action: AnalyticsAction.CTA_SELECTED,
          params: {
            property: 'Plan & Usage',
          },
        },
        analyticsContexts
      );
      break;
    case AlertAction.Refresh:
      window.location.reload();
      break;
    case undefined:
      if (button.url) {
        if (!button.openInSameTab) {
          event.preventDefault();
          dispatch(openInNewTab(button.url));
        } else {
          goTo(button.url);
        }
      }
      break;
  }
}

function getLinkAction(
  event: React.MouseEvent<HTMLElement>,
  goTo: (url: string) => void,
  dispatch: React.Dispatch<any>,
  link: AlertButton,
  navigateToPage?: (link: string) => void,
  analyticsContexts?: AnalyticsContexts,
  setBuyMoreDialog?: () => void
) {
  if (!link.action && link.url) {
    dispatchStructuredEvent(
      dispatch,
      {
        category: Categories.Alert,
        action: AnalyticsAction.CTA_SELECTED,
        params: {
          property: 'Support link',
          label: link.url,
        },
      },
      analyticsContexts
    );

    if (!link.openInSameTab) {
      event.preventDefault();
      dispatch(openInNewTab(link.url));
    }
  }

  if (link.action) {
    return getButtonAction(
      event,
      goTo,
      dispatch,
      link,
      navigateToPage,
      analyticsContexts,
      setBuyMoreDialog
    );
  }
}
