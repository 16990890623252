import React from 'react';
import { Icon, StandAloneLink, Surface, Txt } from '@vst/beam';
import { SpeedOutlined, OpenInNewOutlined } from '@vst/beam-icons/icons';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useScreenResolution } from '@mfe/shared/util';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';

import styles from '../styles.module.scss';

const SpeedTest = () => {
  const { t } = useTranslation('Network', { keyPrefix: 'speedtest' });
  const dispatch = useDispatch();

  const { isExtraLarge, isLarge, isMedium } = useScreenResolution();
  const isDesktop = isExtraLarge || isLarge || isMedium;

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    if (event) event.preventDefault();
    const url = t('link');

    dispatch(openInNewTab(url));
  };

  return (
    <Surface
      radius={isDesktop ? '16px' : '0px'}
      data-cy="speedtest"
      className={styles['helpCardContainer']}
    >
      <Icon
        icon={SpeedOutlined}
        size={40}
        color="gray_600"
        data-cy="speedtest-icon"
      />

      <div className={styles['helpCardTextLink']}>
        <Txt data-cy="speedtest-verbiage" variant="bodyLargeRegular">
          {t('checkYourNetworkSpeeds')}
        </Txt>
        <StandAloneLink
          style={{ alignItems: 'center' }}
          data-cy="speedtest-link"
          variant="primary"
          href=""
          linkSize="medium"
          onClick={onClick}
          icon={OpenInNewOutlined}
        >
          <Txt variant="labelMedium"> {t('runSpeedTest')}</Txt>
        </StandAloneLink>
      </div>
    </Surface>
  );
};

export default SpeedTest;
