import { ShopAddonsLink } from '@mfe/legacy/mv/utils/Navigation';
import { NestedRoutes, RouteRenderProps } from '../utils';
import ShopAddonsPage from '../../views/ShopAddons';
import CheckoutPage from '../../views/ShopAddons/Checkout';

/*
 *
 * I followed the example of
 *   apps/mv-web/src/Navigator/NestedSwitch/PlanSwitch.tsx
 * to make the routing work :(
 *
 * Why is our routing so complicated?
 */
export const shopAddonsRoutes: RouteRenderProps[] = [
  {
    path: ShopAddonsLink.ShopAddons,
    render: <ShopAddonsPage />,
    exact: true,
    fromParent: 0,
  },
  {
    path: ShopAddonsLink.Checkout,
    render: <CheckoutPage />,
    exact: true,
    fromParent: 1,
  },
];

export const ShopAddonsSwitch = (): JSX.Element => {
  return <NestedRoutes routes={shopAddonsRoutes} />;
};
