import { IllustrativeIcon } from '@vst/beam';
import { Alert } from '@vst/beam-icons/illustrative-icons';
import { Pages } from '@mfe/to-be-migrated/redux/streamOnPromo';
import { FlowEnd } from '../../components/FlowEnd';
import styles from './errorPage.module.scss';

export const ErrorPage = () => {
  return (
    <FlowEnd
      namespace="ErrorPage"
      page={Pages.error}
      titleKey="Title"
      descriptionKey="SubTitle"
    >
      <IllustrativeIcon
        className={styles['iconContainer']}
        icon={Alert}
        color="teal_600"
      />
    </FlowEnd>
  );
};
