import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyState, Icon, StandAloneLink, Surface } from '@vst/beam';
import { Payment, DateRange } from '@vst/beam-icons/icons';
import { Alert } from '@vst/beam-icons/illustrative-icons';

import { BadgeStatus } from '@mfe/shared/schema-types';
import { EventTypes } from '@mfe/services/window-messages';
import { Issuer, selectConfig } from '@mfe/shared/redux/config';
import { useTrackTiming } from '@mfe/shared/util';
import { postBillingMessage } from '@mfe/to-be-migrated/redux/utils';
import { trackBillingEvent } from '@mfe/to-be-migrated/redux/analytics';
import { selectBillingInfo } from '@mfe/to-be-migrated/redux/billingInfo';

import Summary from './summary';
import SummaryLoading from './summary/loading';
import { goToAutopay } from '../../shared/navigation';
import { ANALYTICS_EVENT_NAME } from '../../shared/constants';

import styles from './styles.module.scss';
import useNavigate from '@mfe/services/navigation';
import { PayBillLink } from '@mfe/legacy/mv/utils/Navigation';
import { setIsConfirmationModalOpen } from '@mfe/to-be-migrated/redux/updateBillCycle';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';

const BillingSummary = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Error');

  const {
    billingInfo: { summaryInvoiceStatus },
    loading,
    billingLoading,
    errors: apiError,
  } = useSelector(selectBillingInfo);

  const {
    user: {
      auth: {
        tokenInfo: { idToken },
      },
    },
  } = useSelector(selectUser);

  const isPlatform = !idToken;

  const { goTo } = useNavigate();

  const config = useSelector(selectConfig);

  const warning = summaryInvoiceStatus === BadgeStatus.PastDue;
  const isSalesforce = config.issuer === Issuer.Salesforce;

  useTrackTiming({
    eventName: 'BillingSummaryLoaded',
    isLoading: loading || billingLoading,
  });

  const handleChangePaymentMethod = () => {
    goToAutopay(dispatch, 'billing_page');
  };

  const openBillCycleModal = () => {
    if (!isPlatform) {
      goTo(`/${PayBillLink.PayBill}/${PayBillLink.UpdateBillCycle}`);
    }

    dispatch(setIsConfirmationModalOpen(true));
  };

  const handleChangeBillDate = () => {
    dispatch(
      trackBillingEvent({
        eventName: ANALYTICS_EVENT_NAME.billCycleDateChangeSelected,
        version: '1-0-0',
        data: {
          selection_method: 'change_bill_date_method_button',
          selection_zone: 'billing_page',
        },
      })
    );

    dispatch(
      postBillingMessage({
        eventType: EventTypes.ChangeBillDate,
      })
    );

    openBillCycleModal();
  };

  const isLoading = loading || billingLoading;

  if (isLoading) {
    return <SummaryLoading />;
  }

  if (apiError) {
    return (
      <Surface className={styles['errorCard']}>
        <EmptyState
          icon={Alert}
          title={t('title')}
          description={t('message', { error: t('billingsummary') })}
        />
      </Surface>
    );
  }

  return (
    <Surface
      data-cy="billingSummary"
      variant="primary"
      className={styles['container']}
    >
      <Surface className={warning ? styles['cardWarning'] : styles['card']}>
        <Summary />

        <Links
          isSalesforce={isSalesforce}
          handleChangeBillDate={handleChangeBillDate}
          handleChangePaymentMethod={handleChangePaymentMethod}
        />
      </Surface>
    </Surface>
  );
};

interface Props {
  isSalesforce: boolean;
  handleChangePaymentMethod: () => void;
  handleChangeBillDate: () => void;
}

const Links = ({
  handleChangePaymentMethod,
  handleChangeBillDate,
  isSalesforce,
}: Props) => {
  const { t } = useTranslation('Summary');

  return (
    <>
      <Surface className={styles['link']} data-cy="changePaymentLink-button">
        <Icon icon={Payment} mr="16px" color="gray_600" />
        <StandAloneLink
          data-cy="changePaymentLink"
          variant="secondary"
          disabled={isSalesforce}
          href={''}
          onClick={(e) => {
            e.preventDefault();
            if (isSalesforce) {
              return;
            }
            handleChangePaymentMethod();
          }}
          linkSize="large"
        >
          {t('changePaymentMethod')}
        </StandAloneLink>
      </Surface>

      <Surface className={styles['link']} data-cy="changeBillDateLink-button">
        <Icon icon={DateRange} mr="16px" color="gray_600" />

        <StandAloneLink
          data-cy="changeBillDateLink"
          variant="secondary"
          disabled={isSalesforce}
          href={''}
          onClick={(e) => {
            e.preventDefault();
            if (isSalesforce) {
              return;
            }
            handleChangeBillDate();
          }}
          linkSize="large"
        >
          {t('changeBillDate')}
        </StandAloneLink>
      </Surface>
    </>
  );
};

export default BillingSummary;
