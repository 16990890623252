import React from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';
import { Color } from '../utils/Color/consts';

const bar: ViewStyle = {
  width: '100%',
  height: 10,
  borderRadius: 3,
};

export interface StatusBarStyles {
  statusBar: ViewStyle;
  smallerBar: ViewStyle;
}

export const statusBarStyles = StyleSheet.create<StatusBarStyles>({
  statusBar: {
    ...bar,
    backgroundColor: Color.gray100,
    zIndex: 0,
  },
  smallerBar: {
    ...bar,
    backgroundColor: Color.teal500,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    zIndex: 1,
  },
});

export interface StatusBarProps {
  percentage: string;
  color?: typeof Color.black;
  style?: ViewStyle;
  progressBarStyle?: ViewStyle;
}

export const StatusBar = ({
  percentage,
  color,
  style = {},
  progressBarStyle = {},
}: StatusBarProps) => {
  return (
    <View
      style={[statusBarStyles.statusBar, style]}
      importantForAccessibility="no-hide-descendants"
    >
      <View
        style={[
          statusBarStyles.smallerBar,
          progressBarStyle,
          { width: `${percentage}%` } as ViewStyle,
          ...(color ? [{ backgroundColor: color } as ViewStyle] : []),
        ]}
      />
    </View>
  );
};
