import React from 'react';
import { View } from 'react-native';
import { SvgComp } from './shared';

export const webSafeSvg = (SvgComponent: SvgComp): SvgComp => {
  const WebSafeSvg: SvgComp = ({
    testID = '',
    style = {},
    ...otherProps
  }): JSX.Element => (
    <View testID={testID} style={style}>
      <SvgComponent {...otherProps} />
    </View>
  );

  return WebSafeSvg;
};
