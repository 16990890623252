/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Linking, Platform } from 'react-native';
import FileViewer from 'react-native-file-viewer';
import RNFS from 'react-native-fs';
import { useSelector } from 'react-redux';

import env from '../env';
import StorageManager from '../services/localStorage';
import { GetAccountInfoPayloadWithUsername } from '../types/accountInfo';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { ProductInstanceStatus, TokenType } from '@mfe/shared/schema-types';

export const ScrollTarget = React.createContext(
  Platform.OS === 'web'
    ? (x = 0, y = 0, animated = false) =>
        window.scrollTo({
          top: y,
          left: x,
          behavior: animated ? 'smooth' : 'auto',
        })
    : () => {
        if (__DEV__) {
          console.error('No Ref Set!');
        }
      }
);

export const formatString = (
  string: string,
  values: Record<string, any>
): string => {
  let newString: string = string;
  for (const [key, value] of Object.entries(values)) {
    const replacementString = `<${key}>`;
    newString = newString.replace(replacementString, value as string);
  }

  return newString;
};

export const formatMacAddress = (macAddress: string): string => {
  return macAddress // abcdef123456 or ab:cd:ef:12:34:56
    .replace(/:?(.{2})/g, '$1:') // ab:cd:ef:12:34:56:
    .slice(0, -1) // ab:cd:ef:12:34:56
    .toUpperCase(); // AB:CD:EF:12:34:56
};

export const callPhone = (phoneNumber: string): Promise<any> =>
  Linking.openURL(
    Platform.OS === 'ios' ? `telprompt:${phoneNumber}` : `tel:${phoneNumber}`
  );

export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

const accountActive = (accountStatus: ProductInstanceStatus): boolean =>
  [ProductInstanceStatus.Active].includes(accountStatus);

const accountPreInstall = (accountStatus: ProductInstanceStatus): boolean =>
  [ProductInstanceStatus.Preinstall, ProductInstanceStatus.Canceled].includes(
    accountStatus
  );

const accountDisconnected = (accountStatus: ProductInstanceStatus): boolean =>
  [ProductInstanceStatus.Deactivated].includes(accountStatus);

// We assume that the user's account is suspended if their account is in another state
export const accountSuspended = (
  accountStatus: ProductInstanceStatus
): boolean =>
  !accountActive(accountStatus) &&
  !accountPreInstall(accountStatus) &&
  !accountDisconnected(accountStatus);

export type UseAccountInfoPayload = Pick<
  GetAccountInfoPayloadWithUsername,
  'accountStatus' | 'accountReference'
> & {
  isAccountActive: boolean;
  isAccountPreInstall: boolean;
  isAccountDisconnected: boolean;
  isAccountSuspended: boolean;
};
export function useAccountStatus(): UseAccountInfoPayload {
  const { user } = useSelector(selectUser);
  const productInstanceStatus =
    user.productInstanceStatus as ProductInstanceStatus;

  return {
    accountStatus: productInstanceStatus,
    accountReference: user.accountNumber,
    isAccountActive: accountActive(productInstanceStatus),
    isAccountPreInstall: accountPreInstall(productInstanceStatus),
    isAccountDisconnected: accountDisconnected(productInstanceStatus),
    isAccountSuspended: accountSuspended(productInstanceStatus),
  };
}

export interface Obj {
  [key: string]: any;
}

function mergeObject(target: Obj, input: Obj): Obj {
  const conflictingKeys = new Set([
    ...Object.keys(target),
    ...Object.keys(input),
  ]);
  const final = {
    ...target,
    ...input,
  };

  conflictingKeys.forEach((key) => {
    if (key in target && key in input) {
      final[key] = {
        ...target[key],
        ...input[key],
      };
    }
  });

  return final;
}

export function mergeObjects(target: Obj, ...inputs: Obj[]): Obj {
  let resolver = target;
  inputs.forEach((input): void => {
    resolver = mergeObject(resolver, input);
  });
  return resolver;
}

export const externalLink = (location: string | null | undefined): void => {
  if (!isDefined(location)) return;

  if (Platform.OS === 'web') {
    window.open(location, '_blank');
    return;
  }

  Linking.openURL(location).catch(() => {
    if (__DEV__) console.error('Unknown external link: ' + location);
  });
};

export const fetchInvoice = async (
  data: { url?: string | null; invoiceNumber?: string | null },
  token: { token?: string; tokenType?: TokenType },
  onCompleted?: (showPopupMessage?: boolean) => void
): Promise<void> => {
  if (!isDefined(data.url))
    throw new Error('Invoice date required for fetching invoice.');
  if (!env.debug.skipLogin && !isDefined(token))
    throw new Error(
      'Token required for fetching invoice when skipLogin is false.'
    );

  const location = env.backEndUrls.backEndUrl.replace(
    '/graphql',
    `/invoice/${data.url}`
  );
  const locationUrl = Platform.select({
    android: location.replace('localhost', '10.0.2.2'),
    default: location,
  });

  const res = await fetch(locationUrl, {
    credentials: 'include',
    headers: {
      'x-auth-token': token.token ?? '',
      'x-auth-type': token.tokenType ?? 'Okta',
    },
  });

  const encoded = await res.text();
  if (res.status !== 200 || !encoded) {
    onCompleted?.();
    throw new Error('Could not fetch invoice.');
  }

  // mobile
  const path = `${RNFS.DocumentDirectoryPath}/mv-invoice.pdf`;
  await RNFS.writeFile(path, encoded, 'base64');
  FileViewer.open(path, {
    // ios
    displayName: 'My Viasat Invoice',
    // android
    showAppsSuggestions: true,
    showOpenWithDialog: true,
  }).catch(() => {
    // log failure
  });
  onCompleted?.(false);
};

export enum ValidationTypes {
  Email = 'EMAIL',
  PhoneNumber = 'PHONE',
  Password = 'PASSWORD',
  Address = 'ADDRESS',
}

// email is form:
// ex- name@email.com, first.last@email.com
export const ValidateEmail = (email: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

// phone number must be:
// +# ###-###-#### or +## ###-###-####
// the first 3 digit sequence can be enclosed by parentheses and -'s can be replaced with spaces
// keep in mind the <TextField> prop from andromeda trys to automatically format phone numbers
export const ValidatePhoneNumber = (number: string): boolean => {
  return /^(\+\d{1,2}\s)\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(number);
};

// return true if undefined (blank input)
export const ValidationState = (
  content?: string,
  title?: ValidationTypes
): boolean => {
  if (!content) return true;
  switch (title) {
    case ValidationTypes.Email:
      return ValidateEmail(content);
    case ValidationTypes.PhoneNumber:
      return ValidatePhoneNumber(content);
    default:
      return true;
  }
};

export const UpdateValidation = (
  text: string,
  setIsValid?: React.Dispatch<React.SetStateAction<boolean>>,
  isRequired?: boolean,
  title?: ValidationTypes
): void => {
  // any required field that is empty is automatically not valid
  if (isRequired && text.length === 0) {
    setIsValid?.(false);
    return;
  }

  // if there is validation on this type of input
  if (title) {
    const isValid = ValidationState(text, title);
    setIsValid?.(isValid);
  }

  // no validation, but is required and there is content == valid
  if (!title && isRequired && text.length >= 0) {
    setIsValid?.(true);
  }
};

// Slightly modified from: https://stackoverflow.com/a/1099670
export const getURLParams = (url: string): Record<string, string> => {
  const re = /[?&]?([^=]+)=([^&]*)/g;
  const qs = url.replace(/.*\?/, '').replace(/\+/g, ' ');
  const params: Record<string, string> = {};
  let tokens: RegExpExecArray | null;

  // Loop over entire url
  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
};

export const toLowerCaseFirst = (input: string | null): string =>
  input !== null && input.length > 2
    ? input[0].toLowerCase() + input.substr(1)
    : '';

export const dismissModal = async (
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean | undefined>>,
  logger: (error: Error) => void
): Promise<void> => {
  try {
    const value = await StorageManager.get('dismiss');
    if (value) {
      setIsModalVisible(false);
    } else {
      setIsModalVisible(true);
      await StorageManager.set('dismiss', 'modal');
    }
  } catch (e) {
    logger(new Error(e as any));
  }
};
