import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NumericFormat from 'react-number-format';

import { colors, RadioButton, TextField, Txt } from '@vst/beam';

import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import {
  retrieveTransactionId,
  setPayments,
} from '@mfe/to-be-migrated/redux/payments';

import { Invoice, Locale, Money } from '@mfe/shared/schema-types';
import { getCurrencySymbol } from '@mfe/shared/util';

import Divider from '../../shared-ui/divider';
import { formatCurrency } from '../utils';

import { MethodType } from './payment-method';

import styles from '../vpp.module.scss';

export enum AmountType {
  balance = 'balance',
  other = 'other',
}

const amountRange = {
  MIN: 5,
  MAX: 999.99,
};

const getInvoiceAmount = (userLocale: Locale, invoice: Invoice) => {
  if (userLocale === Locale.PtBr)
    return formatCurrency(
      invoice.amountOwedOnInvoice?.value,
      invoice?.amountOwedOnInvoice?.alphabeticCode ?? undefined
    );

  return formatCurrency(
    invoice.invoiceAmount?.value,
    invoice?.invoiceAmount?.alphabeticCode ?? undefined
  );
};

const InvoiceAmount = ({ invoice }: { invoice: Invoice }) => {
  const { t } = useTranslation('VPP');

  const dispatch = useDispatch();
  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  useEffect(() => {
    if (invoice?.invoiceAmount?.value) {
      dispatch(setPayments({ txnAmount: invoice?.invoiceAmount?.value }));
    }
  }, [dispatch, invoice]);

  return (
    <RadioButton
      value="balance"
      label={
        <Txt variant="bodySmallRegular">
          {t('invoiceAmount', {
            invoiceNumber: invoice?.invoiceNumber,
            invoiceAmount: getInvoiceAmount(userLocale, invoice),
            statementDate: invoice.statementDate
              ? new Date(invoice.statementDate)
              : new Date(),
          })}
        </Txt>
      }
      checked={true}
      className={styles['radioButton']}
    />
  );
};

type Props = {
  balance?: Money | null;
  invoice?: Invoice | null;
  amountType: AmountType;
  methodType: MethodType;
  setAmountType: React.Dispatch<React.SetStateAction<AmountType>>;
};

type CustomTextFieldProps = {
  inputRef: (instance: HTMLInputElement | null) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: number;
  name?: string;
  [key: string]: unknown; // Allow any additional props
};

const CustomAmountField = ({
  inputRef,
  onChange,
  error,
  value,
  ...rest
}: CustomTextFieldProps) => {
  const { t } = useTranslation('VPP');

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const isEU = userLocale === Locale.ItIt;
  const translationLocale = userLocale === Locale.ItIt ? '_EURO' : '';

  const CurrencyText = (
    <Txt variant="bodySmallRegular" color="regular">
      {getCurrencySymbol(userLocale)}
    </Txt>
  );

  return (
    <TextField
      ref={inputRef}
      {...rest}
      data-cy="amount-input"
      name="paymentAmount"
      style={{ marginLeft: userLocale !== Locale.EnUs ? 8 : 0 }}
      contentLeft={!isEU ? CurrencyText : undefined}
      contentRight={isEU ? CurrencyText : undefined}
      onChange={onChange}
      value={value}
      maxLength={8}
      helperTextSlot={
        <Txt variant="tinyItalic" color={error ? 'error' : 'subtle'}>
          {value > amountRange.MAX
            ? t(`paymentAmountMax${translationLocale}`)
            : t(`paymentAmountTip${translationLocale}`)}
        </Txt>
      }
    />
  );
};

const BalanceAmount = ({
  balance,
  amountType,
  methodType,
  setAmountType,
}: Props) => {
  const { t } = useTranslation('VPP');
  const dispatch = useDispatch();

  const { value: balanceValue = amountRange.MIN, text: formattedBalance } =
    balance as { value: number; text: string };

  const initOtherAmount =
    balanceValue < amountRange.MIN ? amountRange.MIN : balanceValue;

  const [otherAmount, setOtherAmount] = useState(() => initOtherAmount);
  const [error, setError] = useState(false);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const debounce = (
    callback: { (): void; (): void },
    delay: number | undefined
  ) => {
    clearTimeout(debounceTimeout.current as NodeJS.Timeout);
    debounceTimeout.current = setTimeout(() => {
      callback();
    }, delay);
  };

  // set balance/otherAmount change to store
  useEffect(() => {
    if (amountType === AmountType.balance) {
      dispatch(setPayments({ txnAmount: balanceValue }));
      return;
    }
    dispatch(setPayments({ txnAmount: Number(otherAmount) }));
  }, [amountType, balanceValue, dispatch, otherAmount]);

  // clear the already sent transaction id request
  const debouncedTransactionId = (amount: number) => {
    const isInvalid = amount < amountRange.MIN || amount > amountRange.MAX;
    setError(isInvalid);

    return debounce(() => {
      if (!isInvalid && methodType === MethodType.other) {
        dispatch(retrieveTransactionId(null));
      } else {
        clearTimeout(debounceTimeout.current as NodeJS.Timeout);
      }
    }, 500);
  };

  const handleChange = (type: AmountType) => () => {
    const txnAmount = type === AmountType.balance ? balanceValue : otherAmount;
    setAmountType(type);
    debouncedTransactionId(txnAmount);
    dispatch(
      setPayments({
        txnAmount,
      })
    );
  };

  const handleOtherAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const amount = Number(event.target.value);

    debouncedTransactionId(amount);
    setOtherAmount(amount);
  };

  return (
    <>
      {balanceValue > 0 && (
        <RadioButton
          value="balance"
          label={
            <Txt variant="bodySmallRegular">
              {t('balanceAmount', { amount: formattedBalance })}
            </Txt>
          }
          checked={amountType === AmountType.balance}
          onChange={handleChange(AmountType.balance)}
          className={styles['radioButton']}
        />
      )}

      {balanceValue <= 0 && (
        <div className={styles['radioButton']}>
          <Txt variant="bodySmallRegular" color="subtle">
            {t('balanceAmountPaid', { amount: formattedBalance })}
          </Txt>
        </div>
      )}

      <Divider />

      <RadioButton
        data-cy="pay-other-amount-radio-button"
        value="other"
        label={t('payOtherAmount')}
        checked={amountType === AmountType.other}
        onChange={handleChange(AmountType.other)}
        className={styles['radioButton']}
      />
      {amountType === AmountType.other && (
        <div className={styles['textInput']}>
          <NumericFormat
            name="paymentAmount"
            value={otherAmount}
            allowNegative={false}
            customInput={CustomAmountField}
            onChange={handleOtherAmountChange}
            error={error}
            color={colors.gray[800]}
            decimalScale={2}
            fixedDecimalScale
          />
        </div>
      )}
    </>
  );
};

const PaymentAmount = ({
  balance,
  invoice,
  amountType,
  methodType,
  setAmountType,
}: Props) => {
  return (
    <div className={styles['radioGroupContainer']} data-cy="pay-balance">
      {invoice ? (
        <InvoiceAmount invoice={invoice} />
      ) : (
        <BalanceAmount
          balance={balance}
          amountType={amountType}
          methodType={methodType}
          setAmountType={setAmountType}
        />
      )}
    </div>
  );
};

export default PaymentAmount;
