import React from 'react';
import { format } from 'date-fns';
import { Txt, SectionAlert } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { CalendarToday } from '@vst/beam-icons/icons';

import {
  FlowSuccessOutcome,
  SelectedDate,
  Container,
} from '@mfe/shared/components';
import { ScheduleInput } from '@mfe/shared/schema-types';
import { formatTimeInterval } from '@mfe/to-be-migrated/redux/scheduleInstallation';

export interface ScheduleSuccessProps {
  goToOverview: () => void;
  selectedDate: SelectedDate;
  selectedInterval: ScheduleInput | null;
}

export const ScheduleSuccess = ({
  goToOverview,
  selectedDate,
  selectedInterval,
}: ScheduleSuccessProps) => {
  const { t } = useTranslation('NewScheduleInstallation', {
    keyPrefix: 'AppointmentScheduled',
  });

  const formattedDate =
    selectedDate.day &&
    selectedDate.month &&
    selectedDate.year &&
    format(
      new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day),
      'LLL d'
    );
  return (
    <Container mt="56px">
      <FlowSuccessOutcome goToOverview={goToOverview}>
        <Txt
          data-cy="appointmend-scheduled-title"
          mt="48px"
          mb="40px"
          variant="bodyLargeBold"
        >
          {t('title')}
        </Txt>

        <SectionAlert
          data-cy="appointmend-scheduled-SectionAlert"
          variant="infoPrimary"
          mb="40px"
          contentSlot={
            <div>
              <Txt color="regular" component="span" variant="smallBold">
                {t('alertTitle')}
              </Txt>
              <Txt color="regular" component="span" variant="smallRegular">
                {t('alertContent', {
                  DATE: formattedDate,
                  TIME: formatTimeInterval([
                    selectedInterval?.from ?? '',
                    selectedInterval?.to ?? '',
                  ]),
                })}
              </Txt>
            </div>
          }
          icon={CalendarToday}
        />
      </FlowSuccessOutcome>
    </Container>
  );
};
