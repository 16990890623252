import { TFunction } from 'i18next';

import { BadgeStateType } from '@vst/beam';

import {
  BillingAccount,
  InvoiceStatus,
  SettlementStatus,
} from '@mfe/shared/schema-types';
import { getDate } from '@mfe/shared/util';

export enum InvoiceCurrentStatus {
  Ready = 'Ready',
  Paid = 'Paid',
  PastDue = 'PastDue',
}

interface BadgeStatusProps {
  invoiceStatus?: InvoiceStatus | null;
  invoiceSettlementStatus?: SettlementStatus | null;
}

const getInvoiceCurrentStatus = ({
  invoiceStatus,
  invoiceSettlementStatus,
}: BadgeStatusProps): InvoiceCurrentStatus | null => {
  if (invoiceStatus === InvoiceStatus.Paid) {
    return InvoiceCurrentStatus.Paid;
  }

  if (invoiceStatus === InvoiceStatus.Pending) {
    if (invoiceSettlementStatus === SettlementStatus.Issued) {
      return InvoiceCurrentStatus.Ready;
    }

    if (invoiceSettlementStatus === SettlementStatus.PastDue) {
      return InvoiceCurrentStatus.PastDue;
    }
  }

  if (
    invoiceStatus === InvoiceStatus.Unpaid &&
    invoiceSettlementStatus === SettlementStatus.PastDue
  ) {
    return InvoiceCurrentStatus.PastDue;
  }

  return null;
};

type BadgeProps = {
  label: string;
  state: BadgeStateType;
  status: InvoiceCurrentStatus | null;
};

export const getBadgeProps = (
  { invoiceStatus, invoiceSettlementStatus }: BadgeStatusProps,
  t: TFunction
): BadgeProps => {
  const status = getInvoiceCurrentStatus({
    invoiceStatus,
    invoiceSettlementStatus,
  });

  switch (status) {
    case InvoiceCurrentStatus.Paid:
      return {
        label: t('badge.paid'),
        state: 'positive',
        status,
      };
    case InvoiceCurrentStatus.Ready:
      return {
        label: t('badge.ready'),
        state: 'infoPrimary',
        status,
      };
    case InvoiceCurrentStatus.PastDue:
      return {
        label: t('badge.overdue'),
        state: 'negative',
        status,
      };

    default:
      return {
        label: '',
        state: 'positive',
        status,
      };
  }
};

export const getPaymentDate = (
  { invoiceStatus, invoiceSettlementStatus }: BadgeStatusProps,
  billingAccount: BillingAccount | null | undefined
) => {
  const status = getInvoiceCurrentStatus({
    invoiceStatus,
    invoiceSettlementStatus,
  });

  switch (status) {
    case InvoiceCurrentStatus.Paid:
      return {
        context: 'Paid',
        dueDate: getDate(billingAccount?.dueDate ?? undefined),
      };
    case InvoiceCurrentStatus.Ready:
      return {
        context: 'Ready',
        dueDate: getDate(billingAccount?.dueDate ?? undefined),
      };

    default:
      return {
        context: '',
        dueDate: undefined,
      };
  }
};
