import styles from '../BodyContent.module.scss';

const LoadingContactInformation = () => (
  <div className={styles['loadingContainerWithPadding']}>
    <div className={styles['loading']} style={{ width: '50%' }} />
    <div
      className={styles['loading']}
      style={{ marginTop: '8px', marginBottom: '24px' }}
    />
    <div className={styles['loading']} style={{ width: '50%' }} />
    <div
      className={styles['loading']}
      style={{ marginTop: '8px', marginBottom: '24px' }}
    />
    <div className={styles['loading']} style={{ width: '50%' }} />
    <div
      className={styles['loading']}
      style={{ marginTop: '8px', marginBottom: '24px' }}
    />
  </div>
);

export default LoadingContactInformation;
