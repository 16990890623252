import { useSelector } from 'react-redux';
import Info from '@vst/beam-icons/icons/Info';
import { useTranslation } from 'react-i18next';
import { Surface, Txt, StandAloneLink, Tooltip, Icon } from '@vst/beam';

import {
  formatPrice,
  selectChangePlan,
  selectChosenPlan,
} from '@mfe/to-be-migrated/redux/changePlan';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';

import styles from './InternetSummary.module.scss';

interface InternetSummaryProps {
  planName: string;
  handleBackNavigation: (e: React.MouseEvent<HTMLElement>) => void;
}

export const InternetSummary = ({
  planName,
  handleBackNavigation,
}: InternetSummaryProps) => {
  const { t } = useTranslation('NewChangePlan', {
    keyPrefix: 'orderReview.cartDetails',
  });

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);
  const {
    cart: {
      cartItems: { plan },
      prices: {
        monthly: { totalWithoutPromos },
      },
    },
  } = useSelector(selectChangePlan);

  const selectedPlan = useSelector(selectChosenPlan);
  const { price, priceCurrency } = selectedPlan;
  const priceWithCurrency = formatPrice(userLocale, price, priceCurrency);
  const totalWithCurrency = formatPrice(
    userLocale,
    totalWithoutPromos,
    priceCurrency
  );

  const leaseFeePrice = plan?.leaseFeePrice;
  const contractTerms = plan?.contractTerms;

  const formattedLeaseFee = formatPrice(
    userLocale,
    plan?.leaseFeePrice,
    priceCurrency
  );

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    handleBackNavigation(e);
  };

  const contractPeriodTitle = contractTerms?.includes('24')
    ? t('cartSummary.24monthContract')
    : t('cartSummary.MonthToMonth');

  const contractPeriodTooltip = contractTerms?.includes('24')
    ? t('cartSummary.24monthsTooltip')
    : t('cartSummary.MonthToMonthTooltip');

  return (
    <Surface
      data-cy="internet-summary"
      className={styles['internet-summary-container']}
      variant="secondary"
      radius="8px"
      p="16px"
    >
      <div
        data-cy="internet-summary-title"
        className={styles['internetSummaryDataInfo']}
      >
        <Txt variant="heading6">{t('pricingDetails.title')}</Txt>

        <StandAloneLink
          data-cy="change-plan-button"
          onClick={onClick}
          href=""
          underline={false}
          linkSize="small"
          style={{ textDecoration: 'none' }}
        >
          {t('changeButton')}
        </StandAloneLink>
      </div>

      <div className={styles['cart-items-container']}>
        <InternetProduct
          planName={planName}
          priceWithCurrency={priceWithCurrency}
        />

        {contractTerms && (
          <div
            className={styles['contract']}
            data-cy="internet-product-contract"
          >
            <Txt variant="tinyItalic">{contractPeriodTitle}</Txt>

            <Tooltip
              data-cy="internet-product-tooltip-text"
              bodyText={contractPeriodTooltip}
              variant="description"
              beakPosition="center"
            >
              <Icon icon={Info} color="gray_600" size="16px" />
            </Tooltip>
          </div>
        )}

        {leaseFeePrice && (
          <div
            data-cy="lease-fee-item"
            className={styles['internetSummaryDataInfo']}
          >
            <Txt variant="bodySmallRegular" mt="12px">
              {t('cartSummary.MonthlyLeaseFee')}
            </Txt>
            <Txt
              color="subtle"
              variant="bodySmallBold"
              data-cy="lease-fee-value"
              mt="12px"
            >
              {formattedLeaseFee}
            </Txt>
          </div>
        )}
      </div>

      <hr />

      <div data-cy="total" className={styles['internetSummaryDataInfo']}>
        <Txt
          variant="paragraphBold"
          data-cy="total-value"
          style={{ margin: '0 0 0 auto' }}
        >
          {totalWithCurrency}
          <Txt color="subtle" component="span">
            *
          </Txt>
        </Txt>
      </div>
    </Surface>
  );
};

interface InternetProductProps {
  planName: string;
  priceWithCurrency: string;
}

export const InternetProduct = ({
  planName,
  priceWithCurrency,
}: InternetProductProps) => {
  return (
    <Surface variant="secondary">
      <div data-cy="internet-summary-pricing" className={styles['pricing']}>
        <Txt variant="bodySmallRegular">{planName}</Txt>
        <Txt variant="bodySmallBold" color="subtle">
          {priceWithCurrency}
        </Txt>
      </div>
    </Surface>
  );
};
