import { useEffect } from 'react';

import { AnalyticsContext } from '@mfe/services/analytics';

import { Card, CardTitle } from '../Card';
import { IntroductionFooter } from './IntroductionFooter';
import { IntroductionContent } from './IntroductionContent';
import { PageName } from '../../analytics';

import styles from '../Card/Card.module.scss';
import { useDispatch } from 'react-redux';
import {
  structuredEvent,
  Categories,
  AnalyticsAction,
} from '@mfe/to-be-migrated/redux/analytics';

interface IntroductionFooterProps {
  handleContinue: () => void;
  handleBackToOverview: () => void;
  analyticsContext: AnalyticsContext[];
}

export const Introduction = (props: IntroductionFooterProps) => {
  const { analyticsContext } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      structuredEvent({
        category: Categories.ACP,
        action: AnalyticsAction.DISPLAYED,
        params: {
          label: PageName.ADDITIONAL_DETAILS,
          context: analyticsContext,
        },
      })
    );
  }, []);

  return (
    <Card>
      <div className={styles['content']} data-cy="acp-introduction">
        <CardTitle />
        <IntroductionContent />
      </div>
      <IntroductionFooter {...props} />
    </Card>
  );
};

export default Introduction;
