import { Raygun, UserInfo } from './types';
import version from '../../myversion.json';

declare function rg4js(event: string, options: any): void;

export const raygun: Raygun = {
  setUser: (userInfo: UserInfo) => {
    rg4js('setVersion', version.version);
    rg4js('setUser', {
      identifier: `${userInfo.accountNumber}`,
      isAnonymous: false,
      email: '',
      firstName: userInfo.username,
      fullName: userInfo.username,
    });
  },
};
