export enum FormFieldEnum {
  APPLICATION_ID = 'applicationId',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  DOB = 'dob',
  TRANSFER = 'isTransferring',
  TRIBAL_BENEFIT = 'hasTribalBenefit',
  TERMS = 'terms',
}

export type FormFieldName = `${FormFieldEnum}`;

export type FormValues = {
  applicationId: string;
  firstName: string;
  lastName: string;
  dob: {
    month: string;
    day: string;
    year: string;
  };
  isTransferring: boolean | null;
  hasTribalBenefit: boolean | null;
  terms: boolean;
};

export type FieldHandlerKey =
  | keyof Omit<FormValues, 'dob'>
  | keyof FormValues['dob'];

export type ValidationErrors = Record<keyof FormValues, string>;

export type InputEvent = React.FormEvent<HTMLInputElement>;

export type FormUtilsReturnValue = {
  values: FormValues;
  getFieldHandler: (
    value: FieldHandlerKey
  ) => React.FormEventHandler<HTMLInputElement | HTMLSelectElement>;
};

export type DateOfBirth = {
  day: string;
  month: string;
  year: string;
};
