// Extracted this hook into a separate file, because the file where it was before imported Platform from react-native, which caused errors when mounting the billing page as part of a Cypress component test

import {
  selectBillingInfo,
  fetchInvoicePdf,
} from '@mfe/to-be-migrated/redux/billingInfo';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useDownloadInvoice = (
  invoice: string | null
): {
  loading: boolean;
  base64Content: string | null;
} => {
  const dispatch = useDispatch();
  const {
    billingInfo: { invoicePdf },
  } = useSelector(selectBillingInfo);

  const [base64Content, setBase64Content] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!invoice) return;
    dispatch(fetchInvoicePdf(invoice));
    setLoading(true);
  }, [invoice, dispatch]);

  useEffect(() => {
    if (invoicePdf) {
      setLoading(false);
      setBase64Content(invoicePdf);
    }
  }, [invoicePdf, setBase64Content]);

  return { loading, base64Content };
};
