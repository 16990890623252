import React from 'react';
import {
  TouchableOpacity,
  StyleSheet,
  ViewStyle,
  StyleProp,
} from 'react-native';
import { Color, Text } from '@mfe/legacy/andromeda';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
  editIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    elevation: 2,
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 10 },
    shadowRadius: 30,
  },
  editText: {
    color: Color.teal500,
    textTransform: 'uppercase',
    position: 'relative',
    top: 1.5,
    marginRight: 6,
  },
});

const disabledStyle = {
  opacity: 0.3,
};

interface EditIconProps {
  onPress(): void;
  disabled?: boolean;
  visible?: boolean;
  outline?: boolean;
  size?: number;
  showText?: boolean;
  style?: ViewStyle;
  testID?: string;
}

export const EditIcon = ({
  onPress,
  disabled = false,
  visible = true,
  outline = true,
  size = 16,
  showText = false,
  style = {},
  testID,
}: EditIconProps): JSX.Element => {
  const { t } = useTranslation('Common');

  // let outlineStyle: ViewStyle = {};
  // if (outline) {
  //   outlineStyle = { backgroundColor: Color.white, borderRadius: size };
  //   if (showText) {
  //     outlineStyle = { ...outlineStyle, paddingVertical: 5, paddingLeft: 14, paddingRight: 8, alignItems: 'center' };
  //   } else {
  //     const width = size * 2;
  //     outlineStyle = { ...outlineStyle, width, height: width };
  //   }
  // }

  const width = size * 2;
  const outlineStyle: StyleProp<ViewStyle> = [styles.editIcon];
  if (style) outlineStyle.push(style);
  if (outline) {
    outlineStyle.push({ backgroundColor: Color.white, borderRadius: size });
    outlineStyle.push(
      showText
        ? {
            paddingVertical: 5,
            paddingLeft: 14,
            paddingRight: 8,
            alignItems: 'center',
          }
        : { width, height: width }
    );
  }
  if (disabled) outlineStyle.push({ opacity: 0.3 });

  // const disableStyle = disabled ? disabledStyle : {};

  return (
    <>
      {visible && (
        <TouchableOpacity
          onPress={onPress}
          style={[styles.editIcon, outlineStyle, style, outlineStyle]}
          testID={testID}
          disabled={disabled}
        >
          {showText && (
            <Text caption fixed semiBold style={styles.editText}>
              {t('Edit')}
            </Text>
          )}
          <Icon name="pencil" size={size} color={Color.teal500} />
        </TouchableOpacity>
      )}
    </>
  );
};
