import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { selectConfig } from '@mfe/shared/redux/config';
import {
  selectStreamOnPromo,
  setCurrentPage,
  Pages,
  Persona,
} from '@mfe/to-be-migrated/redux/streamOnPromo';
import {
  WindowMessageData,
  Domains,
  EventTypes,
} from '@mfe/services/window-messages';
import { Button } from '@vst/beam';
import Router from 'next/router';
import React from 'react';
import { TFunction } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './CloseButton.module.scss';
import { postMessage as postMessageUtil } from '@mfe/to-be-migrated/redux/utils';

export interface CloseButtonProps {
  t: TFunction;
  buttonTextKey: string;
  namespace: string;
}

export const CloseButton = ({
  t,
  buttonTextKey,
  namespace,
}: CloseButtonProps) => {
  const { persona } = useSelector(selectStreamOnPromo);
  const {
    user: {
      auth: {
        tokenInfo: { idToken, accessToken },
      },
    },
  } = useSelector(selectUser);
  const { platform } = useSelector(selectConfig);
  const dispatch = useDispatch();

  const handleClick = (
    e: React.ChangeEvent<HTMLInputElement>,
    link: string
  ) => {
    e.preventDefault();
    if (namespace === 'OrderConfirmation') {
      const message: WindowMessageData = {
        domain: Domains.General,
        eventType: EventTypes.VisitPlanUsage,
      };
      if (platform !== 'Web') {
        Router.back();
        dispatch(postMessageUtil(message));
        return;
      }
      dispatch(postMessageUtil(message));
    }
    dispatch(setCurrentPage(Pages.login));
    //idToken is only set when directly imported
    if (idToken) return;
    Router.back();
  };

  switch (persona) {
    case Persona.CARE_AGENT:
    case Persona.PARTNER:
      return (
        <Button data-cy="FlowEndButton" className={styles['button']}>
          {t('Close.Button')}
        </Button>
      );
    default:
      return (
        <Button
          data-cy="FlowEndButton"
          onClick={(e: any) =>
            handleClick(e, t('PurchaseFlow.LearnMoreLink', { ns: 'StreamOn' }))
          }
          variant="primary"
          className={styles['button']}
        >
          {t(buttonTextKey)}
        </Button>
      );
  }
};
