import { Txt } from '@vst/beam';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Payment,
  SettlementStatus,
  Invoice as InvoiceType,
} from '@mfe/shared/schema-types';
import { getDate } from '@mfe/shared/util';
import { selectBillingInfo } from '@mfe/to-be-migrated/redux/billingInfo';

import InvoiceMenu from './menu';
import Links from './links';

import sharedStyles from '../../../shared/styles.module.scss';
import { InvoiceStatusChip } from '../../invoice-status-chip';

import styles from './styles.module.scss';
import { Boleto } from '../boleto';

type Props = {
  invoice: InvoiceType;
  payment?: Payment | null;
  index: number;
};

const Invoice = ({ invoice, payment, index }: Props): JSX.Element => {
  const invoiceAmount = invoice?.invoiceAmount?.value || 0;
  const showBoleto =
    invoice?.invoiceAmount?.alphabeticCode === 'BRL' &&
    invoice?.invoiceSettlementStatus !== SettlementStatus.Paid &&
    invoiceAmount > 0;

  const {
    billingInfo: { popupsBlocked },
  } = useSelector(selectBillingInfo);

  const showDetails = invoice?.dueDate && invoice?.invoiceAmount?.text;

  return (
    <div className={styles['statementContainer']}>
      <div className={styles['statementRowContainer']}>
        <Header invoice={invoice} />
      </div>

      {showDetails && (
        <div className={styles['statementRowContainer']} id="statements">
          <Body invoice={invoice} />
        </div>
      )}

      {showBoleto && (
        <div className={styles['statementRowContainer']}>
          <Boleto invoice={invoice} payment={payment} showLabel />
        </div>
      )}

      <Links
        invoice={invoice}
        index={index}
        showPopupMessage={popupsBlocked ?? false}
      />
    </div>
  );
};

const Header = ({ invoice }: { invoice: InvoiceType }): JSX.Element => {
  const { t } = useTranslation('BillingStatement');
  const statementDate = invoice?.statementDate
    ? getDate(invoice.statementDate)
    : undefined;

  return (
    <div className={styles['headerContainer']}>
      <div className={styles['header']}>
        <Txt variant="paragraphBold" style={{ textTransform: 'capitalize' }}>
          {t('statementDate', { statementDate })}
        </Txt>

        {invoice?.badgeStatus && (
          <InvoiceStatusChip badgeStatus={invoice.badgeStatus} />
        )}
      </div>

      <InvoiceMenu invoice={invoice} />
    </div>
  );
};

const Body = ({ invoice }: { invoice: InvoiceType }): JSX.Element => {
  const { t } = useTranslation('BillingStatement');

  const dueDate = invoice?.dueDate ? getDate(invoice.dueDate) : undefined;

  return (
    <div className={styles['body']}>
      <div className={sharedStyles['flexColumn']}>
        <Txt component="p" variant="labelMedium" pb="8px">
          {t('dueDate')}
        </Txt>
        <Txt
          component="p"
          variant="bodySmallRegular"
          data-cy="dueDate"
          style={{ textTransform: 'capitalize' }}
        >
          {t('dueDate_format', { dueDate })}
        </Txt>
      </div>

      <div
        className={sharedStyles['flexColumn']}
        style={{ textAlign: 'right' }}
      >
        <Txt component="p" variant="labelMedium" pb="8px">
          {t('amountDue')}
        </Txt>
        <Txt component="p" variant="bodySmallRegular">
          {invoice.invoiceAmount?.text}
        </Txt>
      </div>
    </div>
  );
};

export default Invoice;
