import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Form, Surface, Txt } from '@vst/beam';

import { useScreenResolution } from '@mfe/shared/util';
import { selectBillingAccount } from '@mfe/to-be-migrated/redux/billingInfo';
import { triggerBillCycleDayUpdate } from '@mfe/to-be-migrated/redux/updateBillCycle';

import { DateSelect } from '../date-select';
import { InfoNotice } from '../info-notice';
import ProrateNotice from '../prorate-notice';
import { CurrentBillDate } from '../current-bill-date';

import styles from './styles.module.scss';

export const ChangeBillDateForm = ({
  handleCancel,
}: {
  handleCancel: () => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('UpdateBillCycle');

  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isSmall || isExtraSmall;

  const billingAccount = useSelector(selectBillingAccount);

  const [selectedOption, setSelectedOption] = useState('');

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(triggerBillCycleDayUpdate(Number(selectedOption)));
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ select: selectedOption }}
      validationMode="onSubmit"
      className={styles['form']}
    >
      <Surface className={styles['form-body']}>
        <Txt data-cy="title" variant="heading4">
          {t('title')}
        </Txt>
        <CurrentBillDate />
        <DateSelect
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
        />
        <InfoNotice />
        {selectedOption && billingAccount?.billingCycleDayOfMonth && (
          <ProrateNotice
            newBillCycleDay={Number(selectedOption)}
            oldBillCycleDay={billingAccount?.billingCycleDayOfMonth}
          />
        )}
      </Surface>
      <hr />
      <Surface className={styles['form-footer']} data-cy="form-footer">
        <Button
          data-cy="cancel"
          variant="secondary"
          onClick={handleCancel}
          fluid={isMobile}
          type="button"
        >
          {t('buttons.cancel')}
        </Button>
        <Button
          type="submit"
          data-cy="submit"
          variant="primary"
          fluid={isMobile}
          width={isMobile ? undefined : '223px'}
        >
          {t('buttons.submit')}
        </Button>
      </Surface>
    </Form>
  );
};
