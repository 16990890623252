import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ButtonLink, Icon, Surface, Txt } from '@vst/beam';
import { PowerSettingsNew } from '@vst/beam-icons/icons';

import { useScreenResolution } from '@mfe/shared/util';

import styles from '../styles.module.scss';
import { setIsRebootModemModalOpen } from '@mfe/to-be-migrated/redux/network';

export const RebootModemCard = ({
  loadingModemStatus,
}: {
  loadingModemStatus: boolean;
}) => {
  const { t } = useTranslation('Network', { keyPrefix: 'rebootModem' });

  const dispatch = useDispatch();

  const { isExtraLarge, isLarge, isMedium } = useScreenResolution();
  const isDesktop = isExtraLarge || isLarge || isMedium;

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(setIsRebootModemModalOpen(true));
  };

  return (
    <Surface
      radius={isDesktop ? '16px' : '0px'}
      data-cy="reboot-modem-card"
      className={styles['helpCardContainer']}
    >
      <Icon
        icon={PowerSettingsNew}
        size={40}
        color="gray_600"
        data-cy="reboot-modem-icon"
      />

      <div className={styles['helpCardTextLink']}>
        <Txt data-cy="reboot-modem-card-title" variant="bodyLargeRegular">
          {t('card.title')}
        </Txt>
        {loadingModemStatus ? (
          <LoadingContent />
        ) : (
          <ButtonLink
            variant="primary"
            href=""
            onClick={onClick}
            label={t('card.rebootModemLink')}
            linkSize="medium"
            data-cy="reboot-modem-link"
            style={{ alignItems: 'center' }}
          />
        )}
      </div>
    </Surface>
  );
};

const LoadingContent = () => {
  return <div className={styles['titleLoading']} />;
};
