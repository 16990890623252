import * as React from 'react';
import { Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import { CardLogo } from '@mfe/shared/components';
import { getDate } from '@mfe/shared/util';
import type { Payment, PendingPayment } from '@mfe/shared/schema-types';

import { PAYMENT_TYPE, getPaymentType } from './utils';

import sharedStyles from '../shared/styles.module.scss';

const GridItem = ({
  icon,
  dataCy,
  children,
}: React.PropsWithChildren<{
  icon?: React.ReactNode;
  dataCy?: string;
}>) => {
  return (
    <div data-cy={dataCy} className={sharedStyles['gridItem']}>
      {icon}
      {children && (
        <Txt variant="bodySmallRegular" style={{ textTransform: 'capitalize' }}>
          {children}
        </Txt>
      )}
    </div>
  );
};

export const PaymentItem = ({
  payment,
}: {
  payment: Payment | PendingPayment;
}): JSX.Element | null => {
  const { t } = useTranslation('BillingPayment');

  const paymentDate = payment?.paymentDate
    ? getDate(payment.paymentDate)
    : undefined;

  const last4Digits = payment.last4Digits && `...${payment.last4Digits}`;

  const paymentType = getPaymentType(payment);
  const hideStatus = paymentType === PAYMENT_TYPE.refund;

  if (hideStatus) {
    return null;
  }

  return (
    <>
      <GridItem dataCy="payment-item-date">
        {paymentDate && t('paymentDate', { paymentDate })}
      </GridItem>
      <GridItem dataCy="payment-item-amount">
        {payment.paymentAmount?.text ?? ''}
      </GridItem>
      <GridItem>{t('paymentType', { context: paymentType })}</GridItem>
      <GridItem
        dataCy="payment-item-payment-method"
        icon={<CardLogo name={payment.paymentMethod} width="35px" />}
      >
        {last4Digits ?? payment?.paymentMethod ?? ''}
      </GridItem>
      <GridItem>
        {payment.txnStatus &&
          t('paymentStatus', { context: payment.txnStatus })}
      </GridItem>
    </>
  );
};

const PaymentList = ({
  payments,
}: {
  payments: (Payment | PendingPayment)[];
}) => {
  return payments.length > 0 ? (
    <>
      {payments.map((payment, index) => (
        <PaymentItem key={index} payment={payment} />
      ))}
    </>
  ) : null;
};

export default PaymentList;
