import { StringMap, TOptions } from 'i18next';

import { ErrorTypes } from '../components/Outcome/ErrorOutcome';

export const MOCK_APPLICATION_ID = {
  SUCCESSFUL_REGISTRATION: 'Y01234-00200',
  /* Server errors */
  SERVICE_UNAVAILABLE: 'Y12345-00001',
  NV_UNAVAILABLE: 'Y12345-00002',
  VIASAT_SERVICE_UNAVAILABLE: 'Y12345-00003',
  /* Other errors */
  APPLICATION_NOT_FOUND: 'Y23456-00001',
  APPLICATION_EXPIRED: 'Y23456-00002',
  DUPLICATE_SUBSCRIBER_NLAD: 'Y23456-00003',
  NOT_ELIGIBLE_ACP: 'Y23456-00004',
  APPLICATION_NOT_COMPLETE: 'Y23456-00005',
  APPLICATION_PENDING: 'Y23456-00006',
  GENERAL_ERROR: 'Y23456-00007',
} as const;

type MockApplicationIdName = keyof typeof MOCK_APPLICATION_ID;
export type MockApplicationIdValue =
  (typeof MOCK_APPLICATION_ID)[MockApplicationIdName];

const mockResponses = {
  [MOCK_APPLICATION_ID.SUCCESSFUL_REGISTRATION]: {
    message: 'Subscriber passed all validations and verifications',
    name: '',
  },
  [MOCK_APPLICATION_ID.SERVICE_UNAVAILABLE]: {
    error_type: 'Validation Error',
    name: ErrorTypes.SERVICE_UNAVAILABLE,
    message: '',
  },
  [MOCK_APPLICATION_ID.NV_UNAVAILABLE]: {
    error_type: 'Validation Error',
    name: ErrorTypes.NV_UNAVAILABLE,
    message: '',
  },
  [MOCK_APPLICATION_ID.VIASAT_SERVICE_UNAVAILABLE]: {
    error_type: 'Validation Error',
    name: ErrorTypes.VIASAT_SERVICE_UNAVAILABLE,
    message: '',
  },
  [MOCK_APPLICATION_ID.APPLICATION_NOT_FOUND]: {
    error_type: 'Validation Error',
    name: ErrorTypes.APPLICATION_NOT_FOUND,
    message: '',
  },
  [MOCK_APPLICATION_ID.APPLICATION_EXPIRED]: {
    error_type: 'Validation Error',
    name: ErrorTypes.APPLICATION_EXPIRED,
    message: '',
  },
  [MOCK_APPLICATION_ID.DUPLICATE_SUBSCRIBER_NLAD]: {
    error_type: 'Validation Error',
    name: ErrorTypes.DUPLICATE_SUBSCRIBER_NLAD,
    message: '',
  },
  [MOCK_APPLICATION_ID.NOT_ELIGIBLE_ACP]: {
    error_type: 'Validation Error',
    name: ErrorTypes.NOT_ELIGIBLE_ACP,
    message: '',
  },
  [MOCK_APPLICATION_ID.APPLICATION_NOT_COMPLETE]: {
    error_type: 'Validation Error',
    name: ErrorTypes.APPLICATION_NOT_COMPLETE,
    message: '',
  },
  [MOCK_APPLICATION_ID.APPLICATION_PENDING]: {
    error_type: 'Validation Error',
    name: ErrorTypes.APPLICATION_PENDING,
    message: '',
  },
  [MOCK_APPLICATION_ID.GENERAL_ERROR]: {
    error_type: 'Validation Error',
    name: 'INVALID_PHONE_NUMBER', // Or any other error
    message: '',
  },
};

export const shouldUseMockResponse = (applicationId: MockApplicationIdValue) =>
  Object.keys(mockResponses).includes(applicationId);

export const getMockResponse = (applicationId: MockApplicationIdValue) =>
  mockResponses[applicationId];

export const handleMockRequest = (
  applicationId: string,
  setSuccess: (value: boolean) => void,
  setErrorType: (value: ErrorTypes) => void,
  t: (key: string, options?: TOptions<StringMap> | undefined) => string
) => {
  const mockResponse = getMockResponse(applicationId as MockApplicationIdValue);

  if (mockResponse.message === t('Success.responseMessage')) {
    return setSuccess(true);
  }
  return setErrorType(mockResponse.name as ErrorTypes);
};
