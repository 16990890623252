import { Txt } from '@vst/beam';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { LeaseFeeOptions } from './LeaseFeeOptions';
import { OrderDetailsSection } from './OrderDetailsSection';
import { selectChangePlan } from '@mfe/to-be-migrated/redux/changePlan';

export const EqipmentLeaseFee = ({
  priceCurrency,
}: {
  priceCurrency: string;
}) => {
  const { t } = useTranslation('NewChangePlan', {
    keyPrefix: 'orderReview.sateliteMigrationDetails.equipmentAndLeaseFee',
  });

  const {
    cart: {
      cartItems: { plan },
    },
  } = useSelector(selectChangePlan);

  const hasLeaseFee = plan?.leaseFeePrice && plan?.leaseFeeShortName;

  return (
    <OrderDetailsSection id="equipment-lease-fee" title={t('title')}>
      <Txt variant="bodySmallRegular">
        {t('installationDetails')}
        <Txt component="span" color="success" variant="bodySmallBold">
          {t('installationDetailsFee')}{' '}
        </Txt>
        {t('furtherInstallationDetails')}
      </Txt>
      {hasLeaseFee && (
        <LeaseFeeOptions
          leaseFeePrice={plan?.leaseFeePrice}
          leaseFeeShortName={plan?.leaseFeeShortName}
          priceCurrency={priceCurrency}
        />
      )}
    </OrderDetailsSection>
  );
};
