import { Animated } from 'react-native';
import { TitleAnimation } from './types';
import { getTranslateX } from './utils.shared';
import { ScreenSize } from '../../utils';

export const buildTitleAnimation = (
  animation: Animated.Value,
  isValidValue: boolean,
  width: number
): TitleAnimation => {
  const scaleOutputRange = isValidValue ? [0.812, 1] : [1, 0.812];
  const scale = animation.interpolate({
    inputRange: [0, 1],
    outputRange: scaleOutputRange,
  });

  const xOutput = getTranslateX(width);
  const xOutputRange = isValidValue ? [xOutput, 0] : [0, xOutput];
  const translateX = animation.interpolate({
    inputRange: [0, 1],
    outputRange: xOutputRange,
  });

  const yOutputRange = isValidValue ? [0, 10] : [10, 0];
  const translateY = animation.interpolate({
    inputRange: [0, 1],
    outputRange: yOutputRange,
  });

  return { scale, translateX, translateY };
};

export const getMaxCharacterCount = (screenSize: ScreenSize): number => {
  switch (screenSize) {
    case ScreenSize.XS:
    case ScreenSize.SM:
      return 25;
    case ScreenSize.MD:
      return 29;
    default:
      return 35;
  }
};
