import { TFunction } from 'i18next';
import { Badge, IBadgeProps } from '@vst/beam';
import { useTranslation } from 'react-i18next';

type Props = {
  paymentStatus?: string | null;
};

const PaymentStatusChip = ({ paymentStatus }: Props): JSX.Element | null => {
  const { t } = useTranslation('BillingPayment');
  const badgeProps = getBadgeProps(paymentStatus, t);

  if (!paymentStatus) {
    return null;
  }

  return (
    <Badge
      label=""
      size="small"
      theme="inherit"
      showIcon={false}
      ml="8px"
      {...badgeProps}
    />
  );
};

type BadgeProps = Pick<IBadgeProps, 'label' | 'state' | 'emphasis'>;

function getBadgeProps(
  paymentStatus: string | null | undefined,
  t: TFunction
): BadgeProps | null {
  switch (paymentStatus) {
    case 'Success':
      return {
        label: t('paymentStatus_Success'),
        state: 'positive',
        emphasis: 'heavy',
      };

    case 'Failure':
      return {
        label: t('paymentStatus_Failure'),
        state: 'warning',
        emphasis: 'heavy',
      };

    default:
      return null;
  }
}

export default PaymentStatusChip;
