import { useEffect, useMemo } from 'react';
import { Txt, Button, InlineLink } from '@vst/beam';
import { useTranslation } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectStreamOnPromo,
  setCurrentPage,
  submitFullOrder,
  Pages,
} from '@mfe/to-be-migrated/redux/streamOnPromo';
import { LoadingAnimatedButton } from '../LoadingAnimatedButton';
import styles from './Cart.module.scss';
import { AnalyticsEventSource } from '../../lib/analytics/pixel-analytics-type';
import { AddressInput } from '@mfe/shared/schema-types';
import {
  selfDescribingEvent,
  AnalyticsEventNames,
} from '@mfe/to-be-migrated/redux/analytics';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import CartPng from './Cart.png';
import router from 'next/router';

function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

export const priceDecimalFormat = (
  price: number | string,
  country: string
): string => {
  let currency;
  let locale = '';
  const aton = (val?: string | null): number =>
    isNaN(Number(val)) ? 0 : Number(val);

  if (typeof price === 'string') {
    price = aton(price);
  }

  switch (country) {
    case 'Brazil':
      currency = 'BRL';
      locale = 'en-BR';
      break;
    default:
      currency = 'USD';
      locale = 'en-US';
  }
  const roundedPrice = Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
  });
  const wholePrice = Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  });

  if (price % 1 !== 0) {
    return wholePrice.format(price).replace(/\s/g, '');
  } else {
    return roundedPrice.format(price).replace(/\s/g, '');
  }
};

interface CartProps {
  setShowTermsModal: (value: boolean) => void;
}

export const Cart = ({ setShowTermsModal }: CartProps) => {
  const { t: streamonTranslate } = useTranslation('StreamOn');
  const { t: placeOrderTranslate } = useTranslation('PlaceOrder');
  const { t: purchaseFlow } = useTranslation('PurchaseFlow');

  const dispatch = useDispatch();
  const {
    user: {
      auth: {
        tokenInfo: { accessToken, idToken },
      },
    },
  } = useSelector(selectUser);
  const {
    shippingAddress,
    isEditingNumber,
    userDetails,
    isEdittingAddress,
    offerDetails,
    isOrderSubmitting,
    failureReason,
  } = useSelector(selectStreamOnPromo);

  const disableCompleteOrder = !(
    shippingAddress.addressLines[0] &&
    shippingAddress.municipality &&
    shippingAddress.region &&
    shippingAddress.postalCode &&
    !isEdittingAddress &&
    !isEditingNumber &&
    offerDetails.shouldDisplaySms !== null
  );
  const addOnsContext = useMemo(
    () => ({
      schema: 'addons_context',
      data: {
        product_name: offerDetails.name,
        product_type_id: offerDetails.productTypeId,
        product_family: offerDetails.productFamily,
        source: AnalyticsEventSource.OVERVIEW_PAGE,
        product_price: offerDetails.price,
        discount_amount: offerDetails.discountValue ?? 0,
        discount_duration: offerDetails.discountDuration ?? 0,
      },
    }),
    [offerDetails]
  );

  useEffect(() => {
    if (failureReason)
      dispatch(
        selfDescribingEvent({
          eventName: AnalyticsEventNames.AddonsOrderFailed,
          data: { failure_reason: failureReason },
          context: [addOnsContext],
        })
      );
  }, [dispatch, failureReason, addOnsContext]);

  const onNext = () => {
    dispatch(
      submitFullOrder({
        address: shippingAddress as AddressInput,
        isEditingNumber: isEditingNumber,
        phoneNumber: userDetails?.phoneNumber,
      })
    );
    window.scrollTo(0, 0);
    dispatch(
      selfDescribingEvent({
        vendor: 'com.viasat.care',
        eventName: AnalyticsEventNames.AddonsOrderSubmitted,
        context: [addOnsContext],
      })
    );
  };

  const onBack = () => {
    dispatch(setCurrentPage(Pages.login));
    dispatch(
      selfDescribingEvent({
        vendor: 'com.viasat.care',
        eventName: AnalyticsEventNames.AddonsPurchaseFlowCancelled,
        context: [addOnsContext],
      })
    );
    if (idToken) return;
    router.push(`/Overview?token=${accessToken}`);
  };

  const onTermsClick = (event: React.MouseEvent<HTMLElement>) => {
    if (event) event.preventDefault();
    setShowTermsModal(true);
  };

  // TODO: Allow for multiple countries other than US
  const country = 'US';
  const { fullPrice, discountValue, loading } = offerDetails;
  // TODO: replace this if value is returned as number and not string
  const fullPriceNumber = fullPrice
    ? Number(fullPrice.slice(0, fullPrice.length).replace(/\/mo/g, ''))
    : 0;
  const fullPriceValue = Number(fullPriceNumber) ? fullPriceNumber : 0;
  // TODO: remove this ccheck once active customer is set up
  const discount = isDefined(fullPriceValue)
    ? fullPriceValue + discountValue
    : discountValue;
  const monthlyFee = isDefined(fullPriceValue)
    ? priceDecimalFormat(fullPriceValue, country)
    : 0.0;
  const displayDiscount = priceDecimalFormat(discount, country);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cartSrc: string = (CartPng as any)?.src ?? (CartPng as any);

  return (
    <div data-cy="stream-cart-container" className={styles['containerCard']}>
      <div className={styles['card']}>
        <div>
          <Txt variant="heading5" data-cy="cartTitle">
            {streamonTranslate('Cart.Title')}
          </Txt>
        </div>
        <div className={styles['imageAndText']}>
          <div className={styles['imageBackground']}>
            <img src={cartSrc} alt="cart" />
          </div>
          <Txt variant="bodyLargeRegular" data-cy="cartImageText">
            {streamonTranslate('Cart.Image.Text')}
          </Txt>
        </div>
        <div className={styles['cartPricingContainer']}>
          <div className={styles['cartPricingStyle']} data-cy="monthlyFee">
            <Txt variant="bodyLargeRegular">
              {streamonTranslate('Cart.ServiceFee')}
            </Txt>
            {loading ? (
              <div className={styles['loading']} />
            ) : discountValue === 0 ? (
              <div>
                <Txt variant="bodySmallBold" color="success">
                  {streamonTranslate('Cart.Free')}
                </Txt>
              </div>
            ) : (
              <>
                <div className={styles['crossout']}>
                  <Txt variant="bodyLargeRegular" color="inherit">
                    {monthlyFee}
                  </Txt>
                </div>
                <Txt variant="bodyLargeRegular" className={styles['discount']}>
                  {displayDiscount}
                </Txt>
              </>
            )}
          </div>
        </div>
        <div className={styles['termsAndConditions']}>
          <Txt variant="smallRegular" data-cy="termsAndConditions">
            {streamonTranslate('Cart.TermsAndConditions.Text')}
            {
              <InlineLink
                onClick={onTermsClick}
                href={''}
                data-cy="termsAndConditionsLink"
              >
                {streamonTranslate('Cart.TermsAndConditions.TermsText')}.
              </InlineLink>
            }
          </Txt>
        </div>
        <div className={styles['disclosure']}>
          <Txt variant="smallRegular" data-cy="disclosureText">
            {streamonTranslate('Cart.Disclosure')}
          </Txt>
        </div>
        <div className={styles['buttonContainer']}>
          {isOrderSubmitting ? (
            <LoadingAnimatedButton />
          ) : (
            <Button
              fluid
              onClick={onNext}
              disabled={disableCompleteOrder}
              data-cy="completeOrderButton"
            >
              <Txt
                variant="labelLarge"
                color="inherit"
                data-cy="completeOrderButtonText"
              >
                {placeOrderTranslate('Button')}
              </Txt>
            </Button>
          )}

          <Button
            fluid
            disabled={isOrderSubmitting}
            variant="secondary"
            onClick={onBack}
            data-cy="backButton"
          >
            {purchaseFlow('Cancel.Button')}
          </Button>
        </div>
      </div>
    </div>
  );
};
