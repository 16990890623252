import { ViewStyle, Platform } from 'react-native';
import { ScreenSize } from '../../utils';

export const DEFAULT_SHADOW: ViewStyle = {
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.25,
  shadowRadius: 30,
  elevation: 25,
};

type CardMaxWidth = { maxWidth: ViewStyle['maxWidth'] };

export const getMaxWidth = (screenSize: ScreenSize): CardMaxWidth => {
  switch (screenSize) {
    case ScreenSize.XS || (Platform.OS === 'web' && ScreenSize.SM):
      return { maxWidth: '100%' };
    case ScreenSize.SM:
      return { maxWidth: 375 };
    case ScreenSize.MD:
    case ScreenSize.LG:
    case ScreenSize.XL:
    default:
      return { maxWidth: 542 };
  }
};
