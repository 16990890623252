import * as React from 'react';
import {
  ConventionalSelect,
  ConventionalNativeSelect,
  Icon,
  validators,
  Tooltip,
} from '@vst/beam';
import { InfoOutline } from '@vst/beam-icons/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Platform } from '@mfe/shared/schema-types';
import { useScreenResolution } from '@mfe/shared/util';
import { selectConfig } from '@mfe/shared/redux/config';
import { selectBillingAccount } from '@mfe/to-be-migrated/redux/billingInfo';
import { selectUpdateBillCycle } from '@mfe/to-be-migrated/redux/updateBillCycle';

import styles from './styles.module.scss';

interface DateSelectProps {
  setSelectedOption: (newValue: string) => void;
  selectedOption: string;
}

export const DateSelect = (props: DateSelectProps) => {
  const { t } = useTranslation(['UpdateBillCycle', 'Global']);

  const { setSelectedOption, selectedOption } = props;

  const configState = useSelector(selectConfig);

  const { days } = useSelector(selectUpdateBillCycle);
  const billingAccount = useSelector(selectBillingAccount);
  const billingCycleDayOfMonth = billingAccount?.billingCycleDayOfMonth;

  const { isExtraSmall, isSmall } = useScreenResolution();
  const shouldRenderNativeSelect =
    isExtraSmall ||
    isSmall ||
    configState.isPWA ||
    configState.platform !== Platform.Web;

  const options = days.map((day) => {
    return {
      label: t('Global:dayOfTheMonth_ordinal', {
        count: day as number,
        ordinal: true,
      }),
      value: `${day}`,
      disabled: day === billingCycleDayOfMonth,
    };
  });

  const tooltip = (
    <Tooltip
      position="top"
      bodyText={t('UpdateBillCycle:selectBillDate.tooltipText')}
      data-cy="tooltip"
    >
      <Icon icon={InfoOutline} data-cy="tooltip-icon" />
    </Tooltip>
  );

  const selectElement = React.useMemo(() => {
    if (shouldRenderNativeSelect) {
      return {
        Component: ConventionalNativeSelect,
        'data-cy': 'native-new-bill-date',
      };
    }

    return { Component: ConventionalSelect, 'data-cy': 'new-bill-date' };
  }, [shouldRenderNativeSelect]);

  return (
    <selectElement.Component
      className={styles['select']}
      data-cy={selectElement['data-cy']}
      fluid
      id="select-new-bill-cycle-date"
      name="select-new-bill-cycle-date"
      labelProps={{
        labelText: t('UpdateBillCycle:selectBillDate.label'),
        showStar: false,
        tooltip,
      }}
      options={options}
      onChange={(e: any) => setSelectedOption(e.target.value)}
      value={selectedOption}
      placeholder={t('UpdateBillCycle:selectBillDate.placeholder')}
      validationRules={[
        validators.required({
          message: t('UpdateBillCycle:selectBillDate.requiredError'),
        }),
      ]}
    />
  );
};
