import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ArticleCard } from '@mfe/shared/components';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';

import { ArticleImage } from './article-image';

const NetworkArticleCard = () => {
  const { t } = useTranslation('Network', { keyPrefix: 'articleCard' });

  const dispatch = useDispatch();

  const openLinkInNewTab = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(openInNewTab(t('linkUrl')));
  };

  return (
    <ArticleCard
      title={t('title')}
      caption={t('caption')}
      image={<ArticleImage />}
      link={{ text: t('linkText'), url: t('linkUrl') }}
      onClick={(e) => openLinkInNewTab(e)}
    />
  );
};

export default NetworkArticleCard;
