import React, { useState } from 'react';
import { Platform } from 'react-native';
import DeprecatedTab from './Deprecated/Tab';
import {
  View,
  StyleSheet,
  TouchableOpacityProps,
  TouchableWithoutFeedback,
} from 'react-native';
import { Text } from './Text';
import { Color } from '../utils/Color';

const styles = StyleSheet.create({
  alignCenter: { textAlign: 'center', paddingHorizontal: 15 },
  selected: { color: Color.gray800 },
  unselected: { color: Color.gray500 },
  hover: { color: Color.gray600 },
  borderSelected: {
    borderBottomColor: Color.teal500,
    borderBottomWidth: 3,
    paddingBottom: 8,
  },
  borderUnselected: { paddingBottom: 10 },
  grayUnderline: { borderBottomColor: Color.gray200 },
  alignLeft: { textAlign: 'left', paddingRight: 15 },
});

interface DeprecatedTabProps extends TouchableOpacityProps {
  title: string;
  onPress(): void;
  selected: boolean;
  deprecated?: boolean;
  first?: boolean;
  testID?: string;
}

export const Tab = (_props: DeprecatedTabProps): JSX.Element => {
  const {
    title,
    onPress,
    selected,
    deprecated = false,
    first,
    testID,
  } = _props;
  const [hover, setHover] = useState<boolean>(false);
  const alignStyle = first ? styles.alignLeft : styles.alignCenter;
  let textStyle = styles.unselected;
  if (hover) textStyle = styles.hover;
  if (selected) textStyle = styles.selected;
  if (deprecated) {
    return <DeprecatedTab {..._props} />;
  } else {
    return (
      <View style={selected ? styles.borderSelected : styles.borderUnselected}>
        <TouchableWithoutFeedback
          onPress={onPress}
          {...(Platform.OS === 'web' &&
            !selected && {
              onMouseEnter: (): void => setHover(true),
              onMouseLeave: (): void => setHover(false),
            })}
          testID={testID}
        >
          <Text
            testID={`${testID ? testID : title}Text`}
            caption
            semiBold={selected}
            style={[alignStyle, textStyle]}
          >
            {title}
          </Text>
        </TouchableWithoutFeedback>
      </View>
    );
  }
};
