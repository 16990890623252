import { ModalTypeEnum, ModalComponent } from '@mfe/shared/components';
import Outcome from './Outcome';
import { useScreenResolution } from '@mfe/shared/util';
import { useTranslation } from 'react-i18next';

interface NotEligibleErrorProps {
  goBackToOverviewPage: () => void;
  openNationalVerifierInNewTab: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
}

export const NotEligibleError = (props: NotEligibleErrorProps) => {
  const { goBackToOverviewPage, openNationalVerifierInNewTab } = props;
  const { t } = useTranslation('ACP', { keyPrefix: 'Error' });
  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;

  const renderOutcome = () => {
    return (
      <Outcome
        data-cy="acp-not-eligible-error"
        title={t('notEligible.title')}
        text={t('notEligible.description')}
        thirdText={t('notEligible.thirdDescription')}
        primaryButton={{
          label: t('notEligible.buttons.backToMyViasat'),
          handleClick: goBackToOverviewPage,
        }}
        secondaryButton={{
          label: t('notEligible.buttons.goToNationalVerifier'),
          handleClick: (e) => openNationalVerifierInNewTab(e),
          isLink: true,
        }}
      />
    );
  };

  return isMobile ? (
    renderOutcome()
  ) : (
    <ModalComponent type={ModalTypeEnum.ACP}>{renderOutcome()}</ModalComponent>
  );
};
