import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AccordionGroup, Accordion, Button, Surface, Txt } from '@vst/beam';

import useNavigate from '@mfe/services/navigation';
import { AccountType } from '@mfe/shared/schema-types';
import { HelpLink } from '@mfe/legacy/mv/utils/Navigation';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';

import styles from './styles.module.scss';

type Question = {
  header: string;
  description: string;
};

export const CommonQuestions = () => {
  const { t } = useTranslation('Network', { keyPrefix: 'commonQuestions' });
  const questions: Question[] = t('list', { returnObjects: true });

  const dispatch = useDispatch();

  const { goTo } = useNavigate();

  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const openLinkInNewTab = (e: React.MouseEvent<HTMLElement>, url: string) => {
    e.preventDefault();
    dispatch(openInNewTab(url));
  };

  const navigateToFaq = (e: React.MouseEvent<HTMLElement>) =>
    accountType === AccountType.Smb
      ? openLinkInNewTab(e, t('SMBSupportLink'))
      : goTo(HelpLink.Main, { section: 'Connectivity' });

  return (
    <Surface className={styles['questions']}>
      <Txt variant="heading5" mb="16px">
        {t('title')}
      </Txt>

      <AccordionGroup singleExpanded={true} size="small">
        {questions.map(({ header, description }) => (
          <Accordion title={header} className={styles['accordion']}>
            {description}
          </Accordion>
        ))}
      </AccordionGroup>

      <Button
        fluid
        variant="secondary"
        className={styles['button']}
        onClick={(e: React.MouseEvent<HTMLElement>) => navigateToFaq(e)}
      >
        {t('button')}
      </Button>
    </Surface>
  );
};
