import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Router } from '@vst/beam-icons/icons';
import { SectionAlert, Txt } from '@vst/beam';

import { selectingPendingInstallation } from '@mfe/to-be-migrated/redux/pendingInstallation';

import styles from './styles.module.scss';

export const EquipmentShippingAlert = () => {
  const { t } = useTranslation('Profile');

  const { newOfferName } = useSelector(selectingPendingInstallation);

  return (
    <SectionAlert variant="infoPrimary" icon={Router}>
      <div className={styles['content']}>
        <Txt
          data-cy="text"
          component="span"
          variant="smallRegular"
          color="regular"
        >
          {t('equipmentShipmentAlert', {
            planName: newOfferName,
          })}
        </Txt>
      </div>
    </SectionAlert>
  );
};
