import { useSelector } from 'react-redux';
import { LocaleState } from '@mfe/to-be-migrated/redux/locale';
import { useHistory } from 'react-router';

const useNavigate = (): {
  goTo: (link: string, params?: Record<string, string>) => unknown;
  goBack: () => unknown;
  replace: (link: string, skipLogic?: { skip: string }) => unknown;
} => {
  const history = useHistory();

  const {
    locale: { urlPrefix },
  } = useSelector((state) => (state as { locale: LocaleState }).locale);

  const goBack = () => history.goBack();

  const goTo = (link: string, params?: Record<string, string>): void => {
    const priorRoute = history.location.pathname;
    const currentRoute = `${urlPrefix}${link}`;
    history.push({
      pathname: currentRoute,
      state: { priorRoute, currentRoute, component: link, params },
    });
  };

  const replace = (link: string, skipLogic?: { skip: string }) =>
    history.replace(`${urlPrefix}${link}`, skipLogic);

  return { goTo, goBack, replace };
};

export default useNavigate;
