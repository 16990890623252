import React, { useEffect, Component } from 'react';
import { Svg, Circle } from 'react-native-svg';
import { Animated, ViewProps, Easing, Platform } from 'react-native';
import { Color } from '../../utils/Color/consts';

// Based off of http://ux.viasat.io/pattern-library/indeterminate-loader/

// React native svg has terrible animation support which is why
// we are wrapping the circle component to add our own functionality
class SvgCircleWrap extends Component<any> {
  component: any = null;

  // Only use one animation value to drive both dasharray and dashoffset
  // in order to ensure that those animations remain in sync
  override state = {
    animation: new Animated.Value(0),
  };

  constructor(props: any) {
    super(props);
    this.state.animation.addListener((animation) => {
      this.setNativeProps({ animation });
    });
  }

  override componentDidMount() {
    Animated.loop(
      Animated.sequence([
        Animated.timing(this.state.animation, {
          toValue: 1,
          duration: 1500,
          useNativeDriver: true,
        }),
        Animated.timing(this.state.animation, {
          toValue: 0,
          duration: 1,
          useNativeDriver: true,
        }),
      ])
    ).start();
  }

  override componentWillUnmount() {
    this.state.animation.removeAllListeners();
  }

  setNativeProps = (props: any) => {
    if (this.component === null) {
      return;
    }

    // Using manual interpolation instead of animated interpolation because animated interpolation
    // does not provide a way to retrieve the actual interpolated value
    const animationValue = props.animation.value;
    const dashArrayValue = [
      animationValue < 0.5 ? 88 * 2 * animationValue + 1 : 89,
      200,
    ];
    const dashOffsetValue =
      animationValue < 0.5
        ? -35 * 2 * animationValue
        : -89 * 2 * (animationValue - 0.5) - 35;

    // We are manually setting the props and attributes for performance instead of using
    // react state
    if (Platform.OS === 'web') {
      this.component.setAttribute('stroke-dasharray', dashArrayValue);
      this.component.setAttribute('stroke-dashoffset', dashOffsetValue);
    } else {
      this.component.setNativeProps({
        strokeDasharray: dashArrayValue,
        strokeDashoffset: dashOffsetValue,
      });
    }
  };

  override render() {
    // We use platform switching here so that we are able to set our ref
    // The svg's library will not work due to Circle being a functional component
    // in that library and therefore not we are not able to set our reference which
    // we need in order to call setNativeProps or setAttribute
    if (Platform.OS === 'web') {
      return (
        <circle
          ref={(comp) => {
            this.component = comp;
          }}
          {...this.props}
        />
      );
    }

    // Use require where only native will run in order to avoid
    // some warnings when we import it in react web
    return (
      <Circle
        ref={(comp) => {
          this.component = comp;
        }}
        {...this.props}
      />
    );
  }
}

export interface LoadingProps extends ViewProps {
  size?: number;
  color?: string;
  strokeWidth?: number;
}

export const Loading = ({
  size = 50,
  color = Color.teal500,
  strokeWidth = 2,
  style = {},
  ...otherProps
}: LoadingProps) => {
  const rotateAnimation = new Animated.Value(0);
  const width: number = size;
  const height: number = size;

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(rotateAnimation, {
          toValue: 1,
          duration: 2000,
          easing: Easing.linear,
          useNativeDriver: true,
        }),
        Animated.timing(rotateAnimation, {
          toValue: 0,
          duration: 1,
          useNativeDriver: true,
        }),
      ])
    ).start();
  });

  const rotation = rotateAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });

  return (
    <Animated.View
      style={[
        {
          width,
          height,
          transform: [{ rotate: rotation }],
        },
        style,
      ]}
      {...otherProps}
    >
      <Svg width={width} height={height} viewBox="25 25 50 50">
        <SvgCircleWrap
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth={strokeWidth}
          strokeMiterlimit={10}
          strokeLinecap="round"
          stroke={color}
        />
      </Svg>
    </Animated.View>
  );
};
