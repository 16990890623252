import { TOptions, TFunction } from 'i18next';
import {
  useTranslation as i18UseTranslation,
  UseTranslationOptions,
} from 'react-i18next';
import i18n from '@mfe/services/translations-service';

const STREAM_ON_NS = 'StreamOnPromo';

const nsAdapter = (ns: string | string[], key: string): string | string[] => {
  return typeof ns === 'string' ? `${ns}.${key}` : ns.map((n) => `${n}.${key}`);
};

export const getI18n = () => {
  const customGetFixedT = (
    lng: string | string[],
    ns: string | string[],
    keyPrefix?: string
  ) => {
    const t = i18n.getFixedT(lng, STREAM_ON_NS, keyPrefix);
    const customT = (key: string, options?: TOptions<any>) =>
      t(nsAdapter(ns, key), options);

    return customT;
  };

  return {
    ...i18n,
    getFixedT: customGetFixedT,
  };
};

export const useTranslation = (
  ns: string | string[],
  options?: UseTranslationOptions
) => {
  const translation = i18UseTranslation(STREAM_ON_NS, options);

  const customT = (key: string, options?: TOptions) =>
    translation.t(nsAdapter(ns, key), options as any);

  return {
    ...translation,
    t: customT as TFunction,
  };
};
