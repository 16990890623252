import { Transition, TransitionStatus } from 'react-transition-group';
import styles from './styles.module.scss';

const duration = 900;

const defaultStyle: React.CSSProperties = {
  transition: `opacity ${duration}ms ease-in-out, max-height ${duration}ms ease-in-out`,
  opacity: 0,
  maxHeight: 0,
};

const transitionStyles: Record<TransitionStatus, React.CSSProperties> = {
  entering: { opacity: 1, maxHeight: '1000px' },
  entered: { opacity: 1, maxHeight: '1000px' },
  exiting: { opacity: 0, maxHeight: 0 },
  exited: { opacity: 0, maxHeight: 0 },
  unmounted: { opacity: 0, maxHeight: 0 },
};

type TransitionWrapperProps = {
  isOpen: boolean;
  children: React.ReactNode;
};

export const TransitionWrapper = ({
  isOpen,
  children,
}: TransitionWrapperProps) => {
  return (
    <Transition in={isOpen} timeout={300} unmountOnExit={false}>
      {(state) => (
        <div
          className={styles.transitionWrapper}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};
