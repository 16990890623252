export type Window = typeof globalThis & {
  pixel?: any;
  pixel_config?: TrackerConfig;
  GlobalSnowplowNamespace?: string[];
};

export type Context = {
  schema: string;
  data: any;
};

export type StructEventParams = {
  label?: string;
  property?: string;
  value?: number;
  context?: Context[];
};

export type SelfDescribingEvent = {
  schema: string;
  data?: any;
  context?: Context[];
};

export type TrackerConfig = {
  appId: string;
  env?: string;
  collectorUrl?: string;
  forceUnsecureTracker?: boolean;
};

export interface PixelAPI {
  init: (config?: TrackerConfig) => Promise<boolean>;
  setUserId: (userId: string) => void;
  trackStructEvent: (
    category: string,
    action: string,
    eventParams: StructEventParams
  ) => void;
  trackSelfDescribingEvent: (
    event: SelfDescribingEvent,
    context?: Context
  ) => void;
}

export enum AnalyticsEventNames {
  AddonsPromoCardDisplayed = 'addons_promo_card_displayed',
  AddonsPromoCardLoadFailed = 'addons_promo_card_load_failed',
  AddonsPurchaseFlowInitiated = 'addons_purchase_flow_initiated',
  AddonsPurchaseFlowCancelled = 'addons_purchase_flow_canceled',
  AddonsOrderSubmitted = 'addons_order_submitted',
  AddonsOrderSuccessful = 'addons_order_successful',
  AddonsOrderFlowAbandoned = 'addons_order_flow_abandoned',
  AddonsPurchaseFlowFailed = 'addons_purchase_flow_failed',
}

export enum AnalyticsEventSource {
  OVERVIEW_PAGE = 'overview_page',
  USAGE_PAGE = 'usage_page',
  EXTERNAL_LINK = 'external_link',
}

export type SchemaContext = 'addons_context';
export type SchemaVersions = '1-0-0' | '1-0-1';
export type AnalyticsVendors = 'com.viasat.care';
