import React, { useEffect } from 'react';
import { Platform, View } from 'react-native';
import { Loading } from '@mfe/legacy/andromeda';
import { useParams } from 'react-router';
import { useDownloadInvoice } from '../hooks';
import { EmptyState, Surface } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { Alert } from '@vst/beam-icons/illustrative-icons';
import styles from './styles.module.scss';

const Invoice = (): JSX.Element => {
  const { t } = useTranslation('Error');
  const { invoice } = useParams<{ invoice: string }>();
  const { loading, base64Content } = useDownloadInvoice(invoice);

  useEffect(() => {
    if (!base64Content || Platform.OS !== 'web') return;

    // change window to blob tab
    const pdf = new File(
      [Buffer.from(base64Content, 'base64')],
      `mv-invoice-${invoice}.pdf`,
      { type: 'application/pdf' }
    );

    const invoiceUrl = window.URL.createObjectURL(pdf);
    window.location.assign(invoiceUrl);
  }, [base64Content, invoice]);

  if (loading || base64Content)
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Loading size={70} />
      </View>
    );

  return (
    <Surface className={styles['content']}>
      <EmptyState
        icon={Alert}
        title={t('title')}
        description={t('message', { error: t('invoice') })}
      />
    </Surface>
  );
};

export default Invoice;
