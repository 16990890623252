/* eslint-disable @typescript-eslint/no-unused-vars */
import { ErrorsObj, RuntimeError } from '@mfe/shared/redux/graphql';
import {
  Address,
  AvailablePhoneNumber,
  DirectoryListType,
  GetCurrentAddOnsPayload,
  GetScrubbedAddressPayload,
  ProductType,
  SendSurveyResultsToQualtricsInput,
} from '@mfe/shared/schema-types';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ScrubAddressProcessStatus,
  ScrubAddressRecommendation,
} from '@mfe/shared/graphql/PAL/types';
import { ModalType } from '@mfe/shared/components';

export enum AddOnType {
  Voice = 'Voice',
  EasyCare = 'EasyCare',
  Shield = 'Shield',
  StaticIP = 'StaticIP',
}

type ScrubbedE911Address = {
  rawAddress?: Omit<
    GetScrubbedAddressPayload,
    'coordinates' | 'country' | 'processStatus' | 'recommendation'
  > | null;
  formattedAddress: {
    formattedAddressLine1: string;
    formattedAddressLine2: string;
  } | null;
  processStatus?: ScrubAddressProcessStatus;
  recommendation?: ScrubAddressRecommendation;
};

type EmergencyAddress = {
  streetAddress: string; // should be line 1 of addressLines
  aptUnit?: string; // should be line 2 of addressLines
  cityAddress: string; // municipality
  stateProvince: string; // region
  zipCode: string; // postalCode
};

type VoiceConfig = {
  postalCode: string;
  selectedPhoneNumber: string;
  emergencyAddress: EmergencyAddress;
  useServiceAddressForE911: boolean;
  callerId: string;
  blockCallerId: boolean;
  directoryListingType: DirectoryListType | null;
  publishDirectoryListAddress: boolean;
  showLastStepError: boolean;
};

type ErrorPayload = RuntimeError | ErrorsObj | undefined | null;
export type CustomError = {
  operation: string;
  message: string[];
};

export type AddOnsState = {
  loading: boolean;
  error: ErrorPayload;
  availableAddons: {
    loading: boolean;
    error: ErrorPayload;
    productTypes: Array<ProductType> | null;
  };
  currentShopAddonId: string | null;
  currentlySelectedAddOnName: string | null;
  currentAddOns: Array<GetCurrentAddOnsPayload> | null;
  availableNumbersLoading: boolean;
  availableNumbers: AvailablePhoneNumber[] | null;
  availableNumbersError: ErrorPayload;
  reserveNumberLoading: boolean;
  reserveNumberSuccess: boolean | null;
  reserveNumberError: ErrorPayload;
  configureAddonLoading: boolean;
  configureAddonSuccess: boolean | null;
  configureAddonError: ErrorPayload;
  voiceConfig: VoiceConfig;
  showModal: ModalType;
  isScrubbingE911Loading: boolean;
  scrubbedE911Address: ScrubbedE911Address;
  errorE911: ErrorPayload | CustomError;
  voiceEquipmentError: boolean | null;
  submitOrder: {
    loading: boolean;
    error: unknown | string | null;
    success: boolean;
  };
  addOnOrderId: string | null;
};

export const initialAddOnsState: AddOnsState = {
  loading: true,
  error: null,
  availableAddons: {
    loading: false,
    error: null,
    productTypes: null,
  },
  currentShopAddonId: null,
  currentAddOns: null,
  currentlySelectedAddOnName: null,
  availableNumbersLoading: false,
  availableNumbers: null,
  availableNumbersError: null,
  reserveNumberLoading: false,
  reserveNumberSuccess: null,
  reserveNumberError: null,
  configureAddonLoading: false,
  configureAddonSuccess: null,
  configureAddonError: null,
  voiceConfig: {
    postalCode: '',
    selectedPhoneNumber: '',
    emergencyAddress: {
      streetAddress: '',
      aptUnit: '',
      cityAddress: '',
      stateProvince: '',
      zipCode: '',
    },
    useServiceAddressForE911: true,
    callerId: '',
    blockCallerId: false,
    directoryListingType: null,
    publishDirectoryListAddress: false,
    showLastStepError: false,
  },
  showModal: null,
  isScrubbingE911Loading: false,
  scrubbedE911Address: {
    rawAddress: {
      addressLines: [],
      countryCode: '',
      municipality: '',
      postalCode: '',
      region: '',
    },
    formattedAddress: { formattedAddressLine1: '', formattedAddressLine2: '' },
    processStatus: undefined,
    recommendation: undefined,
  },
  errorE911: null,
  voiceEquipmentError: null,
  submitOrder: {
    loading: false,
    error: null,
    success: false,
  },
  addOnOrderId: null,
};

type PurchasedAddOn = {
  productTypeId: string;
  shoppingCartId: string;
  voice?: string;
};

export const addOnsSlice = createSlice({
  name: 'addOns',
  initialState: initialAddOnsState,
  reducers: {
    // ------------------- Purchase add-on offer -------------------
    setCurrentShopAddonId: (state, action: PayloadAction<string | null>) => {
      state.currentShopAddonId = action.payload;
    },
    getAddOnOffers: (state) => {
      state.availableAddons.loading = true;
    },
    refetchAddOnOffers: (state) => {
      state.availableAddons.loading = true;
      state.availableAddons.error = null;
      state.addOnOrderId = null;
      state.submitOrder.error = null;
      state.submitOrder.success = false;
      state.voiceEquipmentError = null;
      state.configureAddonError = null;
    },
    setAddOnOffers: (state, action: PayloadAction<Array<ProductType>>) => {
      state.availableAddons.loading = false;
      state.availableAddons.productTypes = action.payload;
    },
    setAddOnOffersError: (state, action: PayloadAction<ErrorPayload>) => {
      state.availableAddons.loading = false;
      state.availableAddons.error = action.payload;
    },
    setConfigureAddonSuccess: (state, { payload }: { payload: boolean }) => {
      state.configureAddonLoading = false;
      state.configureAddonSuccess = payload;
    },
    setConfigureAddonError: (state, action: PayloadAction<ErrorPayload>) => {
      state.configureAddonLoading = false;
      state.configureAddonError = action.payload;
      state.configureAddonSuccess = false;
    },
    purchaseAddOn: (state, action: PayloadAction<PurchasedAddOn>) => {
      state.submitOrder.loading = true;
    },
    setPurchaseAddOnSuccess: (state, action: PayloadAction<boolean>) => {
      state.submitOrder.loading = false;
      state.submitOrder.error = null;
      state.submitOrder.success = action.payload;
    },
    setPurchaseAddOnError: (state, action: PayloadAction<unknown | string>) => {
      state.submitOrder.loading = false;
      state.submitOrder.error = action.payload;
    },
    setVoiceEquipmentError: (state, { payload }: { payload: boolean }) => {
      state.configureAddonLoading = false;
      state.submitOrder.loading = false;
      state.voiceEquipmentError = payload;
    },
    setAddOnOrderId(state, action: PayloadAction<string | null>) {
      state.addOnOrderId = action.payload;
    },

    // ------------------- Voice add-on -------------------
    getAvailableNumbers: (state, action: PayloadAction<string>) => {
      state.availableNumbersLoading = true;
      state.availableNumbersError = null;
    },
    setAvailableNumbers: (
      state,
      action: PayloadAction<AvailablePhoneNumber[] | null>
    ) => {
      state.availableNumbers = action.payload;
      state.availableNumbersLoading = false;
    },
    setAvailableNumbersError: (state, action: PayloadAction<ErrorPayload>) => {
      state.availableNumbersLoading = false;
      state.availableNumbersError = action.payload;
    },
    reserveSelectedNumber: (state, action: PayloadAction<string>) => {
      state.voiceConfig.selectedPhoneNumber = action.payload;
      state.reserveNumberLoading = true;
    },
    setNumberReservationSuccess: (state) => {
      state.reserveNumberLoading = false;
      state.reserveNumberSuccess = true;
    },
    setNumberReservationError: (state, action: PayloadAction<ErrorPayload>) => {
      state.reserveNumberSuccess = false;
      state.reserveNumberLoading = false;
      state.reserveNumberError = action.payload;
    },
    setVoiceFormValues: (
      state,
      action: PayloadAction<
        Partial<VoiceConfig> | { emergencyAddress: Partial<EmergencyAddress> }
      >
    ) => {
      state.voiceConfig = {
        ...state.voiceConfig,
        ...action.payload,
        emergencyAddress: {
          ...state.voiceConfig.emergencyAddress,
          ...action.payload.emergencyAddress,
        },
      };
    },
    setShowLastStepError: (state, action: PayloadAction<boolean>) => {
      state.voiceConfig.showLastStepError = action.payload;
    },
    scrubEmergencyAddress: (state, _action: PayloadAction<Address>) => {
      state.isScrubbingE911Loading = true;
    },
    setShowModal: (state, action: PayloadAction<ModalType>) => {
      state.showModal = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setE911ScrubbedAddress: (
      state,
      action: PayloadAction<Pick<AddOnsState, 'scrubbedE911Address'>>
    ) => {
      state.isScrubbingE911Loading = false;
      state.scrubbedE911Address = action.payload.scrubbedE911Address;
    },
    setE911Error: (
      state,
      action: PayloadAction<ErrorPayload | CustomError>
    ) => {
      state.isScrubbingE911Loading = false;
      state.errorE911 = action.payload;
    },
    resetE911ScrubbedAddress: (state) => {
      return {
        ...state,
        scrubbedE911Address: initialAddOnsState.scrubbedE911Address,
      };
    },

    // ------------------- Current add-ons -------------------
    getCurrentAddOns: (state) => {
      state.loading = true;
      state.error = null;
    },
    setCurrentAddOns: (
      state,
      action: PayloadAction<GetCurrentAddOnsPayload[]>
    ) => {
      state.loading = false;
      state.currentAddOns = action.payload;
      state.error = null;
    },
    setError: (state, action: PayloadAction<ErrorPayload>) => {
      state.loading = false;
      state.error = action.payload;
    },
    refetchCurrentAddOns: (state, action: PayloadAction<string>) => {
      state.submitOrder.success = false;
      state.loading = true;
      state.error = null;
    },
    removeAddOn: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.error = null;
    },
  },
});

export const sendSurveyResults =
  createAction<SendSurveyResultsToQualtricsInput>('addOns/sendSurveyResults');

export const {
  setError,
  setCurrentShopAddonId,
  setVoiceFormValues,
  setShowLastStepError,
  getAvailableNumbers,
  setAvailableNumbers,
  setAvailableNumbersError,
  reserveSelectedNumber,
  setNumberReservationSuccess,
  setNumberReservationError,
  scrubEmergencyAddress,
  setShowModal,
  setE911ScrubbedAddress,
  setE911Error,
  resetE911ScrubbedAddress,
  setConfigureAddonError,
  setConfigureAddonSuccess,
  purchaseAddOn,
  setPurchaseAddOnSuccess,
  setPurchaseAddOnError,
  getAddOnOffers,
  refetchAddOnOffers,
  setAddOnOffers,
  setAddOnOffersError,
  setAddOnOrderId,
  getCurrentAddOns,
  setCurrentAddOns,
  refetchCurrentAddOns,
  removeAddOn,
  setLoading,
  setVoiceEquipmentError,
} = addOnsSlice.actions;

export const selectAddOns = (state: { addOns: AddOnsState }) => state.addOns;

export const selectCurrentShopAddon = (state: { addOns: AddOnsState }) => {
  const { currentShopAddonId } = state.addOns;

  const currentShopAddon = state.addOns.availableAddons.productTypes?.find(
    (addon) => addon.id === currentShopAddonId
  );

  if (!currentShopAddon) {
    return null;
  }

  return currentShopAddon;
};
