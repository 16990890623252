import React, {
  useState,
  CSSProperties,
  useLayoutEffect,
  useEffect,
} from 'react';
import { Transition } from 'react-transition-group';
import { useDimensionContext, useWebDrawer } from '../../Context';
import { TransitionStyle, initialTransition } from './utils';

export const DrawerContent = (): JSX.Element | null => {
  const { currentRoute, webDrawerRoutes } = useWebDrawer();
  const { width, isMobile, isTablet } = useDimensionContext();
  const [trigger, setTrigger] = useState<boolean>(false);
  const [contentTransitionStyle, setTransitionStyle] =
    useState<TransitionStyle>(initialTransition);

  const contentDefaultStyle: CSSProperties = {
    position: 'absolute',
    transition: 'transform 500ms ease-in-out, opacity 500ms ease-in-out',
    transform: `translateX(${isTablet ? 420 : 570})px`,
    right: isTablet ? 420 : 570,
    width: isTablet ? 420 : 570,
    height: '100%',
    zIndex: 4,
  };

  useLayoutEffect((): void => {
    const releaved = {
      transform: `translateX(${isTablet ? 420 : 570}px)`,
      opacity: 1,
    };
    const hidden = { transform: `translateX(${width}px)`, opacity: 0 };
    setTransitionStyle({
      entering: releaved,
      entered: releaved,
      exiting: hidden,
      exited: hidden,
      unmounted: null,
    });
  }, [width, isTablet]);

  useEffect((): void => {
    setTimeout((): void => {
      setTrigger(true);
    }, 50);
  }, []);

  return !isMobile ? (
    <Transition in={trigger} timeout={500} appear mountOnEnter unmountOnExit>
      {(state): JSX.Element => (
        <div
          style={{
            ...contentDefaultStyle,
            ...contentTransitionStyle[state],
          }}
        >
          {currentRoute && webDrawerRoutes && webDrawerRoutes[currentRoute]}
        </div>
      )}
    </Transition>
  ) : null;
};
