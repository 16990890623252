import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Txt, Icon } from '@vst/beam';
import { useToken } from '@mfe/legacy/mv/views/Auth';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import styles from './profile-hover.module.scss';
import { ExitToApp, SettingsOutlined } from '@vst/beam-icons/icons';
import { AccountType } from '@mfe/shared/schema-types';

interface ProfileHoverProps {
  isProfileOpen: boolean;
  setIsProfileOpen: (action: boolean) => void;
  createNavItemClickHandler: (link: string) => (e: any) => void;
}

const ProfileHover = ({
  isProfileOpen,
  setIsProfileOpen,
  createNavItemClickHandler,
}: ProfileHoverProps) => {
  const { t } = useTranslation('Nav');
  const { logout } = useToken();
  const {
    userInfo: { fullName: residentialName, accountType, businessName },
  } = useSelector(selectUserInfo);

  const fullName =
    accountType === AccountType.Residential ? residentialName : businessName;

  return (
    <>
      {isProfileOpen && (
        <div
          className={styles.overlay}
          onClick={() => setIsProfileOpen(!isProfileOpen)}
        />
      )}
      <div
        className={styles.container}
        data-cy="profileDropdown"
        onClick={() => setIsProfileOpen(!isProfileOpen)}
      >
        <div className={styles.profileInfoHeader}>
          {fullName && (
            <Txt
              variant="bodyLargeBold"
              color="regular"
              className={styles.greeting}
              data-cy="profileDropdownGreeting"
            >
              {fullName}
            </Txt>
          )}
          <Txt
            variant="smallRegular"
            color="regular"
            className={styles.accountType}
            data-cy="profileDropdownAccountType"
            mb={'8px'}
            mt={!fullName ? '16px' : undefined}
          >
            {t('ProfileAccountType', { context: accountType })}
          </Txt>
        </div>
        <div className={styles.actionMargin}>
          <div
            className={styles.actionContainer}
            onClick={createNavItemClickHandler('/Profile')}
          >
            <Icon icon={SettingsOutlined} size={20} color="gray_600" />
            <Txt
              variant="smallRegular"
              color="inherit"
              className={styles.actions}
              data-cy="profileDropdownAccountInfoAndSettings"
            >
              {t('ProfileMobile')}
            </Txt>
          </div>
          <div className={styles.actionContainer} onClick={logout}>
            <Icon icon={ExitToApp} size={20} color="gray_600" />
            <Txt
              variant="smallRegular"
              color="inherit"
              className={styles.actions}
              data-cy="profileDropdownSignOut"
            >
              {t('SignOut')}
            </Txt>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileHover;
