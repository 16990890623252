import React, {
  useState,
  CSSProperties,
  useLayoutEffect,
  useEffect,
} from 'react';
import { Animated, Easing, StyleSheet } from 'react-native';
import { Transition } from 'react-transition-group';
import {
  DrawerContainerProps,
  TransitionStyle,
  initialTransition,
} from './utils';
import { useDimensionContext, useWebDrawer } from '../../Context';
import { Color } from '../../Color';
import { CloseX } from '../../../components';

const styles = StyleSheet.create({
  closeXContainer: {
    position: 'absolute',
    top: 10,
    right: 25,
    zIndex: 4,
  },
});

export const DrawerContainer = ({
  children,
  closeIconColor,
}: DrawerContainerProps): JSX.Element | null => {
  const { isVisible, isDrawerLocked, setDrawer } = useWebDrawer();
  const { width, height, isMobile, isTablet } = useDimensionContext();
  const [opacity] = useState<Animated.Value>(
    new Animated.Value(isDrawerLocked ? 1 : 0)
  );

  const fadeCloseX = (): void => {
    Animated.timing(opacity, {
      toValue: isDrawerLocked ? 0 : 1,
      duration: 400,
      easing: Easing.quad,
      useNativeDriver: true,
    }).start();
  };

  const [containerTransitionStyle, setTransitionStyle] =
    useState<TransitionStyle>(initialTransition);

  const containerDefaultStyle: CSSProperties = {
    position: 'fixed',
    transition: 'transform 500ms ease-in-out',
    transform: `translateX(${isTablet ? 420 : 570})px`,
    right: isTablet ? 420 : 570,
    width: isTablet ? 420 : 570,
    height: '100%',
    backgroundColor: Color.white,
    zIndex: 3,
    overflow: 'auto',
  };

  const backdropDefaultStyle: CSSProperties = {
    position: 'fixed',
    transition: 'opacity 500ms ease-in-out',
    width,
    height,
    backgroundColor: Color.gray800,
    opacity: 0,
    overflow: 'auto',
  };

  const backdropTransitionStyle: TransitionStyle = {
    entering: { opacity: 0.7, zIndex: 2 },
    entered: { opacity: 0.7, zIndex: 2 },
    exiting: { opacity: 0, zIndex: 2 },
    exited: { opacity: 0 },
    unmounted: null,
  };

  useEffect((): void => {
    fadeCloseX();
  }, [isDrawerLocked]);

  useLayoutEffect((): void => {
    const revealed = { transform: `translateX(${isTablet ? 420 : 570}px)` };
    const hidden = { transform: `translateX(${width}px)` };
    setTransitionStyle({
      entering: revealed,
      entered: revealed,
      exiting: hidden,
      exited: hidden,
      unmounted: null,
    });
  }, [width, isTablet]);

  return !isMobile ? (
    <>
      <Transition
        in={isVisible}
        timeout={500}
        appear
        mountOnEnter
        unmountOnExit
      >
        {(state): JSX.Element => (
          <div
            style={{
              ...containerDefaultStyle,
              ...containerTransitionStyle[state],
            }}
          >
            {children}
            <Animated.View
              pointerEvents={isDrawerLocked ? 'none' : 'auto'}
              style={[styles.closeXContainer, { opacity }]}
            >
              <CloseX
                color={closeIconColor}
                transparent
                size={14.5}
                onPress={(): void | any => (isDrawerLocked ? {} : setDrawer())}
              />
            </Animated.View>
          </div>
        )}
      </Transition>
      <Transition in={isVisible} timeout={500}>
        {(state): JSX.Element => (
          <div
            onClick={(): void | any => (isDrawerLocked ? {} : setDrawer())}
            style={{
              ...backdropDefaultStyle,
              ...backdropTransitionStyle[state],
            }}
          />
        )}
      </Transition>
    </>
  ) : null;
};
