import React from 'react';
import { View, StyleSheet, ViewProps } from 'react-native';

const styles = StyleSheet.create({
  viewPadding: {
    maxWidth: 1000,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 0,
    paddingRight: 0,
  },
});

export interface PageContainerProps extends ViewProps {
  children: any;
}

export const PageContainer = ({
  children,
  style,
  ...rest
}: PageContainerProps) => (
  <View {...rest} style={[styles.viewPadding, style]}>
    {children}
  </View>
);
