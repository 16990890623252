import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddressR0 } from '@viasat/res-apps-lib/build/types/vail';
import { Address } from '@mfe/shared/schema-types';

import {
  ScrubAddressProcessStatus,
  ScrubAddressRecommendation,
} from '@mfe/shared/graphql/PAL/types';
import { ModalType } from '../../libUtils';

export enum EditableCardType {
  Billing = 'Billing',
  ContactInformation = 'ContactInformation',
  Shipping = 'Shipping',
  None = 'None',
}

export interface ScrubbedAddressState {
  showModal: ModalType;
  editingCard: EditableCardType;
  closeAfterReload: boolean;
  loading: boolean;
  rawInput: Address;
  scrubbedAddress: {
    rawAddress: AddressR0;
    formattedAddress: string | null;
    processStatus?: ScrubAddressProcessStatus;
    recommendation?: ScrubAddressRecommendation;
  };
}

export const initialScrubbedAddressState: ScrubbedAddressState = {
  showModal: null,
  editingCard: EditableCardType.None,
  closeAfterReload: false,
  loading: false,
  rawInput: {
    addressLines: [''],
    countryCode: '',
    municipality: '',
    postalCode: '',
    region: '',
  },
  scrubbedAddress: {
    rawAddress: {
      addressLines: [''],
      countryCode: '',
      municipality: '',
      postalCode: '',
      region: '',
      country: '',
    },
    formattedAddress: '',
  },
};

export const scrubbedAddressSlice = createSlice({
  name: 'scrubbedAddress',
  initialState: initialScrubbedAddressState,
  reducers: {
    setEditingCard: (state, action: PayloadAction<EditableCardType>) => {
      state.editingCard = action.payload;
    },
    setCloseAfterReload: (state, action: PayloadAction<boolean>) => {
      state.closeAfterReload = action.payload;
    },
    setShowModal: (state, action: PayloadAction<ModalType>) => {
      state.showModal = action.payload;
    },
    setScrubbedAddress: (
      state,
      {
        payload,
      }: {
        payload: Omit<
          ScrubbedAddressState,
          | 'loading'
          | 'showModal'
          | 'editingCard'
          | 'editedAddress'
          | 'closeAfterReload'
        >;
      }
    ) => {
      state.loading = false;
      state.rawInput = payload.rawInput;
      state.scrubbedAddress = payload.scrubbedAddress;
    },
    fetchScrubbedAddress: (state, action: PayloadAction<Address>) => {
      state.loading = true;
    },
    resetScrubbedAddress: (state) => {
      return {
        ...state,
        rawInput: initialScrubbedAddressState.rawInput,
        scrubbedAddress: initialScrubbedAddressState.scrubbedAddress,
      };
    },
  },
});

export const {
  setShowModal,
  setEditingCard,
  setCloseAfterReload,
  setScrubbedAddress,
  fetchScrubbedAddress,
  resetScrubbedAddress,
} = scrubbedAddressSlice.actions;

export const selectScrubbedAddress = (state: {
  scrubbedAddress: ScrubbedAddressState;
}) => state.scrubbedAddress;
