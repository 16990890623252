import { ChangeBillDate } from '@mfe/features/change-bill-date';
import { PayBillLink } from '@mfe/legacy/mv/utils/Navigation';
import useNavigate from '@mfe/services/navigation';

const SelectBillCycle = () => {
  const { replace } = useNavigate();

  const navigateToPreviousPage = () => replace(`/${PayBillLink.PayBill}`);

  return <ChangeBillDate navigateToPreviousPage={navigateToPreviousPage} />;
};

export default SelectBillCycle;
