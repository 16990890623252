import { Txt } from '@vst/beam';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectChosenPlan } from '@mfe/to-be-migrated/redux/changePlan';

export const PrimaryAttribute = () => {
  const { t } = useTranslation('NewChangePlan');
  const { characteristics } = useSelector(selectChosenPlan);
  return (
    <div data-cy="data-cap-primary-attribute">
      <div>
        <Txt component="span" variant="heading2">
          {characteristics.dataCapGB}
        </Txt>
        <Txt component="span" variant="bodySmallBold" ml="4px">
          {characteristics.dataCapUnits}
        </Txt>
      </div>
      <Txt variant="smallRegular" color="inherit">
        {t('orderReview.cartDetails.highSpeedData')}
      </Txt>
    </div>
  );
};
