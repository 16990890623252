import { AnalyticsContext } from '@mfe/services/analytics';

import {
  InputEvent,
  DateOfBirth,
  FormFieldName,
  ValidationErrors,
  FormUtilsReturnValue,
} from '../../utils';
import { BenefitQuestion, BenefitAnswer } from '../../analytics';
import { ApplicationIdField } from './ApplicationIdField';
import { FirstNameField } from './FirstNameField';
import { LastNameField } from './LastNameField';
import { DateOfBirthField } from './DateOfBirthField';
import { TransferField } from './TransferField';
import { TribalBenefitField } from './TribalBenefitField';
import { TermsAndConditions } from './TermsAndConditions';
import {
  structuredEvent,
  AnalyticsAction,
  Categories,
} from '@mfe/to-be-migrated/redux/analytics';

import styles from './Form.module.scss';
import { useDispatch } from 'react-redux';

interface FormContentProps extends FormUtilsReturnValue {
  errors: ValidationErrors;
  analyticsContext: AnalyticsContext[];
  openTermsAndConditions: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  validateDOB: (date: DateOfBirth) => void;
  fieldsToValidate: FormFieldName[];
}

export const FormContent = (props: FormContentProps) => {
  const {
    values,
    errors,
    validateDOB,
    getFieldHandler,
    analyticsContext,
    fieldsToValidate,
    openTermsAndConditions,
  } = props;

  const { isTransferring, hasTribalBenefit } = values;
  const dispatch = useDispatch();

  const onTransferChange = (e: InputEvent) => {
    getFieldHandler('isTransferring')(e);

    if (isTransferring !== null) {
      const answer = isTransferring
        ? BenefitAnswer.TRANSFER_YES
        : BenefitAnswer.TRANSFER_NO;

      dispatch(
        structuredEvent({
          category: Categories.ACP,
          action: AnalyticsAction.QUESTION_ANSWER_SELECTED,
          params: {
            context: analyticsContext,
            property: answer,
            label: BenefitQuestion.TRANSFER,
          },
        })
      );
    }
  };

  const onTribalChange = (e: InputEvent) => {
    getFieldHandler('hasTribalBenefit')(e);

    if (hasTribalBenefit !== null) {
      const answer = hasTribalBenefit
        ? BenefitAnswer.TRIBAL_YES
        : BenefitAnswer.TRIBAL_NO;

      dispatch(
        structuredEvent({
          category: Categories.ACP,
          action: AnalyticsAction.QUESTION_ANSWER_SELECTED,
          params: {
            context: analyticsContext,
            property: answer,
            label: BenefitQuestion.TRIBAL,
          },
        })
      );
    }
  };

  return (
    <div className={styles['form']}>
      <ApplicationIdField
        value={values.applicationId}
        error={errors.applicationId}
        isValid={!fieldsToValidate.includes('applicationId')}
        handleChange={getFieldHandler('applicationId')}
      />
      <FirstNameField
        value={values.firstName}
        error={errors.firstName}
        isValid={!fieldsToValidate.includes('firstName')}
        handleChange={getFieldHandler('firstName')}
      />
      <LastNameField
        value={values.lastName}
        error={errors.lastName}
        isValid={!fieldsToValidate.includes('lastName')}
        handleChange={getFieldHandler('lastName')}
      />
      <DateOfBirthField
        error={errors.dob}
        isValid={!fieldsToValidate.includes('dob')}
        values={values.dob}
        validateDOB={validateDOB}
        onChangeHandlers={{
          day: getFieldHandler('day'),
          year: getFieldHandler('year'),
          month: getFieldHandler('month'),
        }}
      />
      <TransferField
        value={isTransferring}
        error={errors.isTransferring}
        isValid={!fieldsToValidate.includes('isTransferring')}
        onChange={onTransferChange}
      />
      <TribalBenefitField
        value={hasTribalBenefit}
        error={errors.hasTribalBenefit}
        isValid={!fieldsToValidate.includes('hasTribalBenefit')}
        onChange={onTribalChange}
      />
      <TermsAndConditions
        value={values.terms}
        error={errors.terms}
        isValid={!fieldsToValidate.includes('terms')}
        onChange={getFieldHandler('terms')}
        openTermsAndConditions={openTermsAndConditions}
      />
    </div>
  );
};
