import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Surface, Txt } from '@vst/beam';

import { useScreenResolution } from '@mfe/shared/util';
import {
  selectChangePlan,
  selectChosenPlan,
} from '@mfe/to-be-migrated/redux/changePlan';

import { Notice } from './Notice';
import { Contract } from './Contract';
import { ModalTypes } from './OrderReview';
import { EqipmentLeaseFee } from './EquipmentLeaseFee';
import { EquipmentShipping } from './EquipmentShipping';

import styles from './OrderReview.module.scss';

interface OrderDetailsCardProps {
  handlePlacePlanOrder: () => void;
  openModal: (e: React.MouseEvent<HTMLElement>, modalType: ModalTypes) => void;
  closeModal: () => void;
  isTermsAndConditionsModalOpen: boolean;
  isCalculationModalOpen: boolean;
  requiresWorkOrder: boolean;
}

export const OrderDetailsCard = ({
  openModal,
  closeModal,
  handlePlacePlanOrder,
  requiresWorkOrder,
  isTermsAndConditionsModalOpen,
}: OrderDetailsCardProps) => {
  const { t } = useTranslation('NewChangePlan');

  const { isEquipmentChangeNeeded, priceCurrency } =
    useSelector(selectChosenPlan);

  const {
    cart: { hasEquipmentShipping },
  } = useSelector(selectChangePlan);

  const { isSmall, isExtraSmall, isMedium } = useScreenResolution();
  const isMobile = isSmall || isExtraSmall;
  const isMobileOrTablet = isMobile || isMedium;

  const showSubmitButton = () => {
    if (requiresWorkOrder) return true;
    else if (!isMobileOrTablet) return true;

    return false;
  };

  return (
    <Surface
      variant="primary"
      radius={isMobile ? '0px' : '16px'}
      className={styles['order-review-details']}
      data-cy="order-review-details"
    >
      <Content>
        <Txt variant="bodyLargeBold">{t('orderReview.details.title')}</Txt>
        {isEquipmentChangeNeeded && (
          <EqipmentLeaseFee priceCurrency={priceCurrency || ''} />
        )}

        <Contract />

        {hasEquipmentShipping && <EquipmentShipping />}

        <Notice
          openModal={openModal}
          closeModal={closeModal}
          isModalOpen={isTermsAndConditionsModalOpen}
        />
      </Content>

      {showSubmitButton() && (
        <Footer>
          <Button
            data-cy="submit-order-button"
            onClick={handlePlacePlanOrder}
            style={
              isMobileOrTablet
                ? { maxWidth: '400px', width: '100%', alignSelf: 'center' }
                : undefined
            }
          >
            {requiresWorkOrder
              ? t('orderReview.cartDetails.next')
              : t('orderReview.cartDetails.placeOrderButton')}
          </Button>
        </Footer>
      )}
    </Surface>
  );
};

const Content: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className={styles['details-content']}>{children}</div>
);

const Footer: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className={styles['details-footer']}>{children}</div>
);
