import { useState } from 'react';
import {
  Surface,
  Txt,
  IllustrativeIcon,
  Checkbox,
  Button,
  StandAloneLink,
} from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { Container } from '@mfe/shared/components';
import styles from './styles.module.scss';
import { Alert } from '@vst/beam-icons/illustrative-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  ChangePlanSteps,
  selectChangePlan,
  setChangePlanStep,
} from '@mfe/to-be-migrated/redux/changePlan';
import { AddonIcon, AddonProductTypeIds } from './AddonIcon';
import { ADDON_KIND, useScreenResolution } from '@mfe/shared/util';
import { SpacingTypes } from '@vst/beam/src/react/common';
import { selectAddOns } from '@mfe/to-be-migrated/redux/addOns';
import {
  DroppedAddOns as DroppedAddOnsType,
  GetCurrentAddOnsPayload,
} from '@mfe/shared/schema-types';

interface DroppedAddOnsProps {
  handleBackNavigation: () => void;
}

const DroppedAddOnsIcons = ({
  droppedAddOns,
}: {
  droppedAddOns: DroppedAddOnsType[];
}): JSX.Element => {
  return (
    <div
      className={styles['text-icon-container']}
      data-cy="dropped-addons-container"
    >
      {droppedAddOns.map(({ name, productTypeId }) => (
        <div
          key={productTypeId}
          data-cy="IconsWithNames"
          className={styles['text-icon']}
        >
          <AddonIcon productTypeId={productTypeId as AddonProductTypeIds} />
          <Txt pl={'8px'} variant={'bodyLargeBold'}>
            {name}
          </Txt>
        </div>
      ))}
    </div>
  );
};

export const DroppedAddOns = ({ handleBackNavigation }: DroppedAddOnsProps) => {
  const { t } = useTranslation('NewChangePlan', { keyPrefix: 'droppedAddOns' });
  const { droppedAddOns } = useSelector(selectChangePlan);
  const { currentAddOns } = useSelector(selectAddOns);
  const [approvedDroppedAddOns, setApprovedDroppedAddOns] = useState(false);
  const hasStaticIP = currentAddOns?.find(
    (addOn: GetCurrentAddOnsPayload) => addOn.kind === ADDON_KIND.STATIC_IP
  );

  const dispatch = useDispatch();
  const { isLarge, isExtraLarge, isMedium, isSmall, isExtraSmall } =
    useScreenResolution();
  const isMobile = !isLarge && !isExtraLarge && !isMedium;
  const goBack = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    handleBackNavigation();
  };
  const marginVertical: SpacingTypes = isMobile ? '16px' : '40px';
  const marginHorizontal: SpacingTypes | undefined =
    isSmall || isExtraSmall ? undefined : '32px';

  const translationContext = hasStaticIP ? 'StaticIP' : undefined;

  return (
    <Container mt={marginVertical} mb={marginVertical} mx={marginHorizontal}>
      <Surface style={{ background: 'transparent' }}>
        <Surface className={styles['body']}>
          <Surface className={styles['title']}>
            <IllustrativeIcon
              style={{ alignSelf: 'center' }}
              icon={Alert}
              color={'teal_600'}
              size={'88px'}
            />
            <Txt variant="heading4" style={{ textAlign: 'center' }}>
              {t('title')}
            </Txt>
          </Surface>
          <Surface variant="secondary" radius="8px" p="24px" width={'100%'}>
            <Txt variant="bodySmallRegular" color="subtle">
              {t('cardInfo', {
                context: translationContext,
              })}
            </Txt>

            {hasStaticIP ? (
              <div
                key={AddonProductTypeIds.STATIC_IP}
                data-cy="StaticIpIconWithName"
                className={styles['text-icon']}
              >
                <AddonIcon productTypeId={AddonProductTypeIds.STATIC_IP} />
                <Txt pl={'8px'} variant={'bodyLargeBold'}>
                  {t('staticIP')}
                </Txt>
              </div>
            ) : (
              <DroppedAddOnsIcons droppedAddOns={droppedAddOns} />
            )}
          </Surface>

          <Surface>
            <Txt variant="bodySmallRegular" color="subtle">
              {t('body', {
                context: translationContext,
              })}
            </Txt>

            {!hasStaticIP && (
              <Txt variant="bodySmallRegular" color="subtle" mt={'16px'}>
                {t('body2')}
              </Txt>
            )}
          </Surface>

          <hr className={styles['hr']} />
          <Checkbox
            id="acceptDroppedAddons"
            data-cy="acceptDroppedAddons"
            label={t('checkboxText', {
              context: translationContext,
              names: droppedAddOns.map(({ name }) => name).join(', '),
            })}
            onChange={() => setApprovedDroppedAddOns(!approvedDroppedAddOns)}
          />
        </Surface>
        <Surface className={styles['footer']}>
          <StandAloneLink
            data-cy="droppedAddons-backToPlans"
            linkSize="medium"
            onClick={goBack}
            href="#"
            variant={'primary'}
          >
            {t('back')}
          </StandAloneLink>
          <Button
            fluid={isMobile || isMedium}
            data-cy="droppedAddons-continue"
            disabled={!approvedDroppedAddOns}
            onClick={() => {
              dispatch(setChangePlanStep(ChangePlanSteps.ORDER_REVIEW));
            }}
          >
            {t('continue')}
          </Button>
        </Surface>
      </Surface>
    </Container>
  );
};
