import React from 'react';
import { Text } from 'react-native';
import { AndromedaTextProps } from './index';
import { DimensionContext } from '../../utils/Context/DimensionContext';
import {
  TextSize,
  getFontFamily,
  getWeight,
  getColor,
  createTextComponent,
} from './utils';
import {
  TextStyleArray as StyleArray,
  SourceSansProProps,
  SizeProps,
} from '../types';

export class H1 extends React.Component<
  Omit<AndromedaTextProps, SourceSansProProps | SizeProps>
> {
  private root: Text | undefined | null;

  setNativeProps(args: any) {
    return this.root && this.root.setNativeProps(args);
  }

  override render() {
    const {
      style: styleProp,
      book = false,
      bold = false,
      heavy = false,
      white = false,
      gray = false,
      link = false,
      fixed = false,
      ...props
    } = this.props;

    const weight = getWeight(undefined, book, undefined, bold, heavy);
    const textColor = getColor(white, gray, link);
    const fontFamily = getFontFamily('UniNeue', weight, '');

    return (
      <DimensionContext.Consumer>
        {(value: DimensionContext): JSX.Element => {
          const style: StyleArray = [fontFamily, textColor, styleProp];
          return createTextComponent(
            this.root,
            TextSize.H1,
            value,
            style,
            fixed,
            props
          );
        }}
      </DimensionContext.Consumer>
    );
  }
}
