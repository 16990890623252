import * as React from 'react';
import { Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';

import { ProductInstanceStatus } from '@mfe/shared/schema-types';
import {
  selectBillingInfo,
  triggerGetOrders,
} from '@mfe/to-be-migrated/redux/billingInfo';

import { useTrackTiming } from '@mfe/shared/util';
import { trackBillingEvent } from '@mfe/to-be-migrated/redux/analytics';
import { OrderLineItemLifecycleState } from '@mfe/shared/graphql/OM/schemaTypes';

import Invoice from './invoice';
import Card from '../../shared-ui/card';
import Divider from '../../shared-ui/divider';
import InvoiceHistoryLoading from './loading';
import { ANALYTICS_EVENT_NAME } from '../../shared/constants';
import { getNumberOfPages, getPageOfT } from '../../shared/pagination';
import sharedStyles from '../../shared/styles.module.scss';
import { ActivityFooter } from '../../shared-ui/activity-footer';

import { Boleto } from './boleto';
import { findBoletoPayment, getPreinstallBoleto } from './utils';

import styles from './styles.module.scss';

const ITEMS_PER_PAGE = 3;

const InvoiceHistory = (): JSX.Element => {
  const dispatch = useDispatch();

  const {
    billingLoading,
    billingInfo: {
      billingAccount,
      invoices,
      payments,
      orders,
      invoicesLoading,
    },
  } = useSelector(selectBillingInfo);

  const { ref, inView } = useInView();
  const [statementPageNumber, setStatementPageNumber] = React.useState(1);
  const [showMoreClicked, setShowMoreClicked] = React.useState(true);
  const { t } = useTranslation('BillingStatement');

  React.useEffect(() => {
    if (inView) {
      dispatch(
        trackBillingEvent({
          eventName: ANALYTICS_EVENT_NAME.billStatementHistoryViewed,
          version: '1-0-0',
          data: {
            view_method: 'tab_selected',
            view_zone: 'billing_page',
          },
        })
      );
    }
  }, [dispatch, inView]);

  React.useEffect(() => {
    if (!orders) {
      dispatch(triggerGetOrders());
    }
  }, [dispatch, orders]);

  useTrackTiming({
    eventName: 'BillingStatementLoaded',
    isLoading: invoicesLoading,
  });

  if (billingLoading || invoicesLoading) {
    return <InvoiceHistoryLoading />;
  }

  const statementsShown = getPageOfT(
    invoices,
    statementPageNumber,
    showMoreClicked,
    ITEMS_PER_PAGE
  );
  const statementsNumPages = getNumberOfPages(invoices, ITEMS_PER_PAGE);
  const showFooter = invoices.length > 3;
  const preinstallBoleto = getPreinstallBoleto(payments);
  const isPreinstall =
    !invoices?.length &&
    billingAccount?.accountStatus !== ProductInstanceStatus.Canceled;
  const isPaymentDue = (billingAccount?.balance?.value ?? 0) >= 0;
  const showBoleto =
    isPreinstall &&
    isPaymentDue &&
    preinstallBoleto &&
    orders?.[0]?.state !== OrderLineItemLifecycleState.Canceled;

  return (
    <div className={styles['invoiceFrame']}>
      <Card dataCy="billingStatement" className={styles['card']}>
        <div className={styles['title']}>
          <Txt id="title" data-cy="billingHistoryTitle" variant="heading5">
            {t('title')}
          </Txt>
        </div>

        <Divider />

        <div className={sharedStyles['flexColumn']}>
          <div
            id="statementsShown"
            className={sharedStyles['flexColumn']}
            ref={ref}
          >
            {!invoices?.length && (
              <div
                className={
                  preinstallBoleto
                    ? styles['unavailableBoleto']
                    : styles['unavailable']
                }
                id="noStatementItemsShowing"
              >
                <Txt id="noBillingItems" variant="bodySmallRegular">
                  {t('noStatements')}
                </Txt>
              </div>
            )}

            {showBoleto && (
              <div className={styles['preinstallBoleto']}>
                <Boleto payment={preinstallBoleto} showLabel />
              </div>
            )}

            {invoices.length
              ? statementsShown.map((invoice, index) => (
                  <Invoice
                    invoice={invoice}
                    payment={payments?.find(
                      findBoletoPayment(invoice.invoiceNumber)
                    )}
                    index={index}
                    key={index}
                  />
                ))
              : []}
          </div>

          {showFooter && (
            <ActivityFooter
              showMoreClicked={showMoreClicked}
              setShowMoreClicked={setShowMoreClicked}
              pageNumber={statementPageNumber}
              setPageNumber={setStatementPageNumber}
              totalNumPages={statementsNumPages}
            />
          )}
        </div>
      </Card>
    </div>
  );
};

export default InvoiceHistory;
