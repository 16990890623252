import { Button, Surface, Txt } from '@vst/beam';
import { WifiOutlined } from '@vst/beam-icons/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useScreenResolution } from '@mfe/shared/util';
import { selectPlanCharacteristics } from '@mfe/to-be-migrated/redux/plan';

import styles from './Overview.module.scss';

type Props = {
  handleManagePlan: () => void;
  handleViewDataUsage: () => void;
};

export const PlanPreview = ({
  handleManagePlan,
  handleViewDataUsage,
}: Props) => {
  const { t } = useTranslation('Overview', { keyPrefix: 'planPreview' });

  const { isExtraLarge, isLarge, isMedium } = useScreenResolution();
  const isDesktop = isExtraLarge || isLarge || isMedium;

  const {
    characteristics: { name, isUnleashed },
    loading,
    errors,
  } = useSelector(selectPlanCharacteristics);

  return (
    <div className={styles['planInfo']}>
      {loading ? (
        <PlanInfoLoading />
      ) : (
        <Surface
          radius={isDesktop ? '16px' : '0px'}
          data-cy="plan-preview"
          className={styles['planPreview']}
        >
          <div className={styles['previewContent']}>
            <div className={styles['iconBackground']}>
              <WifiOutlined width={24} height={24} />
            </div>
            {errors ? <ErrorMessage /> : <PlanName name={name} />}
          </div>
          <div className={styles['actions']}>
            {isUnleashed && (
              <Button
                data-cy="view-data-usage-button"
                variant="secondary"
                onClick={handleViewDataUsage}
              >
                <Txt variant="labelMedium">{t('viewDataUsage')}</Txt>
              </Button>
            )}
            <Button
              data-cy="manage-plan-button"
              variant="primary"
              onClick={handleManagePlan}
            >
              <Txt variant="labelMedium">{t('managePlan')}</Txt>
            </Button>
          </div>
        </Surface>
      )}
    </div>
  );
};

const PlanName = ({ name }: { name: string }) => {
  const { t } = useTranslation('Overview', { keyPrefix: 'planPreview' });

  return (
    <div data-cy="plan-name" className={styles['inlineText']}>
      <Txt variant="bodyLargeRegular" component="span">
        {t('planIntro')}
      </Txt>
      <Txt variant="bodyLargeBold" component="span">
        {name}
      </Txt>
      <Txt variant="bodyLargeRegular" component="span">
        {t('plan')}
      </Txt>
    </div>
  );
};

const PlanInfoLoading = () => {
  const { isExtraLarge, isLarge, isMedium } = useScreenResolution();
  const isDesktop = isExtraLarge || isLarge || isMedium;

  return (
    <Surface
      radius={isDesktop ? '16px' : '0px'}
      data-cy="plan-info-loading"
      className={styles['planInfoLoading']}
    >
      <div className={styles['leftLoading']}>
        <div className={styles['iconLoading']} />
        <div className={styles['planNameLoading']} />
      </div>
      <div className={styles['rightLoading']} />
    </Surface>
  );
};

const ErrorMessage = () => {
  const { t } = useTranslation('Overview', { keyPrefix: 'planPreview' });

  return (
    <div data-cy="plan-info-error" className={styles['inlineText']}>
      <Txt variant="bodyLargeBold" component="span">
        {t('error')}
      </Txt>
      <Txt variant="bodyLargeRegular" component="span">
        {t('errorCta')}
      </Txt>
    </div>
  );
};
