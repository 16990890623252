import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { parsePhoneNumber } from 'libphonenumber-js';
import { PhoneNumberUtil } from 'google-libphonenumber';

import { Button, Form, HelperText, PhoneNumber, validators } from '@vst/beam';

import {
  setPhoneNumber,
  setIsEditingNumber,
} from '@mfe/to-be-migrated/redux/streamOnPromo';

import { useTranslation } from '../../utils';
import { EditableNumberInputTypes } from '../../utils/componentInterfaces';

import styles from './editableMobileNumberInput.module.scss';

export const EditableMobileNumberInput = ({
  updateWindowStatus,
  formattedNumber,
}: EditableNumberInputTypes): JSX.Element => {
  const dispatch = useDispatch();

  const { t } = useTranslation('SMS');

  const [phoneNumberInput, setPhoneNumberInput] = useState('');

  const isPhoneNumberValid = (value: any, element: any, resetError: any) => {
    if (resetError) {
      return { error: false };
    }

    const prefix = element.value.split(' ')[0];

    if (element.value.length === prefix.length || !element.value) {
      return { error: true, message: t('Error.Message') };
    }

    const phoneUtil = PhoneNumberUtil.getInstance();

    try {
      const number = phoneUtil.parseAndKeepRawInput(value);
      const isValid = phoneUtil.isValidNumber(number);

      return { error: !isValid, message: t('Error.invalidPhoneNumber') };
    } catch (error) {
      return { error: true, message: t('Error.invalidPhoneNumber') };
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    parsePhoneNumber(`+${phoneNumberInput}`);
    dispatch(setIsEditingNumber(false));
    dispatch(setPhoneNumber(`+${phoneNumberInput}`));
  };

  return (
    <Form
      data-cy="stream-phone-form"
      initialValues={{ phone: phoneNumberInput }}
      name="stream-phone-form"
      validationMode="onSubmit"
      onSubmit={handleSubmit}
    >
      <PhoneNumber
        className={styles['phoneInput']}
        onChange={(value: string) => setPhoneNumberInput(value)}
        countryCodeEditable={false}
        validationRules={[validators.define(isPhoneNumberValid)]}
        inputProps={{ id: 'phone', name: 'phone', required: false }}
        value={phoneNumberInput}
        labelProps={{ labelText: t('PhoneNumber.Title') }}
        fluid
      />
      <HelperText
        helpMessage={t('Opt.Disclosure')}
        state="info"
        showIcon
        className={styles['helperText']}
      />
      <div
        data-cy="editableMobileNumberInputFooterContainer"
        className={styles['editableFooter']}
      >
        <Button
          type="submit"
          data-cy="editableMobileNumberInputFooterSaveButton"
          variant="secondary"
        >
          {t('Save.Button')}
        </Button>
        <Button
          data-cy="editableMobileNumberInputFooterCancelButton"
          variant="tertiary"
          mr="24px"
          onClick={(e) => {
            setPhoneNumberInput(formattedNumber as string);
            updateWindowStatus(e);
          }}
        >
          {t('Cancel.Button')}
        </Button>
      </div>
    </Form>
  );
};
