import { useEffect } from 'react';
import {
  mobileMessageHandler,
  MobileMessageHandlerDependencies,
  WindowMessageData,
} from './mobileMessageHandler';

export const useMobileEventHandler = (
  dependencies: MobileMessageHandlerDependencies
) => {
  useEffect(() => {
    const handleMessage = (event: MessageEvent<WindowMessageData>) => {
      mobileMessageHandler(event.data, dependencies);
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [dependencies]);
};

export { ResizeObserverWrapper } from './ResizeObserverWrapper';
