import { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Badge, Txt } from '@vst/beam';

import Address from '../Address/index';
import {
  selectStreamOnPromo,
  scrubAddress,
  setIsEdittingAddress,
} from '@mfe/to-be-migrated/redux/streamOnPromo';
import styles from './ShippingAddress.module.scss';
import { useTranslation } from 'react-i18next';

interface ShippingContainerProps {
  children: ReactNode;
}

const ShippingAddressContainer = ({ children }: ShippingContainerProps) => {
  const { t } = useTranslation('StreamOnPromo');

  return (
    <>
      <div className={styles['titleContainer']}>
        <Txt variant="heading5" color="regular" data-cy="shippingAddressTitle">
          {t('StreamOn.PurchaseFlow.Title')}
        </Txt>
        <Badge
          showIcon={false}
          label="Beta"
          state="infoSecondary"
          emphasis="light"
          size="small"
        />
      </div>
      {children}
    </>
  );
};

export const ShippingAddress = () => {
  const dispatch = useDispatch();
  const { shippingAddress } = useSelector(selectStreamOnPromo);

  return (
    <ShippingAddressContainer>
      <Address
        address={shippingAddress}
        onSubmit={(address) => {
          const submittedAddress = {
            ...address,
            municipality: shippingAddress.municipality,
            region: shippingAddress.region,
          };
          /* VGFB-325:
          - UI currently does not have fields for municipality and region
          - New PAL endpoint throws an error if not provided with municipality and region as input
          - When updating the address, we are passing the existing values for municipality and region
          - These values don't actually influence the response (that's based on postal code), they just ensure the call doesn't fail
          - Ex. if the user wants to change their address to one in a different US state than what we have stored as shipping address, 
          the result will be based on the postal code provided, not the old municipality and region values
          */
          dispatch(scrubAddress({ address: submittedAddress }));
        }}
        onCancel={() => {
          dispatch(setIsEdittingAddress(false));
        }}
      />
    </ShippingAddressContainer>
  );
};
