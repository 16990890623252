import { useState } from 'react';

export const useRetryCounter = (retryLimit: number) => {
  const [retryCounter, setRetryCounter] = useState(0);

  const canRetry = retryCounter < retryLimit;
  const incrementRetryCounter = () => setRetryCounter((prev) => prev + 1);

  return {
    canRetry,
    retryCounter,
    incrementRetryCounter,
  };
};
