import { Logo, Txt, Surface } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { ViasatLogoDefault } from '@vst/beam-icons/logos/viasat';

import { Footer } from '@mfe/shared/components';

import MaintenanceImage from './maintenance-image';
import styles from './index.module.scss';

export const Maintenance = (): JSX.Element => {
  const { t } = useTranslation('Maintenance');

  return (
    <Surface variant="gradientPrimary1">
      <Surface className={styles['container']} variant="secondary">
        <Surface className={styles['header']}>
          <div>
            <Logo logo={ViasatLogoDefault} alt="Viasat" size="112px" />
          </div>
        </Surface>
        <Surface className={styles['body']}>
          <MaintenanceImage />
          <Txt variant="heading4" style={{ paddingBottom: 12, paddingTop: 16 }}>
            {t('title')}
          </Txt>
          <Txt variant="bodyLargeRegular">{t('body')}</Txt>
        </Surface>
        <Surface />
        <Footer />
      </Surface>
    </Surface>
  );
};
