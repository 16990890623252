import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectConfig } from '@mfe/shared/redux/config';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { AffordableConnectivityProgram } from '@mfe/features/acp';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { selectAnalytics } from '@mfe/to-be-migrated/redux/analytics';

import styles from './acp.module.scss';

interface ACPPageProps {
  redirectToOverview: () => void;
}

export const ACPPage = (props: ACPPageProps) => {
  const { redirectToOverview } = props;
  const { i18n } = useTranslation();

  const {
    user: { partyId },
  } = useSelector(selectUser);

  const { showACPBanner, loadingConfig } = useSelector(selectConfig);

  const {
    userInfo: {
      address: { service },
      isACPEligible,
    },
  } = useSelector(selectUserInfo);

  const { usageContext } = useSelector(selectAnalytics);

  const isUsageContextEmpty = usageContext?.data?.usage_cap_gb === undefined;

  if (isUsageContextEmpty) {
    return null;
  }

  if (loadingConfig) {
    return null;
  }

  if (!showACPBanner || !isACPEligible) {
    redirectToOverview();
  }

  return (
    <div className={styles['container']}>
      <AffordableConnectivityProgram
        i18n={i18n}
        partyId={partyId}
        stateCode={service?.region ?? ''}
        redirectToOverview={redirectToOverview}
        analyticsContext={[usageContext]}
      />
    </div>
  );
};
