import { Txt } from '@vst/beam';
import React, { ReactNode } from 'react';
import { useTranslation } from '../../utils';

import { Pages } from '@mfe/to-be-migrated/redux/streamOnPromo';
import styles from './FlowEnd.module.scss';
import { CloseButton } from './CloseButton';

export interface FlowEndProps {
  namespace: string;
  page: Pages;
  titleKey: string;
  descriptionKey: string;
  offerName?: string;
  email?: string | null;
  children: ReactNode;
}

export const FlowEnd = ({
  namespace,
  page,
  titleKey,
  descriptionKey,
  offerName,
  email,
  children,
}: FlowEndProps) => {
  const { t } = useTranslation(namespace);

  return (
    <div className={styles['pageContainer']}>
      <div data-cy="FlowEndIcon">{children}</div>
      <div data-cy="FlowEndTitle" className={styles['titleContainer']}>
        <Txt variant="heading2" className={styles['title']}>
          {t(`${titleKey}`, { short_name: offerName })}
        </Txt>
      </div>
      <div data-cy="FlowEndText" className={styles['textContainer']}>
        <Txt variant="bodyLargeRegular">
          {t(`${descriptionKey}`, { customer_email: email })}
        </Txt>
      </div>
      <CloseButton
        t={t}
        buttonTextKey={page === Pages.orderSummary ? 'Close.Button' : 'Button'}
        namespace={namespace}
      />
    </div>
  );
};
