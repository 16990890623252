import { colors } from '@vst/beam';

const Gradient = (): JSX.Element => {
  return (
    <div
      id="gradient"
      style={{
        height: 8,
        background: `linear-gradient(to right, ${colors.blue[700]}, ${colors.teal[500]})`,
      }}
    />
  );
};

export default Gradient;
