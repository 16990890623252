import { Alert } from '@vst/beam-icons/illustrative-icons';
import { Icon, InlineLink, StandardModal, Txt } from '@vst/beam';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const CustomerSupportTelLink = () => {
  const { t } = useTranslation('ShopAddons');

  return (
    <Txt as="span" variant={'bodyLargeRegular'} mt={'12px'}>
      {t('cancelSubscriptionErrorModal.customerSupportMessage')}
      <InlineLink
        variant="primary"
        className={styles['customer-support-tel-link']}
        href={`tel:${t('Global:callCustomerSupportPhoneNumber')}`}
      >
        {t('Global:callCustomerSupportPhoneNumberDisplay')}
      </InlineLink>
    </Txt>
  );
};

type CancelSubscriptionErrorModalProps = {
  isOpen: boolean;
  onClose: () => void;
  addOnName: string;
};

export const CancelSubscriptionErrorModal = ({
  isOpen,
  onClose,
  addOnName,
}: CancelSubscriptionErrorModalProps) => {
  const { t } = useTranslation('ShopAddons');

  return (
    <div data-cy={'cancel-subscription-error-modal'}>
      <StandardModal
        isOpen={isOpen}
        onClose={onClose}
        size="medium"
        header={t('cancelSubscriptionErrorModal.header', { addOnName })}
        actions={{
          primary: {
            label: t('cancelSubscriptionErrorModal.primaryAction.label'),
            onClick: onClose,
            minWidth: '200px',
          },
        }}
        surfaceProps={{ className: styles['modal-content'] }}
      >
        <div className={styles['modal-body']}>
          <div data-cy="Alert-Icon">
            <Icon icon={Alert} size={100} color={'alert_400'} mb={'48px'} />
          </div>
          <Txt variant="heading4" color={'regular'} mb={'24px'}>
            {t('cancelSubscriptionErrorModal.info')}
          </Txt>
          <Txt variant={'bodyLargeRegular'} color={'regular'}>
            <CustomerSupportTelLink />
          </Txt>
        </div>
      </StandardModal>
    </div>
  );
};
