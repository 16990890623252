import { InlineLink } from '@vst/beam';
import { ModalTypeEnum, ModalComponent } from '@mfe/shared/components';
import { useScreenResolution } from '@mfe/shared/util';
import { useTranslation } from 'react-i18next';
import Outcome from './Outcome';
import { Alert } from '@vst/beam-icons/illustrative-icons';

interface ApplicationGeneralErrorProps {
  goBackToOverviewPage: () => void;
  closeModal: () => void;
}
export const ApplicationGeneralError = (
  props: ApplicationGeneralErrorProps
) => {
  const { goBackToOverviewPage, closeModal } = props;
  const { t } = useTranslation('ACP', { keyPrefix: 'Error' });
  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;

  const hrefLink = `tel:${t(
    'otherApplicationErrors.thirdDescription.phoneNumber'
  )}`;

  const callUs = (
    <>
      {t('otherApplicationErrors.thirdDescription.first')}{' '}
      <InlineLink
        href={hrefLink}
        style={{ textDecoration: 'none' }}
        data-cy="acp-call-us-general-error"
      >
        {t('otherApplicationErrors.thirdDescription.phoneNumber')}
      </InlineLink>
      .
    </>
  );

  const renderOutcome = () => {
    return (
      <Outcome
        iconName={Alert}
        data-cy="acp-application-general-error"
        title={t('otherApplicationErrors.title')}
        text={t(`otherApplicationErrors.description`)}
        thirdText={callUs}
        primaryButton={{
          label: t('otherApplicationErrors.buttons.updateInformation'),
          handleClick: closeModal,
        }}
        secondaryButton={{
          label: t('otherApplicationErrors.buttons.backToMyViasat'),
          handleClick: goBackToOverviewPage,
        }}
        hasCloseButton
        closeButton={{ handleClick: closeModal }}
      />
    );
  };

  return isMobile ? (
    renderOutcome()
  ) : (
    <ModalComponent type={ModalTypeEnum.ACP}>{renderOutcome()}</ModalComponent>
  );
};
