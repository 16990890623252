import { selectAddOns } from '@mfe/to-be-migrated/redux/addOns';

import { useSelector } from 'react-redux';

export const useShouldRefetchCurrentAddons = () => {
  const { addOnOrderId, submitOrder } = useSelector(selectAddOns);

  if (submitOrder.success && addOnOrderId) {
    return true;
  }
  return false;
};
