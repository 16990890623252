import React from 'react';
import {
  View,
  TouchableWithoutFeedback,
  StyleProp,
  ViewStyle,
  Platform,
} from 'react-native';
import { useDimensionContext, ScreenSize, Color } from '../../utils';
import { CloseX } from '../Icons';
import { ViewStyleArray as StyleArray } from '../types';

export interface CardProps {
  exteriorTitle?: React.ReactElement<any> | string | undefined;
  exteriorSubtitle?: React.ReactElement<any> | string | undefined;
  alignItemsCenter?: boolean;
  removeContentStyle?: boolean;
  removeMarginStyle?: boolean;
  removeAllStyle?: boolean;
  removeShadow?: boolean;
  removeBorderRadius?: boolean;
  removeBackgroundColor?: boolean;
  removeHiddenOverflow?: boolean;
  removeMaxWidth?: boolean;
  marginStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  onPress?: (() => void | null | undefined) | undefined;
  onClose?: (() => void | undefined) | undefined;
  testID?: string | undefined;
  children?: React.ReactNode;
}

export const getMaxWidth = (screenSize: ScreenSize): ViewStyle => {
  switch (screenSize) {
    case ScreenSize.XS || (Platform.OS === 'web' && ScreenSize.SM):
      return { maxWidth: '100%' };
    case ScreenSize.SM:
      return { maxWidth: 375 };
    case ScreenSize.MD:
    case ScreenSize.LG:
    case ScreenSize.XL:
    default:
      return { maxWidth: 542 };
  }
};

export const Card = (props: CardProps): JSX.Element => {
  const {
    onClose = undefined,
    exteriorTitle = undefined,
    exteriorSubtitle = undefined,
    alignItemsCenter = false,
    removeContentStyle = false,
    removeMarginStyle = false,
    removeAllStyle = false,
    removeShadow = false,
    removeBorderRadius = false,
    removeBackgroundColor = false,
    removeHiddenOverflow = false,
    removeMaxWidth = false,
    marginStyle = undefined,
    contentStyle = undefined,
    onPress = undefined,
    testID = undefined,
    children,
  } = props;
  const { screenSize } = useDimensionContext();

  const hasTitle = exteriorTitle !== undefined;
  const hasSubtitle = exteriorSubtitle !== undefined;
  const hasCloseX = onClose !== undefined;
  const closeXMargin = screenSize === ScreenSize.XS ? -8 : -12;

  // Content Styling.
  let content: StyleArray = [];
  if (hasCloseX) content.push({ paddingTop: 15 });
  if (alignItemsCenter) content.push({ alignItems: 'center' });
  if (contentStyle) content.push(contentStyle);
  else {
    content.push({
      paddingTop: hasCloseX ? 0 : 45,
      paddingBottom: 35,
      paddingHorizontal: 24,
    });
  }
  if (removeContentStyle) content = [{}];
  const overflow: StyleArray = { overflow: 'hidden' };
  const backgroundColor = removeBackgroundColor
    ? {}
    : { backgroundColor: Color.white };
  const borderRadius = removeBorderRadius ? {} : { borderRadius: 8 };
  const shadow = removeShadow
    ? {}
    : {
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 4,
        },
        shadowOpacity: 0.25,
        shadowRadius: 30,
        elevation: 25,
      };

  // Margin Styling.
  const maxWidth = removeMaxWidth
    ? { maxWidth: '100%' }
    : getMaxWidth(screenSize);
  let margins: StyleArray = [maxWidth];
  if (marginStyle) margins.push(marginStyle);
  else margins.push({ marginTop: 20, marginBottom: 28 });
  if (removeMarginStyle) margins = [{}];

  if (removeAllStyle) {
    content = [{}];
    margins = [{}];
  }

  if (Platform.OS === 'web')
    margins.push(overflow, shadow, backgroundColor, borderRadius);
  else {
    if (Platform.OS === 'android') {
      if (!removeHiddenOverflow) margins.push(overflow);
      if (!removeShadow) margins.push(shadow);
      if (!removeBackgroundColor) margins.push(backgroundColor);
      if (!removeBorderRadius) margins.push(borderRadius);
    } else {
      if (!removeShadow) margins.push(shadow);
      if (!removeHiddenOverflow) content.push(overflow);
      if (!removeBackgroundColor) content.push(backgroundColor);
      if (!removeBorderRadius) content.push(borderRadius);
    }
  }

  const interior = (
    <View style={content} testID={onPress === undefined ? testID : undefined}>
      {hasCloseX && (
        <View style={{ marginRight: closeXMargin, alignSelf: 'flex-end' }}>
          <CloseX transparent onPress={onClose} />
        </View>
      )}
      {children}
    </View>
  );

  const touchable = (
    <TouchableWithoutFeedback onPress={onPress} testID={testID}>
      <View>{interior}</View>
    </TouchableWithoutFeedback>
  );

  return (
    <>
      {hasTitle && exteriorTitle}
      {hasSubtitle && exteriorSubtitle}
      <View style={margins}>
        {onPress === undefined ? interior : touchable}
      </View>
    </>
  );
};

export * from './Variants';
