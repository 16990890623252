export const ArticleImage = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="190"
    height="124"
    viewBox="0 0 190 124"
    fill="none"
  >
    <g clip-path="url(#clip0_5393_14863)">
      <g opacity="0.5" clip-path="url(#clip1_5393_14863)">
        <path
          d="M80.5667 19.9173L58.9863 5.32953C45.3768 -3.88201 29.0523 10.2452 31.9344 28.7018C32.6113 33.0326 32.1977 37.5079 30.7465 41.5541C29.2954 45.6003 26.873 49.0331 23.7895 51.4124C21.363 53.2809 19.3358 55.8098 17.8717 58.7948C16.4075 61.7797 15.5476 65.1371 15.3612 68.5956C15.1749 72.0542 15.6673 75.517 16.7989 78.7043C17.9304 81.8916 19.6694 84.7141 21.8751 86.9438L38.9446 104.195C42.306 107.593 46.1729 110.193 50.3403 111.857L60.0863 115.776C76.9697 122.533 95.1522 123.026 112.272 117.191C129.391 111.357 144.656 99.4642 156.084 83.0583L163.108 73.0093C176.704 47.0495 158.757 13.8293 133.508 18.2341L99.4601 24.1797C92.9441 25.2962 86.3037 23.798 80.5667 19.9173V19.9173Z"
          fill="#D9D1FA"
        />
      </g>
      <g clip-path="url(#clip2_5393_14863)">
        <rect
          x="10.2915"
          y="100.004"
          width="164.667"
          height="0.864029"
          fill="#DEE4E8"
        />
      </g>
      <g clip-path="url(#clip3_5393_14863)">
        <path
          d="M43.4044 47.8418L28.7095 101.678H60.1402L43.4044 47.8418Z"
          fill="#677A89"
        />
        <path
          d="M161.535 101.678L145.207 48.1042C145.207 48.1042 43.3222 47.5798 43.6487 48.1042C43.9753 48.6285 59.9764 101.94 59.9764 101.94L161.535 101.678Z"
          fill="#C3CDD5"
        />
        <path
          d="M84.223 54.834C84.3753 54.857 84.5306 54.8335 84.671 54.7662C84.8114 54.6988 84.9311 54.5904 85.0164 54.4534C85.1017 54.3164 85.1491 54.1564 85.1532 53.9918C85.1573 53.8272 85.1178 53.6647 85.0394 53.5231C84.9511 53.1764 84.7602 52.8697 84.4952 52.6491C84.2303 52.4284 83.9056 52.3058 83.5699 52.2995C83.4282 52.2937 83.2873 52.324 83.1587 52.3877C83.03 52.4514 82.9172 52.5469 82.8296 52.6663C82.742 52.7856 82.682 52.9255 82.6546 53.0744C82.6272 53.2233 82.6331 53.3771 82.6719 53.5231C82.7611 53.893 82.961 54.2215 83.2412 54.4583C83.5213 54.695 83.8662 54.827 84.223 54.834V54.834Z"
          fill="#677A89"
        />
        <path
          d="M88.0609 54.8336C88.2121 54.8448 88.3635 54.8149 88.5011 54.7466C88.6386 54.6784 88.7577 54.5739 88.8474 54.443C88.937 54.3122 88.9943 54.1591 89.0138 53.9982C89.0333 53.8372 89.0144 53.6736 88.9589 53.5226C88.855 53.1689 88.6487 52.8597 88.3699 52.6398C88.0911 52.4198 87.7541 52.3005 87.4078 52.2991C87.2666 52.2862 87.1247 52.3128 86.996 52.3764C86.8673 52.4401 86.7564 52.5384 86.674 52.6619C86.5916 52.7853 86.5407 52.9296 86.5263 53.0805C86.5118 53.2314 86.5342 53.3838 86.5914 53.5226C86.6642 53.8855 86.8485 54.2118 87.1149 54.4494C87.3813 54.687 87.7143 54.8223 88.0609 54.8336V54.8336Z"
          fill="#677A89"
        />
        <path
          d="M91.4891 54.834C91.6404 54.8453 91.7918 54.8154 91.9294 54.7471C92.0669 54.6788 92.186 54.5744 92.2757 54.4435C92.3653 54.3126 92.4225 54.1596 92.442 53.9987C92.4615 53.8377 92.4426 53.6741 92.3872 53.5231C92.2729 53.1762 92.0639 52.8741 91.7873 52.656C91.5108 52.4378 91.1793 52.3136 90.836 52.2995C90.6943 52.2937 90.5534 52.324 90.4247 52.3877C90.2961 52.4514 90.1833 52.5469 90.0957 52.6663C90.0081 52.7856 89.9481 52.9255 89.9207 53.0744C89.8933 53.2233 89.8993 53.3771 89.938 53.5231C90.0386 53.8862 90.2416 54.2072 90.5194 54.4419C90.7971 54.6766 91.1358 54.8136 91.4891 54.834V54.834Z"
          fill="#677A89"
        />
        <path
          d="M95.1637 54.8329C95.8168 54.8329 96.1433 54.3086 95.9801 53.522C95.8918 53.1753 95.7008 52.8686 95.4358 52.648C95.1709 52.4274 94.8462 52.3047 94.5106 52.2985C94.3631 52.2676 94.2105 52.2809 94.0696 52.337C93.9287 52.3931 93.8049 52.4897 93.712 52.6162C93.6192 52.7428 93.5607 52.8943 93.5432 53.0541C93.5256 53.2139 93.5496 53.3758 93.6126 53.522C93.7017 53.892 93.9017 54.2205 94.1818 54.4572C94.4619 54.694 94.8069 54.826 95.1637 54.8329V54.8329Z"
          fill="#677A89"
        />
        <path
          d="M98.7557 54.8336C98.907 54.8448 99.0584 54.8149 99.196 54.7466C99.3335 54.6784 99.4526 54.5739 99.5423 54.443C99.6319 54.3122 99.6891 54.1591 99.7086 53.9982C99.7281 53.8372 99.7092 53.6736 99.6537 53.5226C99.5395 53.1758 99.3305 52.8737 99.0539 52.6555C98.7774 52.4373 98.446 52.3132 98.1027 52.2991C97.9615 52.2862 97.8195 52.3128 97.6908 52.3764C97.5622 52.4401 97.4512 52.5384 97.3689 52.6619C97.2865 52.7853 97.2356 52.9296 97.2211 53.0805C97.2066 53.2314 97.229 53.3838 97.2862 53.5226C97.3591 53.8855 97.5434 54.2118 97.8097 54.4494C98.0761 54.687 98.4092 54.8223 98.7557 54.8336Z"
          fill="#677A89"
        />
        <path
          d="M102.428 54.834C102.58 54.857 102.736 54.8335 102.876 54.7662C103.016 54.6988 103.136 54.5904 103.221 54.4534C103.307 54.3164 103.354 54.1564 103.358 53.9918C103.362 53.8272 103.323 53.6647 103.245 53.5231C103.156 53.1764 102.965 52.8697 102.7 52.6491C102.435 52.4284 102.111 52.3058 101.775 52.2995C101.633 52.2937 101.492 52.324 101.364 52.3877C101.235 52.4514 101.122 52.5469 101.035 52.6663C100.947 52.7856 100.887 52.9255 100.86 53.0744C100.832 53.2233 100.838 53.3771 100.877 53.5231C100.978 53.8862 101.181 54.2072 101.458 54.4419C101.736 54.6766 102.075 54.8136 102.428 54.834Z"
          fill="#677A89"
        />
        <path
          d="M106.021 54.8336C106.756 54.8336 107.082 54.3092 106.919 53.5226C106.815 53.1689 106.609 52.8597 106.33 52.6398C106.051 52.4198 105.714 52.3005 105.368 52.2991C105.227 52.2862 105.085 52.3128 104.956 52.3764C104.827 52.4401 104.716 52.5384 104.634 52.6619C104.552 52.7853 104.501 52.9296 104.486 53.0805C104.472 53.2314 104.494 53.3838 104.551 53.5226C104.624 53.8855 104.809 54.2118 105.075 54.4494C105.341 54.687 105.674 54.8223 106.021 54.8336Z"
          fill="#677A89"
        />
        <path
          d="M109.694 54.8336C109.845 54.8448 109.997 54.8149 110.134 54.7466C110.272 54.6784 110.391 54.5739 110.481 54.443C110.57 54.3122 110.628 54.1591 110.647 53.9982C110.667 53.8372 110.648 53.6736 110.592 53.5226C110.478 53.1758 110.269 52.8737 109.992 52.6555C109.716 52.4373 109.384 52.3132 109.041 52.2991C108.9 52.2862 108.758 52.3128 108.629 52.3764C108.501 52.4401 108.39 52.5384 108.307 52.6619C108.225 52.7853 108.174 52.9296 108.16 53.0805C108.145 53.2314 108.168 53.3838 108.225 53.5226C108.298 53.8855 108.482 54.2118 108.748 54.4494C109.015 54.687 109.348 54.8223 109.694 54.8336V54.8336Z"
          fill="#677A89"
        />
        <path
          d="M113.368 54.834C114.021 54.834 114.348 54.3096 114.184 53.5231C114.096 53.1764 113.905 52.8697 113.64 52.6491C113.375 52.4284 113.051 52.3058 112.715 52.2995C112.573 52.2937 112.432 52.324 112.304 52.3877C112.175 52.4514 112.062 52.5469 111.975 52.6663C111.887 52.7856 111.827 52.9255 111.8 53.0744C111.772 53.2233 111.778 53.3771 111.817 53.5231C111.906 53.893 112.106 54.2215 112.386 54.4583C112.666 54.695 113.011 54.827 113.368 54.834V54.834Z"
          fill="#677A89"
        />
        <path
          d="M116.961 54.8336C117.112 54.8448 117.264 54.8149 117.401 54.7466C117.539 54.6784 117.658 54.5739 117.747 54.443C117.837 54.3122 117.894 54.1591 117.914 53.9982C117.933 53.8372 117.914 53.6736 117.859 53.5226C117.755 53.1689 117.549 52.8597 117.27 52.6398C116.991 52.4198 116.654 52.3005 116.308 52.2991C116.167 52.2862 116.025 52.3128 115.896 52.3764C115.767 52.4401 115.656 52.5384 115.574 52.6619C115.492 52.7853 115.441 52.9296 115.426 53.0805C115.412 53.2314 115.434 53.3838 115.491 53.5226C115.564 53.8855 115.749 54.2118 116.015 54.4494C116.281 54.687 116.614 54.8223 116.961 54.8336Z"
          fill="#677A89"
        />
        <path
          d="M120.716 54.834C120.868 54.857 121.024 54.8335 121.164 54.7662C121.305 54.6988 121.424 54.5904 121.51 54.4534C121.595 54.3164 121.642 54.1564 121.646 53.9918C121.65 53.8272 121.611 53.6647 121.533 53.5231C121.444 53.1764 121.253 52.8697 120.988 52.6491C120.723 52.4284 120.399 52.3058 120.063 52.2995C119.921 52.2937 119.78 52.324 119.652 52.3877C119.523 52.4514 119.41 52.5469 119.323 52.6663C119.235 52.7856 119.175 52.9255 119.148 53.0744C119.12 53.2233 119.126 53.3771 119.165 53.5231C119.266 53.8862 119.469 54.2072 119.746 54.4419C120.024 54.6766 120.363 54.8136 120.716 54.834Z"
          fill="#677A89"
        />
        <path
          d="M124.308 54.8336C124.961 54.8336 125.287 54.3092 125.124 53.5226C125.02 53.1689 124.814 52.8597 124.535 52.6398C124.256 52.4198 123.919 52.3005 123.573 52.2991C123.432 52.2862 123.29 52.3128 123.161 52.3764C123.032 52.4401 122.921 52.5384 122.839 52.6619C122.757 52.7853 122.706 52.9296 122.691 53.0805C122.677 53.2314 122.699 53.3838 122.756 53.5226C122.846 53.8926 123.046 54.2211 123.326 54.4578C123.606 54.6946 123.951 54.8266 124.308 54.8336Z"
          fill="#677A89"
        />
        <path
          d="M127.9 54.8336C128.051 54.8448 128.202 54.8149 128.34 54.7466C128.478 54.6784 128.597 54.5739 128.686 54.443C128.776 54.3122 128.833 54.1591 128.853 53.9982C128.872 53.8372 128.853 53.6736 128.798 53.5226C128.684 53.1758 128.475 52.8737 128.198 52.6555C127.921 52.4373 127.59 52.3132 127.247 52.2991C127.105 52.2862 126.964 52.3128 126.835 52.3764C126.706 52.4401 126.595 52.5384 126.513 52.6619C126.431 52.7853 126.38 52.9296 126.365 53.0805C126.351 53.2314 126.373 53.3838 126.43 53.5226C126.503 53.8855 126.687 54.2118 126.954 54.4494C127.22 54.687 127.553 54.8223 127.9 54.8336Z"
          fill="#677A89"
        />
        <path
          d="M131.574 54.834C132.227 54.834 132.553 54.3096 132.39 53.5231C132.302 53.1764 132.111 52.8697 131.846 52.6491C131.581 52.4284 131.256 52.3058 130.921 52.2995C130.779 52.2937 130.638 52.324 130.509 52.3877C130.381 52.4514 130.268 52.5469 130.18 52.6663C130.093 52.7856 130.033 52.9255 130.005 53.0744C129.978 53.2233 129.984 53.3771 130.023 53.5231C130.112 53.893 130.312 54.2215 130.592 54.4583C130.872 54.695 131.217 54.827 131.574 54.834V54.834Z"
          fill="#677A89"
        />
        <path
          d="M135.166 54.8336C135.318 54.8448 135.469 54.8149 135.607 54.7466C135.744 54.6784 135.863 54.5739 135.953 54.443C136.043 54.3122 136.1 54.1591 136.119 53.9982C136.139 53.8372 136.12 53.6736 136.064 53.5226C135.96 53.1689 135.754 52.8597 135.475 52.6398C135.197 52.4198 134.86 52.3005 134.513 52.2991C134.372 52.2862 134.23 52.3128 134.102 52.3764C133.973 52.4401 133.862 52.5384 133.78 52.6619C133.697 52.7853 133.646 52.9296 133.632 53.0805C133.617 53.2314 133.64 53.3838 133.697 53.5226C133.77 53.8855 133.954 54.2118 134.22 54.4494C134.487 54.687 134.82 54.8223 135.166 54.8336V54.8336Z"
          fill="#677A89"
        />
        <path
          d="M138.84 54.834C138.993 54.857 139.148 54.8335 139.288 54.7662C139.429 54.6988 139.548 54.5904 139.634 54.4534C139.719 54.3164 139.766 54.1564 139.77 53.9918C139.774 53.8272 139.735 53.6647 139.657 53.5231C139.568 53.1764 139.377 52.8697 139.112 52.6491C138.847 52.4284 138.523 52.3058 138.187 52.2995C138.045 52.2937 137.904 52.324 137.776 52.3877C137.647 52.4514 137.534 52.5469 137.447 52.6663C137.359 52.7856 137.299 52.9255 137.272 53.0744C137.244 53.2233 137.25 53.3771 137.289 53.5231C137.39 53.8862 137.593 54.2072 137.87 54.4419C138.148 54.6766 138.487 54.8136 138.84 54.834Z"
          fill="#677A89"
        />
        <path
          d="M70.5082 53.5226C70.4043 53.1689 70.1981 52.8597 69.9193 52.6398C69.6404 52.4198 69.3035 52.3005 68.9572 52.2991C68.8159 52.2862 68.674 52.3128 68.5453 52.3764C68.4167 52.4401 68.3057 52.5384 68.2234 52.6619C68.141 52.7853 68.0901 52.9296 68.0756 53.0805C68.0611 53.2314 68.0835 53.3838 68.1407 53.5226C68.2136 53.8855 68.3979 54.2118 68.6642 54.4494C68.9306 54.687 69.2636 54.8223 69.6102 54.8336C69.7614 54.8448 69.9129 54.8149 70.0504 54.7466C70.188 54.6784 70.3071 54.5739 70.3967 54.443C70.4864 54.3122 70.5436 54.1591 70.5631 53.9982C70.5826 53.8372 70.5637 53.6736 70.5082 53.5226Z"
          fill="#677A89"
        />
        <path
          d="M73.2841 54.834C73.4364 54.857 73.5916 54.8335 73.732 54.7662C73.8724 54.6988 73.9921 54.5904 74.0774 54.4534C74.1628 54.3164 74.2101 54.1564 74.2142 53.9918C74.2183 53.8272 74.1789 53.6647 74.1005 53.5231C74.0122 53.1764 73.8212 52.8697 73.5562 52.6491C73.2913 52.4284 72.9666 52.3058 72.631 52.2995C72.4893 52.2937 72.3483 52.324 72.2197 52.3877C72.091 52.4514 71.9782 52.5469 71.8906 52.6663C71.803 52.7856 71.7431 52.9255 71.7157 53.0744C71.6883 53.2233 71.6942 53.3771 71.733 53.5231C71.8335 53.8862 72.0366 54.2072 72.3143 54.4419C72.592 54.6766 72.9308 54.8136 73.2841 54.834V54.834Z"
          fill="#677A89"
        />
        <path
          d="M76.957 54.8336C77.6101 54.8336 77.9366 54.3092 77.7734 53.5226C77.6695 53.1689 77.4632 52.8597 77.1844 52.6398C76.9056 52.4198 76.5686 52.3005 76.2223 52.2991C76.0811 52.2862 75.9392 52.3128 75.8105 52.3764C75.6819 52.4401 75.5709 52.5384 75.4885 52.6619C75.4061 52.7853 75.3552 52.9296 75.3407 53.0805C75.3262 53.2314 75.3487 53.3838 75.4059 53.5226C75.495 53.8926 75.695 54.2211 75.9751 54.4578C76.2553 54.6946 76.6001 54.8266 76.957 54.8336Z"
          fill="#677A89"
        />
        <path
          d="M80.5492 54.8336C80.7005 54.8448 80.8519 54.8149 80.9894 54.7466C81.1269 54.6784 81.2461 54.5739 81.3357 54.443C81.4254 54.3122 81.4826 54.1591 81.5021 53.9982C81.5216 53.8372 81.5028 53.6736 81.4473 53.5226C81.333 53.1758 81.124 52.8737 80.8474 52.6555C80.5708 52.4373 80.2394 52.3132 79.8961 52.2991C79.7549 52.2862 79.613 52.3128 79.4843 52.3764C79.3557 52.4401 79.2447 52.5384 79.1623 52.6619C79.08 52.7853 79.029 52.9296 79.0145 53.0805C79 53.2314 79.0225 53.3838 79.0797 53.5226C79.1526 53.8855 79.3369 54.2118 79.6032 54.4494C79.8696 54.687 80.2027 54.8223 80.5492 54.8336Z"
          fill="#677A89"
        />
        <path
          d="M83.7335 57.4562C83.8186 57.8124 84.0064 58.1308 84.2702 58.3662C84.534 58.6015 84.8604 58.7419 85.203 58.7672C85.3543 58.7784 85.5057 58.7485 85.6432 58.6802C85.7807 58.6119 85.8999 58.5075 85.9895 58.3766C86.0792 58.2458 86.1364 58.0927 86.1559 57.9318C86.1754 57.7708 86.1566 57.6072 86.1011 57.4562C85.9972 57.1025 85.7909 56.7933 85.512 56.5734C85.2332 56.3534 84.8963 56.2341 84.5499 56.2327C84.4087 56.2198 84.2668 56.2464 84.1382 56.31C84.0095 56.3737 83.8985 56.472 83.8161 56.5955C83.7338 56.7189 83.6828 56.8632 83.6683 57.0141C83.6539 57.165 83.6763 57.3174 83.7335 57.4562V57.4562Z"
          fill="#677A89"
        />
        <path
          d="M87.3257 57.4557C87.4449 57.807 87.6532 58.1157 87.9272 58.3473C88.2012 58.5789 88.53 58.7241 88.8768 58.7666C89.0212 58.7632 89.1628 58.7225 89.2893 58.648C89.4159 58.5735 89.5238 58.4674 89.6039 58.3388C89.6841 58.2101 89.734 58.0627 89.7496 57.9089C89.7651 57.7552 89.7458 57.5997 89.6932 57.4557C89.6049 57.109 89.414 56.8023 89.149 56.5817C88.884 56.361 88.5593 56.2384 88.2237 56.2321C88.082 56.2264 87.9411 56.2566 87.8124 56.3203C87.6838 56.384 87.571 56.4795 87.4834 56.5988C87.3958 56.7182 87.3358 56.8581 87.3084 57.007C87.281 57.1559 87.287 57.3097 87.3257 57.4557Z"
          fill="#677A89"
        />
        <path
          d="M91.0001 57.4562C91.1007 57.8194 91.3038 58.1403 91.5815 58.375C91.8592 58.6097 92.198 58.7467 92.5513 58.7672C93.2044 58.7672 93.531 58.1554 93.3677 57.4562C93.2638 57.1025 93.0575 56.7933 92.7786 56.5734C92.4998 56.3534 92.1629 56.2341 91.8165 56.2327C91.6753 56.2198 91.5334 56.2464 91.4048 56.31C91.2761 56.3737 91.1651 56.472 91.0827 56.5955C91.0004 56.7189 90.9494 56.8632 90.9349 57.0141C90.9205 57.165 90.9429 57.3174 91.0001 57.4562V57.4562Z"
          fill="#677A89"
        />
        <path
          d="M94.6734 57.4562C94.7585 57.8124 94.9463 58.1308 95.2101 58.3662C95.4739 58.6015 95.8003 58.7419 96.1429 58.7672C96.2941 58.7784 96.4456 58.7485 96.5831 58.6802C96.7207 58.6119 96.8398 58.5075 96.9294 58.3766C97.0191 58.2458 97.0763 58.0927 97.0958 57.9318C97.1153 57.7708 97.0964 57.6072 97.0409 57.4562C96.9267 57.1094 96.7177 56.8073 96.4411 56.5891C96.1646 56.3709 95.8332 56.2467 95.4899 56.2327C95.3487 56.2198 95.2067 56.2464 95.078 56.31C94.9494 56.3737 94.8385 56.472 94.7561 56.5955C94.6737 56.7189 94.6228 56.8632 94.6083 57.0141C94.5938 57.165 94.6162 57.3174 94.6734 57.4562V57.4562Z"
          fill="#677A89"
        />
        <path
          d="M98.2657 57.4557C98.3663 57.8188 98.5693 58.1398 98.8471 58.3745C99.1248 58.6092 99.4635 58.7462 99.8168 58.7666C100.47 58.7666 100.796 58.1549 100.633 57.4557C100.545 57.109 100.354 56.8023 100.089 56.5817C99.824 56.361 99.4993 56.2384 99.1637 56.2321C99.022 56.2264 98.8811 56.2566 98.7524 56.3203C98.6237 56.384 98.5109 56.4795 98.4233 56.5988C98.3357 56.7182 98.2758 56.8581 98.2484 57.007C98.221 57.1559 98.2269 57.3097 98.2657 57.4557Z"
          fill="#677A89"
        />
        <path
          d="M101.939 57.4562C102.024 57.8124 102.212 58.1308 102.476 58.3662C102.74 58.6015 103.066 58.7419 103.409 58.7672C103.56 58.7784 103.711 58.7485 103.849 58.6802C103.986 58.6119 104.105 58.5075 104.195 58.3766C104.285 58.2458 104.342 58.0927 104.361 57.9318C104.381 57.7708 104.362 57.6072 104.307 57.4562C104.203 57.1025 103.996 56.7933 103.718 56.5734C103.439 56.3534 103.102 56.2341 102.755 56.2327C102.614 56.2198 102.472 56.2464 102.344 56.31C102.215 56.3737 102.104 56.472 102.022 56.5955C101.939 56.7189 101.888 56.8632 101.874 57.0141C101.859 57.165 101.882 57.3174 101.939 57.4562V57.4562Z"
          fill="#677A89"
        />
        <path
          d="M105.531 57.4557C105.65 57.807 105.859 58.1157 106.133 58.3473C106.407 58.5789 106.736 58.7241 107.082 58.7666C107.227 58.7632 107.368 58.7225 107.495 58.648C107.622 58.5735 107.729 58.4674 107.81 58.3388C107.89 58.2101 107.94 58.0627 107.955 57.9089C107.971 57.7552 107.951 57.5997 107.899 57.4557C107.811 57.109 107.62 56.8023 107.355 56.5817C107.09 56.361 106.765 56.2384 106.429 56.2321C106.288 56.2264 106.147 56.2566 106.018 56.3203C105.889 56.384 105.777 56.4795 105.689 56.5988C105.601 56.7182 105.541 56.8581 105.514 57.007C105.487 57.1559 105.493 57.3097 105.531 57.4557V57.4557Z"
          fill="#677A89"
        />
        <path
          d="M109.206 57.4562C109.306 57.8194 109.509 58.1403 109.787 58.375C110.065 58.6097 110.404 58.7467 110.757 58.7672C111.41 58.7672 111.736 58.1554 111.573 57.4562C111.469 57.1025 111.263 56.7933 110.984 56.5734C110.705 56.3534 110.368 56.2341 110.022 56.2327C109.881 56.2198 109.739 56.2464 109.61 56.31C109.482 56.3737 109.371 56.472 109.288 56.5955C109.206 56.7189 109.155 56.8632 109.141 57.0141C109.126 57.165 109.148 57.3174 109.206 57.4562V57.4562Z"
          fill="#677A89"
        />
        <path
          d="M112.552 57.4562C112.637 57.8124 112.825 58.1308 113.088 58.3662C113.352 58.6015 113.679 58.7419 114.021 58.7672C114.173 58.7784 114.324 58.7485 114.461 58.6802C114.599 58.6119 114.718 58.5075 114.808 58.3766C114.897 58.2458 114.955 58.0927 114.974 57.9318C114.994 57.7708 114.975 57.6072 114.919 57.4562C114.805 57.1094 114.596 56.8073 114.32 56.5891C114.043 56.3709 113.712 56.2467 113.368 56.2327C113.227 56.2198 113.085 56.2464 112.956 56.31C112.828 56.3737 112.717 56.472 112.634 56.5955C112.552 56.7189 112.501 56.8632 112.487 57.0141C112.472 57.165 112.495 57.3174 112.552 57.4562V57.4562Z"
          fill="#677A89"
        />
        <path
          d="M116.471 57.4557C116.572 57.8188 116.775 58.1398 117.053 58.3745C117.33 58.6092 117.669 58.7462 118.022 58.7666C118.167 58.7632 118.308 58.7225 118.435 58.648C118.561 58.5735 118.669 58.4674 118.749 58.3388C118.83 58.2101 118.88 58.0627 118.895 57.9089C118.911 57.7552 118.891 57.5997 118.839 57.4557C118.75 57.109 118.559 56.8023 118.295 56.5817C118.03 56.361 117.705 56.2384 117.369 56.2321C117.228 56.2264 117.087 56.2566 116.958 56.3203C116.829 56.384 116.716 56.4795 116.629 56.5988C116.541 56.7182 116.481 56.8581 116.454 57.007C116.427 57.1559 116.432 57.3097 116.471 57.4557Z"
          fill="#677A89"
        />
        <path
          d="M120.144 57.4562C120.229 57.8124 120.417 58.1308 120.68 58.3662C120.944 58.6015 121.271 58.7419 121.613 58.7672C121.764 58.7784 121.916 58.7485 122.053 58.6802C122.191 58.6119 122.31 58.5075 122.4 58.3766C122.489 58.2458 122.547 58.0927 122.566 57.9318C122.586 57.7708 122.567 57.6072 122.511 57.4562C122.407 57.1025 122.201 56.7933 121.922 56.5734C121.643 56.3534 121.306 56.2341 120.96 56.2327C120.819 56.2198 120.677 56.2464 120.548 56.31C120.42 56.3737 120.309 56.472 120.226 56.5955C120.144 56.7189 120.093 56.8632 120.078 57.0141C120.064 57.165 120.087 57.3174 120.144 57.4562V57.4562Z"
          fill="#677A89"
        />
        <path
          d="M123.736 57.4557C123.855 57.807 124.063 58.1157 124.337 58.3473C124.611 58.5789 124.94 58.7241 125.287 58.7666C125.438 58.7779 125.59 58.748 125.727 58.6797C125.865 58.6114 125.984 58.507 126.074 58.3761C126.163 58.2452 126.22 58.0922 126.24 57.9313C126.259 57.7703 126.241 57.6067 126.185 57.4557C126.071 57.1088 125.862 56.8067 125.585 56.5886C125.309 56.3704 124.977 56.2462 124.634 56.2321C124.492 56.2264 124.351 56.2566 124.223 56.3203C124.094 56.384 123.981 56.4795 123.894 56.5988C123.806 56.7182 123.746 56.8581 123.719 57.007C123.691 57.1559 123.697 57.3097 123.736 57.4557V57.4557Z"
          fill="#677A89"
        />
        <path
          d="M127.41 57.4546C127.511 57.8178 127.714 58.1387 127.992 58.3734C128.269 58.6082 128.608 58.7451 128.962 58.7656C129.615 58.7656 129.941 58.1538 129.778 57.4546C129.69 57.1079 129.499 56.8012 129.234 56.5806C128.969 56.36 128.644 56.2373 128.308 56.2311C128.161 56.2002 128.008 56.2135 127.867 56.2696C127.727 56.3257 127.603 56.4223 127.51 56.5489C127.417 56.6754 127.359 56.8269 127.341 56.9867C127.323 57.1466 127.347 57.3085 127.41 57.4546Z"
          fill="#677A89"
        />
        <path
          d="M131.084 57.4562C131.169 57.8124 131.357 58.1308 131.621 58.3662C131.885 58.6015 132.211 58.7419 132.554 58.7672C132.705 58.7784 132.856 58.7485 132.994 58.6802C133.131 58.6119 133.25 58.5075 133.34 58.3766C133.43 58.2458 133.487 58.0927 133.506 57.9318C133.526 57.7708 133.507 57.6072 133.452 57.4562C133.337 57.1094 133.128 56.8073 132.852 56.5891C132.575 56.3709 132.244 56.2467 131.901 56.2327C131.759 56.2198 131.617 56.2464 131.489 56.31C131.36 56.3737 131.249 56.472 131.167 56.5955C131.084 56.7189 131.033 56.8632 131.019 57.0141C131.004 57.165 131.027 57.3174 131.084 57.4562Z"
          fill="#677A89"
        />
        <path
          d="M134.676 57.4557C134.795 57.807 135.003 58.1157 135.277 58.3473C135.551 58.5789 135.88 58.7241 136.227 58.7666C136.371 58.7632 136.513 58.7225 136.639 58.648C136.766 58.5735 136.874 58.4674 136.954 58.3388C137.034 58.2101 137.084 58.0627 137.1 57.9089C137.115 57.7552 137.096 57.5997 137.043 57.4557C136.955 57.109 136.764 56.8023 136.499 56.5817C136.234 56.361 135.909 56.2384 135.574 56.2321C135.432 56.2264 135.291 56.2566 135.163 56.3203C135.034 56.384 134.921 56.4795 134.833 56.5988C134.746 56.7182 134.686 56.8581 134.659 57.007C134.631 57.1559 134.637 57.3097 134.676 57.4557Z"
          fill="#677A89"
        />
        <path
          d="M138.35 57.4562C138.435 57.8124 138.623 58.1308 138.886 58.3662C139.15 58.6015 139.477 58.7419 139.819 58.7672C139.97 58.7784 140.122 58.7485 140.259 58.6802C140.397 58.6119 140.516 58.5075 140.606 58.3766C140.695 58.2458 140.753 58.0927 140.772 57.9318C140.792 57.7708 140.773 57.6072 140.717 57.4562C140.613 57.1025 140.407 56.7933 140.128 56.5734C139.849 56.3534 139.512 56.2341 139.166 56.2327C139.025 56.2198 138.883 56.2464 138.754 56.31C138.626 56.3737 138.515 56.472 138.432 56.5955C138.35 56.7189 138.299 56.8632 138.285 57.0141C138.27 57.165 138.293 57.3174 138.35 57.4562V57.4562Z"
          fill="#677A89"
        />
        <path
          d="M71.7328 57.4557C71.6445 57.109 71.4535 56.8023 71.1886 56.5817C70.9236 56.361 70.5989 56.2384 70.2633 56.2321C70.1216 56.2264 69.9807 56.2566 69.852 56.3203C69.7233 56.384 69.6105 56.4795 69.5229 56.5988C69.4353 56.7182 69.3754 56.8581 69.348 57.007C69.3206 57.1559 69.3265 57.3097 69.3653 57.4557C69.4659 57.8188 69.669 58.1398 69.9467 58.3745C70.2244 58.6092 70.5631 58.7462 70.9164 58.7666C71.0608 58.7632 71.2023 58.7225 71.3289 58.648C71.4555 58.5735 71.5634 58.4674 71.6436 58.3388C71.7237 58.2101 71.7736 58.0627 71.7892 57.9089C71.8047 57.7552 71.7854 57.5997 71.7328 57.4557V57.4557Z"
          fill="#677A89"
        />
        <path
          d="M72.795 57.4562C72.8801 57.8124 73.0678 58.1308 73.3317 58.3662C73.5955 58.6015 73.9219 58.7419 74.2645 58.7672C74.4157 58.7784 74.5672 58.7485 74.7047 58.6802C74.8423 58.6119 74.9614 58.5075 75.051 58.3766C75.1407 58.2458 75.1979 58.0927 75.2174 57.9318C75.2369 57.7708 75.218 57.6072 75.1625 57.4562C75.0586 57.1025 74.8524 56.7933 74.5736 56.5734C74.2947 56.3534 73.9578 56.2341 73.6114 56.2327C73.4702 56.2198 73.3283 56.2464 73.1996 56.31C73.071 56.3737 72.96 56.472 72.8777 56.5955C72.7953 56.7189 72.7443 56.8632 72.7299 57.0141C72.7154 57.165 72.7378 57.3174 72.795 57.4562V57.4562Z"
          fill="#677A89"
        />
        <path
          d="M76.3858 57.4557C76.505 57.807 76.7132 58.1157 76.9872 58.3473C77.2612 58.5789 77.5901 58.7241 77.937 58.7666C78.0882 58.7779 78.2396 58.748 78.3771 58.6797C78.5146 58.6114 78.6338 58.507 78.7234 58.3761C78.8131 58.2452 78.8704 58.0922 78.8899 57.9313C78.9094 57.7703 78.8905 57.6067 78.835 57.4557C78.7207 57.1088 78.5117 56.8067 78.2351 56.5886C77.9585 56.3704 77.6271 56.2462 77.2838 56.2321C77.1421 56.2264 77.0012 56.2566 76.8725 56.3203C76.7438 56.384 76.631 56.4795 76.5434 56.5988C76.4558 56.7182 76.3959 56.8581 76.3685 57.007C76.3411 57.1559 76.347 57.3097 76.3858 57.4557Z"
          fill="#677A89"
        />
        <path
          d="M79.897 57.4557C79.9976 57.8188 80.2007 58.1398 80.4784 58.3745C80.7561 58.6092 81.0949 58.7462 81.4482 58.7666C82.1013 58.7666 82.4278 58.1549 82.2646 57.4557C82.1763 57.109 81.9853 56.8023 81.7203 56.5817C81.4553 56.361 81.1307 56.2384 80.7951 56.2321C80.6534 56.2264 80.5124 56.2566 80.3837 56.3203C80.255 56.384 80.1423 56.4795 80.0547 56.5988C79.9671 56.7182 79.9071 56.8581 79.8797 57.007C79.8523 57.1559 79.8583 57.3097 79.897 57.4557Z"
          fill="#677A89"
        />
        <path
          d="M87.0802 61.3885C86.9952 61.0323 86.8074 60.7139 86.5435 60.4785C86.2797 60.2432 85.9533 60.1028 85.6107 60.0775C85.4595 60.0662 85.3081 60.0962 85.1705 60.1645C85.033 60.2327 84.9139 60.3372 84.8242 60.468C84.7346 60.5989 84.6774 60.752 84.6579 60.9129C84.6384 61.0738 84.6572 61.2374 84.7127 61.3885C84.8166 61.7422 85.0229 62.0513 85.3017 62.2713C85.5805 62.4912 85.9175 62.6106 86.2638 62.612C86.405 62.6249 86.5469 62.5983 86.6756 62.5346C86.8042 62.471 86.9152 62.3726 86.9976 62.2492C87.08 62.1257 87.1309 61.9815 87.1454 61.8306C87.1599 61.6796 87.1374 61.5273 87.0802 61.3885V61.3885Z"
          fill="#677A89"
        />
        <path
          d="M90.7548 61.3881C90.6542 61.0249 90.4512 60.704 90.1735 60.4693C89.8957 60.2346 89.557 60.0976 89.2037 60.0771C88.5506 60.0771 88.224 60.6889 88.3873 61.3881C88.4756 61.7348 88.6665 62.0415 88.9315 62.2621C89.1965 62.4827 89.5212 62.6054 89.8568 62.6116C89.9985 62.6174 90.1394 62.5872 90.2681 62.5235C90.3968 62.4597 90.5095 62.3643 90.5971 62.2449C90.6847 62.1256 90.7447 61.9857 90.7721 61.8368C90.7995 61.6878 90.7936 61.5341 90.7548 61.3881Z"
          fill="#677A89"
        />
        <path
          d="M94.3473 61.3885C94.2622 61.0323 94.0745 60.7139 93.8107 60.4785C93.5469 60.2432 93.2204 60.1028 92.8778 60.0775C92.7265 60.0662 92.5751 60.0962 92.4376 60.1645C92.3001 60.2327 92.181 60.3372 92.0913 60.468C92.0017 60.5989 91.9444 60.752 91.9249 60.9129C91.9054 61.0738 91.9243 61.2374 91.9798 61.3885C92.0941 61.7353 92.303 62.0374 92.5796 62.2556C92.8562 62.4738 93.1876 62.5979 93.5309 62.612C93.6721 62.6249 93.814 62.5983 93.9427 62.5346C94.0713 62.471 94.1823 62.3726 94.2647 62.2492C94.3471 62.1257 94.398 61.9815 94.4125 61.8306C94.427 61.6796 94.4045 61.5273 94.3473 61.3885V61.3885Z"
          fill="#677A89"
        />
        <path
          d="M98.02 61.3881C97.9308 61.0181 97.7308 60.6896 97.4507 60.4529C97.1706 60.2161 96.8257 60.0841 96.4688 60.0771C95.8157 60.0771 95.4892 60.6889 95.6524 61.3881C95.7564 61.7418 95.9626 62.051 96.2415 62.2709C96.5203 62.4909 96.8573 62.6102 97.2036 62.6116C97.3448 62.6245 97.4867 62.5979 97.6154 62.5343C97.744 62.4706 97.855 62.3723 97.9374 62.2488C98.0198 62.1254 98.0706 61.9811 98.0851 61.8302C98.0996 61.6793 98.0772 61.5269 98.02 61.3881V61.3881Z"
          fill="#677A89"
        />
        <path
          d="M101.695 61.3881C101.576 61.0368 101.368 60.7281 101.094 60.4965C100.82 60.2649 100.491 60.1197 100.144 60.0771C99.9997 60.0806 99.8581 60.1213 99.7316 60.1958C99.605 60.2703 99.497 60.3764 99.4169 60.505C99.3368 60.6337 99.2869 60.7811 99.2713 60.9349C99.2558 61.0886 99.2751 61.2441 99.3276 61.3881C99.4159 61.7348 99.6069 62.0415 99.8719 62.2621C100.137 62.4827 100.461 62.6054 100.797 62.6116C100.939 62.6174 101.08 62.5872 101.208 62.5235C101.337 62.4597 101.45 62.3643 101.538 62.2449C101.625 62.1256 101.685 61.9857 101.712 61.8368C101.74 61.6878 101.734 61.5341 101.695 61.3881Z"
          fill="#677A89"
        />
        <path
          d="M105.286 61.3885C105.201 61.0323 105.013 60.7139 104.749 60.4785C104.485 60.2432 104.159 60.1028 103.816 60.0775C103.665 60.0662 103.514 60.0962 103.376 60.1645C103.239 60.2327 103.119 60.3372 103.03 60.468C102.94 60.5989 102.883 60.752 102.863 60.9129C102.844 61.0738 102.863 61.2374 102.918 61.3885C103.033 61.7353 103.242 62.0374 103.518 62.2556C103.795 62.4738 104.126 62.5979 104.469 62.612C104.611 62.6249 104.753 62.5983 104.881 62.5346C105.01 62.471 105.121 62.3726 105.203 62.2492C105.286 62.1257 105.336 61.9815 105.351 61.8306C105.365 61.6796 105.343 61.5273 105.286 61.3885V61.3885Z"
          fill="#677A89"
        />
        <path
          d="M108.96 61.3881C108.859 61.0249 108.656 60.704 108.379 60.4693C108.101 60.2346 107.762 60.0976 107.409 60.0771C106.756 60.0771 106.429 60.6889 106.592 61.3881C106.681 61.7348 106.872 62.0415 107.137 62.2621C107.402 62.4827 107.726 62.6054 108.062 62.6116C108.204 62.6174 108.345 62.5872 108.473 62.5235C108.602 62.4597 108.715 62.3643 108.802 62.2449C108.89 62.1256 108.95 61.9857 108.977 61.8368C109.005 61.6878 108.999 61.5341 108.96 61.3881Z"
          fill="#677A89"
        />
        <path
          d="M112.552 61.3885C112.433 61.0372 112.225 60.7284 111.951 60.4969C111.677 60.2653 111.348 60.1201 111.001 60.0775C110.85 60.0662 110.699 60.0962 110.561 60.1645C110.424 60.2327 110.304 60.3372 110.215 60.468C110.125 60.5989 110.068 60.752 110.048 60.9129C110.029 61.0738 110.048 61.2374 110.103 61.3885C110.218 61.7353 110.427 62.0374 110.703 62.2556C110.98 62.4738 111.311 62.5979 111.654 62.612C111.796 62.6178 111.937 62.5876 112.066 62.5239C112.194 62.4601 112.307 62.3647 112.395 62.2453C112.482 62.126 112.542 61.9861 112.57 61.8372C112.597 61.6882 112.591 61.5345 112.552 61.3885Z"
          fill="#677A89"
        />
        <path
          d="M116.225 61.3885C116.14 61.0323 115.952 60.7139 115.689 60.4785C115.425 60.2432 115.098 60.1028 114.756 60.0775C114.604 60.0662 114.453 60.0962 114.316 60.1645C114.178 60.2327 114.059 60.3372 113.969 60.468C113.88 60.5989 113.822 60.752 113.803 60.9129C113.783 61.0738 113.802 61.2374 113.858 61.3885C113.962 61.7422 114.168 62.0513 114.447 62.2713C114.726 62.4912 115.062 62.6106 115.409 62.612C115.55 62.6249 115.692 62.5983 115.821 62.5346C115.949 62.471 116.06 62.3726 116.143 62.2492C116.225 62.1257 116.276 61.9815 116.29 61.8306C116.305 61.6796 116.282 61.5273 116.225 61.3885V61.3885Z"
          fill="#677A89"
        />
        <path
          d="M119.9 61.3881C119.8 61.0249 119.597 60.704 119.319 60.4693C119.041 60.2346 118.702 60.0976 118.349 60.0771C118.205 60.0806 118.063 60.1213 117.937 60.1958C117.81 60.2703 117.702 60.3764 117.622 60.505C117.542 60.6337 117.492 60.7811 117.476 60.9349C117.461 61.0886 117.48 61.2441 117.533 61.3881C117.621 61.7348 117.812 62.0415 118.077 62.2621C118.342 62.4827 118.667 62.6054 119.002 62.6116C119.144 62.6174 119.285 62.5872 119.414 62.5235C119.542 62.4597 119.655 62.3643 119.743 62.2449C119.83 62.1256 119.89 61.9857 119.918 61.8368C119.945 61.6878 119.939 61.5341 119.9 61.3881V61.3881Z"
          fill="#677A89"
        />
        <path
          d="M123.491 61.3885C123.406 61.0323 123.218 60.7139 122.954 60.4785C122.69 60.2432 122.364 60.1028 122.021 60.0775C121.87 60.0662 121.719 60.0962 121.581 60.1645C121.444 60.2327 121.325 60.3372 121.235 60.468C121.145 60.5989 121.088 60.752 121.068 60.9129C121.049 61.0738 121.068 61.2374 121.123 61.3885C121.238 61.7353 121.447 62.0374 121.723 62.2556C122 62.4738 122.331 62.5979 122.674 62.612C122.816 62.6249 122.958 62.5983 123.086 62.5346C123.215 62.471 123.326 62.3726 123.408 62.2492C123.491 62.1257 123.542 61.9815 123.556 61.8306C123.571 61.6796 123.548 61.5273 123.491 61.3885V61.3885Z"
          fill="#677A89"
        />
        <path
          d="M127.165 61.3881C127.065 61.0249 126.862 60.704 126.584 60.4693C126.306 60.2346 125.968 60.0976 125.614 60.0771C124.961 60.0771 124.635 60.6889 124.798 61.3881C124.902 61.7418 125.108 62.051 125.387 62.2709C125.666 62.4909 126.003 62.6102 126.349 62.6116C126.49 62.6245 126.632 62.5979 126.761 62.5343C126.89 62.4706 127 62.3723 127.083 62.2488C127.165 62.1254 127.216 61.9811 127.231 61.8302C127.245 61.6793 127.223 61.5269 127.165 61.3881V61.3881Z"
          fill="#677A89"
        />
        <path
          d="M130.839 61.3881C130.72 61.0368 130.511 60.7281 130.237 60.4965C129.963 60.2649 129.634 60.1197 129.288 60.0771C129.143 60.0806 129.002 60.1213 128.875 60.1958C128.749 60.2703 128.641 60.3764 128.56 60.505C128.48 60.6337 128.43 60.7811 128.415 60.9349C128.399 61.0886 128.419 61.2441 128.471 61.3881C128.559 61.7348 128.75 62.0415 129.015 62.2621C129.28 62.4827 129.605 62.6054 129.941 62.6116C130.082 62.6174 130.223 62.5872 130.352 62.5235C130.481 62.4597 130.593 62.3643 130.681 62.2449C130.769 62.1256 130.829 61.9857 130.856 61.8368C130.883 61.6878 130.877 61.5341 130.839 61.3881V61.3881Z"
          fill="#677A89"
        />
        <path
          d="M134.431 61.3885C134.346 61.0323 134.158 60.7139 133.894 60.4785C133.63 60.2432 133.304 60.1028 132.961 60.0775C132.81 60.0662 132.659 60.0962 132.521 60.1645C132.384 60.2327 132.265 60.3372 132.175 60.468C132.085 60.5989 132.028 60.752 132.008 60.9129C131.989 61.0738 132.008 61.2374 132.063 61.3885C132.167 61.7422 132.374 62.0513 132.652 62.2713C132.931 62.4912 133.268 62.6106 133.614 62.612C133.756 62.6249 133.898 62.5983 134.026 62.5346C134.155 62.471 134.266 62.3726 134.348 62.2492C134.431 62.1257 134.481 61.9815 134.496 61.8306C134.51 61.6796 134.488 61.5273 134.431 61.3885V61.3885Z"
          fill="#677A89"
        />
        <path
          d="M138.105 61.3881C138.004 61.0249 137.801 60.704 137.524 60.4693C137.246 60.2346 136.907 60.0976 136.554 60.0771C135.901 60.0771 135.574 60.6889 135.737 61.3881C135.826 61.7348 136.017 62.0415 136.282 62.2621C136.547 62.4827 136.871 62.6054 137.207 62.6116C137.349 62.6174 137.49 62.5872 137.618 62.5235C137.747 62.4597 137.86 62.3643 137.947 62.2449C138.035 62.1256 138.095 61.9857 138.122 61.8368C138.15 61.6878 138.144 61.5341 138.105 61.3881Z"
          fill="#677A89"
        />
        <path
          d="M139.33 61.3885C139.444 61.7353 139.653 62.0374 139.93 62.2556C140.206 62.4738 140.538 62.5979 140.881 62.612C141.022 62.6249 141.164 62.5983 141.293 62.5346C141.421 62.471 141.532 62.3726 141.615 62.2492C141.697 62.1257 141.748 61.9815 141.763 61.8306C141.777 61.6796 141.755 61.5273 141.697 61.3885C141.612 61.0323 141.425 60.7139 141.161 60.4785C140.897 60.2432 140.571 60.1028 140.228 60.0775C140.077 60.0662 139.925 60.0962 139.788 60.1645C139.65 60.2327 139.531 60.3372 139.441 60.468C139.352 60.5989 139.295 60.752 139.275 60.9129C139.256 61.0738 139.274 61.2374 139.33 61.3885Z"
          fill="#677A89"
        />
        <path
          d="M72.5496 61.3881C72.4491 61.0249 72.246 60.704 71.9683 60.4693C71.6906 60.2346 71.3519 60.0976 70.9986 60.0771C70.8542 60.0806 70.7126 60.1213 70.586 60.1958C70.4595 60.2703 70.3515 60.3764 70.2714 60.505C70.1913 60.6337 70.1414 60.7811 70.1258 60.9349C70.1103 61.0886 70.1296 61.2441 70.1821 61.3881C70.2704 61.7348 70.4614 62.0415 70.7264 62.2621C70.9913 62.4827 71.316 62.6054 71.6516 62.6116C71.7933 62.6174 71.9343 62.5872 72.0629 62.5235C72.1916 62.4597 72.3044 62.3643 72.392 62.2449C72.4796 62.1256 72.5396 61.9857 72.567 61.8368C72.5944 61.6878 72.5884 61.5341 72.5496 61.3881V61.3881Z"
          fill="#677A89"
        />
        <path
          d="M76.1422 61.3885C76.0572 61.0323 75.8694 60.7139 75.6056 60.4785C75.3418 60.2432 75.0154 60.1028 74.6728 60.0775C74.5215 60.0662 74.3701 60.0962 74.2326 60.1645C74.0951 60.2327 73.9759 60.3372 73.8863 60.468C73.7966 60.5989 73.7394 60.752 73.7199 60.9129C73.7004 61.0738 73.7192 61.2374 73.7747 61.3885C73.889 61.7353 74.098 62.0374 74.3745 62.2556C74.6511 62.4738 74.9826 62.5979 75.3259 62.612C75.4671 62.6249 75.609 62.5983 75.7376 62.5346C75.8663 62.471 75.9773 62.3726 76.0597 62.2492C76.142 62.1257 76.1929 61.9815 76.2074 61.8306C76.2219 61.6796 76.1994 61.5273 76.1422 61.3885V61.3885Z"
          fill="#677A89"
        />
        <path
          d="M79.8964 61.3881C79.7959 61.0249 79.5928 60.704 79.3151 60.4693C79.0374 60.2346 78.6986 60.0976 78.3453 60.0771C77.6922 60.0771 77.3656 60.6889 77.5289 61.3881C77.6328 61.7418 77.8391 62.051 78.1179 62.2709C78.3968 62.4909 78.7337 62.6102 79.0801 62.6116C79.2213 62.6245 79.3632 62.5979 79.4918 62.5343C79.6205 62.4706 79.7315 62.3723 79.8138 62.2488C79.8962 62.1254 79.9471 61.9811 79.9616 61.8302C79.9761 61.6793 79.9536 61.5269 79.8964 61.3881V61.3881Z"
          fill="#677A89"
        />
        <path
          d="M83.4877 61.3881C83.3685 61.0368 83.1603 60.7281 82.8863 60.4965C82.6123 60.2649 82.2834 60.1197 81.9365 60.0771C81.7921 60.0806 81.6506 60.1213 81.524 60.1958C81.3974 60.2703 81.2895 60.3764 81.2094 60.505C81.1293 60.6337 81.0793 60.7811 81.0638 60.9349C81.0483 61.0886 81.0676 61.2441 81.1202 61.3881C81.2085 61.7348 81.3994 62.0415 81.6644 62.2621C81.9293 62.4827 82.254 62.6054 82.5897 62.6116C82.7314 62.6174 82.8723 62.5872 83.001 62.5235C83.1297 62.4597 83.2424 62.3643 83.33 62.2449C83.4176 62.1256 83.4775 61.9857 83.5049 61.8368C83.5324 61.6878 83.5265 61.5341 83.4877 61.3881V61.3881Z"
          fill="#677A89"
        />
        <path
          d="M87.2436 66.5455C87.4674 66.5236 87.6754 66.4127 87.8265 66.2348C87.9776 66.0568 88.0609 65.8247 88.06 65.5841C87.9457 65.2373 87.7368 64.9352 87.4602 64.717C87.1836 64.4988 86.8521 64.3747 86.5088 64.3606C86.3676 64.3477 86.2257 64.3744 86.0971 64.438C85.9684 64.5016 85.8574 64.5999 85.7751 64.7234C85.6927 64.8468 85.6418 64.9911 85.6273 65.1421C85.6128 65.293 85.6353 65.4453 85.6925 65.5841C85.8353 65.8888 86.0582 66.1422 86.3338 66.313C86.6094 66.4838 86.9257 66.5646 87.2436 66.5455V66.5455Z"
          fill="#677A89"
        />
        <path
          d="M90.9178 66.545C91.0623 66.5415 91.2038 66.5008 91.3304 66.4263C91.4569 66.3518 91.5648 66.2457 91.645 66.1171C91.7251 65.9884 91.7751 65.841 91.7906 65.6872C91.8061 65.5335 91.7868 65.378 91.7342 65.234C91.6459 64.8873 91.455 64.5806 91.19 64.36C90.9251 64.1394 90.6004 64.0167 90.2647 64.0105C90.123 64.0047 89.9821 64.0349 89.8535 64.0986C89.7248 64.1624 89.612 64.2578 89.5244 64.3772C89.4368 64.4965 89.3768 64.6364 89.3494 64.7853C89.322 64.9343 89.328 65.088 89.3668 65.234C89.4859 65.5853 89.6942 65.894 89.9682 66.1256C90.2422 66.3572 90.571 66.5024 90.9178 66.545V66.545Z"
          fill="#677A89"
        />
        <path
          d="M94.5918 66.5445C95.2449 66.5445 95.5715 65.9327 95.4082 65.2335C95.3043 64.8798 95.098 64.5707 94.8192 64.3507C94.5403 64.1308 94.2034 64.0115 93.857 64.01C93.7158 63.9971 93.5739 64.0238 93.4453 64.0874C93.3166 64.151 93.2056 64.2494 93.1233 64.3728C93.0409 64.4963 92.9899 64.6406 92.9755 64.7915C92.961 64.9424 92.9835 65.0947 93.0407 65.2335C93.1298 65.6035 93.3298 65.932 93.6099 66.1688C93.89 66.4055 94.235 66.5375 94.5918 66.5445V66.5445Z"
          fill="#677A89"
        />
        <path
          d="M98.1835 66.5445C98.3347 66.5558 98.4862 66.5258 98.6237 66.4576C98.7612 66.3893 98.8803 66.2848 98.97 66.154C99.0596 66.0231 99.1169 65.8701 99.1364 65.7091C99.1559 65.5482 99.137 65.3846 99.0815 65.2336C98.9672 64.8867 98.7583 64.5846 98.4817 64.3664C98.2051 64.1483 97.8737 64.0241 97.5304 64.01C97.3892 63.9971 97.2472 64.0238 97.1186 64.0874C96.9899 64.151 96.879 64.2494 96.7966 64.3728C96.7142 64.4963 96.6633 64.6406 96.6488 64.7915C96.6343 64.9424 96.6568 65.0947 96.714 65.2336C96.799 65.5897 96.9868 65.9082 97.2506 66.1435C97.5144 66.3789 97.8409 66.5192 98.1835 66.5445V66.5445Z"
          fill="#677A89"
        />
        <path
          d="M101.858 66.545C102.511 66.545 102.837 65.9332 102.674 65.234C102.586 64.8873 102.395 64.5806 102.13 64.36C101.865 64.1394 101.54 64.0167 101.205 64.0105C101.063 64.0047 100.922 64.0349 100.793 64.0986C100.665 64.1624 100.552 64.2578 100.464 64.3772C100.377 64.4965 100.317 64.6364 100.289 64.7853C100.262 64.9343 100.268 65.088 100.307 65.234C100.407 65.5972 100.61 65.9181 100.888 66.1528C101.166 66.3875 101.505 66.5245 101.858 66.545V66.545Z"
          fill="#677A89"
        />
        <path
          d="M105.45 66.5452C105.602 66.5565 105.753 66.5266 105.891 66.4583C106.028 66.39 106.147 66.2856 106.237 66.1547C106.327 66.0238 106.384 65.8708 106.403 65.7099C106.423 65.5489 106.404 65.3853 106.348 65.2343C106.244 64.8806 106.038 64.5714 105.759 64.3515C105.481 64.1315 105.144 64.0122 104.797 64.0107C104.633 64.0274 104.477 64.0971 104.35 64.2107C104.223 64.3243 104.132 64.4764 104.087 64.6469C104.043 64.8174 104.048 64.9983 104.102 65.1656C104.156 65.3329 104.256 65.4788 104.389 65.5839C104.489 65.8179 104.636 66.0253 104.819 66.1915C105.003 66.3577 105.218 66.4785 105.45 66.5452V66.5452Z"
          fill="#677A89"
        />
        <path
          d="M109.123 66.545C109.267 66.5415 109.409 66.5008 109.535 66.4263C109.662 66.3518 109.77 66.2457 109.85 66.1171C109.93 65.9884 109.98 65.841 109.996 65.6872C110.011 65.5335 109.992 65.378 109.939 65.234C109.851 64.8873 109.66 64.5806 109.395 64.36C109.13 64.1394 108.805 64.0167 108.47 64.0105C108.328 64.0047 108.187 64.0349 108.059 64.0986C107.93 64.1624 107.817 64.2578 107.729 64.3772C107.642 64.4965 107.582 64.6364 107.555 64.7853C107.527 64.9343 107.533 65.088 107.572 65.234C107.691 65.5853 107.899 65.894 108.173 66.1256C108.447 66.3572 108.776 66.5024 109.123 66.545Z"
          fill="#677A89"
        />
        <path
          d="M112.553 66.5445C113.206 66.5445 113.532 65.9327 113.369 65.2335C113.265 64.8798 113.059 64.5707 112.78 64.3507C112.501 64.1308 112.164 64.0115 111.818 64.01C111.677 63.9971 111.535 64.0238 111.406 64.0874C111.278 64.151 111.167 64.2494 111.084 64.3728C111.002 64.4963 110.951 64.6406 110.936 64.7915C110.922 64.9424 110.944 65.0947 111.002 65.2335C111.102 65.5967 111.305 65.9177 111.583 66.1524C111.861 66.3871 112.199 66.524 112.553 66.5445V66.5445Z"
          fill="#677A89"
        />
        <path
          d="M116.389 66.5445C116.54 66.5558 116.692 66.5258 116.829 66.4576C116.967 66.3893 117.086 66.2848 117.176 66.154C117.265 66.0231 117.322 65.8701 117.342 65.7091C117.361 65.5482 117.343 65.3846 117.287 65.2336C117.173 64.8867 116.964 64.5846 116.687 64.3664C116.411 64.1483 116.079 64.0241 115.736 64.01C115.595 63.9971 115.453 64.0238 115.324 64.0874C115.196 64.151 115.085 64.2494 115.002 64.3728C114.92 64.4963 114.869 64.6406 114.854 64.7915C114.84 64.9424 114.862 65.0947 114.92 65.2336C115.005 65.5897 115.192 65.9082 115.456 66.1435C115.72 66.3789 116.046 66.5192 116.389 66.5445V66.5445Z"
          fill="#677A89"
        />
        <path
          d="M120.063 66.545C120.256 66.4863 120.426 66.3597 120.545 66.1853C120.663 66.011 120.723 65.7991 120.716 65.5836C120.627 65.2369 120.436 64.9302 120.171 64.7096C119.907 64.489 119.582 64.3663 119.246 64.3601C119.105 64.3543 118.964 64.3845 118.835 64.4482C118.706 64.512 118.593 64.6074 118.506 64.7268C118.418 64.8461 118.358 64.986 118.331 65.1349C118.303 65.2839 118.309 65.4376 118.348 65.5836C118.51 65.9101 118.761 66.1765 119.067 66.3481C119.373 66.5196 119.72 66.5882 120.063 66.545V66.545Z"
          fill="#677A89"
        />
        <path
          d="M123.654 66.5445C123.805 66.5558 123.957 66.5258 124.094 66.4576C124.232 66.3893 124.351 66.2848 124.441 66.154C124.53 66.0231 124.588 65.8701 124.607 65.7091C124.627 65.5482 124.608 65.3846 124.552 65.2336C124.448 64.8798 124.242 64.5707 123.963 64.3507C123.684 64.1308 123.347 64.0115 123.001 64.01C122.86 63.9971 122.718 64.0238 122.589 64.0874C122.461 64.151 122.35 64.2494 122.267 64.3728C122.185 64.4963 122.134 64.6406 122.12 64.7915C122.105 64.9424 122.128 65.0947 122.185 65.2336C122.27 65.5897 122.458 65.9082 122.721 66.1435C122.985 66.3789 123.312 66.5192 123.654 66.5445V66.5445Z"
          fill="#677A89"
        />
        <path
          d="M127.329 66.545C127.473 66.5415 127.614 66.5008 127.741 66.4263C127.868 66.3518 127.976 66.2457 128.056 66.1171C128.136 65.9884 128.186 65.841 128.201 65.6872C128.217 65.5335 128.198 65.378 128.145 65.234C128.057 64.8873 127.866 64.5806 127.601 64.36C127.336 64.1394 127.011 64.0167 126.675 64.0105C126.534 64.0047 126.393 64.0349 126.264 64.0986C126.135 64.1624 126.023 64.2578 125.935 64.3772C125.847 64.4965 125.787 64.6364 125.76 64.7853C125.733 64.9343 125.739 65.088 125.777 65.234C125.897 65.5853 126.105 65.894 126.379 66.1256C126.653 66.3572 126.982 66.5024 127.329 66.545V66.545Z"
          fill="#677A89"
        />
        <path
          d="M131.002 66.5445C131.656 66.5445 131.982 65.9327 131.819 65.2335C131.715 64.8798 131.509 64.5707 131.23 64.3507C130.951 64.1308 130.614 64.0115 130.268 64.01C130.126 63.9971 129.985 64.0238 129.856 64.0874C129.727 64.151 129.616 64.2494 129.534 64.3728C129.451 64.4963 129.401 64.6406 129.386 64.7915C129.372 64.9424 129.394 65.0947 129.451 65.2335C129.552 65.5967 129.755 65.9177 130.033 66.1524C130.31 66.3871 130.649 66.524 131.002 66.5445V66.5445Z"
          fill="#677A89"
        />
        <path
          d="M134.594 66.5445C134.745 66.5558 134.897 66.5258 135.034 66.4576C135.172 66.3893 135.291 66.2848 135.381 66.154C135.47 66.0231 135.527 65.8701 135.547 65.7091C135.566 65.5482 135.548 65.3846 135.492 65.2336C135.378 64.8867 135.169 64.5846 134.892 64.3664C134.616 64.1483 134.284 64.0241 133.941 64.01C133.8 63.9971 133.658 64.0238 133.529 64.0874C133.401 64.151 133.29 64.2494 133.207 64.3728C133.125 64.4963 133.074 64.6406 133.059 64.7915C133.045 64.9424 133.067 65.0947 133.125 65.2336C133.21 65.5897 133.397 65.9082 133.661 66.1435C133.925 66.3789 134.251 66.5192 134.594 66.5445V66.5445Z"
          fill="#677A89"
        />
        <path
          d="M138.269 66.5452C138.413 66.5418 138.554 66.5011 138.681 66.4266C138.808 66.3521 138.916 66.246 138.996 66.1174C139.076 65.9887 139.126 65.8413 139.141 65.6875C139.157 65.5338 139.138 65.3783 139.085 65.2343C138.997 64.8876 138.806 64.5809 138.541 64.3603C138.276 64.1396 137.951 64.017 137.615 64.0107C137.455 64.0329 137.304 64.1008 137.177 64.2072C137.05 64.3137 136.952 64.4548 136.894 64.6157C136.835 64.7767 136.818 64.9515 136.845 65.1219C136.871 65.2923 136.94 65.4518 137.044 65.5839C137.174 65.8266 137.351 66.0374 137.561 66.203C137.772 66.3685 138.013 66.4851 138.269 66.5452Z"
          fill="#677A89"
        />
        <path
          d="M140.39 65.5841C140.475 65.9403 140.663 66.2587 140.927 66.4941C141.191 66.7294 141.517 66.8698 141.86 66.8951C142.011 66.9063 142.162 66.8764 142.3 66.8081C142.437 66.7398 142.557 66.6354 142.646 66.5046C142.736 66.3737 142.793 66.2206 142.813 66.0597C142.832 65.8988 142.813 65.7352 142.758 65.5841C142.654 65.2304 142.448 64.9213 142.169 64.7013C141.89 64.4814 141.553 64.3621 141.207 64.3606C141.065 64.3477 140.924 64.3744 140.795 64.438C140.666 64.5016 140.555 64.5999 140.473 64.7234C140.391 64.8468 140.34 64.9911 140.325 65.1421C140.311 65.293 140.333 65.4453 140.39 65.5841V65.5841Z"
          fill="#677A89"
        />
        <path
          d="M73.5287 65.5836C73.4404 65.2369 73.2494 64.9302 72.9845 64.7096C72.7195 64.489 72.3949 64.3663 72.0592 64.3601C71.9175 64.3543 71.7766 64.3845 71.6479 64.4482C71.5192 64.512 71.4064 64.6074 71.3188 64.7268C71.2312 64.8461 71.1713 64.986 71.1439 65.1349C71.1165 65.2839 71.1224 65.4376 71.1612 65.5836C71.2618 65.9468 71.4649 66.2677 71.7426 66.5024C72.0203 66.7372 72.359 66.8741 72.7123 66.8946C72.8568 66.8911 72.9982 66.8504 73.1248 66.7759C73.2514 66.7014 73.3593 66.5954 73.4395 66.4667C73.5196 66.338 73.5695 66.1906 73.5851 66.0369C73.6006 65.8831 73.5813 65.7276 73.5287 65.5836V65.5836Z"
          fill="#677A89"
        />
        <path
          d="M76.3055 66.5445C76.4568 66.5558 76.6082 66.5258 76.7458 66.4576C76.8833 66.3893 77.0024 66.2848 77.0921 66.154C77.1817 66.0231 77.2389 65.8701 77.2584 65.7091C77.2779 65.5482 77.259 65.3846 77.2036 65.2336C77.0996 64.8798 76.8934 64.5707 76.6146 64.3507C76.3358 64.1308 75.9988 64.0115 75.6525 64.01C75.5113 63.9971 75.3693 64.0238 75.2406 64.0874C75.112 64.151 75.0011 64.2494 74.9187 64.3728C74.8363 64.4963 74.7854 64.6406 74.7709 64.7915C74.7564 64.9424 74.7788 65.0947 74.836 65.2336C74.9211 65.5897 75.1089 65.9082 75.3727 66.1435C75.6365 66.3789 75.9629 66.5192 76.3055 66.5445V66.5445Z"
          fill="#677A89"
        />
        <path
          d="M79.8969 66.545C80.0481 66.5562 80.1995 66.5263 80.337 66.458C80.4746 66.3897 80.5937 66.2853 80.6833 66.1544C80.773 66.0236 80.8303 65.8705 80.8498 65.7096C80.8693 65.5486 80.8504 65.385 80.7949 65.234C80.6806 64.8872 80.4717 64.5851 80.1951 64.3669C79.9185 64.1487 79.5871 64.0246 79.2438 64.0105C79.1021 64.0047 78.9611 64.0349 78.8324 64.0986C78.7037 64.1624 78.591 64.2578 78.5034 64.3772C78.4158 64.4965 78.3559 64.6364 78.3285 64.7853C78.3011 64.9343 78.307 65.088 78.3457 65.234C78.4649 65.5853 78.6731 65.894 78.9471 66.1256C79.2211 66.3572 79.55 66.5024 79.8969 66.545Z"
          fill="#677A89"
        />
        <path
          d="M83.6522 66.545C84.3054 66.545 84.6319 65.9332 84.4686 65.234C84.3803 64.8873 84.1894 64.5806 83.9244 64.36C83.6595 64.1394 83.3348 64.0167 82.9991 64.0105C82.8574 64.0047 82.7165 64.0349 82.5879 64.0986C82.4592 64.1624 82.3464 64.2578 82.2588 64.3772C82.1712 64.4965 82.1112 64.6364 82.0838 64.7853C82.0564 64.9343 82.0623 65.088 82.1011 65.234C82.2017 65.5972 82.4048 65.9181 82.6825 66.1528C82.9602 66.3875 83.2989 66.5245 83.6522 66.545Z"
          fill="#677A89"
        />
        <path
          d="M89.1212 69.1668C89.0362 68.8106 88.8484 68.4922 88.5846 68.2568C88.3207 68.0215 87.9943 67.8811 87.6517 67.8558C87.5005 67.8446 87.3491 67.8745 87.2115 67.9428C87.074 68.0111 86.9549 68.1155 86.8652 68.2464C86.7756 68.3772 86.7184 68.5303 86.6989 68.6912C86.6794 68.8521 86.6982 69.0158 86.7537 69.1668C86.8576 69.5205 87.0639 69.8297 87.3427 70.0496C87.6215 70.2695 87.9585 70.3889 88.3048 70.3903C88.446 70.4032 88.5879 70.3766 88.7166 70.3129C88.8453 70.2493 88.9562 70.151 89.0386 70.0275C89.121 69.9041 89.1719 69.7598 89.1864 69.6089C89.2009 69.4579 89.1784 69.3056 89.1212 69.1668V69.1668Z"
          fill="#677A89"
        />
        <path
          d="M92.7959 69.1664C92.6953 68.8033 92.4922 68.4823 92.2145 68.2476C91.9368 68.0129 91.598 67.8759 91.2447 67.8555C90.5916 67.8555 90.2651 68.4673 90.4283 69.1664C90.5166 69.5131 90.7075 69.8198 90.9725 70.0404C91.2375 70.2611 91.5622 70.3837 91.8978 70.39C92.0395 70.3958 92.1805 70.3656 92.3092 70.3018C92.4378 70.2381 92.5505 70.1426 92.6381 70.0233C92.7257 69.9039 92.7857 69.764 92.8131 69.6151C92.8405 69.4662 92.8346 69.3124 92.7959 69.1664V69.1664Z"
          fill="#677A89"
        />
        <path
          d="M96.2243 69.1668C96.1392 68.8106 95.9515 68.4922 95.6876 68.2568C95.4238 68.0215 95.0974 67.8811 94.7548 67.8558C94.6036 67.8446 94.4521 67.8745 94.3146 67.9428C94.1771 68.0111 94.058 68.1155 93.9683 68.2464C93.8787 68.3772 93.8214 68.5303 93.8019 68.6912C93.7824 68.8521 93.8013 69.0158 93.8568 69.1668C93.971 69.5136 94.18 69.8157 94.4566 70.0339C94.7332 70.2521 95.0646 70.3762 95.4079 70.3903C95.5491 70.4032 95.691 70.3766 95.8197 70.3129C95.9483 70.2493 96.0593 70.151 96.1417 70.0275C96.2241 69.9041 96.2749 69.7598 96.2894 69.6089C96.3039 69.4579 96.2815 69.3056 96.2243 69.1668V69.1668Z"
          fill="#677A89"
        />
        <path
          d="M100.061 69.1664C99.9604 68.8033 99.7573 68.4823 99.4796 68.2476C99.2019 68.0129 98.8631 67.8759 98.5098 67.8555C97.8567 67.8555 97.5302 68.4673 97.6935 69.1664C97.7974 69.5201 98.0037 69.8293 98.2825 70.0493C98.5613 70.2692 98.8983 70.3885 99.2446 70.39C99.3858 70.4029 99.5277 70.3762 99.6564 70.3126C99.785 70.249 99.896 70.1506 99.9784 70.0272C100.061 69.9037 100.112 69.7594 100.126 69.6085C100.141 69.4576 100.118 69.3052 100.061 69.1664V69.1664Z"
          fill="#677A89"
        />
        <path
          d="M103.736 69.1664C103.617 68.8152 103.408 68.5064 103.134 68.2748C102.86 68.0432 102.531 67.898 102.185 67.8555C102.04 67.8589 101.899 67.8996 101.772 67.9741C101.645 68.0486 101.538 68.1547 101.457 68.2833C101.377 68.412 101.327 68.5594 101.312 68.7132C101.296 68.8669 101.316 69.0224 101.368 69.1664C101.456 69.5131 101.647 69.8198 101.912 70.0404C102.177 70.2611 102.502 70.3837 102.838 70.39C102.979 70.3958 103.12 70.3656 103.249 70.3018C103.378 70.2381 103.49 70.1426 103.578 70.0233C103.666 69.9039 103.726 69.764 103.753 69.6151C103.78 69.4662 103.774 69.3124 103.736 69.1664Z"
          fill="#677A89"
        />
        <path
          d="M107.327 69.1668C107.242 68.8106 107.054 68.4922 106.79 68.2568C106.526 68.0215 106.2 67.8811 105.857 67.8558C105.706 67.8446 105.555 67.8745 105.417 67.9428C105.28 68.0111 105.161 68.1155 105.071 68.2464C104.981 68.3772 104.924 68.5303 104.904 68.6912C104.885 68.8521 104.904 69.0158 104.959 69.1668C105.063 69.5205 105.27 69.8297 105.548 70.0496C105.827 70.2695 106.164 70.3889 106.51 70.3903C106.652 70.4032 106.794 70.3766 106.922 70.3129C107.051 70.2493 107.162 70.151 107.244 70.0275C107.327 69.9041 107.377 69.7598 107.392 69.6089C107.406 69.4579 107.384 69.3056 107.327 69.1668Z"
          fill="#677A89"
        />
        <path
          d="M111.001 69.1664C110.9 68.8033 110.697 68.4823 110.42 68.2476C110.142 68.0129 109.803 67.8759 109.45 67.8555C108.797 67.8555 108.47 68.4673 108.633 69.1664C108.722 69.5131 108.913 69.8198 109.178 70.0404C109.443 70.2611 109.767 70.3837 110.103 70.39C110.245 70.3958 110.386 70.3656 110.514 70.3018C110.643 70.2381 110.756 70.1426 110.843 70.0233C110.931 69.9039 110.991 69.764 111.018 69.6151C111.046 69.4662 111.04 69.3124 111.001 69.1664Z"
          fill="#677A89"
        />
        <path
          d="M114.675 69.1668C114.556 68.8155 114.348 68.5067 114.074 68.2752C113.8 68.0436 113.471 67.8984 113.124 67.8558C112.973 67.8446 112.821 67.8745 112.684 67.9428C112.546 68.0111 112.427 68.1155 112.337 68.2464C112.248 68.3772 112.191 68.5303 112.171 68.6912C112.152 68.8522 112.17 69.0158 112.226 69.1668C112.34 69.5136 112.549 69.8157 112.826 70.0339C113.102 70.2521 113.434 70.3762 113.777 70.3903C113.919 70.3961 114.06 70.3659 114.188 70.3022C114.317 70.2385 114.43 70.143 114.517 70.0236C114.605 69.9043 114.665 69.7644 114.692 69.6155C114.72 69.4665 114.714 69.3128 114.675 69.1668Z"
          fill="#677A89"
        />
        <path
          d="M118.267 69.1668C118.182 68.8106 117.994 68.4922 117.73 68.2568C117.466 68.0215 117.14 67.8811 116.797 67.8558C116.646 67.8446 116.495 67.8745 116.357 67.9428C116.22 68.0111 116.1 68.1155 116.011 68.2464C115.921 68.3772 115.864 68.5303 115.844 68.6912C115.825 68.8521 115.844 69.0158 115.899 69.1668C116.003 69.5205 116.209 69.8297 116.488 70.0496C116.767 70.2695 117.104 70.3889 117.45 70.3903C117.592 70.4032 117.733 70.3766 117.862 70.3129C117.991 70.2493 118.102 70.151 118.184 70.0275C118.266 69.9041 118.317 69.7598 118.332 69.6089C118.346 69.4579 118.324 69.3056 118.267 69.1668Z"
          fill="#677A89"
        />
        <path
          d="M121.941 69.1664C121.84 68.8033 121.637 68.4823 121.359 68.2476C121.082 68.0129 120.743 67.8759 120.39 67.8555C120.245 67.8589 120.104 67.8996 119.977 67.9741C119.851 68.0486 119.743 68.1547 119.663 68.2833C119.582 68.412 119.532 68.5594 119.517 68.7132C119.501 68.8669 119.521 69.0224 119.573 69.1664C119.662 69.5131 119.853 69.8198 120.117 70.0404C120.382 70.2611 120.707 70.3837 121.043 70.39C121.184 70.3958 121.325 70.3656 121.454 70.3018C121.583 70.2381 121.696 70.1426 121.783 70.0233C121.871 69.9039 121.931 69.764 121.958 69.6151C121.985 69.4662 121.98 69.3124 121.941 69.1664Z"
          fill="#677A89"
        />
        <path
          d="M125.532 69.1668C125.447 68.8106 125.259 68.4922 124.995 68.2568C124.731 68.0215 124.405 67.8811 124.062 67.8558C123.911 67.8446 123.76 67.8745 123.622 67.9428C123.485 68.0111 123.366 68.1155 123.276 68.2464C123.186 68.3772 123.129 68.5303 123.11 68.6912C123.09 68.8521 123.109 69.0158 123.164 69.1668C123.279 69.5136 123.488 69.8157 123.764 70.0339C124.041 70.2521 124.372 70.3762 124.715 70.3903C124.857 70.4032 124.999 70.3766 125.127 70.3129C125.256 70.2493 125.367 70.151 125.449 70.0275C125.532 69.9041 125.583 69.7598 125.597 69.6089C125.612 69.4579 125.589 69.3056 125.532 69.1668V69.1668Z"
          fill="#677A89"
        />
        <path
          d="M128.879 69.1664C128.779 68.8033 128.576 68.4823 128.298 68.2476C128.02 68.0129 127.682 67.8759 127.328 67.8555C126.675 67.8555 126.349 68.4673 126.512 69.1664C126.616 69.5201 126.822 69.8293 127.101 70.0493C127.38 70.2692 127.717 70.3885 128.063 70.39C128.204 70.4029 128.346 70.3762 128.475 70.3126C128.603 70.249 128.714 70.1506 128.797 70.0272C128.879 69.9037 128.93 69.7594 128.944 69.6085C128.959 69.4576 128.937 69.3052 128.879 69.1664Z"
          fill="#677A89"
        />
        <path
          d="M132.88 69.1664C132.761 68.8152 132.552 68.5064 132.278 68.2748C132.004 68.0432 131.675 67.898 131.329 67.8555C131.184 67.8589 131.043 67.8996 130.916 67.9741C130.79 68.0486 130.682 68.1547 130.601 68.2833C130.521 68.412 130.471 68.5594 130.456 68.7132C130.44 68.8669 130.46 69.0224 130.512 69.1664C130.6 69.5131 130.791 69.8198 131.056 70.0404C131.321 70.2611 131.646 70.3837 131.982 70.39C132.123 70.3958 132.264 70.3656 132.393 70.3018C132.522 70.2381 132.635 70.1426 132.722 70.0233C132.81 69.9039 132.87 69.764 132.897 69.6151C132.924 69.4662 132.919 69.3124 132.88 69.1664V69.1664Z"
          fill="#677A89"
        />
        <path
          d="M136.472 69.1668C136.387 68.8106 136.199 68.4922 135.935 68.2568C135.671 68.0215 135.345 67.8811 135.002 67.8558C134.851 67.8446 134.7 67.8745 134.562 67.9428C134.425 68.0111 134.306 68.1155 134.216 68.2464C134.126 68.3772 134.069 68.5303 134.049 68.6912C134.03 68.8521 134.049 69.0158 134.104 69.1668C134.208 69.5205 134.415 69.8297 134.693 70.0496C134.972 70.2695 135.309 70.3889 135.655 70.3903C135.797 70.4032 135.939 70.3766 136.067 70.3129C136.196 70.2493 136.307 70.151 136.389 70.0275C136.472 69.9041 136.523 69.7598 136.537 69.6089C136.551 69.4579 136.529 69.3056 136.472 69.1668Z"
          fill="#677A89"
        />
        <path
          d="M140.146 69.1664C140.045 68.8033 139.842 68.4823 139.564 68.2476C139.287 68.0129 138.948 67.8759 138.595 67.8555C138.45 67.8589 138.309 67.8996 138.182 67.9741C138.056 68.0486 137.948 68.1547 137.868 68.2833C137.788 68.412 137.738 68.5594 137.722 68.7132C137.706 68.8669 137.726 69.0224 137.778 69.1664C137.867 69.5131 138.058 69.8198 138.323 70.0404C138.588 70.2611 138.912 70.3837 139.248 70.39C139.389 70.3958 139.53 70.3656 139.659 70.3018C139.788 70.2381 139.901 70.1426 139.988 70.0233C140.076 69.9039 140.136 69.764 140.163 69.6151C140.191 69.4662 140.185 69.3124 140.146 69.1664Z"
          fill="#677A89"
        />
        <path
          d="M143.738 69.1668C143.653 68.8106 143.466 68.4922 143.202 68.2568C142.938 68.0215 142.612 67.8811 142.269 67.8558C142.118 67.8446 141.966 67.8745 141.829 67.9428C141.691 68.0111 141.572 68.1155 141.482 68.2464C141.393 68.3772 141.336 68.5303 141.316 68.6912C141.297 68.8521 141.315 69.0158 141.371 69.1668C141.485 69.5136 141.694 69.8157 141.971 70.0339C142.247 70.2521 142.579 70.3762 142.922 70.3903C143.063 70.4032 143.205 70.3766 143.334 70.3129C143.462 70.2493 143.573 70.151 143.656 70.0275C143.738 69.9041 143.789 69.7598 143.804 69.6089C143.818 69.4579 143.796 69.3056 143.738 69.1668Z"
          fill="#677A89"
        />
        <path
          d="M74.5902 69.1664C74.4896 68.8033 74.2865 68.4823 74.0088 68.2476C73.7311 68.0129 73.3924 67.8759 73.0391 67.8555C72.8947 67.8589 72.7532 67.8996 72.6266 67.9741C72.5 68.0486 72.392 68.1547 72.3119 68.2833C72.2318 68.412 72.1819 68.5594 72.1664 68.7132C72.1508 68.8669 72.1701 69.0224 72.2227 69.1664C72.3109 69.5131 72.5019 69.8198 72.7669 70.0404C73.0319 70.2611 73.3565 70.3837 73.6921 70.39C73.8338 70.3958 73.9748 70.3656 74.1035 70.3018C74.2322 70.2381 74.3449 70.1426 74.4325 70.0233C74.5201 69.9039 74.5801 69.764 74.6075 69.6151C74.6349 69.4662 74.6289 69.3124 74.5902 69.1664Z"
          fill="#677A89"
        />
        <path
          d="M78.1828 69.1668C78.0977 68.8106 77.91 68.4922 77.6461 68.2568C77.3823 68.0215 77.0559 67.8811 76.7133 67.8558C76.5621 67.8446 76.4106 67.8745 76.2731 67.9428C76.1356 68.0111 76.0165 68.1155 75.9268 68.2464C75.8372 68.3772 75.7799 68.5303 75.7604 68.6912C75.7409 68.8521 75.7598 69.0158 75.8153 69.1668C75.9295 69.5136 76.1385 69.8157 76.4151 70.0339C76.6916 70.2521 77.0231 70.3762 77.3664 70.3903C77.5076 70.4032 77.6495 70.3766 77.7782 70.3129C77.9068 70.2493 78.0178 70.151 78.1002 70.0275C78.1826 69.9041 78.2334 69.7598 78.2479 69.6089C78.2624 69.4579 78.24 69.3056 78.1828 69.1668V69.1668Z"
          fill="#677A89"
        />
        <path
          d="M81.8554 69.1664C81.7548 68.8033 81.5518 68.4823 81.274 68.2476C80.9963 68.0129 80.6576 67.8759 80.3043 67.8555C79.6512 67.8555 79.3246 68.4673 79.4879 69.1664C79.5918 69.5201 79.7981 69.8293 80.0769 70.0493C80.3558 70.2692 80.6927 70.3885 81.039 70.39C81.1802 70.4029 81.3222 70.3762 81.4508 70.3126C81.5795 70.249 81.6905 70.1506 81.7728 70.0272C81.8552 69.9037 81.9061 69.7594 81.9206 69.6085C81.9351 69.4576 81.9126 69.3052 81.8554 69.1664Z"
          fill="#677A89"
        />
        <path
          d="M85.5292 69.1664C85.41 68.8152 85.2018 68.5064 84.9278 68.2748C84.6538 68.0432 84.3249 67.898 83.978 67.8555C83.8336 67.8589 83.6921 67.8996 83.5655 67.9741C83.4389 68.0486 83.3311 68.1547 83.2509 68.2833C83.1708 68.412 83.1208 68.5594 83.1053 68.7132C83.0898 68.8669 83.1091 69.0224 83.1617 69.1664C83.25 69.5131 83.4409 69.8198 83.7059 70.0404C83.9708 70.2611 84.2955 70.3837 84.6312 70.39C84.7729 70.3958 84.9138 70.3656 85.0425 70.3018C85.1712 70.2381 85.2839 70.1426 85.3715 70.0233C85.4591 69.9039 85.519 69.764 85.5465 69.6151C85.5739 69.4662 85.568 69.3124 85.5292 69.1664V69.1664Z"
          fill="#677A89"
        />
        <path
          d="M88.6319 71.7891C88.4874 71.7925 88.3459 71.8332 88.2193 71.9077C88.0928 71.9822 87.9849 72.0883 87.9047 72.2169C87.8246 72.3456 87.7747 72.493 87.7591 72.6468C87.7436 72.8005 87.7629 72.956 87.8155 73.1C87.9038 73.4467 88.0947 73.7534 88.3597 73.974C88.6246 74.1946 88.9494 74.3173 89.285 74.3236C89.4267 74.3293 89.5676 74.2991 89.6963 74.2354C89.825 74.1716 89.9377 74.0762 90.0253 73.9569C90.1129 73.8375 90.1729 73.6976 90.2003 73.5487C90.2277 73.3998 90.2218 73.246 90.183 73.1C90.0639 72.7487 89.8556 72.44 89.5816 72.2084C89.3076 71.9768 88.9787 71.8316 88.6319 71.7891Z"
          fill="#677A89"
        />
        <path
          d="M92.3055 71.7885C92.1543 71.7772 92.0029 71.8071 91.8653 71.8754C91.7278 71.9437 91.6087 72.0481 91.519 72.179C91.4294 72.3099 91.3722 72.4629 91.3527 72.6238C91.3332 72.7848 91.3521 72.9484 91.4075 73.0994C91.5218 73.4462 91.7308 73.7483 92.0074 73.9665C92.2839 74.1847 92.6153 74.3089 92.9586 74.3229C93.0998 74.3358 93.2417 74.3092 93.3704 74.2456C93.4991 74.1819 93.61 74.0836 93.6924 73.9601C93.7748 73.8367 93.8257 73.6924 93.8402 73.5415C93.8547 73.3906 93.8322 73.2382 93.775 73.0994C93.69 72.7432 93.5022 72.4248 93.2384 72.1895C92.9745 71.9541 92.6481 71.8138 92.3055 71.7885Z"
          fill="#677A89"
        />
        <path
          d="M96.2237 71.7891C95.5706 71.7891 95.2441 72.4008 95.4073 73.1C95.4439 73.3583 95.5369 73.6038 95.6787 73.8164C95.8206 74.029 96.0073 74.2027 96.2237 74.3236C96.3712 74.3544 96.5238 74.3411 96.6647 74.285C96.8056 74.2289 96.9294 74.1323 97.0223 74.0058C97.1151 73.8792 97.1736 73.7277 97.1911 73.5679C97.2087 73.4081 97.1847 73.2462 97.1217 73.1C97.0762 72.8223 96.9715 72.5596 96.8157 72.3323C96.66 72.1049 96.4574 71.9191 96.2237 71.7891V71.7891Z"
          fill="#677A89"
        />
        <path
          d="M99.5707 71.7885C99.4195 71.7772 99.268 71.8071 99.1305 71.8754C98.993 71.9437 98.8738 72.0481 98.7842 72.179C98.6945 72.3099 98.6373 72.4629 98.6178 72.6238C98.5983 72.7848 98.6172 72.9484 98.6727 73.0994C98.787 73.4462 98.9959 73.7483 99.2725 73.9665C99.5491 74.1847 99.8805 74.3089 100.224 74.3229C100.365 74.3287 100.506 74.2985 100.635 74.2348C100.764 74.171 100.877 74.0756 100.964 73.9562C101.052 73.8369 101.112 73.697 101.139 73.5481C101.167 73.3992 101.161 73.2454 101.122 73.0994C101.003 72.7481 100.794 72.4394 100.52 72.2078C100.246 71.9762 99.9175 71.831 99.5707 71.7885Z"
          fill="#677A89"
        />
        <path
          d="M103.246 71.7885C103.094 71.7772 102.943 71.8071 102.805 71.8754C102.668 71.9437 102.549 72.0481 102.459 72.179C102.369 72.3099 102.312 72.4629 102.293 72.6238C102.273 72.7848 102.292 72.9484 102.347 73.0994C102.451 73.4531 102.658 73.7623 102.937 73.9822C103.215 74.2022 103.552 74.3215 103.899 74.3229C104.04 74.3358 104.182 74.3092 104.31 74.2456C104.439 74.1819 104.55 74.0836 104.632 73.9601C104.715 73.8367 104.766 73.6924 104.78 73.5415C104.795 73.3906 104.772 73.2382 104.715 73.0994C104.63 72.7432 104.442 72.4248 104.178 72.1895C103.915 71.9541 103.588 71.8138 103.246 71.7885V71.7885Z"
          fill="#677A89"
        />
        <path
          d="M106.837 71.7891C106.693 71.7925 106.551 71.8332 106.424 71.9077C106.298 71.9822 106.19 72.0883 106.11 72.2169C106.03 72.3456 105.98 72.493 105.964 72.6468C105.949 72.8005 105.968 72.956 106.021 73.1C106.109 73.4467 106.3 73.7534 106.565 73.974C106.83 74.1946 107.154 74.3173 107.49 74.3236C107.632 74.3293 107.773 74.2991 107.901 74.2354C108.03 74.1716 108.143 74.0762 108.23 73.9569C108.318 73.8375 108.378 73.6976 108.405 73.5487C108.433 73.3998 108.427 73.246 108.388 73.1C108.287 72.7369 108.084 72.4159 107.807 72.1812C107.529 71.9465 107.19 71.8095 106.837 71.7891V71.7891Z"
          fill="#677A89"
        />
        <path
          d="M110.511 71.7885C110.359 71.7772 110.208 71.8071 110.07 71.8754C109.933 71.9437 109.814 72.0481 109.724 72.179C109.635 72.3099 109.577 72.4629 109.558 72.6238C109.538 72.7848 109.557 72.9484 109.613 73.0994C109.727 73.4462 109.936 73.7483 110.212 73.9665C110.489 74.1847 110.82 74.3089 111.164 74.3229C111.305 74.3358 111.447 74.3092 111.576 74.2456C111.704 74.1819 111.815 74.0836 111.898 73.9601C111.98 73.8367 112.031 73.6924 112.045 73.5415C112.06 73.3906 112.037 73.2382 111.98 73.0994C111.895 72.7432 111.707 72.4248 111.443 72.1895C111.18 71.9541 110.853 71.8138 110.511 71.7885Z"
          fill="#677A89"
        />
        <path
          d="M114.104 71.7891C113.451 71.7891 113.124 72.4008 113.287 73.1C113.391 73.4537 113.597 73.7629 113.876 73.9828C114.155 74.2028 114.492 74.3221 114.838 74.3236C114.98 74.3365 115.121 74.3098 115.25 74.2462C115.379 74.1826 115.49 74.0842 115.572 73.9607C115.655 73.8373 115.705 73.693 115.72 73.5421C115.734 73.3912 115.712 73.2388 115.655 73.1C115.554 72.7369 115.351 72.4159 115.073 72.1812C114.796 71.9465 114.457 71.8095 114.104 71.7891Z"
          fill="#677A89"
        />
        <path
          d="M117.777 71.7891C117.632 71.7925 117.491 71.8332 117.364 71.9077C117.238 71.9822 117.13 72.0883 117.05 72.2169C116.97 72.3456 116.92 72.493 116.904 72.6468C116.889 72.8005 116.908 72.956 116.961 73.1C117.049 73.4467 117.24 73.7534 117.505 73.974C117.77 74.1946 118.094 74.3173 118.43 74.3236C118.572 74.3293 118.713 74.2991 118.841 74.2354C118.97 74.1716 119.083 74.0762 119.17 73.9569C119.258 73.8375 119.318 73.6976 119.345 73.5487C119.373 73.3998 119.367 73.246 119.328 73.1C119.209 72.7487 119.001 72.44 118.727 72.2084C118.453 71.9768 118.124 71.8316 117.777 71.7891V71.7891Z"
          fill="#677A89"
        />
        <path
          d="M121.451 71.7885C121.3 71.7772 121.148 71.8071 121.011 71.8754C120.873 71.9437 120.754 72.0481 120.665 72.179C120.575 72.3099 120.518 72.4629 120.498 72.6238C120.479 72.7848 120.498 72.9484 120.553 73.0994C120.657 73.4531 120.863 73.7623 121.142 73.9822C121.421 74.2022 121.758 74.3215 122.104 74.3229C122.245 74.3358 122.387 74.3092 122.516 74.2456C122.645 74.1819 122.756 74.0836 122.838 73.9601C122.92 73.8367 122.971 73.6924 122.986 73.5415C123 73.3906 122.978 73.2382 122.921 73.0994C122.835 72.7432 122.648 72.4248 122.384 72.1895C122.12 71.9541 121.794 71.8138 121.451 71.7885V71.7885Z"
          fill="#677A89"
        />
        <path
          d="M125.042 71.7891C124.389 71.7891 124.062 72.4008 124.226 73.1C124.314 73.4467 124.505 73.7534 124.77 73.974C125.035 74.1946 125.36 74.3173 125.695 74.3236C125.837 74.3293 125.978 74.2991 126.107 74.2354C126.235 74.1716 126.348 74.0762 126.436 73.9569C126.523 73.8375 126.583 73.6976 126.611 73.5487C126.638 73.3998 126.632 73.246 126.593 73.1C126.493 72.7369 126.29 72.4159 126.012 72.1812C125.734 71.9465 125.395 71.8095 125.042 71.7891V71.7891Z"
          fill="#677A89"
        />
        <path
          d="M128.879 71.7885C128.728 71.7772 128.577 71.8071 128.439 71.8754C128.302 71.9437 128.182 72.0481 128.093 72.179C128.003 72.3099 127.946 72.4629 127.926 72.6238C127.907 72.7848 127.926 72.9484 127.981 73.0994C128.096 73.4462 128.305 73.7483 128.581 73.9665C128.858 74.1847 129.189 74.3089 129.532 74.3229C129.674 74.3358 129.816 74.3092 129.944 74.2456C130.073 74.1819 130.184 74.0836 130.266 73.9601C130.349 73.8367 130.399 73.6924 130.414 73.5415C130.428 73.3906 130.406 73.2382 130.349 73.0994C130.264 72.7432 130.076 72.4248 129.812 72.1895C129.548 71.9541 129.222 71.8138 128.879 71.7885Z"
          fill="#677A89"
        />
        <path
          d="M132.309 71.7891C131.656 71.7891 131.329 72.4008 131.492 73.1C131.596 73.4537 131.802 73.7629 132.081 73.9828C132.36 74.2028 132.697 74.3221 133.043 74.3236C133.185 74.3365 133.327 74.3098 133.455 74.2462C133.584 74.1826 133.695 74.0842 133.777 73.9607C133.86 73.8373 133.91 73.693 133.925 73.5421C133.939 73.3912 133.917 73.2388 133.86 73.1C133.759 72.7369 133.556 72.4159 133.278 72.1812C133.001 71.9465 132.662 71.8095 132.309 71.7891Z"
          fill="#677A89"
        />
        <path
          d="M135.982 71.7882C135.838 71.7917 135.696 71.8324 135.569 71.9069C135.443 71.9814 135.335 72.0874 135.255 72.2161C135.175 72.3448 135.125 72.4922 135.109 72.6459C135.094 72.7997 135.113 72.9552 135.166 73.0992C135.213 73.3117 135.3 73.5121 135.42 73.6887C135.541 73.8654 135.693 74.0148 135.867 74.1285C136.041 74.2422 136.235 74.3179 136.437 74.3512C136.639 74.3845 136.845 74.3748 137.043 74.3227C137.185 74.3285 137.326 74.2983 137.455 74.2345C137.583 74.1708 137.696 74.0754 137.784 73.956C137.871 73.8367 137.931 73.6968 137.959 73.5479C137.986 73.3989 137.98 73.2452 137.941 73.0992C137.798 72.6781 137.527 72.3204 137.172 72.0829C136.817 71.8454 136.398 71.7417 135.982 71.7882V71.7882Z"
          fill="#677A89"
        />
        <path
          d="M139.656 71.7885C139.505 71.7772 139.354 71.8071 139.216 71.8754C139.078 71.9437 138.959 72.0481 138.87 72.179C138.78 72.3099 138.723 72.4629 138.703 72.6238C138.684 72.7848 138.703 72.9484 138.758 73.0994C138.862 73.4531 139.068 73.7623 139.347 73.9822C139.626 74.2022 139.963 74.3215 140.309 74.3229C140.45 74.3358 140.592 74.3092 140.721 74.2456C140.85 74.1819 140.961 74.0836 141.043 73.9601C141.125 73.8367 141.176 73.6924 141.191 73.5415C141.205 73.3906 141.183 73.2382 141.126 73.0994C141.041 72.7432 140.853 72.4248 140.589 72.1895C140.325 71.9541 139.999 71.8138 139.656 71.7885Z"
          fill="#677A89"
        />
        <path
          d="M143.248 71.7891C142.595 71.7891 142.268 72.4008 142.431 73.1C142.52 73.4467 142.71 73.7534 142.975 73.974C143.24 74.1946 143.565 74.3173 143.901 74.3236C144.042 74.3293 144.183 74.2991 144.312 74.2354C144.441 74.1716 144.553 74.0762 144.641 73.9569C144.729 73.8375 144.789 73.6976 144.816 73.5487C144.843 73.3998 144.837 73.246 144.799 73.1C144.698 72.7369 144.495 72.4159 144.217 72.1812C143.94 71.9465 143.601 71.8095 143.248 71.7891V71.7891Z"
          fill="#677A89"
        />
        <path
          d="M74.1 71.7885C73.9488 71.7772 73.7974 71.8071 73.6598 71.8754C73.5223 71.9437 73.4032 72.0481 73.3135 72.179C73.2239 72.3099 73.1666 72.4629 73.1471 72.6238C73.1276 72.7848 73.1465 72.9484 73.202 73.0994C73.3059 73.4531 73.5122 73.7623 73.791 73.9822C74.0698 74.2022 74.4068 74.3215 74.7531 74.3229C74.8943 74.3358 75.0362 74.3092 75.1649 74.2456C75.2935 74.1819 75.4045 74.0836 75.4869 73.9601C75.5693 73.8367 75.6202 73.6924 75.6346 73.5415C75.6491 73.3906 75.6267 73.2382 75.5695 73.0994C75.4845 72.7432 75.2967 72.4248 75.0328 72.1895C74.769 71.9541 74.4426 71.8138 74.1 71.7885Z"
          fill="#677A89"
        />
        <path
          d="M77.6935 71.7891C77.0404 71.7891 76.7138 72.4008 76.8771 73.1C76.9653 73.4467 77.1563 73.7534 77.4213 73.974C77.6863 74.1946 78.0109 74.3173 78.3465 74.3236C78.4882 74.3293 78.6292 74.2991 78.7579 74.2354C78.8866 74.1716 78.9993 74.0762 79.0869 73.9569C79.1745 73.8375 79.2345 73.6976 79.2619 73.5487C79.2893 73.3998 79.2833 73.246 79.2446 73.1C79.144 72.7369 78.9409 72.4159 78.6632 72.1812C78.3855 71.9465 78.0468 71.8095 77.6935 71.7891V71.7891Z"
          fill="#677A89"
        />
        <path
          d="M81.3656 71.7885C81.2144 71.7772 81.063 71.8071 80.9255 71.8754C80.7879 71.9437 80.6688 72.0481 80.5792 72.179C80.4895 72.3099 80.4322 72.4629 80.4127 72.6238C80.3932 72.7848 80.4121 72.9484 80.4676 73.0994C80.5819 73.4462 80.7908 73.7483 81.0674 73.9665C81.344 74.1847 81.6755 74.3089 82.0188 74.3229C82.16 74.3358 82.3019 74.3092 82.4305 74.2456C82.5592 74.1819 82.6702 74.0836 82.7525 73.9601C82.8349 73.8367 82.8858 73.6924 82.9003 73.5415C82.9148 73.3906 82.8923 73.2382 82.8351 73.0994C82.7501 72.7432 82.5623 72.4248 82.2985 72.1895C82.0347 71.9541 81.7082 71.8138 81.3656 71.7885Z"
          fill="#677A89"
        />
        <path
          d="M85.0409 71.7885C84.8896 71.7772 84.7382 71.8071 84.6007 71.8754C84.4632 71.9437 84.344 72.0481 84.2544 72.179C84.1648 72.3099 84.1075 72.4629 84.088 72.6238C84.0685 72.7848 84.0874 72.9484 84.1428 73.0994C84.2468 73.4531 84.453 73.7623 84.7319 73.9822C85.0107 74.2022 85.3477 74.3215 85.694 74.3229C85.8352 74.3358 85.9771 74.3092 86.1058 74.2456C86.2344 74.1819 86.3454 74.0836 86.4278 73.9601C86.5102 73.8367 86.5611 73.6924 86.5756 73.5415C86.5901 73.3906 86.5676 73.2382 86.5104 73.0994C86.4253 72.7432 86.2375 72.4248 85.9737 72.1895C85.7099 71.9541 85.3835 71.8138 85.0409 71.7885Z"
          fill="#677A89"
        />
        <path
          d="M59.814 101.678L43.4863 47.8418H43.8129L60.1405 101.678H59.814Z"
          fill="#677A89"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_5393_14863">
        <rect
          width="190"
          height="123"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
      <clipPath id="clip1_5393_14863">
        <rect
          width="152.46"
          height="118.797"
          fill="white"
          transform="translate(15.3228 2.43555)"
        />
      </clipPath>
      <clipPath id="clip2_5393_14863">
        <rect
          width="164.667"
          height="0.864029"
          fill="white"
          transform="translate(10.2915 100.004)"
        />
      </clipPath>
      <clipPath id="clip3_5393_14863">
        <rect
          width="209.919"
          height="196.403"
          fill="white"
          transform="translate(-9.95996 -94.7256)"
        />
      </clipPath>
    </defs>
  </svg>
);
