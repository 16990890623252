import { selectConfig } from '@mfe/shared/redux/config';
import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTrackScreenFunction } from '@mfe/legacy/mv/containers';
import { Footer } from '@mfe/shared/components';
import NavBar from './NavBar';
import { WebHome } from '../views/WebTabs';
import Cookies from 'js-cookie';
import { useToken } from '@mfe/legacy/mv/views/Auth';
import { useSelector } from 'react-redux';
import { Platform } from '@mfe/shared/schema-types';
import { MobileTabBar } from '@mfe/features/mobile-app-nav';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { REDIRECT_PATH_COOKIE_NAME } from '@mfe/to-be-migrated/redux/auth';
import { defaultAppRoutes, fixedWirelessAppRoutes } from './appRouter';
import CountryRouter from './countryRouter';
import { ServerError } from '@mfe/legacy/mv/views/ServerError';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { ProductInstanceTypes } from '@mfe/shared/graphql/PSM/types';

declare function rg4js(event: string, options: any): void;

const Navigator = (): JSX.Element => {
  const location = useLocation();

  const [prevPath, setPrevPath] = useState('');

  const trackScreen = useTrackScreenFunction();
  const {
    locale: { urlPrefix },
  } = useSelector(selectLocale);
  const { platform } = useSelector(selectConfig);
  const isWeb = platform === Platform.Web;

  const {
    error: getBEPInputsError,
    user: { productKind },
  } = useSelector(selectUser);

  const { redirectUrl } = useToken();

  const isFixedWirelessUser =
    productKind === ProductInstanceTypes.WirelessInternet;
  const routes = isFixedWirelessUser
    ? fixedWirelessAppRoutes
    : defaultAppRoutes;

  const history = useHistory();
  const path = Cookies.get(REDIRECT_PATH_COOKIE_NAME);
  Cookies.remove(REDIRECT_PATH_COOKIE_NAME);

  if (window.location.href.startsWith(redirectUrl ?? ' ')) {
    history.push(path ?? '/');
  }

  useEffect((): void => {
    const path = location.pathname.replace(urlPrefix, '');
    if (!window.location.hostname.includes('localhost')) {
      rg4js('trackEvent', { type: 'pageView', path });
    }

    const route = routes.find((route) => route.path === path);
    //This any types are due to function share between mobile and web
    if (location.state) {
      const currentRoute = (location as NonNullable<Record<string, any>>)[
        'state'
      ].currentRoute as string;

      trackScreen(
        currentRoute,
        (location as NonNullable<Record<string, any>>)['state']
          .priorRoute as string,
        (location as NonNullable<Record<string, any>>)['state'][
          'component'
        ] as any
      );
    } else {
      trackScreen(path, prevPath, route && (route.label as any));
      setPrevPath(path);
    }
  }, [location, urlPrefix]);

  if (getBEPInputsError) {
    return <ServerError canRetry={true} />;
  }

  return (
    <div
      style={{
        minHeight: '100vh',
        flexGrow: 1,
        backgroundColor: '#F2F5F8',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      {isWeb && <NavBar />}
      <CountryRouter routes={routes} fallback={WebHome} />
      {isWeb ? <Footer /> : <MobileTabBar />}
    </div>
  );
};

export default Navigator;
