import React from 'react';
import Nav from './Nav';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { AccountType, Locale } from '@mfe/shared/schema-types';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { selectConfig } from '@mfe/shared/redux/config';
import { ProductInstanceTypes } from '@mfe/shared/graphql/PSM/types';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';

const NavBar = (): JSX.Element => {
  const { t } = useTranslation('Nav');

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const {
    user: { productKind },
  } = useSelector(selectUser);

  const { showNetworkPage } = useSelector(selectConfig);

  const showFAQs =
    userLocale === Locale.EnUs && accountType === AccountType.Residential;

  const defaultTabs = [
    { label: t('Overview'), path: '/', testId: 'Overview' },
    { label: t('PayBill'), path: '/PayBill', testId: 'PayBill' },
    { label: t('PlanUsage'), path: '/PlanUsage', testId: 'PlanUsage' },
  ];

  const fixedWirelessTabs = [
    { label: t('Overview'), path: '/', testId: 'Overview' },
    { label: t('PayBill'), path: '/PayBill', testId: 'PayBill' },
  ];

  if (showNetworkPage) {
    const networkTab = {
      label: t('Network'),
      path: '/Network',
      testId: 'Network',
    };
    defaultTabs.push(networkTab);
  }

  if (showFAQs) {
    const faqTab = { label: t('Help'), path: '/Help', testId: 'Help' };
    defaultTabs.push(faqTab);
  }

  const isFixedWirelessUser =
    productKind === ProductInstanceTypes.WirelessInternet;
  const tabItems = isFixedWirelessUser ? fixedWirelessTabs : defaultTabs;

  return <Nav logoUrl="/" tabItems={tabItems} />;
};

export default NavBar;
