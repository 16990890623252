import React from 'react';
import { View } from 'react-native';
import Svg from 'svgs';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WebSafeSvg = ({
  testID = '',
  style = {},
  children,
  accessible,
  ...otherProps
}: any): JSX.Element => (
  <View testID={testID} style={style}>
    <Svg {...otherProps}>{children}</Svg>
  </View>
);

export * from 'svgs';
export default WebSafeSvg;
