import { NavLink } from 'react-router-dom';
import { Icon, IconComponentType, Txt } from '@vst/beam';
import styles from './styles.module.scss';

type TabNavItem = {
  label: string;
  icon: IconComponentType;
  path: string;
  testId: string;
};

const TabBarItem = ({
  navItem: { icon, path, label, testId },
}: {
  navItem: TabNavItem;
}) => {
  return (
    <NavLink
      to={path}
      exact
      data-cy={testId}
      className={styles.tabItem}
      activeClassName={styles.active}
    >
      <Icon icon={icon} size={25} />
      <Txt variant="tinyRegular">{label}</Txt>
    </NavLink>
  );
};

const TabBar = ({ navItems }: { navItems: TabNavItem[] }) => {
  return (
    <>
      <nav className={styles.tabBarContainer}>
        {navItems.map((item) => (
          <TabBarItem navItem={item} key={item.label} />
        ))}
      </nav>
      <div className={styles.offsetElement} />
    </>
  );
};

export default TabBar;
