import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../utils';
import {
  Cart,
  ConfirmAddressModal,
  ErrorToast,
  Page,
  ShippingAddress,
  Sms,
  ModalContainer,
  Banner,
} from '../../components';
import {
  getAllDetails,
  selectStreamOnPromo,
  setConfirmModal,
} from '@mfe/to-be-migrated/redux/streamOnPromo';
import { AnalyticsEventSource } from '../../lib/analytics/pixel-analytics-type';
import styles from './orderReview.module.scss';
import {
  AnalyticsEventNames,
  selfDescribingEvent,
} from '@mfe/to-be-migrated/redux/analytics';
import TermsAndConditionsModal from '../../components/TermsAndConditionsModal';
import { Txt, Spinner } from '@vst/beam';
import { useScreenResolution, useTrackTiming } from '@mfe/shared/util';

export const OrderReview = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    isOrderSubmitting,
    isScrubbingLoading,
    confirmModal,
    offerDetails,
    didOrderFail,
  } = useSelector(selectStreamOnPromo);
  const { shouldDisplaySms } = offerDetails;
  const { t } = useTranslation('StreamOnPromo:Shipping.AddressScrub');
  const [showTermsModal, setShowTermsModal] = useState(false);

  const { isMedium, isSmall, isExtraSmall } = useScreenResolution();
  const isMediumOrLess = isMedium || isSmall || isExtraSmall;

  useEffect(() => {
    if (!offerDetails.productTypeId) dispatch(getAllDetails());

    dispatch(
      selfDescribingEvent({
        vendor: 'com.viasat.care',
        eventName: AnalyticsEventNames.AddonsPurchaseFlowInitiated,
        context: [
          {
            schema: 'addons_context',
            data: {
              product_name: offerDetails.name,
              product_type_id: offerDetails.productTypeId,
              product_family: offerDetails.productFamily,
              source: AnalyticsEventSource.OVERVIEW_PAGE,
              product_price: offerDetails.price,
              discount_amount: offerDetails.discountValue ?? 0,
              discount_duration: offerDetails.discountDuration ?? 0,
            },
          },
        ],
      })
    );
  }, [offerDetails]);

  useTrackTiming({
    eventName: 'StreamOnOfferDetailsLoaded',
    isLoading: offerDetails.loading ?? false,
  });

  useTrackTiming({
    eventName: 'StreamOnScrubAddressLoaded',
    isLoading: isScrubbingLoading,
  });

  if (offerDetails.loading) {
    <div className={styles['spinnerContainer']}>
      <Spinner />
    </div>;
  }

  return (
    <Page>
      {confirmModal && !isScrubbingLoading && (
        <ModalContainer id="Address">
          <ConfirmAddressModal
            onClose={() => dispatch(setConfirmModal(false))}
            show={confirmModal}
          />
        </ModalContainer>
      )}
      {isScrubbingLoading && (
        <ModalContainer id="Address">
          <div className={styles['scrubSpinnerContainer']}>
            <Spinner />
            <Txt color="subtle" variant="bodySmallRegular">
              {t('Loading')}
            </Txt>
          </div>
        </ModalContainer>
      )}
      {didOrderFail.didOrderFail && !isOrderSubmitting && <ErrorToast />}
      {showTermsModal && (
        <ModalContainer id="Terms">
          <TermsAndConditionsModal setShowTermsModal={setShowTermsModal} />
        </ModalContainer>
      )}
      <div className={styles['bannerContainer']}>
        <Banner />
      </div>
      <div className={styles['pageLayout']}>
        <div
          className={styles['pageContent']}
          style={
            isMediumOrLess
              ? { display: 'flex', flexDirection: 'column' }
              : { display: 'flex', flexDirection: 'row' }
          }
        >
          <div className={styles['container']} data-cy="shippingAddress">
            <ShippingAddress />
            {shouldDisplaySms && <Sms />}
          </div>
          <Cart setShowTermsModal={setShowTermsModal} />
        </div>
      </div>
    </Page>
  );
};
