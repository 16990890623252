import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Router } from '@vst/beam-icons/icons';
import { InlineLink, SectionAlert, Txt } from '@vst/beam';

import { selectingPendingInstallation } from '@mfe/to-be-migrated/redux/pendingInstallation';
import { formatTimeInterval } from '@mfe/to-be-migrated/redux/scheduleInstallation';

import styles from './styles.module.scss';

interface ScheduledAlertProps {
  handleRedirect: () => void;
}

export const ScheduledAlert = ({ handleRedirect }: ScheduledAlertProps) => {
  const { t } = useTranslation('Profile');

  const { newOfferName, schedule } = useSelector(selectingPendingInstallation);
  const date = format(new Date(schedule?.from), 'LLL d');
  const interval = formatTimeInterval([schedule?.from, schedule?.to]);

  return (
    <SectionAlert variant="infoPrimary" icon={Router}>
      <div className={styles['content']}>
        <Txt
          data-cy="text"
          component="span"
          variant="smallRegular"
          color="regular"
        >
          {t('scheduledAlert.placedOrder', { newOfferName })}
        </Txt>
        <Txt
          data-cy="highlight"
          component="span"
          variant="smallBold"
          color="regular"
        >
          {t('scheduledAlert.installationDate', {
            date,
            interval,
          })}
        </Txt>
        .
        <InlineLink
          data-cy="cta"
          variant="primary"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleRedirect();
          }}
        >
          {t('scheduledAlert.cta')}
        </InlineLink>
      </div>
    </SectionAlert>
  );
};
