import type {
  Invoice,
  Payment,
  PendingPayment,
} from '@mfe/shared/schema-types';

export const getPageOfT = <T>(
  items: T[],
  pageNum: number,
  showMoreClicked: boolean,
  itemsPerPage: number
): T[] => {
  if (!showMoreClicked) return items.slice(0, 3);
  return items.filter((_, i) => {
    return i >= (pageNum - 1) * itemsPerPage && i < pageNum * itemsPerPage;
  });
};

export const getNumberOfPages = (
  items: Invoice[] | (Payment | PendingPayment)[],
  itemsPerPage: number
) => {
  return items.length < itemsPerPage
    ? 1
    : Math.ceil(items.length / itemsPerPage);
};
