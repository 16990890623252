import React, { useState } from 'react';
import { StyleSheet, View, TouchableOpacity, Platform } from 'react-native';
import MaterialIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { PasswordIconProps, IconProps } from './types';
import { Text } from '../Text';
import { CloseX } from '../Icons';
import { Color } from '../../utils';
import { PasswordInput } from '.';
import { isDefined } from '../../utils';

const styles = StyleSheet.create({
  checkIcon: {
    padding: 4,
    paddingLeft: 12,
    paddingRight: 7,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  passwordText: {
    flexDirection: 'column',
    paddingTop: 6,
  },
});

export const CheckIcon = ({
  size = 15,
  color = Color.green500,
  style,
}: IconProps): JSX.Element => (
  <MaterialIcon name="check" size={size} color={color} style={style} />
);

export const PasswordIcon = ({
  size,
  active,
  style,
  error,
  onPress,
  secureTextEntry,
  testID,
}: PasswordIconProps): JSX.Element => {
  const [hover, setHover] = useState<boolean>(false);
  const [icon, setIcon] = useState<boolean>(secureTextEntry);
  const color = active ? Color.teal500 : Color.gray400;
  const hoverColor = error ? Color.red400 : Color.teal600;
  const errorColor = error ? Color.red400 : color;

  const handleIcon = (): void => {
    setIcon(!icon);
  };

  return (
    <View>
      <TouchableOpacity
        testID={`${testID}-${icon ? 'Show' : 'Hide'}Pass`}
        {...(Platform.OS === 'web' && {
          onMouseEnter: (): void => setHover(true),
          onMouseLeave: (): void => setHover(false),
        })}
        onPress={onPress}
        onPressIn={handleIcon}
      >
        <MaterialIcon
          testID={`${testID}-Icon${icon ? 'Show' : 'Hide'}`}
          name={icon ? 'eye' : 'eye-off'}
          size={size}
          color={hover ? hoverColor : errorColor}
          style={style}
        />
      </TouchableOpacity>
    </View>
  );
};

// TODO: Internationalization
export const PasswordText = ({
  lowercase,
  charLength,
  uppercase,
  number,
}: PasswordInput): JSX.Element | null => {
  const passwordTextProperties = [
    {
      flag: lowercase,
      message: 'password must contain at least one lowercase letter',
    },
    {
      flag: charLength,
      message: 'password must be between 8-25 characters, no spaces',
    },
    {
      flag: uppercase,
      message: 'password must contain at least one uppercase letter',
    },
    {
      flag: number,
      message: 'password must contain at least one number',
    },
  ];
  return (
    <View style={styles.passwordText}>
      {passwordTextProperties.map((properties): JSX.Element | null =>
        isDefined(properties.flag) ? (
          <View style={styles.row} key={properties.message}>
            {properties.flag ? (
              <CheckIcon style={styles.checkIcon} />
            ) : (
              <CloseX
                color={Color.red400}
                style={{ marginLeft: 6, marginRight: 3 }}
                size={8}
                width={24}
                unresponsive
              />
            )}
            <Text caption>{properties.message}</Text>
          </View>
        ) : null
      )}
    </View>
  );
};

export const PasswordMustMatch = (
  doesNotMatch: boolean
): JSX.Element | null => {
  return doesNotMatch ? (
    <View style={[styles.row, { paddingTop: 6 }]}>
      <CloseX
        color={Color.red400}
        style={{ marginLeft: 6, marginRight: 3 }}
        size={8}
        width={24}
        unresponsive
      />
      <Text caption>passwords must match</Text>
    </View>
  ) : null;
};
