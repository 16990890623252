import styles from './styles.module.scss';

export const LoadingContent = () => {
  return (
    <div data-cy="loading-content">
      <div className={styles['iconLoading']} />
      <div className={styles['titleLoading']} />
      <div className={styles['descriptionLoading']} />
    </div>
  );
};
