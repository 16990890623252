import { useParams } from '@mfe/legacy/andromeda';

interface AnalyticsLabel {
  label: string;
}

export type AnalyticsReferrer = AnalyticsLabel | undefined;

export interface ContactPhoneProps {
  referrer?: AnalyticsReferrer;
  button?: boolean;
  testID?: string;
  showText?: boolean;
}

export type PhoneButtonProps = Pick<ContactPhoneProps, 'referrer' | 'button'>;

export const getAnalyticsReferrer = (): AnalyticsReferrer => {
  try {
    const {
      params: { analyticsLabel },
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useParams();
    return { label: analyticsLabel };
  } catch (e) {
    return undefined;
  }
};
