import { Txt } from '@vst/beam';
import { FlowFailureOutcome, Container } from '@mfe/shared/components';
import { useTranslation } from 'react-i18next';

export interface AvailableInstallationDatesFailureProps {
  goToOverview: () => void;
  refreshPage: () => void;
}

export const AvailableInstallationDatesFailure = ({
  goToOverview,
  refreshPage,
}: AvailableInstallationDatesFailureProps) => {
  const { t } = useTranslation('NewScheduleInstallation', {
    keyPrefix: 'UnableToLoadAppointmentTimes',
  });

  return (
    <Container mt="56px">
      <FlowFailureOutcome goToOverview={goToOverview} refreshPage={refreshPage}>
        <Txt
          data-cy="error-available-installation-dates-title"
          mt="48px"
          mb="12px"
          variant="heading4"
        >
          {t('title')}
        </Txt>

        <Txt
          variant="bodyLargeRegular"
          data-cy="error-available-installation-dates-description"
          color="subtle"
          mb="40px"
        >
          {t('description')}
        </Txt>
      </FlowFailureOutcome>
    </Container>
  );
};
