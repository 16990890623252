import { Checkbox, HelperText, InlineLink, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import styles from './Form.module.scss';

interface TermsProps {
  value: boolean;
  error: string;
  isValid: boolean;
  openTermsAndConditions: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  onChange: React.FormEventHandler<HTMLInputElement>;
}

export const TermsAndConditions = (props: TermsProps) => {
  const { onChange, value, error, openTermsAndConditions, isValid } = props;
  const hasError = error !== '' && !isValid;

  const { t } = useTranslation('ACP', { keyPrefix: 'Form' });

  return (
    <div className={styles['terms']}>
      <div className={styles['checkbox']}>
        <Checkbox
          data-cy="acp-terms-conditions-check"
          checked={value}
          error={hasError}
          id="terms-and-conditions"
          onChange={onChange}
        />
        <Txt variant="smallRegular">
          {t('termsAndConditions.start')}
          <InlineLink
            data-cy="acp-terms-conditions-button"
            href="#"
            variant="primary"
            onClick={(e) => openTermsAndConditions(e)}
          >
            {t('termsAndConditions.linkLabel')}
          </InlineLink>
          {t('termsAndConditions.end')}
        </Txt>
      </div>
      {hasError && !value && (
        <HelperText
          state="error"
          helpMessage={error}
          className={styles['helper-text']}
        />
      )}
    </div>
  );
};
