import { ProductType } from '@mfe/shared/schema-types';

export function getShortenedZipCode(zipCode: string) {
  return zipCode.split('-')[0];
}

export const sortProductTypesByDisplayOrder = (
  a: ProductType,
  b: ProductType
) => {
  const aOrder = a.marketing_copy.ui_behaviors.characteristics?.find(
    (characteristic) => characteristic.name === 'DISPLAY_ORDER'
  )?.value;
  const bOrder = b.marketing_copy.ui_behaviors.characteristics?.find(
    (characteristic) => characteristic.name === 'DISPLAY_ORDER'
  )?.value;

  if (aOrder && bOrder) {
    if (aOrder === bOrder) {
      return 0;
    }

    return aOrder > bOrder ? 1 : -1;
  }

  if (aOrder) {
    return 1;
  }

  if (bOrder) {
    return -1;
  }

  return 0;
};
