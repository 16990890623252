import { Payment, PaymentStatus } from '@mfe/shared/schema-types';
import { BOLETO } from '@mfe/shared/util';

export const findBoletoPayment =
  (invoiceNumber?: string | null) =>
  (payment: Payment): boolean =>
    !payment.isExpired &&
    payment.paymentMethod === BOLETO &&
    payment.invoiceNum === invoiceNumber &&
    payment.paymentStatus === PaymentStatus.Requested;

export const getPreinstallBoleto = (payments?: Payment[] | null) => {
  const currentBoleto = payments?.find(
    (payment) =>
      payment.barCode && payment.paymentMethod === BOLETO && !payment?.isExpired
  );
  const expiredBoleto = payments?.find(
    (payment) =>
      payment.barCode && payment.paymentMethod === BOLETO && payment?.isExpired
  );
  const preinstallBoleto = currentBoleto ?? expiredBoleto;
  return preinstallBoleto;
};
