import { useEffect, useState } from 'react';
import { InlineLink, Txt, Icon } from '@vst/beam';
import { Close } from '@vst/beam-icons/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  ACP_BENEFIT_REGISTERED_KEY,
  HIDE_ACP_BANNER_KEY,
} from '@mfe/features/acp';
import {
  selfDescribingEvent,
  AnalyticsEventNames,
  DisplayLocationEnum,
  selectAnalytics,
} from '@mfe/to-be-migrated/redux/analytics';
import { Storage } from '@mfe/shared/util';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { AccountType, Locale } from '@mfe/shared/schema-types';

import styles from './ACPBanner.module.scss';

type ACPBannerProps = {
  navigateToACPPage: () => void;
};

// TODO: This could be moved to @mfe/features/acp
export const ACPBanner = ({ navigateToACPPage }: ACPBannerProps) => {
  const { t } = useTranslation('ACPBanner');
  const dispatch = useDispatch();

  const [hideForCurrentSession, setHideForCurrentSession] = useState(true);

  const { loading: userLoading, user } = useSelector(selectUser);
  const { loading: userInfoLoading, userInfo } = useSelector(selectUserInfo);
  const isDataLoading = userLoading || userInfoLoading;

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);
  const isUSresidentialCustomer =
    userLocale === Locale.EnUs &&
    userInfo.accountType === AccountType.Residential;

  const shouldBeDisplayed =
    !isDataLoading &&
    userInfo.isACPEligible &&
    isUSresidentialCustomer &&
    !user.hasCaf &&
    !user.isACPSuspended &&
    !hideForCurrentSession;

  useEffect(() => {
    determineDisplayOptions();

    function determineDisplayOptions() {
      Promise.all([
        Storage.getItem(ACP_BENEFIT_REGISTERED_KEY),
        Storage.getItem(HIDE_ACP_BANNER_KEY),
      ]).then(([hasRegistered, hasDismissed]: [boolean, boolean]) => {
        setHideForCurrentSession(hasRegistered || hasDismissed);
      });
    }
  }, []);

  useEffect(() => {
    if (shouldBeDisplayed) {
      dispatch(
        selfDescribingEvent({
          eventName: AnalyticsEventNames.ACPBannerDisplayed,
          data: {
            display_platform: DisplayLocationEnum.Body,
          },
        })
      );
    }
  }, [dispatch, shouldBeDisplayed]);

  const { display_platform, usageContext } = useSelector(selectAnalytics);

  function dismissBanner() {
    Storage.setItem(HIDE_ACP_BANNER_KEY, true);
    setHideForCurrentSession(true);
    dispatchSelfDescribingEvent(AnalyticsEventNames.ACPBannerClosed);
  }

  function enterFlow() {
    dispatchSelfDescribingEvent(AnalyticsEventNames.ACPBannerSelected);
    navigateToACPPage();
  }

  function dispatchSelfDescribingEvent(eventName: AnalyticsEventNames) {
    dispatch(
      selfDescribingEvent({
        eventName,
        vendor: 'com.viasat.care',
        data: {
          display_location: 'header',
          display_zone: 'overview_page',
          display_platform,
        },
        context: [usageContext],
      })
    );
  }

  if (!shouldBeDisplayed) {
    return null;
  }

  return (
    <div className={styles['container']} data-cy="acp-banner">
      <div className={styles['content']}>
        <div>
          <Txt
            variant="bodySmallRegular"
            component="span"
            className={styles['message']}
          >
            {t('overview.message')}
          </Txt>{' '}
          <span>
            <InlineLink
              href="#"
              className={styles['message']}
              data-cy="acp-check-eligibility"
              onClick={(e) => {
                e.preventDefault();
                enterFlow();
              }}
            >
              {t('overview.link')}
            </InlineLink>
          </span>
        </div>
        <div>
          <button
            className={styles['close']}
            onClick={dismissBanner}
            data-cy="acp-banner-x"
          >
            <Icon icon={Close} surface="primary" color="gray_300" />
          </button>
        </div>
      </div>
    </div>
  );
};
