import { Loading } from '@mfe/legacy/andromeda';

const FullPageLoading = (): JSX.Element => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    }}
  >
    <Loading size={120} />
  </div>
);

export default FullPageLoading;
