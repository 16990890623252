import { Txt } from '@vst/beam';

import styles from './styles.module.scss';

type Props = React.PropsWithChildren<{
  title: string;
  style?: React.CSSProperties;
}>;

export const TitleCard = ({ title, children }: Props) => {
  return (
    <TitleText title={title} style={{ paddingBottom: 12 }}>
      <div className={styles['card']}>{children}</div>
    </TitleText>
  );
};

export const TitleText = ({ title, style, children }: Props) => {
  return (
    <div className={styles['container']}>
      <div className={styles['title']} style={style}>
        <Txt variant="labelSmall" color="subtle">
          {title}
        </Txt>
      </div>
      {children}
    </div>
  );
};
