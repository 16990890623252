import {
  Categories,
  structuredEvent,
} from '@mfe/to-be-migrated/redux/analytics';
import { postProfileMessage } from '@mfe/to-be-migrated/redux/utils';
import {
  setPushNotificationState,
  selectPushNotifications,
} from '@mfe/to-be-migrated/redux/push-notifications';
import { Switch } from '@vst/beam';
import { sendMessageToMobileApp } from '@mfe/shared/cross-platform-events';
import { EventTypes } from '@mfe/services/window-messages';
import { Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';

const PushNotification = ({
  displayContext,
  profileContext,
}: {
  displayContext: any;
  profileContext: any;
}): JSX.Element => {
  const { t } = useTranslation('NewProfile');
  const dispatch = useDispatch();
  const { enabled } = useSelector(selectPushNotifications);

  const pushNotifClick = () => {
    const shouldSubscribeToPushNotifications = !enabled;
    dispatch(
      postProfileMessage({
        eventType: EventTypes.PushNotification,
        data: shouldSubscribeToPushNotifications,
      })
    );

    dispatch(
      setPushNotificationState({ enabled: shouldSubscribeToPushNotifications })
    );
    dispatch(
      sendMessageToMobileApp({
        type: 'set-push-notification-status',
        data: { shouldSubscribeToPushNotifications },
      })
    );

    dispatch(
      structuredEvent({
        category: Categories.Profile,
        action: 'Push Notification Toggle',
        params: {
          property: shouldSubscribeToPushNotifications ? 'on' : 'off',
          context: [displayContext, profileContext],
        },
      })
    );
  };

  return (
    <div
      data-cy="profile-push-notif-card"
      className={styles['notificationCard']}
    >
      <Txt variant="bodyLargeRegular" mr="24px">
        {t('notificationMessage')}
      </Txt>
      <Switch
        id="pushNotifSwitch"
        checked={enabled}
        onChange={pushNotifClick}
      />
    </div>
  );
};

export default PushNotification;
