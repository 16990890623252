import { format } from 'date-fns';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, StandAloneLink, Surface, Txt } from '@vst/beam';

import {
  AlertScheduleInstallation,
  DatePicker,
  TimePicker,
  AlertAction,
  ScheduleInstallationContext,
  OnSiteContactInformation,
  ScheduleInstallationForm,
} from '@mfe/shared/components';
import { useScreenResolution } from '@mfe/shared/util';
import { selectChangePlan } from '@mfe/to-be-migrated/redux/changePlan';
import { selectScheduleInstallation } from '@mfe/to-be-migrated/redux/scheduleInstallation';

import { CalendarLoading } from './calendar-loading';

import styles from './form.module.scss';

interface FormProps {
  validationMessageType: AlertAction;
  handleBackNavigation: (e: React.MouseEvent<HTMLElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

export const Form = ({
  handleSubmit,
  handleBackNavigation,
  validationMessageType,
}: FormProps) => {
  const { t } = useTranslation('NewScheduleInstallation');

  const {
    textInput,
    setTextInput,
    setTextAreaInput,
    textAreaMaxLength,
    calendarLocale,
    selectedDate,
    selectedInterval,
    setSelectedInterval,
    handleDateSelection,
    showAlert,
    setShowAlert,
    phoneNumberInput,
    setPhoneNumberInput,
    isPhoneNumberValid,
    isNameValid,
    textFieldRef,
    phoneFieldRef,
  }: any = useContext(ScheduleInstallationContext);

  const { loading: availableDatesLoading, availableDates } = useSelector(
    selectScheduleInstallation
  );

  const { loading: changePlanLoading } = useSelector(selectChangePlan);

  const loading = availableDatesLoading || changePlanLoading;

  const { isExtraSmall, isSmall, isMedium } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;
  const isMobileOrTablet = isMobile || isMedium;
  const isDesktop = !isMobileOrTablet;

  const alertRef = useRef<HTMLDivElement>(null);

  const availableDays = useMemo(() => {
    if (!loading && availableDates) {
      return Object.keys(availableDates).map((date: string) => {
        const currentDate = new Date(date);
        const processedCurrentDate = new Date(
          currentDate.valueOf() + currentDate.getTimezoneOffset() * 60 * 1000
        );
        return processedCurrentDate;
      });
    }
    return [];
  }, [availableDates, loading]);

  const formattedSelectedDate = format(
    new Date(
      selectedDate.year as number,
      (selectedDate.month as number) - 1,
      selectedDate.day as number
    ),
    'yyyy-MM-dd'
  );

  const intervals = useMemo(() => {
    if (!loading && availableDates) {
      return availableDates[formattedSelectedDate];
    }
    return [];
  }, [loading, availableDates, formattedSelectedDate]);

  const scrollToElement = () => {
    if (alertRef.current) {
      alertRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  useEffect(() => setShowAlert(false), [selectedDate, setShowAlert]);

  useEffect(() => {
    scrollToElement();
  }, [showAlert]);

  return (
    <>
      <ScheduleInstallationForm
        className={styles['form']}
        initialValues={{
          'appointment-contact': textInput,
          phone: phoneNumberInput,
        }}
        handleSubmit={handleSubmit}
      >
        <Txt variant="bodyLargeRegular">{t('calendar.changePlanStepInfo')}</Txt>
        <Txt className={styles['calendar-title']} variant="bodyLargeBold">
          {t('calendar.title')}
        </Txt>
        {loading ? (
          <CalendarLoading />
        ) : (
          <Surface className={styles['form-content']}>
            <DatePicker
              locale={calendarLocale}
              selectedDate={selectedDate}
              handleDateSelection={handleDateSelection}
              availableDays={availableDays}
            />
            {selectedDate.day && (
              <Surface mt="40px">
                <TimePicker
                  intervals={intervals}
                  selectedInterval={selectedInterval}
                  setSelectedInterval={setSelectedInterval}
                  onTimeIntervalClick={() => setShowAlert(true)}
                />
              </Surface>
            )}
            {showAlert && (
              <Surface mt="16px" ref={alertRef}>
                <AlertScheduleInstallation
                  action={validationMessageType}
                  selectedDate={selectedDate}
                  selectedInterval={selectedInterval}
                />
              </Surface>
            )}
            <OnSiteContactInformation
              setTextInput={setTextInput}
              setTextAreaInput={setTextAreaInput}
              textAreaMaxLength={textAreaMaxLength}
              phoneNumberInput={phoneNumberInput}
              setPhoneNumberInput={setPhoneNumberInput}
              isPhoneNumberValid={isPhoneNumberValid}
              isNameValid={isNameValid}
              textFieldProps={{
                ref: textFieldRef,
                name: 'appointment-contact',
              }}
              phoneFieldProps={{
                ref: phoneFieldRef,
                name: 'phone',
              }}
            />
          </Surface>
        )}
      </ScheduleInstallationForm>
      {isDesktop && (
        <div className={styles['form-footer']}>
          <StandAloneLink
            href="#"
            underline={true}
            data-cy="back-button"
            onClick={handleBackNavigation}
          >
            {t('backToPlanReview')}
          </StandAloneLink>
          <Button
            form="schedule-installation-form"
            disabled={loading}
            data-cy="schedule-installation-button"
            type="submit"
            variant="primary"
            fluid={isMobile}
            style={{ minWidth: '224px' }}
            onClick={() => setShowAlert(true)}
          >
            {t('contactInformation.placeOrder')}
          </Button>
        </div>
      )}
    </>
  );
};
