import { useEffect, useState } from 'react';
import messaging from '@react-native-firebase/messaging';
import StorageManager from '../../services/localStorage';
import { useAccountStatus } from '../../utils';
import env from '../../env';
import { Platform } from 'react-native';
import { LogEventFunction } from '../../containers';

const sleep = (duration: number): Promise<void> => {
  return new Promise((resolve): any => setTimeout(resolve, duration));
};

const PUSH_ENABLED = 'PUSH_ENABLED';

export interface UpdateStatusProps {
  pushNotificationStatus: boolean;
  accountReference: string;
  logger: (error: Error) => void;
  updateSubscription: (props: any) => any;
  trackEvent: LogEventFunction;
}

export const updatePushNotificationStatus = async ({
  pushNotificationStatus,
  accountReference,
  trackEvent,
  logger,
  updateSubscription,
}: UpdateStatusProps): Promise<void> => {
  if (Platform.OS !== 'ios' && Platform.OS !== 'android') {
    return;
  }
  try {
    const currentToken = await messaging().getToken();
    const permission = await messaging().requestPermission();
    const isRegistered = messaging().isDeviceRegisteredForRemoteMessages;
    if (permission) {
      if (!isRegistered) {
        await messaging().registerDeviceForRemoteMessages();
      }
      let newToken = '';
      try {
        newToken = await messaging().getToken();
      } catch (e) {
        // This case only happened on ios device
        // when they hit accept push the first time it does
        // not go through so we sleep and try in 1000 ms
        await sleep(1000);
        newToken = await messaging().getToken();
      }
      if (!pushNotificationStatus) {
        await StorageManager.delete(`${PUSH_ENABLED}${accountReference}`);
      } else {
        await StorageManager.set(
          `${PUSH_ENABLED}${accountReference}`,
          newToken
        );
      }
      updateSubscription({
        variables: {
          input: {
            subscriptionId: newToken,
            subscribe: pushNotificationStatus,
            priorSubscriptionId: currentToken,
          },
        },
      });
      trackEvent(
        'Push Notifications',
        pushNotificationStatus ? 'enabled' : 'disabled'
      );
    }
  } catch (e: any) {
    if (env.debug.dev) console.log('Push permission rejected');
    logger(new Error(e));
  }
};

export const useGetPushNotificationStatus = (): boolean => {
  const { accountReference } = useAccountStatus();
  const [status, setStatus] = useState(false);

  const isSubscribed = async (): Promise<void> => {
    const isSubscribed = await StorageManager.get(
      `${PUSH_ENABLED}${accountReference}`
    );
    if (isSubscribed) {
      setStatus(true);
    } else setStatus(false);
  };
  useEffect((): void => {
    if (Platform.OS === 'ios' || Platform.OS === 'android') isSubscribed();
  }, []);

  return status;
};
