import * as React from 'react';

import { Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useScreenResolution } from '@mfe/shared/util';
import { selectConfig } from '@mfe/shared/redux/config';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { AccountType } from '@mfe/shared/schema-types';

import styles from './Overview.module.scss';

export const Header = () => {
  const { t } = useTranslation('Overview');
  const { loadingConfig } = useSelector(selectConfig);
  const { userInfo, loading: loadingUser } = useSelector(selectUserInfo);
  const { accountNumber } = userInfo;
  const { isExtraLarge, isLarge, isMedium } = useScreenResolution();
  const isDesktop = isExtraLarge || isLarge || isMedium;

  const time = determineTimeOfDay();
  const loading = loadingUser || loadingConfig;

  const name = useAccountName();

  const greetingText = t(name ? 'greeting' : 'greetingLoading', {
    context: time,
    name,
  });

  return (
    <div className={styles['header']}>
      <Txt variant={isDesktop ? 'heading3' : 'heading4'} component="p">
        {greetingText}
      </Txt>
      <div className={styles['accountNumber']}>
        <Txt variant="bodyLargeRegular" component="span" mr="4px">
          {t('accountNumber')}
        </Txt>
        {loading ? (
          <span className={styles['accountNumberLoading']} />
        ) : (
          <Txt variant="bodyLargeRegular" component="span">
            {accountNumber}
          </Txt>
        )}
      </div>
    </div>
  );
};

const useAccountName = () => {
  const { userInfo, loading } = useSelector(selectUserInfo);
  const { firstName, username, accountHolder, accountType } = userInfo;

  return React.useMemo(() => {
    if (loading) {
      return '';
    }

    if (accountType === AccountType.Smb) {
      return accountHolder.firstName ?? username;
    }

    return firstName ?? username;
  }, [accountHolder, accountType, firstName, loading, username]);
};

function determineTimeOfDay() {
  const currentHour = new Date().getHours();

  if (currentHour < 12) {
    return 'MORNING';
  }

  if (currentHour < 18) {
    return 'AFTERNOON';
  }

  return 'EVENING';
}
