export const LINKS = {
  NATIONAL_VERIFIER: 'https://nv.fcc.gov/lifeline/?id=nv_flow&ebbp=true',
  ACP_VIASAT:
    'https://www.viasat.com/satellite-internet/affordable-connectivity-program/current-customer/',
};

export const I18N_NAMESPACE = 'ACP';

export const ACP_BENEFIT_REGISTERED_KEY = 'ACPBenefitSuccessfulyRegistered';

export const HIDE_ACP_BANNER_KEY = 'hideACPBanner';
