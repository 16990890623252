import { useTranslation } from 'react-i18next';

import { HeaderWithBackNavigation } from '@mfe/shared/components';

import AddonsGrid from '../addons-grid';
import AddonsFooter from '../add-ons-footer';
import AddonsDisclosures from '../add-ons-disclosures';

import styles from './index.module.scss';

type ShopAddonsProps = {
  navigateToPreviousPage: () => void;
};

export const ShopAddons = ({ navigateToPreviousPage }: ShopAddonsProps) => {
  const { t } = useTranslation('ShopAddons');

  return (
    <div className={styles['layout']}>
      <div>
        <HeaderWithBackNavigation
          title={t('selectAddon.title')}
          handleBackNavigation={navigateToPreviousPage}
          goBackLabel={t('goBack')}
        />
      </div>
      <AddonsGrid />
      <div>
        <AddonsFooter />
        <AddonsDisclosures />
      </div>
    </div>
  );
};
