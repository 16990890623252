import { Surface, Icon, IIconProps, colors } from '@vst/beam';
import {
  Language,
  LiveTvOutlined,
  SecurityOutlined,
} from '@vst/beam-icons/icons';

export enum AddonProductTypeIds {
  SHIELD = '49162977-8ebf-460b-8908-368ee1f8673d',
  STREAM = 'd46ae641-7779-42e5-ba5e-479ef09648c5',
  STATIC_IP = '77a013f1-6947-4045-939d-67ec292e78ef',
}

export const AddonIcon = ({
  productTypeId,
}: {
  productTypeId: AddonProductTypeIds;
}): JSX.Element | null => {
  let iconProps: {
    icon: any;
    color: IIconProps['color'];
    backgroundColor: string;
  };

  switch (productTypeId) {
    case AddonProductTypeIds.SHIELD:
      iconProps = {
        icon: SecurityOutlined,
        color: 'warm_400',
        backgroundColor: colors['warm'][100],
      };
      break;
    case AddonProductTypeIds.STREAM:
      iconProps = {
        icon: LiveTvOutlined,
        color: 'teal_700',
        backgroundColor: colors['teal'][100],
      };
      break;
    case AddonProductTypeIds.STATIC_IP:
      iconProps = {
        icon: Language,
        color: 'violet_500',
        backgroundColor: colors['violet'][100],
      };
      break;
    default:
      return null;
  }

  return (
    <Surface
      data-cy="droppedAddonsIcon"
      style={{ backgroundColor: iconProps.backgroundColor }}
      radius="8px"
      p="8px"
    >
      <Icon size={'16'} icon={iconProps.icon} color={iconProps.color} />
    </Surface>
  );
};
