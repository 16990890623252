import type {
  MobileAppToWebEvents,
  LegacyMobileAppToWebEvents,
  SubscribeToPushNotifications,
  UnsubscribeFromPushNotifications,
} from '@mfe/shared/cross-platform-events';

export interface WindowMessageData {
  eventType?: string;
  type?: string;
  data?: any;
}

export interface MobileMessageHandlerDependencies {
  receivePushNotificationStatus: (data: boolean) => void;
  subscribeToPushNotifications: (
    data: SubscribeToPushNotifications['data']
  ) => void;
  unsubscribeToPushNotifications: (
    data: UnsubscribeFromPushNotifications['data']
  ) => void;
}

export const mobileMessageHandler = (
  message: WindowMessageData,
  dependencies: MobileMessageHandlerDependencies
) => {
  const { type, data } = {
    type: message.type ?? message.eventType ?? null,
    data: message.data,
  } as MobileAppToWebEvents | LegacyMobileAppToWebEvents;

  if (!type) return;

  switch (type) {
    case 'subscribe-to-push-notifications':
      dependencies.subscribeToPushNotifications(data);
      break;
    case 'unsubscribe-from-push-notifications':
      dependencies.unsubscribeToPushNotifications(data);
      break;
    // Legacy events
    case 'PushNotification':
      dependencies.receivePushNotificationStatus(data);
      break;
  }
};
