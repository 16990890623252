import { Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { getDate } from '@mfe/shared/util';
import type { Payment, PendingPayment } from '@mfe/shared/schema-types';

import PaymentStatusChip from './payment-status-chip';

import sharedStyles from '../../shared/styles.module.scss';
import styles from './styles.module.scss';

type Props = {
  payment: Payment | PendingPayment;
};

const PaymentSummary = ({ payment }: Props): JSX.Element => {
  const { t } = useTranslation('BillingPayment');
  const paymentDate = payment?.paymentDate
    ? getDate(payment.paymentDate)
    : undefined;

  return (
    <div className={styles['paymentRowContainer']}>
      <div className={styles['body']}>
        <div className={sharedStyles['flexColumn']}>
          <div className={styles['bodySubHeader']}>
            <Txt component="p" variant="labelMedium">
              {t('date')}
            </Txt>
            <PaymentStatusChip paymentStatus={payment.txnStatus} />
          </div>
          <Txt
            component="p"
            variant="bodySmallRegular"
            style={{ textTransform: 'capitalize', textAlign: 'left' }}
          >
            {t('paymentDate', { paymentDate })}
          </Txt>
        </div>

        <div
          className={sharedStyles['flexColumn']}
          style={{ textAlign: 'right' }}
        >
          <Txt
            component="p"
            variant="labelMedium"
            className={styles['bodySubHeader']}
          >
            {t('amount')}
          </Txt>
          <Txt component="p" variant="bodySmallRegular">
            {payment.paymentAmount?.text}
          </Txt>
        </div>
      </div>
    </div>
  );
};

export default PaymentSummary;
