import React from 'react';

import { RadioButton, Txt } from '@vst/beam';
import styles from './SelectionCard.module.scss';

interface SelectionCardProps {
  checked: boolean;
  className?: string;
  labelRadioButton: string;
  onClickHandler: () => void;
  subtitle?: string;
  value: string;
}

export const SelectionCard: React.FC<SelectionCardProps> = ({
  labelRadioButton,
  subtitle,
  checked,
  onClickHandler,
  value,
}) => {
  return (
    <div
      data-cy="cardContainer"
      className={
        checked
          ? `${styles['containerAddressButtons']} ${styles['containerAddressButtonsChecked']}`
          : `${styles['containerAddressButtons']} ${styles['containerAddressButtonsUnChecked']}`
      }
    >
      <div className={styles['wrapper']}>
        <div data-cy="radioButton" onClick={onClickHandler}>
          <Txt data-cy="radioButtonLabel" variant="heading5" mb="16px">
            {labelRadioButton}
          </Txt>
          <div className={styles['radioButtonContainer']}>
            {/*
             * `nonce` isn't supposed to be a required
             * prop. BEAM team will address this in a future
             * release
             *
             * https://viasat.slack.com/archives/C02BEV69HAQ/p1676986409336519?thread_ts=1676931679.737829&cid=C02BEV69HAQ
             */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <RadioButton
              label={''}
              value={value}
              checked={checked}
              className={styles['radioButton']}
            />
            <Txt data-cy="addressLocation" variant="paragraphRegular">
              {subtitle}
            </Txt>
          </div>
        </div>
      </div>
    </div>
  );
};
