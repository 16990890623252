import * as React from 'react';
import { Txt, Button } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';

import { getUnpaidInvoice } from '@mfe/shared/util';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { Issuer, selectConfig } from '@mfe/shared/redux/config';
import { trackBillingEvent } from '@mfe/to-be-migrated/redux/analytics';
import { BillingStatus, Locale, Invoice } from '@mfe/shared/schema-types';
import { selectBillingInfo } from '@mfe/to-be-migrated/redux/billingInfo';

import { goToPayment } from '../../../shared/navigation';
import { ANALYTICS_EVENT_NAME } from '../../../shared/constants';
import { InvoiceStatusChip } from '../../invoice-status-chip';
import { BillingAutoPayMethod } from '../autopay-method';
import { BillingSummaryHeader } from '../billing-summary-header';

import styles from './styles.module.scss';

const Summary = (): JSX.Element => {
  const dispatch = useDispatch();
  const { ref: balanceRef, inView: balanceInView } = useInView({
    threshold: 1,
  });
  const { ref: dateRef, inView: dateInView } = useInView({ threshold: 1 });
  const {
    billingInfo: {
      autoPay,
      billingAccount,
      invoices,
      summaryInvoiceStatus,
      totalBalance,
    },
  } = useSelector(selectBillingInfo);
  const {
    locale: { userLocale: locale },
  } = useSelector(selectLocale);
  const {
    user: { productInstanceStatus },
  } = useSelector(selectUser);

  const { issuer } = useSelector(selectConfig);
  const isSalesforce = issuer === Issuer.Salesforce;

  const { t } = useTranslation('Summary');

  React.useEffect(() => {
    if (balanceInView) {
      dispatch(
        trackBillingEvent({
          eventName: ANALYTICS_EVENT_NAME.billBalanceDisplayed,
          version: '1-0-0',
          data: {
            display_method: 'balance_card',
            display_zone: 'billing_page',
          },
        })
      );
    }
  }, [balanceInView, dispatch]);

  React.useEffect(() => {
    if (dateInView) {
      dispatch(
        trackBillingEvent({
          eventName: ANALYTICS_EVENT_NAME.billCycleDateDisplayed,
          version: '1-0-0',
          data: {
            care_category: 'Billing',
            care_subcategory: 'Bill',
            subject: 'Bill Cycle Date',
            display_method: 'balance_card',
            display_zone: 'billing_page',
          },
        })
      );
    }
  }, [dateInView, dispatch]);

  const invoice = getUnpaidInvoice(locale, invoices);

  const isPaymentButtonDisabled =
    (locale === Locale.PtBr && totalBalance.value === 0) || isSalesforce;

  return (
    <div className={styles['container']}>
      <div className={styles['headerContainer']} ref={dateRef} id="header">
        <BillingSummaryHeader
          productInstanceStatus={productInstanceStatus}
          billingAccount={billingAccount}
          locale={locale}
        />
      </div>

      {locale === Locale.PtBr && summaryInvoiceStatus && (
        <div className={styles['badgeContainer']}>
          <InvoiceStatusChip badgeStatus={summaryInvoiceStatus} />
        </div>
      )}

      {billingAccount?.billingStatus !== BillingStatus.New && (
        <div className={styles['balanceContainer']} ref={balanceRef}>
          <div className={styles['balance']}>
            <Txt variant="heading1" color="regular" mb="12px">
              {totalBalance.text}
            </Txt>
            <Txt variant="bodySmallRegular" color="regular">
              {t('amountDue')}
            </Txt>
          </div>
        </div>
      )}

      <div className={styles['autoPayContainer']}>
        <BillingAutoPayMethod autoPay={autoPay} />
      </div>

      <div className={styles['paymentButtonContainer']}>
        <PaymentButton invoice={invoice} disabled={isPaymentButtonDisabled} />
      </div>
    </div>
  );
};

type Props = {
  invoice?: Invoice | null;
  disabled?: boolean;
};

const PaymentButton = ({ invoice, disabled = false }: Props): JSX.Element => {
  const { t } = useTranslation('Summary');
  const dispatch = useDispatch();

  return (
    <Button
      data-cy="make-payment-button"
      variant="primary"
      fluid
      disabled={disabled}
      onClick={() => goToPayment(dispatch, 'billing_page', invoice)}
    >
      <Txt variant="labelLarge" theme="dark" color="regular">
        {t('makeOneTimePayment')}
      </Txt>
    </Button>
  );
};

export default Summary;
