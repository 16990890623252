import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { InlineLink, Txt } from '@vst/beam';

import { useScreenResolution } from '@mfe/shared/util';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';

import { Furnitures } from './Furnitures';

import styles from './index.module.scss';

export const Description = () => {
  const { t } = useTranslation(['Global', 'Home']);

  const dispatch = useDispatch();

  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isSmall || isExtraSmall;

  const VIASAT_HELP_PAGE_LINK = t('Home:errorContent.help_URL');

  const openLinkInNewTab = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(openInNewTab(VIASAT_HELP_PAGE_LINK));
  };

  return (
    <div className={styles['description-container']}>
      <Furnitures />
      <Txt variant="heading4" color="regular" mt="48px">
        {t('Home:errorContent.title')}
      </Txt>
      <Txt variant="bodyLargeRegular" color="subtle" mt="12px">
        {t('Home:errorContent.description')}
      </Txt>
      <Txt variant="bodyLargeRegular" color="subtle" mt="12px">
        {t('Home:errorContent.helpDescription')}
        <InlineLink href="#" onClick={openLinkInNewTab}>
          {t('Home:errorContent.link')}
        </InlineLink>{' '}
        {t('Home:errorContent.phoneDescription')}
        {isMobile ? (
          <CustomerSupportTelLink />
        ) : (
          <Txt variant="bodyLargeRegular" color="subtle" component="span">
            {t('Global:callCustomerSupportPhoneNumberDisplay')}
          </Txt>
        )}
      </Txt>
    </div>
  );
};

const CustomerSupportTelLink = () => {
  const { t } = useTranslation(['Global']);

  return (
    <InlineLink
      variant="primary"
      className={styles['customer-support-tel-link']}
      href={`tel:${t('Global:callCustomerSupportPhoneNumber')}`}
    >
      {t('Global:callCustomerSupportPhoneNumberDisplay')}
    </InlineLink>
  );
};
