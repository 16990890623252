import React from 'react';
import { Color } from '@mfe/legacy/andromeda';
import Svg, {
  SvgProps,
  G,
  Polygon,
  Circle,
  LinearGradient,
  Stop,
  Defs,
  Rect,
} from 'react-native-svg';
import { successColor, ColorType } from '../../components/Status';

interface StatusProps extends SvgProps {
  color?: ColorType;
  errorColor?: string;
  backgroundColor?: string;
  noEdge?: boolean;
  noIcon?: boolean;
}

export const StatusBG = ({
  width = 414,
  height = 254,
  color = ColorType.red,
  ...otherProps
}: StatusProps): JSX.Element => {
  let fill = '#FFECE7';
  if (color === ColorType.green) fill = 'url(#StatusBG-1)';
  if (color === ColorType.blue) fill = 'url(#StatusBG-2)';
  return (
    <Svg width={width} height={height} viewBox="0 0 414 254" {...otherProps}>
      <Defs>
        <LinearGradient
          x1="123.086976%"
          y1="36.059976%"
          x2="9.5710651%"
          y2="67.8586621%"
          id="StatusBG-1"
        >
          <Stop stopColor="#8DF2ED" offset="0%" />
          <Stop stopColor="#DDFA96" offset="100%" />
        </LinearGradient>
        <LinearGradient
          x1="123.086976%"
          y1="36.059976%"
          x2="9.5710651%"
          y2="67.8586621%"
          id="StatusBG-2"
        >
          <Stop stopColor="#8DF2ED" offset="0%" />
          <Stop stopColor="#006EAD" offset="100%" />
        </LinearGradient>
      </Defs>
      <Polygon
        transform="translate(207.000000, 130.500000) scale(-1, -1) translate(-207.000000, -130.500000) "
        points="-78 54.9798823 492 0 491.186393 261 -78 261"
        fill={fill}
      />
    </Svg>
  );
};

export const StatusFG = ({
  width = 128,
  height = 128,
  color = ColorType.red,
  errorColor = Color.red300,
  backgroundColor = Color.white,
  noEdge = false,
  noIcon = false,
  ...otherProps
}: StatusProps): JSX.Element => {
  let iconColor: string = successColor;
  if (color === ColorType.blue) iconColor = Color.blue600;
  if (color === ColorType.red) iconColor = errorColor;
  return (
    <Svg width={width} height={height} viewBox="0 0 128 128" {...otherProps}>
      <Circle fill={backgroundColor} cx="64" cy="64" r="64" />
      {!noIcon && (
        <Circle
          fill="none"
          cx="64"
          cy="64"
          r={noEdge ? '59' : '46.5'}
          stroke={iconColor}
          strokeWidth="4"
        />
      )}
      {!noIcon && color !== ColorType.red && (
        <G transform="translate(20.0, 20.0)">
          <Polygon
            fill={iconColor}
            stroke={iconColor}
            fillRule="nonzero"
            points="61 35.6286353 39.7428571 58 30 47.7464579 32.4977143 45.1178225 39.7428571 52.7240865 58.5022857 33"
          />
        </G>
      )}
      {!noIcon && color === ColorType.red && (
        <>
          <Rect
            x="60.46"
            y="42.25"
            width="8.09"
            height="26.31"
            fill={iconColor}
          />
          <Rect
            x="60.46"
            y="76.65"
            width="8.09"
            height="8.09"
            fill={iconColor}
          />
        </>
      )}
    </Svg>
  );
};
