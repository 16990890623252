import React, { ReactNode } from 'react';
import { View, StyleSheet, SafeAreaView, ViewStyle } from 'react-native';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Color,
  Text,
  Button,
  useDimensionContext,
  Loading,
} from '@mfe/legacy/andromeda';
import Modal from 'react-native-modal';

import { StatusBG, StatusFG } from '../img/svgs/Status';
import { formatString, isDefined } from '../utils';

export const successColor = Color.teal500;

export const styles = StyleSheet.create({
  wrapper: { flexGrow: 1 },
  imageBG: { position: 'absolute', top: 0, left: 0, right: 0, zIndex: -2 },
  imageFG: { alignSelf: 'center', alignItems: 'center' },
  paddingCard: { paddingTop: 42 },
  paddingFull: { paddingTop: 24 },
  container: {
    paddingHorizontal: 28,
    paddingBottom: 29,
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  title: { color: successColor, textAlign: 'center', minHeight: 54 },
  body: {
    color: successColor,
    marginTop: 13,
    textAlign: 'center',
    minHeight: 54,
  },
  textContainerFull: { marginTop: 0 },
  textContainerCard: { marginTop: 12 },
  topContainer: { marginBottom: 56 },
  iconContainer: {
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: -20,
  },
  buttonContainer: { minWidth: 184, alignSelf: 'center' },
});

export enum StatusType {
  fullPage = 'fullPage',
  card = 'card',
  modal = 'modal',
}

export enum ColorType {
  red = 'red',
  green = 'green',
  blue = 'blue',
}

interface StatusProps {
  children?: ReactNode;
  bodyData?: string;
  customBody?: string;
  title?: string;
  type?: StatusType;
  noTitle?: boolean;
  noCircle?: boolean;
  color?: ColorType;
  style?: ViewStyle;
  retry?: () => void;
  isVisible?: boolean;
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  removeMargin?: boolean;
  testID?: string;
  loading?: boolean;
  noText?: boolean;
  customElement?: JSX.Element | null;
  customIcon?: JSX.Element | null;
  iconColor?: ColorType | null;
}

const Status = ({
  children,
  bodyData,
  customBody,
  title,
  type = StatusType.fullPage,
  noTitle,
  noCircle = false,
  color = ColorType.red,
  style = {},
  retry,
  isVisible = false,
  setIsVisible,
  removeMargin = false,
  testID = 'default',
  loading = false,
  noText = false,
  customElement,
  customIcon,
  iconColor,
}: StatusProps): JSX.Element => {
  const { t } = useTranslation('Status');
  const { isMobile } = useDimensionContext();
  const titleText = color !== ColorType.red ? t('success') : t('oops');
  const gradientSize = type === StatusType.fullPage ? 224 : 100;
  const circleSize = type === StatusType.fullPage ? 128 : 107;
  const textStyle = color === ColorType.red && { color: Color.red300 };
  let buttonExtraMargin;
  if (type === StatusType.fullPage)
    buttonExtraMargin = { marginHorizontal: 14 };
  if (type === StatusType.card && !isMobile)
    buttonExtraMargin = { marginHorizontal: 60 };
  const heightWithCircle = gradientSize + (circleSize - 20) / 2;
  const myBody = (
    <>
      <View
        style={{
          width: '100%',
          height: noCircle ? gradientSize : heightWithCircle,
        }}
        testID={testID}
      >
        <StatusBG
          testID={`${testID}StatusBG`}
          preserveAspectRatio="none"
          style={styles.imageBG}
          width="100%"
          height={gradientSize}
          color={color}
        />
        {!noCircle && (
          <View style={[styles.iconContainer, { height: heightWithCircle }]}>
            <View style={{ position: 'absolute' }}>
              {isDefined(customIcon) ? (
                customIcon
              ) : (
                <StatusFG
                  color={iconColor ? iconColor : color}
                  testID={`${testID}StatusFG`}
                  style={styles.imageFG}
                  width={circleSize}
                  height={circleSize}
                  noIcon={loading}
                />
              )}
            </View>
            {loading && (
              <Loading
                size={circleSize}
                strokeWidth={1.25}
                color={color === ColorType.blue ? Color.blue600 : Color.teal500}
              />
            )}
          </View>
        )}
      </View>
      {!noText && (
        <View style={styles.wrapper}>
          <View
            style={[
              styles.container,
              type === StatusType.fullPage
                ? styles.paddingFull
                : styles.paddingCard,
            ]}
          >
            <View
              style={[
                !removeMargin && styles.topContainer,
                { paddingHorizontal: isMobile ? undefined : 30 },
              ]}
            >
              <View
                style={
                  type === StatusType.fullPage
                    ? styles.textContainerFull
                    : styles.textContainerCard
                }
              >
                {!noTitle && (
                  <Text.H1
                    heavy
                    testID={`${testID}StatusTitle`}
                    style={[styles.title, textStyle]}
                  >
                    {title ? title : titleText}
                  </Text.H1>
                )}
                {bodyData && (
                  <Text style={[styles.body, textStyle]}>
                    {formatString(t('fetchError'), { data: bodyData })}
                  </Text>
                )}
                <Text
                  testID={`${testID}StatusBody`}
                  style={[styles.body, textStyle]}
                >
                  {customBody}
                </Text>
                {customElement && customElement}
              </View>
            </View>
            <View style={[styles.buttonContainer, buttonExtraMargin]}>
              {retry && (
                <Button testID="change-plan-retry-button" fill onPress={retry}>
                  {t('retry')}
                </Button>
              )}
              {children}
              {setIsVisible && (
                <Button
                  testID="change-plan-exit-error"
                  fill
                  onPress={(): void => setIsVisible(false)}
                  tertiary
                >
                  {t('exit')}
                </Button>
              )}
            </View>
          </View>
        </View>
      )}
    </>
  );
  if (type === StatusType.card)
    return (
      <Card
        removeContentStyle
        marginStyle={style}
        contentStyle={{ paddingHorizontal: !isMobile ? 30 : undefined }}
      >
        {myBody}
      </Card>
    );
  if (type === StatusType.modal)
    return (
      <Modal isVisible={isVisible} backdropOpacity={0.5}>
        <View style={{ justifyContent: 'center', flexDirection: 'row' }}>
          <Card removeMaxWidth removeContentStyle>
            {myBody}
          </Card>
        </View>
      </Modal>
    );
  return <SafeAreaView style={[style, { flex: 1 }]}>{myBody}</SafeAreaView>;
};

export default Status;
