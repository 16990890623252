import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionGroup, Button, Surface, Txt } from '@vst/beam';

import {
  Storage,
  VOICE_ONLY_CONNECTION_PRODUCT_TYPE_ID,
  useScreenResolution,
} from '@mfe/shared/util';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { AccountType, Locale } from '@mfe/shared/schema-types';
import {
  getPendingInstallation,
  selectingPendingInstallation,
} from '@mfe/to-be-migrated/redux/pendingInstallation';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectConfig } from '@mfe/shared/redux/config';
import {
  AEMContentProvider,
  AemFeature,
  PlanChangePendingBadge,
  SKIP_PARAMETER_CONSTANT,
  createAemUrl,
  useCanChangePlan,
  useHasPlanChangePending,
  useShowAddonsShop,
} from '@mfe/shared/components';
import { selectChangePlan } from '@mfe/to-be-migrated/redux/changePlan';
import {
  getBroadbandLabelAttributes,
  selectBroadbandLabelAttributes,
} from '@mfe/to-be-migrated/redux/broadbandLabel/broadbandLabelSlice';
import {
  getPortfolio,
  selectPortfolio,
} from '@mfe/to-be-migrated/redux/portfolio';
import {
  getCharacteristics,
  getDownloadSpeeds,
  getPrice,
  selectCurrentPlanPrice,
  selectDownloadSpeeds,
  selectPlanCharacteristics,
} from '@mfe/to-be-migrated/redux/plan';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { ProductInstanceTypes } from '@mfe/shared/graphql/PSM/types';

import { ScheduledAlert } from './ScheduledAlert';
import { UnscheduledAlert } from './UnscheduledAlert';
import { PlanName } from './PlanName';
import { Price } from './Price';
import { DownloadSpeed } from './DownloadSpeed';
import { Characteristics } from './Characteristics';
import { EquipmentShippingAlert } from './EquipmentShippingAlert';
import styles from '../BodyContent.module.scss';
import loadingStyles from './Loading.module.scss';
import { ProfileActiveAddons } from '../AddOns/profile-active-addons';
import {
  refetchCurrentAddOns,
  selectAddOns,
} from '@mfe/to-be-migrated/redux/addOns';
import { useShouldRefetchCurrentAddons } from '@mfe/features/add-ons';

interface Props {
  navigateToChangePlan: () => void;
  navigateToScheduleInstallation: () => void;
}

export const InternetPlan = ({
  navigateToChangePlan,
  navigateToScheduleInstallation,
}: Props) => {
  const { t } = useTranslation(['NewProfile', 'Global']);
  const { isSmall, isExtraSmall } = useScreenResolution();
  const dispatch = useDispatch();

  const { showChangePlanSMB, aemBaseUrl, showBroadbandLabels } =
    useSelector(selectConfig);
  const showAddonsShop = useShowAddonsShop();

  const { hasActivationError } = useSelector(selectChangePlan);

  const { addOnOrderId } = useSelector(selectAddOns);

  const shouldRefetchCurrentAddOns = useShouldRefetchCurrentAddons();

  const {
    data: { upi, serviceAreaId, serviceId, satelliteName, iduDeviceModel },
    loading: broadbandLabelAttributesLoading,
  } = useSelector(selectBroadbandLabelAttributes);

  const { portfolio } = useSelector(selectPortfolio);

  const {
    characteristics: {
      id: productTypeId,
      name: planName,
      uploadSpeed,
      isGoodFor,
      bestFor,
      titleAddOns,
      dataCap,
    },
    loading: characteristicsLoading,
  } = useSelector(selectPlanCharacteristics);

  const {
    price,
    errors: priceError,
    loading: priceLoading,
  } = useSelector(selectCurrentPlanPrice);

  const {
    data: { downloadSpeed, downloadUnits },
    loading: downloadSpeedLoading,
  } = useSelector(selectDownloadSpeeds);

  React.useEffect(() => {
    if (shouldRefetchCurrentAddOns) {
      dispatch(refetchCurrentAddOns(addOnOrderId as string));
    }
  }, []);

  React.useEffect(() => {
    dispatch(getPendingInstallation());
    dispatch(getBroadbandLabelAttributes());
  }, []);

  React.useEffect(() => {
    // Fetch portfolio in advance, to later pass to configureCart
    if (!portfolio) dispatch(getPortfolio());

    if (!productTypeId) dispatch(getCharacteristics());
    if (!price) dispatch(getPrice());
    if (!downloadSpeed) dispatch(getDownloadSpeeds());
  }, [dispatch, portfolio, productTypeId, price, downloadSpeed]);

  const {
    loading: alertLoading,
    hasPendingInstallation,
    schedule,
    isEquipmentShipped,
  } = useSelector(selectingPendingInstallation);

  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const {
    user: { productKind },
  } = useSelector(selectUser);

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const canFlowBeAccessed = userLocale === Locale.EnUs;
  const canChangePlan = useCanChangePlan();
  const showChangePlanButton =
    canFlowBeAccessed ||
    accountType === AccountType.Residential ||
    showChangePlanSMB;

  const showBroadbandLabel =
    showBroadbandLabels && !broadbandLabelAttributesLoading;

  const hasVoicePlan = productTypeId === VOICE_ONLY_CONNECTION_PRODUCT_TYPE_ID;

  const showMissingInfoNotice = hasVoicePlan
    ? Boolean(priceError)
    : [planName, price, downloadSpeed, uploadSpeed].some((attr) => !attr);

  const hasDataLoaded =
    !characteristicsLoading && !priceLoading && !downloadSpeedLoading;

  const handleNavScheduleInstall = () => {
    Storage.setItem('showInternetPlan', true);
    navigateToScheduleInstallation();
  };

  const hasPlanChangePending = useHasPlanChangePending();

  const isChangePlanDisabled =
    productKind === ProductInstanceTypes.WirelessInternet ||
    !canChangePlan ||
    (hasPendingInstallation && alertLoading) ||
    hasActivationError;

  return (
    <>
      <Surface
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
        data-cy="internet-plan-card-surface"
        variant="primary"
        radius={isSmall || isExtraSmall ? '0px' : '16px'}
      >
        <div className={styles['cardTitle']}>
          <Txt variant="heading5" component="span" color="regular">
            {t('NewProfile:myPlanCardTitle')}
          </Txt>

          {hasPlanChangePending ? (
            <PlanChangePendingBadge />
          ) : (
            showChangePlanButton && (
              <Button
                data-cy="change-plan-button"
                buttonSize="small"
                variant="secondary"
                onClick={navigateToChangePlan}
                disabled={isChangePlanDisabled}
              >
                <Txt variant="labelSmall" style={{ whiteSpace: 'nowrap' }}>
                  {t('Usage:buttons.changePlan')}
                </Txt>
              </Button>
            )
          )}
        </div>

        <Surface
          pt="24px"
          px={{ xs: '16px', sm: '24px' }}
          className={styles['planInfoContainer']}
        >
          {alertLoading ? (
            <div className={loadingStyles['alertLoading']} />
          ) : (
            hasPendingInstallation &&
            (schedule ? (
              <ScheduledAlert handleRedirect={handleNavScheduleInstall} />
            ) : (
              <UnscheduledAlert handleRedirect={handleNavScheduleInstall} />
            )) &&
            isEquipmentShipped && <EquipmentShippingAlert />
          )}

          {hasDataLoaded && showMissingInfoNotice && (
            <Txt variant="bodySmallItalic" color="subtle">
              {t('InternetPlan.missingPlanInfo')}
            </Txt>
          )}
          <div className={styles['planHeader']} data-cy="planDetails">
            <PlanName name={planName} loading={characteristicsLoading} />
            <Price price={price} loading={priceLoading} />
          </div>
          <div className={styles['attributesContainer']}>
            <DownloadSpeed
              downloadSpeed={downloadSpeed}
              downloadUnits={downloadUnits}
              loading={downloadSpeedLoading}
            />
            <Characteristics
              isGoodFor={isGoodFor}
              bestFor={bestFor}
              titleAddOns={titleAddOns}
              dataCap={dataCap}
              uploadSpeed={uploadSpeed}
              loading={characteristicsLoading}
            />
          </div>
          <AccordionGroup>
            <Accordion
              className={styles['broadbandFactsAccordion']}
              title={
                <Txt variant="labelMedium">
                  {t('InternetPlan.broadbandFacts')}
                </Txt>
              }
            >
              {showBroadbandLabel && (
                <AEMContentProvider
                  src={createAemUrl({
                    baseUrl: aemBaseUrl,
                    locale: userLocale,
                    feature: AemFeature.PLAN_LABEL,
                    accountType,
                    additionalSelectors: ['pidi'],
                    suffixes: {
                      upi,
                      serviceAreaId,
                      offerId: SKIP_PARAMETER_CONSTANT,
                      satelliteName,
                      serviceId,
                      iduDeviceModel,
                    },
                  })}
                  id={AemFeature.PLAN_LABEL}
                  style={{ textAlign: 'left' }}
                />
              )}
            </Accordion>
          </AccordionGroup>
        </Surface>
      </Surface>
      {showAddonsShop && (
        <div className={styles['addOnsContainer']}>
          <ProfileActiveAddons />
        </div>
      )}
    </>
  );
};
