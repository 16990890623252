import React from 'react';
import { useHistory } from 'react-router';
import { Platform } from '@mfe/shared/schema-types';
import { MobileStackNavBar } from '@mfe/features/mobile-app-nav';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectConfig } from '@mfe/shared/redux/config';

const WrapWithMobileStackNav = ({
  navTranslationKey,
  children,
  backgroundColor = '#FFF',
}: {
  navTranslationKey: string;
  children: React.ReactNode;
  backgroundColor?: string;
}) => {
  const { t } = useTranslation('Nav');
  const history = useHistory();
  const { platform } = useSelector(selectConfig);
  const isWeb = platform === Platform.Web;

  const onBack = () => {
    history.goBack();
  };

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (isWeb) return <>{children}</>;

  return (
    <div
      style={{
        position: 'absolute',
        width: '100vw',
        inset: 0,
        zIndex: 12347,
        backgroundColor,
      }}
    >
      <MobileStackNavBar title={t(navTranslationKey)} onBack={onBack} />
      {children}
    </div>
  );
};

export default WrapWithMobileStackNav;
