import { TextField, validators } from '@vst/beam';
import { ComponentProps, FC, useMemo } from 'react';

interface AddressInputProps extends ComponentProps<typeof TextField> {
  validation?: {
    required: string;
  };
}

export const AddressInput: FC<AddressInputProps> = (
  props: AddressInputProps
) => {
  const { validation, ...textFieldProps } = props;

  const validationRules = useMemo(() => {
    const validationRules = [];
    if (validation?.required) {
      validationRules.push(
        validators.required({ message: validation.required })
      );
    }
    return validationRules;
  }, [validation]);

  return (
    <TextField
      data-cy={textFieldProps.name}
      labelProps={{ showStar: false }}
      validationRules={validationRules}
      required={!!validation?.required}
      {...textFieldProps}
    />
  );
};
