import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getPlans,
  selectChangePlan,
  setError,
  refetchPlans,
  setChangePlanStep,
  ChangePlanSteps,
} from '@mfe/to-be-migrated/redux/changePlan';
import {
  getCharacteristics,
  getDownloadSpeeds,
  getPrice,
  selectCurrentPlanPrice,
  selectDownloadSpeeds,
  selectPlanCharacteristics,
} from '@mfe/to-be-migrated/redux/plan';
import { getBroadbandLabelAttributes } from '@mfe/to-be-migrated/redux/broadbandLabel/broadbandLabelSlice';

import { PlanSelection } from './lib/components/PlanSelection/PlanSelection';
import { OrderReview } from './lib/components/OrderReview/OrderReview';
import { DroppedAddOns } from './lib/components/DroppedAddons';
import { ScheduleInstallation } from './lib/components/ScheduleInstallation';
import { OrderOutcome } from './lib/components/OrderOutcome/OrderOutcome';
import { NavigationHandlers } from './lib/types';
import {
  getPortfolio,
  selectPortfolio,
} from '@mfe/to-be-migrated/redux/portfolio/portfolioSlice';

export const ChangePlan = ({
  navigateToPreviousPage,
  navigateToOverview,
}: NavigationHandlers) => {
  const dispatch = useDispatch();

  const {
    characteristics: { id: productTypeId },
  } = useSelector(selectPlanCharacteristics);

  const {
    data: { id: subscriptionId },
  } = useSelector(selectDownloadSpeeds);

  const { portfolio } = useSelector(selectPortfolio);
  const { price } = useSelector(selectCurrentPlanPrice);
  const { error, step, planOffersData, orderSuccess, hasPendingTransition } =
    useSelector(selectChangePlan);

  useEffect(() => {
    if (error || hasPendingTransition || planOffersData?.length === 0) {
      dispatch(refetchPlans());
    } else {
      dispatch(getPlans());
    }

    dispatch(getBroadbandLabelAttributes());

    // Fetch portfolio in advance, to later pass to configureCart
    if (!portfolio) dispatch(getPortfolio());
    if (!productTypeId) dispatch(getCharacteristics());
    if (!subscriptionId) dispatch(getDownloadSpeeds());
    if (!price) dispatch(getPrice());

    return () => {
      dispatch(setChangePlanStep(ChangePlanSteps.PLAN_SELECTION));
    };
  }, []);

  useEffect(() => {
    if (orderSuccess && !planOffersData) {
      dispatch(refetchPlans());
    }
  }, [orderSuccess, planOffersData, dispatch]);

  const handleBackNavigation = () => {
    dispatch(setChangePlanStep(ChangePlanSteps.PLAN_SELECTION));
    dispatch(setError(null));
  };

  switch (step) {
    case ChangePlanSteps.ORDER_REVIEW:
      return <OrderReview handleBackNavigation={handleBackNavigation} />;
    case ChangePlanSteps.DROPPED_ADDONS:
      return <DroppedAddOns handleBackNavigation={handleBackNavigation} />;
    case ChangePlanSteps.ORDER_OUTCOME:
      return <OrderOutcome navigateToOverview={navigateToOverview} />;
    case ChangePlanSteps.SELECT_SCHEDULE:
      return (
        <ScheduleInstallation navigateToPreviousPage={navigateToPreviousPage} />
      );
    case ChangePlanSteps.PLAN_SELECTION:
    default:
      return <PlanSelection navigateToPreviousPage={navigateToPreviousPage} />;
  }
};
