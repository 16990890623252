import { Txt, RadioButton, HelperText } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import { FormFieldEnum, FormValues, InputEvent } from '../../utils';

import styles from './Form.module.scss';

export const TRANSFER_RADIO_IDS = {
  yes: 'transferYes',
  no: 'transferNo',
};

interface TransferFieldProps {
  value: FormValues['isTransferring'];
  onChange: (e: InputEvent) => void;
  error: string;
  isValid: boolean;
}

export const TransferField = (props: TransferFieldProps) => {
  const { onChange, value, error, isValid } = props;
  const hasError = error !== '' && !isValid;

  const { t } = useTranslation('ACP', { keyPrefix: 'Form' });

  return (
    <div
      className={styles['radio-field-wrapper']}
      data-cy="acp-transferring-question"
    >
      <Txt variant="labelSmall" component="label" color="subtle">
        {t('fields.radio.transfer.label')}
      </Txt>
      {/*
       * `nonce` isn't supposed to be a required
       * prop. BEAM team will address this in a future
       * release
       *
       * https://viasat.slack.com/archives/C02BEV69HAQ/p1676986409336519?thread_ts=1676931679.737829&cid=C02BEV69HAQ
       */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <RadioButton
        data-cy="acp-is-transferring"
        className={styles['radio-field']}
        onChange={onChange}
        name={FormFieldEnum.TRANSFER}
        id={TRANSFER_RADIO_IDS.yes}
        checked={value === null ? false : value}
        label={t('fields.radio.transfer.yes')}
      />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <RadioButton
        data-cy="acp-is-not-tranferring"
        className={styles['radio-field']}
        onChange={onChange}
        name={FormFieldEnum.TRANSFER}
        id={TRANSFER_RADIO_IDS.no}
        checked={value === null ? false : !value}
        label={t('fields.radio.transfer.no')}
      />
      {hasError && (
        <HelperText
          helpMessage={error}
          state="error"
          className={styles['helper-text']}
        />
      )}
    </div>
  );
};
