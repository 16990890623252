import React from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { possibleTypes } from '@viasat/res-apps-lib/build/types/mv';

import useLink from './links';
import { useToken } from '../../views/Auth';
import { BillingTypePolicies } from './typePolicies/billing';
import { memoryStorage } from '@mfe/shared/util';
import { Locale, TokenType } from '@mfe/shared/schema-types';

export const cacheFactory = (): InMemoryCache =>
  new InMemoryCache({
    possibleTypes,
    typePolicies: {
      ...BillingTypePolicies,
      GetAccountInfoPayload: {
        keyFields: [],
      },
      GetInitialAccountInfoPayload: {
        keyFields: [],
      },
      GetConfigurations: {
        keyFields: [],
      },
      GetTransactionIdPayload: {},
      Plan: {
        fields: {
          characteristics: {
            merge: true,
          },
        },
      },
    },
  });

export const ApolloProviders = ({
  children,
  URI,
}: {
  children: React.ReactNode;
  URI: string;
}): JSX.Element => {
  const { token: tokenInfo } = useToken();

  const isRegistrationToken = tokenInfo?.type === TokenType.Registration;
  const token = isRegistrationToken
    ? {
        accessToken: '',
        accessTokenExpirationTime: '',
        locale: Locale.EnUs,
      }
    : tokenInfo;

  const link = useLink(URI, token?.accessToken, token?.type);
  const cache = cacheFactory();

  const KEY = URI;
  let client = memoryStorage.get(KEY);
  if (!client) {
    client = new ApolloClient({
      link,
      cache,
    });
    if (token?.accessToken) {
      memoryStorage.set(KEY, client);
    }
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
