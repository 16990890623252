import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { InlineLink, Txt } from '@vst/beam';

import { selectChosenPlan } from '@mfe/to-be-migrated/redux/changePlan';

import { ModalTypes } from './OrderReview';
import { TermsAndConditionsModal } from './TermsAndConditionsModal';

import styles from './TermsAndConditions.module.scss';

interface TermsAndConditionsProps {
  openModal: (e: React.MouseEvent<HTMLElement>, modalType: ModalTypes) => void;
  closeModal: () => void;
  isModalOpen: boolean;
}
export const TermsAndConditions = ({
  openModal,
  closeModal,
  isModalOpen,
}: TermsAndConditionsProps) => {
  const { t } = useTranslation('NewChangePlan', {
    keyPrefix: 'orderReview.details.terms',
  });

  const { isEquipmentChangeNeeded } = useSelector(selectChosenPlan);

  const noticeText = isEquipmentChangeNeeded ? t('phase2Notice') : t('notice');

  return (
    <div data-cy="terms-notice" className={styles['terms-notice']}>
      <Txt variant="smallRegular" component="span">
        {noticeText}
      </Txt>
      <InlineLink
        href="#"
        onClick={(e) => openModal && openModal(e, 'terms&conditions')}
      >
        <Txt variant="smallRegular" component="span">
          {t('buttonLink')}
        </Txt>
      </InlineLink>
      <TermsAndConditionsModal isOpen={isModalOpen} handleClose={closeModal} />
    </div>
  );
};
