import { call, put, takeLatest, spawn, select, all } from 'redux-saga/effects';
import { ApolloQueryResult, gql } from '@apollo/client';
import {
  AccountType,
  Address,
  ContactMethodLabels,
  Locale,
  Query,
  Role,
} from '@mfe/shared/schema-types';

import { graphqlQuery } from '@mfe/shared/redux/graphql';

import {
  ScrubAddressProcessStatus,
  ScrubAddressRecommendation,
} from '@mfe/shared/graphql/PAL/types';

import {
  ScrubbedAddressState,
  setScrubbedAddress,
  fetchScrubbedAddress as fetchScrubbedAddressAction,
  setShowModal,
  selectScrubbedAddress,
  setEditingCard,
  setCloseAfterReload,
} from './scrubbedAddressSlice';

import { resetScrubbedAddress } from '.';
import { clearError, setError } from '../error';
import { selectLocale } from '../locale';

import {
  selectUserInfo,
  updateContactInfo,
  updateContactInfoSMB,
} from '../userInfo/userInfoSlice';
import { ModalTypeEnum } from '../../libUtils';

export enum EditableCardType {
  Billing = 'Billing',
  ContactInformation = 'ContactInformation',
  Shipping = 'Shipping',
  None = 'None',
}

export const GET_SCRUBBED_ADDRESS = gql`
  query getScrubbedAddress($address: GetScrubbedAddressInput!) {
    getScrubbedAddress(address: $address) {
      addressLines
      municipality
      region
      country
      postalCode
      countryCode
      processStatus
      recommendation
    }
  }
`;

export const parseScrubbedAddress = (
  data: Partial<ApolloQueryResult<Query>['data']>,
  secondAddressLine: string,
  locale = Locale.EnUs
): ScrubbedAddressState['scrubbedAddress'] | null => {
  const rawAddress = data.getScrubbedAddress;
  const firstAddressLine = rawAddress?.addressLines[0] ?? '';
  const municipality = rawAddress?.municipality ?? '';
  const region = rawAddress?.region ?? '';
  const postalCode = rawAddress?.postalCode ?? '';
  const countryName = rawAddress?.country ?? '';

  let formattedAddress = '';

  if (rawAddress?.processStatus !== ScrubAddressProcessStatus.Incorrect) {
    switch (locale) {
      case Locale.ItIt:
        formattedAddress = `${firstAddressLine} ${secondAddressLine} ${postalCode} ${municipality} ${region} ${countryName}`;
        break;
      default:
        formattedAddress = `${firstAddressLine} ${secondAddressLine} ${municipality}, ${region} ${postalCode} ${countryName}`;
        break;
    }
  }

  return {
    rawAddress: rawAddress as any,
    formattedAddress,
    processStatus: rawAddress?.processStatus as ScrubAddressProcessStatus,
    recommendation: rawAddress?.recommendation as ScrubAddressRecommendation,
  };
};

export function* fetchScrubbedAddress({
  payload,
}: {
  type: string;
  payload: Address;
}) {
  const address = payload;
  yield put(clearError({}));
  const {
    locale: { userLocale },
  }: ReturnType<typeof selectLocale> = yield select(selectLocale);
  try {
    const data: Query = yield call(graphqlQuery, {
      query: GET_SCRUBBED_ADDRESS,
      variables: { address },
    });
    yield put(resetScrubbedAddress());

    const secondAddressLine = payload.addressLines[1];
    const scrubbedAddress = parseScrubbedAddress(
      data,
      secondAddressLine,
      userLocale
    );
    if (scrubbedAddress) {
      yield put(setScrubbedAddress({ rawInput: payload, scrubbedAddress }));
    } else
      yield put(
        setError({
          operation: 'getScrubbedAddress',
          message: ['getScrubbedAddress attempt failed.'],
        })
      );
  } catch (error) {
    yield put(
      setError({
        operation: 'getScrubbedAddress',
        message: ['getScrubbedAddress attempt failed.'],
      })
    );
    yield put(resetScrubbedAddress());
  }
}

type PayloadType = {
  rawInput: Address;
  scrubbedAddress: ScrubbedAddressState['scrubbedAddress'];
};

function* maybeSaveScrubbedAddress({
  payload,
}: {
  type: string;
  payload: PayloadType;
}) {
  const { processStatus, recommendation } = payload.scrubbedAddress;
  const { editingCard, scrubbedAddress } = yield select(selectScrubbedAddress);
  const {
    userInfo: { accountType },
  } = yield select(selectUserInfo);

  if (
    processStatus === ScrubAddressProcessStatus.Verified ||
    (processStatus === ScrubAddressProcessStatus.Corrected &&
      recommendation === ScrubAddressRecommendation.RECOMMEND)
  ) {
    yield put(setShowModal(null));

    const labels = [];

    if (editingCard === EditableCardType.Billing) {
      labels.push(ContactMethodLabels.Invoice);
    }

    if (editingCard === EditableCardType.Shipping) {
      labels.push(ContactMethodLabels.Shipping);
    }

    const addresses = [
      {
        addressLines: scrubbedAddress.rawAddress.addressLines,
        countryCode: scrubbedAddress.rawAddress.countryCode,
        municipality: scrubbedAddress.rawAddress.municipality,
        postalCode: scrubbedAddress.rawAddress.postalCode,
        region: scrubbedAddress.rawAddress.region,
        labels,
      },
    ];

    if (accountType === AccountType.Residential) {
      yield put(setEditingCard(EditableCardType.None));
      yield put(setCloseAfterReload(true));
      yield put(updateContactInfo({ addresses }));
    } else {
      yield put(
        updateContactInfoSMB({
          role: Role.Customer,
          addresses,
        })
      );
    }

    return;
  }

  if (processStatus === ScrubAddressProcessStatus.Corrected) {
    return;
  }

  yield put(setShowModal(ModalTypeEnum.AddressError));
}

export function* watchScrubbedAddress() {
  yield spawn(function* () {
    yield takeLatest(fetchScrubbedAddressAction.type, fetchScrubbedAddress);
  });
  yield all([takeLatest(setScrubbedAddress.type, maybeSaveScrubbedAddress)]);
}
