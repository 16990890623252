import React, { useRef, useEffect } from 'react';
import { View, Platform, LayoutChangeEvent } from 'react-native';
import { Text, useDimensionContext, ScreenSize } from '@mfe/legacy/andromeda';
import { FaqSection } from '@viasat/res-apps-lib/build/types/mv';
import { FaqCard } from './FaqCard';
import {
  updateLayoutMap,
  filterSections,
  autoScroll,
  webSmallAccordion,
  webDefaultAccordion,
} from '../utils';
import {
  LayoutMap,
  SectionTitleProps,
  AccordionProps,
  Accumulator,
  SectionValue,
  FaqSelection,
} from '../types';
import styles from './styles';

const SectionTitle = ({ title }: SectionTitleProps): JSX.Element => (
  <View style={styles.sectionTitle} testID={`${title}-SectionTitle`}>
    <Text>{title}</Text>
  </View>
);

export const Accordion = ({
  faqSelection,
  handleSelect,
  setLayoutMap,
  faqs,
}: AccordionProps): JSX.Element => {
  const { screenSize } = useDimensionContext();
  const isSmallScreen = screenSize <= ScreenSize.MD;
  const {
    section: selectedSection,
    previousSection,
    question: selectedQuestion,
    previousQuestion,
  }: FaqSelection = faqSelection;
  const { sections } = faqs ?? { sections: [] };

  const sectionRefs = sections.reduce(
    (accumulator: any, section: FaqSection): Accumulator => {
      //todo for future analysis - using useRef outside of the callback is the RCA for this bug hence suppressed this rule
      // eslint-disable-next-line react-hooks/rules-of-hooks
      accumulator[section.title as NonNullable<SectionValue>] = useRef(null);
      return accumulator;
    },
    {}
  );

  const filteredSection =
    isSmallScreen || selectedSection === SectionValue.All
      ? sections
      : filterSections(sections, selectedSection);

  const handleScroll = () => {
    autoScroll({
      ref: sectionRefs,
      section: selectedSection,
      previousSection,
      previousQuestion,
      isSmallScreen,
    });
  };

  useEffect((): void => {
    if (selectedSection !== previousSection && !selectedQuestion)
      handleScroll();
  }, [selectedSection]);

  return (
    <View style={styles.rightContent} testID="accordion">
      {filteredSection.map((section: FaqSection): JSX.Element | null => {
        const { title } = section;
        const shouldScroll = title !== selectedSection;
        if (title === SectionValue.All) return null;
        else {
          const content: JSX.Element = (
            <>
              <SectionTitle {...{ title }} />
              <FaqCard {...{ section, faqSelection, handleSelect }} />
            </>
          );

          return Platform.OS === 'web' ? (
            <div
              ref={sectionRefs[title]}
              style={isSmallScreen ? webSmallAccordion : webDefaultAccordion}
              key={title}
            >
              {content}
            </div>
          ) : (
            <View
              style={styles.smallAccordion}
              onLayout={(event: LayoutChangeEvent): void => {
                const {
                  nativeEvent: { layout },
                } = event;
                setLayoutMap(
                  (priorState: LayoutMap): LayoutMap =>
                    updateLayoutMap({
                      layout,
                      section: title as SectionValue,
                      shouldScroll: previousSection !== selectedSection,
                      priorState,
                      handleScroll: shouldScroll ? handleScroll : undefined,
                    })
                );
              }}
              key={title}
            >
              {content}
            </View>
          );
        }
      })}
    </View>
  );
};
