import { ConventionalNativeSelect, Txt, HelperText } from '@vst/beam';

import { useScreenResolution } from '@mfe/shared/util';

import { FormValues, dateOptions, DateOfBirth } from '../../utils';

import styles from './Form.module.scss';
import { useTranslation } from 'react-i18next';

interface DOBProps {
  values: FormValues['dob'];
  onChangeHandlers: Record<
    'month' | 'day' | 'year',
    React.FormEventHandler<HTMLSelectElement>
  >;
  error: string;
  isValid: boolean;
  validateDOB: (date: DateOfBirth) => void;
}

export const DateOfBirthField = (props: DOBProps) => {
  const { values, onChangeHandlers, error, validateDOB, isValid } = props;
  const { day, month, year } = values;

  const hasError = error !== '' && !isValid;

  const { isExtraSmall, isSmall } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;

  const { t } = useTranslation('ACP', { keyPrefix: 'Form' });

  const handleBlur = () => {
    validateDOB({
      day,
      month,
      year,
    });
  };

  return (
    <div className={styles['dob-field-wrapper']}>
      <Txt variant="labelSmall" component="label" color="subtle">
        {t('fields.dateOfBirth')}
      </Txt>
      <div className={styles['dob-selectors']}>
        <ConventionalNativeSelect
          data-cy="acp-month-of-birth"
          id="month"
          name="month"
          placeholder="Month"
          fluid={isMobile}
          options={dateOptions.MONTHS}
          value={month}
          onChange={onChangeHandlers.month}
          onBlur={handleBlur}
        />
        {!isMobile && <Separator />}
        <ConventionalNativeSelect
          data-cy="acp-day-of-birth"
          id="day"
          name="day"
          placeholder="Day"
          fluid={isMobile}
          options={dateOptions.DAYS}
          value={day}
          onChange={onChangeHandlers.day}
          onBlur={handleBlur}
        />
        {!isMobile && <Separator />}
        <ConventionalNativeSelect
          data-cy="acp-year-of-birth"
          id="year"
          name="year"
          placeholder="Year"
          fluid={isMobile}
          options={dateOptions.YEARS}
          value={year}
          onChange={onChangeHandlers.year}
          onBlur={handleBlur}
        />
      </div>
      {hasError && (
        <HelperText
          className={styles['helper-text']}
          helpMessage={error}
          state="error"
        />
      )}
    </div>
  );
};

const Separator = () => <Txt variant="paragraphRegular">/</Txt>;
