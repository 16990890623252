import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { ManageBilling } from '@mfe/features/billing';
import { setIsConfirmationModalOpen } from '@mfe/to-be-migrated/redux/updateBillCycle';

import { PayBillLink } from '../../utils/Navigation';
import { useMessageHandler } from '../useMessageHandler';

const PayBill = (): JSX.Element => {
  useMessageHandler();

  const history = useHistory();
  const dispatch = useDispatch();

  //to be revomed
  const openUpdateBillCycleConfirmationModal = () => {
    history.push(`/${PayBillLink.PayBill}/${PayBillLink.UpdateBillCycle}`);

    dispatch(setIsConfirmationModalOpen(true));
  };

  //to be revomed
  const navigateToBilling = () => {
    history.replace(`/${PayBillLink.PayBill}`);
    dispatch(setIsConfirmationModalOpen(false));
  };

  //to be removed
  const navigateToSelectBillCycle = () => {
    history.replace(`/${PayBillLink.PayBill}/${PayBillLink.SelectBillCycle}`, {
      /*
       * This "state" object prevents `redirectRoute`
       * from redirecting to /PayBill when the user is trying
       * to navigate to /PayBill/SelectBillCycle
       *
       * /Navigator/utils.tsx - redirect route
       */
      skip: 'redirect',
    });
    dispatch(setIsConfirmationModalOpen(false));
  };

  return <ManageBilling />;
};

export default PayBill;
