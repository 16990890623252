const DeskLaptop = () => (
  <svg viewBox="0 0 480 280" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1147_13155)">
      <g opacity="0.5" clip-path="url(#clip1_1147_13155)">
        <path
          d="M167.703 12.3994L272.236 56.7313C287.892 63.3723 304.846 66.4241 321.845 65.6612L328.595 65.3436C354.722 64.1502 380.371 72.6167 400.611 89.1163C420.851 105.616 434.256 128.984 438.249 154.731L438.834 158.508C442.062 179.349 438.548 200.673 428.801 219.393C419.054 238.113 403.579 253.258 384.617 262.635C365.654 272.012 344.188 275.135 323.324 271.553C302.461 267.97 283.284 257.867 268.569 242.707L252.198 225.853C244.825 218.255 235.914 212.307 226.056 208.403C216.199 204.5 205.62 202.729 195.023 203.21L101.935 207.393C67.3332 208.946 48.783 167.455 72.9674 142.819C77.653 138.055 80.9972 132.147 82.6642 125.688C84.3312 119.229 84.2621 112.446 82.4639 106.022L78.1587 90.5804C63.3469 37.4597 116.765 -9.20187 167.703 12.3994Z"
          fill="#CCF0FF"
        />
      </g>
      <g clip-path="url(#clip2_1147_13155)">
        <rect x="26" y="242" width="416" height="1.9669" fill="#DEE4E8" />
      </g>
      <path
        d="M161.652 149.031C161.652 149.031 150.839 86.4753 151.907 53.4293C153.643 -1.78329 163.076 -7.94824 163.076 -7.94824C163.076 -7.94824 166.013 74.508 164.767 106.874C163.942 132.079 165.013 157.311 167.971 182.349L161.652 149.031Z"
        fill="#006A85"
      />
      <path
        d="M169.529 209.819C168.469 192.67 165.923 175.65 161.919 158.958C155.957 137.018 141.406 106.284 137.267 64.036C133.129 21.788 141.717 14.7617 141.717 14.7617C141.717 14.7617 152.174 82.7575 158.805 120.971C165.435 159.185 170.819 200.254 171.22 209.728L169.529 209.819Z"
        fill="#03485C"
      />
      <path
        d="M169.529 209.819C168.469 192.67 165.923 175.65 161.919 158.958C155.957 137.018 141.406 108.369 137.267 66.1212C133.129 23.8732 141.717 14.7617 141.717 14.7617C141.717 14.7617 136.333 48.125 154.666 116.075C162.606 146.798 168.141 178.114 171.22 209.728L169.529 209.819Z"
        fill="#006A85"
      />
      <path
        d="M168.773 209.411C168.773 209.411 173.578 169.339 175.002 133.845C176.426 98.3509 191.378 70.382 192.579 37.2C193.781 4.01805 183.19 -12.3916 183.19 -12.3916C183.19 -12.3916 184.925 40.1012 177.806 71.9685C170.686 103.836 171.621 126.365 171.443 155.059C171.295 173.279 169.807 191.462 166.993 209.456L168.773 209.411Z"
        fill="#006A85"
      />
      <path
        d="M173.934 209.683C173.934 209.683 175.358 160.228 166.993 127.136C159.072 95.7675 163.032 42.8214 173 32.6221C173 32.6221 173.534 59.2311 174.424 79.1765C175.314 99.122 176.471 175.776 175.314 209.683H173.934Z"
        fill="#03485C"
      />
      <path
        d="M173.934 209.683C173.934 209.683 175.447 158.278 170.864 124.552C165.924 88.288 168.817 47.4905 173 32.6221C173 32.6221 173.533 59.2311 174.423 79.1765C175.313 99.122 176.47 175.776 175.313 209.683H173.934Z"
        fill="#006A85"
      />
      <path
        d="M167.081 209.91C167.081 209.91 161.919 173.872 157.069 152.431C152.219 130.99 137.267 106.511 129.969 82.2139C129.969 82.2139 126.766 109.14 135.443 126.955C144.12 144.77 151.996 148.034 158.048 169.747C161.796 182.881 164.226 196.367 165.301 210.001L167.081 209.91Z"
        fill="#03485C"
      />
      <path
        d="M177.405 209.728C178.922 194.82 181.224 180.005 184.303 165.349C189.286 142.231 200.589 135.748 203.081 103.428C204.838 86.5448 204.073 69.4881 200.811 52.8389C200.811 52.8389 189.153 114.488 186.75 132.575C184.347 150.662 181.9 170.88 179.23 187.924C176.871 203.427 175.714 209.864 175.714 209.864L177.405 209.728Z"
        fill="#03485C"
      />
      <path
        d="M177.405 209.728C178.922 194.82 181.224 180.005 184.303 165.349C189.286 142.231 200.589 135.748 203.081 103.428C204.838 86.5448 204.073 69.4881 200.811 52.8389C201.357 64.231 200.955 75.6497 199.61 86.9728C198.162 101.416 195.618 115.722 192.001 129.765C186.266 148.783 181.996 168.229 179.23 187.924C176.871 203.427 175.714 209.864 175.714 209.864L177.405 209.728Z"
        fill="#03485C"
      />
      <path
        d="M198.14 200.865H145.213C143.298 204.826 142.304 209.169 142.304 213.57C142.304 221.367 145.396 228.846 150.899 234.359C156.401 239.873 163.865 242.97 171.647 242.97C179.429 242.97 186.893 239.873 192.395 234.359C197.898 228.846 200.99 221.367 200.99 213.57C201.008 209.174 200.034 204.83 198.14 200.865Z"
        fill="#677A89"
      />
      <g clip-path="url(#clip3_1147_13155)">
        <path
          d="M377.556 231.889H202.474C200.551 231.889 198.706 231.123 197.346 229.76C195.986 228.397 195.222 226.548 195.222 224.62L207.24 114.553C207.24 112.625 208.004 110.776 209.364 109.413C210.724 108.05 212.568 107.284 214.492 107.284H389.47C391.393 107.284 393.238 108.05 394.598 109.413C395.958 110.776 396.722 112.625 396.722 114.553L384.808 224.62C384.808 226.548 384.044 228.397 382.684 229.76C381.324 231.123 379.479 231.889 377.556 231.889Z"
          fill="#F2F5F8"
        />
        <path
          d="M377.554 234.07H202.472C199.972 234.07 197.574 233.074 195.806 231.302C194.038 229.53 193.044 227.127 193.044 224.621L205.062 114.242C205.116 111.772 206.133 109.421 207.895 107.694C209.657 105.966 212.025 104.999 214.489 105H389.467C390.719 105 391.959 105.247 393.115 105.727C394.271 106.207 395.322 106.911 396.207 107.798C397.092 108.685 397.794 109.738 398.273 110.897C398.752 112.056 398.999 113.299 398.999 114.553L386.981 224.828C386.927 227.298 385.91 229.649 384.148 231.376C382.386 233.104 380.019 234.071 377.554 234.07ZM214.489 109.465C213.143 109.465 211.852 110.001 210.9 110.955C209.948 111.909 209.413 113.204 209.413 114.553L197.499 224.828C197.553 226.142 198.111 227.383 199.057 228.293C200.004 229.202 201.264 229.71 202.576 229.709H377.657C378.319 229.709 378.974 229.577 379.584 229.32C380.195 229.063 380.748 228.686 381.211 228.212C381.674 227.739 382.038 227.177 382.282 226.56C382.525 225.943 382.644 225.284 382.63 224.621L394.337 114.242C394.257 112.947 393.688 111.731 392.744 110.843C391.801 109.956 390.555 109.463 389.26 109.465H214.489Z"
          fill="#9FAFBC"
        />
        <path
          d="M381.906 233.448H117.315C115.484 233.448 114 234.936 114 236.771V239.678C114 241.514 115.484 243.001 117.315 243.001H381.906C383.737 243.001 385.221 241.514 385.221 239.678V236.771C385.221 234.936 383.737 233.448 381.906 233.448Z"
          fill="#9FAFBC"
        />
        <path
          opacity="0.5"
          d="M382.838 243.001H276.235V233.448H382.838C383.47 233.448 384.076 233.7 384.522 234.148C384.969 234.596 385.22 235.203 385.22 235.836V240.717C385.221 241.026 385.158 241.332 385.038 241.616C384.917 241.9 384.739 242.156 384.517 242.37C384.294 242.584 384.03 242.75 383.742 242.858C383.453 242.967 383.146 243.015 382.838 243.001Z"
          fill="#465967"
        />
        <path
          d="M375.483 122.133H225.887C224.113 122.133 222.675 123.574 222.675 125.352V125.456C222.675 127.233 224.113 128.675 225.887 128.675H375.483C377.257 128.675 378.695 127.233 378.695 125.456V125.352C378.695 123.574 377.257 122.133 375.483 122.133Z"
          fill="#32424E"
        />
        <path
          d="M264.322 171.974H218.635L222.986 138.331H268.673L264.322 171.974Z"
          fill="#C3CDD5"
        />
        <path
          d="M354.555 211.226H213.868L216.769 177.479H359.01L354.555 211.226Z"
          fill="#C3CDD5"
        />
        <path
          d="M317.883 171.974H272.196L276.547 138.331H322.337L317.883 171.974Z"
          fill="#C3CDD5"
        />
      </g>
      <g clip-path="url(#clip4_1147_13155)">
        <path
          d="M52.3005 199.143L39 243H67.4483L52.3005 199.143Z"
          fill="#677A89"
        />
        <path
          d="M159.221 243.001L144.443 199.357C144.443 199.357 52.2261 198.93 52.5217 199.357C52.8173 199.784 67.3 243.214 67.3 243.214L159.221 243.001Z"
          fill="#C3CDD5"
        />
        <path
          d="M89.2459 204.839C89.3838 204.857 89.5244 204.838 89.6514 204.783C89.7784 204.729 89.8868 204.64 89.964 204.529C90.0412 204.417 90.0842 204.287 90.0879 204.153C90.0915 204.019 90.0558 203.886 89.9849 203.771C89.9049 203.488 89.7321 203.238 89.4923 203.059C89.2525 202.879 88.9586 202.779 88.6548 202.774C88.5266 202.769 88.399 202.794 88.2826 202.846C88.1661 202.898 88.064 202.976 87.9847 203.073C87.9054 203.17 87.8512 203.284 87.8264 203.405C87.8016 203.527 87.8069 203.652 87.842 203.771C87.9227 204.072 88.1037 204.34 88.3573 204.533C88.6108 204.726 88.923 204.833 89.2459 204.839Z"
          fill="#677A89"
        />
        <path
          d="M92.7196 204.838C92.8564 204.847 92.9935 204.823 93.118 204.767C93.2424 204.711 93.3503 204.626 93.4314 204.52C93.5125 204.413 93.5644 204.288 93.582 204.157C93.5997 204.026 93.5826 203.893 93.5324 203.77C93.4383 203.482 93.2516 203.23 92.9992 203.051C92.7469 202.871 92.4419 202.774 92.1284 202.773C92.0006 202.763 91.8722 202.784 91.7557 202.836C91.6393 202.888 91.5388 202.968 91.4643 203.069C91.3897 203.169 91.3436 203.287 91.3305 203.41C91.3174 203.533 91.3377 203.657 91.3895 203.77C91.4554 204.065 91.6222 204.331 91.8633 204.525C92.1044 204.718 92.4059 204.829 92.7196 204.838Z"
          fill="#677A89"
        />
        <path
          d="M95.8226 204.839C95.9595 204.848 96.0966 204.824 96.221 204.768C96.3455 204.712 96.4533 204.627 96.5345 204.521C96.6156 204.414 96.6674 204.289 96.6851 204.158C96.7027 204.027 96.6856 203.894 96.6354 203.771C96.532 203.488 96.3428 203.242 96.0925 203.064C95.8422 202.887 95.5422 202.785 95.2314 202.774C95.1032 202.769 94.9757 202.794 94.8592 202.846C94.7427 202.898 94.6407 202.976 94.5614 203.073C94.4821 203.17 94.4278 203.284 94.403 203.405C94.3782 203.527 94.3836 203.652 94.4187 203.771C94.5097 204.067 94.6935 204.328 94.9448 204.519C95.1962 204.71 95.5028 204.822 95.8226 204.839Z"
          fill="#677A89"
        />
        <path
          d="M99.1486 204.838C99.7397 204.838 100.035 204.411 99.8875 203.77C99.8076 203.487 99.6347 203.237 99.3949 203.058C99.155 202.878 98.8612 202.778 98.5574 202.773C98.4239 202.748 98.2858 202.759 98.1582 202.804C98.0307 202.85 97.9187 202.929 97.8346 203.032C97.7506 203.135 97.6977 203.258 97.6818 203.389C97.6659 203.519 97.6877 203.651 97.7446 203.77C97.8253 204.071 98.0063 204.339 98.2598 204.532C98.5134 204.725 98.8256 204.832 99.1486 204.838Z"
          fill="#677A89"
        />
        <path
          d="M102.4 204.838C102.537 204.847 102.674 204.823 102.798 204.767C102.923 204.711 103.03 204.626 103.112 204.52C103.193 204.413 103.245 204.288 103.262 204.157C103.28 204.026 103.263 203.893 103.213 203.77C103.109 203.487 102.92 203.241 102.67 203.063C102.419 202.886 102.119 202.785 101.809 202.773C101.681 202.763 101.552 202.784 101.436 202.836C101.319 202.888 101.219 202.968 101.144 203.069C101.07 203.169 101.024 203.287 101.011 203.41C100.998 203.533 101.018 203.657 101.07 203.77C101.136 204.065 101.302 204.331 101.544 204.525C101.785 204.718 102.086 204.829 102.4 204.838Z"
          fill="#677A89"
        />
        <path
          d="M105.724 204.839C105.861 204.857 106.002 204.838 106.129 204.783C106.256 204.729 106.364 204.64 106.442 204.529C106.519 204.417 106.562 204.287 106.565 204.153C106.569 204.019 106.533 203.886 106.462 203.771C106.383 203.488 106.21 203.238 105.97 203.059C105.73 202.879 105.436 202.779 105.132 202.774C105.004 202.769 104.877 202.794 104.76 202.846C104.644 202.898 104.542 202.976 104.462 203.073C104.383 203.17 104.329 203.284 104.304 203.405C104.279 203.527 104.284 203.652 104.32 203.771C104.411 204.067 104.594 204.328 104.846 204.519C105.097 204.71 105.404 204.822 105.724 204.839Z"
          fill="#677A89"
        />
        <path
          d="M108.975 204.838C109.64 204.838 109.936 204.411 109.788 203.77C109.694 203.482 109.508 203.23 109.255 203.051C109.003 202.871 108.698 202.774 108.384 202.773C108.257 202.763 108.128 202.784 108.012 202.836C107.895 202.888 107.795 202.968 107.72 203.069C107.646 203.169 107.6 203.287 107.586 203.41C107.573 203.533 107.594 203.657 107.645 203.77C107.711 204.065 107.878 204.331 108.119 204.525C108.36 204.718 108.662 204.829 108.975 204.838Z"
          fill="#677A89"
        />
        <path
          d="M112.3 204.838C112.437 204.847 112.574 204.823 112.699 204.767C112.823 204.711 112.931 204.626 113.012 204.52C113.093 204.413 113.145 204.288 113.163 204.157C113.18 204.026 113.163 203.893 113.113 203.77C113.01 203.487 112.82 203.241 112.57 203.063C112.32 202.886 112.02 202.785 111.709 202.773C111.581 202.763 111.453 202.784 111.336 202.836C111.22 202.888 111.119 202.968 111.045 203.069C110.97 203.169 110.924 203.287 110.911 203.41C110.898 203.533 110.918 203.657 110.97 203.77C111.036 204.065 111.203 204.331 111.444 204.525C111.685 204.718 111.986 204.829 112.3 204.838Z"
          fill="#677A89"
        />
        <path
          d="M115.625 204.839C116.216 204.839 116.512 204.412 116.364 203.771C116.284 203.488 116.112 203.238 115.872 203.059C115.632 202.879 115.338 202.779 115.034 202.774C114.906 202.769 114.778 202.794 114.662 202.846C114.545 202.898 114.443 202.976 114.364 203.073C114.285 203.17 114.231 203.284 114.206 203.405C114.181 203.527 114.186 203.652 114.221 203.771C114.302 204.072 114.483 204.34 114.737 204.533C114.99 204.726 115.302 204.833 115.625 204.839Z"
          fill="#677A89"
        />
        <path
          d="M118.877 204.838C119.014 204.847 119.151 204.823 119.276 204.767C119.4 204.711 119.508 204.626 119.589 204.52C119.67 204.413 119.722 204.288 119.74 204.157C119.757 204.026 119.74 203.893 119.69 203.77C119.596 203.482 119.409 203.23 119.157 203.051C118.905 202.871 118.6 202.774 118.286 202.773C118.158 202.763 118.03 202.784 117.913 202.836C117.797 202.888 117.697 202.968 117.622 203.069C117.547 203.169 117.501 203.287 117.488 203.41C117.475 203.533 117.495 203.657 117.547 203.77C117.613 204.065 117.78 204.331 118.021 204.525C118.262 204.718 118.564 204.829 118.877 204.838Z"
          fill="#677A89"
        />
        <path
          d="M122.276 204.839C122.414 204.857 122.555 204.838 122.682 204.783C122.809 204.729 122.917 204.64 122.994 204.529C123.071 204.417 123.114 204.287 123.118 204.153C123.122 204.019 123.086 203.886 123.015 203.771C122.935 203.488 122.762 203.238 122.523 203.059C122.283 202.879 121.989 202.779 121.685 202.774C121.557 202.769 121.429 202.794 121.313 202.846C121.196 202.898 121.094 202.976 121.015 203.073C120.936 203.17 120.881 203.284 120.857 203.405C120.832 203.527 120.837 203.652 120.872 203.771C120.963 204.067 121.147 204.328 121.398 204.519C121.65 204.71 121.956 204.822 122.276 204.839Z"
          fill="#677A89"
        />
        <path
          d="M125.527 204.838C126.118 204.838 126.414 204.411 126.266 203.77C126.172 203.482 125.985 203.23 125.733 203.051C125.48 202.871 125.175 202.774 124.862 202.773C124.734 202.763 124.606 202.784 124.489 202.836C124.373 202.888 124.272 202.968 124.198 203.069C124.123 203.169 124.077 203.287 124.064 203.41C124.051 203.533 124.071 203.657 124.123 203.77C124.204 204.071 124.385 204.339 124.638 204.532C124.892 204.725 125.204 204.832 125.527 204.838Z"
          fill="#677A89"
        />
        <path
          d="M128.778 204.838C128.915 204.847 129.052 204.823 129.177 204.767C129.301 204.711 129.409 204.626 129.49 204.52C129.571 204.413 129.623 204.288 129.641 204.157C129.658 204.026 129.641 203.893 129.591 203.77C129.488 203.487 129.298 203.241 129.048 203.063C128.798 202.886 128.498 202.785 128.187 202.773C128.059 202.763 127.931 202.784 127.814 202.836C127.698 202.888 127.597 202.968 127.523 203.069C127.448 203.169 127.402 203.287 127.389 203.41C127.376 203.533 127.396 203.657 127.448 203.77C127.514 204.065 127.681 204.331 127.922 204.525C128.163 204.718 128.464 204.829 128.778 204.838Z"
          fill="#677A89"
        />
        <path
          d="M132.103 204.839C132.695 204.839 132.99 204.412 132.842 203.771C132.762 203.488 132.59 203.238 132.35 203.059C132.11 202.879 131.816 202.779 131.512 202.774C131.384 202.769 131.256 202.794 131.14 202.846C131.023 202.898 130.921 202.976 130.842 203.073C130.763 203.17 130.709 203.284 130.684 203.405C130.659 203.527 130.664 203.652 130.699 203.771C130.78 204.072 130.961 204.34 131.215 204.533C131.468 204.726 131.78 204.833 132.103 204.839Z"
          fill="#677A89"
        />
        <path
          d="M135.355 204.838C135.492 204.847 135.629 204.823 135.754 204.767C135.878 204.711 135.986 204.626 136.067 204.52C136.148 204.413 136.2 204.288 136.218 204.157C136.235 204.026 136.218 203.893 136.168 203.77C136.074 203.482 135.887 203.23 135.635 203.051C135.383 202.871 135.078 202.774 134.764 202.773C134.636 202.763 134.508 202.784 134.392 202.836C134.275 202.888 134.175 202.968 134.1 203.069C134.025 203.169 133.979 203.287 133.966 203.41C133.953 203.533 133.973 203.657 134.025 203.77C134.091 204.065 134.258 204.331 134.499 204.525C134.74 204.718 135.042 204.829 135.355 204.838Z"
          fill="#677A89"
        />
        <path
          d="M138.681 204.839C138.818 204.857 138.959 204.838 139.086 204.783C139.213 204.729 139.321 204.64 139.399 204.529C139.476 204.417 139.519 204.287 139.522 204.153C139.526 204.019 139.49 203.886 139.419 203.771C139.34 203.488 139.167 203.238 138.927 203.059C138.687 202.879 138.393 202.779 138.089 202.774C137.961 202.769 137.834 202.794 137.717 202.846C137.601 202.898 137.499 202.976 137.419 203.073C137.34 203.17 137.286 203.284 137.261 203.405C137.236 203.527 137.242 203.652 137.277 203.771C137.368 204.067 137.551 204.328 137.803 204.519C138.054 204.71 138.361 204.822 138.681 204.839Z"
          fill="#677A89"
        />
        <path
          d="M76.8327 203.77C76.7386 203.482 76.552 203.23 76.2996 203.051C76.0472 202.871 75.7422 202.774 75.4288 202.773C75.301 202.763 75.1725 202.784 75.056 202.836C74.9396 202.888 74.8392 202.968 74.7646 203.069C74.69 203.169 74.6439 203.287 74.6308 203.41C74.6177 203.533 74.638 203.657 74.6898 203.77C74.7557 204.065 74.9225 204.331 75.1636 204.525C75.4047 204.718 75.7062 204.829 76.0198 204.838C76.1567 204.847 76.2938 204.823 76.4183 204.767C76.5428 204.711 76.6506 204.626 76.7317 204.52C76.8129 204.413 76.8647 204.288 76.8823 204.157C76.9 204.026 76.8829 203.893 76.8327 203.77Z"
          fill="#677A89"
        />
        <path
          d="M79.3451 204.839C79.4829 204.857 79.6235 204.838 79.7505 204.783C79.8775 204.729 79.9859 204.64 80.0631 204.529C80.1404 204.417 80.1833 204.287 80.1869 204.153C80.1906 204.019 80.155 203.886 80.084 203.771C80.0041 203.488 79.8312 203.238 79.5914 203.059C79.3516 202.879 79.0577 202.779 78.754 202.774C78.6257 202.769 78.4981 202.794 78.3817 202.846C78.2652 202.898 78.1631 202.976 78.0838 203.073C78.0045 203.17 77.9503 203.284 77.9255 203.405C77.9007 203.527 77.9061 203.652 77.9411 203.771C78.0322 204.067 78.216 204.328 78.4673 204.519C78.7187 204.71 79.0253 204.822 79.3451 204.839Z"
          fill="#677A89"
        />
        <path
          d="M82.6694 204.838C83.2606 204.838 83.5561 204.411 83.4083 203.77C83.3143 203.482 83.1276 203.23 82.8753 203.051C82.6229 202.871 82.3179 202.774 82.0044 202.773C81.8766 202.763 81.7482 202.784 81.6317 202.836C81.5153 202.888 81.4148 202.968 81.3403 203.069C81.2657 203.169 81.2196 203.287 81.2065 203.41C81.1934 203.533 81.2137 203.657 81.2655 203.77C81.3462 204.071 81.5272 204.339 81.7807 204.532C82.0343 204.725 82.3464 204.832 82.6694 204.838Z"
          fill="#677A89"
        />
        <path
          d="M85.9208 204.838C86.0577 204.847 86.1947 204.823 86.3192 204.767C86.4437 204.711 86.5515 204.626 86.6326 204.52C86.7138 204.413 86.7656 204.288 86.7833 204.157C86.8009 204.026 86.7838 203.893 86.7336 203.77C86.6302 203.487 86.441 203.241 86.1906 203.063C85.9403 202.886 85.6404 202.785 85.3296 202.773C85.2018 202.763 85.0734 202.784 84.9569 202.836C84.8405 202.888 84.74 202.968 84.6655 203.069C84.5909 203.169 84.5448 203.287 84.5317 203.41C84.5186 203.533 84.539 203.657 84.5907 203.77C84.6566 204.065 84.8235 204.331 85.0646 204.525C85.3056 204.718 85.6071 204.829 85.9208 204.838Z"
          fill="#677A89"
        />
        <path
          d="M88.8026 206.975C88.8796 207.265 89.0496 207.524 89.2884 207.716C89.5271 207.908 89.8226 208.022 90.1327 208.043C90.2696 208.052 90.4066 208.028 90.5311 207.972C90.6556 207.916 90.7634 207.831 90.8445 207.725C90.9257 207.618 90.9775 207.493 90.9952 207.362C91.0128 207.231 90.9957 207.098 90.9455 206.975C90.8515 206.687 90.6647 206.435 90.4124 206.256C90.16 206.077 89.855 205.979 89.5415 205.978C89.4137 205.968 89.2853 205.989 89.1688 206.041C89.0524 206.093 88.9519 206.173 88.8774 206.274C88.8028 206.374 88.7567 206.492 88.7436 206.615C88.7305 206.738 88.7509 206.862 88.8026 206.975Z"
          fill="#677A89"
        />
        <path
          d="M92.0539 206.975C92.1618 207.261 92.3503 207.513 92.5983 207.701C92.8463 207.89 93.1439 208.008 93.4578 208.043C93.5886 208.04 93.7166 208.007 93.8312 207.946C93.9458 207.886 94.0434 207.799 94.116 207.694C94.1885 207.589 94.2337 207.469 94.2478 207.344C94.2618 207.219 94.2443 207.092 94.1968 206.975C94.1168 206.692 93.944 206.443 93.7042 206.263C93.4644 206.083 93.1705 205.983 92.8667 205.978C92.7385 205.973 92.6109 205.998 92.4945 206.05C92.378 206.102 92.2759 206.18 92.1966 206.277C92.1173 206.374 92.0631 206.488 92.0383 206.609C92.0135 206.731 92.0188 206.856 92.0539 206.975Z"
          fill="#677A89"
        />
        <path
          d="M95.3798 206.975C95.4708 207.271 95.6546 207.532 95.906 207.723C96.1573 207.915 96.464 208.026 96.7837 208.043C97.3749 208.043 97.6704 207.545 97.5227 206.975C97.4286 206.687 97.2419 206.435 96.9895 206.256C96.7371 206.077 96.4322 205.979 96.1187 205.978C95.9909 205.968 95.8624 205.989 95.746 206.041C95.6295 206.093 95.5291 206.173 95.4545 206.274C95.38 206.374 95.3339 206.492 95.3208 206.615C95.3077 206.738 95.328 206.862 95.3798 206.975Z"
          fill="#677A89"
        />
        <path
          d="M98.7044 206.975C98.7814 207.265 98.9514 207.524 99.1902 207.716C99.429 207.908 99.7244 208.022 100.034 208.043C100.171 208.052 100.308 208.028 100.433 207.972C100.557 207.916 100.665 207.831 100.746 207.725C100.828 207.618 100.879 207.493 100.897 207.362C100.915 207.231 100.898 207.098 100.847 206.975C100.744 206.692 100.555 206.446 100.304 206.269C100.054 206.091 99.7541 205.99 99.4434 205.978C99.3156 205.968 99.1871 205.989 99.0707 206.041C98.9542 206.093 98.8538 206.173 98.7793 206.274C98.7047 206.374 98.6586 206.492 98.6455 206.615C98.6324 206.738 98.6527 206.862 98.7044 206.975Z"
          fill="#677A89"
        />
        <path
          d="M101.956 206.975C102.047 207.271 102.231 207.532 102.482 207.723C102.733 207.915 103.04 208.026 103.36 208.043C103.951 208.043 104.246 207.544 104.099 206.975C104.019 206.692 103.846 206.443 103.606 206.263C103.366 206.083 103.072 205.983 102.769 205.978C102.64 205.973 102.513 205.998 102.396 206.05C102.28 206.102 102.178 206.18 102.098 206.277C102.019 206.374 101.965 206.488 101.94 206.609C101.915 206.731 101.921 206.856 101.956 206.975Z"
          fill="#677A89"
        />
        <path
          d="M105.281 206.975C105.358 207.265 105.528 207.524 105.766 207.716C106.005 207.908 106.301 208.022 106.611 208.043C106.748 208.052 106.885 208.028 107.009 207.972C107.134 207.916 107.241 207.831 107.323 207.725C107.404 207.618 107.456 207.493 107.473 207.362C107.491 207.231 107.474 207.098 107.424 206.975C107.329 206.687 107.143 206.435 106.89 206.256C106.638 206.077 106.333 205.979 106.02 205.978C105.892 205.968 105.763 205.989 105.647 206.041C105.53 206.093 105.43 206.173 105.355 206.274C105.281 206.374 105.235 206.492 105.222 206.615C105.209 206.738 105.229 206.862 105.281 206.975Z"
          fill="#677A89"
        />
        <path
          d="M108.532 206.975C108.64 207.261 108.828 207.513 109.076 207.701C109.324 207.89 109.622 208.008 109.936 208.043C110.067 208.04 110.195 208.007 110.309 207.946C110.424 207.886 110.522 207.799 110.594 207.694C110.667 207.589 110.712 207.469 110.726 207.344C110.74 207.219 110.722 207.092 110.675 206.975C110.595 206.692 110.422 206.443 110.182 206.263C109.942 206.083 109.649 205.983 109.345 205.978C109.217 205.973 109.089 205.998 108.972 206.05C108.856 206.102 108.754 206.18 108.675 206.277C108.595 206.374 108.541 206.488 108.516 206.609C108.491 206.731 108.497 206.856 108.532 206.975Z"
          fill="#677A89"
        />
        <path
          d="M111.858 206.975C111.949 207.271 112.133 207.532 112.384 207.723C112.635 207.915 112.942 208.026 113.262 208.043C113.853 208.043 114.148 207.545 114.001 206.975C113.907 206.687 113.72 206.435 113.468 206.256C113.215 206.077 112.91 205.979 112.597 205.978C112.469 205.968 112.34 205.989 112.224 206.041C112.108 206.093 112.007 206.173 111.933 206.274C111.858 206.374 111.812 206.492 111.799 206.615C111.786 206.738 111.806 206.862 111.858 206.975Z"
          fill="#677A89"
        />
        <path
          d="M114.887 206.975C114.964 207.265 115.134 207.524 115.372 207.716C115.611 207.908 115.907 208.022 116.217 208.043C116.353 208.052 116.491 208.028 116.615 207.972C116.739 207.916 116.847 207.831 116.928 207.725C117.01 207.618 117.061 207.493 117.079 207.362C117.097 207.231 117.08 207.098 117.029 206.975C116.926 206.692 116.737 206.446 116.487 206.269C116.236 206.091 115.936 205.99 115.625 205.978C115.498 205.968 115.369 205.989 115.253 206.041C115.136 206.093 115.036 206.173 114.961 206.274C114.887 206.374 114.841 206.492 114.828 206.615C114.814 206.738 114.835 206.862 114.887 206.975Z"
          fill="#677A89"
        />
        <path
          d="M118.434 206.975C118.525 207.271 118.709 207.532 118.96 207.723C119.211 207.915 119.518 208.026 119.838 208.043C119.968 208.04 120.097 208.007 120.211 207.946C120.326 207.886 120.423 207.799 120.496 207.694C120.568 207.589 120.614 207.469 120.628 207.344C120.642 207.219 120.624 207.092 120.577 206.975C120.497 206.692 120.324 206.443 120.084 206.263C119.844 206.083 119.55 205.983 119.247 205.978C119.118 205.973 118.991 205.998 118.874 206.05C118.758 206.102 118.656 206.18 118.577 206.277C118.497 206.374 118.443 206.488 118.418 206.609C118.393 206.731 118.399 206.856 118.434 206.975Z"
          fill="#677A89"
        />
        <path
          d="M121.758 206.975C121.835 207.265 122.005 207.524 122.244 207.716C122.483 207.908 122.778 208.022 123.088 208.043C123.225 208.052 123.362 208.028 123.487 207.972C123.611 207.916 123.719 207.831 123.8 207.725C123.881 207.618 123.933 207.493 123.951 207.362C123.968 207.231 123.951 207.098 123.901 206.975C123.807 206.687 123.62 206.435 123.368 206.256C123.116 206.077 122.811 205.979 122.497 205.978C122.369 205.968 122.241 205.989 122.124 206.041C122.008 206.093 121.908 206.173 121.833 206.274C121.758 206.374 121.712 206.492 121.699 206.615C121.686 206.738 121.706 206.862 121.758 206.975Z"
          fill="#677A89"
        />
        <path
          d="M125.01 206.975C125.117 207.261 125.306 207.513 125.554 207.701C125.802 207.89 126.099 208.008 126.413 208.043C126.55 208.052 126.687 208.028 126.812 207.972C126.936 207.916 127.044 207.831 127.125 207.725C127.206 207.618 127.258 207.493 127.276 207.362C127.294 207.231 127.276 207.098 127.226 206.975C127.123 206.692 126.934 206.446 126.683 206.268C126.433 206.091 126.133 205.99 125.822 205.978C125.694 205.973 125.566 205.998 125.45 206.05C125.334 206.102 125.231 206.18 125.152 206.277C125.073 206.374 125.019 206.488 124.994 206.609C124.969 206.731 124.974 206.856 125.01 206.975Z"
          fill="#677A89"
        />
        <path
          d="M128.335 206.974C128.426 207.27 128.61 207.531 128.861 207.722C129.113 207.914 129.419 208.025 129.739 208.042C130.33 208.042 130.626 207.543 130.478 206.974C130.398 206.691 130.225 206.442 129.985 206.262C129.745 206.082 129.452 205.982 129.148 205.977C129.014 205.952 128.876 205.963 128.749 206.008C128.621 206.054 128.509 206.133 128.425 206.236C128.341 206.339 128.288 206.462 128.272 206.593C128.256 206.723 128.278 206.855 128.335 206.974Z"
          fill="#677A89"
        />
        <path
          d="M131.66 206.975C131.737 207.265 131.907 207.524 132.146 207.716C132.385 207.908 132.68 208.022 132.99 208.043C133.127 208.052 133.264 208.028 133.389 207.972C133.513 207.916 133.621 207.831 133.702 207.725C133.783 207.618 133.835 207.493 133.853 207.362C133.87 207.231 133.853 207.098 133.803 206.975C133.699 206.692 133.51 206.446 133.26 206.269C133.01 206.091 132.71 205.99 132.399 205.978C132.271 205.968 132.143 205.989 132.026 206.041C131.91 206.093 131.809 206.173 131.735 206.274C131.66 206.374 131.614 206.492 131.601 206.615C131.588 206.738 131.608 206.862 131.66 206.975Z"
          fill="#677A89"
        />
        <path
          d="M134.911 206.975C135.019 207.261 135.208 207.513 135.456 207.701C135.704 207.89 136.001 208.008 136.315 208.043C136.446 208.04 136.574 208.007 136.689 207.946C136.803 207.886 136.901 207.799 136.973 207.694C137.046 207.589 137.091 207.469 137.105 207.344C137.119 207.219 137.102 207.092 137.054 206.975C136.974 206.692 136.801 206.443 136.562 206.263C136.322 206.083 136.028 205.983 135.724 205.978C135.596 205.973 135.468 205.998 135.352 206.05C135.235 206.102 135.133 206.18 135.054 206.277C134.975 206.374 134.92 206.488 134.896 206.609C134.871 206.731 134.876 206.856 134.911 206.975Z"
          fill="#677A89"
        />
        <path
          d="M138.236 206.975C138.313 207.265 138.483 207.524 138.722 207.716C138.961 207.908 139.256 208.022 139.566 208.043C139.703 208.052 139.84 208.028 139.965 207.972C140.089 207.916 140.197 207.831 140.278 207.725C140.359 207.618 140.411 207.493 140.429 207.362C140.446 207.231 140.429 207.098 140.379 206.975C140.285 206.687 140.098 206.435 139.846 206.256C139.594 206.077 139.289 205.979 138.975 205.978C138.847 205.968 138.719 205.989 138.602 206.041C138.486 206.093 138.386 206.173 138.311 206.274C138.236 206.374 138.19 206.492 138.177 206.615C138.164 206.738 138.184 206.862 138.236 206.975Z"
          fill="#677A89"
        />
        <path
          d="M77.9409 206.975C77.861 206.692 77.6882 206.443 77.4483 206.263C77.2085 206.083 76.9147 205.983 76.6109 205.978C76.4827 205.973 76.3551 205.998 76.2386 206.05C76.1221 206.102 76.0201 206.18 75.9408 206.277C75.8615 206.374 75.8072 206.488 75.7824 206.609C75.7576 206.731 75.763 206.856 75.7981 206.975C75.8891 207.271 76.0729 207.532 76.3243 207.723C76.5756 207.915 76.8823 208.026 77.202 208.043C77.3327 208.04 77.4608 208.007 77.5754 207.946C77.6899 207.886 77.7876 207.799 77.8602 207.694C77.9327 207.589 77.9779 207.469 77.992 207.344C78.006 207.219 77.9885 207.092 77.9409 206.975Z"
          fill="#677A89"
        />
        <path
          d="M78.9022 206.975C78.9792 207.265 79.1491 207.524 79.3879 207.716C79.6267 207.908 79.9222 208.022 80.2322 208.043C80.3691 208.052 80.5062 208.028 80.6307 207.972C80.7552 207.916 80.863 207.831 80.9441 207.725C81.0253 207.618 81.0771 207.493 81.0947 207.362C81.1124 207.231 81.0953 207.098 81.0451 206.975C80.951 206.687 80.7644 206.435 80.512 206.256C80.2596 206.077 79.9546 205.979 79.6412 205.978C79.5134 205.968 79.3849 205.989 79.2684 206.041C79.152 206.093 79.0516 206.173 78.977 206.274C78.9025 206.374 78.8563 206.492 78.8432 206.615C78.8301 206.738 78.8504 206.862 78.9022 206.975Z"
          fill="#677A89"
        />
        <path
          d="M82.1521 206.975C82.2599 207.261 82.4484 207.513 82.6964 207.701C82.9444 207.89 83.2421 208.008 83.556 208.043C83.6929 208.052 83.83 208.028 83.9544 207.972C84.0789 207.916 84.1868 207.831 84.2679 207.725C84.349 207.618 84.4009 207.493 84.4185 207.362C84.4362 207.231 84.4191 207.098 84.3689 206.975C84.2654 206.692 84.0762 206.446 83.8259 206.268C83.5756 206.091 83.2756 205.99 82.9649 205.978C82.8367 205.973 82.7091 205.998 82.5926 206.05C82.4761 206.102 82.3741 206.18 82.2948 206.277C82.2155 206.374 82.1612 206.488 82.1364 206.609C82.1116 206.731 82.117 206.856 82.1521 206.975Z"
          fill="#677A89"
        />
        <path
          d="M85.3303 206.975C85.4213 207.271 85.6052 207.532 85.8565 207.723C86.1079 207.915 86.4145 208.026 86.7343 208.043C87.3254 208.043 87.621 207.544 87.4732 206.975C87.3933 206.692 87.2204 206.443 86.9806 206.263C86.7407 206.083 86.4469 205.983 86.1431 205.978C86.0149 205.973 85.8873 205.998 85.7708 206.05C85.6543 206.102 85.5523 206.18 85.473 206.277C85.3937 206.374 85.3394 206.488 85.3146 206.609C85.2898 206.731 85.2952 206.856 85.3303 206.975Z"
          fill="#677A89"
        />
        <path
          d="M91.8321 210.178C91.7551 209.888 91.5851 209.629 91.3463 209.437C91.1075 209.245 90.8121 209.131 90.502 209.11C90.3651 209.101 90.2281 209.125 90.1036 209.181C89.9791 209.237 89.8713 209.322 89.7902 209.428C89.709 209.535 89.6572 209.66 89.6396 209.791C89.6219 209.922 89.639 210.055 89.6892 210.178C89.7832 210.466 89.9699 210.718 90.2223 210.897C90.4747 211.077 90.7797 211.174 91.0931 211.175C91.221 211.186 91.3494 211.164 91.4658 211.112C91.5823 211.06 91.6827 210.98 91.7573 210.879C91.8319 210.779 91.878 210.661 91.8911 210.538C91.9042 210.415 91.8838 210.291 91.8321 210.178Z"
          fill="#677A89"
        />
        <path
          d="M95.1581 210.177C95.067 209.882 94.8832 209.62 94.6319 209.429C94.3805 209.238 94.0739 209.126 93.7541 209.109C93.163 209.109 92.8674 209.608 93.0152 210.177C93.0951 210.46 93.2679 210.71 93.5077 210.889C93.7476 211.069 94.0415 211.169 94.3452 211.174C94.4735 211.179 94.6011 211.154 94.7175 211.102C94.834 211.05 94.936 210.973 95.0153 210.875C95.0946 210.778 95.1489 210.664 95.1737 210.543C95.1985 210.422 95.1932 210.296 95.1581 210.177Z"
          fill="#677A89"
        />
        <path
          d="M98.4092 210.178C98.3322 209.888 98.1623 209.629 97.9235 209.437C97.6847 209.245 97.3892 209.131 97.0791 209.11C96.9423 209.101 96.8052 209.125 96.6807 209.181C96.5563 209.237 96.4484 209.322 96.3673 209.428C96.2861 209.535 96.2344 209.66 96.2167 209.791C96.1991 209.922 96.2162 210.055 96.2664 210.178C96.3698 210.461 96.5589 210.707 96.8093 210.885C97.0596 211.062 97.3596 211.164 97.6703 211.175C97.7981 211.186 97.9265 211.164 98.043 211.112C98.1594 211.06 98.2599 210.98 98.3344 210.879C98.409 210.779 98.4551 210.661 98.4682 210.538C98.4813 210.415 98.461 210.291 98.4092 210.178Z"
          fill="#677A89"
        />
        <path
          d="M101.734 210.177C101.653 209.876 101.472 209.608 101.218 209.415C100.965 209.223 100.653 209.115 100.33 209.109C99.7387 209.109 99.4431 209.608 99.5909 210.177C99.6849 210.465 99.8716 210.717 100.124 210.897C100.376 211.076 100.681 211.173 100.995 211.174C101.123 211.185 101.251 211.163 101.368 211.111C101.484 211.059 101.584 210.979 101.659 210.879C101.734 210.778 101.78 210.66 101.793 210.537C101.806 210.415 101.786 210.29 101.734 210.177Z"
          fill="#677A89"
        />
        <path
          d="M105.06 210.177C104.952 209.891 104.764 209.64 104.516 209.451C104.268 209.262 103.97 209.144 103.656 209.109C103.525 209.112 103.397 209.145 103.283 209.206C103.168 209.267 103.07 209.353 102.998 209.458C102.925 209.563 102.88 209.683 102.866 209.808C102.852 209.933 102.869 210.06 102.917 210.177C102.997 210.46 103.17 210.71 103.41 210.889C103.649 211.069 103.943 211.169 104.247 211.174C104.375 211.179 104.503 211.154 104.619 211.102C104.736 211.05 104.838 210.973 104.917 210.875C104.996 210.778 105.051 210.664 105.076 210.543C105.1 210.422 105.095 210.296 105.06 210.177Z"
          fill="#677A89"
        />
        <path
          d="M108.31 210.178C108.233 209.888 108.063 209.629 107.824 209.437C107.586 209.245 107.29 209.131 106.98 209.11C106.843 209.101 106.706 209.125 106.582 209.181C106.457 209.237 106.349 209.322 106.268 209.428C106.187 209.535 106.135 209.66 106.118 209.791C106.1 209.922 106.117 210.055 106.167 210.178C106.271 210.461 106.46 210.707 106.71 210.885C106.96 211.062 107.26 211.164 107.571 211.175C107.699 211.186 107.827 211.164 107.944 211.112C108.06 211.06 108.161 210.98 108.235 210.879C108.31 210.779 108.356 210.661 108.369 210.538C108.382 210.415 108.362 210.291 108.31 210.178Z"
          fill="#677A89"
        />
        <path
          d="M111.636 210.177C111.545 209.882 111.361 209.62 111.109 209.429C110.858 209.238 110.551 209.126 110.232 209.109C109.641 209.109 109.345 209.608 109.493 210.177C109.573 210.46 109.746 210.71 109.985 210.889C110.225 211.069 110.519 211.169 110.823 211.174C110.951 211.179 111.079 211.154 111.195 211.102C111.312 211.05 111.414 210.973 111.493 210.875C111.572 210.778 111.626 210.664 111.651 210.543C111.676 210.422 111.671 210.296 111.636 210.177Z"
          fill="#677A89"
        />
        <path
          d="M114.887 210.178C114.779 209.892 114.591 209.641 114.343 209.452C114.095 209.263 113.797 209.145 113.483 209.11C113.346 209.101 113.209 209.125 113.085 209.181C112.96 209.237 112.852 209.322 112.771 209.428C112.69 209.535 112.638 209.66 112.621 209.791C112.603 209.922 112.62 210.055 112.67 210.178C112.774 210.461 112.963 210.707 113.213 210.885C113.463 211.062 113.763 211.164 114.074 211.175C114.202 211.18 114.33 211.155 114.446 211.103C114.563 211.051 114.665 210.974 114.744 210.876C114.824 210.779 114.878 210.665 114.903 210.544C114.927 210.422 114.922 210.297 114.887 210.178Z"
          fill="#677A89"
        />
        <path
          d="M118.211 210.178C118.134 209.888 117.964 209.629 117.726 209.437C117.487 209.245 117.191 209.131 116.881 209.11C116.745 209.101 116.607 209.125 116.483 209.181C116.359 209.237 116.251 209.322 116.17 209.428C116.088 209.535 116.037 209.66 116.019 209.791C116.001 209.922 116.018 210.055 116.069 210.178C116.163 210.466 116.349 210.718 116.602 210.897C116.854 211.077 117.159 211.174 117.473 211.175C117.6 211.186 117.729 211.164 117.845 211.112C117.962 211.06 118.062 210.98 118.137 210.879C118.211 210.779 118.257 210.661 118.27 210.538C118.284 210.415 118.263 210.291 118.211 210.178Z"
          fill="#677A89"
        />
        <path
          d="M121.537 210.177C121.446 209.882 121.263 209.62 121.011 209.429C120.76 209.238 120.453 209.126 120.134 209.109C120.003 209.112 119.875 209.145 119.76 209.206C119.646 209.267 119.548 209.353 119.475 209.458C119.403 209.563 119.358 209.683 119.344 209.808C119.33 209.933 119.347 210.06 119.395 210.177C119.475 210.46 119.647 210.71 119.887 210.889C120.127 211.069 120.421 211.169 120.725 211.174C120.853 211.179 120.98 211.154 121.097 211.102C121.213 211.05 121.315 210.973 121.395 210.875C121.474 210.778 121.528 210.664 121.553 210.543C121.578 210.422 121.573 210.296 121.537 210.177Z"
          fill="#677A89"
        />
        <path
          d="M124.788 210.178C124.711 209.888 124.541 209.629 124.302 209.437C124.063 209.245 123.768 209.131 123.458 209.11C123.321 209.101 123.184 209.125 123.059 209.181C122.935 209.237 122.827 209.322 122.746 209.428C122.665 209.535 122.613 209.66 122.595 209.791C122.577 209.922 122.595 210.055 122.645 210.178C122.748 210.461 122.937 210.707 123.188 210.885C123.438 211.062 123.738 211.164 124.049 211.175C124.176 211.186 124.305 211.164 124.421 211.112C124.538 211.06 124.638 210.98 124.713 210.879C124.787 210.779 124.834 210.661 124.847 210.538C124.86 210.415 124.839 210.291 124.788 210.178Z"
          fill="#677A89"
        />
        <path
          d="M128.114 210.177C128.023 209.882 127.839 209.62 127.587 209.429C127.336 209.238 127.029 209.126 126.71 209.109C126.119 209.109 125.823 209.608 125.971 210.177C126.065 210.465 126.252 210.717 126.504 210.897C126.756 211.076 127.061 211.173 127.375 211.174C127.503 211.185 127.631 211.163 127.747 211.111C127.864 211.059 127.964 210.979 128.039 210.879C128.113 210.778 128.159 210.66 128.173 210.537C128.186 210.415 128.165 210.29 128.114 210.177Z"
          fill="#677A89"
        />
        <path
          d="M131.438 210.177C131.33 209.891 131.142 209.64 130.894 209.451C130.646 209.262 130.348 209.144 130.034 209.109C129.904 209.112 129.776 209.145 129.661 209.206C129.546 209.267 129.449 209.353 129.376 209.458C129.304 209.563 129.259 209.683 129.244 209.808C129.23 209.933 129.248 210.06 129.295 210.177C129.375 210.46 129.548 210.71 129.788 210.889C130.028 211.069 130.322 211.169 130.625 211.174C130.754 211.179 130.881 211.154 130.998 211.102C131.114 211.05 131.216 210.973 131.296 210.875C131.375 210.778 131.429 210.664 131.454 210.543C131.479 210.422 131.473 210.296 131.438 210.177Z"
          fill="#677A89"
        />
        <path
          d="M134.69 210.178C134.613 209.888 134.443 209.629 134.204 209.437C133.965 209.245 133.67 209.131 133.359 209.11C133.223 209.101 133.086 209.125 132.961 209.181C132.837 209.237 132.729 209.322 132.648 209.428C132.566 209.535 132.515 209.66 132.497 209.791C132.479 209.922 132.496 210.055 132.547 210.178C132.641 210.466 132.827 210.718 133.08 210.897C133.332 211.077 133.637 211.174 133.951 211.175C134.078 211.186 134.207 211.164 134.323 211.112C134.44 211.06 134.54 210.98 134.615 210.879C134.689 210.779 134.735 210.661 134.748 210.538C134.762 210.415 134.741 210.291 134.69 210.178Z"
          fill="#677A89"
        />
        <path
          d="M138.015 210.177C137.924 209.882 137.74 209.62 137.489 209.429C137.237 209.238 136.931 209.126 136.611 209.109C136.02 209.109 135.724 209.608 135.872 210.177C135.952 210.46 136.125 210.71 136.365 210.889C136.605 211.069 136.898 211.169 137.202 211.174C137.33 211.179 137.458 211.154 137.574 211.102C137.691 211.05 137.793 210.973 137.872 210.875C137.952 210.778 138.006 210.664 138.031 210.543C138.055 210.422 138.05 210.296 138.015 210.177Z"
          fill="#677A89"
        />
        <path
          d="M139.124 210.178C139.227 210.461 139.416 210.707 139.667 210.885C139.917 211.062 140.217 211.164 140.528 211.175C140.656 211.186 140.784 211.164 140.9 211.112C141.017 211.06 141.117 210.98 141.192 210.879C141.266 210.779 141.313 210.661 141.326 210.538C141.339 210.415 141.318 210.291 141.267 210.178C141.19 209.888 141.02 209.629 140.781 209.437C140.542 209.245 140.247 209.131 139.937 209.11C139.8 209.101 139.663 209.125 139.538 209.181C139.414 209.237 139.306 209.322 139.225 209.428C139.144 209.535 139.092 209.66 139.074 209.791C139.056 209.922 139.074 210.055 139.124 210.178Z"
          fill="#677A89"
        />
        <path
          d="M78.68 210.177C78.589 209.882 78.4052 209.62 78.1538 209.429C77.9024 209.238 77.5959 209.126 77.2761 209.109C77.1454 209.112 77.0173 209.145 76.9027 209.206C76.7882 209.267 76.6904 209.353 76.6179 209.458C76.5454 209.563 76.5002 209.683 76.4862 209.808C76.4721 209.933 76.4896 210.06 76.5371 210.177C76.617 210.46 76.7899 210.71 77.0297 210.889C77.2696 211.069 77.5634 211.169 77.8672 211.174C77.9954 211.179 78.123 211.154 78.2395 211.102C78.356 211.05 78.458 210.973 78.5373 210.875C78.6166 210.778 78.6709 210.664 78.6957 210.543C78.7205 210.422 78.7151 210.296 78.68 210.177Z"
          fill="#677A89"
        />
        <path
          d="M81.9317 210.178C81.8547 209.888 81.6848 209.629 81.446 209.437C81.2072 209.245 80.9117 209.131 80.6017 209.11C80.4648 209.101 80.3277 209.125 80.2033 209.181C80.0788 209.237 79.9709 209.322 79.8898 209.428C79.8087 209.535 79.7568 209.66 79.7392 209.791C79.7215 209.922 79.7386 210.055 79.7888 210.178C79.8923 210.461 80.0814 210.707 80.3317 210.885C80.5821 211.062 80.8821 211.164 81.1928 211.175C81.3206 211.186 81.449 211.164 81.5655 211.112C81.6819 211.06 81.7824 210.98 81.8569 210.879C81.9315 210.779 81.9776 210.661 81.9907 210.538C82.0038 210.415 81.9835 210.291 81.9317 210.178Z"
          fill="#677A89"
        />
        <path
          d="M85.3299 210.177C85.2389 209.882 85.0551 209.62 84.8037 209.429C84.5524 209.238 84.2458 209.126 83.926 209.109C83.3348 209.109 83.0393 209.608 83.1871 210.177C83.2811 210.465 83.4678 210.717 83.7202 210.897C83.9726 211.076 84.2776 211.173 84.591 211.174C84.7188 211.185 84.8473 211.163 84.9637 211.111C85.0802 211.059 85.1806 210.979 85.2552 210.879C85.3297 210.778 85.3758 210.66 85.3889 210.537C85.402 210.415 85.3817 210.29 85.3299 210.177Z"
          fill="#677A89"
        />
        <path
          d="M88.5804 210.177C88.4726 209.891 88.2841 209.64 88.0361 209.451C87.7881 209.262 87.4904 209.144 87.1765 209.109C87.0458 209.112 86.9177 209.145 86.8031 209.206C86.6885 209.267 86.5909 209.353 86.5184 209.458C86.4458 209.563 86.4006 209.683 86.3866 209.808C86.3725 209.933 86.39 210.06 86.4376 210.177C86.5175 210.46 86.6903 210.71 86.9301 210.889C87.1699 211.069 87.4638 211.169 87.7676 211.174C87.8959 211.179 88.0235 211.154 88.1399 211.102C88.2564 211.05 88.3584 210.973 88.4377 210.875C88.517 210.778 88.5713 210.664 88.5961 210.543C88.6209 210.422 88.6155 210.296 88.5804 210.177Z"
          fill="#677A89"
        />
        <path
          d="M91.98 214.379C92.1825 214.361 92.3707 214.271 92.5075 214.126C92.6443 213.981 92.7197 213.792 92.7189 213.596C92.6155 213.313 92.4263 213.067 92.176 212.89C91.9257 212.712 91.6257 212.611 91.3149 212.599C91.1871 212.589 91.0587 212.61 90.9422 212.662C90.8258 212.714 90.7253 212.794 90.6508 212.895C90.5762 212.995 90.5302 213.113 90.5171 213.236C90.5039 213.359 90.5243 213.483 90.576 213.596C90.7053 213.844 90.9071 214.051 91.1565 214.19C91.406 214.329 91.6922 214.395 91.98 214.379Z"
          fill="#677A89"
        />
        <path
          d="M95.3055 214.379C95.4362 214.376 95.5643 214.343 95.6789 214.282C95.7934 214.221 95.8911 214.135 95.9636 214.03C96.0361 213.925 96.0814 213.805 96.0954 213.68C96.1095 213.555 96.092 213.428 96.0444 213.311C95.9645 213.028 95.7917 212.779 95.5519 212.599C95.312 212.419 95.0181 212.319 94.7144 212.314C94.5861 212.309 94.4586 212.334 94.3421 212.386C94.2256 212.438 94.1236 212.516 94.0443 212.613C93.965 212.71 93.9107 212.824 93.8859 212.945C93.8611 213.067 93.8665 213.192 93.9016 213.311C94.0094 213.597 94.198 213.849 94.446 214.037C94.694 214.226 94.9916 214.344 95.3055 214.379Z"
          fill="#677A89"
        />
        <path
          d="M98.6309 214.379C99.222 214.379 99.5176 213.88 99.3698 213.311C99.2758 213.023 99.0891 212.771 98.8367 212.592C98.5843 212.412 98.2793 212.315 97.9659 212.314C97.8381 212.304 97.7096 212.325 97.5932 212.377C97.4767 212.429 97.3763 212.509 97.3017 212.61C97.2272 212.71 97.181 212.828 97.1679 212.951C97.1548 213.074 97.1752 213.198 97.227 213.311C97.3077 213.612 97.4886 213.88 97.7422 214.073C97.9957 214.266 98.3079 214.373 98.6309 214.379Z"
          fill="#677A89"
        />
        <path
          d="M101.882 214.379C102.019 214.388 102.156 214.364 102.28 214.308C102.405 214.252 102.512 214.167 102.594 214.061C102.675 213.954 102.726 213.829 102.744 213.698C102.762 213.567 102.745 213.434 102.694 213.311C102.591 213.028 102.402 212.782 102.152 212.604C101.901 212.427 101.601 212.326 101.291 212.314C101.163 212.304 101.034 212.325 100.918 212.377C100.801 212.429 100.701 212.509 100.626 212.61C100.552 212.71 100.506 212.828 100.493 212.951C100.48 213.074 100.5 213.198 100.552 213.311C100.629 213.601 100.799 213.86 101.037 214.052C101.276 214.244 101.572 214.358 101.882 214.379Z"
          fill="#677A89"
        />
        <path
          d="M105.207 214.379C105.799 214.379 106.094 213.88 105.946 213.311C105.866 213.028 105.694 212.779 105.454 212.599C105.214 212.419 104.92 212.319 104.616 212.314C104.488 212.309 104.36 212.334 104.244 212.386C104.127 212.438 104.025 212.516 103.946 212.613C103.867 212.71 103.813 212.824 103.788 212.945C103.763 213.067 103.768 213.192 103.803 213.311C103.894 213.607 104.078 213.868 104.33 214.059C104.581 214.251 104.888 214.362 105.207 214.379Z"
          fill="#677A89"
        />
        <path
          d="M108.459 214.379C108.596 214.388 108.733 214.364 108.857 214.308C108.982 214.253 109.09 214.168 109.171 214.061C109.252 213.954 109.304 213.83 109.321 213.699C109.339 213.568 109.322 213.434 109.272 213.311C109.178 213.023 108.991 212.771 108.739 212.592C108.486 212.413 108.181 212.316 107.868 212.314C107.719 212.328 107.578 212.385 107.463 212.477C107.348 212.57 107.265 212.694 107.225 212.833C107.185 212.972 107.19 213.119 107.239 213.255C107.287 213.392 107.378 213.51 107.498 213.596C107.589 213.787 107.722 213.956 107.888 214.091C108.054 214.226 108.249 214.325 108.459 214.379Z"
          fill="#677A89"
        />
        <path
          d="M111.783 214.379C111.914 214.376 112.042 214.343 112.156 214.282C112.271 214.221 112.369 214.135 112.441 214.03C112.514 213.925 112.559 213.805 112.573 213.68C112.587 213.555 112.57 213.428 112.522 213.311C112.442 213.028 112.269 212.779 112.029 212.599C111.79 212.419 111.496 212.319 111.192 212.314C111.064 212.309 110.936 212.334 110.82 212.386C110.703 212.438 110.601 212.516 110.522 212.613C110.443 212.71 110.388 212.824 110.363 212.945C110.339 213.067 110.344 213.192 110.379 213.311C110.487 213.597 110.675 213.849 110.923 214.037C111.171 214.226 111.469 214.344 111.783 214.379Z"
          fill="#677A89"
        />
        <path
          d="M114.887 214.379C115.478 214.379 115.774 213.88 115.626 213.311C115.532 213.023 115.345 212.771 115.093 212.592C114.841 212.412 114.536 212.315 114.222 212.314C114.094 212.304 113.966 212.325 113.849 212.377C113.733 212.429 113.633 212.509 113.558 212.61C113.484 212.71 113.437 212.828 113.424 212.951C113.411 213.074 113.431 213.198 113.483 213.311C113.574 213.607 113.758 213.868 114.009 214.059C114.261 214.251 114.567 214.362 114.887 214.379Z"
          fill="#677A89"
        />
        <path
          d="M118.36 214.379C118.497 214.388 118.634 214.364 118.758 214.308C118.883 214.252 118.99 214.167 119.072 214.061C119.153 213.954 119.205 213.829 119.222 213.698C119.24 213.567 119.223 213.434 119.173 213.311C119.069 213.028 118.88 212.782 118.63 212.604C118.379 212.427 118.079 212.326 117.769 212.314C117.641 212.304 117.512 212.325 117.396 212.377C117.279 212.429 117.179 212.509 117.104 212.61C117.03 212.71 116.984 212.828 116.971 212.951C116.958 213.074 116.978 213.198 117.03 213.311C117.107 213.601 117.277 213.86 117.515 214.052C117.754 214.244 118.05 214.358 118.36 214.379Z"
          fill="#677A89"
        />
        <path
          d="M121.685 214.379C121.86 214.331 122.014 214.228 122.121 214.086C122.228 213.944 122.283 213.771 122.276 213.596C122.196 213.314 122.023 213.064 121.783 212.884C121.543 212.704 121.25 212.604 120.946 212.599C120.818 212.594 120.69 212.619 120.574 212.671C120.457 212.723 120.355 212.801 120.276 212.898C120.196 212.995 120.142 213.109 120.117 213.23C120.093 213.352 120.098 213.477 120.133 213.596C120.28 213.862 120.506 214.079 120.783 214.219C121.06 214.358 121.374 214.414 121.685 214.379Z"
          fill="#677A89"
        />
        <path
          d="M124.935 214.379C125.072 214.388 125.209 214.364 125.334 214.308C125.458 214.252 125.566 214.167 125.647 214.061C125.728 213.954 125.78 213.829 125.798 213.698C125.816 213.567 125.798 213.434 125.748 213.311C125.654 213.023 125.467 212.771 125.215 212.592C124.963 212.412 124.658 212.315 124.344 212.314C124.216 212.304 124.088 212.325 123.972 212.377C123.855 212.429 123.755 212.509 123.68 212.61C123.606 212.71 123.559 212.828 123.546 212.951C123.533 213.074 123.554 213.198 123.605 213.311C123.682 213.601 123.852 213.86 124.091 214.052C124.33 214.244 124.625 214.358 124.935 214.379Z"
          fill="#677A89"
        />
        <path
          d="M128.261 214.379C128.392 214.376 128.52 214.343 128.634 214.282C128.749 214.221 128.847 214.135 128.919 214.03C128.992 213.925 129.037 213.805 129.051 213.68C129.065 213.555 129.048 213.428 129 213.311C128.92 213.028 128.747 212.779 128.507 212.599C128.268 212.419 127.974 212.319 127.67 212.314C127.542 212.309 127.414 212.334 127.298 212.386C127.181 212.438 127.079 212.516 127 212.613C126.921 212.71 126.866 212.824 126.841 212.945C126.817 213.067 126.822 213.192 126.857 213.311C126.965 213.597 127.154 213.849 127.402 214.037C127.65 214.226 127.947 214.344 128.261 214.379Z"
          fill="#677A89"
        />
        <path
          d="M131.586 214.379C132.178 214.379 132.473 213.88 132.325 213.311C132.231 213.023 132.045 212.771 131.792 212.592C131.54 212.412 131.235 212.315 130.921 212.314C130.794 212.304 130.665 212.325 130.549 212.377C130.432 212.429 130.332 212.509 130.257 212.61C130.183 212.71 130.137 212.828 130.124 212.951C130.11 213.074 130.131 213.198 130.182 213.311C130.274 213.607 130.457 213.868 130.709 214.059C130.96 214.251 131.267 214.362 131.586 214.379Z"
          fill="#677A89"
        />
        <path
          d="M134.837 214.379C134.974 214.388 135.111 214.364 135.236 214.308C135.36 214.252 135.468 214.167 135.549 214.061C135.63 213.954 135.682 213.829 135.7 213.698C135.717 213.567 135.7 213.434 135.65 213.311C135.547 213.028 135.357 212.782 135.107 212.604C134.857 212.427 134.557 212.326 134.246 212.314C134.118 212.304 133.99 212.325 133.873 212.377C133.757 212.429 133.657 212.509 133.582 212.61C133.507 212.71 133.461 212.828 133.448 212.951C133.435 213.074 133.455 213.198 133.507 213.311C133.584 213.601 133.754 213.86 133.993 214.052C134.232 214.244 134.527 214.358 134.837 214.379Z"
          fill="#677A89"
        />
        <path
          d="M138.163 214.379C138.294 214.376 138.422 214.343 138.536 214.283C138.651 214.222 138.749 214.135 138.821 214.031C138.894 213.926 138.939 213.806 138.953 213.68C138.967 213.555 138.95 213.429 138.902 213.311C138.822 213.029 138.649 212.779 138.409 212.599C138.17 212.419 137.876 212.32 137.572 212.314C137.427 212.332 137.29 212.388 137.175 212.475C137.06 212.561 136.971 212.676 136.919 212.807C136.866 212.938 136.85 213.081 136.874 213.22C136.898 213.358 136.961 213.488 137.055 213.596C137.173 213.794 137.332 213.965 137.523 214.1C137.714 214.235 137.932 214.33 138.163 214.379Z"
          fill="#677A89"
        />
        <path
          d="M140.083 213.596C140.16 213.886 140.33 214.146 140.569 214.337C140.808 214.529 141.103 214.643 141.413 214.664C141.55 214.673 141.687 214.649 141.812 214.593C141.936 214.538 142.044 214.452 142.125 214.346C142.206 214.239 142.258 214.115 142.276 213.983C142.294 213.852 142.276 213.719 142.226 213.596C142.132 213.308 141.946 213.056 141.693 212.877C141.441 212.698 141.136 212.6 140.822 212.599C140.695 212.589 140.566 212.61 140.45 212.662C140.333 212.714 140.233 212.794 140.158 212.895C140.084 212.995 140.037 213.113 140.024 213.236C140.011 213.359 140.032 213.483 140.083 213.596Z"
          fill="#677A89"
        />
        <path
          d="M79.5664 213.596C79.4865 213.314 79.3137 213.064 79.0738 212.884C78.834 212.704 78.5402 212.604 78.2364 212.599C78.1081 212.594 77.9806 212.619 77.8641 212.671C77.7476 212.723 77.6455 212.801 77.5663 212.898C77.487 212.995 77.4327 213.109 77.4079 213.23C77.3831 213.352 77.3885 213.477 77.4236 213.596C77.5146 213.892 77.6984 214.153 77.9498 214.344C78.2011 214.536 78.5077 214.647 78.8275 214.664C78.9582 214.661 79.0863 214.628 79.2008 214.567C79.3154 214.507 79.4131 214.42 79.4856 214.315C79.5582 214.211 79.6034 214.09 79.6175 213.965C79.6315 213.84 79.614 213.713 79.5664 213.596Z"
          fill="#677A89"
        />
        <path
          d="M82.0794 214.379C82.2163 214.388 82.3534 214.364 82.4779 214.308C82.6023 214.252 82.7102 214.167 82.7913 214.061C82.8725 213.954 82.9242 213.829 82.9419 213.698C82.9595 213.567 82.9425 213.434 82.8922 213.311C82.7982 213.023 82.6115 212.771 82.3592 212.592C82.1068 212.412 81.8018 212.315 81.4883 212.314C81.3605 212.304 81.232 212.325 81.1156 212.377C80.9991 212.429 80.8987 212.509 80.8242 212.61C80.7496 212.71 80.7035 212.828 80.6904 212.951C80.6773 213.074 80.6976 213.198 80.7494 213.311C80.8263 213.601 80.9963 213.86 81.2351 214.052C81.4739 214.244 81.7693 214.358 82.0794 214.379Z"
          fill="#677A89"
        />
        <path
          d="M85.3304 214.379C85.4673 214.388 85.6044 214.364 85.7288 214.308C85.8533 214.252 85.9611 214.167 86.0423 214.061C86.1234 213.954 86.1753 213.829 86.1929 213.698C86.2106 213.567 86.1935 213.434 86.1432 213.311C86.0398 213.028 85.8507 212.782 85.6003 212.604C85.35 212.427 85.05 212.326 84.7393 212.314C84.611 212.309 84.4834 212.334 84.367 212.386C84.2505 212.438 84.1485 212.516 84.0692 212.613C83.9899 212.71 83.9357 212.824 83.9108 212.945C83.886 213.067 83.8914 213.192 83.9265 213.311C84.0343 213.597 84.2228 213.849 84.4708 214.037C84.7188 214.226 85.0165 214.344 85.3304 214.379Z"
          fill="#677A89"
        />
        <path
          d="M88.7293 214.379C89.3205 214.379 89.616 213.88 89.4683 213.311C89.3883 213.028 89.2155 212.779 88.9757 212.599C88.7359 212.419 88.442 212.319 88.1382 212.314C88.01 212.309 87.8824 212.334 87.766 212.386C87.6495 212.438 87.5474 212.516 87.4681 212.613C87.3888 212.71 87.3346 212.824 87.3098 212.945C87.285 213.067 87.2903 213.192 87.3254 213.311C87.4164 213.607 87.6002 213.868 87.8516 214.059C88.103 214.251 88.4096 214.362 88.7293 214.379Z"
          fill="#677A89"
        />
        <path
          d="M93.6792 216.515C93.6022 216.225 93.4323 215.966 93.1935 215.774C92.9547 215.582 92.6593 215.468 92.3492 215.447C92.2123 215.438 92.0752 215.462 91.9508 215.518C91.8263 215.574 91.7185 215.659 91.6373 215.765C91.5562 215.872 91.5044 215.997 91.4867 216.128C91.4691 216.259 91.4861 216.392 91.5364 216.515C91.6304 216.803 91.8171 217.055 92.0695 217.234C92.3219 217.414 92.6268 217.511 92.9403 217.512C93.0681 217.522 93.1966 217.501 93.313 217.449C93.4295 217.397 93.5299 217.317 93.6045 217.216C93.679 217.116 93.7251 216.998 93.7382 216.875C93.7513 216.752 93.731 216.628 93.6792 216.515Z"
          fill="#677A89"
        />
        <path
          d="M97.0052 216.515C96.9142 216.219 96.7304 215.958 96.479 215.767C96.2277 215.576 95.9211 215.464 95.6013 215.447C95.0101 215.447 94.7146 215.946 94.8624 216.515C94.9423 216.798 95.1151 217.048 95.3549 217.227C95.5947 217.407 95.8886 217.507 96.1924 217.512C96.3207 217.517 96.4482 217.492 96.5647 217.44C96.6812 217.388 96.7832 217.311 96.8625 217.213C96.9418 217.116 96.996 217.002 97.0209 216.881C97.0457 216.759 97.0403 216.634 97.0052 216.515Z"
          fill="#677A89"
        />
        <path
          d="M100.108 216.515C100.031 216.225 99.8615 215.966 99.6227 215.774C99.3839 215.582 99.0885 215.468 98.7784 215.447C98.6415 215.438 98.5045 215.462 98.38 215.518C98.2555 215.574 98.1477 215.659 98.0666 215.765C97.9854 215.872 97.9336 215.997 97.9159 216.128C97.8983 216.259 97.9154 216.392 97.9656 216.515C98.069 216.798 98.2582 217.044 98.5085 217.222C98.7588 217.399 99.0588 217.5 99.3696 217.512C99.4974 217.522 99.6258 217.501 99.7422 217.449C99.8587 217.397 99.9592 217.317 100.034 217.216C100.108 217.116 100.154 216.998 100.167 216.875C100.181 216.752 100.16 216.628 100.108 216.515Z"
          fill="#677A89"
        />
        <path
          d="M103.581 216.515C103.49 216.219 103.306 215.958 103.055 215.767C102.803 215.576 102.497 215.464 102.177 215.447C101.586 215.447 101.29 215.946 101.438 216.515C101.532 216.803 101.719 217.055 101.971 217.234C102.224 217.414 102.529 217.511 102.842 217.512C102.97 217.523 103.098 217.501 103.215 217.449C103.331 217.397 103.432 217.317 103.506 217.216C103.581 217.116 103.627 216.998 103.64 216.875C103.653 216.752 103.633 216.628 103.581 216.515Z"
          fill="#677A89"
        />
        <path
          d="M106.907 216.515C106.799 216.229 106.611 215.978 106.363 215.789C106.115 215.6 105.817 215.482 105.503 215.447C105.372 215.45 105.244 215.483 105.13 215.544C105.015 215.605 104.917 215.691 104.845 215.796C104.772 215.901 104.727 216.021 104.713 216.146C104.699 216.271 104.717 216.398 104.764 216.515C104.844 216.798 105.017 217.048 105.257 217.227C105.497 217.407 105.79 217.507 106.094 217.512C106.222 217.517 106.35 217.492 106.467 217.44C106.583 217.388 106.685 217.311 106.764 217.213C106.844 217.116 106.898 217.002 106.923 216.881C106.948 216.759 106.942 216.634 106.907 216.515Z"
          fill="#677A89"
        />
        <path
          d="M110.157 216.515C110.08 216.225 109.91 215.966 109.672 215.774C109.433 215.582 109.137 215.468 108.827 215.447C108.69 215.438 108.553 215.462 108.429 215.518C108.304 215.574 108.197 215.659 108.115 215.765C108.034 215.872 107.982 215.997 107.965 216.128C107.947 216.259 107.964 216.392 108.014 216.515C108.108 216.803 108.295 217.055 108.548 217.234C108.8 217.414 109.105 217.511 109.418 217.512C109.546 217.522 109.675 217.501 109.791 217.449C109.908 217.397 110.008 217.317 110.083 217.216C110.157 217.116 110.203 216.998 110.216 216.875C110.229 216.752 110.209 216.628 110.157 216.515Z"
          fill="#677A89"
        />
        <path
          d="M113.483 216.515C113.392 216.219 113.208 215.958 112.957 215.767C112.705 215.576 112.399 215.464 112.079 215.447C111.488 215.447 111.192 215.946 111.34 216.515C111.42 216.798 111.593 217.048 111.833 217.227C112.072 217.407 112.366 217.507 112.67 217.512C112.798 217.517 112.926 217.492 113.042 217.44C113.159 217.388 113.261 217.311 113.34 217.213C113.419 217.116 113.474 217.002 113.498 216.881C113.523 216.759 113.518 216.634 113.483 216.515Z"
          fill="#677A89"
        />
        <path
          d="M116.808 216.515C116.701 216.229 116.512 215.977 116.264 215.789C116.016 215.6 115.718 215.482 115.404 215.447C115.268 215.438 115.13 215.462 115.006 215.518C114.882 215.574 114.774 215.659 114.693 215.765C114.611 215.872 114.56 215.997 114.542 216.128C114.524 216.259 114.541 216.392 114.592 216.515C114.695 216.798 114.884 217.044 115.134 217.222C115.385 217.399 115.685 217.5 115.996 217.512C116.124 217.517 116.251 217.492 116.368 217.44C116.484 217.388 116.586 217.31 116.666 217.213C116.745 217.116 116.799 217.002 116.824 216.881C116.849 216.759 116.843 216.634 116.808 216.515Z"
          fill="#677A89"
        />
        <path
          d="M120.059 216.515C119.982 216.225 119.812 215.966 119.573 215.774C119.335 215.582 119.039 215.468 118.729 215.447C118.592 215.438 118.455 215.462 118.331 215.518C118.206 215.574 118.098 215.659 118.017 215.765C117.936 215.872 117.884 215.997 117.867 216.128C117.849 216.259 117.866 216.392 117.916 216.515C118.01 216.803 118.197 217.055 118.449 217.234C118.702 217.414 119.007 217.511 119.32 217.512C119.448 217.522 119.576 217.501 119.693 217.449C119.809 217.397 119.91 217.317 119.984 217.216C120.059 217.116 120.105 216.998 120.118 216.875C120.131 216.752 120.111 216.628 120.059 216.515Z"
          fill="#677A89"
        />
        <path
          d="M123.385 216.515C123.294 216.219 123.11 215.958 122.858 215.767C122.607 215.576 122.3 215.464 121.981 215.447C121.85 215.45 121.722 215.483 121.607 215.544C121.493 215.605 121.395 215.691 121.323 215.796C121.25 215.901 121.205 216.021 121.191 216.146C121.177 216.271 121.194 216.398 121.242 216.515C121.322 216.798 121.494 217.048 121.734 217.227C121.974 217.407 122.268 217.507 122.572 217.512C122.7 217.517 122.828 217.492 122.944 217.44C123.061 217.388 123.163 217.311 123.242 217.213C123.321 217.116 123.375 217.002 123.4 216.881C123.425 216.759 123.42 216.634 123.385 216.515Z"
          fill="#677A89"
        />
        <path
          d="M126.635 216.515C126.558 216.225 126.388 215.966 126.149 215.774C125.91 215.582 125.615 215.468 125.305 215.447C125.168 215.438 125.031 215.462 124.906 215.518C124.782 215.574 124.674 215.659 124.593 215.765C124.512 215.872 124.46 215.997 124.442 216.128C124.425 216.259 124.442 216.392 124.492 216.515C124.595 216.798 124.785 217.044 125.035 217.222C125.285 217.399 125.585 217.5 125.896 217.512C126.024 217.522 126.152 217.501 126.269 217.449C126.385 217.397 126.485 217.317 126.56 217.216C126.635 217.116 126.681 216.998 126.694 216.875C126.707 216.752 126.687 216.628 126.635 216.515Z"
          fill="#677A89"
        />
        <path
          d="M129.665 216.515C129.574 216.219 129.39 215.958 129.139 215.767C128.887 215.576 128.581 215.464 128.261 215.447C127.67 215.447 127.374 215.946 127.522 216.515C127.616 216.803 127.803 217.055 128.055 217.234C128.308 217.414 128.613 217.511 128.926 217.512C129.054 217.523 129.182 217.501 129.299 217.449C129.415 217.397 129.516 217.317 129.59 217.216C129.665 217.116 129.711 216.998 129.724 216.875C129.737 216.752 129.717 216.628 129.665 216.515Z"
          fill="#677A89"
        />
        <path
          d="M133.285 216.515C133.178 216.229 132.989 215.978 132.741 215.789C132.493 215.6 132.195 215.482 131.882 215.447C131.751 215.45 131.623 215.483 131.508 215.544C131.394 215.605 131.296 215.691 131.223 215.796C131.151 215.901 131.106 216.021 131.092 216.146C131.078 216.271 131.095 216.398 131.143 216.515C131.223 216.798 131.395 217.048 131.635 217.227C131.875 217.407 132.169 217.507 132.473 217.512C132.601 217.517 132.728 217.492 132.845 217.44C132.961 217.388 133.064 217.311 133.143 217.213C133.222 217.116 133.276 217.002 133.301 216.881C133.326 216.759 133.321 216.634 133.285 216.515Z"
          fill="#677A89"
        />
        <path
          d="M136.537 216.515C136.46 216.225 136.29 215.966 136.051 215.774C135.812 215.582 135.517 215.468 135.207 215.447C135.07 215.438 134.933 215.462 134.808 215.518C134.684 215.574 134.576 215.659 134.495 215.765C134.414 215.872 134.362 215.997 134.344 216.128C134.327 216.259 134.344 216.392 134.394 216.515C134.488 216.803 134.675 217.055 134.927 217.234C135.179 217.414 135.484 217.511 135.798 217.512C135.926 217.522 136.054 217.501 136.17 217.449C136.287 217.397 136.387 217.317 136.462 217.216C136.536 217.116 136.583 216.998 136.596 216.875C136.609 216.752 136.588 216.628 136.537 216.515Z"
          fill="#677A89"
        />
        <path
          d="M139.862 216.515C139.771 216.219 139.587 215.958 139.336 215.767C139.085 215.576 138.778 215.464 138.458 215.447C138.328 215.45 138.199 215.483 138.085 215.544C137.97 215.605 137.873 215.691 137.8 215.796C137.728 215.901 137.682 216.021 137.668 216.146C137.654 216.271 137.672 216.398 137.719 216.515C137.799 216.798 137.972 217.048 138.212 217.227C138.452 217.407 138.746 217.507 139.049 217.512C139.178 217.517 139.305 217.492 139.422 217.44C139.538 217.388 139.64 217.311 139.719 217.213C139.799 217.116 139.853 217.002 139.878 216.881C139.903 216.759 139.897 216.634 139.862 216.515Z"
          fill="#677A89"
        />
        <path
          d="M143.114 216.515C143.037 216.225 142.867 215.966 142.628 215.774C142.389 215.582 142.094 215.468 141.784 215.447C141.647 215.438 141.51 215.462 141.385 215.518C141.261 215.574 141.153 215.659 141.072 215.765C140.991 215.872 140.939 215.997 140.921 216.128C140.904 216.259 140.921 216.392 140.971 216.515C141.074 216.798 141.264 217.044 141.514 217.222C141.764 217.399 142.064 217.5 142.375 217.512C142.503 217.522 142.631 217.501 142.748 217.449C142.864 217.397 142.965 217.317 143.039 217.216C143.114 217.116 143.16 216.998 143.173 216.875C143.186 216.752 143.166 216.628 143.114 216.515Z"
          fill="#677A89"
        />
        <path
          d="M80.5272 216.515C80.4361 216.219 80.2523 215.958 80.001 215.767C79.7496 215.576 79.443 215.464 79.1233 215.447C78.9926 215.45 78.8645 215.483 78.7499 215.544C78.6353 215.605 78.5376 215.691 78.4651 215.796C78.3926 215.901 78.3474 216.021 78.3333 216.146C78.3193 216.271 78.3367 216.398 78.3843 216.515C78.4642 216.798 78.6371 217.048 78.8769 217.227C79.1167 217.407 79.4106 217.507 79.7143 217.512C79.8426 217.517 79.9702 217.492 80.0866 217.44C80.2031 217.388 80.3052 217.311 80.3845 217.213C80.4638 217.116 80.518 217.002 80.5428 216.881C80.5676 216.759 80.5623 216.634 80.5272 216.515Z"
          fill="#677A89"
        />
        <path
          d="M83.7789 216.515C83.7019 216.225 83.5319 215.966 83.2931 215.774C83.0544 215.582 82.7589 215.468 82.4488 215.447C82.3119 215.438 82.1749 215.462 82.0504 215.518C81.9259 215.574 81.8181 215.659 81.737 215.765C81.6558 215.872 81.604 215.997 81.5863 216.128C81.5687 216.259 81.5858 216.392 81.636 216.515C81.7394 216.798 81.9286 217.044 82.1789 217.222C82.4292 217.399 82.7292 217.5 83.04 217.512C83.1678 217.522 83.2962 217.501 83.4127 217.449C83.5291 217.397 83.6296 217.317 83.7041 217.216C83.7787 217.116 83.8247 216.998 83.8378 216.875C83.8509 216.752 83.8306 216.628 83.7789 216.515Z"
          fill="#677A89"
        />
        <path
          d="M87.1029 216.515C87.0118 216.219 86.828 215.958 86.5767 215.767C86.3253 215.576 86.0188 215.464 85.699 215.447C85.1078 215.447 84.8122 215.946 84.96 216.515C85.0541 216.803 85.2408 217.055 85.4932 217.234C85.7455 217.414 86.0505 217.511 86.364 217.512C86.4918 217.523 86.6202 217.501 86.7367 217.449C86.8531 217.397 86.9536 217.317 87.0281 217.216C87.1027 217.116 87.1488 216.998 87.1619 216.875C87.175 216.752 87.1547 216.628 87.1029 216.515Z"
          fill="#677A89"
        />
        <path
          d="M90.4281 216.515C90.3202 216.229 90.1317 215.978 89.8837 215.789C89.6357 215.6 89.338 215.482 89.0241 215.447C88.8934 215.45 88.7653 215.483 88.6508 215.544C88.5362 215.605 88.4385 215.691 88.366 215.796C88.2935 215.901 88.2483 216.021 88.2342 216.146C88.2201 216.271 88.2377 216.398 88.2852 216.515C88.3651 216.798 88.5379 217.048 88.7778 217.227C89.0176 217.407 89.3115 217.507 89.6153 217.512C89.7435 217.517 89.8711 217.492 89.9876 217.44C90.1041 217.388 90.2061 217.311 90.2854 217.213C90.3646 217.116 90.4189 217.002 90.4437 216.881C90.4685 216.759 90.4632 216.634 90.4281 216.515Z"
          fill="#677A89"
        />
        <path
          d="M93.2365 218.65C93.1058 218.653 92.9777 218.686 92.8632 218.747C92.7486 218.808 92.6509 218.894 92.5784 218.999C92.5059 219.104 92.4607 219.224 92.4466 219.349C92.4325 219.474 92.4501 219.601 92.4976 219.718C92.5775 220.001 92.7504 220.251 92.9902 220.43C93.23 220.61 93.5239 220.71 93.8277 220.715C93.9559 220.72 94.0835 220.695 94.2 220.643C94.3165 220.591 94.4185 220.514 94.4978 220.416C94.577 220.319 94.6313 220.205 94.6561 220.084C94.6809 219.963 94.6756 219.837 94.6405 219.718C94.5327 219.432 94.3441 219.181 94.0961 218.992C93.8481 218.803 93.5505 218.685 93.2365 218.65Z"
          fill="#677A89"
        />
        <path
          d="M96.5616 218.65C96.4247 218.641 96.2876 218.666 96.1632 218.721C96.0387 218.777 95.9309 218.862 95.8497 218.968C95.7686 219.075 95.7168 219.2 95.6991 219.331C95.6815 219.462 95.6986 219.595 95.7488 219.718C95.8522 220.001 96.0414 220.247 96.2917 220.425C96.542 220.602 96.842 220.704 97.1527 220.715C97.2805 220.726 97.409 220.704 97.5254 220.652C97.6418 220.6 97.7423 220.52 97.8169 220.419C97.8914 220.319 97.9375 220.201 97.9506 220.078C97.9637 219.955 97.9434 219.831 97.8916 219.718C97.8146 219.428 97.6447 219.169 97.4059 218.977C97.1671 218.785 96.8717 218.671 96.5616 218.65Z"
          fill="#677A89"
        />
        <path
          d="M100.108 218.65C99.5165 218.65 99.2209 219.149 99.3687 219.718C99.4018 219.929 99.4859 220.129 99.6143 220.302C99.7427 220.475 99.9118 220.617 100.108 220.715C100.241 220.74 100.379 220.729 100.507 220.684C100.634 220.638 100.746 220.559 100.83 220.456C100.914 220.353 100.967 220.23 100.983 220.1C100.999 219.969 100.977 219.837 100.92 219.718C100.879 219.492 100.784 219.278 100.643 219.093C100.503 218.908 100.319 218.756 100.108 218.65Z"
          fill="#677A89"
        />
        <path
          d="M103.137 218.65C103 218.641 102.863 218.666 102.739 218.721C102.614 218.777 102.507 218.862 102.425 218.968C102.344 219.075 102.292 219.2 102.275 219.331C102.257 219.462 102.274 219.595 102.324 219.718C102.428 220.001 102.617 220.247 102.867 220.425C103.118 220.602 103.418 220.704 103.728 220.715C103.857 220.72 103.984 220.695 104.101 220.643C104.217 220.591 104.319 220.514 104.399 220.416C104.478 220.319 104.532 220.205 104.557 220.084C104.582 219.962 104.576 219.837 104.541 219.718C104.433 219.432 104.245 219.181 103.997 218.992C103.749 218.803 103.451 218.685 103.137 218.65Z"
          fill="#677A89"
        />
        <path
          d="M106.463 218.65C106.327 218.641 106.19 218.666 106.065 218.721C105.941 218.777 105.833 218.862 105.752 218.968C105.67 219.075 105.619 219.2 105.601 219.331C105.583 219.462 105.6 219.595 105.651 219.718C105.745 220.006 105.931 220.258 106.184 220.437C106.436 220.617 106.741 220.714 107.055 220.715C107.182 220.726 107.311 220.704 107.427 220.652C107.544 220.6 107.644 220.52 107.719 220.419C107.793 220.319 107.839 220.201 107.853 220.078C107.866 219.955 107.845 219.831 107.794 219.718C107.717 219.428 107.547 219.169 107.308 218.977C107.069 218.785 106.774 218.671 106.463 218.65Z"
          fill="#677A89"
        />
        <path
          d="M109.714 218.65C109.583 218.653 109.455 218.686 109.341 218.747C109.226 218.808 109.128 218.894 109.056 218.999C108.983 219.104 108.938 219.224 108.924 219.349C108.91 219.474 108.928 219.601 108.975 219.718C109.055 220.001 109.228 220.251 109.468 220.43C109.708 220.61 110.001 220.71 110.305 220.715C110.433 220.72 110.561 220.695 110.677 220.643C110.794 220.591 110.896 220.514 110.975 220.416C111.055 220.319 111.109 220.205 111.134 220.084C111.158 219.963 111.153 219.837 111.118 219.718C111.027 219.423 110.843 219.161 110.592 218.97C110.34 218.779 110.034 218.667 109.714 218.65Z"
          fill="#677A89"
        />
        <path
          d="M113.039 218.65C112.902 218.641 112.765 218.666 112.641 218.721C112.516 218.777 112.408 218.862 112.327 218.968C112.246 219.075 112.194 219.2 112.177 219.331C112.159 219.462 112.176 219.595 112.226 219.718C112.33 220.001 112.519 220.247 112.769 220.425C113.02 220.602 113.32 220.704 113.63 220.715C113.758 220.726 113.887 220.704 114.003 220.652C114.119 220.6 114.22 220.52 114.294 220.419C114.369 220.319 114.415 220.201 114.428 220.078C114.441 219.955 114.421 219.831 114.369 219.718C114.292 219.428 114.122 219.169 113.883 218.977C113.645 218.785 113.349 218.671 113.039 218.65Z"
          fill="#677A89"
        />
        <path
          d="M116.291 218.65C115.7 218.65 115.405 219.149 115.552 219.718C115.646 220.007 115.833 220.258 116.085 220.438C116.338 220.617 116.643 220.714 116.956 220.715C117.084 220.726 117.213 220.704 117.329 220.652C117.445 220.6 117.546 220.52 117.62 220.42C117.695 220.319 117.741 220.201 117.754 220.078C117.767 219.956 117.747 219.831 117.695 219.718C117.604 219.423 117.42 219.161 117.169 218.97C116.918 218.779 116.611 218.667 116.291 218.65Z"
          fill="#677A89"
        />
        <path
          d="M119.616 218.65C119.485 218.653 119.357 218.686 119.243 218.747C119.128 218.808 119.03 218.894 118.958 218.999C118.885 219.104 118.84 219.224 118.826 219.349C118.812 219.474 118.829 219.601 118.877 219.718C118.957 220.001 119.13 220.251 119.37 220.43C119.609 220.61 119.903 220.71 120.207 220.715C120.335 220.72 120.463 220.695 120.579 220.643C120.696 220.591 120.798 220.514 120.877 220.416C120.956 220.319 121.011 220.205 121.035 220.084C121.06 219.963 121.055 219.837 121.02 219.718C120.912 219.432 120.724 219.181 120.476 218.992C120.228 218.803 119.93 218.685 119.616 218.65Z"
          fill="#677A89"
        />
        <path
          d="M122.941 218.65C122.805 218.641 122.668 218.666 122.543 218.721C122.419 218.777 122.311 218.862 122.23 218.968C122.148 219.075 122.097 219.2 122.079 219.331C122.061 219.462 122.078 219.595 122.129 219.718C122.223 220.006 122.409 220.258 122.662 220.437C122.914 220.617 123.219 220.714 123.533 220.715C123.66 220.726 123.789 220.704 123.905 220.652C124.022 220.6 124.122 220.52 124.197 220.419C124.271 220.319 124.317 220.201 124.331 220.078C124.344 219.955 124.323 219.831 124.271 219.718C124.195 219.428 124.025 219.169 123.786 218.977C123.547 218.785 123.252 218.671 122.941 218.65Z"
          fill="#677A89"
        />
        <path
          d="M126.192 218.65C125.6 218.65 125.305 219.149 125.453 219.718C125.533 220.001 125.705 220.251 125.945 220.43C126.185 220.61 126.479 220.71 126.783 220.715C126.911 220.72 127.039 220.695 127.155 220.643C127.272 220.591 127.374 220.514 127.453 220.416C127.532 220.319 127.586 220.205 127.611 220.084C127.636 219.963 127.631 219.837 127.596 219.718C127.505 219.423 127.321 219.161 127.069 218.97C126.818 218.779 126.511 218.667 126.192 218.65Z"
          fill="#677A89"
        />
        <path
          d="M129.665 218.65C129.528 218.641 129.391 218.666 129.266 218.721C129.142 218.777 129.034 218.862 128.953 218.968C128.872 219.075 128.82 219.2 128.802 219.331C128.785 219.462 128.802 219.595 128.852 219.718C128.955 220.001 129.144 220.247 129.395 220.425C129.645 220.602 129.945 220.704 130.256 220.715C130.384 220.726 130.512 220.704 130.628 220.652C130.745 220.6 130.845 220.52 130.92 220.419C130.994 220.319 131.041 220.201 131.054 220.078C131.067 219.955 131.046 219.831 130.995 219.718C130.918 219.428 130.748 219.169 130.509 218.977C130.27 218.785 129.975 218.671 129.665 218.65Z"
          fill="#677A89"
        />
        <path
          d="M132.769 218.65C132.178 218.65 131.882 219.149 132.03 219.718C132.124 220.007 132.311 220.258 132.563 220.438C132.815 220.617 133.12 220.714 133.434 220.715C133.562 220.726 133.69 220.704 133.806 220.652C133.923 220.6 134.023 220.52 134.098 220.42C134.173 220.319 134.219 220.201 134.232 220.078C134.245 219.956 134.224 219.831 134.173 219.718C134.082 219.423 133.898 219.161 133.647 218.97C133.395 218.779 133.089 218.667 132.769 218.65Z"
          fill="#677A89"
        />
        <path
          d="M136.093 218.65C135.963 218.653 135.835 218.686 135.72 218.747C135.606 218.808 135.508 218.894 135.435 218.999C135.363 219.104 135.318 219.224 135.304 219.349C135.289 219.474 135.307 219.601 135.355 219.718C135.398 219.892 135.476 220.055 135.585 220.199C135.694 220.343 135.832 220.464 135.989 220.557C136.147 220.65 136.323 220.711 136.505 220.738C136.688 220.766 136.875 220.758 137.054 220.715C137.182 220.72 137.31 220.695 137.426 220.643C137.543 220.591 137.645 220.514 137.724 220.416C137.803 220.319 137.858 220.205 137.883 220.084C137.907 219.963 137.902 219.837 137.867 219.718C137.737 219.375 137.492 219.084 137.171 218.891C136.849 218.697 136.47 218.613 136.093 218.65Z"
          fill="#677A89"
        />
        <path
          d="M139.419 218.65C139.282 218.641 139.145 218.666 139.021 218.721C138.896 218.777 138.788 218.862 138.707 218.968C138.626 219.075 138.574 219.2 138.557 219.331C138.539 219.462 138.556 219.595 138.606 219.718C138.7 220.006 138.887 220.258 139.139 220.437C139.392 220.617 139.697 220.714 140.01 220.715C140.138 220.726 140.266 220.704 140.383 220.652C140.499 220.6 140.6 220.52 140.674 220.419C140.749 220.319 140.795 220.201 140.808 220.078C140.821 219.955 140.801 219.831 140.749 219.718C140.672 219.428 140.502 219.169 140.263 218.977C140.025 218.785 139.729 218.671 139.419 218.65Z"
          fill="#677A89"
        />
        <path
          d="M142.67 218.65C142.078 218.65 141.783 219.149 141.931 219.718C142.011 220.001 142.183 220.251 142.423 220.43C142.663 220.61 142.957 220.71 143.261 220.715C143.389 220.72 143.517 220.695 143.633 220.643C143.749 220.591 143.852 220.514 143.931 220.416C144.01 220.319 144.064 220.205 144.089 220.084C144.114 219.963 144.109 219.837 144.074 219.718C143.982 219.423 143.799 219.161 143.547 218.97C143.296 218.779 142.989 218.667 142.67 218.65Z"
          fill="#677A89"
        />
        <path
          d="M80.0836 218.65C79.9467 218.641 79.8097 218.666 79.6852 218.721C79.5607 218.777 79.4529 218.862 79.3717 218.968C79.2906 219.075 79.2388 219.2 79.2211 219.331C79.2035 219.462 79.2205 219.595 79.2708 219.718C79.3648 220.006 79.5515 220.258 79.8039 220.437C80.0563 220.617 80.3612 220.714 80.6747 220.715C80.8025 220.726 80.931 220.704 81.0474 220.652C81.1639 220.6 81.2643 220.52 81.3389 220.419C81.4134 220.319 81.4595 220.201 81.4726 220.078C81.4857 219.955 81.4654 219.831 81.4136 219.718C81.3367 219.428 81.1667 219.169 80.9279 218.977C80.6891 218.785 80.3937 218.671 80.0836 218.65Z"
          fill="#677A89"
        />
        <path
          d="M83.3357 218.65C82.7446 218.65 82.449 219.149 82.5967 219.718C82.6766 220.001 82.8495 220.251 83.0893 220.43C83.3292 220.61 83.623 220.71 83.9268 220.715C84.055 220.72 84.1826 220.695 84.2991 220.643C84.4156 220.591 84.5176 220.514 84.5969 220.416C84.6762 220.319 84.7305 220.205 84.7553 220.084C84.7801 219.963 84.7747 219.837 84.7396 219.718C84.6486 219.423 84.4648 219.161 84.2134 218.97C83.962 218.779 83.6555 218.667 83.3357 218.65Z"
          fill="#677A89"
        />
        <path
          d="M86.6598 218.65C86.5229 218.641 86.3858 218.666 86.2614 218.721C86.1369 218.777 86.0291 218.862 85.9479 218.968C85.8668 219.075 85.8149 219.2 85.7973 219.331C85.7796 219.462 85.7967 219.595 85.8469 219.718C85.9504 220.001 86.1395 220.247 86.3898 220.425C86.6402 220.602 86.9402 220.704 87.2509 220.715C87.3787 220.726 87.5071 220.704 87.6236 220.652C87.74 220.6 87.8405 220.52 87.9151 220.419C87.9896 220.319 88.0357 220.201 88.0488 220.078C88.0619 219.955 88.0416 219.831 87.9898 219.718C87.9128 219.428 87.7429 219.169 87.5041 218.977C87.2653 218.785 86.9698 218.671 86.6598 218.65Z"
          fill="#677A89"
        />
        <path
          d="M89.9859 218.65C89.849 218.641 89.712 218.666 89.5875 218.721C89.463 218.777 89.3552 218.862 89.274 218.968C89.1929 219.075 89.1411 219.2 89.1235 219.331C89.1058 219.462 89.1228 219.595 89.1731 219.718C89.2671 220.006 89.4538 220.258 89.7062 220.437C89.9586 220.617 90.2635 220.714 90.577 220.715C90.7048 220.726 90.8333 220.704 90.9497 220.652C91.0662 220.6 91.1666 220.52 91.2412 220.419C91.3157 220.319 91.3618 220.201 91.375 220.078C91.3881 219.955 91.3677 219.831 91.3159 219.718C91.239 219.428 91.069 219.169 90.8302 218.977C90.5914 218.785 90.296 218.671 89.9859 218.65Z"
          fill="#677A89"
        />
        <path
          d="M67.1528 243L52.3745 199.143H52.6701L67.4484 243H67.1528Z"
          fill="#677A89"
        />
      </g>
      <g clip-path="url(#clip5_1147_13155)">
        <path
          d="M447.335 229.256H380.106V242H447.335V229.256Z"
          fill="#465967"
        />
        <path
          d="M409.308 135.82V229.253H418.136V141.047L409.308 135.82Z"
          fill="#677A89"
        />
        <path
          d="M374.224 101.405L370.827 105.41L459.036 178.498L462.432 174.493L374.224 101.405Z"
          fill="#677A89"
        />
        <path
          d="M447.634 161.076L443.114 166.407L458.483 179.142L463.004 173.811L447.634 161.076Z"
          fill="#DAEE6B"
        />
        <path
          d="M313 129.913L342.364 132.164L354.931 160.548C354.931 160.548 333.62 157.252 313 129.913Z"
          fill="#C3CDD5"
        />
        <path
          d="M338.138 155.24C346.157 155.24 352.657 148.814 352.657 140.887C352.657 132.96 346.157 126.534 338.138 126.534C330.119 126.534 323.618 132.96 323.618 140.887C323.618 148.814 330.119 155.24 338.138 155.24Z"
          fill="#DAEE6B"
        />
        <path
          d="M357.372 97.1059L361.113 92C367.311 94.1002 372.762 97.9255 376.812 103.016L372.339 109.971L357.372 97.1059Z"
          fill="#DAEE6B"
        />
        <path
          d="M357.371 97.1055L351.759 104.543C348.465 107.599 329.634 110.734 313 129.912C328.343 138.157 342.454 148.467 354.931 160.547C368.352 140.726 365.872 119.78 368.19 116.323L373.396 108.282C369.317 103.044 363.731 99.148 357.371 97.1055Z"
          fill="#111920"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1147_13155">
        <rect width="480" height="280" fill="white" />
      </clipPath>
      <clipPath id="clip1_1147_13155">
        <rect
          width="378"
          height="266"
          fill="white"
          transform="translate(62 7)"
        />
      </clipPath>
      <clipPath id="clip2_1147_13155">
        <rect
          width="416"
          height="1.9669"
          fill="white"
          transform="translate(26 242)"
        />
      </clipPath>
      <clipPath id="clip3_1147_13155">
        <rect
          width="285"
          height="240"
          fill="white"
          transform="translate(114 3)"
        />
      </clipPath>
      <clipPath id="clip4_1147_13155">
        <rect
          width="190"
          height="160"
          fill="white"
          transform="translate(4 83)"
        />
      </clipPath>
      <clipPath id="clip5_1147_13155">
        <rect
          width="150"
          height="150"
          fill="white"
          transform="translate(313 92)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default DeskLaptop;
