import { Color } from '../../utils/Color';

export const sharedStyles = {
  blurBorder: {
    borderTopColor: Color.gray400,
  },
  blurContainer: {
    backgroundColor: Color.white,
    borderColor: Color.gray400,
  },
  blurTouchable: {
    backgroundColor: Color.white,
  },
  blurTitle: {
    color: Color.gray500,
  },
  focusBorder: {
    borderTopColor: Color.teal500,
  },
  focusContainer: {
    borderColor: Color.teal500,
  },
  focusTitle: {
    color: Color.teal600,
  },
  focusTouchable: {
    backgroundColor: Color.nonStandardTeal,
  },
};
