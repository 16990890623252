import { Badge, Button, colors } from '@vst/beam';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectConfig } from '@mfe/shared/redux/config';
import { getCurrencySymbol } from '@mfe/shared/util';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import {
  AEMContentProvider,
  AemFeature,
  SKIP_PARAMETER_CONSTANT,
  createAemUrl,
} from '@mfe/shared/components';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import {
  selectCurrentPlanPrice,
  selectCurrentUsage,
  selectDownloadSpeeds,
  selectPlanCharacteristics,
} from '@mfe/to-be-migrated/redux/plan';
import { selectBroadbandLabelAttributes } from '@mfe/to-be-migrated/redux/broadbandLabel/broadbandLabelSlice';

import { PlanCard } from '../PlanCard';
import { PlanCardBody } from '../PlanCard/PlanCardBody';
import { PlanCardPrice } from '../PlanCard/PlanCardPrice';
import { TitleAddOns } from '../../shared';

import loadingStyles from '../AvailablePlans/AvailablePlans.module.scss';

type CurrentPlanProps = {
  isCollapsibleCurrentPlan?: boolean;
};

export const CurrentPlan = ({ isCollapsibleCurrentPlan }: CurrentPlanProps) => {
  const { t } = useTranslation('NewChangePlan');

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const {
    data: { upi, serviceAreaId, serviceId, satelliteName, iduDeviceModel },
  } = useSelector(selectBroadbandLabelAttributes);

  const {
    price,
    errors: priceError,
    loading: priceLoading,
  } = useSelector(selectCurrentPlanPrice);

  const {
    characteristics: {
      dataCap,
      titleAddOns,
      usageMeter,
      family,
      inflectionPointText,
    },
    loading: characteristicsLoading,
  } = useSelector(selectPlanCharacteristics);

  const {
    data: { downloadSpeed, downloadUnits },
    loading: downloadSpeedLoading,
  } = useSelector(selectDownloadSpeeds);

  const loading =
    characteristicsLoading || downloadSpeedLoading || priceLoading;

  const {
    data: { monthlyDataCap },
  } = useSelector(selectCurrentUsage);

  const { aemBaseUrl, showBroadbandLabels } = useSelector(selectConfig);
  const { loading: broadbandLabelAttributesLoading } = useSelector(
    selectBroadbandLabelAttributes
  );
  const showBroadbandLabel =
    showBroadbandLabels && !broadbandLabelAttributesLoading;

  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  return loading ? (
    <Loading />
  ) : (
    <PlanCard
      variant="blue"
      name={t('currentPlan.title')}
      broadbandLabel={
        showBroadbandLabel && (
          <AEMContentProvider
            src={createAemUrl({
              baseUrl: aemBaseUrl,
              locale: userLocale,
              feature: AemFeature.PLAN_LABEL,
              accountType,
              additionalSelectors: ['pidi'],
              suffixes: {
                upi,
                serviceAreaId,
                offerId: SKIP_PARAMETER_CONSTANT,
                satelliteName,
                serviceId,
                iduDeviceModel,
              },
            })}
            id={'current-plan-label'}
            style={{ textAlign: 'left' }}
          />
        )
      }
    >
      <PlanCardBody
        characteristics={{
          dataCapGB: dataCap ?? monthlyDataCap.toString(),
          downloadSpeed,
          downloadUnits,
          usageMeter,
          family,
        }}
        disclosure={inflectionPointText}
        isCollapsibleCurrentPlan={isCollapsibleCurrentPlan}
      />

      {Boolean(titleAddOns) && <TitleAddOns characteristic={titleAddOns} />}

      <Button disabled={true} fluid={true} variant="primary" buttonSize="large">
        {t('currentPlan.button')}
      </Button>

      {!priceError && (
        <PlanCardPrice
          price={price}
          currency={getCurrencySymbol(userLocale) ?? undefined}
        />
      )}
    </PlanCard>
  );
};

export const CurrentPlanBadge = () => {
  const { t } = useTranslation('NewChangePlan');
  const label = t('plan.badge.updatedCurrent');
  return (
    <Badge
      datatype="plan-badge"
      label={label}
      showIcon={false}
      emphasis="heavy"
      theme="light"
      size="small"
      state="infoPrimary"
      style={{ backgroundColor: colors['blue'][700] }}
    />
  );
};

const Loading = () => {
  return (
    <PlanCard name="Current plan" variant="blue">
      <div className={loadingStyles['loadingCharacteristics']} />
      <div className={loadingStyles['loadingButton']} />
      <div className={loadingStyles['loadingPrice']} />
      <div className={loadingStyles['loadingLabel']} />
    </PlanCard>
  );
};
