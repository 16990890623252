export const Furnitures = () => (
  <svg
    width="362"
    height="264"
    viewBox="0 0 362 264"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_8751_4864)">
      <path
        d="M59.9024 170.031C59.9024 170.031 49.0892 107.475 50.1572 74.4293C51.8926 19.2167 61.3263 13.0518 61.3263 13.0518C61.3263 13.0518 64.2632 95.508 63.0172 127.874C62.1917 153.079 63.2628 178.311 66.2211 203.349L59.9024 170.031Z"
        fill="#006A85"
      />
      <path
        d="M67.7782 230.819C66.7189 213.67 64.1725 196.65 60.169 179.958C54.2062 158.018 39.6552 127.284 35.5169 85.036C31.3785 42.7879 39.9667 35.7617 39.9667 35.7617C39.9667 35.7617 50.4238 103.757 57.0541 141.971C63.6844 180.185 69.0686 221.254 69.4691 230.728L67.7782 230.819Z"
        fill="#03485C"
      />
      <path
        d="M67.7782 230.819C66.7189 213.67 64.1725 196.65 60.169 179.958C54.2062 158.018 39.6552 129.369 35.5169 87.1212C31.3785 44.8731 39.9667 35.7617 39.9667 35.7617C39.9667 35.7617 34.5824 69.125 52.9157 137.075C60.8551 167.798 66.3902 199.114 69.4691 230.728L67.7782 230.819Z"
        fill="#006A85"
      />
      <path
        d="M67.0221 230.411C67.0221 230.411 71.828 190.339 73.2519 154.845C74.6759 119.351 89.6273 91.3823 90.8287 58.2003C92.0302 25.0184 81.4396 8.6087 81.4396 8.6087C81.4396 8.6087 83.175 61.1015 76.0553 92.9688C68.9355 124.836 69.87 147.365 69.692 176.06C69.5444 194.28 68.057 212.463 65.2422 230.456L67.0221 230.411Z"
        fill="#006A85"
      />
      <path
        d="M72.1843 230.683C72.1843 230.683 73.6082 181.227 65.2425 148.136C57.3218 116.767 61.2822 63.8212 71.2498 53.6219C71.2498 53.6219 71.7838 80.2309 72.6738 100.176C73.5637 120.122 74.7207 196.776 73.5637 230.683H72.1843Z"
        fill="#03485C"
      />
      <path
        d="M72.1837 230.683C72.1837 230.683 73.6966 179.278 69.1133 145.552C64.174 109.288 67.0664 68.4903 71.2493 53.6219C71.2493 53.6219 71.7832 80.2309 72.6732 100.176C73.5632 120.122 74.7201 196.776 73.5632 230.683H72.1837Z"
        fill="#006A85"
      />
      <path
        d="M65.3309 230.909C65.3309 230.909 60.1691 194.872 55.3187 173.43C50.4684 151.989 35.5169 127.511 28.2192 103.213C28.2192 103.213 25.0153 130.14 33.6925 147.955C42.3697 165.769 50.2459 169.033 56.2977 190.747C60.0455 203.88 62.4756 217.367 63.5509 231L65.3309 230.909Z"
        fill="#03485C"
      />
      <path
        d="M75.6548 230.728C77.1712 215.82 79.4737 201.006 82.552 186.349C87.5358 163.231 98.8384 156.749 101.33 124.428C103.088 107.545 102.322 90.4884 99.061 73.8391C99.061 73.8391 87.4024 135.489 84.9994 153.576C82.5965 171.662 80.1491 191.88 77.4792 208.924C75.1208 224.427 73.9639 230.864 73.9639 230.864L75.6548 230.728Z"
        fill="#03485C"
      />
      <path
        d="M75.6548 230.728C77.1712 215.82 79.4737 201.006 82.552 186.349C87.5358 163.231 98.8384 156.749 101.33 124.428C103.088 107.545 102.322 90.4884 99.061 73.8391C99.6063 85.2312 99.2044 96.6499 97.8595 107.973C96.4118 122.416 93.8678 136.723 90.2502 150.765C84.5156 169.784 80.2456 189.229 77.4792 208.924C75.1208 224.427 73.9639 230.864 73.9639 230.864L75.6548 230.728Z"
        fill="#03485C"
      />
      <path
        d="M96.3895 221.866H43.4623C41.548 225.826 40.5537 230.17 40.5542 234.57C40.5542 242.368 43.6456 249.846 49.1484 255.36C54.6512 260.873 62.1146 263.971 69.8968 263.971C77.679 263.971 85.1424 260.873 90.6452 255.36C96.148 249.846 99.2396 242.368 99.2396 234.57C99.2582 230.174 98.2838 225.831 96.3895 221.866Z"
        fill="#677A89"
      />
    </g>
    <g clip-path="url(#clip1_8751_4864)">
      <path
        d="M275.806 252.889H100.724C98.8008 252.889 96.9562 252.123 95.5962 250.76C94.2362 249.397 93.4722 247.548 93.4722 245.62L105.49 135.553C105.49 133.625 106.254 131.776 107.614 130.413C108.974 129.05 110.818 128.284 112.742 128.284H287.72C289.643 128.284 291.488 129.05 292.848 130.413C294.208 131.776 294.972 133.625 294.972 135.553L283.058 245.62C283.058 247.548 282.294 249.397 280.934 250.76C279.574 252.123 277.729 252.889 275.806 252.889Z"
        fill="#F2F5F8"
      />
      <path
        d="M275.804 255.07H100.722C98.2216 255.07 95.8237 254.074 94.0557 252.302C92.2877 250.53 91.2944 248.127 91.2944 245.621L103.312 135.242C103.366 132.772 104.383 130.421 106.145 128.694C107.907 126.966 110.275 125.999 112.739 126H287.718C288.969 126 290.209 126.247 291.365 126.727C292.521 127.207 293.572 127.911 294.457 128.798C295.342 129.685 296.044 130.738 296.523 131.897C297.002 133.056 297.249 134.299 297.249 135.553L285.231 245.828C285.177 248.298 284.16 250.649 282.398 252.376C280.636 254.104 278.269 255.071 275.804 255.07ZM112.739 130.465C111.393 130.465 110.102 131.001 109.15 131.955C108.198 132.909 107.663 134.204 107.663 135.553L95.7492 245.828C95.8027 247.142 96.3611 248.383 97.3073 249.293C98.2536 250.202 99.5143 250.71 100.826 250.709H275.907C276.569 250.709 277.224 250.577 277.835 250.32C278.445 250.063 278.998 249.686 279.461 249.212C279.924 248.739 280.288 248.177 280.532 247.56C280.775 246.943 280.894 246.284 280.88 245.621L292.587 135.242C292.507 133.947 291.938 132.731 290.994 131.843C290.051 130.956 288.805 130.463 287.51 130.465H112.739Z"
        fill="#9FAFBC"
      />
      <path
        d="M280.156 254.448H15.5652C13.7342 254.448 12.25 255.936 12.25 257.771V260.678C12.25 262.513 13.7342 264.001 15.5652 264.001H280.156C281.987 264.001 283.471 262.513 283.471 260.678V257.771C283.471 255.936 281.987 254.448 280.156 254.448Z"
        fill="#9FAFBC"
      />
      <path
        opacity="0.5"
        d="M281.088 264.001H174.485V254.448H281.088C281.72 254.448 282.326 254.7 282.773 255.148C283.22 255.595 283.471 256.203 283.471 256.836V261.717C283.471 262.026 283.409 262.331 283.288 262.616C283.167 262.9 282.99 263.156 282.767 263.37C282.544 263.583 282.281 263.749 281.992 263.858C281.703 263.966 281.396 264.015 281.088 264.001Z"
        fill="#465967"
      />
      <path
        d="M273.733 143.132H124.136C122.363 143.132 120.925 144.573 120.925 146.351V146.455C120.925 148.233 122.363 149.674 124.136 149.674H273.733C275.507 149.674 276.944 148.233 276.944 146.455V146.351C276.944 144.573 275.507 143.132 273.733 143.132Z"
        fill="#32424E"
      />
      <path
        d="M162.572 192.975H116.885L121.236 159.331H166.923L162.572 192.975Z"
        fill="#C3CDD5"
      />
      <path
        d="M252.805 232.225H112.118L115.019 198.478H257.26L252.805 232.225Z"
        fill="#C3CDD5"
      />
      <path
        d="M216.132 192.975H170.445L174.796 159.331H220.587L216.132 192.975Z"
        fill="#C3CDD5"
      />
    </g>
    <g clip-path="url(#clip2_8751_4864)">
      <path d="M345.585 250.256H278.356V263H345.585V250.256Z" fill="#465967" />
      <path
        d="M307.558 156.821V250.254H316.386V162.047L307.558 156.821Z"
        fill="#677A89"
      />
      <path
        d="M272.474 122.403L269.078 126.409L357.286 199.497L360.682 195.492L272.474 122.403Z"
        fill="#677A89"
      />
      <path
        d="M345.885 182.077L341.364 187.409L356.733 200.143L361.254 194.812L345.885 182.077Z"
        fill="#DAEE6B"
      />
      <path
        d="M211.25 150.913L240.614 153.164L253.181 181.548C253.181 181.548 231.87 178.251 211.25 150.913Z"
        fill="#C3CDD5"
      />
      <path
        d="M236.388 176.241C244.406 176.241 250.907 169.815 250.907 161.888C250.907 153.961 244.406 147.535 236.388 147.535C228.369 147.535 221.868 153.961 221.868 161.888C221.868 169.815 228.369 176.241 236.388 176.241Z"
        fill="#111920"
        fill-opacity="0.5"
      />
      <path
        d="M255.622 118.106L259.363 113C265.56 115.1 271.012 118.925 275.062 124.016L270.588 130.971L255.622 118.106Z"
        fill="#DAEE6B"
      />
      <path
        d="M255.621 118.105L250.009 125.543C246.715 128.598 227.884 131.734 211.25 150.911C226.593 159.157 240.704 169.467 253.181 181.546C266.602 161.726 264.122 140.78 266.44 137.322L271.646 129.282C267.567 124.044 261.981 120.147 255.621 118.105Z"
        fill="#111920"
      />
    </g>
    <defs>
      <clipPath id="clip0_8751_4864">
        <rect
          width="201"
          height="264"
          fill="white"
          transform="translate(0.75)"
        />
      </clipPath>
      <clipPath id="clip1_8751_4864">
        <rect
          width="285"
          height="240"
          fill="white"
          transform="translate(12.25 24)"
        />
      </clipPath>
      <clipPath id="clip2_8751_4864">
        <rect
          width="150"
          height="150"
          fill="white"
          transform="translate(211.25 113)"
        />
      </clipPath>
    </defs>
  </svg>
);
