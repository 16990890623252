import React from 'react';
import { useDispatch } from 'react-redux';

import { WebPayBill } from '../WebTabs';
import { setIsConfirmationModalOpen } from '@mfe/to-be-migrated/redux/updateBillCycle';

const UpdateBillCycle = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setIsConfirmationModalOpen(true));
  }, [dispatch]);

  return <WebPayBill />;
};

export default UpdateBillCycle;
