import { useTranslation } from 'react-i18next';
import { Router, Construction } from '@vst/beam-icons/icons';
import { SectionAlert, Txt } from '@vst/beam';
import { useSelector } from 'react-redux';
import {
  selectChangePlan,
  selectChosenPlan,
} from '@mfe/to-be-migrated/redux/changePlan';

export const ModemAlert = () => {
  const { t } = useTranslation('NewChangePlan', {
    keyPrefix: 'orderReview.details.modemReboot',
  });
  const { isEquipmentChangeNeeded } = useSelector(selectChosenPlan);
  const {
    cart: { hasEquipmentShipping },
  } = useSelector(selectChangePlan);

  if (hasEquipmentShipping)
    return (
      <SectionAlert
        variant="warning"
        mb="40px"
        contentSlot={
          <Txt color="regular" component="span" variant="smallRegular">
            {t('deviceInstallWarning')}
          </Txt>
        }
        icon={Construction}
      />
    );

  if (!isEquipmentChangeNeeded)
    return (
      <SectionAlert
        data-cy="modem-reebot"
        variant="infoPrimary"
        mb="40px"
        contentSlot={
          <div>
            <Txt color="regular" component="span" variant="smallBold">
              {t('warning')}
            </Txt>
            <Txt color="regular" component="span" variant="smallRegular">
              {t('details')}
            </Txt>
          </div>
        }
        icon={Router}
      />
    );

  return null;
};
