import React from 'react';
import { ViewStyle, TextStyle } from 'react-native';
import { Color } from '../../utils/Color';

export enum ButtonSize {
  small = 'small',
  large = 'large',
  wide = 'wide',
  tandem = 'tandem',
}

export enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
}

export interface ButtonContext {
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  wide?: boolean;
  small?: boolean;
  large?: boolean;
  disabled?: boolean;
  color?: Color;
  allowLowercase?: boolean;
}

export const getButtonType = (
  primary = false,
  secondary = false,
  tertiary = false
): ButtonType => {
  // Using unary operation to check logic
  if (+primary + +secondary + +tertiary > 1)
    console.error('Button received more than one ButtonType prop.');
  switch (true) {
    case primary:
    default:
      return ButtonType.primary;
    case secondary:
      return ButtonType.secondary;
    case tertiary:
      return ButtonType.tertiary;
  }
};

export const getButtonSize = (
  large = false,
  small = false,
  wide = false,
  tandem = false
): ButtonSize => {
  // Using unary operation to check logic
  if (+large + +small + +wide > 1)
    console.error('ButtonText received more than one ButtonSize prop.');
  switch (true) {
    case large:
    default:
      return ButtonSize.large;
    case small:
      return ButtonSize.small;
    case wide:
      return ButtonSize.wide;
    case tandem:
      return ButtonSize.tandem;
  }
};

export type IconPosition = 'left' | 'right';

export interface ButtonProps extends ButtonContext {
  disabled?: boolean;
  fill?: boolean;
  tandem?: boolean;
  style?: ViewStyle | ViewStyle[];
  onPress?(e: any): void;
  children: React.ReactNode;
  testID?: string;
  Icon?: React.ReactNode;
  IconPosition?: IconPosition;
  IconOffset?: number;
  DisableHover?: boolean;
  overrideTextColor?: Color;
}

export const ButtonDefaultColor = Color.teal500;

export const ButtonCtx = React.createContext({
  primary: false,
  secondary: false,
  tertiary: false,
  small: false,
  large: false,
  wide: false,
  disabled: false,
  color: ButtonDefaultColor,
} as ButtonContext);

export interface ButtonTextProps extends ButtonContext {
  children: React.ReactNode;
  testID?: string;
  style?: TextStyle;
  textColor?: Color;
}
