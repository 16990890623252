import {
  IllustrativeIcon,
  Txt,
  Button,
  Icon,
  IconComponentType,
  StandAloneLink,
} from '@vst/beam';
import { Close } from '@vst/beam-icons/icons';
import { useScreenResolution } from '@mfe/shared/util';
import styles from './Outcome.module.scss';

export type OutcomeFunction = (
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) => void;

export interface OutcomeButton {
  label: string;
  handleClick: OutcomeFunction;
  isLink?: boolean;
}
interface PrimaryButtonOutcome {
  label: string;
  handleClick: OutcomeFunction;
  isLink?: boolean;
}

interface CloseButton {
  handleClick: () => void;
}

interface OutcomeProps {
  iconName?: IconComponentType;
  title: string;
  text: string | JSX.Element;
  secondaryText?: string | JSX.Element;
  thirdText?: string | JSX.Element;
  primaryButton: PrimaryButtonOutcome;
  secondaryButton?: OutcomeButton;
  hasCloseButton?: boolean;
  closeButton?: CloseButton;
}

const Outcome = (props: OutcomeProps) => {
  const {
    iconName,
    title,
    text,
    secondaryText,
    thirdText,
    primaryButton,
    secondaryButton,
    hasCloseButton,
    closeButton,
  } = props;
  const { isExtraSmall, isSmall } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;

  return (
    <div className={styles['outcome']} data-cy="acp-modal-response">
      {hasCloseButton && (
        <button
          className={styles['close']}
          onClick={closeButton?.handleClick}
          data-cy="acp-contactus-x"
        >
          <Icon icon={Close} surface="primary" />
        </button>
      )}
      <div className={styles['content']}>
        {iconName && <IllustrativeIcon icon={iconName} color="teal_600" />}

        <Txt variant="heading4" mt="24px" mb="16px">
          {title}
        </Txt>

        <div className={styles['text-container']}>
          <Txt
            variant="bodyLargeRegular"
            color="subtle"
            mb="16px"
            data-cy="acp-first-text"
          >
            {text}
          </Txt>

          {secondaryText && (
            <Txt
              variant="bodyLargeRegular"
              color="subtle"
              mb="16px"
              data-cy="acp-second-text"
            >
              {secondaryText}
            </Txt>
          )}
          {thirdText && (
            <Txt
              variant="smallRegular"
              color="subtle"
              mb="16px"
              data-cy="acp-third-text"
            >
              {thirdText}
            </Txt>
          )}
        </div>
      </div>
      <div className={styles['footer']}>
        <Button
          style={primaryButton.isLink ? { padding: 0 } : {}}
          data-cy="acp-primary-button"
          variant="primary"
          onClick={primaryButton.isLink ? undefined : primaryButton.handleClick}
          fluid={isMobile}
        >
          {primaryButton.isLink ? (
            <StandAloneLink
              className={styles['go-to-national-verifier-link']}
              style={{
                color: 'white',
                fontWeight: 600,
              }}
              linkSize="medium"
              variant="primary"
              href=""
              onClick={primaryButton.handleClick}
            >
              {primaryButton.label}
            </StandAloneLink>
          ) : (
            <Txt variant="labelMedium">{primaryButton.label}</Txt>
          )}
        </Button>
        {secondaryButton && (
          <Button
            style={secondaryButton.isLink ? { padding: 0 } : {}}
            data-cy="acp-secondary-button"
            variant="secondary"
            onClick={
              secondaryButton.isLink ? undefined : secondaryButton.handleClick
            }
            fluid={isMobile}
          >
            {secondaryButton.isLink ? (
              <StandAloneLink
                className={styles['go-to-national-verifier-link']}
                style={{
                  color: 'black',
                  fontWeight: 600,
                }}
                linkSize="medium"
                variant="secondary"
                href="#"
                onClick={secondaryButton.handleClick}
              >
                {secondaryButton.label}
              </StandAloneLink>
            ) : (
              <Txt variant="labelMedium">{secondaryButton.label}</Txt>
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Outcome;
