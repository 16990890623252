import React from 'react';

export const PushNotificationImage = () => (
  <svg
    width="241"
    height="232"
    viewBox="0 0 241 232"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_8926_18508)">
      <mask
        id="mask0_8926_18508"
        maskUnits="userSpaceOnUse"
        x="41"
        y="0"
        width="200"
        height="200"
      >
        <ellipse
          cx="140.978"
          cy="100.437"
          rx="99.5222"
          ry="99.5222"
          fill="#C4C4C4"
        />
      </mask>
      <g mask="url(#mask0_8926_18508)">
        <ellipse
          cx="140.978"
          cy="100.437"
          rx="99.5222"
          ry="99.5222"
          fill="white"
        />
        <g opacity="0.5" clip-path="url(#clip1_8926_18508)">
          <path
            d="M102.865 20.3358C102.865 20.3358 107.55 16.3222 128.101 12.6607C148.653 8.99931 171.514 16.6908 171.514 16.6908L71.599 27.2901C61.8893 36.9412 54.1057 48.3525 48.6638 60.9147L50.1874 60.7099C62.8099 59.129 70.6078 59.8908 70.6078 59.8908C70.6078 59.8908 78.8481 53.1986 95.3123 52.0191C111.776 50.8396 122.343 55.017 122.343 55.017L47.5171 63.6259C41.0425 79.8339 38.6059 97.3731 40.4174 114.732C42.2289 132.091 48.234 148.75 57.9153 163.272C67.5967 177.794 80.6638 189.744 95.9909 198.093C111.318 206.441 128.445 210.938 145.896 211.196C163.348 211.453 180.6 207.463 196.167 199.57C211.733 191.677 225.147 180.117 235.253 165.887C245.358 151.657 251.852 135.183 254.174 117.884C256.497 100.586 254.578 82.983 248.585 66.5911C199.602 71.7024 120.107 80.0082 119.067 80.1884C124.503 78.2795 130.047 76.6931 135.67 75.4375C145.499 73.2259 159.359 74.3317 159.359 74.3317C159.359 74.3317 177.641 69.4171 187.741 66.8205C197.841 64.2239 214.772 66.4519 214.772 66.4519C214.772 66.4519 219.686 62.3563 241.057 58.5638C242.351 58.3345 243.662 58.1543 244.972 58.0068C240.633 48.7669 235.005 40.1898 228.254 32.5324L146.097 41.9768C146.097 41.9768 147.31 39.3638 159.94 37.7747C172.571 36.1856 180.369 36.9556 180.369 36.9556C180.369 36.9556 188.617 30.2389 205.049 29.0512C212.211 28.508 219.414 28.999 226.436 30.5092C207.187 9.66949 180.47 -2.69982 152.126 -3.89352L142.821 -3.89352C119.692 -2.9307 97.4978 5.49819 79.5607 20.131C89.3164 18.5092 102.865 20.3358 102.865 20.3358Z"
            fill="url(#paint0_linear_8926_18508)"
          />
          <path
            d="M241.114 58.5638C219.727 62.3481 214.829 66.4519 214.829 66.4519C214.829 66.4519 197.873 64.1993 187.798 66.8205C177.723 69.4416 159.416 74.3317 159.416 74.3317C159.416 74.3317 145.565 73.2259 135.727 75.4375C130.104 76.6931 124.56 78.2795 119.124 80.1884C120.164 80.0082 199.659 71.7024 248.642 66.5911C247.569 63.6669 246.365 60.8082 245.054 58.0068C243.686 58.1543 242.376 58.359 241.114 58.5638Z"
            fill="white"
          />
          <path
            d="M128.077 12.6607C107.525 16.3222 102.84 20.3358 102.84 20.3358C102.84 20.3358 89.2918 18.5092 79.5362 20.131C76.7676 22.3863 74.1137 24.7727 71.5744 27.2901L171.49 16.6908C171.49 16.6908 148.653 8.99931 128.077 12.6607Z"
            fill="white"
          />
          <path
            d="M95.3123 52.0191C78.93 53.1986 70.6324 59.9235 70.6324 59.9235C70.6324 59.9235 62.8344 59.1044 50.2119 60.7427L48.6884 60.9474C48.2952 61.8485 47.9184 62.7495 47.558 63.6587L122.343 55.0171C122.343 55.0171 111.695 50.8314 95.3123 52.0191Z"
            fill="white"
          />
          <path
            d="M205.073 29.084C188.642 30.2717 180.393 36.9884 180.393 36.9884C180.393 36.9884 172.596 36.1693 159.965 37.8075C147.334 39.4457 146.097 41.9768 146.097 41.9768L228.279 32.5324C227.673 31.8444 227.058 31.1645 226.436 30.4928C219.419 29.0015 212.225 28.527 205.073 29.084Z"
            fill="white"
          />
        </g>
        <g clip-path="url(#clip2_8926_18508)">
          <path
            d="M-67.8959 153.27L26.156 57.8315L55.2545 67.5238L103.352 22.2116L140.67 58.1805L173.677 27.5854L224.213 74.0179L261.374 57.523L313.569 112.211L341.494 86.0968L384.477 131.409L397.77 153.27H-67.8959Z"
            fill="#C3CDD5"
          />
          <path
            d="M43.4862 127.521L90.5003 90.1231V74.5455L106.265 69.0905L104.097 22.2116L181.014 96.3493L153.821 103.988H135.642L62.343 153.27H-6.0531L15.9066 127.521H43.4862Z"
            fill="#9FAFBC"
          />
          <path
            d="M188.494 92.2398L232.909 126.675L237.224 153.27H276.951L256.734 135.143L224.023 73.4664L173.333 27.9454L188.494 92.2398Z"
            fill="#9FAFBC"
          />
        </g>
        <g clip-path="url(#clip3_8926_18508)">
          <path
            d="M250.336 71.6857H173.102V167.632H250.336V71.6857Z"
            fill="#DEE4E8"
          />
          <path
            d="M250.44 62.3506H173.074L170.819 58.1274H252.822L250.44 62.3506Z"
            fill="#9FAFBC"
          />
          <path
            d="M251.303 114.217H172.13V119.596H251.303V114.217Z"
            fill="#9FAFBC"
          />
          <path
            d="M251.596 69.2491H171.729V71.9383H251.596V69.2491Z"
            fill="#9FAFBC"
          />
          <path
            d="M250.449 62.3553H173.088V69.2444H250.449V62.3553Z"
            fill="#DEE4E8"
          />
          <path
            d="M252.034 165.265H173.041V168.025H252.034V165.265Z"
            fill="#9FAFBC"
          />
          <path
            d="M173.069 168.029H199.87V164.115H173.069V168.029Z"
            fill="#9FAFBC"
          />
          <path
            d="M193.803 83.4106H180.061V106.21H193.803V83.4106Z"
            fill="#9FAFBC"
          />
          <path
            d="M192.695 83.102H181.169V106.514H192.695V83.102Z"
            fill="white"
          />
          <path
            d="M194.893 84.1309H178.966V81.8486C178.966 81.8486 181.74 81.8065 182.457 81.1517C183.174 80.497 184.623 79.8983 186.93 79.8983C189.336 79.8983 190.704 80.4689 191.515 81.1657C192.327 81.8626 194.893 81.8486 194.893 81.8486V84.1309Z"
            fill="#9FAFBC"
          />
          <path
            d="M194.893 105.476H178.971V107.988H194.893V105.476Z"
            fill="#9FAFBC"
          />
          <path
            d="M217.84 83.4106H204.098V106.21H217.84V83.4106Z"
            fill="#9FAFBC"
          />
          <path
            d="M216.732 83.102H205.206V106.514H216.732V83.102Z"
            fill="white"
          />
          <path
            d="M218.93 84.1309H203.008V81.8486C203.008 81.8486 205.782 81.8065 206.494 81.1517C207.206 80.497 208.66 79.8983 210.966 79.8983C213.373 79.8983 214.741 80.4689 215.552 81.1657C216.364 81.8626 218.93 81.8486 218.93 81.8486V84.1309Z"
            fill="#9FAFBC"
          />
          <path
            d="M218.93 105.476H203.008V107.988H218.93V105.476Z"
            fill="#9FAFBC"
          />
          <path
            d="M241.877 83.4106H228.134V106.21H241.877V83.4106Z"
            fill="#9FAFBC"
          />
          <path
            d="M240.773 83.102H229.248V106.514H240.773V83.102Z"
            fill="white"
          />
          <path
            d="M242.967 84.1309H227.035V81.8486C227.035 81.8486 229.809 81.8065 230.521 81.1517C231.234 80.497 232.687 79.8983 234.994 79.8983C237.4 79.8983 238.768 80.4689 239.58 81.1657C240.391 81.8626 242.957 81.8486 242.957 81.8486L242.967 84.1309Z"
            fill="#9FAFBC"
          />
          <path
            d="M242.967 105.476H227.044V107.988H242.967V105.476Z"
            fill="#9FAFBC"
          />
          <path
            d="M193.785 131.681H177.579V164.124H193.785V131.681Z"
            fill="#9FAFBC"
          />
          <path
            d="M191.341 134.552H180.023V146.895H191.341V134.552Z"
            fill="white"
          />
          <path
            d="M247.35 121.588H226.03V163.329H247.35V121.588Z"
            fill="#9FAFBC"
          />
          <path
            d="M246.208 122.608H227.125V162.314H246.208V122.608Z"
            fill="white"
          />
          <path
            d="M223.742 121.588H202.423V163.329H223.742V121.588Z"
            fill="#9FAFBC"
          />
          <path
            d="M222.6 122.608H203.517V162.314H222.6V122.608Z"
            fill="white"
          />
          <path
            d="M166.195 156.398H168.262L168.743 168.207H165.714L166.195 156.398Z"
            fill="#9FAFBC"
          />
          <path
            d="M167.922 155.65H166.61V128.865C166.663 127.829 166.428 126.799 165.931 125.886C165.374 124.997 164.473 124.567 163.185 124.567C160.355 124.567 159.76 126.213 159.76 128.865H158.449C158.449 127.191 158.449 123.253 163.167 123.253C166.219 123.253 167.884 125.241 167.884 128.865L167.922 155.65Z"
            fill="#9FAFBC"
          />
          <path
            d="M159.11 135.361C160.837 135.361 162.237 133.973 162.237 132.261C162.237 130.548 160.837 129.16 159.11 129.16C157.382 129.16 155.982 130.548 155.982 132.261C155.982 133.973 157.382 135.361 159.11 135.361Z"
            fill="#F1FFA4"
          />
          <path
            d="M165.096 154.972L165.761 156.805H168.715L169.38 154.972H165.096Z"
            fill="#9FAFBC"
          />
          <path
            d="M157.392 128.561L155.816 129.847H162.435L160.86 128.561H157.392Z"
            fill="#9FAFBC"
          />
          <path
            d="M168.71 131.793H165.823V132.864H168.71V131.793Z"
            fill="#9FAFBC"
          />
        </g>
        <g clip-path="url(#clip4_8926_18508)">
          <path
            d="M165.349 174.057C165.353 176.119 164.75 178.136 163.615 179.852C162.48 181.569 160.864 182.909 158.972 183.702C157.079 184.495 154.996 184.706 152.984 184.308C150.973 183.91 149.124 182.921 147.671 181.467C146.218 180.012 145.227 178.156 144.823 176.135C144.419 174.114 144.62 172.018 145.401 170.111C146.182 168.205 147.507 166.575 149.21 165.426C150.912 164.277 152.915 163.662 154.965 163.658C156.326 163.655 157.674 163.922 158.933 164.443C160.191 164.964 161.335 165.729 162.299 166.695C163.263 167.66 164.029 168.807 164.552 170.071C165.076 171.334 165.346 172.689 165.349 174.057Z"
            fill="#1D2229"
          />
          <path
            d="M255.25 173.874C255.255 175.937 254.651 177.954 253.515 179.672C252.38 181.389 250.763 182.73 248.87 183.523C246.977 184.317 244.892 184.528 242.88 184.129C240.867 183.731 239.017 182.742 237.564 181.287C236.111 179.831 235.119 177.975 234.715 175.953C234.311 173.931 234.512 171.833 235.293 169.926C236.074 168.019 237.4 166.388 239.104 165.239C240.807 164.09 242.811 163.474 244.862 163.47C246.223 163.467 247.572 163.734 248.831 164.255C250.09 164.776 251.234 165.542 252.199 166.508C253.164 167.474 253.93 168.622 254.453 169.886C254.977 171.15 255.247 172.505 255.25 173.874Z"
            fill="#1D2229"
          />
          <path
            d="M210.839 140.453C211.178 139.196 211.583 137.957 212.053 136.744C212.431 136.042 213.095 135.249 214.074 135.249H221.094C221.542 135.284 221.971 135.446 222.332 135.715C222.693 135.985 222.971 136.351 223.134 136.772C223.474 137.733 224.491 141.405 224.491 141.405"
            fill="#D3CFCB"
          />
          <path
            d="M247.332 178.228C247.332 178.228 256.616 176.042 258.055 175.676C259.493 175.311 259.397 174.139 259.397 174.139L263.43 173.322V169.256C263.442 168.008 263.281 166.765 262.953 165.561L260.606 160.362L124.756 164.624C124.756 164.624 122.085 172.625 122.085 173.163C122.085 173.701 124.068 178.305 127.394 178.93C130.161 179.453 136.081 179.891 137.969 180.025L219.546 179.876L221.457 180.568L247.332 178.228Z"
            fill="#1D2229"
          />
          <path
            d="M247.332 178.228C247.423 178.353 256.616 176.042 258.055 175.676C259.493 175.311 259.397 174.139 259.397 174.139L263.43 173.322V169.256C263.442 168.008 263.281 166.765 262.952 165.561L260.606 160.362V157.08C260.632 156.542 260.54 156.006 260.338 155.508C260.136 155.01 259.828 154.562 259.436 154.196V147.949L252.703 147.243C227.377 137.459 194.354 137.527 184.4 139.627C174.447 141.727 152.304 153.86 152.304 153.86C152.304 153.86 132.841 158.771 129.167 160.612C125.492 162.452 124.775 164.605 124.775 164.605L123.432 165.47C122.63 167.728 122.104 172.606 122.104 173.158C122.104 173.711 124.087 178.3 127.413 178.925C130.18 179.448 137.925 180.083 137.987 180.02C135.092 175.724 136.994 164.888 147.454 164.888C157.039 164.888 160.446 174.768 157.273 179.987C157.273 180.025 219.565 179.871 219.565 179.871L221.476 180.563C221.476 180.563 227.129 180.025 227.052 179.972C224.912 177.57 224.998 165.273 236.241 164.73C246.08 164.249 248.851 173.73 247.332 178.228Z"
            fill="#F5F8FC"
          />
          <path
            d="M121.626 173.12C121.626 173.12 127.561 176.599 138.733 176.599C149.905 176.599 227.334 175.36 227.334 175.36C227.334 175.36 252.034 171.583 262.957 166.441L263.435 173.322L259.402 174.139C259.384 174.513 259.245 174.871 259.006 175.158C258.767 175.445 258.442 175.646 258.079 175.73C256.779 176.042 238.874 180.823 238.874 180.823L180.1 182.337L128.651 180.982C128.651 180.982 120.91 177.325 120.91 175.403C120.91 173.481 121.626 173.12 121.626 173.12Z"
            fill="#A2A8B4"
          />
          <path
            d="M139.894 157.546C139.894 157.546 132.139 157.546 126.992 161.083C121.846 164.62 124.694 165.143 124.694 165.143L126.405 166.998C126.405 166.998 128.727 162.933 136.172 160.751C139.956 159.31 140.262 157.575 140.262 157.575"
            fill="#EDEEAD"
          />
          <path
            d="M158.755 157.993L245.072 148.666C245.072 148.666 249.372 148.786 244.35 147.402C239.328 146.019 227.865 142.809 208.67 141.588C189.475 140.367 183.707 142.996 179.244 144.106C174.781 145.216 159.787 153.001 159.787 153.001C159.787 153.001 156.021 155.139 158.755 157.993Z"
            fill="#B5DDF7"
          />
          <path
            d="M199.51 178.305L199.472 159.031L201.76 141.309L202.014 141.343L199.725 159.045L199.763 178.3L199.51 178.305Z"
            fill="#7B828F"
          />
          <path
            d="M166.601 178.502C164.269 178.502 164.044 176.066 164.039 176.042L162.959 157.537H163.213L164.297 176.037C164.297 176.129 164.508 178.262 166.601 178.262L215.054 178.161C215.949 178.171 216.834 177.965 217.634 177.559C218.434 177.154 219.126 176.561 219.65 175.831L233.508 154.716C233.551 154.658 234.392 153.539 233.398 151.496L229.8 144.082L230.029 143.971L233.632 151.381C234.698 153.582 233.756 154.812 233.718 154.865L219.861 175.97C219.312 176.734 218.589 177.355 217.753 177.781C216.916 178.206 215.991 178.424 215.054 178.416L166.596 178.517L166.601 178.502Z"
            fill="#7B828F"
          />
          <path
            d="M189.986 160.271C189.88 160.269 189.779 160.229 189.7 160.157C189.622 160.085 189.572 159.987 189.561 159.881C189.554 159.825 189.558 159.767 189.573 159.711C189.589 159.656 189.615 159.604 189.651 159.56C189.686 159.515 189.731 159.478 189.781 159.45C189.831 159.423 189.886 159.406 189.943 159.401L197.464 158.598C197.578 158.586 197.691 158.62 197.78 158.692C197.869 158.764 197.925 158.869 197.937 158.983C197.949 159.097 197.916 159.211 197.844 159.3C197.773 159.39 197.669 159.447 197.555 159.459L190.034 160.256L189.986 160.271Z"
            fill="#203944"
          />
          <path
            d="M222.393 156.816C222.288 156.815 222.186 156.776 222.108 156.705C222.03 156.634 221.98 156.537 221.968 156.431C221.956 156.317 221.989 156.202 222.061 156.112C222.133 156.022 222.237 155.964 222.35 155.951L229.886 155.158C229.945 155.145 230.006 155.145 230.065 155.158C230.124 155.17 230.18 155.195 230.228 155.231C230.277 155.266 230.318 155.312 230.348 155.364C230.378 155.416 230.397 155.475 230.404 155.535C230.41 155.595 230.404 155.656 230.385 155.714C230.367 155.772 230.336 155.825 230.296 155.87C230.256 155.915 230.207 155.951 230.152 155.976C230.097 156 230.037 156.013 229.977 156.013L222.441 156.811L222.393 156.816Z"
            fill="#203944"
          />
          <path
            d="M246.849 175.316C246.853 177.256 246.285 179.155 245.216 180.77C244.147 182.386 242.625 183.647 240.844 184.393C239.062 185.139 237.101 185.337 235.207 184.963C233.314 184.588 231.573 183.657 230.206 182.287C228.839 180.917 227.906 179.171 227.526 177.268C227.145 175.365 227.335 173.392 228.07 171.598C228.805 169.803 230.053 168.268 231.655 167.187C233.258 166.105 235.143 165.526 237.073 165.522C238.354 165.52 239.623 165.771 240.808 166.262C241.993 166.753 243.07 167.473 243.978 168.383C244.886 169.292 245.607 170.373 246.099 171.562C246.592 172.752 246.847 174.027 246.849 175.316Z"
            fill="#38414A"
          />
          <path
            d="M246.137 175.316C246.141 177.256 245.572 179.155 244.503 180.77C243.434 182.386 241.913 183.647 240.131 184.393C238.35 185.139 236.388 185.337 234.495 184.963C232.601 184.588 230.861 183.657 229.494 182.287C228.126 180.917 227.194 179.171 226.814 177.268C226.433 175.365 226.623 173.392 227.358 171.598C228.093 169.803 229.34 168.268 230.943 167.187C232.545 166.105 234.431 165.526 236.361 165.522C237.642 165.52 238.911 165.771 240.096 166.262C241.281 166.753 242.358 167.473 243.266 168.383C244.174 169.292 244.894 170.373 245.387 171.562C245.88 172.752 246.135 174.027 246.137 175.316Z"
            fill="#252D38"
          />
          <path
            d="M243.218 175.326C243.22 176.686 242.822 178.016 242.073 179.148C241.324 180.281 240.258 181.164 239.01 181.688C237.761 182.211 236.387 182.35 235.06 182.087C233.733 181.825 232.513 181.172 231.555 180.213C230.596 179.253 229.943 178.029 229.676 176.696C229.409 175.362 229.542 173.979 230.057 172.722C230.572 171.464 231.446 170.389 232.569 169.631C233.692 168.873 235.013 168.466 236.365 168.464C237.263 168.462 238.153 168.638 238.983 168.982C239.813 169.325 240.568 169.83 241.205 170.468C241.841 171.105 242.346 171.862 242.692 172.695C243.037 173.529 243.216 174.423 243.218 175.326Z"
            fill="#6C7986"
          />
          <path
            d="M157.455 175.494C157.459 177.435 156.89 179.333 155.821 180.949C154.752 182.564 153.23 183.825 151.449 184.571C149.667 185.317 147.706 185.516 145.812 185.141C143.919 184.766 142.179 183.835 140.811 182.465C139.444 181.096 138.511 179.349 138.131 177.446C137.751 175.544 137.94 173.57 138.675 171.776C139.41 169.981 140.658 168.446 142.26 167.365C143.863 166.284 145.748 165.704 147.678 165.701C148.96 165.698 150.229 165.949 151.414 166.44C152.598 166.931 153.676 167.652 154.583 168.561C155.491 169.47 156.212 170.551 156.705 171.74C157.197 172.93 157.452 174.205 157.455 175.494Z"
            fill="#38414A"
          />
          <path
            d="M156.843 175.494C156.847 177.435 156.278 179.333 155.209 180.949C154.14 182.564 152.619 183.825 150.837 184.571C149.056 185.317 147.094 185.516 145.201 185.141C143.307 184.766 141.567 183.835 140.2 182.465C138.832 181.096 137.9 179.349 137.519 177.446C137.139 175.544 137.329 173.57 138.064 171.776C138.799 169.981 140.046 168.446 141.649 167.365C143.251 166.284 145.137 165.704 147.066 165.701C148.348 165.698 149.617 165.949 150.802 166.44C151.987 166.931 153.064 167.652 153.972 168.561C154.88 169.47 155.6 170.551 156.093 171.74C156.586 172.93 156.841 174.205 156.843 175.494Z"
            fill="#252D38"
          />
          <path
            d="M153.895 175.499C153.898 176.859 153.499 178.189 152.75 179.322C152.001 180.454 150.935 181.338 149.686 181.861C148.437 182.384 147.062 182.523 145.735 182.26C144.408 181.997 143.189 181.344 142.23 180.384C141.272 179.424 140.619 178.199 140.353 176.866C140.087 175.532 140.22 174.149 140.736 172.892C141.251 171.634 142.126 170.559 143.25 169.801C144.373 169.044 145.695 168.639 147.048 168.637C148.86 168.634 150.6 169.355 151.884 170.642C153.168 171.929 153.891 173.676 153.895 175.499Z"
            fill="#6C7986"
          />
          <path
            d="M161.407 153.231C160.15 154.356 159.495 156.023 160.341 157.46C161.187 158.897 162.94 159.382 164.441 159.243C165.941 159.103 166.457 154.12 165.482 152.438C164.804 151.271 162.668 152.107 161.407 153.231Z"
            fill="#A2A8B4"
          />
        </g>
      </g>
      <g clip-path="url(#clip5_8926_18508)">
        <path
          d="M79.8086 151.114C79.6604 150.393 79.2721 149.746 78.7092 149.283C78.1463 148.819 77.4433 148.567 76.7186 148.568L38.4094 146.144C35.5937 137.852 33.136 130.858 32.7423 130.482C32.7423 130.482 24.892 126.033 21.1099 128.312C17.3279 130.591 18.4971 133.634 18.4971 133.634L24.9992 151.575L31.8713 169.564L36.3215 177.25L47.8225 174.45C47.8225 174.45 43.7662 162.036 39.8529 150.411C50.4593 150.411 72.0656 150.702 75.2034 153.563C77.2913 155.43 79.6655 177.25 79.6655 177.25H84.9506C85.4637 171.407 80.3932 153.066 79.8086 151.114Z"
          fill="#677A89"
        />
        <path
          d="M84.5689 184.22H90.9637L95.5212 231.085H92.9919L84.5689 184.22Z"
          fill="#677A89"
        />
        <path
          d="M52.1058 184.22H45.7109L39.8888 231.085H42.4062L52.1058 184.22Z"
          fill="#677A89"
        />
        <path
          d="M36.3214 177.226L43.8377 173.007L90.0451 177.226H36.3214Z"
          fill="#677A89"
        />
        <path
          d="M90.94 184.22H76.3488V177.226H90.0452L90.94 184.22Z"
          fill="#677A89"
        />
        <path
          d="M80.9063 184.22H30.4159V177.226H79.6775L80.9063 184.22Z"
          fill="#C3CDD5"
        />
        <path
          d="M36.8106 184.22H30.4158L25.8583 231.085H28.3756L36.8106 184.22Z"
          fill="#C3CDD5"
        />
        <path
          d="M74.4876 184.22H80.8824L82.159 231.085H79.6416L74.4876 184.22Z"
          fill="#C3CDD5"
        />
        <path
          d="M32.7423 130.457C32.7423 130.457 27.1228 124.748 20.5252 126.615C18.8072 127.1 15.4906 129.67 17.5188 135.573C19.547 141.477 30.4157 177.226 30.4157 177.226H36.381C36.381 177.226 21.1695 138.337 20.2986 134.591C19.4276 130.845 22.5654 125.439 32.7423 130.457Z"
          fill="#C3CDD5"
        />
        <path
          d="M21.7969 145.898C21.7969 145.898 66.852 148.968 68.1047 149.065C68.8314 149.061 69.5373 149.312 70.1027 149.776C70.6681 150.24 71.0581 150.888 71.2068 151.611C71.7914 153.599 76.3488 177.723 76.3488 177.723C76.0242 177.723 76.9566 183.577 76.6775 183.577C75.5541 183.577 72.6596 183.577 71.3532 183.577L71.0636 177.723C71.0636 177.723 68.8206 153.951 65.3846 153.951L22.9185 150.565L21.7969 145.898Z"
          fill="#C3CDD5"
        />
      </g>
      <g clip-path="url(#clip6_8926_18508)">
        <g clip-path="url(#clip7_8926_18508)">
          <path
            d="M43.2577 89.5884C39.2135 92.8298 32.4787 89.2157 32.4787 89.2157C32.4787 89.2157 35.76 94.0778 32.249 100.22L31.5764 101.395C31.5764 101.395 31.199 102.092 31.035 102.497C30.8709 102.902 30.6986 103.308 30.6986 103.308V103.389C30.6986 105.358 30.4525 111.897 27.5076 112.067C26.3021 114.362 30.0752 116.751 32.1834 117.359C34.2916 117.967 37.2366 118.631 39.8206 116.784C42.6096 114.782 39.0002 111.857 39.0002 111.857C39.0002 111.857 36.5967 113.032 37.7041 106.038C38.3275 106.187 38.9662 106.263 39.6073 106.265C43.4218 106.265 44.6522 99.2559 45.046 94.7261C45.7597 89.321 43.2577 89.5884 43.2577 89.5884Z"
            fill="#A28370"
          />
          <path
            d="M29.1154 97.6838C29.0416 97.9836 28.8857 98.3078 28.533 98.3078C27.9401 98.1566 27.4208 97.8023 27.0678 97.3081C26.7147 96.8138 26.5508 96.2118 26.6052 95.6093C26.6052 94.2803 27.1138 92.9271 28.3689 92.8703C28.8529 92.8703 29.1892 93.5105 29.1892 94.1264C29.2344 94.9064 29.2344 95.6883 29.1892 96.4683C29.1971 96.8747 29.1724 97.2812 29.1154 97.6838Z"
            fill="#A28370"
          />
          <g opacity="0.3">
            <path
              d="M42.0108 100.577C42.8311 100.674 42.4209 101.784 42.4209 101.784C42.2049 102.134 41.9063 102.428 41.5506 102.64C41.195 102.851 40.7928 102.975 40.3784 103C39.0494 103 37.417 100.877 37.5236 100.877C37.6303 100.877 39.8698 100.471 40.2799 100.374C40.6901 100.277 41.1987 100.674 41.1987 100.674C41.4469 100.54 41.7374 100.506 42.0108 100.577Z"
              fill="#8E0D13"
            />
          </g>
          <path
            d="M42.0108 100.577C42.8311 100.674 42.4209 101.784 42.4209 101.784C42.4209 101.784 41.6908 101.784 40.6654 101.687C39.5949 101.514 38.5431 101.243 37.5236 100.877C37.6221 100.877 39.8697 100.471 40.2799 100.374C40.6901 100.277 41.1987 100.674 41.1987 100.674C41.4469 100.54 41.7374 100.506 42.0108 100.577Z"
            fill="#590409"
          />
          <g opacity="0.3">
            <path
              d="M41.1084 98.7292L42.9049 98.2673C42.4266 98.6133 41.8984 98.8864 41.3381 99.0777C40.9772 99.0615 41.1084 98.7292 41.1084 98.7292Z"
              fill="black"
            />
          </g>
          <path
            d="M45.1362 94.6855C44.8454 94.8789 44.5148 95.0062 44.1682 95.0582C44.1009 94.9913 44.0095 94.9534 43.9139 94.9529C43.816 94.9529 43.7221 94.9913 43.6529 95.0597C43.5837 95.1281 43.5448 95.2209 43.5448 95.3176C43.5448 95.4143 43.5837 95.507 43.6529 95.5754C43.7221 95.6438 43.816 95.6822 43.9139 95.6822C44.0118 95.6822 44.1057 95.6438 44.175 95.5754C44.2442 95.507 44.2831 95.4143 44.2831 95.3176C44.2747 95.2679 44.2551 95.2209 44.2257 95.1798C44.5704 95.1033 44.886 94.9319 45.1362 94.6855Z"
            fill="#1C262F"
          />
          <path
            d="M39.3858 94.6855C39.095 94.8789 38.7644 95.0062 38.4178 95.0582C38.3505 94.9913 38.2591 94.9534 38.1635 94.9529C38.0656 94.9529 37.9717 94.9913 37.9025 95.0597C37.8333 95.1281 37.7944 95.2209 37.7944 95.3176C37.7944 95.4143 37.8333 95.507 37.9025 95.5754C37.9717 95.6438 38.0656 95.6822 38.1635 95.6822C38.2614 95.6822 38.3553 95.6438 38.4245 95.5754C38.4938 95.507 38.5327 95.4143 38.5327 95.3176C38.5243 95.2679 38.5047 95.2209 38.4752 95.1798C38.8199 95.1033 39.1356 94.9319 39.3858 94.6855Z"
            fill="#1C262F"
          />
          <path
            d="M42.5276 93.8671V93.2188C42.5276 93.2188 45.8089 91.9304 46.6948 92.838C45.2578 92.9516 43.8497 93.2993 42.5276 93.8671Z"
            fill="#131C24"
          />
          <path
            d="M40.001 93.632C39.2061 93.1064 38.3032 92.7611 37.3572 92.621C36.4112 92.4809 35.4455 92.5494 34.5295 92.8216C34.5295 92.8216 36.5311 91.1118 40.083 92.8216L40.001 93.632Z"
            fill="#131C24"
          />
          <path
            d="M29.1974 96.4521C29.1901 96.8511 29.1544 97.2492 29.0907 97.6433C29.0251 97.935 28.861 98.2511 28.5247 98.2511C27.9417 98.1066 27.4302 97.7609 27.0827 97.2764C26.7351 96.7919 26.5745 96.2006 26.6298 95.6093C26.6298 94.3047 27.1302 92.9757 28.3606 92.919C28.8364 92.9595 29.181 93.5511 29.181 94.1588C29.2316 94.9223 29.2371 95.688 29.1974 96.4521ZM32.5197 81.3877C32.4968 80.9595 32.3939 80.5392 32.2161 80.1479C31.1825 77.9357 27.9013 77.2469 24.8907 78.6163C21.8801 79.9858 20.2641 82.8869 21.2895 85.0991C21.6985 85.9327 22.4101 86.5836 23.2829 86.9224C23.168 87.044 23.0614 87.1736 22.9629 87.2952C18.041 93.3647 25.8587 100.188 28.4837 101.185C28.3659 102.122 28.0392 103.021 27.5271 103.818C27.015 104.616 26.3303 105.291 25.5223 105.795C26.2734 105.377 26.9277 104.809 27.4433 104.127C27.959 103.444 28.3245 102.663 28.5165 101.833C28.2786 103.543 27.9341 108.518 31.5271 110.593C31.5271 110.593 28.1802 106.979 32.0357 100.69C35.8912 94.4019 32.6181 89.3454 32.6181 89.3454C32.6181 89.3454 39.1807 92.9433 43.1838 89.71C43.1838 89.71 45.6448 89.4426 44.9393 94.8395C44.9393 94.8395 49.0409 91.4766 48.8112 87.7084C48.5077 83.284 43.1756 78.3976 32.5197 81.3877Z"
            fill="#131C24"
          />
          <g opacity="0.2">
            <path
              d="M31.0432 102.57C31.8635 103.818 34.3244 107.132 37.4663 107.87L37.6877 106.055C34.6772 105.382 32.6264 103.291 31.2482 102.043C31.2482 102.068 31.2318 102.084 31.0432 102.57Z"
              fill="black"
            />
          </g>
        </g>
        <path
          d="M135.515 217.857L142.902 220.012C142.902 220.012 148.913 218.592 149.572 219.83L131.854 228.384C131.229 227.096 131.538 222.142 131.538 222.142L133.989 220.953L135.515 217.857Z"
          fill="#677A89"
        />
        <path
          d="M105.25 223.841L110.978 228.993C110.978 228.993 116.997 230.331 117.039 231.726H97.3707C97.3707 230.298 99.7969 225.947 99.7969 225.947H102.523L105.25 223.841Z"
          fill="#677A89"
        />
        <path
          d="M132.697 230.002L150.397 221.457L149.597 219.797L131.897 228.343L132.697 230.002Z"
          fill="black"
        />
        <path
          d="M140.716 219.368L139.967 220.673"
          stroke="black"
          stroke-width="0.1"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M139.266 218.972L138.524 220.276"
          stroke="black"
          stroke-width="0.1"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M137.815 218.567L137.074 219.88"
          stroke="black"
          stroke-width="0.1"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M109.268 227.458L108.026 228.316"
          stroke="black"
          stroke-width="0.1"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M108.134 226.467L106.891 227.326"
          stroke="black"
          stroke-width="0.1"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M107.009 225.476L105.767 226.335"
          stroke="black"
          stroke-width="0.1"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M42.6683 141.146C42.6683 141.146 46.6536 152.705 54.8327 153.638C74.0089 155.884 96.5701 164.091 99.4049 166.18C105.708 168.161 134.514 214.439 134.514 214.439C134.514 214.439 130.345 222.183 121.908 220.747L91.4843 175.336L48.0043 168.731L42.6683 141.146Z"
          fill="#00819D"
        />
        <path
          d="M33.3885 146.942L38.6411 166.444C38.6411 166.444 38.6492 178.231 56.1997 177.843C73.7501 177.455 90.2418 174.923 88.9578 176.261C88.8828 176.343 95.4611 219.242 95.4611 219.242H110.469C110.469 219.242 106.517 166.048 103.749 162.217C100.98 158.386 54.5574 156.437 54.5574 156.437L33.3885 146.942Z"
          fill="#00A2C0"
        />
        <path
          d="M25.6934 111.712C22.9421 112.05 19.8572 116.666 20.1824 120.975C20.4825 124.608 23.2422 133.946 24.6846 137.901C26.8774 143.945 38.7249 166.361 38.7249 166.361C38.7249 166.361 49.8888 168.698 58.1763 153.935C58.1763 153.886 54.0576 147.982 52.5652 144.201C51.1478 140.609 49.0884 136.225 49.1718 135.944C49.5299 134.524 49.7697 133.077 49.8888 131.618C49.8888 131.618 53.0071 130.586 52.8486 129.719C52.6902 128.852 48.4465 115.592 48.4465 115.592C48.4465 115.592 47.9712 109.499 39.8839 110.011C39.8839 110.011 38.5165 110.011 38.0246 110.671C37.3909 113.19 34.6813 114.676 25.6934 111.712Z"
          fill="#DAEE6B"
        />
        <path
          d="M31.0712 125.029L32.6887 133.129"
          stroke="#215B04"
          stroke-width="0.1"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M49.9805 131.692C50.3807 128.39 49.1467 122.024 45.72 117.722"
          stroke="#215B04"
          stroke-width="0.1"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M81.0872 128.365L70.7321 124.699L78.5943 96.7509L88.9495 97.9315L81.0872 128.365Z"
          fill="#C3CDD5"
        />
        <path
          d="M80.8455 126.912L71.6576 123.568L78.6361 98.4847L87.824 99.8057L80.8455 126.912Z"
          fill="#202E39"
        />
        <path
          d="M129.853 219.219C130.636 220.074 131.37 220.969 132.054 221.903L133.989 220.945L134.972 218.98L133.305 216.181C132.34 217.383 131.172 218.411 129.853 219.219Z"
          fill="#A28370"
        />
        <path
          d="M100.439 219.259C100.731 222.702 100.898 224.84 100.606 225.963H102.532L104.766 224.213L105.467 219.259C105.467 219.259 100.406 219.209 100.439 219.259Z"
          fill="#A28370"
        />
        <path
          d="M60.4776 159.063L60.8195 155.554L39.142 153.968L32.8305 133.046L23.4675 134.301C27.2444 146.909 31.805 161.077 35.9738 161.085C38.3499 161.085 49.6973 160.07 56.1088 159.484L60.4776 159.063Z"
          fill="#A28370"
        />
        <path
          d="M65.1882 153.134C65.1882 153.134 66.272 152.416 66.6055 152.309C66.939 152.201 68.4565 152.045 68.7983 151.954C69.1401 151.863 69.8071 152.086 70.3741 151.954C70.941 151.822 71.5997 151.392 71.558 152.391C71.5163 153.39 66.0136 153.333 65.7218 153.217C65.6577 153.177 65.5856 153.152 65.5106 153.144C65.4356 153.135 65.3597 153.143 65.2882 153.167C65.2882 153.167 64.9047 153.283 65.1882 153.134Z"
          fill="#A28370"
        />
        <path
          d="M57.093 159.104C57.093 159.104 62.7625 158.988 63.5629 159.005C64.3633 159.021 67.3898 159.12 68.4153 159.054C69.4834 158.918 70.5396 158.703 71.5752 158.41C72.0215 158.16 72.4172 157.829 72.7425 157.436C72.8214 157.24 72.8214 157.021 72.7425 156.825C72.7425 156.825 73.6763 156.206 73.5762 155.347C73.833 155.165 74.0488 154.931 74.2099 154.662C74.3266 154.348 74.435 154.076 74.46 153.985C74.474 153.879 74.4489 153.771 74.3891 153.681C74.3294 153.592 74.2391 153.527 74.1348 153.498C73.8513 153.448 72.9426 154.183 72.1005 154.233L70.2162 154.571C70.0911 154.571 69.6076 154.629 69.5825 154.571C69.5575 154.513 69.5825 154.348 70.0661 154.241L71.5085 153.878L72.9175 153.539C73.226 153.49 74.7935 153.25 74.9936 152.846C75.1937 152.441 74.7851 152.235 74.3016 152.21C73.818 152.185 72.5424 152.35 72.2672 152.317C71.9921 152.284 70.5997 152.532 70.1828 152.614C69.766 152.697 68.3486 152.788 67.9818 152.846C67.1543 152.979 66.3186 153.056 65.4805 153.077C65.0553 153.019 63.0876 154.662 62.6624 154.926C62.2372 155.19 60.4947 155.752 57.2014 155.752C53.9081 155.752 56.9763 158.196 57.093 159.104Z"
          fill="#A28370"
        />
        <path
          d="M73.9845 113.421C74.268 111.769 74.7183 111.588 76.169 110.399C77.6197 109.21 77.0861 108.5 77.0861 108.5L73.9845 110.399C73.2821 111.043 72.5475 111.652 71.7834 112.224"
          fill="#A28370"
        />
        <path
          d="M73.9838 113.421C74.2673 111.769 74.7175 111.588 76.1683 110.399C77.619 109.21 77.0854 108.5 77.0854 108.5L73.9838 110.399C73.2814 111.043 72.5467 111.652 71.7827 112.224C69.9652 113.644 69.9402 115.039 69.9402 115.039C69.9402 115.039 69.7484 116.195 69.4566 117.31C69.3321 117.795 69.1761 118.272 68.9897 118.738C68.8563 119.035 67.9475 120.975 67.9475 120.975C67.2471 121.388 71.2825 120.975 71.2825 120.975L72.3914 118.581C72.3914 118.581 72.4497 118.581 72.4747 118.581L73.3919 115.278C73.6994 114.707 73.9026 114.087 73.9922 113.445C74.039 113.165 74.1087 112.889 74.2006 112.62"
          fill="#A28370"
        />
        <path
          d="M69.3902 117.912C69.1883 118.386 68.9572 118.846 68.6982 119.291L56.0252 136.869L52.6902 130.165C52.3413 130.503 51.9385 130.782 51.4979 130.991C50.7559 131.337 49.9138 131.635 49.9138 131.635C49.9138 131.635 49.7304 133.063 49.6053 133.988C49.4886 134.648 49.1801 135.977 49.1801 135.977C49.2574 136.31 49.3692 136.634 49.5136 136.943C49.9638 138.009 50.6642 139.701 50.9727 140.329C51.1311 140.527 51.2812 140.733 51.4396 140.94C54.5161 145.291 57.9095 142.74 57.9095 142.74C57.9095 142.74 60.0689 140.684 62.0782 138.289C63.6379 136.151 65.0315 133.899 66.247 131.552C68.2813 127.325 71.2494 121.008 71.2494 121.008C71.4579 120.373 71.7497 119.448 71.9915 118.598C71.0243 118.565 70.399 117.912 69.3902 117.912Z"
          fill="#A28370"
        />
        <path
          d="M73.5338 155.306C73.1486 155.525 72.7367 155.694 72.3082 155.809C71.8329 155.892 70.7991 156.024 70.4489 156.082C70.007 156.123 69.5709 156.212 69.1483 156.346"
          stroke="#624834"
          stroke-width="0.1"
          stroke-miterlimit="10"
        />
        <path
          d="M72.7335 156.875C72.4775 157.042 72.196 157.167 71.8997 157.246C71.3912 157.362 70.5241 157.56 70.2823 157.593L68.9149 157.775"
          stroke="#624834"
          stroke-width="0.1"
          stroke-miterlimit="10"
        />
        <path
          d="M71.5581 152.358C69.8208 152.513 68.0927 152.755 66.3806 153.085"
          stroke="#624834"
          stroke-width="0.1"
          stroke-miterlimit="10"
        />
      </g>
      <g clip-path="url(#clip8_8926_18508)">
        <path
          d="M21.7969 145.898C21.7969 145.898 66.852 148.968 68.1047 149.065C68.8314 149.061 69.5373 149.312 70.1027 149.776C70.6681 150.24 71.0581 150.888 71.2068 151.611C71.7914 153.599 76.3488 177.723 76.3488 177.723C76.0242 177.723 76.9566 183.577 76.6775 183.577C75.5541 183.577 72.6596 183.577 71.3532 183.577L71.0636 177.723C71.0636 177.723 68.8206 153.951 65.3846 153.951L22.9185 150.565L21.7969 145.898Z"
          fill="#C3CDD5"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_8926_18508"
        x1="147.473"
        y1="-35.6096"
        x2="147.473"
        y2="223.189"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#92CDF3" />
        <stop offset="1" stop-color="#D6ECFA" />
      </linearGradient>
      <clipPath id="clip0_8926_18508">
        <rect
          width="240"
          height="230.171"
          fill="white"
          transform="translate(0.5 0.914673)"
        />
      </clipPath>
      <clipPath id="clip1_8926_18508">
        <rect
          width="215.312"
          height="215.312"
          fill="white"
          transform="translate(39.8174 -4)"
        />
      </clipPath>
      <clipPath id="clip2_8926_18508">
        <rect
          width="465.256"
          height="131.061"
          fill="white"
          transform="translate(-67.4864 22.2116)"
        />
      </clipPath>
      <clipPath id="clip3_8926_18508">
        <rect
          width="245.734"
          height="111.945"
          fill="white"
          transform="translate(90.6024 55.7952)"
        />
      </clipPath>
      <clipPath id="clip4_8926_18508">
        <rect
          width="142.526"
          height="50.0766"
          fill="white"
          transform="translate(120.91 135.249)"
        />
      </clipPath>
      <clipPath id="clip5_8926_18508">
        <rect
          width="78.6348"
          height="117.952"
          fill="white"
          transform="translate(16.8823 113.133)"
        />
      </clipPath>
      <clipPath id="clip6_8926_18508">
        <rect
          width="147.44"
          height="176.928"
          fill="white"
          transform="translate(7.87201 63.9863)"
        />
      </clipPath>
      <clipPath id="clip7_8926_18508">
        <rect
          width="45.8703"
          height="59.7952"
          fill="white"
          transform="translate(7.87201 72.1775)"
        />
      </clipPath>
      <clipPath id="clip8_8926_18508">
        <rect
          width="78.6348"
          height="117.952"
          fill="white"
          transform="translate(16.8823 113.133)"
        />
      </clipPath>
    </defs>
  </svg>
);
