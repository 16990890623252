import { ModalTypes } from './OrderReview';
import { TermsAndConditions } from './TermsAndConditions';
import { OrderDetailsSection } from './OrderDetailsSection';
import { ModemAlert } from '../ModemAlert/ModemAlert';

interface NoticeProps {
  openModal: (e: React.MouseEvent<HTMLElement>, modalType: ModalTypes) => void;
  closeModal: () => void;
  isModalOpen: boolean;
}

export const Notice = ({ openModal, closeModal, isModalOpen }: NoticeProps) => {
  return (
    <OrderDetailsSection id="modem-reboot">
      <ModemAlert />
      <TermsAndConditions
        openModal={openModal}
        closeModal={closeModal}
        isModalOpen={isModalOpen}
      />
    </OrderDetailsSection>
  );
};
