import { Surface, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

const parseCurrency = (currency: string) => {
  switch (currency) {
    case 'BRL':
      return 'R$';
    case 'EURO':
      return '€';
    case 'USD':
    default:
      return '$';
  }
};

export const PlanCardPrice = ({
  price,
  currency,
}: {
  price: string;
  currency: string | undefined;
}) => {
  const { t } = useTranslation('NewChangePlan');

  if (!price || !currency) return null;

  const [integral, decimal] = price.split('.');
  const currencySymbol = parseCurrency(currency);

  return (
    <Surface
      variant="secondary"
      p={'8px'}
      radius="12px"
      data-cy="plan-card-price"
      style={{ textAlign: 'center' }}
    >
      <Txt component="span" data-cy="price-integral" variant={'heading3'}>
        {currencySymbol}
        {integral}
        {decimal && (
          <sup
            data-cy="price-decimal"
            className="beam-font--headerCapsLarge beam-font-color--regular"
          >
            {decimal}
          </sup>
        )}
      </Txt>

      <Txt data-cy="per-month-text" variant={'smallBold'}>
        {t('perMonth')}
      </Txt>
    </Surface>
  );
};
