import React from 'react';
import {
  View,
  TouchableWithoutFeedback,
  StyleSheet,
  TouchableWithoutFeedbackProps,
} from 'react-native';
import { Text } from '../Text';
import { Color } from '../../utils/Color';

const styles = StyleSheet.create({
  firstTab: {
    marginRight: 'auto',
  },
  notFirstTab: {
    marginLeft: 'auto',
  },
  notSelectedText: {
    color: Color.gray400,
  },
  tabContainer: {
    flex: 1,
    paddingBottom: 7,
  },
  selectedTab: {
    paddingTop: 5,
    borderBottomWidth: 4,
    borderBottomColor: Color.teal500,
  },
  notSelectedTab: {
    paddingTop: 5,
    borderBottomWidth: 2,
    borderBottomColor: Color.gray400,
  },
});

interface TabProps extends TouchableWithoutFeedbackProps {
  title: string;
  onPress(): void;
  selected: boolean;
  first?: boolean;
}

const Tab = (_props: TabProps): JSX.Element => {
  const { title, onPress, selected, first, ...otherProps } = _props;
  const textStyles = {
    ...(first ? styles.firstTab : styles.notFirstTab),
    ...(!selected && styles.notSelectedText),
  };
  const tabStyles = {
    ...styles.tabContainer,
    ...(selected ? styles.selectedTab : styles.notSelectedTab),
  };
  return (
    <TouchableWithoutFeedback onPress={onPress} {...otherProps}>
      <View style={tabStyles}>
        <Text caption semiBold style={textStyles} testID={`${title}Button`}>
          {title}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default Tab;
