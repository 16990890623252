import { ApolloLink, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import packageJson from '../../myversion.json';
import { useMemo } from 'react';
import { Platform } from 'react-native';
import { logTimeLink } from '@mfe/services/graphql-client';

export const getErrorHandlerLink = (): ApolloLink =>
  onError(({ graphQLErrors, networkError }): void => {
    if (!__DEV__) return;
    if (networkError) console.log(`[Network error]: ${networkError}`);
    if (!graphQLErrors) return;

    graphQLErrors.forEach(({ message, locations, path }): void => {
      console.warn(
        `[GraphQL error]: Message: ${message},
            Location: ${
              locations
                ? locations.map((loc): string => JSON.stringify(loc)).join(', ')
                : 'unknown'
            },
            Path: ${path ? path.join(', ') : 'unknown'}`
      );
    });
  });

export const getAuthLink = (
  accessToken?: string | null,
  type?: string | null
): ApolloLink =>
  new ApolloLink((operation, forward): any => {
    const context = operation.getContext();
    const headers = {
      ...context['headers'],
      'x-platform': Platform.OS,
      'x-version': packageJson.version,
    };

    if (accessToken) {
      // Add headers for http authentication
      headers['x-auth-token'] = accessToken;
      headers['x-auth-type'] = type;
    }

    operation.setContext({
      ...context,
      headers,
    });

    return forward(operation);
  });

const useLink = (
  uri: string,
  accessToken?: string,
  type?: string | null
): ApolloLink => {
  const authLink = useMemo(
    () => getAuthLink(accessToken, type),
    [accessToken, type]
  );

  const httpLink = useMemo(
    () => new HttpLink({ uri, credentials: 'include' }),
    [uri]
  );

  return ApolloLink.from([
    logTimeLink,
    getErrorHandlerLink(),
    authLink,
    httpLink,
  ]);
};

export default useLink;
