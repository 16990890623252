import { useContext } from 'react';
import { Button, StandAloneLink } from '@vst/beam';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  NoAppointmentsAvailable,
  ScheduleInstallationContext,
} from '@mfe/shared/components';
import { useScreenResolution } from '@mfe/shared/util';
import { submitOrder } from '@mfe/to-be-migrated/redux/changePlan';

import styles from './styles.module.scss';

interface NoAppointmentsProps {
  handleBackNavigation: (e: React.MouseEvent<HTMLElement>) => void;
}
const NoAppointments = ({ handleBackNavigation }: NoAppointmentsProps) => {
  const { t } = useTranslation('NewScheduleInstallation');
  const dispatch = useDispatch();

  const { isLarge, isExtraLarge } = useScreenResolution();
  const isDesktop = isLarge || isExtraLarge;

  const { textInput, phoneNumberInput }: any = useContext(
    ScheduleInstallationContext
  );

  const handleSubmitWithoutAppointment = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    const content = `${textInput} ${phoneNumberInput}` ?? '';
    dispatch(submitOrder({ notes: content }));
  };

  return (
    <div className={styles['no-appointments-container']}>
      <NoAppointmentsAvailable
        description={t('NewChangePlan:scheduleInstallation.errorContent')}
      />
      {isDesktop && (
        <>
          <hr />

          <div className={styles['no-appointments-footer']}>
            <StandAloneLink
              href="#"
              underline={true}
              data-cy="back-button"
              onClick={handleBackNavigation}
            >
              {t('backToPlanReview')}
            </StandAloneLink>

            <Button
              onClick={handleSubmitWithoutAppointment}
              data-cy="no-appointment-place-order"
            >
              {t('contactInformation.placeOrder')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default NoAppointments;
