import React, { useState, Suspense, useCallback, useEffect } from 'react';
import styles from './Profile.module.scss';
import { useScreenResolution, Storage } from '@mfe/shared/util';
import {
  EditableCardType,
  ModalType,
  ModalTypeEnum,
  PageAlert,
  Version,
  wasAlertDismissed,
} from '@mfe/shared/components';
import { Surface } from '@vst/beam';

import ProfileHeader from './components/Header/ProfileHeader';
import NavBar from './components/Body/Navigation/NavBar';
import NavBarDropdown from './components/Body/Navigation/NavBarDropdown';
import {
  resetScrubbedAddress,
  selectScrubbedAddress,
  setShowModal as setShowModalAction,
  setEditingCard as setEditingCardAction,
  setCloseAfterReload as setCloseAfterReloadAction,
} from '@mfe/to-be-migrated/redux/scrubbedAddress';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddressInput,
  ContactMethodLabels,
  AccountType,
  Platform,
} from '@mfe/shared/schema-types';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { getCountryCode, updateAddressRedux } from './utils';
import { clearError, selectError } from '@mfe/to-be-migrated/redux/error';
import SignoutButton from './components/Body/Navigation/SignoutButton';
import {
  AnalyticsAction,
  Categories,
  structuredEvent,
} from '@mfe/to-be-migrated/redux/analytics';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import {
  getDisplayContext,
  getProfileContext,
  ProfileAnalyticsContext,
} from './analytics-utils';
import { setProfilePageStatistics } from '@mfe/to-be-migrated/redux/utils';
import { useTrackTiming } from '@mfe/shared/util';
import { ModalLoading } from '@mfe/shared/components';
import { ProfilePageAlert } from './components/ProfilePageAlert/ProfilePageAlert';
import { InternetPlan } from '@mfe/to-be-migrated/shared-platform-react/pages/Profile/components/Body/Content/InternetPlanTab';
import { selectConfig } from '@mfe/shared/redux/config';
import { selectAlerts } from '@mfe/to-be-migrated/redux/alerts';
import { useTranslation } from 'react-i18next';
import { getHasPendingTransition } from '@mfe/to-be-migrated/redux/changePlan';

const ModalComponent = React.lazy(() => import('./modal'));
const ResidentialAccountBody = React.lazy(
  () =>
    import(
      './components/Body/Content/ResidentialAccountInfoTab/ResidentialAccountInfoBody'
    )
);
const ResidentialContactBody = React.lazy(
  () =>
    import(
      './components/Body/Content/ResidentialContactInfoTab/ResidentialContactBody'
    )
);
const SMBAccountBody = React.lazy(
  () => import('./components/Body/Content/SMBAccountInfoTab/SMBAccountInfoBody')
);
const SMBBusinessBody = React.lazy(
  () =>
    import('./components/Body/Content/SMBBusinessInfoTab/SMBBusinessInfoBody')
);

export enum ProfileTab {
  ResidentialAccount = 'ResidentialAccount',
  ResidentialContact = 'ResidentialContact',
  SMBAccount = 'SMBAccount',
  SMBBusiness = 'SMBBusiness',
  ProfileTab = 'ProfileTab',
  InternetPlan = 'InternetPlan',
}

export interface SetModalProps {
  type: ModalType;
}

const Profile = ({
  navigateToChangePlan,
  navigateToScheduleInstallation,
}: {
  navigateToChangePlan: () => void;
  navigateToScheduleInstallation: () => void;
}): JSX.Element | null => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Error');

  const { isMedium, isSmall, isExtraSmall } = useScreenResolution();
  const showDropdownDesign = isMedium || isSmall || isExtraSmall;

  const setShowModal = useCallback(
    (modalType: ModalType) => {
      dispatch(setShowModalAction(modalType));
    },
    [dispatch]
  );

  const setCloseAfterReload = useCallback(
    (payload: boolean) => {
      dispatch(setCloseAfterReloadAction(payload));
    },
    [dispatch]
  );

  const {
    loading: scrubbedAddressLoading,
    scrubbedAddress,
    rawInput,
    showModal,
    editingCard,
    closeAfterReload,
  } = useSelector(selectScrubbedAddress);

  const { error: scrubbedAddressError } = useSelector(selectError);
  const {
    user: {
      auth: {
        tokenInfo: { type: tokenType },
      },
    },
  } = useSelector(selectUser);

  const {
    userInfo: { accountType },
    loading,
  } = useSelector(selectUserInfo);

  const [currentTab, setCurrentTab] = useState(
    accountType === AccountType.Residential
      ? ProfileTab.ResidentialAccount
      : ProfileTab.SMBAccount
  );
  const [pendingTab, setPendingTab] = useState<ProfileTab | null>(null);
  const [restrictSwitchTabs, setRestrictSwitchTabs] = useState<boolean>(false);
  const [addressTimeout, setAddressTimeout] = useState<boolean>(false);
  const [currentField, setCurrentField] = useState(EditableCardType.None);

  const { platform } = useSelector(selectConfig);

  const isMobileApp =
    platform === Platform.Ios || platform === Platform.Android;

  useEffect(() => {
    setNavigationTab();

    function setNavigationTab() {
      setNavigationTabFromStorage('showInternetPlan', ProfileTab.InternetPlan);
    }

    function setNavigationTabFromStorage(storageKey: string, tab: ProfileTab) {
      const navigatedFrom = Storage.getItem(storageKey);
      if (navigatedFrom) {
        setCurrentTab(tab);
        Storage.removeItem(storageKey);
      }
    }
  }, []);

  const setEditingCard = useCallback(
    (editingCard: EditableCardType) => {
      dispatch(setEditingCardAction(editingCard));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(setProfilePageStatistics());
    dispatch(getHasPendingTransition());
  }, [dispatch]);

  useEffect(() => {
    if (
      accountType === AccountType.Smb &&
      currentTab === ProfileTab.ResidentialAccount
    ) {
      setCurrentTab(ProfileTab.SMBAccount);
    }
    if (
      accountType === AccountType.Residential &&
      currentTab === ProfileTab.SMBAccount
    ) {
      setCurrentTab(ProfileTab.ResidentialAccount);
    }
  }, [accountType, currentTab]);

  useTrackTiming({
    eventName: 'ScrubedAddressLoaded',
    isLoading: scrubbedAddressLoading,
  });

  useEffect(() => {
    const displayContext = getDisplayContext(tokenType);
    const profileContext = getProfileContext(currentField, currentField);
    dispatch(
      structuredEvent({
        category: Categories.Profile,
        action: AnalyticsAction.PAGE_DISPLAYED,
        params: {
          label:
            currentTab === ProfileTab.ResidentialAccount
              ? 'account_information'
              : 'contact_information',
          context: [displayContext, profileContext],
        },
      })
    );
  }, [dispatch, currentTab]);

  useEffect(() => {
    const displayContext = getDisplayContext(tokenType);
    const profileContext = getProfileContext(editingCard, currentField);

    if (editingCard !== EditableCardType.None)
      dispatch(
        structuredEvent({
          category: Categories.Profile,
          action: AnalyticsAction.EDIT_SELECTED,
          params: {
            context: [displayContext, profileContext],
          },
        })
      );

    setCurrentField(editingCard);
  }, [dispatch, editingCard]);

  const onCancel = () => {
    setShowModal(null);
    setPendingTab(null);
    dispatch(resetScrubbedAddress());
    setAddressTimeout(false);
    dispatch(
      structuredEvent({
        category: Categories.Profile,
        action: AnalyticsAction.BUTTON_CLICKED,
        params: {
          property: 'continue',
          context: [
            getDisplayContext(tokenType),
            getProfileContext(editingCard, currentField),
          ],
        },
      })
    );
  };

  const closeOpenCards = () => {
    setShowModal(null);
    setCloseAfterReload(true);
    setEditingCard(EditableCardType.None);
    setRestrictSwitchTabs(false);

    dispatch(
      structuredEvent({
        category: Categories.Profile,
        action: AnalyticsAction.BUTTON_CLICKED,
        params: {
          property: 'cancel',
          context: [
            getDisplayContext(tokenType),
            getProfileContext(editingCard, currentField),
          ],
        },
      })
    );

    pendingTab && setCurrentTab(pendingTab);
  };

  const saveAddress = () => {
    const newScrubbedAddress = scrubbedAddress.rawAddress;
    const newSecondAddressLine = rawInput.addressLines[1];
    const newCountryCode = newScrubbedAddress.country
      ? getCountryCode(newScrubbedAddress.country)
      : 'Unknown';
    const newAddress: AddressInput = {
      addressLines: [newScrubbedAddress.addressLines[0], newSecondAddressLine],
      countryCode: newCountryCode ?? '',
      municipality: newScrubbedAddress.municipality,
      postalCode: newScrubbedAddress?.postalCode ?? '',
      region: newScrubbedAddress?.region ?? '',
      labels:
        editingCard === EditableCardType.Billing
          ? [ContactMethodLabels.Invoice]
          : [ContactMethodLabels.Shipping],
    };
    updateAddressRedux(accountType, dispatch, [newAddress]);
    setEditingCard(EditableCardType.None);
    setRestrictSwitchTabs(false);
    setCloseAfterReload(true);
  };

  const getCurrentTab = (currentTab: ProfileTab): JSX.Element | null => {
    const props = {
      currentTab: currentTab,
      setEditingCard: setEditingCard,
      editingCard: editingCard,
      setShowModal: setShowModal,
      setRestrictSwitchTabs: setRestrictSwitchTabs,
      closeAfterReload: closeAfterReload,
      setCloseAfterReload: setCloseAfterReload,
    };
    switch (currentTab) {
      case ProfileTab.ResidentialAccount:
        return <ResidentialAccountBody {...props} loading={loading} />;
      case ProfileTab.SMBAccount:
        return <SMBAccountBody {...props} />;
      case ProfileTab.ResidentialContact:
        return <ResidentialContactBody {...props} />;
      case ProfileTab.SMBBusiness:
        return <SMBBusinessBody {...props} loading={loading} />;
      case ProfileTab.InternetPlan:
        return (
          <InternetPlan
            navigateToChangePlan={navigateToChangePlan}
            navigateToScheduleInstallation={navigateToScheduleInstallation}
          />
        );
      default:
        return null;
    }
  };

  const getCurrentModal = (modalType: ModalType): JSX.Element | null => {
    let modal;
    if (scrubbedAddressError) {
      setAddressTimeout(
        scrubbedAddressError.operation === 'getScrubbedAddress'
      );
      dispatch(clearError({}));
    }
    switch (modalType) {
      case ModalTypeEnum.Unsaved:
        modal = (
          <Suspense fallback={<ModalLoading onCancel={onCancel} />}>
            <ModalComponent
              type={showModal}
              onCancel={onCancel}
              closeOpenCards={closeOpenCards}
            />
          </Suspense>
        );
        break;
      case ModalTypeEnum.Address:
      case ModalTypeEnum.AddressError:
        modal = scrubbedAddressLoading ? (
          <ModalLoading
            onCancel={onCancel}
            modalText={'scrubAddressLoading'}
            addressTimeout={addressTimeout}
          />
        ) : (
          <Suspense
            fallback={
              <ModalLoading
                onCancel={onCancel}
                modalText={'scrubAddressLoading'}
                addressTimeout={addressTimeout}
              />
            }
          >
            <ModalComponent
              type={showModal}
              onCancel={onCancel}
              extraText={scrubbedAddress.formattedAddress ?? undefined}
              saveAddress={saveAddress}
            />
          </Suspense>
        );
        break;
      default:
        break;
    }
    return modal ?? null;
  };

  const { alerts: allAlerts } = useSelector(selectAlerts);

  const alerts = allAlerts.filter(
    (alert) =>
      alert.type === 'global' ||
      alert.caption === t('Alerts:AccountStatus.error.caption') ||
      alert.caption === t('Alerts:AccountStatus.transitionError.caption')
  );

  return (
    <ProfileAnalyticsContext.Provider
      value={{
        displayContext: getDisplayContext(tokenType),
        profileContext: getProfileContext(editingCard, currentField),
      }}
    >
      <div className={styles['profile']}>
        <div className={styles['headerMargin']}>
          <ProfilePageAlert />

          {alerts && (
            <Surface
              variant="secondary"
              className={styles['appAlertsContainer']}
            >
              {alerts
                .filter((alert) => !wasAlertDismissed(alert))
                .map((alert, i) => (
                  <PageAlert key={i} alert={alert} />
                ))}
            </Surface>
          )}

          <ProfileHeader />
        </div>
        <div className={styles['bodyGrid']}>
          {!showDropdownDesign ? (
            <div className={styles['sideNavContainer']}>
              <NavBar
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                restrictSwitchTabs={restrictSwitchTabs}
                setShowModal={setShowModal}
                setPendingTab={setPendingTab}
              />

              <SignoutButton />
            </div>
          ) : (
            <NavBarDropdown
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              restrictSwitchTabs={restrictSwitchTabs}
              setShowModal={setShowModal}
              setPendingTab={setPendingTab}
            />
          )}
          {/* TODO: Body loading component for fallback; Or these could be page components and next would handle the chunking */}
          <Suspense fallback={null}>
            {getCurrentTab(currentTab)}
            {isMobileApp && (
              <div className={styles['versionContainer']}>
                <Version />
              </div>
            )}
            {showDropdownDesign && <SignoutButton />}
          </Suspense>
        </div>
        {getCurrentModal(showModal)}
      </div>
    </ProfileAnalyticsContext.Provider>
  );
};

export default Profile;
