import styles from './styles.module.scss';

const LinkLoading = () => {
  return (
    <div className={styles['smallLoading']} style={{ boxShadow: 'none' }}>
      <div className={styles['viewStatementLoading']} />
    </div>
  );
};

export default LinkLoading;
