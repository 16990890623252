import { Txt } from '@vst/beam';

import { useTranslation } from 'react-i18next';

import styles from './Card.module.scss';
import React from 'react';

export const CardTitle = () => {
  const { t } = useTranslation('ACP', { keyPrefix: 'Common' });
  return (
    <Txt variant="heading4" color="regular" className={styles['card-title']}>
      {t('title')}
    </Txt>
  );
};

export const Card: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className={styles['wrapper']}>
      <div className={styles['gradient']} />
      <div className={styles['card-layout']}>{children}</div>
    </div>
  );
};
