import * as React from 'react';
import { Txt, Icon, EmptyState, Surface } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { ChevronLeft } from '@vst/beam-icons/icons';
import { useDispatch, useSelector } from 'react-redux';

import {
  Payment,
  PendingPayment,
  PaymentStatus,
} from '@mfe/shared/schema-types';
import {
  fetchPaymentsHistory,
  fetchPendingPayments,
  selectBillingInfo,
} from '@mfe/to-be-migrated/redux/billingInfo';

import { useScreenResolution } from '@mfe/shared/util';
import { useTrackTiming } from '@mfe/shared/util';
import { Route, setBillingNav } from '@mfe/to-be-migrated/redux/billingNav';

import Card from '../shared-ui/card';
import PaymentList from './payment-list';
import Divider from '../shared-ui/divider';
import { ActivityFooter } from '../shared-ui/activity-footer';

import PaymentHistoryLoading from './loading';
import PaymentDetails from './expandable-payment/payment-details';
import PaymentSummary from './expandable-payment/payment-summary';
import { ExpandableList } from './expandable-list';

import { getNumberOfPages, getPageOfT } from '../shared/pagination';

import styles from './styles.module.scss';
import { Alert } from '@vst/beam-icons/illustrative-icons';

const ITEMS_PER_PAGE = 12;

const PaymentHistory = (): JSX.Element => {
  const { t } = useTranslation('BillingPayment');
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = React.useState(1);
  const [showMoreClicked, setShowMoreClicked] = React.useState(true);

  const {
    billingInfo: { payments, pendingPayments },
    errors,
    loading,
    loadingInfo,
  } = useSelector(selectBillingInfo);
  const { isExtraSmall, isSmall } = useScreenResolution();

  const isMobile = isExtraSmall || isSmall;
  const isLoading =
    loading || loadingInfo.payments || loadingInfo.pendingPayments;
  const allPayments = [
    ...(pendingPayments ?? []),
    ...(payments?.filter(
      (payment) => payment.paymentStatus !== PaymentStatus.Requested
    ) ?? []),
  ];

  const showFooter = allPayments?.length > ITEMS_PER_PAGE;
  const paymentsShown = getPageOfT(
    allPayments,
    pageNumber,
    showMoreClicked,
    ITEMS_PER_PAGE
  );
  const numPages = getNumberOfPages(allPayments, ITEMS_PER_PAGE);

  React.useEffect(() => {
    dispatch(fetchPaymentsHistory());
    dispatch(fetchPendingPayments());
  }, [dispatch]);

  useTrackTiming({ eventName: 'PaymentHistoryLoaded', isLoading });

  if (errors) {
    return (
      <PaymentHistoryWrapper>
        <ErrorMessage />
      </PaymentHistoryWrapper>
    );
  }

  return (
    <PaymentHistoryWrapper>
      <Card dataCy="billingPayment" className={styles['payments']}>
        <Txt id="title" variant="heading5" p="24px">
          {t('title')}
        </Txt>

        {isMobile ? (
          <SmallScreenLayout payments={paymentsShown} loading={isLoading} />
        ) : (
          <LargeScreenLayout payments={paymentsShown} loading={isLoading} />
        )}

        {showFooter && (
          <div className={styles['footerContainer']}>
            <ActivityFooter
              showMoreClicked={showMoreClicked}
              setShowMoreClicked={setShowMoreClicked}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              totalNumPages={numPages}
            />
          </div>
        )}
      </Card>
    </PaymentHistoryWrapper>
  );
};

const ErrorMessage = () => {
  const { t } = useTranslation('UnexpectedError');
  const { t: billingT } = useTranslation('BillingPayment');
  const goBack = useGoBack();

  return (
    <div
      data-cy="payments-history-error"
      className={styles['paymentsHistoryErrorWrapper']}
    >
      <Surface radius="8px" py="64px">
        <EmptyState
          icon={Alert}
          title={t('title')}
          description={t('description')}
          theme={'light'}
          actions={{
            primary: {
              label: billingT('back'),
              onClick: goBack,
            },
          }}
        />
      </Surface>
    </div>
  );
};

const useGoBack = () => {
  const dispatch = useDispatch();

  return React.useCallback(() => {
    dispatch(
      setBillingNav({
        route: Route.Home,
      })
    );
  }, [dispatch]);
};

const PaymentHistoryWrapper: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;
  const { t } = useTranslation('BillingPayment');
  const goBack = useGoBack();

  return (
    <div data-cy="paymentHistory" className={styles['pageContainer']}>
      <div className={styles['container']}>
        <button className={styles['backButton']} onClick={goBack}>
          <Icon icon={ChevronLeft} color="gray_1000" />
          <Txt variant="labelLarge">{t('back')}</Txt>
        </button>
        {children}
      </div>
    </div>
  );
};

type Props = {
  payments: (Payment | PendingPayment)[];
  loading: boolean;
};

const LargeScreenLayout = ({ payments, loading }: Props): JSX.Element => {
  const { t } = useTranslation('BillingPayment');

  return (
    <>
      <div id="payments" className={styles['paymentsGrid']}>
        <PaymentHeader />

        {!payments.length && loading && <PaymentHistoryLoading />}

        <PaymentList payments={payments} />
      </div>

      {!payments.length && !loading && (
        <div className={styles['unavailable']}>
          <Txt id="noPaymentItems" variant="bodySmallRegular">
            {t('noPayments')}
          </Txt>
        </div>
      )}
    </>
  );
};

const SmallScreenLayout = ({ payments, loading }: Props): JSX.Element => {
  const content =
    payments.map((payment) => {
      return {
        visibleContent: <PaymentSummary payment={payment} />,
        hiddenContent: <PaymentDetails payment={payment} />,
      };
    }) ?? [];

  return (
    <>
      <Divider />
      {!payments.length && loading && <PaymentHistoryLoading />}

      <div id="payments" className={styles['paymentsContainer']}>
        <ExpandableList
          isExpandable={true}
          items={content}
          maxExpandedHeight="500px"
          listStyle={{ width: '100%' }}
          payments
        />
      </div>
    </>
  );
};

const PaymentHeader = (): JSX.Element => {
  const { t } = useTranslation('BillingPayment');

  return (
    <>
      <PaymentHeaderItem text={t('date')} />
      <PaymentHeaderItem text={t('amount')} />
      <PaymentHeaderItem text={t('paymentType')} />
      <PaymentHeaderItem text={t('method')} />
      <PaymentHeaderItem text={t('paymentStatus')} />
    </>
  );
};

const PaymentHeaderItem = ({ text }: { text: string }): JSX.Element => {
  return (
    <div className={styles['gridHeader']}>
      <Txt variant="labelMedium" component="span">
        {text}
      </Txt>
    </div>
  );
};

export default PaymentHistory;
