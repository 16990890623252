import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Accordion,
  AccordionGroup,
  Button,
  StandAloneLink,
  Txt,
} from '@vst/beam';

import {
  AccountType,
  Locale,
  ProductInstanceStatus,
} from '@mfe/shared/schema-types';
import useNavigate from '@mfe/services/navigation';
import { HelpLink } from '@mfe/legacy/mv/utils/Navigation';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';

import styles from './Overview.module.scss';

type Link = {
  label: string;
  url: string;
  SMBurl: string;
};

type Question = {
  header: string;
  description: string[];
  links?: Link[];
};

export const OverviewCommonQuestions = () => {
  const dispatch = useDispatch();
  const { goTo } = useNavigate();
  const { t } = useTranslation(['CommonQuestionsUpdated', 'Global']);

  const {
    loading,
    userInfo: { accountType, isPreInstall },
  } = useSelector(selectUserInfo);

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  // For Italy, there was different content between preinstall and active
  const translationsAccountStatus =
    isPreInstall && userLocale === Locale.ItIt
      ? ProductInstanceStatus.Preinstall
      : ProductInstanceStatus.Active;

  const translationsLocale =
    userLocale === Locale.PtBr ? Locale.EnUs : userLocale;

  // For US, displayed questions are the same for residential and SMB
  const questions: Question[] = t(
    `CommonQuestionsUpdated:Overview.${translationsLocale}.${translationsAccountStatus}`,
    { returnObjects: true }
  );

  const navigateToFaq = (e: React.MouseEvent<HTMLElement>) => {
    const url = t('Global:SMBSupportLink');

    accountType === AccountType.Smb
      ? openLinkInNewTab(e, url)
      : goTo(HelpLink.Main, { section: 'All' });
  };

  const openLinkInNewTab = (e: React.MouseEvent<HTMLElement>, url: string) => {
    e.preventDefault();
    dispatch(openInNewTab(url));
  };

  return (
    <div className={styles['commonQuestionsMargin']}>
      <Txt variant="heading5" mb="16px">
        {t('CommonQuestionsUpdated:title')}
      </Txt>
      {loading ? (
        <QuestionsLoading />
      ) : (
        <AccordionGroup singleExpanded={true} size="small">
          {questions.map(({ header, description, links }, questionKey) => (
            <Accordion title={header} key={questionKey}>
              <div className={styles['accordionDescription']}>
                {description?.map((line: string, descriptionKey) => (
                  <Txt variant="bodySmallRegular" key={descriptionKey}>
                    {line}
                  </Txt>
                ))}
                {links &&
                  links?.map(({ label, url, SMBurl }, linkKey) => {
                    const link =
                      accountType === AccountType.Residential ? url : SMBurl;
                    return (
                      <StandAloneLink
                        href={link}
                        key={linkKey}
                        onClick={(e) => openLinkInNewTab(e, link)}
                      >
                        {label}
                      </StandAloneLink>
                    );
                  })}
              </div>
            </Accordion>
          ))}
        </AccordionGroup>
      )}
      {userLocale === Locale.EnUs && (
        <Button
          fluid
          variant="secondary"
          mt="16px"
          onClick={(e) => navigateToFaq(e)}
        >
          {t('CommonQuestionsUpdated:viewFaqs')}
        </Button>
      )}
    </div>
  );
};

const QuestionsLoading = () => {
  return (
    <div className={styles['loadingContainer']}>
      <div className={styles['loadingCell']} />
      <div className={styles['loadingCell']} />
      <div className={styles['loadingCell']} />
    </div>
  );
};
