import { useTranslation } from 'react-i18next';

import { Surface, Tooltip, Txt, colors } from '@vst/beam';
import { AddOutlined, InfoOutline } from '@vst/beam-icons/icons';

import { hasOfficeHoursTitleAddOns } from '@mfe/shared/util';

import styles from './TitleAddOns.module.scss';

export const TitleAddOns = ({
  characteristic,
  isCartTitleAddOns,
}: {
  characteristic: string;
  isCartTitleAddOns?: boolean;
}) => {
  const { t } = useTranslation('Global');

  const showTooltip = hasOfficeHoursTitleAddOns(characteristic);

  const containerWithIcon = isCartTitleAddOns
    ? styles['title-add-on-with-icon']
    : undefined;

  const className = isCartTitleAddOns
    ? styles['on-cart-container']
    : styles['on-card-container'];

  const tooltipIconColor = isCartTitleAddOns
    ? colors['gray'][600]
    : colors['gray'][500];

  return (
    <Surface className={containerWithIcon} data-cy="title-add-ons">
      {isCartTitleAddOns && (
        <AddOutlined
          data-cy="title-add-ons-icon"
          style={{ color: colors['teal'][600] }}
          width={20}
          height={20}
        />
      )}
      <Surface className={className}>
        <Txt
          variant="smallRegular"
          component="span"
          style={{ color: colors['teal'][600] }}
        >
          {characteristic}
        </Txt>
        {showTooltip && (
          <Tooltip
            data-cy="tooltip-text"
            bodyText={t('Global:officeHours_titleAddOns_tooltip')}
            beakPosition="end"
            style={{ width: 240, textAlign: 'left' }}
            enableAutoPositioning
          >
            <InfoOutline
              data-cy="tooltip-icon"
              style={{ width: 16, height: 16, color: tooltipIconColor }}
            />
          </Tooltip>
        )}
      </Surface>
    </Surface>
  );
};
