import { Trans } from 'react-i18next';

import { Txt } from '@vst/beam';

import { BillingAccount, Locale } from '@mfe/shared/schema-types';

import { getBillingContext } from './utils';

type Props = {
  productInstanceStatus?: string | null;
  billingAccount?: BillingAccount | null;
  locale?: Locale | null;
};

export const BillingSummaryHeader = ({
  productInstanceStatus,
  billingAccount,
  locale,
}: Props) => {
  return (
    <Txt id="header" variant="bodyLargeRegular">
      <Trans
        ns="Summary"
        i18nKey="header"
        values={getBillingContext(
          productInstanceStatus,
          billingAccount,
          locale
        )}
        components={{ Bold: <Bold /> }}
      />
    </Txt>
  );
};

const Bold: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <Txt
    component="span"
    style={{ display: 'inline', fontWeight: 'bold', whiteSpace: 'nowrap' }}
  >
    {children}
  </Txt>
);
