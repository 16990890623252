import React from 'react';
import WrapWithMobileStackNav from '../../services/WrapWithMobileStackNav';
import { HelpLink } from '@mfe/legacy/mv/utils/Navigation';
import { Faq } from '@mfe/legacy/mv/views/FAQ';

import { RouteRenderProps, NestedRoutes } from '../utils';
import LiveChat from '../../views/ContactUs';

export const helpRoutes: RouteRenderProps[] = [
  {
    path: HelpLink.Main,
    render: (
      <WrapWithMobileStackNav navTranslationKey="Faq">
        <Faq />
      </WrapWithMobileStackNav>
    ),
    exact: true,
    fromParent: 0,
  },
  {
    path: HelpLink.AgentChat,
    render: (
      <WrapWithMobileStackNav navTranslationKey="ChatHeader">
        <LiveChat />
      </WrapWithMobileStackNav>
    ),
    exact: true,
    fromParent: 1,
  },
];

export const HelpSwitch = (): JSX.Element => (
  <NestedRoutes routes={helpRoutes} />
);
