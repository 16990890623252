enum AppColors {
  gray004655 = '#004655',
  gray747474 = '#747474',
  white = '#FFFFFF',
  whiteEDF5F9 = '#EDF5F9',
  blue008CC9 = '#008CC9',
  darkGray = '#1F2E39',
  darkGray32424E = '#32424e',
  lightGray = '#415463',
  teal = '#3EA1A1',
  borderGray = '#D8D8D8',
  iconGray = '#C8C8C8',
  iconHoverGray = '#A8A8A8',
  gray979797 = '#979797',
  pink = '#FF00DF',
  gray465967 = '#465967',
  lightBlue = '#CCF0FF',
  teal0AA5AB = '#0AA5AB',
}

export default AppColors;
