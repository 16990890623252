import React, { useState } from 'react';
import {
  View,
  StyleSheet,
  ViewStyle,
  StyleProp,
  TouchableWithoutFeedback,
  TextStyle,
} from 'react-native';
import { Color } from '../../utils/Color';
import { Text } from '../Text';
import CheckboxSVG from './CheckboxSVG';

const styles = StyleSheet.create({
  hover: {
    backgroundColor: Color.gray200,
  },
  checkbox: {
    width: 24,
    height: 24,
    borderRadius: 3,
    backgroundColor: Color.white,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  borderAll: {
    borderStyle: 'solid',
    borderWidth: 1,
  },
  borderHoverFocus: {
    borderColor: Color.teal500,
  },
  borderDefault: {
    borderColor: Color.gray400,
  },
  borderDefaultDisabled: {
    borderColor: Color.gray200,
  },
  hoverContainer: {
    borderRadius: 6,
    width: 36,
    height: 36,
    zIndex: -1,
    position: 'relative',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  checked: {
    color: Color.teal500,
  },
  defaultDisabled: {
    color: Color.teal500,
  },
  selectedDisabled: {
    backgroundColor: Color.gray200,
  },
  borderError: {
    borderColor: Color.red500,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    marginLeft: 4,
    textAlign: 'center',
  },
  disabledText: {
    color: Color.gray400,
  },
  message: {
    color: Color.red500,
    marginLeft: 41,
    marginTop: -10,
  },
});

export interface CheckboxProps {
  onChange?(): void;
  value: boolean;
  disabled?: boolean;
  error?: boolean;
  testID?: string;
  style?: StyleProp<ViewStyle>;
  label?: string;
  message?: string;
}

export const Checkbox = ({
  onChange,
  value = false,
  disabled = false,
  error = false,
  testID,
  style,
  label,
  message,
}: CheckboxProps) => {
  const [hover, setHover] = useState(false);

  const hoverProps = {
    onMouseEnter: (): void => setHover(true),
    onMouseLeave: (): void => setHover(false),
  };
  let borderStyle: ViewStyle = styles.borderDefault;
  if (error) {
    borderStyle = styles.borderError;
  } else if (hover) {
    borderStyle = styles.borderHoverFocus;
  } else if (disabled) {
    borderStyle = styles.borderDefaultDisabled;
  }

  const hoverStyle: ViewStyle = hover && !disabled ? styles.hover : {};
  const checkColorStyle = disabled ? Color.gray200 : Color.teal500;
  const disabledTextStyle: TextStyle =
    disabled && !value ? styles.disabledText : {};

  return (
    <View style={style} testID={testID}>
      <View style={styles.row}>
        <View style={[styles.hoverContainer, hoverStyle]}>
          <TouchableWithoutFeedback
            disabled={disabled}
            onPress={onChange}
            {...hoverProps}
          >
            <View style={[styles.checkbox, styles.borderAll, borderStyle]}>
              {value && <CheckboxSVG color={checkColorStyle} />}
            </View>
          </TouchableWithoutFeedback>
        </View>
        <Text
          testID={`${testID}-Label`}
          small
          style={[styles.text, disabledTextStyle]}
        >
          {label}
        </Text>
      </View>
      {error && message && (
        <Text testID={`${testID}-Message`} caption style={styles.message}>
          {message}
        </Text>
      )}
    </View>
  );
};
