import { NetworkLink } from '@mfe/legacy/mv/utils/Navigation';
import { NestedRoutes, RouteRenderProps } from '../utils';
import {
  Network,
  DiagnosticResults,
  ModemRebooting,
} from '@mfe/features/network';

export const networkRoutes: RouteRenderProps[] = [
  {
    path: NetworkLink.Network,
    render: <Network />,
    exact: true,
    fromParent: 0,
  },
  {
    path: NetworkLink.DiagnosticResults,
    render: <DiagnosticResults />,
    exact: true,
    fromParent: 0,
  },
  {
    path: NetworkLink.Reboot,
    render: <ModemRebooting />,
    exact: true,
    fromParent: 1,
  },
];

export const NetworkSwitch = (): JSX.Element => {
  return <NestedRoutes routes={networkRoutes} />;
};
