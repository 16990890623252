import React from 'react';
import { useTranslation } from 'react-i18next';

import { Surface, Button, Txt } from '@vst/beam';

import DeskLaptop from '../../assets/DeskLaptop';

import styles from './NoPlansAvailable.module.scss';

interface NoPlansAvailableProps {
  handleGoBack: (e: React.MouseEvent<HTMLElement>) => void;
}

export const NoPlansAvailable = ({ handleGoBack }: NoPlansAvailableProps) => {
  const { t } = useTranslation('NewChangePlan');

  return (
    <Surface
      variant="secondary"
      className={styles['noPlansPage']}
      py={{ xs: '96px', md: undefined }}
      px={{ xs: '16px', md: '32px' }}
    >
      <DeskLaptop data-cy="change-plan-page-error-svg" />

      <div>
        <Txt
          data-cy="no-plans-available-page-title"
          variant="heading4"
          mb="12px"
        >
          {t('noPlansAvailablePage.title')}
        </Txt>
        <Txt
          data-cy="no-plans-available-page-description"
          component="p"
          variant="bodyLargeRegular"
          color="subtle"
        >
          {t('noPlansAvailablePage.description')}
        </Txt>
      </div>

      <div className={styles['button']}>
        <Button
          fluid
          data-cy="no-plans-available-go-back"
          variant="primary"
          onClick={handleGoBack}
        >
          {t('goBack')}
        </Button>
      </div>
    </Surface>
  );
};
