import React from 'react';
import { StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { BaseCard, BaseCardType } from '../BaseCard';
import { Color } from '../../../utils/Color';

export class GrayCard extends React.Component<BaseCardType> {
  private root: BaseCard | undefined | null;

  setNativeProps(args: any) {
    return this.root && this.root.setNativeProps(args);
  }

  override render() {
    const { removeShadow = true, style: styleProps, ...props } = this.props;
    const style: StyleProp<ViewStyle> = [styles.default];
    if (styleProps) style.push(styleProps);

    return (
      <BaseCard
        ref={(c) => {
          this.root = c;
        }}
        removeShadow={removeShadow}
        {...props}
      />
    );
  }
}

const styles = StyleSheet.create({
  default: {
    backgroundColor: Color.gray100,
  },
});
