import React from 'react';

const ViasatVoice = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.4655 31.6027L31.1162 28.9545C31.2487 28.6896 31.3371 28.4689 31.4254 28.2041L27.9795 31.6469C28.112 31.691 28.2887 31.6469 28.4655 31.6027Z"
      fill="#202E39"
    />
    <path
      d="M26.3891 31.5147L31.1603 26.7478L30.9394 26.5712L26.0356 31.4705C26.1682 31.4705 26.2565 31.5147 26.3891 31.5147Z"
      fill="#202E39"
    />
    <path
      d="M24.622 31.1614L29.9676 25.8207L29.7467 25.6441L24.2686 31.0731C24.3569 31.1172 24.4894 31.1172 24.622 31.1614Z"
      fill="#202E39"
    />
    <path
      d="M22.8991 30.72L25.3289 28.2924C25.1964 28.2924 25.0638 28.2924 24.9313 28.2482L22.5898 30.5876C22.6782 30.6317 22.8107 30.6758 22.8991 30.72Z"
      fill="#202E39"
    />
    <path
      d="M21.3085 30.1464L23.4733 27.9836C23.3849 27.9395 23.2524 27.9395 23.164 27.8954L21.0435 30.014C21.1318 30.0581 21.2202 30.1023 21.3085 30.1464Z"
      fill="#202E39"
    />
    <path
      d="M19.8505 29.4841L21.8385 27.4979C21.7502 27.4538 21.6177 27.4096 21.5293 27.3655L19.5854 29.3076C19.6738 29.3958 19.7622 29.44 19.8505 29.4841Z"
      fill="#202E39"
    />
    <path
      d="M18.4369 28.7335L20.2924 26.8797C20.2041 26.8356 20.1157 26.7915 19.9832 26.7473L18.1719 28.557C18.2602 28.6453 18.3486 28.6894 18.4369 28.7335Z"
      fill="#202E39"
    />
    <path
      d="M17.1117 27.9393L18.8789 26.1738C18.7905 26.1296 18.7022 26.0855 18.6138 26.0414L16.8467 27.8069C16.935 27.8069 17.0234 27.851 17.1117 27.9393Z"
      fill="#202E39"
    />
    <path
      d="M15.8305 27.0565L17.5093 25.3793C17.4209 25.3351 17.3326 25.291 17.2442 25.2027L15.5654 26.88C15.6538 26.9241 15.7421 27.0124 15.8305 27.0565Z"
      fill="#202E39"
    />
    <path
      d="M14.5937 26.1738L16.2283 24.5407C16.1399 24.4965 16.0516 24.4083 15.9632 24.3641L14.3286 25.9972C14.417 26.0414 14.5053 26.0855 14.5937 26.1738Z"
      fill="#202E39"
    />
    <path
      d="M13.4006 25.2025L14.991 23.6135C14.9026 23.5694 14.8143 23.4811 14.7701 23.437L13.1797 25.026C13.2239 25.0701 13.3122 25.1584 13.4006 25.2025Z"
      fill="#202E39"
    />
    <path
      d="M12.2077 24.2317L13.754 22.6869C13.6656 22.6427 13.6214 22.5545 13.5331 22.5103L11.9868 24.011C12.0752 24.0993 12.1635 24.1434 12.2077 24.2317Z"
      fill="#202E39"
    />
    <path
      d="M11.1032 23.2165L12.6495 21.6717C12.5611 21.5834 12.5169 21.5393 12.4286 21.4951L10.8823 23.04C10.9265 23.0841 11.0149 23.1724 11.1032 23.2165Z"
      fill="#202E39"
    />
    <path
      d="M9.95455 22.2016L11.5008 20.6568C11.4566 20.6126 11.3683 20.5243 11.3241 20.4802L9.77783 21.9809C9.82201 22.0692 9.91037 22.1133 9.95455 22.2016Z"
      fill="#202E39"
    />
    <path
      d="M8.89423 21.1418L10.4847 19.5528C10.3963 19.4646 10.3521 19.4204 10.3079 19.3322L8.67334 20.9211C8.7617 21.0094 8.80588 21.0535 8.89423 21.1418Z"
      fill="#202E39"
    />
    <path
      d="M7.83417 20.0386L9.46877 18.4055C9.42459 18.3172 9.33624 18.2731 9.29206 18.1848L7.61328 19.862C7.70164 19.9062 7.74582 19.9945 7.83417 20.0386Z"
      fill="#202E39"
    />
    <path
      d="M6.81783 18.9351L8.54079 17.2138C8.49661 17.1255 8.40825 17.0372 8.36407 16.9931L6.64111 18.7145C6.68529 18.7586 6.77365 18.8469 6.81783 18.9351Z"
      fill="#202E39"
    />
    <path
      d="M5.84638 17.7434L7.61352 15.9779C7.56934 15.8896 7.48098 15.8013 7.4368 15.7131L5.62549 17.5227C5.71385 17.5669 5.8022 17.6551 5.84638 17.7434Z"
      fill="#202E39"
    />
    <path
      d="M4.91841 16.5076L6.77391 14.6538C6.72973 14.5655 6.68555 14.4772 6.59719 14.3889L4.7417 16.2427C4.83006 16.331 4.87423 16.4193 4.91841 16.5076Z"
      fill="#202E39"
    />
    <path
      d="M4.07882 15.2276L6.02267 13.2855C5.9785 13.1972 5.93432 13.1089 5.89014 13.0207L3.94629 14.9627C3.94629 15.051 4.03465 15.1393 4.07882 15.2276Z"
      fill="#202E39"
    />
    <path
      d="M3.2839 13.9034L5.31611 11.8731C5.27193 11.7848 5.22775 11.6965 5.18357 11.5641L3.15137 13.5944C3.15137 13.7269 3.23972 13.8151 3.2839 13.9034Z"
      fill="#202E39"
    />
    <path
      d="M2.53293 12.4908L4.65349 10.3722C4.60931 10.2839 4.56513 10.1515 4.56513 10.0632L2.40039 12.2259C2.44457 12.2701 2.48875 12.4025 2.53293 12.4908Z"
      fill="#202E39"
    />
    <path
      d="M1.86984 10.9903L4.12294 8.73929C4.07876 8.60687 4.07876 8.5186 4.03458 8.38618L1.7373 10.6814C1.82566 10.7696 1.82566 10.902 1.86984 10.9903Z"
      fill="#202E39"
    />
    <path
      d="M1.33982 9.40136L3.85799 6.88549C3.85799 6.75308 3.85799 6.62066 3.85799 6.48825L1.25146 9.09239C1.25146 9.18067 1.29564 9.31308 1.33982 9.40136Z"
      fill="#202E39"
    />
    <path
      d="M0.898182 7.67997L6.33213 2.25101L6.15541 2.03032L0.854004 7.32687C0.854004 7.45928 0.854004 7.54756 0.898182 7.67997Z"
      fill="#202E39"
    />
    <path
      d="M0.677246 5.78204L5.40433 1.05928L5.22762 0.838593L0.677246 5.3848C0.677246 5.51721 0.677246 5.64963 0.677246 5.78204Z"
      fill="#202E39"
    />
    <path
      d="M2.44419 1.41238L1.11884 2.73652C1.03048 2.91307 0.942128 3.13376 0.897949 3.39859L3.15105 1.14755L2.57673 1.36824C2.53255 1.41238 2.48837 1.41238 2.44419 1.41238Z"
      fill="#202E39"
    />
    <path
      d="M18.6579 17.6552C18.437 15.4041 16.6257 13.5945 14.3726 13.3738L14.4609 12.7117C16.9791 12.9766 19.0555 15.0069 19.3205 17.5669L18.6579 17.6552Z"
      fill="#202E39"
    />
    <path
      d="M22.9873 17.611C22.7223 12.9765 19.0113 9.3131 14.3726 9.04827L14.4167 8.3862C19.3647 8.65103 23.3408 12.6234 23.65 17.611H22.9873Z"
      fill="#202E39"
    />
    <path
      d="M27.1401 17.611C26.8751 10.7255 21.2644 5.12 14.3726 4.85517L14.4167 4.1931C21.662 4.45793 27.5377 10.3724 27.8028 17.5669L27.1401 17.611Z"
      fill="#202E39"
    />
    <path
      d="M27.6263 32C27.4938 32 27.4054 32 27.3613 32C27.3171 32 27.3171 32 27.2729 32C22.4575 32 15.168 28.1159 9.51319 22.4662C3.94671 16.8607 -0.0293521 9.40138 0.0148264 4.63448C-0.0293521 4.32552 -0.161887 2.16276 2.88642 0.750345L4.91863 0L11.6337 8.73931L9.60154 11.3876C8.80633 12.3145 8.71797 13.6386 9.46901 14.6097C10.7944 16.5959 15.3447 21.1421 17.3328 22.4662C18.3047 23.1724 19.63 23.1283 20.5578 22.3338L23.2527 20.3034L32 27.1007L31.249 29.0869C30.0561 31.691 28.289 32 27.6263 32ZM27.4054 31.2938C27.4938 31.2938 29.3493 31.5586 30.6305 28.8221L31.2048 27.3214L23.2527 21.1862L20.9996 22.9076C19.8509 23.8786 18.2163 23.9669 16.9793 23.0841C14.903 21.6717 10.3526 17.1255 8.93887 15.051C8.0553 13.8152 8.09948 12.1821 9.11558 10.9903L10.8385 8.73931L4.74192 0.794482L3.15149 1.36828C0.412432 2.64828 0.677503 4.50207 0.677503 4.54621V4.59034C0.633324 9.22483 4.52102 16.5076 9.99915 21.9807C15.4331 27.4097 22.6784 31.3379 27.2729 31.3379C27.3171 31.2938 27.3613 31.2938 27.4054 31.2938Z"
      fill="#202E39"
    />
  </svg>
);
export default ViasatVoice;
