import styles from './NavBar.module.scss';
import { Icon, Txt } from '@vst/beam';
import { IconComponentType } from '@vst/beam/src/react/Icon/Icon';

interface NavBarItemProps {
  id: string;
  isActiveTab: boolean;
  onClick: () => void;
  icon: IconComponentType;
  label: string;
}

export const NavBarItem = (props: NavBarItemProps): JSX.Element | null => {
  const { id, isActiveTab, onClick, icon, label } = props;
  return (
    <button
      key={id}
      data-cy={`profile-nav-${id}`}
      className={isActiveTab ? styles['buttonOn'] : styles['buttonOff']}
      style={{ marginBottom: '4px' }}
      onClick={onClick}
    >
      <div className={styles['buttonPadding']}>
        <div className={styles['label']}>
          <Icon icon={icon} color={isActiveTab ? 'teal_600' : 'gray_600'} />
          <Txt
            variant={isActiveTab ? 'labelLarge' : 'bodyLargeRegular'}
            ml="8px"
            color={isActiveTab ? 'regular' : 'subtle'}
          >
            {label}
          </Txt>
        </div>
      </div>
    </button>
  );
};
