const IllustrationBrazilBill = () => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M101.547 182.447C147.816 182.447 185.324 144.939 185.324 98.6701C185.324 52.4013 147.816 14.8931 101.547 14.8931C55.2783 14.8931 17.77 52.4013 17.77 98.6701C17.77 144.939 55.2783 182.447 101.547 182.447Z"
        fill="#518FFB"
      />
      <path
        d="M157.694 26.2785L57.0467 30.335C52.9962 30.4983 49.845 33.9142 50.0082 37.9647L55.6547 178.062C55.818 182.112 59.2339 185.264 63.2844 185.1L163.932 181.044C167.982 180.881 171.133 177.465 170.97 173.414L165.324 33.317C165.16 29.2665 161.744 26.1153 157.694 26.2785Z"
        fill="#919395"
      />
      <path
        d="M157.017 25.18L56.2962 23.902C52.2427 23.8505 48.9151 27.0948 48.8637 31.1482L47.0846 171.348C47.0332 175.401 50.2775 178.729 54.3309 178.78L155.052 180.059C159.105 180.11 162.433 176.866 162.484 172.812L164.263 32.6126C164.315 28.5591 161.071 25.2315 157.017 25.18Z"
        fill="#CFD1D2"
      />
      <path
        d="M155.221 21.5878L54.5961 17.0115C50.5465 16.8273 47.1144 19.9608 46.9302 24.0104L40.56 164.077C40.3759 168.126 43.5094 171.558 47.559 171.743L148.184 176.319C152.234 176.503 155.666 173.37 155.85 169.32L162.22 29.2537C162.404 25.2042 159.271 21.772 155.221 21.5878Z"
        fill="#F1F4F7"
      />
      <path
        d="M66.881 29.44C66.875 29.582 66.792 29.658 66.633 29.651L61.577 29.421L61.427 32.718L65.817 32.918C65.96 32.924 66.035 33.007 66.028 33.166L65.97 34.434C65.963 34.593 65.88 34.668 65.737 34.662L61.347 34.462L61.152 38.758C61.145 38.917 61.062 38.992 60.919 38.986L59.461 38.92C59.318 38.914 59.243 38.831 59.25 38.672L59.744 27.815C59.751 27.656 59.834 27.581 59.977 27.587L66.713 27.893C66.872 27.9 66.947 27.983 66.94 28.142L66.881 29.442V29.44Z"
        fill="#CFD1D2"
      />
      <path
        d="M70.84 31.0181C72.806 31.1071 74.092 32.1501 73.991 34.3851L73.78 39.0131C73.774 39.1401 73.707 39.2331 73.593 39.2751C72.757 39.4911 71.558 39.6591 70.385 39.6061C68.546 39.5221 66.881 38.7791 66.971 36.7971C67.05 35.0701 68.529 34.3271 70.558 34.4191C71.081 34.4431 71.665 34.5331 72.137 34.6181L72.151 34.3171C72.202 33.1911 71.812 32.6971 70.639 32.6431C69.847 32.6071 69.108 32.7801 68.587 33.0581C68.473 33.1321 68.346 33.1101 68.273 32.9801L67.683 31.9841C67.609 31.8541 67.613 31.7741 67.695 31.7151C68.48 31.2261 69.556 30.9581 70.84 31.0161V31.0181ZM70.459 38.0051C70.982 38.0291 71.589 37.9611 71.989 37.8851L72.071 36.0931C71.646 36.0111 71.031 35.9191 70.539 35.8961C69.351 35.8421 68.857 36.2171 68.827 36.8671C68.793 37.6121 69.492 37.9611 70.459 38.0051Z"
        fill="#CFD1D2"
      />
      <path
        d="M78.7639 38.303C78.9069 38.309 79.1609 38.306 79.4149 38.301C79.5739 38.308 79.6649 38.392 79.6589 38.535L79.6039 39.74C79.5979 39.867 79.5299 39.975 79.3709 39.983C79.1009 39.986 78.7669 39.988 78.5289 39.977C76.8809 39.902 75.6349 39.003 75.7299 36.911L76.0589 29.668C76.0659 29.525 76.1479 29.449 76.3069 29.456L77.5109 29.511C77.6379 29.517 77.7289 29.6 77.7389 29.729L77.7949 31.637L79.4909 31.714C79.6339 31.72 79.7249 31.804 79.7189 31.947L79.6659 33.104C79.6589 33.247 79.5609 33.322 79.4179 33.316L77.7379 33.24L77.5729 36.869C77.5329 37.741 77.9539 38.268 78.7619 38.305L78.7639 38.303Z"
        fill="#CFD1D2"
      />
      <path
        d="M87.8959 31.9839C88.0389 31.9899 88.1299 32.0739 88.1239 32.2159L87.7939 39.4759C87.7869 39.6189 87.7199 39.7109 87.6059 39.7529C86.6849 40.1079 85.5799 40.2969 84.4709 40.2459C82.3149 40.1479 80.8879 39.0499 81.0029 36.5139L81.2129 31.9009C81.2189 31.7589 81.3019 31.6829 81.4609 31.6899L82.8399 31.7529C82.9829 31.7589 83.0579 31.8419 83.0519 31.9849L82.8439 36.5659C82.7759 38.0559 83.4209 38.5459 84.5459 38.5969C85.0689 38.6209 85.6269 38.5819 85.9969 38.4719L86.2849 32.1319C86.2909 31.9899 86.3739 31.9139 86.5169 31.9209L87.8959 31.9839Z"
        fill="#CFD1D2"
      />
      <path
        d="M89.96 40.3051C89.801 40.2981 89.726 40.2151 89.733 40.0561L90.065 32.7651C90.071 32.6381 90.123 32.5461 90.252 32.5041C91.078 32.1441 92.086 31.9841 93.053 32.0281C93.544 32.0501 94.066 32.1061 94.664 32.2281C94.806 32.2501 94.865 32.3491 94.86 32.4751L94.808 33.6161C94.801 33.7591 94.703 33.8341 94.545 33.7951C94.152 33.7131 93.692 33.6921 93.264 33.6731C92.693 33.6471 92.166 33.7191 91.859 33.8321L91.572 40.1401C91.565 40.2991 91.482 40.3741 91.323 40.3671L89.96 40.3051Z"
        fill="#CFD1D2"
      />
      <path
        d="M98.9789 32.2969C100.945 32.3859 102.231 33.4289 102.13 35.6639L101.919 40.2919C101.913 40.4189 101.846 40.5119 101.732 40.5539C100.897 40.7699 99.6969 40.9379 98.5239 40.8849C96.6849 40.8009 95.0199 40.0579 95.1099 38.0759C95.1889 36.3489 96.6679 35.6059 98.6969 35.6979C99.2199 35.7219 99.8039 35.8119 100.276 35.8969L100.29 35.5959C100.341 34.4699 99.9509 33.9759 98.7779 33.9219C97.9859 33.8859 97.2469 34.0589 96.7259 34.3369C96.6109 34.4109 96.4849 34.3889 96.4119 34.2589L95.8219 33.2629C95.7479 33.1329 95.7519 33.0529 95.8339 32.9939C96.6189 32.5049 97.6949 32.2369 98.9789 32.2949V32.2969ZM98.5979 39.2839C99.1209 39.3079 99.7279 39.2399 100.128 39.1639L100.21 37.3719C99.7849 37.2899 99.1699 37.1979 98.6779 37.1749C97.4899 37.1209 96.9959 37.4959 96.9659 38.1459C96.9319 38.8909 97.6309 39.2399 98.5979 39.2839Z"
        fill="#CFD1D2"
      />
      <path
        d="M110.068 41.22C109.941 41.214 109.865 41.147 109.807 41.017L106.466 29.969C106.426 29.793 106.494 29.701 106.668 29.709L108.253 29.781C108.38 29.787 108.472 29.854 108.498 29.982L110.295 36.211C110.57 37.161 110.84 38.205 111.083 39.137L111.146 39.14C111.506 38.219 111.852 37.25 112.213 36.298L114.552 30.257C114.59 30.132 114.703 30.074 114.83 30.08L116.415 30.152C116.573 30.159 116.648 30.258 116.577 30.429L112.247 41.128C112.194 41.253 112.112 41.313 111.985 41.307L110.067 41.22H110.068Z"
        fill="#CFD1D2"
      />
      <path
        d="M118.523 32.265C117.936 32.238 117.498 31.758 117.525 31.172C117.552 30.57 118.031 30.162 118.618 30.189C119.22 30.216 119.629 30.664 119.601 31.267C119.574 31.853 119.126 32.293 118.523 32.265ZM117.402 41.554C117.259 41.548 117.168 41.464 117.175 41.305L117.528 33.554C117.534 33.412 117.633 33.337 117.776 33.343L119.155 33.406C119.298 33.412 119.373 33.495 119.367 33.638L119.014 41.389C119.007 41.548 118.924 41.623 118.781 41.617L117.402 41.554Z"
        fill="#CFD1D2"
      />
      <path
        d="M124.424 33.4549C126.39 33.5439 127.676 34.5869 127.575 36.8219L127.364 41.4499C127.358 41.5769 127.291 41.6699 127.177 41.7119C126.341 41.9279 125.142 42.0959 123.969 42.0429C122.13 41.9589 120.465 41.2159 120.555 39.2339C120.634 37.5069 122.113 36.7639 124.142 36.8559C124.665 36.8799 125.249 36.9699 125.721 37.0549L125.735 36.7539C125.786 35.6279 125.396 35.1339 124.223 35.0799C123.431 35.0439 122.692 35.2169 122.171 35.4949C122.057 35.5689 121.93 35.5469 121.857 35.4169L121.267 34.4209C121.193 34.2909 121.197 34.2109 121.279 34.1519C122.064 33.6629 123.14 33.3949 124.424 33.4529V33.4549ZM124.043 40.4419C124.566 40.4659 125.173 40.3979 125.573 40.3219L125.655 38.5299C125.23 38.4479 124.615 38.3559 124.123 38.3329C122.935 38.2789 122.441 38.6539 122.411 39.3039C122.377 40.0489 123.076 40.3979 124.043 40.4419Z"
        fill="#CFD1D2"
      />
      <path
        d="M132.049 42.4109C130.971 42.3619 129.844 42.0079 128.879 41.2339C128.74 41.1479 128.745 41.0379 128.83 40.9139L129.479 39.9109C129.565 39.7719 129.661 39.7599 129.799 39.8629C130.488 40.4179 131.331 40.7419 132.156 40.7789C132.981 40.8159 133.644 40.5299 133.672 39.9269C133.701 39.2929 132.948 39.0839 132.241 38.9249C131.062 38.6479 129.072 38.0659 129.159 36.1479C129.239 34.3889 130.936 33.7359 132.633 33.8129C133.536 33.8539 134.446 34.1169 135.358 34.6679C135.482 34.7369 135.507 34.8649 135.423 34.9879L134.774 35.9749C134.69 36.0829 134.577 36.1089 134.453 36.0399C133.881 35.6969 133.177 35.4579 132.464 35.4249C131.529 35.3819 131.006 35.7079 130.982 36.2319C130.952 36.8819 131.658 37.0729 132.695 37.3259C133.984 37.6229 135.613 38.1099 135.531 39.9329C135.45 41.7079 133.953 42.4979 132.051 42.4109H132.049Z"
        fill="#CFD1D2"
      />
      <path
        d="M140.331 34.178C142.297 34.267 143.583 35.31 143.482 37.545L143.271 42.173C143.265 42.3 143.198 42.393 143.084 42.435C142.248 42.651 141.049 42.819 139.876 42.766C138.037 42.682 136.372 41.939 136.462 39.957C136.541 38.23 138.02 37.487 140.049 37.579C140.572 37.603 141.156 37.693 141.628 37.778L141.642 37.477C141.693 36.351 141.303 35.857 140.13 35.803C139.338 35.767 138.599 35.94 138.078 36.218C137.964 36.292 137.837 36.27 137.764 36.14L137.174 35.144C137.1 35.014 137.104 34.934 137.186 34.875C137.971 34.386 139.047 34.118 140.331 34.176V34.178ZM139.95 41.165C140.473 41.189 141.08 41.121 141.48 41.045L141.562 39.253C141.137 39.171 140.522 39.079 140.03 39.056C138.842 39.002 138.348 39.377 138.318 40.027C138.284 40.772 138.983 41.121 139.95 41.165Z"
        fill="#CFD1D2"
      />
      <path
        d="M148.255 41.4639C148.398 41.4699 148.652 41.4669 148.906 41.4619C149.065 41.4689 149.156 41.5529 149.15 41.6959L149.095 42.9009C149.089 43.0279 149.021 43.1359 148.862 43.1439C148.592 43.1469 148.258 43.1489 148.02 43.1379C146.372 43.0629 145.126 42.1639 145.221 40.0719L145.55 32.8289C145.557 32.6859 145.639 32.6099 145.798 32.6169L147.002 32.6719C147.129 32.6779 147.22 32.7609 147.23 32.8899L147.286 34.7979L148.982 34.8749C149.125 34.8809 149.216 34.9649 149.21 35.1079L149.157 36.2649C149.15 36.4079 149.052 36.4829 148.909 36.4769L147.229 36.4009L147.064 40.0299C147.024 40.9019 147.445 41.4289 148.254 41.4659L148.255 41.4639Z"
        fill="#CFD1D2"
      />
      <path
        d="M148.518 55.5481L58.6689 51.4611C57.8479 51.4241 57.2139 50.7281 57.2509 49.9081C57.2879 49.0881 57.9829 48.4531 58.8039 48.4901L148.653 52.5771C149.474 52.6141 150.108 53.3101 150.071 54.1301C150.034 54.9501 149.339 55.5851 148.518 55.5481Z"
        fill="#CFD1D2"
      />
      <path
        d="M148.058 65.6601L58.209 61.5731C57.388 61.5361 56.754 60.8401 56.791 60.0201C56.828 59.2001 57.523 58.5651 58.344 58.6021L148.193 62.6891C149.014 62.7261 149.648 63.4221 149.611 64.2421C149.574 65.0621 148.879 65.6971 148.058 65.6601Z"
        fill="#CFD1D2"
      />
      <path
        d="M147.598 75.7739L57.749 71.6869C56.928 71.6499 56.294 70.9539 56.331 70.1339C56.368 69.3139 57.063 68.6789 57.884 68.7159L147.733 72.8029C148.554 72.8399 149.188 73.5359 149.151 74.3559C149.114 75.1759 148.419 75.8109 147.598 75.7739Z"
        fill="#CFD1D2"
      />
      <path
        d="M146.505 99.7929L56.656 95.7059C55.835 95.6689 55.201 94.9729 55.238 94.1529C55.275 93.3329 55.97 92.6979 56.791 92.7349L146.64 96.8219C147.461 96.8589 148.095 97.5549 148.058 98.3749C148.021 99.1949 147.326 99.8299 146.505 99.7929Z"
        fill="#CFD1D2"
      />
      <path
        d="M145.999 110.917L56.15 106.83C55.329 106.793 54.695 106.097 54.732 105.277C54.769 104.457 55.464 103.822 56.285 103.859L146.134 107.946C146.955 107.983 147.589 108.679 147.552 109.499C147.515 110.319 146.82 110.954 145.999 110.917Z"
        fill="#CFD1D2"
      />
      <path
        d="M96.7749 128.439L55.2519 126.55C54.4309 126.513 53.7969 125.817 53.8339 124.997C53.8709 124.177 54.5659 123.542 55.3869 123.579L96.9099 125.468C97.7309 125.505 98.3649 126.201 98.3279 127.021C98.2909 127.841 97.5959 128.476 96.7749 128.439Z"
        fill="#CFD1D2"
      />
      <path
        d="M144.883 135.473L53.0698 131.297L52.4374 145.202L144.251 149.378L144.883 135.473Z"
        fill="#CFD1D2"
      />
      <path
        d="M28.1527 45.6421C25.3638 45.0037 23.2134 44.4661 23.2134 42.3492C23.2134 40.3332 24.8934 39.0899 27.6151 39.0899C29.5976 39.1571 31.6136 39.762 33.5289 41.0724C33.7977 41.2404 34.0329 41.2404 34.2345 40.9716L35.8138 38.6867C35.9818 38.4179 35.9482 38.1827 35.6794 38.0147C33.4617 36.4019 31.5464 35.5954 29.3288 35.3602V33.0082C29.3288 32.7057 29.1608 32.5041 28.8584 32.5041H26.5735C26.2711 32.5041 26.0695 32.7057 26.0695 33.0082V35.3938C22.2054 35.8978 19.2149 38.0483 19.2149 42.2484C19.2149 47.1206 23.6502 48.6326 27.0775 49.3718C29.9672 50.0103 32.4201 50.5815 32.4201 52.9671C32.4201 55.2856 30.0344 56.2936 27.7159 56.2936C25.5319 56.2264 23.5494 55.3528 21.4325 53.7736C21.1973 53.572 20.9285 53.6392 20.7605 53.8744L19.1141 56.1592C18.9125 56.3944 18.9461 56.6633 19.1813 56.8649C21.4325 58.7465 23.5158 59.7881 26.0695 60.057V62.6106C26.0695 62.913 26.2711 63.1146 26.5735 63.1146H28.8584C29.1608 63.1146 29.3288 62.913 29.3288 62.6106V60.0234C33.1593 59.5193 36.4858 57.3353 36.4858 53.0343C36.4858 47.6582 31.8824 46.5158 28.1527 45.6421Z"
        fill="#CFD1D2"
      />
      <path
        d="M187.389 92.5521C185.833 92.1958 184.633 91.8958 184.633 90.7144C184.633 89.5893 185.57 88.8955 187.089 88.8955C188.196 88.933 189.321 89.2705 190.39 90.0018C190.54 90.0956 190.671 90.0956 190.783 89.9456L191.665 88.6704C191.759 88.5204 191.74 88.3891 191.59 88.2954C190.352 87.3953 189.283 86.9452 188.046 86.814V85.5013C188.046 85.3326 187.952 85.2201 187.783 85.2201H186.508C186.339 85.2201 186.227 85.3326 186.227 85.5013V86.8327C184.07 87.114 182.401 88.3141 182.401 90.6581C182.401 93.3772 184.877 94.221 186.789 94.6336C188.402 94.9899 189.771 95.3086 189.771 96.64C189.771 97.9339 188.439 98.4965 187.146 98.4965C185.927 98.459 184.82 97.9714 183.639 97.0901C183.508 96.9776 183.358 97.0151 183.264 97.1463L182.345 98.4215C182.233 98.5527 182.251 98.7027 182.383 98.8153C183.639 99.8654 184.802 100.447 186.227 100.597V102.022C186.227 102.191 186.339 102.303 186.508 102.303H187.783C187.952 102.303 188.046 102.191 188.046 102.022V100.578C190.183 100.297 192.04 99.0778 192.04 96.6775C192.04 93.6772 189.471 93.0396 187.389 92.5521Z"
        fill="#CFD1D2"
      />
      <path
        d="M30.4344 169.328C28.878 168.972 27.6778 168.672 27.6778 167.49C27.6778 166.365 28.6154 165.671 30.1344 165.671C31.2407 165.709 32.3658 166.046 33.4347 166.778C33.5847 166.871 33.716 166.871 33.8285 166.721L34.7098 165.446C34.8036 165.296 34.7848 165.165 34.6348 165.071C33.3972 164.171 32.3283 163.721 31.0907 163.59V162.277C31.0907 162.108 30.9969 161.996 30.8282 161.996H29.553C29.3843 161.996 29.2718 162.108 29.2718 162.277V163.609C27.1153 163.89 25.4464 165.09 25.4464 167.434C25.4464 170.153 27.9216 170.997 29.8343 171.409C31.447 171.766 32.8159 172.085 32.8159 173.416C32.8159 174.71 31.4845 175.272 30.1906 175.272C28.9717 175.235 27.8654 174.747 26.684 173.866C26.5527 173.753 26.4027 173.791 26.3089 173.922L25.3901 175.197C25.2776 175.329 25.2963 175.479 25.4276 175.591C26.684 176.641 27.8466 177.223 29.2718 177.373V178.798C29.2718 178.966 29.3843 179.079 29.553 179.079H30.8282C30.9969 179.079 31.0907 178.966 31.0907 178.798V177.354C33.2284 177.073 35.0849 175.854 35.0849 173.453C35.0849 170.453 32.5159 169.816 30.4344 169.328Z"
        fill="#CFD1D2"
      />
      <path
        d="M182.272 50.902C185.412 50.902 187.957 48.3567 187.957 45.217C187.957 42.0772 185.412 39.532 182.272 39.532C179.132 39.532 176.587 42.0772 176.587 45.217C176.587 48.3567 179.132 50.902 182.272 50.902Z"
        stroke="#CFD1D2"
        strokeWidth="0.92"
        strokeMiterlimit="10"
      />
      <path
        d="M182.272 161.364C185.412 161.364 187.957 158.819 187.957 155.679C187.957 152.539 185.412 149.994 182.272 149.994C179.132 149.994 176.587 152.539 176.587 155.679C176.587 158.819 179.132 161.364 182.272 161.364Z"
        stroke="#CFD1D2"
        strokeWidth="0.92"
        strokeMiterlimit="10"
      />
      <path
        d="M17.771 125.163C23.5727 125.163 28.276 120.46 28.276 114.658C28.276 108.856 23.5727 104.153 17.771 104.153C11.9692 104.153 7.26599 108.856 7.26599 114.658C7.26599 120.46 11.9692 125.163 17.771 125.163Z"
        stroke="#CFD1D2"
        strokeWidth="0.92"
        strokeMiterlimit="10"
      />
      <path
        d="M135.533 154.186C146.103 154.186 154.671 145.618 154.671 135.048C154.671 124.478 146.103 115.91 135.533 115.91C124.963 115.91 116.395 124.478 116.395 135.048C116.395 145.618 124.963 154.186 135.533 154.186Z"
        fill="#D1DB41"
      />
      <path
        d="M136.037 135.065C133.248 134.427 131.097 133.889 131.097 131.772C131.097 129.756 132.777 128.513 135.499 128.513C137.482 128.58 139.498 129.185 141.413 130.495C141.682 130.663 141.917 130.663 142.119 130.395L143.698 128.11C143.866 127.841 143.832 127.606 143.563 127.438C141.346 125.825 139.43 125.019 137.213 124.783V122.431C137.213 122.129 137.045 121.927 136.742 121.927H134.458C134.155 121.927 133.954 122.129 133.954 122.431V124.817C130.089 125.321 127.099 127.471 127.099 131.672C127.099 136.544 131.534 138.056 134.962 138.795C137.851 139.433 140.304 140.005 140.304 142.39C140.304 144.709 137.918 145.717 135.6 145.717C133.416 145.65 131.433 144.776 129.317 143.197C129.081 142.995 128.813 143.062 128.645 143.297L126.998 145.582C126.796 145.818 126.83 146.086 127.065 146.288C129.317 148.17 131.4 149.211 133.954 149.48V152.034C133.954 152.336 134.155 152.538 134.458 152.538H136.742C137.045 152.538 137.213 152.336 137.213 152.034V149.446C141.043 148.942 144.37 146.758 144.37 142.457C144.37 137.081 139.766 135.939 136.037 135.065Z"
        fill="white"
      />
    </svg>
  );
};

export default IllustrationBrazilBill;
