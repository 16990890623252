import { Spinner } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import { ModalTypeEnum, ModalComponent } from '@mfe/shared/components';

import styles from './Loading.module.scss';

type LoadingProps = {
  isMobile: boolean;
};

export const Loading = ({ isMobile }: LoadingProps) => {
  const { t } = useTranslation('ACP', { keyPrefix: 'Loading' });

  return isMobile ? (
    <div className={styles['loadingContainer']}>
      <div className={styles['content']}>
        <Spinner description={t('description')} />
      </div>
    </div>
  ) : (
    <ModalComponent type={ModalTypeEnum.ACP}>
      <div className={styles['content']}>
        <Spinner description={t('description')} />
      </div>
    </ModalComponent>
  );
};
