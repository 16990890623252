import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Icon, Spinner, StandardModal, Surface, Txt } from '@vst/beam';
import { DataUsageOutlined, EventOutlined } from '@vst/beam-icons/icons';

import {
  selectUpdateBillCycle,
  setIsConfirmationModalOpen,
} from '@mfe/to-be-migrated/redux/updateBillCycle';
import { selectBillingAccount } from '@mfe/to-be-migrated/redux/billingInfo';

import { ModalSectionAlert } from './modal-section-alert';

import styles from './styles.module.scss';
import useNavigate from '@mfe/services/navigation';
import { PayBillLink } from '@mfe/legacy/mv/utils/Navigation';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';

export const UpdateBillCycleModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['UpdateBillCycle', 'Global']);
  const { goTo, replace } = useNavigate();

  const {
    user: {
      auth: {
        tokenInfo: { accessToken, idToken },
      },
    },
  } = useSelector(selectUser);

  const isPlatform = !idToken;

  const {
    isConfirmationModalOpen,
    reason,
    loading: billCycleDaysLoading,
  } = useSelector(selectUpdateBillCycle);

  const billingAccountState = useSelector(selectBillingAccount);
  const loading =
    billCycleDaysLoading || !billingAccountState?.billingCycleDayOfMonth;

  const billingPageLink = isPlatform
    ? `${PayBillLink.ManageBilling}?token=${accessToken}`
    : `/${PayBillLink.PayBill}`;

  const navigateToBillingPage = () => {
    replace(billingPageLink);
    dispatch(setIsConfirmationModalOpen(false));
  };

  const navigateToSelectBillCyclePage = () => {
    isPlatform
      ? goTo(
          `${PayBillLink.ManageBilling}/${PayBillLink.SelectBillCycle}?token=${accessToken}`
        )
      : replace(`/${PayBillLink.PayBill}/${PayBillLink.SelectBillCycle}`, {
          skip: 'redirect',
        });

    dispatch(setIsConfirmationModalOpen(false));
  };

  const handleContinue = () => {
    navigateToSelectBillCyclePage();
    dispatch(setIsConfirmationModalOpen(false));
  };

  const textMargin = reason ? undefined : 0;

  return (
    <StandardModal
      isOpen={isConfirmationModalOpen}
      onClose={navigateToBillingPage}
      size="medium"
      header={t('UpdateBillCycle:modal.title')}
      actions={{
        primary: {
          label: t('Global:continue'),
          onClick: handleContinue,
          disabled: loading || Boolean(reason),
        },
        secondary: {
          label: t('Global:cancel'),
          onClick: navigateToBillingPage,
        },
      }}
      surfaceProps={{ className: styles['modal-content'] }}
    >
      {loading ? (
        <Spinner />
      ) : (
        <Surface>
          <Txt color="regular" variant="bodySmallRegular">
            <Icon mr="8px" icon={DataUsageOutlined} color="teal_600" />
            {t('UpdateBillCycle:modal.info1')}
          </Txt>
          <Txt variant="bodySmallRegular" style={{ marginBottom: textMargin }}>
            <Icon mr="8px" icon={EventOutlined} color="teal_600" />
            {t('UpdateBillCycle:modal.info2')}
          </Txt>
          {reason ? <ModalSectionAlert /> : null}
        </Surface>
      )}
    </StandardModal>
  );
};
