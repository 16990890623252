import { Surface } from '@vst/beam';

import { useScreenResolution } from '@mfe/shared/util';

import styles from './CartSummaryContainer.module.scss';

export const CartSummaryContainer: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => {
  const { isMedium, isLarge, isExtraLarge } = useScreenResolution();
  const isMediumOrLarger = isMedium || isLarge || isExtraLarge;

  return (
    <div>
      <Surface
        p="24px"
        variant="primary"
        data-cy="cart-summary-card"
        className={styles['cart-summary-container']}
        radius={isMediumOrLarger ? '16px' : undefined}
      >
        {children}
      </Surface>
    </div>
  );
};
