import { StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { ScreenSize } from '../../utils/Context';
import { Color } from '../../utils/Color';

const circleStyles = (circleWidth: number) => ({
  width: circleWidth,
  height: circleWidth,
  borderRadius: circleWidth / 2,
});

const defaultBodyContentWrapper: TextStyle = {
  paddingHorizontal: 40,
  paddingTop: 10,
};

const defaultModalCard: ViewStyle = {
  alignSelf: 'center',
  width: 479,
};

const baseStyles: StyleSheet.NamedStyles<
  Record<string, ViewStyle | TextStyle>
> = {
  bodyContentWrapper: {
    ...defaultBodyContentWrapper,
  },
  bodyText: {
    textAlign: 'center',
    color: Color.gray700,
  },
  bodyTextView: {
    marginHorizontal: 56,
  },
  button: {
    height: 36,
    alignSelf: 'center',
    alignContent: 'center',
    paddingHorizontal: 16,
  },
  circle: {
    backgroundColor: Color.white,
    alignSelf: 'center',
    marginTop: 51,
    ...circleStyles(88),
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    backgroundColor: Color.white,
    ...circleStyles(30),
  },
  footer: {
    paddingHorizontal: 'auto',
    alignItems: 'center',
  },
  footerButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 30,
    marginBottom: 40,
  },
  footerButtonGroup: {
    backgroundColor: Color.gray100,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 40,
    paddingHorizontal: 28,
    height: 68,
  },
  header: {
    width: '100%',
  },
  icon: {
    alignSelf: 'center',
    marginTop: 12,
  },
  modal: {
    alignSelf: 'center',
  },
  modalCard: {
    ...defaultModalCard,
  },
  popupContainer: {
    maxWidth: 479,
  },
  title: {
    textAlign: 'center',
    marginBottom: 10,
  },
};

const Styles = (
  screenSize: ScreenSize
): StyleSheet.NamedStyles<Record<string, ViewStyle>> => {
  switch (screenSize) {
    case ScreenSize.XS:
      return StyleSheet.create({
        ...baseStyles,
        bodyText: {
          textAlign: 'center',
        },
        bodyTextView: {
          marginHorizontal: 28,
        },
        footerButtonGroup: {
          display: 'flex',
          flexDirection: 'column-reverse',
          justifyContent: 'space-between',
          marginTop: 40,
          marginBottom: 10,
        },
        modal: {
          marginHorizontal: 0,
        },
        modalCard: {
          ...defaultModalCard,
          maxWidth: 320,
        },
        xsButton: {
          marginTop: 10,
        },
      });
    case ScreenSize.SM:
      return StyleSheet.create({
        ...baseStyles,
        modalCard: {
          ...defaultModalCard,
          width: 366,
        },
        footerButtonGroup: {
          display: 'flex',
          flexDirection: 'column-reverse',
          justifyContent: 'space-between',
          marginTop: 40,
          marginBottom: 10,
        },
      });
    default:
      return StyleSheet.create({
        ...baseStyles,
        bodyContentWrapper: {
          ...defaultBodyContentWrapper,
          paddingHorizontal: 56,
          paddingTop: 14,
        },
      });
  }
};

export default Styles;
