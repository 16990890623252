import { ModalTypeEnum, ModalComponent } from '@mfe/shared/components';
import Outcome from './Outcome';
import { useScreenResolution } from '@mfe/shared/util';
import { useTranslation } from 'react-i18next';

interface ApplicationNotFoundErrorProps {
  goBackToOverviewPage: () => void;
  closeModal: () => void;
}

export const ApplicationNotFoundError = (
  props: ApplicationNotFoundErrorProps
) => {
  const { goBackToOverviewPage, closeModal } = props;
  const { t } = useTranslation('ACP', { keyPrefix: 'Error' });
  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;

  const renderOutcome = () => {
    return (
      <Outcome
        data-cy="acp-application-not-found-error"
        title={t('applicationNotFound.title')}
        text={t('applicationNotFound.description')}
        thirdText={t('applicationNotFound.thirdDescription')}
        primaryButton={{
          label: t('applicationNotFound.buttons.updateInformation'),
          handleClick: closeModal,
        }}
        secondaryButton={{
          label: t('applicationNotFound.buttons.backToMyViasat'),
          handleClick: goBackToOverviewPage,
        }}
        hasCloseButton
        closeButton={{ handleClick: closeModal }}
      />
    );
  };

  return isMobile ? (
    renderOutcome()
  ) : (
    <ModalComponent type={ModalTypeEnum.ACP}>{renderOutcome()}</ModalComponent>
  );
};
