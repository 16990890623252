import React from 'react';
import Svg, { Path } from 'react-native-svg';
import {
  Color,
  DimensionContext,
  ScreenSize,
  webSafeSvg,
  isDefined,
} from '../../utils';

export type CloseIconType = React.ComponentProps<typeof Svg> & {
  color?: Color | string;
  size?: number;
};

export class CloseIcon extends React.Component<CloseIconType> {
  override render() {
    const { color = Color.teal500, size: inputSize, ...props } = this.props;

    return (
      <DimensionContext.Consumer>
        {(value: DimensionContext): JSX.Element => {
          const { screenSize } = value;
          let size = 12;
          if (isDefined(inputSize)) size = inputSize;
          else if (screenSize === ScreenSize.XS) size = 8;

          const _Icon = (): JSX.Element => (
            <Svg
              width={size}
              height={size}
              viewBox={`11.5 11.5 9 9`}
              {...props}
            >
              <Path
                d="M19.7519789,13.4195251 C19.9169278,13.2588775 19.9996015,13.0636269 20,12.8337731 C19.9996015,12.6039193 19.9169278,12.4069096 19.7519789,12.2427441 C19.5913314,12.0813132 19.3960807,12.0003985 19.1662269,12 C18.9363594,12.0003848 18.7393497,12.0812995 18.5751979,12.2427441 L16,14.823219 L13.4195251,12.2427441 C13.2588775,12.0812995 13.0636269,12.0003848 12.8337731,12 C12.6039193,12.0003985 12.4069096,12.0813132 12.2427441,12.2427441 C12.0813132,12.4069096 12.0003985,12.6039193 12,12.8337731 C12.0003848,13.0636269 12.0812995,13.2588775 12.2427441,13.4195251 L14.823219,16 L12.2427441,18.5751979 C12.0812995,18.7393497 12.0003848,18.9363594 12,19.1662269 C12.0003985,19.3960807 12.0813132,19.5913314 12.2427441,19.7519789 C12.4069096,19.9169278 12.6039193,19.9996015 12.8337731,20 C13.0636269,19.9996015 13.2588775,19.9169278 13.4195251,19.7519789 L16,17.176781 L18.5751979,19.7519789 C18.7393497,19.9169278 18.9363594,19.9996015 19.1662269,20 C19.3960807,19.9996015 19.5913314,19.9169278 19.7519789,19.7519789 C19.9169278,19.5913314 19.9996015,19.3960807 20,19.1662269 C19.9996015,18.9363594 19.9169278,18.7393497 19.7519789,18.5751979 L17.176781,16 L19.7519789,13.4195251 Z"
                fill={color}
              />
            </Svg>
          );

          const Icon = webSafeSvg(_Icon);

          return <Icon />;
        }}
      </DimensionContext.Consumer>
    );
  }
}
