import { gql } from '@apollo/client';

export const GET_ALL_DETAILS = gql`
  query getAllDetails {
    getOfferDetails {
      name
      description
      offerDescription
      tooltipShipping
      titleShipping
      descriptionShipping
      shippingConfirmation
      price
      title
      imageUrl
      logoURL
      hyperlinkUrl
      hyperlinkText
      buttonText
      tag
      attributes
      smsFooter
      smsLabel
      shouldDisplaySms
      fullPrice
      discountValue
      productTypeId
      productFamily
      discountDuration
    }
    getUserDetails {
      error {
        failureReason
      }
      phoneNumber
      email
      shippingAddress {
        addressLines
        municipality
        region
        postalCode
        countryCode
      }
    }
  }
`;

export const GET_INVOICE_HISTORY = gql`
  query getInvoiceHistory {
    getInvoiceHistory {
      currentMonthlyBill
      error {
        failureReason
      }
    }
  }
`;

export const GET_OFFER_DETAILS = gql`
  query getOfferDetails {
    getOfferDetails {
      name
      description
      price
      attributes
      smsFooter
      smsLabel
      shouldDisplaySms
      discountValue
      productFamily
      productTypeId
    }
  }
`;

export const GET_SCRUBBED_ADDRESS = gql`
  query getScrubbedAddress($address: GetScrubbedAddressInput!) {
    getScrubbedAddress(address: $address) {
      addressLines
      municipality
      region
      country
      postalCode
      countryCode
    }
  }
`;

export const GET_STREAM_ELIGIBILITY = gql`
  query checkStreamEligibility {
    checkStreamEligibility {
      isEligible
      error {
        failureReason
      }
    }
  }
`;
