import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  selectChangePlan,
  selectChosenPlan,
  reset,
} from '@mfe/to-be-migrated/redux/changePlan';

import { NavigationHandlers } from '../../types';
import { SubmitError } from '../errors/SubmitError';
import { ConfirmPurchaseLoading } from '@mfe/shared/components';
import { ConfirmationWithEquipmentChange } from '../OrderConfirmation/ConfirmationWithEquipmentChange';
import { ConfirmationWithoutEquipmentChange } from '../OrderConfirmation/ConfirmationWithoutEquipmentChange';
import { formatTimeInterval } from '@mfe/to-be-migrated/redux/scheduleInstallation';

export const OrderOutcome = ({
  navigateToOverview,
}: Pick<NavigationHandlers, 'navigateToOverview'>) => {
  const { isEquipmentChangeNeeded } = useSelector(selectChosenPlan);
  const dispatch = useDispatch();
  const { loading, error, orderSuccess, installationAppointment } =
    useSelector(selectChangePlan);

  const goToOverview = () => {
    dispatch(reset());
    navigateToOverview();
  };

  const { t } = useTranslation('NewChangePlan', {
    keyPrefix: 'orderConfirmation.withEquipmentChange',
  });

  if (loading) {
    return (
      <ConfirmPurchaseLoading
        title={'confirmPurchase.title'}
        description={'confirmPurchase.description'}
      />
    );
  }

  if (error || !orderSuccess) {
    return <SubmitError navigateToOverview={goToOverview} />;
  }

  const content = installationAppointment?.['startTime']
    ? t('alertContent', {
        DATE: new Date(installationAppointment?.['startTime']),
        TIME: formatTimeInterval([
          installationAppointment?.['startTime'] ?? '',
          installationAppointment?.['endTime'] ?? '',
        ]),
      })
    : t('alertContentUnscheduled');

  if (isEquipmentChangeNeeded) {
    return (
      <ConfirmationWithEquipmentChange
        goToOverview={goToOverview}
        content={content}
      />
    );
  }

  return (
    <ConfirmationWithoutEquipmentChange navigateToOverview={goToOverview} />
  );
};
