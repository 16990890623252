import { Txt } from '@vst/beam';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './footer.module.scss';

interface FooterProps {
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  showMoreClicked: boolean;
  setShowMoreClicked: React.Dispatch<React.SetStateAction<boolean>>;
  totalNumPages: number;
}

interface PaginationProps {
  totalNumPages: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  pageNumber: number;
}

const Pagination = ({
  totalNumPages,
  setPageNumber,
  pageNumber,
}: PaginationProps): JSX.Element => {
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(4);

  const pagesList = Array.from({ length: totalNumPages }, (_, i) => i + 1);
  const showLeftArrow = totalNumPages > 4;
  const showRightArrow = totalNumPages > 4;
  const showLeftEllipses = startIndex > 0;
  const showRightEllipses = endIndex + 1 <= totalNumPages;
  const leftArrowStyle =
    pageNumber === 1 ? styles['disabledArrow'] : styles['activeArrow'];
  const rightArrowStyle =
    pageNumber === totalNumPages
      ? styles['disabledArrow']
      : styles['activeArrow'];

  const getPaginationRange = (direction: string): void => {
    if (direction === 'next' && totalNumPages >= endIndex && pageNumber >= 4) {
      setStartIndex(startIndex + 1);
      setEndIndex(endIndex + 1);
    }
    if (direction === 'prev' && pageNumber <= totalNumPages - 3) {
      setStartIndex(startIndex - 1);
      setEndIndex(endIndex - 1);
    }
  };

  return (
    <div className={styles['paginationContainer']}>
      {showLeftArrow && (
        <button
          disabled={pageNumber === 1}
          className={styles['arrow']}
          onClick={(): void => {
            setPageNumber(pageNumber - 1);
            getPaginationRange('prev');
          }}
        >
          <Txt variant="bodyLargeRegular" className={leftArrowStyle}>
            {'<'}
          </Txt>
        </button>
      )}

      {showLeftEllipses && (
        <Txt variant="bodyLargeRegular" className={styles['ellipses']}>
          {'...'}
        </Txt>
      )}

      {pagesList.slice(startIndex, endIndex).map((pageNum, index) => (
        <button
          className={styles['arrow']}
          key={index}
          onClick={(): void => {
            setPageNumber(pageNum);
          }}
        >
          <Txt
            component="p"
            variant="bodyLargeRegular"
            className={
              pageNum === pageNumber ? styles['grayElt'] : styles['pageNumber']
            }
            key={index}
          >
            {pageNum}
          </Txt>
        </button>
      ))}

      {showRightEllipses && (
        <Txt variant="bodyLargeRegular" className={styles['ellipses']}>
          {'...'}
        </Txt>
      )}

      {showRightArrow && (
        <button
          disabled={pageNumber === totalNumPages}
          className={styles['arrow']}
          onClick={(): void => {
            setPageNumber(pageNumber + 1);
            getPaginationRange('next');
          }}
        >
          <Txt variant="bodyLargeRegular" className={rightArrowStyle}>
            {'>'}
          </Txt>
        </button>
      )}
    </div>
  );
};

export const ActivityFooter = ({
  pageNumber,
  setPageNumber,
  showMoreClicked,
  setShowMoreClicked,
  totalNumPages,
}: FooterProps): JSX.Element => {
  const { t } = useTranslation('BillingStatement');
  const showPagination = showMoreClicked && totalNumPages > 0;

  return (
    <div
      id="footer"
      className={styles['footer']}
      style={!showPagination ? { justifyContent: 'center' } : {}}
    >
      {showPagination ? (
        <Pagination {...{ totalNumPages, setPageNumber, pageNumber }} />
      ) : (
        <button
          className={styles['showMoreButton']}
          onClick={(): void => {
            setShowMoreClicked(true);
          }}
        >
          <Txt
            component="p"
            variant="bodyLargeRegular"
            className={styles['showMoreText']}
          >
            {t('showMore')}
          </Txt>
        </button>
      )}
    </div>
  );
};
