import { useCallback, useMemo, useState } from 'react';

import {
  hasDigits,
  isDateInvalid,
  isDateInTheFuture,
  isApplicationIdValid,
} from './validators';
import {
  InputEvent,
  FormValues,
  DateOfBirth,
  FormFieldName,
  FieldHandlerKey,
  ValidationErrors,
} from './types';
import { TRANSFER_RADIO_IDS } from '../../components/Form/TransferField';
import { TRIBAL_BENEFIT_RADIO_IDS } from '../../components/Form/TribalBenefitField';
import { useTranslation } from 'react-i18next';

export const useFormState = () => {
  const [applicationId, setApplicationId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const [isTransferring, setIsTransferring] =
    useState<FormValues['isTransferring']>(null);

  const [hasTribalBenefit, setHasTribalBenefit] =
    useState<FormValues['hasTribalBenefit']>(null);

  const [terms, setTerms] = useState(false);

  const dob = useMemo(
    () => ({
      month,
      day,
      year,
    }),
    [month, day, year]
  );

  const [fieldsToValidate, setFieldsToValidate] = useState<FormFieldName[]>([]);

  const addFieldsToValidate = useCallback(
    (fields: FormFieldName[]) => {
      const newFields = fieldsToValidate.slice();

      fields.forEach((field: FormFieldName) => {
        if (!fieldsToValidate.includes(field)) {
          newFields.push(field);
        }
      });

      setFieldsToValidate(newFields);
    },
    [fieldsToValidate]
  );

  const pushFieldToValidate = (field: FormFieldName) =>
    setFieldsToValidate((prevFields) => [...prevFields, field]);

  const handlers = {
    applicationId: (e: InputEvent) => {
      setApplicationId(e.currentTarget?.value);
      pushFieldToValidate('applicationId');
    },
    firstName: (e: InputEvent) => {
      setFirstName(e.currentTarget.value);
      pushFieldToValidate('firstName');
    },
    lastName: (e: InputEvent) => {
      setLastName(e.currentTarget.value);
      pushFieldToValidate('lastName');
    },
    month: (e: InputEvent) => {
      setMonth(e.currentTarget.value);
      pushFieldToValidate('dob');
    },
    day: (e: InputEvent) => {
      setDay(e.currentTarget.value);
      pushFieldToValidate('dob');
    },
    year: (e: InputEvent) => {
      setYear(e.currentTarget.value);
      pushFieldToValidate('dob');
    },
    isTransferring: (e: InputEvent) => {
      pushFieldToValidate('isTransferring');
      const hasBenefit = e.currentTarget.id === TRANSFER_RADIO_IDS.yes;
      setIsTransferring(hasBenefit);
    },
    hasTribalBenefit: (e: InputEvent) => {
      pushFieldToValidate('hasTribalBenefit');
      const hasBenefit = e.currentTarget.id === TRIBAL_BENEFIT_RADIO_IDS.yes;
      setHasTribalBenefit(hasBenefit);
    },
    terms: (e: InputEvent) => {
      setTerms(e.currentTarget.checked);
      pushFieldToValidate('terms');
    },
  };

  const getFieldHandler = (field: FieldHandlerKey) => handlers[field];

  const { t } = useTranslation('ACP', { keyPrefix: 'Form' });

  const emptyStateErrors: Record<FormFieldName, string> = useMemo(
    () => ({
      applicationId: t('errors.empty.applicationId'),
      firstName: t('errors.empty.firstName'),
      lastName: t('errors.empty.lastName'),
      dob: t('errors.empty.dob'),
      isTransferring: t('errors.empty.transfer'),
      hasTribalBenefit: t('errors.empty.tribalBenefit'),
      terms: t('errors.empty.terms'),
    }),
    [t]
  );

  const validateDOB = useCallback(
    (date: DateOfBirth) => {
      if (!date.day || !date.month || !date.year) {
        return emptyStateErrors.dob;
      }

      if (isDateInvalid(date)) {
        return t('errors.invalid.dob');
      }

      if (isDateInTheFuture(date)) {
        return t('errors.invalid.dobInTheFuture');
      }

      return '';
    },
    [emptyStateErrors.dob, t]
  );

  const errors = useMemo((): ValidationErrors => {
    const validateApplicationId = (value: string) => {
      if (!value) {
        return emptyStateErrors.applicationId;
      }

      if (!isApplicationIdValid(value)) {
        return t('errors.invalid.applicationId');
      }

      return '';
    };

    const validateFirstName = (value: string) => {
      if (!value) {
        return emptyStateErrors.firstName;
      }

      if (hasDigits(value)) {
        return t('errors.invalid.firstName');
      }

      return '';
    };

    const validateLastName = (value: string) => {
      if (!value) {
        return emptyStateErrors.lastName;
      }

      if (hasDigits(value)) {
        return t('errors.invalid.lastName');
      }

      return '';
    };

    const validateBenefitsValue = (
      value: boolean | null,
      field: FormFieldName
    ) => {
      if (value === null) {
        return emptyStateErrors[field];
      }

      return '';
    };

    const validateTermsField = (value: boolean, field: FormFieldName) => {
      if (!value) {
        return emptyStateErrors[field];
      }

      return '';
    };

    return {
      applicationId: validateApplicationId(applicationId),
      firstName: validateFirstName(firstName),
      lastName: validateLastName(lastName),
      dob: validateDOB({ month, day, year }),
      isTransferring: validateBenefitsValue(isTransferring, 'isTransferring'),
      hasTribalBenefit: validateBenefitsValue(
        hasTribalBenefit,
        'hasTribalBenefit'
      ),
      terms: validateTermsField(terms, 'terms'),
    };
  }, [
    applicationId,
    firstName,
    lastName,
    day,
    month,
    year,
    isTransferring,
    hasTribalBenefit,
    terms,
    emptyStateErrors,
    t,
    validateDOB,
  ]);

  return {
    applicationId,
    firstName,
    lastName,
    dob,
    isTransferring,
    hasTribalBenefit,
    terms,

    errors,
    getFieldHandler,
    validateDOB,
    fieldsToValidate,
    addFieldsToValidate,
  };
};
