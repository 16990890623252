import { Surface, Txt, colors } from '@vst/beam';
import { useSelector } from 'react-redux';

import { selectChangePlan } from '@mfe/to-be-migrated/redux/changePlan';

import styles from './styles.module.scss';

type Variant = 'default' | 'blue';

interface PlanCardProps {
  variant?: Variant;
  children: React.ReactNode;
  name: string;
  broadbandLabel?: React.ReactNode;
}

export const PlanCard = ({
  variant = 'default',
  children,
  name,
  broadbandLabel,
}: PlanCardProps) => {
  const { hideBroadbandLabels } = useSelector(selectChangePlan);

  return (
    <Surface
      className={`${styles['container']} ${styles[variant]}`}
      data-cy="plan-card-surface"
    >
      <Surface data-cy="plan-card-header" className={styles['header']} py="4px">
        <Txt
          variant="labelMedium"
          style={{ color: name === '-' ? colors.blue['100'] : 'inherit' }}
        >
          {name}
        </Txt>
      </Surface>
      <Surface
        style={{
          paddingBottom:
            broadbandLabel && hideBroadbandLabels ? '0px' : undefined,
        }}
        className={styles['body']}
      >
        {children}
        {broadbandLabel && (
          <Surface
            data-selector="broadband-label"
            data-hidden={hideBroadbandLabels ? 'true' : undefined}
            style={{
              position: 'relative',
              height: hideBroadbandLabels ? '88px' : undefined,
              overflow: 'hidden',
            }}
          >
            {hideBroadbandLabels && <Surface className={styles['overlay']} />}
            {broadbandLabel}
          </Surface>
        )}
      </Surface>
    </Surface>
  );
};
