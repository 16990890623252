import { Badge, Txt } from '@vst/beam';

import styles from './OrderDetailsSection.module.scss';

interface OrderDetailsSectionProps {
  id: string;
  title?: string;
  badge?: string;
  children: React.ReactNode;
}

export const OrderDetailsSection = ({
  children,
  id,
  title,
  badge,
}: OrderDetailsSectionProps) => (
  <div data-cy={id} className={styles['order-details-section']}>
    <div className={styles['title-with-badge']}>
      {title && (
        <Txt variant="labelSmall" color="subtle">
          {title}
        </Txt>
      )}
      {badge && (
        <Badge
          className={styles['capitalize']}
          label={badge}
          state="infoPrimary"
          emphasis="light"
          showIcon={false}
          size="small"
        />
      )}
    </div>
    {children}
  </div>
);
