import React from 'react';
import { BaseCard, BaseCardType } from '../BaseCard';
import { Svg, Close } from '../Components';

export class DefaultCard extends React.Component<BaseCardType> {
  private root: BaseCard | undefined | null;

  static Close = Close;
  static Svg = Svg;

  setNativeProps(args: any) {
    return this.root && this.root.setNativeProps(args);
  }

  override render() {
    const props = this.props;

    return (
      <BaseCard
        ref={(c) => {
          this.root = c;
        }}
        {...props}
      />
    );
  }
}
