import React, { createRef, useState, useContext } from 'react';
import MicroFrontend, {
  GeneralEventTypes,
  authMFEMessageHandler,
  TokenInfo,
} from '../../services/microFrontend';
import env from '../../env';
import { getAuthVars, retrieveAuthCode } from './utils';
import { ServerError } from '../ServerError';
import StorageManager from '../../services/localStorage';
import { Platform } from 'react-native';
import {
  triggerAfterUserIdSet,
  useTrackEventFunction,
} from '../../containers/analytics';

export type TokenFetchAction = 'NONE' | 'REFRESH' | 'LOGIN' | 'ERROR';

export interface TokenProviderType {
  token?: TokenInfo | null;
  isAuthMFELoaded?: boolean;
  isAuthenticated: boolean;
  cookieLoginFailed: boolean;
  setToken: (token: TokenInfo) => void;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  logout: () => void;
  login: (url?: string) => void;
  logoutUrl?: string;
  loginUrl?: string;
  redirectUrl?: string;
  setLoginType?: (loginType: string) => void;
}
const initialFunc = (): void => {
  console.error('this function has not been set');
};

export const TokenContext = React.createContext<TokenProviderType>({
  token: null,
  isAuthenticated: false,
  isAuthMFELoaded: false,
  setToken: initialFunc,
  setIsAuthenticated: initialFunc,
  logout: initialFunc,
  login: initialFunc,
  cookieLoginFailed: false,
  redirectUrl: '',
  setLoginType: initialFunc,
});

export const AuthProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const trackEvent = useTrackEventFunction();

  const [ref, setRef] = useState(createRef<any>());
  const [isAuthMFELoaded, setIsAuthMFELoaded] = useState(false);
  const [showAuthError, setShowAuthError] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState({} as TokenInfo);
  const [loginUrl, setLoginUrl] = useState('');
  const [logoutUrl, setLogoutUrl] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [cookieLoginFailed, setCookieLoginFailed] = useState(false);
  const { platform, env: mvEnv, mfeEnv } = getAuthVars();

  const logout = () => {
    StorageManager.deleteAll().finally((): void => {
      if (!isAuthMFELoaded) {
        ref.current?.reload();
      }

      setShowAuthError(false);
      setToken({} as TokenInfo);
      setIsAuthenticated(false);
    });
  };

  const login = (url?: string) => {
    if (!isAuthMFELoaded) {
      console.error('Auth MFE not loaded');
      setShowAuthError(true);
      setCookieLoginFailed(true);
      return;
    }
    setCookieLoginFailed(false);
    if (url) {
      const code = retrieveAuthCode(url, redirectUrl);
      ref.current?.sendMFEmessage({ code }, GeneralEventTypes.LoginFromCode);
      return;
    }
    ref.current?.sendMFEmessage(
      { platform, env: mvEnv, mfeEnv },
      GeneralEventTypes.GetAuthParams
    );
  };

  const eventHandlerMethods = {
    logout,
    trackEvent,
    setIsAuthenticated,
    setIsAuthMFELoaded,
    setToken,
    setCookieLoginFailed,
    setRedirectUrl,
    setLoginUrl,
    setLogoutUrl,
    sendMFEmessage: (message: any, type: string) => {
      ref.current?.sendMFEmessage(message, type);
    },
    trackLoginEvent: (loginType: string) =>
      triggerAfterUserIdSet(() => {
        switch (loginType) {
          case 'cookie': {
            const isReloaded =
              Platform.OS === 'web' &&
              performance?.getEntriesByType('navigation')?.length &&
              (
                performance?.getEntriesByType(
                  'navigation'
                )[0] as PerformanceNavigationTiming
              )?.type === 'reload';
            if (!isReloaded || Platform.OS !== 'web') {
              trackEvent('Login', 'successful', { property: 'token' });
            }
            break;
          }
          case 'password':
            trackEvent('Login', 'successful', { property: 'password' });
            break;
        }
      }),
  };

  const onError = () => {
    console.error('MFE failed to load');
    setCookieLoginFailed(true);
    setShowAuthError(true);
  };

  const handleMessage = authMFEMessageHandler(eventHandlerMethods);

  return (
    <>
      <MicroFrontend
        ref={ref}
        hidden={true}
        id="authIframe"
        onError={onError}
        onMessage={handleMessage}
        url={env.microfrontends.auth.url}
        onLoad={() => {
          Platform.OS !== 'web' && setIsAuthMFELoaded(true);
        }}
      />
      <TokenContext.Provider
        value={{
          token,
          logout,
          setToken,
          setIsAuthenticated,
          isAuthenticated,
          isAuthMFELoaded,
          login,
          cookieLoginFailed,
          redirectUrl,
          loginUrl,
        }}
      >
        {showAuthError ? <ServerError /> : children}
      </TokenContext.Provider>
    </>
  );
};

export const useToken = (): TokenProviderType => useContext(TokenContext);
