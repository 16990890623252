import { Icon, StandAloneLink, Txt } from '@vst/beam';
import { Close, ErrorOutline } from '@vst/beam-icons/icons';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectStreamOnPromo,
  submitFullOrder,
  setOrderFail,
} from '@mfe/to-be-migrated/redux/streamOnPromo';
import styles from './errorToast.module.scss';
import { AddressInput } from '@mfe/shared/schema-types';

export const ErrorToast = () => {
  const { t } = useTranslation('ToastError');
  const dispatch = useDispatch();
  const {
    shippingAddress,
    isEditingNumber,
    userDetails,
    didOrderFail,
    orderAttempts,
  } = useSelector(selectStreamOnPromo);

  const errorToastRef = useRef<HTMLDivElement>(null);

  const MAX_ATTEMPTS = 3;

  useEffect(() => {
    errorToastRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <div className={styles['wrapperToast']}>
      <div
        className={styles['toastContainer']}
        ref={errorToastRef}
        data-cy="errorToast"
      >
        <div className={styles['iconContainer']}>
          <Icon
            icon={ErrorOutline}
            color="alert_300"
            data-cy="errorToastExclamationMark"
          />
        </div>
        <div className={styles['textContainer']}>
          <div className={styles['title']}>
            <Txt
              variant="bodyLargeBold"
              className={styles['titleText']}
              data-cy="errorToastTitle"
            >
              {orderAttempts >= MAX_ATTEMPTS ? t('TryLater.Title') : t('Title')}
            </Txt>
          </div>
          <div className={styles['subtitle']} data-cy="errorToastSubTitle">
            <Txt variant="bodySmallRegular">
              {orderAttempts >= MAX_ATTEMPTS
                ? t('TryLater.SubTitle')
                : t('SubTitle')}
            </Txt>
          </div>
          <div className={styles['actionLink']}>
            {orderAttempts < MAX_ATTEMPTS && (
              <StandAloneLink
                variant="primary"
                linkSize="medium"
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(
                    submitFullOrder({
                      address: shippingAddress as AddressInput,
                      isEditingNumber: isEditingNumber,
                      phoneNumber: userDetails?.phoneNumber,
                    })
                  );
                }}
                className={styles['linkText']}
                data-cy="retryErrorToastRequest"
              >
                {t('Retry')}
              </StandAloneLink>
            )}
          </div>
        </div>
        <div className={styles['closeButtonContainer']}>
          <Icon
            icon={Close}
            color="gray_700"
            onClick={() =>
              dispatch(setOrderFail(didOrderFail?.error?.failureReason ?? ''))
            }
            className={styles['closeButton']}
            data-cy="errorToastCloseButton"
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorToast;
