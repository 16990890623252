import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
  Text,
  Button,
  Color,
  useDimensionContext,
  useNavigate,
  ScreenSize,
  Accordion,
  AccordionCard,
} from '@mfe/legacy/andromeda';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { FaqQuestion, FaqText } from '@viasat/res-apps-lib/build/types/mv';
import { useTranslation } from 'react-i18next';
import {
  FaqCardProps,
  KnowledgeBaseProps,
  AnswerTextProp,
  HiddenContentProps,
  SectionValue,
} from '../types';
import { useTrackEventFunction } from '../../../containers';
import { DownloadAppIcon } from '../../../components/Icons';
import { HelpLink } from '../../../utils/Navigation';
import { isDefined } from '../../../utils';
import styles from './styles';

import { Platform } from '@mfe/shared/schema-types';
import { selectConfig } from '@mfe/shared/redux/config';

import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';

const KnowledgeBase = ({
  title,
  knowledgeBase,
}: KnowledgeBaseProps): JSX.Element | null => {
  const { t } = useTranslation('FAQ');

  const dispatch = useDispatch();
  if (!knowledgeBase) return null;
  const { location } = knowledgeBase;
  return (
    <TouchableOpacity
      style={{ flexDirection: 'row', marginTop: 12 }}
      onPress={(): void => {
        dispatch(openInNewTab(location));
      }}
      testID={`${title}-KnowledgeBase`}
    >
      <Text small link style={styles.moreHelp}>
        {t('learnMore')}
      </Text>
      <Icon
        size={18}
        name="open-in-new"
        color={Color.gray500}
        style={{ marginLeft: 5 }}
      />
    </TouchableOpacity>
  );
};

const AnswerText = ({ title, text }: AnswerTextProp): JSX.Element => {
  const dispatch = useDispatch();

  return (
    <View style={{ marginTop: 12 }} testID={`${title}-Hidden`}>
      {text.map((answer: FaqText, index: number): JSX.Element => {
        const { heading, body, interiorLink } = answer;
        const bodyArray = interiorLink
          ? body.split(interiorLink.label)
          : [body];
        return (
          <View style={{ marginTop: index > 0 ? 12 : undefined }} key={body}>
            {isDefined(heading) && (
              <Text bold style={{ marginBottom: 6 }}>
                {heading}
              </Text>
            )}
            {bodyArray.length > 1 && interiorLink ? (
              <Text>
                <Text>{bodyArray[0]}</Text>
                <Text
                  link
                  onPress={(): void => {
                    dispatch(openInNewTab(interiorLink.location));
                  }}
                >
                  {interiorLink.label}
                </Text>
                <Text>{bodyArray[1]}</Text>
              </Text>
            ) : (
              <Text>{body}</Text>
            )}
          </View>
        );
      })}
    </View>
  );
};

const HiddenContent = ({
  title,
  analyticsLabel,
  content,
  secondaryPressCallback,
}: HiddenContentProps): JSX.Element => {
  const { t } = useTranslation('FAQ');
  const { platform } = useSelector(selectConfig);
  const { isMobile } = useDimensionContext();
  const goTo = useNavigate();
  const trackEvent = useTrackEventFunction();
  const {
    knowledgeBase,
    text,
    primaryRedirect,
    secondaryRedirect,
    downloadApp,
  } = content;
  const renderDownloadApp = isDefined(downloadApp) && platform === Platform.Web;
  const margin = isMobile ? 24 : 32;

  const handlePrimaryPress = (location: string): void => {
    trackEvent('FAQ Action Button', 'selected', {
      label: analyticsLabel,
      property: title,
    });
    location === 'Home' ? goTo('') : goTo(location);
  };

  const handleSecondaryPress = (
    location: null | undefined | string,
    label: string
  ): void => {
    if (location !== '') {
      goTo(location, { drawer: true });
    }
    if (location === '') {
      secondaryPressCallback(label);
      return;
    }
  };

  const handleStillNeedHelpPress = (): void => {
    trackEvent('FAQ Still Need Help', 'selected', {
      label: analyticsLabel,
      property: title,
    });
    goTo(HelpLink.AgentChat, {
      drawer: true,
      params: { analyticsLabel },
    });
  };

  return (
    <View>
      {isDefined(text) && <AnswerText {...{ title, text }} />}
      {knowledgeBase && <KnowledgeBase {...{ title, knowledgeBase }} />}
      {primaryRedirect && !renderDownloadApp && (
        <Button
          onPress={(): void => {
            handlePrimaryPress(primaryRedirect.location);
            secondaryRedirect &&
              handleSecondaryPress(
                secondaryRedirect.location,
                secondaryRedirect.label
              );
          }}
          style={{
            alignSelf: 'flex-start',
            marginTop: margin,
            marginBottom: margin / 2,
          }}
          testID={`${title}-PrimaryButton`}
        >
          {primaryRedirect.label}
        </Button>
      )}
      {renderDownloadApp && (
        <View>
          {downloadApp &&
            downloadApp.map(
              (download, index) =>
                download && (
                  <DownloadAppIcon
                    key={index}
                    os={download.label === 'Apple' ? 'Apple' : 'Google'}
                    overrideLocation={download.location}
                  />
                )
            )}
        </View>
      )}

      <Button
        tertiary
        onPress={handleStillNeedHelpPress}
        color={Color.teal600}
        testID={`${title}-StillNeedHelp`}
        style={{
          alignSelf: 'flex-start',
          paddingHorizontal: 0,
          paddingBottom: 0,
        }}
      >
        {t('moreHelp')}
      </Button>
    </View>
  );
};

interface ChangeBillCycleModalProps {
  isVisible: boolean;
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FaqCard = ({
  section,
  faqSelection,
  handleSelect,
}: FaqCardProps): JSX.Element => {
  const testID = 'FaqCard';
  const { screenSize } = useDimensionContext();
  const isSmallScreen = screenSize <= ScreenSize.MD;
  const { title, analyticsTag: topicTag, questions } = section;
  const trackEvent = useTrackEventFunction();
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [showStatement, setShowStatement] = useState(false);
  const [isVisible, setVisibility] = useState(false);

  useEffect((): void => {
    if (!faqSelection || !faqSelection.question) {
      setCurrentIndex(null);
      return;
    }

    const matches = faqSelection.question.match(/([a-zA-Z\s]+)(\d+)/);
    if (!matches || !matches[1] || !matches[2]) return;

    const newIndex = section.title === matches[1] ? Number(matches[2]) : null;
    setCurrentIndex(newIndex);
  }, [faqSelection]);

  const onPress = (activeIndex: number, expanded: boolean): void => {
    const question = questions[activeIndex];
    const currentQuestion = title + activeIndex;
    // Button behavior is different for large screens due to filtering the list items.
    const skipSectionUpdate =
      !isSmallScreen && faqSelection.section == SectionValue.All;
    const section = skipSectionUpdate ? undefined : (title as SectionValue);
    handleSelect({ question: currentQuestion, section });
    // Due to the event tracking occuring simultaneously with state update, expanded's inverse is used to validate the event.
    const { title: questionTitle, analyticsTag: questionTag } = question;
    const analyticsLabel = `${topicTag}__${questionTag}`;
    expanded &&
      trackEvent('FAQ', 'expanded', {
        label: analyticsLabel,
        property: questionTitle,
      });
  };

  const secondaryPressCallback = (label: string): void => {
    switch (label) {
      case 'Modal':
        setVisibility(true);
        break;
      case 'Statement Modal':
        setShowStatement(true);
        break;
    }
  };

  const data = questions.map((question: FaqQuestion): AccordionCard => {
    const { title, analyticsTag: questionTag, content } = question;
    const analyticsLabel = `${topicTag}__${questionTag}`;

    return {
      title,
      content: (
        <HiddenContent
          title={title}
          analyticsLabel={analyticsLabel}
          content={content}
          secondaryPressCallback={secondaryPressCallback}
        />
      ),
    };
  });

  return (
    <Accordion
      data={data}
      onCardPressed={onPress}
      initialActiveIndex={currentIndex}
      testID={testID}
      visibleContentCustomization={{
        textProps: {
          semiBold: true,
          style: { flex: 1 },
        },
      }}
      cardProps={{
        removeShadow: true,
        contentStyle: { padding: isSmallScreen ? 24 : 32 },
        marginStyle: styles.marginStyle,
      }}
    />
  );
};
