import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  colors,
  RadioButton,
  RadioGroup,
  StandardModal,
  Surface,
  TextArea,
  Txt,
} from '@vst/beam';

import { useDispatch, useSelector } from 'react-redux';
import { useScreenResolution } from '@mfe/shared/util';

import { selectConfig } from '@mfe/shared/redux/config';
import {
  Platform,
  SendSurveyResultsToQualtricsInput,
} from '@mfe/shared/schema-types';
import {
  removeAddOn,
  sendSurveyResults,
} from '@mfe/to-be-migrated/redux/addOns';

import styles from './styles.module.scss';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';

type CancelSubscriptionModalProps = {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  addOnName: string;
};

const cancellationReasons = [
  {
    value: 'NoValue',
    label: 'cancelSubscription.reasons.first',
    id: '1',
  },
  {
    value: 'TooExpensive',
    label: 'cancelSubscription.reasons.second',
    id: '2',
  },
  {
    value: 'TechnicalDifficulties',
    label: 'cancelSubscription.reasons.third',
    id: '3',
  },
  {
    value: 'NoServiceNeeded',
    label: 'cancelSubscription.reasons.fourth',
    id: '4',
  },
  { value: 'Other', label: 'cancelSubscription.reasons.fifth', id: '5' },
];

export const CancelSubscriptionModal = ({
  id,
  isOpen,
  onClose,
  addOnName,
}: CancelSubscriptionModalProps) => {
  const { t } = useTranslation('ShopAddons');
  const dispatch = useDispatch();

  const { isExtraSmall, isSmall } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;

  const { platform } = useSelector(selectConfig);
  const {
    user: { accountNumber },
  } = useSelector(selectUser);

  const isMobileApp = platform !== Platform.Web;
  const modalContentClass = isMobileApp
    ? `${styles['mobilePadding']}`
    : `${styles['cancel-subscription-modal']}`;

  const [formData, setFormData] = useState({
    cancellationReason: '',
    additionalInfo: '',
  });

  const [acknowledgmentChecked, setAcknowledgmentChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleFormChange = (field: string, value: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleCancellationReasonChange = (option: string) => {
    handleFormChange('cancellationReason', option);
  };

  const handleAdditionalInfoChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    handleFormChange('additionalInfo', e.target.value);
  };

  const handleCheckboxChange = () => {
    setErrorMessage('');
    setAcknowledgmentChecked(!acknowledgmentChecked);
  };

  const validateForm = (): boolean => {
    if (!acknowledgmentChecked) {
      setErrorMessage(t('cancelSubscriptionModal.error'));
      return false;
    }
    setErrorMessage('');
    return true;
  };

  const sendSurveyAnswers = () => {
    const surveyData: SendSurveyResultsToQualtricsInput = {
      embeddedData: {
        billingAccountNumber: accountNumber,
        addOn: addOnName,
      },
      firstQuestionAnswer: formData.cancellationReason
        ? cancellationReasons.find(
            (reason) => reason.value === formData.cancellationReason
          )?.id || ''
        : '',
      secondQuestionAnswer: formData.additionalInfo || '',
    };
    dispatch(sendSurveyResults(surveyData));
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }
    dispatch(removeAddOn(id as string));
    sendSurveyAnswers();
    onClose();
  };

  const style = isMobile
    ? { display: 'flex', order: 1, flexBasis: 'unset' }
    : { flexBasis: 'unset' };

  const RadioOption = React.memo(
    ({
      option,
      checked,
      onChange,
    }: {
      option: string;
      checked: boolean;
      onChange: () => void;
    }) => (
      <RadioButton
        name="reasons-cancel-subscription"
        label={option}
        id={option}
        value={option}
        checked={checked}
        onChange={onChange}
        aria-required="false"
      />
    )
  );

  return (
    <StandardModal
      isOpen={isOpen}
      header={t('cancelSubscription.header', { addOnName })}
      actions={{
        primary: {
          label: t('cancelSubscription.primaryAction.label'),
          onClick: handleSubmit,
          style: { backgroundColor: colors.alert[400], minWidth: 200 },
        },
        secondary: {
          label: t('cancelSubscription.secondaryAction.label'),
          onClick: onClose,
          style,
        },
      }}
      onClose={onClose}
      size="medium"
      surfaceProps={{ className: modalContentClass }}
    >
      <div>
        <Surface>
          <Txt
            variant={'bodyLargeBold'}
            color={'regular'}
            component={'span'}
            style={{ display: 'inline-block' }}
            mb={'8px'}
          >
            {t('cancelSubscription.title')}
          </Txt>
          <Txt variant={'bodySmallRegular'} color={'regular'}>
            {t('cancelSubscription.info')}
          </Txt>
        </Surface>

        <Surface mb={'24px'}>
          <RadioGroup
            name="reasons-cancel-subscription"
            labelProps={{
              labelText: t('cancelSubscription.radioGroup.labelText'),
              optionalText: t('cancelSubscription.optionalText'),
            }}
            aria-labelledby="reason-cancel-subscription-group"
          >
            {cancellationReasons.map((option) => (
              <RadioOption
                key={option.id}
                option={`${t(option.label)}`}
                checked={formData.cancellationReason === option.value}
                onChange={() => handleCancellationReasonChange(option.value)}
              />
            ))}
          </RadioGroup>
        </Surface>

        <Surface mb={'24px'}>
          <TextArea
            id="additionalInfo"
            name="additionalInfo"
            value={formData.additionalInfo || ''}
            labelProps={{
              labelText: t('cancelSubscription.additionalInfo.label'),
              optionalText: t('cancelSubscription.optionalText'),
            }}
            fluid
            onChange={handleAdditionalInfoChange}
            rows={1}
          />
        </Surface>

        <Surface variant={'secondary'} radius={'8px'} responsive>
          <Txt
            variant={'bodySmallBold'}
            color={'regular'}
            style={{ display: 'inline-block' }}
            component={'span'}
            pb={'16px'}
          >
            {t('cancelSubscriptionModal.warning', { addOnName })}
          </Txt>
          <Checkbox
            id="terms"
            checked={acknowledgmentChecked}
            required
            onChange={handleCheckboxChange}
            label={t('cancelSubscriptionModal.acknowledgment.label')}
            error={!!errorMessage}
            aria-required="true"
            data-cy="terms-checkbox"
          />
          {errorMessage && (
            <Txt color="error" variant="bodySmallRegular" mt="8px">
              {errorMessage}
            </Txt>
          )}
        </Surface>
      </div>
    </StandardModal>
  );
};
