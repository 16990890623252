import { Button, Txt } from '@vst/beam';
import { useScreenResolution } from '@mfe/shared/util';

import styles from './Form.module.scss';
import { useTranslation } from 'react-i18next';

interface FormFooterProps {
  handleCancel: () => void;
}

export const FormFooter = (props: FormFooterProps) => {
  const { handleCancel } = props;
  const { t } = useTranslation('ACP', { keyPrefix: 'Form' });

  const { isExtraSmall, isSmall } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;

  return (
    <div className={styles['footer']}>
      <Button
        variant="secondary"
        onClick={handleCancel}
        fluid={isMobile}
        data-cy="acp-form-cancel"
      >
        <Txt variant="labelMedium">{t('footer.buttons.cancel')}</Txt>
      </Button>
      <Button
        type="submit"
        variant="primary"
        fluid={isMobile}
        data-cy="acp-form-continue"
      >
        <Txt variant="labelMedium">{t('footer.buttons.confirm')}</Txt>
      </Button>
    </div>
  );
};
