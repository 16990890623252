import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  setEditAddress,
  setIsEdittingAddress,
  setShippingAddress,
} from '@mfe/to-be-migrated/redux/streamOnPromo';
import { Txt, Icon, Button, IllustrativeIcon } from '@vst/beam';
import { Close } from '@vst/beam-icons/icons';
import { RoadMap } from '@vst/beam-icons/illustrative-icons';
import { selectStreamOnPromo } from '@mfe/to-be-migrated/redux/streamOnPromo';
import { SelectionCard } from '../SelectionCard';
import styles from './ConfirmAddressModal.module.scss';
import { useScreenResolution } from '@mfe/shared/util';
import ErrorModal from './ErrorModal';

enum CardOptions {
  SuggestedAddress = 'SuggestedAddress',
  EnteredAddress = 'YouEntered',
}

interface ConfirmAddressModalProps {
  children?: ReactNode;
  onClose?: () => void;
  show: boolean;
}

export const ConfirmAddressModal: React.FC<ConfirmAddressModalProps> = ({
  show,
  onClose,
}) => {
  const { scrubLocation, editAddress } = useSelector(selectStreamOnPromo);
  const dispatch = useDispatch();
  const { isMedium, isSmall, isExtraSmall } = useScreenResolution();
  const isMobileSize = isMedium || isSmall || isExtraSmall;
  const { t } = useTranslation('Shipping');
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    if (!scrubLocation) {
      setShowErrorModal(true);
    }
  }, [scrubLocation, showErrorModal]);

  const concatAddress = () => {
    if (editAddress.addressLines[1] !== '' || editAddress.addressLines[1]) {
      return `${editAddress.addressLines[0]}, ${editAddress.addressLines[1]}, ${scrubLocation?.municipality}, ${scrubLocation?.region}, ${editAddress.postalCode}`;
    }
    return `${editAddress.addressLines[0]}, ${scrubLocation?.municipality}, ${scrubLocation?.region}, ${editAddress.postalCode}`;
  };

  const [selectedCard, setSelectedCard] = useState<CardOptions>(
    CardOptions.SuggestedAddress
  );

  const handleAddressSelect = () => {
    if (selectedCard === CardOptions.SuggestedAddress) {
      if (scrubLocation) dispatch(setShippingAddress(scrubLocation));
    } else {
      if (scrubLocation)
        dispatch(
          setShippingAddress({
            addressLines: [
              editAddress.addressLines[0],
              editAddress.addressLines[1],
            ],
            countryCode: scrubLocation.countryCode,
            municipality: scrubLocation.municipality,
            postalCode: editAddress.postalCode,
            region: scrubLocation.region,
          })
        );
    }
    dispatch(setIsEdittingAddress(false));
    dispatch(
      setEditAddress({
        addressLines: ['', ''],
        postalCode: '',
        countryCode: '',
        municipality: '',
        region: '',
      })
    );
    setSelectedCard(CardOptions.SuggestedAddress);
    if (onClose) {
      onClose();
      setShowErrorModal(false);
    }
  };

  const modalNoError = (
    <div className={styles['twoContainer']}>
      <div className={styles['wrapper']}>
        {!isMobileSize && (
          <IllustrativeIcon
            icon={RoadMap}
            color="teal_600"
            mb="16px"
            mt="40px"
          />
        )}
        <div className={styles['topTextContainer']}>
          <Txt
            data-cy="addressTitle"
            variant={isMobileSize ? 'heading5' : 'heading2'}
            color="inherit"
          >
            {t('AddressScrub.Title')}
          </Txt>
          <Txt data-cy="addressSubTitle" variant="bodyLargeRegular">
            {t('AddressScrub.SubTitle')}
          </Txt>
        </div>
        <SelectionCard
          data-cy="selectedCard"
          className={styles['containerAddressButtonsChecked']}
          checked={selectedCard === CardOptions.SuggestedAddress}
          value={CardOptions.SuggestedAddress}
          onClickHandler={() => setSelectedCard(CardOptions.SuggestedAddress)}
          labelRadioButton={t('AddressScrub.Suggest')}
          subtitle={`${scrubLocation?.addressLines[0]}, ${
            scrubLocation?.addressLines[1] || ''
          } ${scrubLocation?.municipality}, ${scrubLocation?.region}, ${
            scrubLocation?.postalCode
          }`}
        />
        <SelectionCard
          data-cy="addressEntered"
          className={styles['containerAddressButtonsChecked']}
          checked={selectedCard === CardOptions.EnteredAddress}
          value={CardOptions.EnteredAddress}
          onClickHandler={() => setSelectedCard(CardOptions.EnteredAddress)}
          labelRadioButton={t('AddressScrub.Entered')}
          subtitle={`${concatAddress()}`}
        />
      </div>
      <div className={styles['buttonsContainer']}>
        <Button
          data-cy="useEditButton"
          variant="secondary"
          fluid={isMobileSize}
          onClick={onClose}
        >
          <Txt data-cy="useEditText" variant="labelLarge">
            {t('AddressScrub.Button.Edit')}
          </Txt>
        </Button>
        <Button
          data-cy="useSuggesteButton"
          variant="primary"
          fluid={isMobileSize}
          onClick={handleAddressSelect}
        >
          <Txt data-cy="useSuggesteText" variant="labelLarge">
            {t('AddressScrub.Button.UseSuggested')}
          </Txt>
        </Button>
      </div>
    </div>
  );

  const modalContent = show ? (
    <div className={styles['container']}>
      <div className={styles['closeButton']}>
        <Icon icon={Close} onClick={onClose} color="gray_800" />
      </div>
      {showErrorModal ? <ErrorModal /> : modalNoError}
    </div>
  ) : null;
  return modalContent;
};

export default ConfirmAddressModal;
