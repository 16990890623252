import React, { useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { useMobileAppEvents } from './mobileAppEvents';
import '@mfe/services/translations-service';
import {
  ApolloProviders,
  AnalyticsProvider,
  RocketChatProvider,
} from '@mfe/legacy/mv/containers';
import {
  DimensionContextProvider,
  useWebDrawer,
  WebDrawerProvider,
} from '@mfe/legacy/andromeda';
import Navigator from './Navigator';
import { routes } from './containers/DrawerContainer';
import {
  pixelTrackScreenFunction,
  pixelEventLogFunction,
  pixelLogUnstructuredEventFunction,
  pixelSetUserId,
} from './services/pixel';
import env from '@mfe/legacy/mv/env';
import { useTimeout } from './utils';
import { ServerError } from '@mfe/legacy/mv/views/ServerError';
import { useToken } from '@mfe/legacy/mv/views/Auth';
import {
  Provider as ReduxProvider,
  useDispatch,
  useSelector,
} from 'react-redux';
import { store } from '@mfe/to-be-migrated/redux/store';
import {
  REDIRECT_PATH_COOKIE_NAME,
  selectUser,
} from '@mfe/to-be-migrated/redux/auth';
import { WebAuthProvider } from './views/WebAuthProvider';
import FullPageLoading from './views/FullPageLoading';
import packageJson from '@mfe/legacy/mv/myversion.json';
import Cookies from 'js-cookie';
import RegistrationProvider from './views/Registration';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import TermsAndPushContainer from './containers/TermsAndPushContainer';
import { BeamProvider, ToastContainer } from '@vst/beam';
import {
  selectConfig,
  setConfig,
  getMaintenanceStatus,
} from '@mfe/shared/redux/config';
import { useSetUserID, SetUserIDFunction } from '@mfe/legacy/mv/containers';
import StorageManager from '@mfe/legacy/mv/services/localStorage';
import { raygun } from '@mfe/legacy/mv/services/raygun';
import { useAddTranslations } from './useAddTranslations';
import { Maintenance } from '@mfe/features/errors';
import i18n from '@mfe/services/translations-service';
import { I18nLocaleProvider } from '@mfe/shared/components';

//How long we should wait for auth MFE to loads
const AUTH_MFE_TIMEOUT_MS = 20000;

const setStoreAnalyticsUserId = async (
  partyId: string,
  setUserId: SetUserIDFunction
) => {
  await StorageManager.set('user_id', partyId, { secure: true });
  setUserId(partyId);
};

const App = (): JSX.Element => {
  const { pathname } = useLocation();
  const { loading, user } = useSelector(selectUser);
  const { inMaintenance, loadingConfig } = useSelector(selectConfig);
  const { isAuthenticated, isAuthMFELoaded } = user.auth;
  const {
    locale: { userLocale },
  } = useSelector(selectLocale);
  const dispatch = useDispatch();
  const { login } = useToken();

  useMobileAppEvents();
  useAddTranslations();

  useEffect(() => {
    if (isAuthenticated && userLocale)
      dispatch(setConfig({ version: packageJson.version }));
  }, [isAuthenticated, userLocale]);

  useEffect((): void => {
    const redirectPath = Cookies.get(REDIRECT_PATH_COOKIE_NAME);
    if (!redirectPath) {
      Cookies.set(REDIRECT_PATH_COOKIE_NAME, pathname);
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!isAuthMFELoaded && inMaintenance === null)
      dispatch(getMaintenanceStatus());

    if (inMaintenance !== null && inMaintenance === false) {
      if (isAuthMFELoaded) login(window.location.href);
    }

    if (inMaintenance) i18n.changeLanguage(navigator.language);
  }, [isAuthMFELoaded, inMaintenance]);

  const setUserId = useSetUserID();
  useEffect(() => {
    if (loading) return;

    setStoreAnalyticsUserId(user.partyId, setUserId);
    raygun.setUser({
      username: user.auth.username,
      accountNumber: user.accountNumber,
    });
  }, [loading, setUserId, user]);

  const [authTimeout, setAuthTimeout] = React.useState(false);
  useTimeout(() => setAuthTimeout(true), AUTH_MFE_TIMEOUT_MS);

  if (inMaintenance) return <Maintenance />;

  if (!isAuthenticated && authTimeout) {
    return <ServerError />;
  }

  const noProductKind = user.productKind === null;

  if (!isAuthenticated || noProductKind || loadingConfig) {
    return <FullPageLoading />;
  }

  return (
    <TermsAndPushContainer>
      <Navigator />
    </TermsAndPushContainer>
  );
};

const DrawerWrapper = (): JSX.Element => {
  const { setWebDrawerRoutes } = useWebDrawer();
  useEffect(() => {
    setWebDrawerRoutes(routes);
  }, []);

  return <App />;
};

const AppWrapper = (): JSX.Element => {
  return (
    <ReduxProvider store={store}>
      <I18nLocaleProvider>
        <AnalyticsProvider
          logEvents={[pixelEventLogFunction]}
          logUnstructuredEvents={[pixelLogUnstructuredEventFunction]}
          trackScreens={[pixelTrackScreenFunction]}
          setUserId={[pixelSetUserId]}
          startTime={new Date()}
        >
          <DimensionContextProvider>
            <Router>
              <WebAuthProvider>
                <ApolloProviders URI={env.backEndUrls.backEndUrl}>
                  <RegistrationProvider>
                    <RocketChatProvider>
                      <WebDrawerProvider>
                        <BeamProvider withToastProvider>
                          <ToastContainer
                            className={'toast-position-override'}
                            position={'bottom-center'}
                          />
                          <DrawerWrapper />
                        </BeamProvider>
                      </WebDrawerProvider>
                    </RocketChatProvider>
                  </RegistrationProvider>
                </ApolloProviders>
              </WebAuthProvider>
            </Router>
          </DimensionContextProvider>
        </AnalyticsProvider>
      </I18nLocaleProvider>
    </ReduxProvider>
  );
};

export default AppWrapper;
