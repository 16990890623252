import { useTranslation } from 'react-i18next';
import { EmptyState } from '@vst/beam';
import { Alert } from '@vst/beam-icons/illustrative-icons';

import useNavigate from '@mfe/services/navigation';
import { NetworkLink } from '@mfe/legacy/mv/utils/Navigation';

import styles from './styles.module.scss';

export const RebootError = () => {
  const { replace } = useNavigate();

  const { t } = useTranslation('Network', { keyPrefix: 'rebootModem' });
  return (
    <div className={styles['pageContent']}>
      <EmptyState
        title={t('error.title')}
        icon={Alert}
        description={t('error.description')}
        actions={{
          primary: {
            label: t('error.button'),
            minWidth: '200px',
            onClick: () => replace(NetworkLink.Network),
          },
        }}
      />
    </div>
  );
};
