import { HelperText, TextField } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import { FormFieldEnum } from '../../utils';

import styles from './Form.module.scss';

interface FirstNameFieldProps {
  value: string;
  error: string;
  isValid: boolean;
  handleChange: React.FormEventHandler<HTMLInputElement>;
}

export const FirstNameField = (props: FirstNameFieldProps) => {
  const { value, handleChange, error, isValid } = props;
  const { t } = useTranslation('ACP', { keyPrefix: 'Form' });

  const hasError = error !== '' && !isValid;

  return (
    <>
      <TextField
        data-cy="acp-first-name"
        fluid
        name={FormFieldEnum.FIRST_NAME}
        labelProps={{ labelText: t('fields.firstName') }}
        value={value}
        onChange={handleChange}
      />

      {hasError && (
        <HelperText
          state="error"
          helpMessage={error}
          className={styles['helper-text']}
        />
      )}
    </>
  );
};
