import { ModalTypeEnum, ModalComponent } from '@mfe/shared/components';
import { CheckMark } from '@vst/beam-icons/illustrative-icons';
import { useTranslation } from 'react-i18next';
import Outcome from './Outcome';

interface SuccessOutcomeProps {
  goBackToOverviewPage: () => void;
  saveVariableInSessionStorage: () => void;
  isMobile: boolean;
}

export const SuccessOutcome = (props: SuccessOutcomeProps) => {
  const { isMobile, goBackToOverviewPage, saveVariableInSessionStorage } =
    props;
  const { t } = useTranslation('ACP', { keyPrefix: 'Success' });

  const handleDone = () => {
    saveVariableInSessionStorage();
    goBackToOverviewPage();
  };

  const renderOutcome = () => {
    return (
      <Outcome
        iconName={CheckMark}
        title={t('title')}
        text={t('description')}
        secondaryText={t('secondaryDescription')}
        primaryButton={{
          label: t('primaryButton'),
          handleClick: handleDone,
        }}
      />
    );
  };

  return isMobile ? (
    renderOutcome()
  ) : (
    <ModalComponent type={ModalTypeEnum.ACP}>{renderOutcome()}</ModalComponent>
  );
};
