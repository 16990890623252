import Cookies from 'js-cookie';
import StorageManager, { Get, Set, Delete, Secure, DeleteAll } from './types';

const COOKIE_LIFETIME_DAYS = 14;

const setWeb: Set = (
  key: string,
  value: string,
  { secure = true, expiresInDays = COOKIE_LIFETIME_DAYS }: Secure = {}
): Promise<void> => {
  return new Promise((resolve): void => {
    // We are unable to set secure cookies on localhost
    Cookies.set(key, value, {
      secure: window.location.hostname.includes('localhost') ? false : secure,
      expires: expiresInDays,
    });
    resolve();
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getWeb: Get = (
  key: string,
  { secure }: Secure = {}
): Promise<string | undefined> => {
  return new Promise((resolve): void => {
    resolve(Cookies.get(key));
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const deleteWeb: Delete = (
  key: string,
  { secure }: Secure = {}
): Promise<void> => {
  return new Promise((resolve): void => {
    Cookies.remove(key);
    resolve();
  });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const deleteAllWeb: DeleteAll = ({ secure }: Secure = {}): Promise<void> => {
  return new Promise((resolve): void => {
    Object.keys(Cookies.get()).forEach((key) => {
      Cookies.remove(key);
    });
    resolve();
  });
};

const CookiesManager: StorageManager = {
  set: setWeb,
  get: getWeb,
  delete: deleteWeb,
  deleteAll: deleteAllWeb,
};

export default CookiesManager;
