import React from 'react';
import { Icon } from '@vst/beam';
import { Menu, Close } from '@vst/beam-icons/icons';
import styles from './hamburger.module.scss';

const HamburgerMenuButton = ({
  isOpen,
  onClick,
}: {
  isOpen: boolean;
  onClick: () => void;
}) => {
  const icon = isOpen ? Close : Menu;

  return (
    <div
      className={styles.hamburgerButton}
      onClick={onClick}
      data-cy="mobileMenuToggle"
    >
      <Icon icon={icon} size={28} mr="8px" />
    </div>
  );
};

export default HamburgerMenuButton;
