import React from 'react';
import { Txt, StandAloneLink, Tooltip, Icon } from '@vst/beam';
import { Help } from '@vst/beam-icons/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectStreamOnPromo,
  setIsEditingNumber,
} from '@mfe/to-be-migrated/redux/streamOnPromo';
import { useTranslation } from '../../utils';

import styles from './sms.module.scss';
import { createPhoneNumber } from '../../utils';
import { PhoneNumberContainer } from '../PhoneNumberContainer';
import { selectConfig } from '@mfe/shared/redux/config';

export const Sms = () => {
  const { isEditingNumber, userDetails } = useSelector(selectStreamOnPromo);
  const { platform } = useSelector(selectConfig);
  const dispatch = useDispatch();

  const { phoneNumber } = userDetails ?? {};
  const { t } = useTranslation('SMS');

  const isSMSWindowOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    dispatch(setIsEditingNumber(!isEditingNumber));
  };

  let formattedNumber = t('PhoneNumber.NotFound');

  try {
    const createdPhoneNumber = createPhoneNumber(phoneNumber as string);
    formattedNumber = createdPhoneNumber.formattedNumber;
  } catch (error) {
    console.log(error);
  }

  return (
    <div className={styles['SmsContainer']} data-cy="sms">
      <Txt
        variant="bodyLargeBold"
        data-cy="mobilePhoneNumberTitle"
        mb="12px"
        color="regular"
      >
        {t('Opt.Title')}
      </Txt>
      <div className={styles['subtitleContainer']}>
        <Txt
          variant="bodySmallRegular"
          data-cy="mobilePhoneNumberSubtitle"
          mb="12px"
        >
          {t('Opt.Subtitle')}
        </Txt>
        <Tooltip
          style={{ width: 200 }}
          bodyText={t('Opt.Tooltip')}
          position="top"
          beakPosition={platform === 'Web' ? 'start' : 'end'}
        >
          <Icon icon={Help} />
        </Tooltip>
      </div>

      {isEditingNumber ? (
        <PhoneNumberContainer formattedNumber={formattedNumber} />
      ) : (
        <>
          <Txt
            data-cy="actualPhoneNumber"
            variant="bodyLargeRegular"
            className={styles['phoneNumber']}
          >
            {formattedNumber}
          </Txt>
          <StandAloneLink
            href=""
            variant="primary"
            linkSize="large"
            onClick={(e) => isSMSWindowOpen(e)}
            openInNewLocation={false}
            className={styles['linkPhoneNumber']}
            data-cy="changePhoneNumberBtn"
          >
            {/\d/.test(formattedNumber) ? t('Change.Button') : t('Add.Button')}
          </StandAloneLink>
        </>
      )}
    </div>
  );
};
