import { Payment, PendingPayment } from '@mfe/shared/schema-types';

export const PAYMENT_TYPE = {
  refund: 'isRefund',
  recurring: 'isRecurring',
  oneTime: 'oneTime',
} as const;

export const getPaymentType = (payment: Payment | PendingPayment | null) => {
  if (!payment) {
    return undefined;
  }

  if (payment.isRefund) {
    return PAYMENT_TYPE.refund;
  }

  if (payment.isRecurring) {
    return PAYMENT_TYPE.recurring;
  }

  return PAYMENT_TYPE.oneTime;
};
