import { useDispatch } from 'react-redux';
import { useMutation, gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Domains,
  EventTypes,
  WindowMessageData,
} from '@mfe/services/window-messages';
import { openInNewTab, postMessage } from '@mfe/to-be-migrated/redux/utils';
import { AnalyticsContext } from '@mfe/services/analytics';
import { Storage, useScreenResolution } from '@mfe/shared/util';

import {
  formatDate,
  FormValues,
  useFormState,
  FormFieldName,
  getEmptyFields,
  useRetryCounter,
  useStoreContext,
  handleMockRequest,
  shouldUseMockResponse,
  MockApplicationIdValue,
} from '../../utils';
import { PageName, Benefits } from '../../analytics';
import { FormFooter } from './FormFooter';
import { FormContent } from './FormContent';
import { ErrorTypes } from '../Outcome/ErrorOutcome';
import { Card, CardTitle, cardStyles } from '../Card';
import TermsAndConditionsModal from './TermsAndConditionsModal';
import { LINKS, ACP_BENEFIT_REGISTERED_KEY } from '../../constants';
import { Loading, SuccessOutcome, ErrorOutcome, ContactUs } from '../Outcome';
import {
  structuredEvent,
  AnalyticsAction,
  Categories,
} from '@mfe/to-be-migrated/redux/analytics';

const NLAD_ACP_VERIFY_SAVE = gql`
  mutation verifyAndSaveACP($input: VerifyAndSaveAcpInput!) {
    verifyAndSaveACP(input: $input) {
      message
      name
      trace_id
      corrective_action
      error_type
    }
  }
`;

enum TransactionType {
  Enroll = 'Enroll',
  Transfer = 'Transfer',
}

interface FormProps {
  handleCancel: () => void;
  redirectToOverview: () => void;
  analyticsContext: AnalyticsContext[];
}

export const Form = (props: FormProps) => {
  const { redirectToOverview, handleCancel, analyticsContext } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation('ACP');
  const { partyId, stateCode } = useStoreContext();
  const { canRetry, incrementRetryCounter } = useRetryCounter(2);

  const {
    applicationId,
    firstName,
    lastName,
    dob,
    isTransferring,
    hasTribalBenefit,
    terms,
    errors,
    validateDOB,
    getFieldHandler,
    fieldsToValidate,
    addFieldsToValidate,
  } = useFormState();

  const isFormValid = Object.values(errors).every((error) => error === '');

  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;

  const [success, setSuccess] = useState(false);
  const [errorType, setErrorType] = useState<ErrorTypes | null>(null);
  const [showContactUs, setShowContactUs] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  const hasError = errorType !== null;
  const hasTribalOrTransfer = hasTribalBenefit || isTransferring;

  const [verifyAndSaveCustomer, { loading }] = useMutation(
    NLAD_ACP_VERIFY_SAVE,
    {
      variables: {
        input: {
          customerPartyId: partyId,
          stateCode,
          dateOfBirth: formatDate(dob),
          applicationId,
          transactionType: isTransferring
            ? TransactionType.Transfer
            : TransactionType.Enroll,
          hasTribalBenefit,
          firstName,
          lastName,
        },
      },
      onError: () => {
        setErrorType(ErrorTypes.SERVICE_UNAVAILABLE);
        dispatch(
          structuredEvent({
            category: Categories.ACP,
            action: AnalyticsAction.FAILED,
            params: {
              context: analyticsContext,
              property: ErrorTypes.SERVICE_UNAVAILABLE,
            },
          })
        );
      },
      onCompleted: (data) => {
        if (
          data?.verifyAndSaveACP?.error_type ||
          data?.verifyAndSaveACP === null
        ) {
          const errorName =
            (data?.verifyAndSaveACP?.name as ErrorTypes) ??
            ErrorTypes.GENERAL_ERROR;

          setErrorType(errorName);
          dispatch(
            structuredEvent({
              category: Categories.ACP,
              action: AnalyticsAction.FAILED,
              params: {
                context: analyticsContext,
                property: errorName,
              },
            })
          );
        }
        if (data?.verifyAndSaveACP?.message === t('responseMessage')) {
          dispatch(
            structuredEvent({
              category: Categories.ACP,
              action: AnalyticsAction.SUCCESS,
              params: {
                context: analyticsContext,
              },
            })
          );

          setSuccess(true);
        }
      },
    }
  );

  const tryAgain = () => {
    setErrorType(null);
    incrementRetryCounter();

    if (shouldUseMockResponse(applicationId as MockApplicationIdValue)) {
      handleMockRequest(applicationId, setSuccess, setErrorType, t);
    } else {
      verifyAndSaveCustomer();
    }
  };

  const isModalOpen =
    loading || success || hasError || showContactUs || showTermsAndConditions;

  useEffect(() => {
    if (isMobile && isModalOpen) {
      const message: WindowMessageData = {
        domain: Domains.General,
        eventType: EventTypes.Resize,
        data: {
          height: 0,
        },
      };

      dispatch(postMessage(message));
    }
  }, [isMobile, isModalOpen, dispatch]);

  useEffect(() => {
    dispatch(
      structuredEvent({
        category: Categories.ACP,
        action: AnalyticsAction.DISPLAYED,
        params: {
          label: PageName.ACP_FLOW_PAGE,
        },
      })
    );
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const emptyFields = getEmptyFields({
      applicationId,
      firstName,
      lastName,
      dob,
      isTransferring,
      hasTribalBenefit,
      terms,
    }) as FormFieldName[];
    addFieldsToValidate(emptyFields);

    if (isFormValid) {
      const selectedBenefits = getSelectedBenefits(
        isTransferring,
        hasTribalBenefit
      );
      dispatch(
        structuredEvent({
          category: Categories.ACP,
          action: AnalyticsAction.SUBMITTED,
          params: {
            label: selectedBenefits,
            context: analyticsContext,
          },
        })
      );

      if (hasTribalOrTransfer) {
        dispatch(
          structuredEvent({
            category: Categories.ACP,
            action: AnalyticsAction.CONTACT_US_DISPLAYED,
            params: {
              label: selectedBenefits,
              context: analyticsContext,
            },
          })
        );
        return setShowContactUs(true);
      }

      if (shouldUseMockResponse(applicationId as MockApplicationIdValue)) {
        handleMockRequest(applicationId, setSuccess, setErrorType, t);
      } else {
        return verifyAndSaveCustomer();
      }
    }
  };

  const openNationalVerifierInNewTab = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(openInNewTab(LINKS.NATIONAL_VERIFIER));
    setErrorType(null);
  };

  const openTermsAndConditions = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    return setShowTermsAndConditions(true);
  };

  const saveVariableInSessionStorage = () =>
    Storage.setItem(ACP_BENEFIT_REGISTERED_KEY, true);

  const modals = (
    <>
      {showTermsAndConditions && (
        <TermsAndConditionsModal
          closeModal={() => setShowTermsAndConditions(false)}
        />
      )}
      {loading && <Loading isMobile={isMobile} />}
      {success && (
        <SuccessOutcome
          isMobile={isMobile}
          goBackToOverviewPage={redirectToOverview}
          saveVariableInSessionStorage={saveVariableInSessionStorage}
        />
      )}
      {hasError && (
        <ErrorOutcome
          errorType={errorType}
          canRetry={canRetry}
          tryAgain={tryAgain}
          closeModal={() => setErrorType(null)}
          goBackToOverviewPage={redirectToOverview}
          openNationalVerifierInNewTab={openNationalVerifierInNewTab}
        />
      )}
      {showContactUs && (
        <ContactUs
          closeModal={() => setShowContactUs(false)}
          goBackToOverviewPage={redirectToOverview}
        />
      )}
    </>
  );

  const formCard = (
    <Card>
      <form data-cy="acp-form" onSubmit={handleSubmit}>
        <div className={cardStyles['content']}>
          <CardTitle />
          <FormContent
            values={{
              applicationId,
              firstName,
              lastName,
              dob,
              isTransferring,
              hasTribalBenefit,
              terms,
            }}
            errors={errors}
            analyticsContext={analyticsContext}
            getFieldHandler={getFieldHandler}
            validateDOB={validateDOB}
            fieldsToValidate={fieldsToValidate}
            openTermsAndConditions={openTermsAndConditions}
          />
        </div>
        <FormFooter handleCancel={handleCancel} />
      </form>
    </Card>
  );

  if (isMobile) {
    return isModalOpen ? modals : formCard;
  }

  return (
    <>
      {modals}
      {formCard}
    </>
  );
};

function getSelectedBenefits(
  isTransferring: FormValues['isTransferring'],
  hasTribalBenefit: FormValues['hasTribalBenefit']
) {
  if (isTransferring && hasTribalBenefit) {
    return Benefits.BOTH;
  }
  if (isTransferring) {
    return Benefits.TRANSFER;
  }
  if (hasTribalBenefit) {
    return Benefits.TRIBAL;
  }
  return Benefits.NONE;
}

export default Form;
