import { FormValues } from './types';

const isFieldEmpty = (value: string | boolean | null) =>
  Boolean(value) === false;

const isDateFieldEmpty = (dateField: FormValues['dob']) =>
  Object.values(dateField).some(isFieldEmpty);

export const getEmptyFields = (formValues: FormValues) =>
  Object.keys(formValues).filter((fieldName) => {
    const fieldValue = formValues[fieldName as keyof FormValues];

    const isDate = fieldValue !== null && typeof fieldValue === 'object';

    if (isDate) {
      return isDateFieldEmpty(fieldValue);
    }

    return isFieldEmpty(fieldValue as string | boolean | null);
  });
