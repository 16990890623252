import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Icon, Txt } from '@vst/beam';
import { TodayOutlined } from '@vst/beam-icons/icons';

import { selectBillingAccount } from '@mfe/to-be-migrated/redux/billingInfo';

import styles from './styles.module.scss';

export const CurrentBillDate = () => {
  const { t } = useTranslation(['UpdateBillCycle', 'Global']);

  const billingAccount = useSelector(selectBillingAccount);

  if (!billingAccount?.billingCycleDayOfMonth) {
    return null;
  }

  return (
    <div
      data-cy="current-bill-date"
      className={styles['current-bill-date-container']}
    >
      <Txt variant="labelSmall" color="subtle">
        {t('UpdateBillCycle:currentBillDateTitle')}
      </Txt>
      <div className={styles['current-bill-date']}>
        <Icon icon={TodayOutlined} size={24} />
        <Txt variant="bodySmallBold" color="regular">
          {t('Global:dayOfTheMonth_ordinal', {
            count: billingAccount.billingCycleDayOfMonth,
            ordinal: true,
          })}
        </Txt>
      </div>
    </div>
  );
};
