import React from 'react';

const EasyCare = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.0921 31.515L27.4015 31.2065L27.5341 30.7657C27.5341 30.7657 27.4899 30.7657 27.4899 30.7217L26.5176 31.6914C26.6944 31.6473 26.9153 31.6032 27.0921 31.515Z"
      fill="#202E39"
    />
    <path
      d="M24.6172 31.7797L26.5619 29.8404C26.4735 29.7522 26.4293 29.7081 26.3409 29.62L24.2637 31.6916C24.3963 31.7357 24.5288 31.7797 24.6172 31.7797Z"
      fill="#202E39"
    />
    <path
      d="M22.9815 31.2506L25.4565 28.7823C25.3681 28.6941 25.3239 28.65 25.2355 28.5619L22.7163 31.0743C22.8047 31.1184 22.8931 31.1624 22.9815 31.2506Z"
      fill="#202E39"
    />
    <path
      d="M21.7 30.3691L24.3959 27.6803C24.3075 27.5922 24.2633 27.504 24.2191 27.4159L21.479 30.1487C21.5232 30.2368 21.6116 30.2809 21.7 30.3691Z"
      fill="#202E39"
    />
    <path
      d="M20.6392 29.2228L23.9539 25.917C23.9981 25.6966 24.0865 25.4763 24.1748 25.2999L20.4624 28.9583C20.5066 29.0465 20.595 29.1347 20.6392 29.2228Z"
      fill="#202E39"
    />
    <path
      d="M19.9321 27.7685L23.954 23.7575L23.733 23.5371L19.8438 27.4159C19.8438 27.5481 19.8879 27.6363 19.9321 27.7685Z"
      fill="#202E39"
    />
    <path
      d="M19.5786 25.9173L22.8491 22.6555L22.6281 22.4352L19.5786 25.4765C19.5786 25.6087 19.5786 25.785 19.5786 25.9173Z"
      fill="#202E39"
    />
    <path
      d="M19.8884 23.3608C19.8884 23.3608 19.8884 23.4049 19.8442 23.493L21.7447 21.5977L21.5679 21.3773L19.7559 23.1845L19.8884 23.3608Z"
      fill="#202E39"
    />
    <path
      d="M18.8274 22.3029L20.6837 20.4958L20.4627 20.2754L18.6064 22.0825L18.8274 22.3029Z"
      fill="#202E39"
    />
    <path
      d="M17.7227 21.2453L19.5789 19.3941L19.3579 19.1737L17.5459 21.0249L17.7227 21.2453Z"
      fill="#202E39"
    />
    <path
      d="M16.6619 20.1432L18.4739 18.2919L18.2529 18.1156L16.4409 19.9228L16.6619 20.1432Z"
      fill="#202E39"
    />
    <path
      d="M15.5569 19.0412L17.3689 17.2341L17.1922 17.0137L15.3359 18.8208L15.5569 19.0412Z"
      fill="#202E39"
    />
    <path
      d="M14.4519 17.9831L16.3082 16.1319L16.0872 15.9115L14.231 17.7628L14.4519 17.9831Z"
      fill="#202E39"
    />
    <path
      d="M13.347 16.8814L15.2032 15.0302L14.9822 14.8539L13.126 16.6611L13.347 16.8814Z"
      fill="#202E39"
    />
    <path
      d="M12.2422 15.7797L14.0984 13.9726L13.8775 13.7522L12.0654 15.6034L12.2422 15.7797Z"
      fill="#202E39"
    />
    <path
      d="M11.1814 14.7217L12.9935 12.8704L12.8167 12.65L10.9604 14.5013L11.1814 14.7217Z"
      fill="#202E39"
    />
    <path
      d="M10.0764 13.6197L11.9327 11.7685L11.7117 11.5922L9.85547 13.3993L10.0764 13.6197Z"
      fill="#202E39"
    />
    <path
      d="M8.97196 12.5619L10.8282 10.7106L10.6072 10.4902L8.75098 12.3415L8.97196 12.5619Z"
      fill="#202E39"
    />
    <path
      d="M7.86698 11.4599L9.7232 9.60871L9.50222 9.38832L7.646 11.2396L7.86698 11.4599Z"
      fill="#202E39"
    />
    <path
      d="M6.80594 10.358L8.66216 8.55086L8.44118 8.33047L6.58496 10.1817L6.80594 10.358Z"
      fill="#202E39"
    />
    <path
      d="M5.70096 9.30017L7.55718 7.44893L7.3362 7.22855L5.47998 9.07979L5.70096 9.30017Z"
      fill="#202E39"
    />
    <path
      d="M4.59647 8.19801L6.45269 6.34677L6.23171 6.12639L4.37549 7.97763L4.59647 8.19801Z"
      fill="#202E39"
    />
    <path
      d="M3.49149 7.09631L5.34771 5.24506L5.12673 5.06876L3.27051 6.92L3.49149 7.09631Z"
      fill="#202E39"
    />
    <path
      d="M2.38651 6.0387L4.24273 4.18746L4.06595 3.96707L2.16553 5.81831L2.38651 6.0387Z"
      fill="#202E39"
    />
    <path
      d="M1.28176 4.93654L3.18219 3.0853L2.96121 2.86491L1.10498 4.71615L1.28176 4.93654Z"
      fill="#202E39"
    />
    <path
      d="M0.353516 3.26161C0.353516 3.39384 0.397711 3.48199 0.441907 3.61423L2.07715 1.98337L1.85617 1.76299L0.353516 3.26161Z"
      fill="#202E39"
    />
    <path
      d="M25.501 32C24.7055 32 23.9099 31.8678 23.2028 31.6033C19.8881 30.3691 18.1645 26.7107 19.3136 23.3609L0.839721 4.98072C0.309371 4.45179 0 3.70248 0 2.90909C0 2.1157 0.309371 1.41047 0.839721 0.837465C1.37007 0.30854 2.1214 0 2.91693 0C3.71245 0 4.41958 0.30854 4.99413 0.837465L15.6011 11.3719L15.115 11.8568L4.50798 1.32231C4.06602 0.881543 3.53567 0.661157 2.91693 0.661157C2.29818 0.661157 1.76783 0.881543 1.32588 1.32231C0.883917 1.76309 0.662938 2.29201 0.662938 2.90909C0.662938 3.52617 0.883917 4.0551 1.32588 4.49587L20.0207 23.1405L19.9323 23.3609C19.3578 24.8154 19.4462 26.4463 20.0649 27.8567C20.7278 29.2672 21.8769 30.3691 23.3796 30.9421C24.5287 31.3829 25.7662 31.427 26.9595 31.1185L27.0037 30.8981L24.3519 28.2534C23.8216 27.7245 23.5564 27.0193 23.5564 26.27C23.5564 25.5207 23.8658 24.8154 24.4403 24.3306C25.501 23.3168 27.2246 23.3168 28.2853 24.3306L30.9371 26.9752L31.2023 26.8871C31.6 25.4325 31.379 23.9339 30.6719 22.5675C29.9206 21.2011 28.6831 20.1873 27.1362 19.7466C25.8988 19.3939 24.5729 19.438 23.3796 19.8788L23.1586 19.9669L18.739 15.5152L19.2252 15.0303L23.3354 19.1736C24.6171 18.7328 25.9872 18.6887 27.313 19.0854C28.9925 19.5702 30.3625 20.6722 31.2023 22.2149C32.042 23.7576 32.2188 25.5207 31.7326 27.1956L31.6884 27.3719L30.7161 27.6364L27.7992 24.7273C27.0921 24.022 25.7662 24.022 24.9706 24.8154C24.1309 25.6088 24.0867 26.9752 24.8823 27.8127L27.7992 30.7218L27.534 31.6915L27.3572 31.7355C26.7385 31.9118 26.1197 32 25.501 32Z"
      fill="#202E39"
    />
    <path
      d="M2.12257 1.51635L1.65381 1.98386L2.9351 3.2617L3.40386 2.7942L2.12257 1.51635Z"
      fill="#202E39"
    />
    <path
      d="M17.3248 17.4545H16.8387L13.1704 13.7961V13.3113L15.336 11.1515L15.5128 11.1956C15.6012 11.1956 15.6896 11.1956 15.778 11.1956C16.2199 11.1074 16.5735 10.6667 16.4851 10.2259L16.4409 10.0496L25.5894 0.925616C26.1198 0.39669 26.8711 0.08815 27.6666 0.08815C28.4622 0.08815 29.1693 0.39669 29.7439 0.925616C30.8929 2.07162 30.8929 3.92286 29.7439 5.02479L20.6837 14.0606L20.5069 13.8843L20.4627 14.1928C20.1976 14.1488 19.9324 14.2369 19.7114 14.4132C19.5346 14.5895 19.4462 14.8981 19.4904 15.1625L19.5346 15.3388L17.3248 17.4545ZM13.8775 13.5758L17.0596 16.7493L18.7833 15.0744C18.7833 14.6777 18.9159 14.2369 19.2252 13.9725C19.5346 13.708 19.8882 13.5317 20.3301 13.5317L29.2577 4.6281C30.1416 3.74655 30.1416 2.33608 29.2577 1.45454C28.8157 1.01377 28.2854 0.793385 27.6666 0.793385C27.0479 0.793385 26.5176 1.01377 26.0756 1.45454L17.148 10.314C17.1922 11.0634 16.6619 11.7245 15.8664 11.8567C15.778 11.8567 15.6454 11.9008 15.557 11.8567L13.8775 13.5758Z"
      fill="#202E39"
    />
    <path
      d="M14.9954 15.1888L5.27637 24.8818L5.74513 25.3493L15.4642 15.6563L14.9954 15.1888Z"
      fill="#202E39"
    />
    <path
      d="M2.07715 30.2808H1.63519L0.353516 29.0025V28.5618L3.6682 25.0356L5.52443 24.8152L5.87799 25.1678L5.65702 27.0191L2.07715 30.2808ZM1.01645 28.7821L1.85617 29.6196L4.99408 26.6664L5.12667 25.4764L3.93338 25.6086L1.01645 28.7821Z"
      fill="#202E39"
    />
  </svg>
);
export default EasyCare;
