import { Platform } from 'react-native';

const feedbackApp =
  'https://viasat.az1.qualtrics.com/jfe/form/SV_6VeJGV0uayx8drU';
const feedbackWeb =
  'https://viasat.az1.qualtrics.com/jfe/form/SV_09ek9udOcZf8waG';
const feedbackUrl = Platform.OS === 'web' ? feedbackWeb : feedbackApp;

export const urls = {
  appStore: 'https://apps.apple.com/us/app/my-viasat/id1341120640',
  feedbackUrl,
  playStore:
    'https://play.google.com/store/apps/details?id=com.viasat.cts.store.ViasatInternet',
};
