import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';

import { trackBillingEvent } from '@mfe/to-be-migrated/redux/analytics';
import { Route, setBillingNav } from '@mfe/to-be-migrated/redux/billingNav';
import type { Invoice } from '@mfe/shared/schema-types';
import { ANALYTICS_EVENT_NAME } from './constants';

export const goToAutopay = (
  dispatch: Dispatch<any>,
  selection_zone: string
) => {
  dispatch(
    trackBillingEvent({
      eventName: ANALYTICS_EVENT_NAME.updatePaymentMethodUpdateSelected,
      version: '1-0-0',
      data: {
        selection_method: 'click',
        selection_zone,
      },
    })
  );

  dispatch(setBillingNav({ route: Route.Autopay }));
};

export const goToPayment = (
  dispatch: ReturnType<typeof useDispatch>,
  selection_zone: string,
  invoice?: Invoice | null
) => {
  dispatch(
    trackBillingEvent({
      eventName: ANALYTICS_EVENT_NAME.makePaymentSelected,
      version: '1-0-0',
      data: {
        selection_method: 'click',
        selection_zone,
      },
    })
  );

  dispatch(setBillingNav({ route: Route.OneTimePayment, props: { invoice } }));
};
