import { SectionAlert, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

const ProrateNotice = ({
  newBillCycleDay,
  oldBillCycleDay,
}: {
  readonly newBillCycleDay: number;
  readonly oldBillCycleDay: number;
}) => {
  const { t } = useTranslation('UpdateBillCycle', {
    keyPrefix: 'prorateNotice',
  });

  const isForward = newBillCycleDay > oldBillCycleDay;
  const variant = isForward
    ? { textContent: t('addToAmount'), type: 'warning' as const }
    : { textContent: t('subtractToAmount'), type: 'infoPrimary' as const };

  return (
    <SectionAlert
      data-cy="prorated-notice"
      variant={variant.type}
      showIcon={false}
      contentSlot={
        <Txt variant="smallRegular" color="info">
          {variant.textContent}
        </Txt>
      }
    />
  );
};

export default ProrateNotice;
