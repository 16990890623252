import {
  FaqSection,
  FaqQuestion,
  FaqContent,
  FaqText,
  Query,
} from '@viasat/res-apps-lib/build/types/mv';

export enum SectionValue {
  All = 'All',
  Billing = 'Billing',
  Connectivity = 'Connectivity',
  Speeds = 'Speeds',
  AccountAdmin = 'Account Administration',
  Usage = 'Usage',
}

export type SelectionType = 'Section' | 'Question';

export interface FaqSelection {
  section?: SectionValue;
  previousSection?: SectionValue;
  question?: string;
  previousQuestion?: string;
  resetQuestion?: boolean;
}

export interface FaqAction {
  type: SelectionType;
  section?: SectionValue;
  question?: string;
}

export interface XYPosition {
  x: number;
  y: number;
}

export type LayoutMap = {
  [value in SectionValue]: XYPosition | undefined;
};

export interface FiltersProps {
  faqs: Query['getFAQ'];
  faqSelection: FaqSelection;
  layoutMap: LayoutMap;
  handleSelect(faqSelection: FaqSelection): void;
}

export interface FilterProps extends Pick<FiltersProps, 'layoutMap'> {
  title: string;
  handler(): void;
  selected: boolean;
  testID?: string;
}

type MobileScrollArgs = {
  section?: SectionValue;
  previousSection?: SectionValue;
};
type SetLayoutCallback = (priorState: LayoutMap) => LayoutMap;
export interface AccordionProps {
  faqSelection: FaqSelection;
  faqs: Query['getFAQ'];
  handleSelect(faqSelection: FaqSelection): void;
  setLayoutMap(setLayoutCallback: SetLayoutCallback): void;
}

export type SectionTitleProps = Pick<FaqSection, 'title'>;
interface Section {
  section: FaqSection;
}
export type FaqCardProps = Section &
  Pick<AccordionProps, 'handleSelect' | 'faqSelection'>;

export type KnowledgeBaseProps = NonNullable<
  Pick<FaqContent, 'knowledgeBase'>
> &
  Pick<FaqQuestion, 'title'>;

export interface AnswerTextProp {
  title: string;
  text: FaqText[];
}

export interface HiddenContentProps {
  title: string;
  analyticsLabel: string;
  content: FaqContent;
  secondaryPressCallback: (label: string) => void;
}

export type Accumulator = Record<
  NonNullable<SectionValue>,
  React.RefObject<HTMLDivElement>
>;
