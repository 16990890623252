import styles from '../BodyContent.module.scss';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { useSelector } from 'react-redux';
import { Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { getContactInformation, PhoneUILabel } from '../../../../utils';
import LoadingAccountInfo from './LoadingAccountInfo';

interface PhoneNumberProps {
  phoneText: string;
  landlineOrMobile: PhoneUILabel;
}

const ContactInformation = (): JSX.Element => {
  const {
    userInfo: {
      email: residentialEmail,
      phone: residentialPhone,
      accountHolder: { email: accountHolderEmail, phone: accountHolderPhone },
      accountType,
    },
    loading: userInfoLoading,
  } = useSelector(selectUserInfo);
  const { t } = useTranslation('NewProfile');
  const {
    email,
    primaryPhoneText,
    primaryLandlineOrMobile,
    secondaryPhoneText,
    secondaryLandlineOrMobile,
  } = getContactInformation(
    accountType,
    residentialPhone,
    residentialEmail,
    accountHolderPhone,
    accountHolderEmail,
    t
  );

  const PhoneNumber = ({
    phoneText,
    landlineOrMobile,
  }: PhoneNumberProps): JSX.Element => {
    return (
      <div className={styles['phoneText']}>
        <Txt variant="bodySmallRegular" component="span" mt="4px">
          {phoneText}
        </Txt>
        <Txt
          variant="bodySmallRegular"
          color="subtle"
          component="span"
          mt="4px"
          ml="4px"
        >
          {phoneText !== t('noPhone') &&
            (landlineOrMobile === PhoneUILabel.Landline
              ? t('landlineLabel')
              : t('mobileLabel'))}
        </Txt>
      </div>
    );
  };

  return (
    <div className={styles['accountInfoCard']}>
      <div className={styles['accountInfoBody']}>
        <div className={styles['accountInfoText']}>
          <Txt variant="labelSmall" component="span" color="subtle">
            {t('emailAddress')}
          </Txt>
          {userInfoLoading ? (
            <div className={styles['loading']} style={{ marginTop: '4px' }} />
          ) : (
            <Txt
              variant="bodySmallRegular"
              component="span"
              mt="4px"
              style={{ whiteSpace: 'pre-line', overflowWrap: 'anywhere' }}
            >
              {email ?? t('noEmail')}
            </Txt>
          )}
        </div>
        <div className={styles['accountInfoText']} data-cy="primaryPhoneNumber">
          <Txt variant="labelSmall" component="span" color="subtle">
            {t('primaryPhone')}
          </Txt>
          {userInfoLoading ? (
            <div className={styles['loading']} style={{ marginTop: '4px' }} />
          ) : (
            <PhoneNumber
              phoneText={primaryPhoneText}
              landlineOrMobile={primaryLandlineOrMobile}
            />
          )}
        </div>
        <div className={styles['accountInfoText']}>
          <Txt variant="labelSmall" component="span" color="subtle">
            {t('secondaryPhone')}
          </Txt>
          {userInfoLoading ? (
            <LoadingAccountInfo />
          ) : (
            <PhoneNumber
              phoneText={secondaryPhoneText}
              landlineOrMobile={secondaryLandlineOrMobile}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactInformation;
