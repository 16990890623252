import { i18n } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';

import { Form } from './components/Form';
import { Introduction } from './components/Introduction';
import { StoreProvider } from './utils';
import { PageName, ButtonName } from './analytics';

import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { getClient } from '@mfe/services/graphql-client';
import {
  structuredEvent,
  AnalyticsAction,
  Categories,
} from '@mfe/to-be-migrated/redux/analytics';
import { AnalyticsContext } from '@mfe/services/analytics';

enum Content {
  INTRODUCTION = 'INTRODUCTION',
  FORM = 'FORM',
}

interface AffordableConnectivityProgramProps {
  i18n?: i18n;
  partyId: string;
  stateCode: string;
  redirectToOverview: () => void;
  analyticsContext: AnalyticsContext[];
}

export const AffordableConnectivityProgram = (
  props: AffordableConnectivityProgramProps
) => {
  const {
    i18n: externalI18n,
    partyId,
    stateCode,
    redirectToOverview,
    analyticsContext,
  } = props;

  const [page, setPage] = useState(Content.INTRODUCTION);
  const showIntroduction = page === Content.INTRODUCTION;
  const dispatch = useDispatch();
  const {
    user: {
      auth: { tokenInfo },
    },
  } = useSelector(selectUser);
  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const [client, setClient] = useState(getClient(tokenInfo, userLocale));

  useEffect(() => {
    setClient(getClient(tokenInfo, userLocale));
  }, [tokenInfo, userLocale]);

  const handleContinue = () => {
    setPage(Content.FORM);
    dispatch(
      structuredEvent({
        category: Categories.ACP,
        action: AnalyticsAction.BUTTON_CLICKED,
        params: {
          context: analyticsContext,
          label: PageName.ADDITIONAL_DETAILS,
          property: ButtonName.CONTINUE,
        },
      })
    );
  };

  const handleBackToOverview = () => {
    dispatch(
      structuredEvent({
        category: Categories.ACP,
        action: AnalyticsAction.BUTTON_CLICKED,
        params: {
          context: analyticsContext,
          label: PageName.ADDITIONAL_DETAILS,
          property: ButtonName.BACK,
        },
      })
    );
    redirectToOverview();
  };

  const handleCancel = () => {
    setPage(Content.INTRODUCTION);
    dispatch(
      structuredEvent({
        category: Categories.ACP,
        action: AnalyticsAction.BUTTON_CLICKED,
        params: {
          context: analyticsContext,
          label: PageName.ACP_FLOW_PAGE,
          property: ButtonName.CANCEL,
        },
      })
    );
  };

  return (
    <ApolloProvider client={client}>
      <StoreProvider
        value={{
          hasExternalI18n: !!externalI18n,
          partyId,
          stateCode,
        }}
      >
        {showIntroduction ? (
          <Introduction
            handleContinue={handleContinue}
            handleBackToOverview={handleBackToOverview}
            analyticsContext={analyticsContext}
          />
        ) : (
          <Form
            handleCancel={handleCancel}
            redirectToOverview={redirectToOverview}
            analyticsContext={analyticsContext}
          />
        )}
      </StoreProvider>
    </ApolloProvider>
  );
};
