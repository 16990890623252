import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectStreamOnPromo,
  setIsEditingNumber,
} from '@mfe/to-be-migrated/redux/streamOnPromo';

import { EditableMobileNumberInput } from './EditableMobileNumberInput';

type PhoneNumberContainerProps = {
  formattedNumber: string | undefined;
};

export const PhoneNumberContainer: FC<PhoneNumberContainerProps> = ({
  formattedNumber,
}) => {
  const { isEditingNumber } = useSelector(selectStreamOnPromo);
  const dispatch = useDispatch();

  const isSMSWindowOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    dispatch(setIsEditingNumber(!isEditingNumber));
  };

  return (
    <div>
      <EditableMobileNumberInput
        updateWindowStatus={isSMSWindowOpen}
        formattedNumber={formattedNumber}
      />
    </div>
  );
};
