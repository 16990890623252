import { useMemo } from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import {
  Button,
  IllustrativeIcon,
  InlineLink,
  StandAloneLink,
  Txt,
} from '@vst/beam';

import styles from './styles.module.scss';
import { Caution } from '@vst/beam-icons/illustrative-icons';
import { Phone } from '@vst/beam-icons/icons';
import { useTranslation } from 'react-i18next';
import { selectNetwork } from '@mfe/to-be-migrated/redux/network/networkSlice';

interface RunLimitNotifierProps {
  navigateToDiagnosticReport: (e: React.MouseEvent<HTMLElement>) => void;
}

export const RunLimitNotifier = ({
  navigateToDiagnosticReport,
}: RunLimitNotifierProps) => {
  const { t } = useTranslation('Network', { keyPrefix: 'diagnostic' });

  const runDate = useLastRunDate();

  const phoneHref = `tel:${t('callCustomerSupportPhoneNumber')}`;

  return (
    <div className={styles['card']}>
      <div className={styles['content']}>
        <IllustrativeIcon
          data-cy="CheckMark-Icon"
          name="CheckMark"
          icon={Caution}
          color="warning_400"
          width={100}
          height={100}
        />
        <div className={styles['titleAndDescription']}>
          <Txt variant="heading5" mb="8px" data-cy="run-diagnostic-title">
            {t('runLimitIssue.title')}
          </Txt>
          <Txt
            variant="bodySmallRegular"
            color="subtle"
            data-cy="run-diagnostic-description"
          >
            {t('runLimitIssue.description')}
          </Txt>
        </div>

        <InlineLink
          href={phoneHref}
          style={{ maxWidth: '400px', width: '100%' }}
        >
          <Button
            variant="primary"
            fluid
            data-cy="run-diagnostic-button"
            style={{ width: '100%' }}
            icon={Phone}
            iconPos="left"
          >
            {t('buttons.callCare')}
          </Button>
        </InlineLink>

        <div className={styles['dateAndLink']}>
          <Txt component="span" variant="smallRegular" data-cy="run-date">
            {runDate}
          </Txt>
          <StandAloneLink
            variant="primary"
            linkSize="small"
            href=""
            onClick={navigateToDiagnosticReport}
            data-cy="go-to-full-report"
            underline
          >
            {t('linkToDiagnosticReport')}
          </StandAloneLink>
        </div>
      </div>
    </div>
  );
};

const useLastRunDate = () => {
  const { runDate: storeRunDate } = useSelector(selectNetwork);

  const runDate = useMemo(() => {
    if (storeRunDate) {
      return storeRunDate;
    }
    const runDateFromStorage = JSON.parse(
      localStorage.getItem('diagnosticRunsTimeStamps') ?? '[]'
    );

    const lastTimeStampFromStorage =
      runDateFromStorage[runDateFromStorage.length - 1];

    if (lastTimeStampFromStorage) {
      return format(lastTimeStampFromStorage, 'MMM do HH:mm a zzz');
    }
    return null;
  }, [storeRunDate]);

  return runDate;
};
