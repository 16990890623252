import { useTranslation } from 'react-i18next';

import { Txt } from '@vst/beam';

import { CardLogo } from '@mfe/shared/components';
import type { Payment, PendingPayment } from '@mfe/shared/schema-types';

import { getPaymentType } from '../utils';

import sharedStyles from '../../shared/styles.module.scss';

import styles from './styles.module.scss';

type Props = {
  payment: Payment | PendingPayment;
};

const PaymentDetails = ({ payment }: Props): JSX.Element => {
  const { t } = useTranslation('BillingPayment');

  const last4Digits = payment.last4Digits && `...${payment.last4Digits}`;
  const paymentType = getPaymentType(payment);

  return (
    <>
      <div className={styles['paymentRowContainer']}>
        <div className={sharedStyles['flexColumn']}>
          <Txt
            component="p"
            variant="labelMedium"
            className={styles['bodySubHeader']}
          >
            {t('paymentType')}
          </Txt>
          <Txt component="p" variant="bodySmallRegular">
            {t('paymentType', { context: paymentType })}
          </Txt>
        </div>
      </div>

      <div className={styles['paymentRowContainer']}>
        <div className={sharedStyles['flexColumn']}>
          <Txt
            component="p"
            variant="labelMedium"
            className={styles['bodySubHeader']}
          >
            {t('method')}
          </Txt>

          <div className={styles['logo']}>
            <CardLogo name={payment.paymentMethod} width="35px" />
            <Txt component="p" variant="bodySmallRegular">
              {last4Digits ?? payment.paymentMethod ?? ''}
            </Txt>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentDetails;
