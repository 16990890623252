import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SelectionCard, Surface, Txt } from '@vst/beam';

import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { formatPrice } from '@mfe/to-be-migrated/redux/changePlan';

import styles from './LeaseFeeOptions.module.scss';

interface LeaseFeeOptionsProps {
  priceCurrency: string;
  leaseFeePrice: number | null | undefined;
  leaseFeeShortName: string | null | undefined;
}
export const LeaseFeeOptions = ({
  priceCurrency,
  leaseFeePrice,
  leaseFeeShortName,
}: LeaseFeeOptionsProps) => {
  const { t } = useTranslation('NewChangePlan', {
    keyPrefix: 'orderReview.sateliteMigrationDetails.equipmentAndLeaseFee',
  });

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const formattedLeaseFee = formatPrice(
    userLocale,
    leaseFeePrice,
    priceCurrency,
    0
  );

  return (
    <Surface pt="24px">
      <Txt variant="bodySmallRegular" mb="16px">
        {t('chooseOptionTitle')}
      </Txt>
      <div className={styles['selection-card']}>
        <SelectionCard
          name="order_review"
          header={`${formattedLeaseFee} ${leaseFeeShortName} `}
          body={t('selectedLeaseFee.details')}
          checked={true}
          readOnly={true}
        />
      </div>
    </Surface>
  );
};
