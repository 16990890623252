import React from 'react';
import { View, StyleSheet, ScrollView, Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonText,
  Text,
  useDimensionContext,
  ScreenSize,
  useWebDrawer,
  Color,
  useParams,
  useNavigate,
} from '@mfe/legacy/andromeda';
import Status, {
  ColorType,
  StatusType,
} from '@mfe/legacy/mv/components/Status';
import ContactPhone from './ContactPhone';
import { getAnalyticsReferrer } from '@mfe/legacy/mv/utils/liveChat';
import Svg, { Circle } from 'react-native-svg';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

const styles = StyleSheet.create({
  bodyText: {
    textAlign: 'center',
    marginBottom: 4,
  },
  button: {
    marginTop: 48,
    marginHorizontal: Platform.OS === 'web' ? 20 : undefined,
  },
  secondButton: {
    marginTop: 16,
    marginHorizontal: Platform.OS === 'web' ? 20 : undefined,
  },
  container: {
    alignSelf: 'center',
    maxWidth: 325,
  },
  headerText: {
    textAlign: 'center',
  },
  loadingState: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    height: '100%',
    width: '100%',
  },
  agentChatLink: {
    color: Color.teal500,
    textAlign: 'center',
    marginTop: 18,
  },
  iconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: Color.white,
    width: 100,
    height: 100,
    borderRadius: 62,
  },
  circle: { position: 'absolute', top: 0, left: 0 },
});

const LiveChat = (): JSX.Element => {
  const { t } = useTranslation(['LiveChat', 'ChangePlan']);
  const { setDrawer, unlockDrawer } = useWebDrawer();
  const { screenSize, isMobile } = useDimensionContext();
  const isSmallScreen = screenSize <= ScreenSize.MD;
  const referrer = getAnalyticsReferrer();

  const { params } = useParams();
  const goTo = useNavigate();

  const isChatEnabled = false;
  const onPressEnglish = async (): Promise<void> => {
    try {
      setDrawer();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <View>
      <Status
        color={ColorType.blue}
        type={StatusType.fullPage}
        noText
        customIcon={
          <View style={styles.iconContainer}>
            <Svg
              width={100}
              height={100}
              viewBox="0 0 128 128"
              style={styles.circle}
            >
              <Circle
                fill="none"
                cx="64"
                cy="64"
                r={'46.5'}
                stroke={Color.teal600}
                strokeWidth="4"
              />
            </Svg>
            <Icon
              name="forum"
              size={45}
              color={Color.teal600}
              style={{ position: 'absolute' }}
            />
          </View>
        }
      />
      <ScrollView
        bounces={false}
        style={{ paddingHorizontal: isSmallScreen ? 32 : 72 }}
      >
        <View style={styles.container}>
          {isChatEnabled && (
            <>
              <Text.H4
                heavy
                fixed
                testID="availableTitle"
                style={[
                  styles.headerText,
                  { marginVertical: isSmallScreen ? 24 : 32 },
                ]}
              >
                {params?.title ? params.title : t('availableTitle')}
              </Text.H4>
              <View>
                <Text small fixed testID="description" style={styles.bodyText}>
                  {params?.primaryDescription
                    ? params.primaryDescription
                    : t('availableDescription')}
                </Text>
                {params?.secondaryDescription && (
                  <Text
                    small
                    fixed
                    testID="description"
                    style={[styles.bodyText, { marginTop: 12 }]}
                  >
                    {params.secondaryDescription}
                  </Text>
                )}
              </View>
              <Button
                fill
                testID="chatbotButton"
                style={styles.button}
                onPress={onPressEnglish}
              >
                <ButtonText>{t('ctaEnglish')}</ButtonText>
              </Button>
              {params?.closeButtonText && (
                <Button
                  secondary
                  fill
                  style={styles.secondButton}
                  onPress={(): void => {
                    if (!isMobile) {
                      unlockDrawer();
                      setDrawer();
                    } else {
                      goTo(params.closeButtonLocation);
                    }
                  }}
                >
                  {params.closeButtonText}
                </Button>
              )}
            </>
          )}
          <ContactPhone referrer={referrer} testID="CallComp" />
        </View>
      </ScrollView>
    </View>
  );
};

export default LiveChat;
