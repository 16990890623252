import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Alert } from '@vst/beam-icons/illustrative-icons';
import { Button, IllustrativeIcon, InlineLink, Txt } from '@vst/beam';

import { useScreenResolution } from '@mfe/shared/util';
import { setUpdateBillCycleError } from '@mfe/to-be-migrated/redux/updateBillCycle';

import Container from './container';
import GradientCard from '../gradient-card';

import styles from './error.module.scss';

const Error = ({ navigateToBilling }: { navigateToBilling: () => void }) => {
  const { t } = useTranslation(['UpdateBillCycle', 'Global']);
  const dispatch = useDispatch();

  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isSmall || isExtraSmall;

  return (
    <GradientCard>
      <Container>
        <IllustrativeIcon
          data-cy="update-bill-cycle-error"
          name="error-illustration"
          icon={Alert}
          color="alert_400"
          style={{ height: '100px', width: '100px' }}
          mt="72px"
          mb={isMobile ? '8px' : '24px'}
        />
        <div style={{ textAlign: 'center' }}>
          <Txt variant="heading4" mb="12px">
            {t('error.title')}
          </Txt>
          <Txt variant="bodyLargeRegular">
            {t('UpdateBillCycle:error.subtitle')}{' '}
            {isMobile ? (
              <CustomerSupportTelLink />
            ) : (
              <Txt variant="bodyLargeBold" color="subtle" component="span">
                {t('Global:callCustomerSupportPhoneNumberDisplay')}
              </Txt>
            )}
          </Txt>
        </div>

        <Button
          mb="72px"
          mt={isMobile ? '8px' : '24px'}
          fluid={isMobile}
          className={styles['back-button']}
          onClick={() => {
            dispatch(setUpdateBillCycleError(null));
            navigateToBilling();
          }}
        >
          {t('buttons.backToBilling')}
        </Button>
      </Container>
    </GradientCard>
  );
};

const CustomerSupportTelLink = () => {
  const { t } = useTranslation(['Global']);

  return (
    <InlineLink
      variant="primary"
      className={styles['customer-support-tel-link']}
      href={`tel:${t('Global:callCustomerSupportPhoneNumber')}`}
    >
      {t('Global:callCustomerSupportPhoneNumberDisplay')}
    </InlineLink>
  );
};

export default Error;
