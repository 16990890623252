import { ModalTypeEnum, ModalComponent } from '@mfe/shared/components';
import Outcome from './Outcome';
import { Alert } from '@vst/beam-icons/illustrative-icons';
import { InlineLink } from '@vst/beam';
import { useScreenResolution } from '@mfe/shared/util';
import { useTranslation } from 'react-i18next';

interface ServiceUnavailableErrorProps {
  tryAgain: () => void;
  goBackToOverviewPage: () => void;
  canRetry: boolean;
}

export const ServiceUnavailableError = (
  props: ServiceUnavailableErrorProps
) => {
  const { tryAgain, goBackToOverviewPage, canRetry } = props;
  const { t } = useTranslation('ACP', { keyPrefix: 'Error' });
  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;

  const hrefLink = `tel:${t(
    'serviceUnavailable.nextSteps.callUs.phoneNumber'
  )}`;

  const callUs = (
    <>
      {t('serviceUnavailable.nextSteps.callUs.first')}{' '}
      <InlineLink
        href={hrefLink}
        data-cy="acp-call-service-unavailable"
        style={{ textDecoration: 'none' }}
      >
        {t('serviceUnavailable.nextSteps.callUs.phoneNumber')}
      </InlineLink>{' '}
      {t('serviceUnavailable.nextSteps.callUs.last')}
    </>
  );

  const buttonLabel = canRetry
    ? t(`serviceUnavailable.buttons.tryAgain`)
    : t('serviceUnavailable.buttons.backToMyViasat');

  const handleClick = canRetry ? tryAgain : goBackToOverviewPage;

  const renderOutcome = () => {
    return (
      <Outcome
        iconName={Alert}
        title={t('serviceUnavailable.title')}
        text={t(`serviceUnavailable.description`)}
        secondaryText={
          canRetry ? t('serviceUnavailable.nextSteps.tryAgain') : callUs
        }
        primaryButton={{
          label: buttonLabel,
          handleClick: handleClick,
        }}
      />
    );
  };

  return isMobile ? (
    renderOutcome()
  ) : (
    <ModalComponent type={ModalTypeEnum.ACP}>{renderOutcome()}</ModalComponent>
  );
};
