import * as React from 'react';
import { Button, Surface } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import {
  refetchPlans,
  selectChangePlan,
  toggleBroadbandLabels,
  resetHideBroadbandLabels,
} from '@mfe/to-be-migrated/redux/changePlan';
import { Platform } from '@mfe/shared/schema-types';
import { useScreenResolution } from '@mfe/shared/util';
import { selectConfig } from '@mfe/shared/redux/config';
import { HeaderWithBackNavigation } from '@mfe/shared/components';
import {
  selectCurrentPlanPrice,
  selectDownloadSpeeds,
  selectPlanCharacteristics,
} from '@mfe/to-be-migrated/redux/plan';

import { CurrentPlan } from '../CurrentPlan/CurrentPlan';
import PlanErrorContent from '../errors/PlanErrorContent';
import { NoPlansAvailable } from '../errors/NoPlansAvailable';
import { ErrorWithRefresh } from '../errors/ErrorWithRefresh';
import { AvailablePlans } from '../AvailablePlans/AvailablePlans';
import { CollapsibleCurrentPlan } from '../CollapsibleCurrentPlan/CollapsibleCurrentPlan';
import useElementHeight from '../../shared/use-element-height';

import styles from './PlanSelection.module.scss';

type StylesWithCSSVariable = React.CSSProperties & {
  '--collapsible-current-plan-height': string;
  '--navigation-header-height': string;
};

interface PlanSelectionProps {
  navigateToPreviousPage: () => void;
}

export const PlanSelection = ({
  navigateToPreviousPage,
}: PlanSelectionProps) => {
  const { t } = useTranslation('NewChangePlan');
  const dispatch = useDispatch();

  const {
    loading: offersLoading,
    error,
    hideBroadbandLabels,
    planOffersData,
  } = useSelector(selectChangePlan);
  const { platform, showBroadbandLabels } = useSelector(selectConfig);

  const { loading: priceLoading } = useSelector(selectCurrentPlanPrice);
  const { loading: downloadSpeedLoading } = useSelector(selectDownloadSpeeds);
  const { loading: characteristicsLoading } = useSelector(
    selectPlanCharacteristics
  );

  const currentPlanLoading =
    priceLoading || downloadSpeedLoading || characteristicsLoading;

  const { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge } =
    useScreenResolution();

  const isMobile = isSmall || isExtraSmall;
  const isMobileOrTablet = isMobile || isMedium;
  const isDesktop = isLarge || isExtraLarge;

  const shouldDisplayBroadbandLabel =
    showBroadbandLabels && !(offersLoading || currentPlanLoading);

  const buttonBackground =
    !isMobile || hideBroadbandLabels ? undefined : 'white';

  const buttonPosition =
    platform === Platform.Web || !isMobile ? undefined : '85px';

  const currentPlanFlexBasis = isLarge ? '33.33%' : '396px';
  const navigationHeaderHeight = useElementHeight('[data-cy=mobileNav]');

  const collapsibleCurrentPlanHeight = useElementHeight(
    '[data-cy=collapsible-current-plan]'
  );

  const collapsibleSummaryHeight = useElementHeight(
    '[data-cy=collapsible-current-plan] summary'
  );

  const cssCustomVariables = isMobileOrTablet
    ? ({
        '--collapsible-current-plan-height': collapsibleCurrentPlanHeight
          ? `${collapsibleCurrentPlanHeight}px`
          : undefined,
        '--navigation-header-height':
          platform === Platform.Web ? `${navigationHeaderHeight}px` : undefined,
        '--collapsible-summary-height': collapsibleSummaryHeight
          ? `${collapsibleSummaryHeight}px`
          : undefined,
      } as StylesWithCSSVariable)
    : {};

  const pageHeaderClassName = `${styles['page-header']} ${
    currentPlanLoading && isMobile ? styles['mobile-loading-header'] : ''
  }`;

  const additionalButtonClassName = `${styles['toggle-labels']} ${
    hideBroadbandLabels ? 'pressed' : ''
  }`;

  React.useEffect(() => {
    return () => {
      dispatch(resetHideBroadbandLabels());
    };
  }, []);

  const handleBackNavigation = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    navigateToPreviousPage();
  };

  const handleRefreshOnErrorPage = () => {
    dispatch(refetchPlans());
  };

  if (!offersLoading && planOffersData?.length === 0) {
    return <NoPlansAvailable handleGoBack={handleBackNavigation} />;
  }

  if (error) {
    return (
      <ErrorWithRefresh
        handleRefresh={handleRefreshOnErrorPage}
        handleGoBack={handleBackNavigation}
      >
        <PlanErrorContent />
      </ErrorWithRefresh>
    );
  }

  return (
    <Surface variant="secondary" style={{ flex: 1, ...cssCustomVariables }}>
      <CollapsibleCurrentPlan
        isHidden={!isMobileOrTablet || currentPlanLoading}
      />
      <HeaderWithBackNavigation
        handleBackNavigation={handleBackNavigation}
        title={t('mainTitle')}
        additionalClassName={pageHeaderClassName}
        goBackLabel={t('goBack')}
        additionalButton={
          shouldDisplayBroadbandLabel && (
            <Button
              buttonSize="small"
              variant="secondary"
              onClick={() => dispatch(toggleBroadbandLabels())}
              className={additionalButtonClassName}
              style={{
                whiteSpace: 'nowrap',
                backgroundColor: buttonBackground,
                bottom: buttonPosition,
              }}
            >
              {hideBroadbandLabels ? t('showLabels') : t('hideLabels')}
            </Button>
          )
        }
      />

      <Surface
        variant="secondary"
        pb={{ xs: '24px', md: '48px' }}
        className={styles['content-wrapper']}
      >
        <Surface variant="secondary" className={styles['content']}>
          {isDesktop && (
            <div
              data-cy="current-plan"
              className={styles['current-plan-wrapper']}
              style={{ flexBasis: currentPlanFlexBasis }}
            >
              <CurrentPlan />
            </div>
          )}
          <AvailablePlans />
        </Surface>
      </Surface>
    </Surface>
  );
};
