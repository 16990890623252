import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Logo, Txt, Icon } from '@vst/beam';
import { ViasatLogoDefault } from '@vst/beam-icons/logos/viasat';
import { Storage, useScreenResolution } from '@mfe/shared/util';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { useNavigate } from '@mfe/legacy/andromeda';
import LanguageToggle from './LanguageToggle';
import HamburgerMenuButton from './HamburgerMenuButton';
import MobileBottomNav from './MobileBottomNav';
import styles from './nav.module.scss';
import { useTranslation } from 'react-i18next';
import { TabItem } from './TabItem';
import ProfileHover from './ProfileHover';
import {
  AccountCircleOutlined,
  ChevronDownCurved,
} from '@vst/beam-icons/icons';

import { availableLanguagesMap } from '../useSwitchLanguage';

type NavItem = {
  label: string;
  path: string;
  testId: string;
};

interface DesktopNav {
  logoUrl: string;
  tabItems: NavItem[];
}

const ViasatLogo = ({
  logoUrl,
  onClick,
  showLanguageToggle,
}: {
  logoUrl: string;
  onClick: (e: any) => void;
  showLanguageToggle?: boolean;
}) => {
  const className = showLanguageToggle
    ? styles.logo
    : `${styles.logo} ${styles.noLanguageToggle}`;

  return (
    <a href={logoUrl} className={className} onClick={onClick}>
      <Logo logo={ViasatLogoDefault} alt="Viasat" size="112px" />
    </a>
  );
};

const Nav = ({ tabItems, logoUrl }: DesktopNav) => {
  const {
    locale: { urlPrefix, userLocale },
  } = useSelector(selectLocale);
  const { isLarge, isExtraLarge } = useScreenResolution();
  const [isOpen, setIsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const accountOpenAction = (e: any) => {
    e.preventDefault();
    setIsProfileOpen(!isProfileOpen);
  };
  const goTo = useNavigate();
  const useMobileLayout = !isLarge && !isExtraLarge;
  const testId = useMobileLayout ? 'mobileNav' : 'desktopNav';
  const { t } = useTranslation('Nav');

  /* On open we should...
    scroll to the top,
    open the mobile dropdown,
    and then not allow the user to scroll down */
  const open = (): void => {
    window.scrollTo(0, 0);
    setIsOpen(true);
    document.body.style.overflow = 'hidden';
  };
  /* On close we should...
      scroll to the top,
      close the mobile dropdown,
      and then allow the user to scroll again */
  const close = (): void => {
    window.scrollTo(0, 0);
    setIsOpen(false);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore Null is allowed
    document.body.style.overflow = null;
  };

  const createNavItemClickHandler =
    (link: string): ((e: any) => void) =>
    (e: any) => {
      e.preventDefault();
      close();
      goTo(link.slice(1));
      Storage.removeItem('showInternetPlan');
    };

  const profileTab: NavItem = {
    label: t('Profile'),
    path: '/Profile',
    testId: 'Profile',
  };
  const profileMobileTab: NavItem = {
    label: t('ProfileMobile'),
    path: '/Profile',
    testId: 'Profile',
  };

  const ProfileButton = (): JSX.Element => {
    let chevronStyles = styles.chevron;
    if (isProfileOpen) chevronStyles += ` ${styles.flip}`;
    return (
      <>
        <div
          className={styles.profileTabContainer}
          data-cy={'/Account'}
          onClick={accountOpenAction}
          style={{ margin: 'auto' }}
        >
          <div
            className={styles.profileHoverContainer}
            style={{ display: 'flex' }}
          >
            <Icon icon={AccountCircleOutlined} size={24} color="gray_500" />
            <div className={styles.navItemProfile}>
              <Txt variant="bodySmallRegular" color="subtle">
                {profileTab.label}
              </Txt>
            </div>
            <Icon
              icon={ChevronDownCurved}
              size={26}
              color="gray_500"
              className={chevronStyles}
            />
          </div>
        </div>
        {isProfileOpen && (
          <ProfileHover
            isProfileOpen={isProfileOpen}
            setIsProfileOpen={setIsProfileOpen}
            createNavItemClickHandler={createNavItemClickHandler}
          />
        )}
      </>
    );
  };

  let tabItemsContainerStyles = styles.tabItemsContainer;
  if (isOpen) tabItemsContainerStyles += ` ${styles.visible}`;

  // Close the dropdown if our screensize goes to desktop
  useEffect(() => {
    close();
  }, [useMobileLayout]);

  const onHamburgerClick = () => {
    isOpen ? close() : open();
  };

  const availableLanguages = availableLanguagesMap[userLocale];

  const showLanguageToggle = availableLanguages.length > 1;

  if (useMobileLayout) {
    return (
      <>
        <div className={styles.offsetElement} />
        <nav className={styles.navContainer} data-cy={testId}>
          <div className={styles.navItemContainer}>
            <HamburgerMenuButton isOpen={isOpen} onClick={onHamburgerClick} />
            <ViasatLogo
              logoUrl={`${urlPrefix}${logoUrl}`}
              onClick={createNavItemClickHandler(logoUrl)}
              showLanguageToggle={showLanguageToggle}
            />
            <div className={tabItemsContainerStyles}>
              {tabItems.map((item) => (
                <TabItem
                  key={item.label}
                  {...item}
                  path={`${urlPrefix}${item.path}`}
                  onClick={createNavItemClickHandler(item.path)}
                />
              ))}
              <TabItem
                key={profileMobileTab.label}
                {...profileMobileTab}
                path={`${urlPrefix}${profileMobileTab.path}`}
                onClick={createNavItemClickHandler(profileMobileTab.path)}
              />
              <MobileBottomNav />
            </div>
            {showLanguageToggle && <LanguageToggle />}
          </div>
        </nav>
      </>
    );
  }

  return (
    <>
      <div className={styles.offsetElement} />
      <nav className={styles.navContainer} data-cy={testId}>
        <div className={styles.navItemContainer}>
          <div className={styles.logoAndTabsContainer}>
            <div className={styles.logoContainer}>
              <ViasatLogo
                logoUrl={`${urlPrefix}${logoUrl}`}
                onClick={createNavItemClickHandler(logoUrl)}
              />
            </div>
            <div className={styles.dividerContainer}>
              <div className={styles.divider} />
            </div>
            <div className={styles.tabItemsContainer}>
              {tabItems.map((item) => (
                <TabItem
                  key={item.label}
                  {...item}
                  path={`${urlPrefix}${item.path}`}
                  onClick={createNavItemClickHandler(item.path)}
                />
              ))}
            </div>
          </div>

          <div className={styles.languageToggleAndProfileContainer}>
            {showLanguageToggle && (
              <>
                <LanguageToggle />

                <div className={styles.dividerContainer}>
                  <div className={styles.divider} />
                </div>
              </>
            )}
            <ProfileButton />
          </div>
        </div>
      </nav>
    </>
  );
};

export default Nav;
