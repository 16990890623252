export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AddRelnEdit = {
  __typename?: 'AddRelnEdit';
  destinationId: Scalars['ID'];
  destinationType: ProductIdType;
  relnType: Scalars['String'];
  sourceId: Scalars['ID'];
  sourceType: ProductIdType;
};

export type AddRelnEditInput = {
  destinationId: Scalars['ID'];
  destinationType: ProductIdType;
  relnType: Scalars['String'];
  sourceId: Scalars['ID'];
  sourceType: ProductIdType;
};

export type Attribute = {
  __typename?: 'Attribute';
  name: Scalars['String'];
  value: Scalars['String'];
  valueType?: Maybe<Scalars['String']>;
};

export type CartItemInput = {
  characteristics?: InputMaybe<Array<CharacteristicInput>>;
  description?: InputMaybe<Scalars['String']>;
  kind: Scalars['String'];
  marketingCopy?: InputMaybe<MarketingCopyInput>;
  name: Scalars['String'];
  price?: InputMaybe<PriceInput>;
  prices?: InputMaybe<Array<InputMaybe<PriceInput>>>;
  productCandidateId: Scalars['ID'];
  productInstanceId: Scalars['ID'];
  productTypeId: Scalars['ID'];
  serviceTypes?: InputMaybe<Array<InputMaybe<ServiceTypeInput>>>;
};

export type CartItemRelationshipInput = {
  dstProductInstanceId: Scalars['ID'];
  relationshipType?: InputMaybe<Scalars['String']>;
  srcProductInstanceId: Scalars['ID'];
};

export type Characteristic = {
  __typename?: 'Characteristic';
  name: Scalars['String'];
  value: Scalars['String'];
  valueType?: Maybe<Scalars['String']>;
};

export type CharacteristicInput = {
  name: Scalars['String'];
  /** If the productCandidateId value is missing, this characteristic will be applied to all ProductInstances in the current order line. */
  productCandidateId?: InputMaybe<Scalars['ID']>;
  value: Scalars['String'];
  valueType: Scalars['String'];
};

export type Currency = {
  __typename?: 'Currency';
  alphabeticCode?: Maybe<Scalars['String']>;
  majorUnitSymbol?: Maybe<Scalars['String']>;
  minorUnits?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  numericCode?: Maybe<Scalars['Int']>;
};

export type CurrencyInput = {
  alphabeticCode?: InputMaybe<Scalars['String']>;
  minorUnits?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  numericCode?: InputMaybe<Scalars['Int']>;
};

export type Location = {
  __typename?: 'Location';
  addressLines?: Maybe<Array<Scalars['String']>>;
  city?: Maybe<Scalars['String']>;
  countryCode: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  regionOrState?: Maybe<Scalars['String']>;
  zipOrPostCode?: Maybe<Scalars['String']>;
};

export type LocationInput = {
  addressLines?: InputMaybe<Array<Scalars['String']>>;
  city?: InputMaybe<Scalars['String']>;
  countryCode: Scalars['String'];
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  regionOrState?: InputMaybe<Scalars['String']>;
  zipOrPostCode?: InputMaybe<Scalars['String']>;
};

export type MarketingCopyInput = {
  translations?: InputMaybe<Array<TranslationInput>>;
  uiBehaviors?: InputMaybe<UiBehaviorsInput>;
};

export type Money = {
  __typename?: 'Money';
  currency: Currency;
  value: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** PRIVATE. To be used by BUC resolver only. */
  PACSubmitOrder?: Maybe<Order>;
  cancelOrder?: Maybe<Order>;
  /** PRIVATE. To be used for incident management by OM team only. */
  forceOrderLifecycleStateChange?: Maybe<Order>;
  /** onboardOrder, used for US-res migration */
  onboardOrder?: Maybe<Order>;
  requestOrderLifecycleStateChange?: Maybe<Order>;
  resumeWholesaleService?: Maybe<SuccessResponse>;
  /**
   * `submitOrder` returns an order in an OPEN or SCHEDULED state.
   *
   * See `OrderInput` documentation for a description of the input fields.
   *
   * Query the order's via `getOrder` to see state changes (e.g. to see it go to `PROCESSED`).
   *
   * Pre-conditions:
   * - [buc] If required by the Business Unit as configured in BUC, a Sales Agreement
   *   required.
   * - [so] If a `salesAgreementId` is supplied, it must exist.
   * - [so] If a `shoppingCartId` is supplied, it must exist and be in the TBD state.
   * - [so] If a `salesAgreement` and a `shoppingCartId` are supplied, the products in the
   *   shopping cart must be allowed according to the associated Sales Policy.
   * - [so] If a `salesAgreement` and a `serviceLocation` are supplied, the location must
   *   be within the territory associated (indirectly) with the `salesAgreement`.
   */
  submitOrder?: Maybe<Order>;
  suspendWholesaleService?: Maybe<SuccessResponse>;
  tick?: Maybe<Scalars['Boolean']>;
};

export type MutationPacSubmitOrderArgs = {
  order: PacOrderInput;
};

export type MutationCancelOrderArgs = {
  cancelReason?: InputMaybe<Scalars['String']>;
  orderId: Scalars['ID'];
};

export type MutationForceOrderLifecycleStateChangeArgs = {
  destinationState: OrderLifecycleState;
  forceReason: Scalars['String'];
  orderId: Scalars['ID'];
};

export type MutationOnboardOrderArgs = {
  order: OnboardOrderInput;
};

export type MutationRequestOrderLifecycleStateChangeArgs = {
  destinationState?: InputMaybe<OrderLifecycleState>;
  orderId?: InputMaybe<Scalars['ID']>;
};

export type MutationResumeWholesaleServiceArgs = {
  endUser: Scalars['String'];
  subscriptionId: Scalars['ID'];
};

export type MutationSubmitOrderArgs = {
  order: OrderInput;
};

export type MutationSuspendWholesaleServiceArgs = {
  endUser: Scalars['String'];
  reason: Scalars['String'];
  subscriptionId: Scalars['ID'];
};

export type OnboardOrderInput = {
  customerRelationshipId: Scalars['ID'];
  orderId: Scalars['ID'];
  orderLines?: InputMaybe<Array<OnboardOrderLineItemInput>>;
  orderPlacedDate?: InputMaybe<Scalars['DateTime']>;
  /** Input used to determine BUC policy to apply; if not specified, policy for US Res is used. */
  policyInput?: InputMaybe<OnboardOrderPolicyInput>;
  salesAgreementId?: InputMaybe<Scalars['ID']>;
  /** Tokenized service location. */
  serviceLocationId?: InputMaybe<Scalars['ID']>;
  state: OrderLifecycleState;
};

export type OnboardOrderLineItemInput = {
  characteristics?: InputMaybe<Array<InputMaybe<CharacteristicInput>>>;
  orderLineEvent: OrderLineEvent;
  orderLineId: Scalars['ID'];
  productInstanceId?: InputMaybe<Scalars['ID']>;
  /** Tokenized service location. */
  serviceLocationId?: InputMaybe<Scalars['ID']>;
  state: OrderLineItemLifecycleState;
};

export type OnboardOrderPolicyInput = {
  /** Business Unit (IRA Seller Role Party) ID; used to determine appropriate policy. */
  businessUnitId: Scalars['ID'];
  /** Country code; used to determine appropriate policy. */
  countryCode: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  attributes?: Maybe<Array<Attribute>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerRelationshipId: Scalars['ID'];
  /** This is the date the order is scheduled to be processed. */
  executionDate?: Maybe<Scalars['DateTime']>;
  orderId: Scalars['ID'];
  /** orderLineItemConfigurations is DEPRECATED; use OrderLineItem.characteristics instead. */
  orderLineItemConfigurations?: Maybe<Array<OrderLineItemConfiguration>>;
  orderLines?: Maybe<Array<OrderLineItem>>;
  paymentTransactionId?: Maybe<Scalars['ID']>;
  productCandidateGraphEdits?: Maybe<Array<ProductCandidateGraphEdit>>;
  productInstanceIds?: Maybe<Array<Scalars['ID']>>;
  productInstanceSummary?: Maybe<Array<ProductInstanceSummaryItem>>;
  salesAgreementId?: Maybe<Scalars['ID']>;
  /** serviceLocation is DEPRECATED; use serviceLocationId instead. */
  serviceLocation?: Maybe<Location>;
  /** Identifies the related service location identity created by https://docs.bep-api.viasat.io/manuals/psm-manual.html#_createlocationidpayload */
  serviceLocationId?: Maybe<Scalars['ID']>;
  shoppingCartId?: Maybe<Scalars['ID']>;
  state?: Maybe<OrderLifecycleState>;
};

export type OrderAttributeInput = {
  name: Scalars['String'];
  value: Scalars['String'];
  valueType?: InputMaybe<Scalars['String']>;
};

export type OrderInput = {
  /** OrderAttributes can consist of arbitrary business unit specific information that is opaque to OM. */
  attributes?: InputMaybe<Array<OrderAttributeInput>>;
  customerRelationshipId: Scalars['ID'];
  executionDate?: InputMaybe<Scalars['DateTime']>;
  orderId: Scalars['ID'];
  orderLines?: InputMaybe<Array<OrderLineItemInput>>;
  paymentTransactionId?: InputMaybe<Scalars['ID']>;
  productCandidateGraphEdits?: InputMaybe<
    Array<ProductCandidateGraphEditInput>
  >;
  productInstanceIds?: InputMaybe<Array<Scalars['ID']>>;
  salesAgreementId?: InputMaybe<Scalars['ID']>;
  serviceLocation?: InputMaybe<LocationInput>;
  shoppingCartId?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<OrderLifecycleState>;
  validationSignature?: InputMaybe<Scalars['String']>;
};

export enum OrderLifecycleState {
  Canceled = 'Canceled',
  Canceling = 'Canceling',
  CancelingError = 'CancelingError',
  Closed = 'Closed',
  Initialized = 'Initialized',
  InitializedError = 'InitializedError',
  Initializing = 'Initializing',
  InitializingError = 'InitializingError',
  Open = 'Open',
  OpenError = 'OpenError',
  Processed = 'Processed',
  ProcessedError = 'ProcessedError',
  Rejected = 'Rejected',
  Scheduled = 'Scheduled',
}

export enum OrderLineEvent {
  Add = 'Add',
  Move = 'Move',
  Reconnect = 'Reconnect',
  Return = 'Return',
  Transition = 'Transition',
}

export type OrderLineItem = {
  __typename?: 'OrderLineItem';
  attributes?: Maybe<Array<Attribute>>;
  characteristics?: Maybe<Array<Maybe<Characteristic>>>;
  /** DEPRECATED */
  configuredProductType?: Maybe<ProductType>;
  contractId?: Maybe<Scalars['ID']>;
  fulfillmentAgreementId?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  orderLineEvent: OrderLineEvent;
  orderLineId: Scalars['ID'];
  prodCandidateCharacteristics?: Maybe<
    Array<Maybe<ProductCandidateCharacteristic>>
  >;
  productCandidateGraph?: Maybe<ProductCandidateGraph>;
  /** Eventually replace orderLineItemConfigurations attribute with productInstanceCharacteristics. */
  productInstanceCharacteristics?: Maybe<Array<Maybe<Characteristic>>>;
  productInstanceId?: Maybe<Scalars['ID']>;
  /** serviceLocation is DEPRECATED; use serviceLocationId instead. */
  serviceLocation?: Maybe<Location>;
  serviceLocationId?: Maybe<Scalars['ID']>;
  shoppingCartItemId?: Maybe<Scalars['ID']>;
  soProductInstances?: Maybe<Array<SoProductInstance>>;
  state?: Maybe<OrderLineItemLifecycleState>;
};

export type OrderLineItemConfiguration = {
  __typename?: 'OrderLineItemConfiguration';
  attributes?: Maybe<Array<Attribute>>;
  orderLineItemId: Scalars['ID'];
};

export type OrderLineItemInput = {
  /** Eventually replace orderLineItemConfigurations attribute with productInstanceCharacteristics.  The characteristics attribute should be used instead of orderLineItemConfigurations. */
  characteristics?: InputMaybe<Array<InputMaybe<CharacteristicInput>>>;
  contractId?: InputMaybe<Scalars['ID']>;
  orderLineAttributes?: InputMaybe<Array<OrderAttributeInput>>;
  /** The orderLineEvent indicates if this is an Add order or a Return order. */
  orderLineEvent: OrderLineEvent;
  orderLineId: Scalars['ID'];
  /** orderLineItemId is DEPRECATED. Please do not use this field. This field will be removed in the future. */
  orderLineItemId?: InputMaybe<Scalars['ID']>;
  /** The productInstanceId attribute is used to refer to an existing ProductInstance to be updated by this OrderLineItem. */
  productInstanceId?: InputMaybe<Scalars['ID']>;
  serviceLocation?: InputMaybe<LocationInput>;
  /** The shoppingCartId is used to refer to a specific item within the ShoppingCart referred to by the shoppingCartId attribute in the Order. */
  shoppingCartItemId?: InputMaybe<Scalars['ID']>;
};

export enum OrderLineItemLifecycleState {
  Canceled = 'Canceled',
  Canceling = 'Canceling',
  CancelingError = 'CancelingError',
  Closed = 'Closed',
  Initialized = 'Initialized',
  InitializedError = 'InitializedError',
  Initializing = 'Initializing',
  InitializingError = 'InitializingError',
  Open = 'Open',
  OpenError = 'OpenError',
  Processed = 'Processed',
  ProcessedError = 'ProcessedError',
  Rejected = 'Rejected',
  Scheduled = 'Scheduled',
}

export type PacAddressInput = {
  addressLines?: InputMaybe<Array<Scalars['String']>>;
  countryCode: Scalars['String'];
  municipality?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

export type PacCoordinatesInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type PacLocationInput = {
  address: PacAddressInput;
  coordinates?: InputMaybe<PacCoordinatesInput>;
};

export type PacOrderInput = {
  /** OrderAttributes can consist of arbitrary business unit specific information that is opaque to OM. */
  attributes?: InputMaybe<Array<OrderAttributeInput>>;
  customerRelationshipId: Scalars['ID'];
  executionDate?: InputMaybe<Scalars['DateTime']>;
  fulfillmentAgreementId?: InputMaybe<Scalars['ID']>;
  orderId: Scalars['ID'];
  orderLines?: InputMaybe<Array<PacOrderLineInput>>;
  productInstanceIds?: InputMaybe<Array<Scalars['ID']>>;
  salesAgreementId?: InputMaybe<Scalars['ID']>;
};

export type PacOrderLineInput = {
  attributes?: InputMaybe<Array<OrderAttributeInput>>;
  billingAccountId: Scalars['ID'];
  contractId?: InputMaybe<Scalars['ID']>;
  fulfillmentAgreementId?: InputMaybe<Scalars['ID']>;
  newLocationWorkOrderInformation?: InputMaybe<PacWorkOrderInformationInput>;
  newServiceLocation?: InputMaybe<PacLocationInput>;
  notes?: InputMaybe<Scalars['String']>;
  oldLocationWorkOrderInformation?: InputMaybe<PacWorkOrderInformationInput>;
  oldProductInstanceId?: InputMaybe<Scalars['ID']>;
  oldServiceLocation?: InputMaybe<PacLocationInput>;
  orderLineId?: InputMaybe<Scalars['ID']>;
  orderLineType: OrderLineEvent;
  /** paymentTransactionId is DEPRECATED; use paymentTransactionIds instead. */
  paymentTransactionId?: InputMaybe<Scalars['ID']>;
  paymentTransactionIds?: InputMaybe<Array<Scalars['ID']>>;
  productGraph?: InputMaybe<ProductGraphInput>;
  productInstanceId?: InputMaybe<Scalars['ID']>;
  productInstanceIdToReconnect?: InputMaybe<Scalars['ID']>;
  serviceLocationId?: InputMaybe<Scalars['ID']>;
  shoppingCartId?: InputMaybe<Scalars['ID']>;
  taxJurisdiction?: InputMaybe<TaxJurisdictionInput>;
  waiveEtf?: InputMaybe<Scalars['Boolean']>;
};

export type PacWorkOrderAppointmentInput = {
  endTime?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['String']>;
};

export type PacWorkOrderInformationInput = {
  appointment: PacWorkOrderAppointmentInput;
  notes?: InputMaybe<Scalars['String']>;
};

export type PortfolioItemInput = {
  characteristics?: InputMaybe<Array<CharacteristicInput>>;
  description?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
  marketingCopy?: InputMaybe<MarketingCopyInput>;
  name?: InputMaybe<Scalars['String']>;
  prices?: InputMaybe<Array<InputMaybe<PriceInput>>>;
  productInstanceId: Scalars['ID'];
  productTypeId?: InputMaybe<Scalars['ID']>;
  serviceTypes?: InputMaybe<Array<ServiceTypeInput>>;
};

export type PortfolioItemRelationshipInput = {
  dstProductInstanceId: Scalars['ID'];
  srcProductInstanceId: Scalars['ID'];
};

export type Price = {
  __typename?: 'Price';
  amount: Money;
  characteristics?: Maybe<Array<Characteristic>>;
  description?: Maybe<Scalars['String']>;
  kind: Scalars['String'];
  name: Scalars['String'];
  percentage?: Maybe<Scalars['Float']>;
  recurrence: Scalars['String'];
  unitOfMeasure?: Maybe<Scalars['String']>;
};

export type PriceAmountInput = {
  currency?: InputMaybe<CurrencyInput>;
  value?: InputMaybe<Scalars['Float']>;
};

export type PriceInput = {
  amount: PriceAmountInput;
  characteristics?: InputMaybe<Array<CharacteristicInput>>;
  description?: InputMaybe<Scalars['String']>;
  kind: Scalars['String'];
  name: Scalars['String'];
  recurrence?: InputMaybe<Scalars['String']>;
};

export type ProductCandidate = {
  __typename?: 'ProductCandidate';
  characteristics: Array<Characteristic>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kind: Scalars['String'];
  name: Scalars['String'];
  prices?: Maybe<Array<Price>>;
  productTypeId: Scalars['ID'];
};

export type ProductCandidateCharacteristic = {
  __typename?: 'ProductCandidateCharacteristic';
  characteristics?: Maybe<Array<Characteristic>>;
  productCandidateId: Scalars['ID'];
};

export type ProductCandidateGraph = {
  __typename?: 'ProductCandidateGraph';
  productCandidates?: Maybe<Array<ProductCandidate>>;
  relationships?: Maybe<Array<ProductCandidateReln>>;
};

export type ProductCandidateGraphEdit = {
  __typename?: 'ProductCandidateGraphEdit';
  addRelnEdit?: Maybe<AddRelnEdit>;
  editType: ProductCandidateGraphEditType;
  id: Scalars['ID'];
  replaceProdCandidateEdit?: Maybe<ReplaceProdCandidateEdit>;
  rmProdCandidateEdit?: Maybe<RmProdCandidateEdit>;
  rmRelnEdit?: Maybe<RmRelnEdit>;
};

export type ProductCandidateGraphEditInput = {
  addRelnEdit?: InputMaybe<AddRelnEditInput>;
  editType: ProductCandidateGraphEditType;
  id: Scalars['ID'];
  replaceProdCandidateEdit?: InputMaybe<ReplaceProdCandidateEditInput>;
  rmProdCandidateEdit?: InputMaybe<RmProdCandidateEditInput>;
  rmRelnEdit?: InputMaybe<RmRelnEditInput>;
};

export enum ProductCandidateGraphEditType {
  AddReln = 'AddReln',
  ReplaceProdCandidateEdit = 'ReplaceProdCandidateEdit',
  RmProdCandidate = 'RmProdCandidate',
  RmReln = 'RmReln',
}

export type ProductCandidateReln = {
  __typename?: 'ProductCandidateReln';
  destinationId: Scalars['ID'];
  id: Scalars['ID'];
  relationshipType: Scalars['String'];
  sourceId: Scalars['ID'];
};

export type ProductGraphInput = {
  cartItemRelationships: Array<CartItemRelationshipInput>;
  cartItems: Array<CartItemInput>;
  portfolioItemRelationships?: InputMaybe<
    Array<PortfolioItemRelationshipInput>
  >;
  portfolioItems?: InputMaybe<Array<PortfolioItemInput>>;
};

export enum ProductIdType {
  ProductCandidateId = 'ProductCandidateId',
  ProductInstanceId = 'ProductInstanceId',
}

export type ProductInstanceSummaryItem = {
  __typename?: 'ProductInstanceSummaryItem';
  productCandidateId: Scalars['ID'];
  productInstanceId: Scalars['ID'];
};

export type ProductType = {
  __typename?: 'ProductType';
  characteristics: Array<Characteristic>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kind: Scalars['String'];
  name: Scalars['String'];
  prices?: Maybe<Array<Price>>;
  products?: Maybe<Array<ProductType>>;
};

export type Query = {
  __typename?: 'Query';
  getOrder?: Maybe<Order>;
  getOrdersByAttribute: Array<Maybe<Order>>;
  getOrdersByContractId: Array<Maybe<Order>>;
  getOrdersByCustomerRelationshipId: Array<Maybe<Order>>;
  getOrdersByProductInstanceId: Array<Maybe<Order>>;
  version: Scalars['String'];
  workflowStatus?: Maybe<WorkflowStatusResponse>;
};

export type QueryGetOrderArgs = {
  orderId: Scalars['ID'];
};

export type QueryGetOrdersByAttributeArgs = {
  attributeName: Scalars['String'];
  attributeValue: Scalars['String'];
};

export type QueryGetOrdersByContractIdArgs = {
  contractId: Scalars['String'];
};

export type QueryGetOrdersByCustomerRelationshipIdArgs = {
  customerRelationshipId: Scalars['String'];
};

export type QueryGetOrdersByProductInstanceIdArgs = {
  productInstanceId: Scalars['String'];
};

export type QueryWorkflowStatusArgs = {
  workflowId: Scalars['ID'];
};

export type ReplaceProdCandidateEdit = {
  __typename?: 'ReplaceProdCandidateEdit';
  newProductInstanceId: Scalars['ID'];
  oldProductCandidateId: Scalars['ID'];
};

export type ReplaceProdCandidateEditInput = {
  newProductInstanceId: Scalars['ID'];
  oldProductCandidateId: Scalars['ID'];
};

export type RmProdCandidateEdit = {
  __typename?: 'RmProdCandidateEdit';
  productCandidateId: Scalars['ID'];
};

export type RmProdCandidateEditInput = {
  productCandidateId: Scalars['ID'];
};

export type RmRelnEdit = {
  __typename?: 'RmRelnEdit';
  relnId: Scalars['ID'];
};

export type RmRelnEditInput = {
  relnId: Scalars['ID'];
};

export type ServiceTypeInput = {
  characteristics?: InputMaybe<Array<CharacteristicInput>>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  kind: Scalars['String'];
  name: Scalars['String'];
};

export type SoProductInstance = {
  __typename?: 'SoProductInstance';
  characteristics?: Maybe<Array<Characteristic>>;
  id: Scalars['ID'];
  lifecycleState?: Maybe<SoProductInstanceLifecycleState>;
  prices?: Maybe<Array<Maybe<Price>>>;
  salesAgreementId?: Maybe<Scalars['ID']>;
};

export enum SoProductInstanceLifecycleState {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  errorMsg?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  workflowId?: Maybe<Scalars['String']>;
};

export type TaxJurisdictionInput = {
  taxCodeType: Scalars['String'];
  taxCodeValue: Scalars['String'];
};

export type TranslationInput = {
  characteristics: Array<CharacteristicInput>;
  language: Scalars['String'];
  name: Scalars['String'];
};

export type UiBehaviorsInput = {
  characteristics: Array<CharacteristicInput>;
};

export enum WorkflowState {
  Closed = 'Closed',
  Open = 'Open',
}

export type WorkflowStatusResponse = {
  __typename?: 'WorkflowStatusResponse';
  errorMsg?: Maybe<Scalars['String']>;
  status?: Maybe<WorkflowState>;
};
