import React from 'react';
import { View, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { PlanCardType } from './PlanCard';
import { Text } from '../../../Text';
import { Color } from '../../../../utils';
import { Button } from '../../../Button';

export interface PlanOverviewProps extends PlanCardType {
  isExpanded: boolean;
  handleToggle(): void;
}

export type DetailsToggle = Pick<
  PlanOverviewProps,
  't' | 'isExpanded' | 'handleToggle' | 'testID'
>;

const DetailsToggle = ({
  t,
  isExpanded,
  handleToggle,
  testID = '',
}: DetailsToggle): JSX.Element => {
  const {
    PlanCard: {
      detailsToggle: { hide, show },
    },
  } = t;
  const showHide = isExpanded ? hide : show;
  const iconDir = isExpanded ? 'up' : 'down';
  return (
    <Text>
      {/* Double text is to force inline link */}
      <Text
        caption
        fixed
        testID={`${testID}-ToggleButton`}
        onPress={handleToggle}
      >
        {showHide}
        <Icon
          name={`chevron-${iconDir}`}
          color={Color.teal500}
          size={17}
          style={styles.toggleIcon}
          testID={`${testID}-Icon`}
        />
      </Text>
    </Text>
  );
};

export const PlanOverview = ({
  t,
  plan,
  alwaysExpanded,
  isExpanded,
  handleToggle,
  onChoose,
  testID,
}: PlanOverviewProps): JSX.Element => {
  const {
    PlanCard: { price, chooseButton, downloadDesc, uploadDesc },
  } = t;
  const { name } = plan;
  return (
    <>
      <View style={{ marginHorizontal: 8 }}>
        <Text italic caption fixed testID={`${testID}-Name`}>
          {name}
        </Text>
        <Text
          uniNeue
          large
          heavy
          style={styles.planPrice}
          testID={`${testID}-Price`}
        >
          {price}
        </Text>

        <Text.H4
          heavy
          style={styles.downloadDesc}
          testID={`${testID}-Download`}
        >
          {downloadDesc}
        </Text.H4>
        <Text small fixed style={styles.uploadDesc} testID={`${testID}-Upload`}>
          {uploadDesc}
        </Text>
        {!alwaysExpanded && (
          <DetailsToggle
            {...{ t, isExpanded, handleToggle }}
            testID={`${testID}-DetailsToggle`}
          />
        )}
      </View>
      {onChoose && (
        <Button
          fill
          style={styles.chooseButton}
          testID={`${testID}-ChooseButton`}
          onPress={(): void => {
            onChoose(plan);
          }}
        >
          {chooseButton}
        </Button>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  chooseButton: {
    marginTop: 24,
    backgroundColor: '#008CC9',
  },
  downloadDesc: {
    marginTop: 12,
    marginBottom: 4,
  },
  planPrice: {
    color: '#008CC9',
    marginTop: 23,
  },
  toggleIcon: {
    position: 'relative',
    top: 2,
  },
  uploadDesc: {
    marginBottom: 8,
  },
});
