const IllustrationBrazilCreditCard = () => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.44">
        <path
          opacity="0.5"
          d="M100 158.311C142.609 158.311 177.151 123.769 177.151 81.16C177.151 38.5507 142.609 4.00903 100 4.00903C57.3907 4.00903 22.849 38.5507 22.849 81.16C22.849 123.769 57.3907 158.311 100 158.311Z"
          fill="#E1D658"
        />
        <path
          opacity="0.5"
          d="M38.635 81.1601C38.635 47.3242 66.164 19.7961 100.001 19.7961C133.838 19.7961 161.366 47.3242 161.366 81.1601C161.366 114.996 133.837 142.524 100.001 142.524C66.165 142.524 38.635 114.996 38.635 81.1601Z"
          fill="#E1D658"
        />
        <path
          opacity="0.5"
          d="M54.422 81.16C54.422 56.028 74.869 35.582 100.001 35.582C125.133 35.582 145.579 56.028 145.579 81.16C145.579 106.292 125.132 126.738 100.001 126.738C74.87 126.738 54.422 106.292 54.422 81.16Z"
          fill="#E1D658"
        />
        <path
          opacity="0.5"
          d="M100.001 110.951C83.548 110.951 70.208 97.6131 70.208 81.1601C70.208 64.7071 83.548 51.3691 100.001 51.3691C116.454 51.3691 129.793 64.7071 129.793 81.1601C129.793 97.6131 116.453 110.951 100.001 110.951Z"
          fill="#E1D658"
        />
      </g>
      <path
        d="M113.123 94.5C110.488 93.352 107.766 94.695 107.073 97.484C107.073 97.484 99.61 127.539 100.19 128.636C100.77 129.733 108.784 157.835 108.784 157.835C109.572 160.599 112.246 162.144 114.725 161.268C117.204 160.392 119.052 157.331 118.832 154.466L116.805 128.156C116.584 125.29 117.202 120.734 118.178 118.031L123.056 104.517C124.032 101.814 122.674 98.662 120.039 97.514L113.123 94.501V94.5Z"
        fill="#CDA36E"
      />
      <path
        d="M104.39 96.2051C101.705 95.1801 98.882 96.6071 98.116 99.3771C98.116 99.3771 91.318 123.961 91.914 124.898C92.509 125.836 100.698 149.102 100.698 149.102C101.652 151.813 104.448 153.454 106.911 152.749C109.374 152.044 111.136 149.129 110.827 146.272L108.582 125.513C108.273 122.656 108.913 118.142 110.005 115.484L114.103 105.505C115.195 102.846 113.891 99.8321 111.206 98.8071L104.39 96.2041V96.2051Z"
        fill="#DBBB89"
      />
      <path
        d="M165.656 195.991L157.732 162.402L157.994 109.997C158.008 107.123 155.984 103.595 153.495 102.158L118.563 81.985C116.074 80.548 111.975 80.5 109.453 81.879L91.3559 91.775C88.8339 93.154 86.8709 96.632 86.9929 99.503L87.9349 121.688C88.0569 124.56 90.5069 126.837 93.3799 126.747L94.1299 126.724C97.0029 126.635 99.6859 124.235 100.093 121.389L102.121 107.205C102.528 104.36 104.709 103.486 106.967 105.264L111.75 109.029C114.008 110.807 126.322 195.988 126.322 195.988H165.656V195.991Z"
        fill="#EAD1A6"
      />
      <path
        d="M123.692 111.235C122.765 112.163 121.261 112.163 120.333 111.235L70.364 61.2661C69.437 60.3381 69.437 58.8341 70.364 57.9071L101.005 27.2661C101.932 26.3381 103.436 26.3381 104.364 27.2661L154.333 77.2351C155.261 78.1631 155.261 79.6671 154.333 80.5941L123.692 111.235Z"
        fill="#518FFB"
      />
      <path
        d="M100.157 39.0013L96.1143 43.0438C95.1868 43.9713 95.1868 45.475 96.1143 46.4026L105.471 55.7592C106.398 56.6867 107.902 56.6867 108.829 55.7592L112.872 51.7168C113.799 50.7893 113.799 49.2855 112.872 48.358L103.516 39.0014C102.588 38.0739 101.084 38.0739 100.157 39.0013Z"
        fill="#D0DA33"
      />
      <path
        d="M88.9315 50.2281L85.8951 53.2644L95.5556 62.9249L98.592 59.8886L88.9315 50.2281Z"
        fill="white"
      />
      <path
        d="M82.2856 56.8746L79.2485 59.9116L97.716 78.3791L100.753 75.3421L82.2856 56.8746Z"
        fill="white"
      />
      <path
        d="M99.9009 61.198L96.8646 64.2344L106.525 73.894L109.561 70.8576L99.9009 61.198Z"
        fill="white"
      />
      <path
        d="M110.871 72.1677L107.834 75.2041L117.494 84.8637L120.531 81.8274L110.871 72.1677Z"
        fill="white"
      />
      <path
        d="M121.841 83.1378L118.805 86.1741L128.465 95.8347L131.501 92.7985L121.841 83.1378Z"
        fill="white"
      />
      <path
        d="M145.081 73.3901C147.803 76.1121 147.803 80.5401 145.081 83.2621C142.359 85.9841 137.931 85.9831 135.209 83.2621"
        stroke="white"
        strokeWidth="0.95"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.558 74.9131C145.44 76.7951 145.44 79.8561 143.558 81.7381C141.676 83.6201 138.614 83.6201 136.733 81.7381"
        stroke="white"
        strokeWidth="0.95"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.034 76.436C143.077 77.479 143.078 79.171 142.034 80.215C140.991 81.258 139.299 81.258 138.255 80.215"
        stroke="white"
        strokeWidth="0.95"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.985 177.377C149.096 175.138 150.586 170.8 150.296 167.736L147.024 133.183C146.734 130.119 144.553 126.013 142.177 124.057L133.016 116.516C130.64 114.56 127.857 110.586 126.832 107.684L121.939 93.84C120.913 90.938 114.631 79.892 107.131 83.149C99.631 86.406 101.142 95.934 102.59 98.649L110.544 113.984C111.992 116.699 112.693 121.392 112.102 124.412L106.283 154.129C105.692 157.149 106.436 161.818 107.936 164.505L124.243 193.709C125.743 196.396 128.698 196.763 130.81 194.524L146.985 177.376V177.377Z"
        fill="#F6E0B8"
      />
      <path
        opacity="0.5"
        d="M94.8 85.7981C90.702 87.9611 90.717 92.1501 93.159 96.5571C95.756 101.244 99.015 98.8441 101.687 97.1401C104.657 95.2461 106.723 93.5771 104.182 89.4851C101.628 85.3711 98.062 84.0761 94.8 85.7971V85.7981Z"
        fill="white"
      />
    </svg>
  );
};

export default IllustrationBrazilCreditCard;
