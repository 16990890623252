import React from 'react';
import { Platform, Text, TextStyle } from 'react-native';
import { Color, ScreenSize, DimensionContext } from '../../utils';
import { TextStyleArray as StyleArray } from '../types';

type FontPoints = 10 | 11 | 12 | 14 | 16 | 18 | 20 | 24 | 28 | 32 | 40 | 60;
export const fontSizes: FontPoints[] = [
  10, 11, 12, 14, 16, 18, 20, 24, 28, 32, 40, 60,
];

export enum TextSize {
  Tiny,
  Caption,
  Small,
  Body,
  Large,
  H4,
  H3,
  H2,
  H1,
  Display,
}

export enum TypographyBreakpoint {
  XS,
  SM,
  Default,
}

export type Fonts = 'SourceSansPro' | 'UniNeue';
type SourceSansBook = 'Regular' | '';
export type Weights =
  | 'Light'
  | 'Book'
  | 'Bold'
  | 'SemiBold'
  | 'Heavy'
  | SourceSansBook;
export type FontStyle = 'Italic' | '';

export type FontSize = { fontSize: FontPoints };
export type FontFamily = { fontFamily: string };
export type FontColor = { color: Color };

export const validateFont = (
  font: Fonts,
  weight: Weights,
  style: FontStyle
): void => {
  if (font === 'UniNeue' && weight === 'SemiBold')
    console.error('Font family UniNeue does not have semi-bold Weight prop.');
  if (font === 'UniNeue' && weight === 'Light')
    console.error('Font family UniNeue does not have light Weight prop.');
  if (font === 'SourceSansPro' && weight === 'Heavy')
    console.error('Font family SourceSansPro does not have heavy Weight prop.');
  if (font === 'UniNeue' && style === 'Italic')
    console.error('Font family UniNeue does not have italic prop.');
};

export const getFontFamily = (
  font: Fonts,
  weight: Weights,
  style: FontStyle
): FontFamily => {
  validateFont(font, weight, style);
  if (font === 'SourceSansPro') {
    font += '-';
    if (weight === 'Book')
      weight = style !== 'Italic' && Platform.OS !== 'android' ? 'Regular' : '';
  }

  const fontFamily = font + weight + style;
  return { fontFamily };
};

export const getWeight = (
  light = false,
  book = false,
  semiBold = false,
  bold = false,
  heavy = false
): Weights => {
  // Using unary operation
  if (+light + +book + +semiBold + +bold + +heavy > 1) {
    console.error('Text received more than one Weight prop.');
  }
  switch (true) {
    case light:
      return 'Light';
    case book:
    default:
      return 'Book';
    case semiBold:
      return 'SemiBold';
    case bold:
      return 'Bold';
    case heavy:
      return 'Heavy';
  }
};

export const getColor = (
  white = false,
  gray = false,
  link = false
): TextStyle => {
  if (+white + +gray > 1) {
    console.error('Text received more than one text color prop.');
  }
  let color: Color;
  if (link && !(white || gray)) {
    color = Color.blue600;
  } else if (white) {
    color = Color.white;
  } else {
    color = Color.gray800;
  }
  return { color, textDecorationLine: link ? 'underline' : undefined };
};

export const getFont = (
  sourceSans = false,
  uniNeue = false,
  overrideDefault = false
): Fonts => {
  // Using unary operation.
  if (+sourceSans + +uniNeue > 1) {
    console.error('Text received more than one Font prop.');
  }
  switch (true) {
    case sourceSans:
      return 'SourceSansPro';
    case uniNeue:
      return 'UniNeue';
    default:
      return overrideDefault ? 'SourceSansPro' : 'UniNeue';
  }
};

export const getType = (
  tiny = false,
  caption = false,
  small = false,
  body = false,
  large = false
): TextSize => {
  // Using unary operation
  if (+large + +body + +small + +caption + +tiny > 1) {
    console.error('Text received more than one TextType prop.');
  }
  switch (true) {
    case large:
      return TextSize.Large;
    case body:
    default:
      return TextSize.Body;
    case small:
      return TextSize.Small;
    case caption:
      return TextSize.Caption;
    case tiny:
      return TextSize.Tiny;
  }
};

export const getBreakpoint = (screenSize: ScreenSize): TypographyBreakpoint => {
  switch (screenSize) {
    case ScreenSize.XS:
      return TypographyBreakpoint.XS;
    case ScreenSize.SM:
      return TypographyBreakpoint.SM;
    case ScreenSize.MD:
    case ScreenSize.LG:
    case ScreenSize.XL:
    default:
      return TypographyBreakpoint.Default;
  }
};

export const getFontSize = (
  type: TextSize,
  breakpoint: TypographyBreakpoint,
  fixed: boolean
): FontSize => {
  const index = (fixed ? TypographyBreakpoint.Default : breakpoint) + type;
  return { fontSize: fontSizes[index] };
};

export const createTextComponent = (
  root: Text | null | undefined,
  testSize: TextSize,
  context: DimensionContext,
  style: StyleArray,
  fixed: boolean,
  props: any
): JSX.Element => {
  const { screenSize } = context;
  const typographyBreakpoint = getBreakpoint(screenSize);
  const fontSize = getFontSize(testSize, typographyBreakpoint, fixed);

  return (
    <Text
      ref={(c) => {
        root = c;
      }}
      style={[fontSize, style]}
      {...props}
    />
  );
};
