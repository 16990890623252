import * as React from 'react';
import { Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectConfig } from '@mfe/shared/redux/config';
import type { Invoice } from '@mfe/shared/schema-types';
import { useTrackTiming } from '@mfe/shared/util';
import { trackBillingEvent } from '@mfe/to-be-migrated/redux/analytics';
import { useDownloadInvoice } from '@mfe/legacy/mv/views/PayBill/hooks';
import { selectBillingInfo } from '@mfe/to-be-migrated/redux/billingInfo';
import { sendMessageToMobileApp } from '@mfe/shared/cross-platform-events';
import { Route, setBillingNav } from '@mfe/to-be-migrated/redux/billingNav';

import LinkLoading from './link-loading';
import { getInvoicePDFUrl } from '../get-invoice-pdf-url';
import { ANALYTICS_EVENT_NAME } from '../../../shared/constants';

import styles from './styles.module.scss';

const InvoiceLink = ({ invoice, index }: Pick<Props, 'invoice' | 'index'>) => {
  const { t } = useTranslation('BillingStatement');
  const [date, setDate] = React.useState<string | null>(null);
  const dispatch = useDispatch();
  const { isPWA } = useSelector(selectConfig);

  const [timestamp, setTimestamp] = React.useState<Date>();

  // PWA invoice loads different from non PWA invoice
  // On web none of this code is used
  const { base64Content: invoiceContent, loading: pwaInvoiceLoading } =
    useDownloadInvoice(date);
  const { loadingInfo: billingLoadingInfo } = useSelector(selectBillingInfo);
  const isCurrentNonPWAInvoiceLoading = Boolean(
    invoice?.invoiceNumber &&
      billingLoadingInfo.invoicePdf.includes(invoice?.invoiceNumber)
  );
  const loading = pwaInvoiceLoading || isCurrentNonPWAInvoiceLoading;

  useTrackTiming({
    eventName: 'InvoiceLoaded',
    isLoading: loading,
  });

  React.useEffect(() => {
    // Once downloading invoice has finished on the PWA
    // Save and open the file on the mobile app
    if (!invoiceContent || !date || !isPWA) return;
    dispatch(
      sendMessageToMobileApp({
        type: 'save-and-open-file',
        data: {
          file: {
            name: `viasat-invoice-${date}.pdf`,
            displayName: `Viasat Invoice - ${date}`,
            base64Content: invoiceContent,
          },
          onErrorDisplayMessage: {
            title: t('invoiceLoadFailed'),
            message: t('invoiceLoadFailedMessage'),
          },
        },
      })
    );
  }, [invoiceContent, date, dispatch, isPWA, t]);

  React.useEffect((): void => {
    // Once we have finished loading...
    if (!loading && timestamp) {
      dispatch(
        trackBillingEvent({
          eventName: ANALYTICS_EVENT_NAME.elementLoaded,
          version: '1-0-0',
          data: {
            element_loaded_name: 'view_statement_link',
            // element_load_time: getLoadTime(timestamp),
            did_element_load: true,
          },
        })
      );
      setTimestamp(undefined);
    }
  }, [dispatch, index, loading, timestamp]);

  const onViewStatementClick = () => {
    setTimestamp(new Date());

    if (isPWA) {
      // Setting the date to non null value triggers useDownloadInvoice
      setDate(getInvoicePDFUrl(invoice.statementDate));
    } else {
      // Open the invoice in a new tab on the web
      window.open(
        `${window.location.origin}/Invoice/${getInvoicePDFUrl(
          invoice.statementDate
        )}`,
        '_blank'
      );
    }

    // analytics
    dispatch(
      trackBillingEvent({
        eventName: ANALYTICS_EVENT_NAME.billStatementSelected,
        version: '1-0-0',
        data: {
          selection_method: 'view_bill_button',
          selection_zone: 'billing_history',
          statement_number: index + 1,
        },
      })
    );
  };

  if (loading) {
    return <LinkLoading />;
  }

  return (
    <button className={styles['link']}>
      <Txt variant="bodySmallRegular" onClick={onViewStatementClick}>
        {t('viewStatement')}
      </Txt>
    </button>
  );
};

type Props = {
  invoice: Invoice;
  index: number;
  showPopupMessage: boolean;
};

const Links = ({ invoice, index, showPopupMessage }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation('BillingStatement');

  return (
    <>
      <div className={styles['links']}>
        <button
          className={styles['link']}
          data-cy="viewPaymentsBtn"
          onClick={() => dispatch(setBillingNav({ route: Route.Payments }))}
        >
          <Txt variant="bodySmallRegular">{t('viewPayments')}</Txt>
        </button>
        <InvoiceLink invoice={invoice} index={index} />
      </div>
      {showPopupMessage && (
        <div style={{ marginTop: '10px' }}>
          <Txt variant="bodySmallRegular">{t('popupBlockerMessage')}</Txt>
        </div>
      )}
    </>
  );
};

export default Links;
