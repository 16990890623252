import { setBillingAlerts } from '@mfe/to-be-migrated/redux/alerts';
import { Route, setBillingNav } from '@mfe/to-be-migrated/redux/billingNav';
import {
  AutopayMethod,
  Money,
  NotificationLevel,
  ProductInstanceStatus,
} from '@mfe/shared/schema-types';
import { Button, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Card from '../../shared-ui/card';
import Divider from '../../shared-ui/divider';
import Gradient from '../../shared-ui/gradient';
import styles from '../vpp.module.scss';
import { formatCurrency } from '../utils';
import { TitleText } from '../../shared-ui/title';
import { CardLogo } from '@mfe/shared/components';

type Props = {
  txnAmount?: number;
  paymentMethod?: Partial<AutopayMethod> | null;
  accountStatus?: ProductInstanceStatus | null;
  balance?: Money | null;
  saveOTP?: boolean;
};

const PaymentStatus = ({
  txnAmount,
  paymentMethod,
  accountStatus,
  balance,
  saveOTP,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const restoringService =
    accountStatus === ProductInstanceStatus.Suspended &&
    (txnAmount || -1) >= (balance?.value ?? 0);

  return (
    <div
      className={styles['paymentContainer']}
      data-cy="payment-status-heading"
    >
      <Card className={styles['card']}>
        <Gradient />

        <div className={styles['container']}>
          <div className={styles['titleContainer']}>
            <Txt variant="heading4">{t('VPP:paymentStatusHeading')}</Txt>
          </div>

          {restoringService && (
            <div className={styles['statusAlert']}>
              <Txt variant="bodySmallRegular" color="regular">
                {t('VPP:paymentServiceRestored')}
              </Txt>
            </div>
          )}

          <div className={styles['statusContainer']}>
            <TitleText title={t('VPP:paymentAmountTitle')}>
              <Txt variant="bodySmallRegular" color="regular">
                {formatCurrency(
                  txnAmount,
                  balance?.alphabeticCode ?? undefined
                )}
              </Txt>
            </TitleText>
          </div>

          <div className={styles['statusContainer']}>
            <TitleText title={t('VPP:paymentDateTitle')}>
              <Txt
                style={{ textTransform: 'capitalize' }}
                variant="bodySmallRegular"
                color="regular"
              >
                {t('VPP:paymentDate', { date: new Date() })}
              </Txt>
            </TitleText>
          </div>

          <div className={styles['statusMethodContainer']}>
            <TitleText title={t('VPP:paymentMethodTitle')}>
              <div className={styles['label']}>
                <CardLogo name={paymentMethod?.paymentMethod} width="44px" />
                <Txt variant="bodySmallRegular" color="regular">
                  {t('VPP:paymentMethod', {
                    paymentMethod: paymentMethod?.paymentMethod,
                    last4Digits: paymentMethod?.last4Digits,
                    count: paymentMethod?.last4Digits?.length,
                  })}
                </Txt>
              </div>
            </TitleText>
          </div>

          <div className={styles['statusContainer']}>
            <div className={styles['terms']}>
              <Txt variant="bodySmallRegular" color="regular">
                {t('VPP:terms')}
              </Txt>
            </div>
          </div>

          <Divider />

          <div className={styles['statusFooterContainer']}>
            <Button
              data-cy="go-to-billing-button"
              theme="color"
              className={styles['cancelButton']}
              onClick={() => {
                const alerts = saveOTP ? ['PaymentMethodUpdated'] : [];
                if (restoringService) {
                  alerts.push('PaymentRestoredService');
                } else {
                  alerts.push('PaymentOneTime');
                }

                const newAlerts = alerts.map((alert) => ({
                  level: NotificationLevel.Success,
                  title: t(`Alerts:Billing.${alert}.title`),
                  caption: t(`Alerts:Billing.${alert}.caption`),
                }));
                dispatch(setBillingAlerts(newAlerts));

                dispatch(setBillingNav({ route: Route.Home }));
              }}
            >
              <Txt variant="labelMedium">{t('VPP:goToBilling')}</Txt>
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PaymentStatus;
