import {
  addAdditionalTranslations,
  Translations,
} from '@mfe/services/translations-service';
import { streamOnTranslations } from '@mfe/features/stream-on/translations';
import { networkTranslations } from '@mfe/features/network/translations';

import { useEffect } from 'react';

export const useAddTranslations = () => {
  useEffect(() => {
    [streamOnTranslations, networkTranslations].forEach(
      (translations: Translations) => {
        addAdditionalTranslations(translations[0], translations[1]);
      }
    );
  }, []);
};
