import { IllustrativeIcon, Txt } from '@vst/beam';
import { Alert } from '@vst/beam-icons/illustrative-icons';
import { useTranslation } from 'react-i18next';
import styles from './ConfirmAddressModal.module.scss';

const ErrorModal = (): JSX.Element => {
  const { t } = useTranslation('StreamOnPromo');
  return (
    <div className={styles['errorContainer']}>
      <IllustrativeIcon icon={Alert} />
      <Txt variant="bodyLargeRegular" mt="24px" style={{ textAlign: 'center' }}>
        {t('Shipping.AddressScrub.Error')}
      </Txt>
    </div>
  );
};

export default ErrorModal;
