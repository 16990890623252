import * as React from 'react';
import { useSelector } from 'react-redux';
import { Info } from '@vst/beam-icons/icons';
import { useTranslation } from 'react-i18next';
import { Surface, Txt, Tooltip, Icon } from '@vst/beam';

import { GetPlanOffersPayload } from '@mfe/shared/schema-types';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { formatPriceWithCurrency } from '@mfe/to-be-migrated/redux/changePlan';
import { selectChangePlan } from '@mfe/to-be-migrated/redux/changePlan';

import styles from './OneTimeFees.module.scss';

interface OneTimeFeesProps {
  selectedPlan: GetPlanOffersPayload;
}

export const OneTimeFees = ({ selectedPlan }: OneTimeFeesProps) => {
  const { t } = useTranslation('NewChangePlan', {
    keyPrefix: 'orderReview.cartDetails',
  });

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const {
    cart: {
      prices: {
        oneTime: { feesAndDiscounts, totalWithoutPromos },
      },
    },
  } = useSelector(selectChangePlan);

  const { priceCurrency } = selectedPlan;

  return (
    <div>
      {feesAndDiscounts && feesAndDiscounts?.length > 0 ? (
        <div data-cy="one-time-fees" className={styles['container']}>
          <Surface
            variant="primary"
            radius="8px"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              data-cy="one-time-fees-title"
              className={styles['one-time-fees']}
            >
              <Txt variant="bodySmallBold">{t('oneTimeFees')}</Txt>
              <Tooltip
                data-cy="internet-product-tooltip-text"
                bodyText={t('cartSummary.oneTimeFeesTooltip')}
                beakPosition="center"
              >
                <Icon
                  icon={Info}
                  color="gray_600"
                  size="16px"
                  style={{ marginLeft: '4px' }}
                />
              </Tooltip>
            </div>

            <Txt
              variant="bodySmallRegular"
              data-cy="one-time-fees-pricing"
              mb="16px"
              mt="8px"
            >
              {t('oneTimeFeesInfo')}
            </Txt>

            <div data-cy="one-time-fees-description">
              {feesAndDiscounts?.map((feeAndDiscount, index) => {
                const hasDiscount = (feeAndDiscount.discount ?? 0) < 0;

                const freeLabel = (
                  <Txt component="span" variant="bodySmallBold" color="success">
                    {t('free')}
                  </Txt>
                );

                const formattedTotalPrice =
                  feeAndDiscount.total === 0
                    ? freeLabel
                    : formatPriceWithCurrency(
                        userLocale,
                        feeAndDiscount.total ?? 0,
                        priceCurrency,
                        0
                      );

                return (
                  <div className={styles['info']} key={index}>
                    <Txt variant="bodySmallRegular" color="subtle">
                      {feeAndDiscount.name}
                    </Txt>

                    <Txt
                      variant="paragraphBold"
                      data-cy="one-time-fees-total-amount-discount"
                      style={{ textAlign: 'right' }}
                    >
                      <Txt
                        variant="smallBold"
                        color={hasDiscount ? 'success' : 'info'}
                      >
                        {formattedTotalPrice}
                      </Txt>
                      {hasDiscount && (
                        <div
                          style={{ textAlign: 'right', marginBottom: '8px' }}
                        >
                          <Txt
                            color="subtle"
                            component="span"
                            variant="linkBodySmall"
                            style={{
                              textDecoration: 'line-through',
                              textAlign: 'right',
                            }}
                          >
                            {formatPriceWithCurrency(
                              userLocale,
                              feeAndDiscount.fee ?? 0,
                              priceCurrency,
                              0
                            )}
                          </Txt>
                        </div>
                      )}
                    </Txt>
                  </div>
                );
              })}
            </div>

            <hr />

            <Surface
              data-cy="one-time-fees-total-section"
              className={`${styles['info']} `}
              mt="16px"
            >
              <Txt variant="heading6">{t('oneTimeFeesTotal')}</Txt>
              <Txt
                variant="paragraphBold"
                data-cy="one-time-fees-total-amount-value"
              >
                <Txt color="subtle" component="span" variant="paragraphBold">
                  {formatPriceWithCurrency(
                    userLocale,
                    totalWithoutPromos ?? 0,
                    priceCurrency,
                    0
                  )}
                </Txt>
              </Txt>
            </Surface>
          </Surface>
        </div>
      ) : (
        <hr />
      )}
    </div>
  );
};
