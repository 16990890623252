import React from 'react';
import { PlanLink } from '@mfe/legacy/mv/utils/Navigation';
import { RouteRenderProps, NestedRoutes } from '../utils';
import { WebPlan } from '../../views/WebTabs';
import { ChangePlan } from '@mfe/features/change-plan';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

const noop = () => {
  //noop
};

export const planRoutes: RouteRenderProps[] = [
  {
    path: PlanLink.Plan,
    render: <WebPlan />,
    exact: true,
    fromParent: 0,
  },
  {
    path: PlanLink.ChoosePlanFilter,
    render: (
      <ChangePlan navigateToOverview={noop} navigateToPreviousPage={noop} />
    ),
    exact: true,
    fromParent: 0,
  },
];

const createPlanRoutes = (
  navigateToPreviousPage: () => void,
  navigateToOverview: () => void
) => {
  return [
    ...planRoutes.filter(
      (routeObj) => routeObj.path !== PlanLink.ChoosePlanFilter
    ),
    {
      path: PlanLink.ChoosePlanFilter,
      render: (
        <ChangePlan
          navigateToPreviousPage={navigateToPreviousPage}
          navigateToOverview={navigateToOverview}
        />
      ),
      exact: true,
      fromParent: 0,
    },
  ];
};

export const PlanSwitch = (): JSX.Element => {
  const dispatch = useDispatch();

  const history = useHistory();

  const navigateToPreviousPage = () => {
    history.goBack();
  };

  const navigateToOverview = () => {
    // changedPlan action only set a loading variable to true
    // TODO: figure out what it was used for & what it can be replaced with
    // dispatch(changedPlan());
    history.replace('/');
  };

  const routes = createPlanRoutes(navigateToPreviousPage, navigateToOverview);

  return <NestedRoutes routes={routes} />;
};
