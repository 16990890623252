import React, { useEffect, useRef } from 'react';
import { useTrackEventFunction } from '../../containers';
import ProfileComponent from '@mfe/to-be-migrated/shared-platform-react/pages/Profile';
import { useMessageHandler } from '../useMessageHandler';
import { PlanLink, StackLink } from '@mfe/legacy/mv/utils/Navigation';
import { useNavigate } from '@mfe/legacy/andromeda';

export const Profile = (): JSX.Element => {
  const ref = useRef<any>();
  const trackEvent = useTrackEventFunction();

  const goTo = useNavigate();

  const navigateToChangePlan = React.useCallback(() => {
    goTo(`${PlanLink.Plan}/${PlanLink.ChoosePlanFilter}`);
  }, [goTo]);

  const navigateToScheduleInstallation = React.useCallback(() => {
    goTo(`${StackLink.ScheduleInstallation}`);
    return;
  }, [goTo]);

  useEffect(() => {
    trackEvent('Nav', 'profile_clicked', { label: 'main_nav' });
  }, []);

  useMessageHandler(ref);

  return (
    <ProfileComponent
      navigateToChangePlan={navigateToChangePlan}
      navigateToScheduleInstallation={navigateToScheduleInstallation}
    />
  );
};
