import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllDetails,
  selectStreamOnPromo,
  setCurrentPage,
  Pages as StreamOnPages,
} from '@mfe/to-be-migrated/redux/streamOnPromo';
import { Home } from '../Home';
import { View } from 'react-native';
import { Loading } from '@mfe/legacy/andromeda';

export const Stream = (): JSX.Element => {
  const dispatch = useDispatch();
  const {
    offerDetails: { loading },
  } = useSelector(selectStreamOnPromo);

  React.useEffect(() => {
    dispatch(getAllDetails());
    dispatch(setCurrentPage(StreamOnPages.orderReview));
  }, [dispatch]);

  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Loading size={120} />
      </View>
    );
  }

  return <Home />;
};

export default Stream;
