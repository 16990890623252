import { useTranslation } from 'react-i18next';

import { ScheduleInstallationFailure } from '@mfe/shared/components';

export interface ScheduleFailureProps {
  goToOverview: () => void;
}

export const ScheduleFailure = ({ goToOverview }: ScheduleFailureProps) => {
  const { t } = useTranslation('NewScheduleInstallation', {
    keyPrefix: 'ErrorAppointmentSchedule',
  });

  return (
    <ScheduleInstallationFailure
      handleBackNavigation={goToOverview}
      title={t('title')}
    />
  );
};
