import React from 'react';

const Park = () => (
  <svg
    width="205"
    height="120"
    viewBox="0 -1 205 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2796_35463)">
      <path
        d="M14.9634 112.674H15.4667L15.7958 21.3301H14.9247L14.9634 112.674Z"
        fill="#111920"
      />
      <path
        d="M1.76147 112.674L14.0807 52.0852H16.6165L28.8312 112.674H29.3229L17.5263 51.0908H13.105L1.27366 112.674H1.76147Z"
        fill="#111920"
      />
      <path
        d="M38.4869 27.2536C37.6722 27.2536 37.0118 26.5937 37.0118 25.7795C37.0118 24.9654 37.6722 24.3054 38.4869 24.3054C39.3015 24.3054 39.9619 24.9654 39.9619 25.7795C39.9619 26.5937 39.3015 27.2536 38.4869 27.2536Z"
        fill="#FFDB7D"
      />
      <path
        d="M31.2665 15.72L32.2344 15.0894L34.0618 17.9834L33.1791 18.5599L31.2665 15.72Z"
        fill="#111920"
      />
      <path
        d="M31.6266 29.7144L44.9098 21.0671C44.3431 20.1944 43.6097 19.442 42.7517 18.8529C41.8937 18.2638 40.9278 17.8496 39.9095 17.634C38.8911 17.4183 37.8401 17.4055 36.8168 17.5963C35.7934 17.7871 34.8178 18.1776 33.9456 18.7457C33.0719 19.313 32.3187 20.047 31.7292 20.9056C31.1397 21.7643 30.7253 22.7306 30.5099 23.7494C30.2945 24.7682 30.2823 25.8195 30.4739 26.843C30.6655 27.8666 31.0572 28.8423 31.6266 29.7144Z"
        fill="#111920"
      />
      <path
        d="M35.6492 16.9504L35.3859 18.3781L32.9159 19.9876L31.5918 19.593L35.6492 16.9504Z"
        fill="#111920"
      />
      <path
        d="M15.7959 21.7247L32.3041 15.7238L32.0911 15.1357L15.583 21.1328L15.7959 21.7247Z"
        fill="#202E39"
      />
      <path
        d="M38.0571 25.5627C38.1384 25.8491 38.3165 26.1005 38.7114 25.8413C39.447 25.3616 37.999 25.6285 38.3591 26.1779C38.7191 26.7273 39.234 26.0773 39.1837 26.0038C39.1334 25.9303 38.4404 26.2282 38.8856 26.6693"
        fill="#111920"
      />
      <path
        d="M38.8702 26.6849L38.9012 26.654C38.8296 26.5899 38.7807 26.5044 38.7618 26.4102C38.8522 26.3868 38.937 26.3452 39.0109 26.288C39.0847 26.2308 39.1462 26.1592 39.1915 26.0775C39.2109 26.0388 39.2147 26.0117 39.1915 25.9924C39.1683 25.973 39.1644 25.9692 39.1373 25.9692H39.118C39.0334 25.9863 38.9542 26.0233 38.8869 26.0771C38.8196 26.1309 38.766 26.2 38.7308 26.2787C38.7199 26.3085 38.7134 26.3398 38.7115 26.3715C38.641 26.371 38.5721 26.3512 38.512 26.3144C38.452 26.2776 38.4032 26.2251 38.3708 26.1626C38.3278 26.102 38.3098 26.0272 38.3204 25.9537C38.3881 25.966 38.4577 25.9637 38.5244 25.9471C38.5912 25.9304 38.6536 25.8997 38.7076 25.857C38.8663 25.7525 38.9399 25.6751 38.9283 25.621C38.9251 25.6075 38.9177 25.5955 38.9073 25.5865C38.8969 25.5774 38.8839 25.5719 38.8702 25.5707C38.7646 25.5688 38.66 25.5908 38.5641 25.635C38.4683 25.6792 38.3836 25.7445 38.3166 25.826C38.3019 25.8476 38.2902 25.871 38.2817 25.8957C38.2242 25.8563 38.1751 25.806 38.1372 25.7475C38.0993 25.6891 38.0734 25.6237 38.061 25.5552H38.0223C38.0327 25.6339 38.0603 25.7093 38.1031 25.7762C38.146 25.843 38.203 25.8997 38.2701 25.9421C38.2614 25.9849 38.2617 26.029 38.271 26.0716C38.2804 26.1143 38.2985 26.1545 38.3243 26.1897C38.3589 26.2552 38.4099 26.3107 38.4723 26.3507C38.5347 26.3907 38.6064 26.4139 38.6805 26.418H38.7153C38.7358 26.5217 38.7903 26.6156 38.8702 26.6849ZM39.1489 26.0156C39.0867 26.1583 38.9735 26.2728 38.8315 26.3367C38.8033 26.351 38.7734 26.3614 38.7424 26.3677C38.7478 26.304 38.7721 26.2434 38.8121 26.1936C38.8987 26.0949 39.0186 26.0315 39.1489 26.0156ZM38.8431 25.6287H38.8741H38.9012C38.9012 25.6287 38.9012 25.6867 38.6998 25.8183C38.6506 25.8597 38.5927 25.8896 38.5304 25.9056C38.4681 25.9217 38.4029 25.9236 38.3398 25.9111C38.347 25.8903 38.3575 25.8707 38.3708 25.8531C38.4292 25.7833 38.5011 25.726 38.5823 25.6848C38.6635 25.6436 38.7522 25.6192 38.8431 25.6132V25.6287Z"
        fill="#202E39"
      />
    </g>
    <g clipPath="url(#clip1_2796_35463)">
      <path
        d="M47.6111 55.8301H150.1C152.578 55.8301 154.586 57.9429 154.586 60.5493V74.842C154.586 77.4483 152.578 79.5612 150.1 79.5612H47.6111C45.1334 79.5612 43.1247 77.4483 43.1247 74.842V60.5493C43.1247 57.9429 45.1334 55.8301 47.6111 55.8301Z"
        fill="#677A89"
      />
      <path
        d="M22.6611 99.9761L20.8423 113.286H22.1476L28.9379 99.9761H22.6611Z"
        fill="#9FAFBC"
      />
      <path
        d="M62.9178 99.9761L64.7295 113.286H63.4242L56.6411 99.9761H62.9178Z"
        fill="#9FAFBC"
      />
      <path
        d="M109.558 99.9761L107.746 113.286H109.052L115.835 99.9761H109.558Z"
        fill="#9FAFBC"
      />
      <path
        d="M152.432 99.9761L154.244 113.286H152.939L146.148 99.9761H152.432Z"
        fill="#9FAFBC"
      />
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M27.5186 102.767L28.938 99.9761H22.6613L22.2761 102.767H27.5186Z"
          fill="#677A89"
        />
        <path
          opacity="0.5"
          d="M63.2958 102.767L63.1817 101.934L62.9177 99.9761H56.641L58.0604 102.767H63.2958Z"
          fill="#677A89"
        />
        <path
          opacity="0.5"
          d="M114.415 102.767L115.835 99.9761H109.558L109.18 102.767H114.415Z"
          fill="#677A89"
        />
        <path
          opacity="0.5"
          d="M152.81 102.767L152.432 99.9761H146.149L147.575 102.767H152.81Z"
          fill="#677A89"
        />
      </g>
      <path
        d="M102.404 63.3777L103.124 63.3327L103.117 63.2727L102.404 63.3327V63.3777Z"
        fill="#202E39"
      />
      <path
        d="M103.845 63.2952L104.565 63.2577L104.558 63.1602L103.838 63.2127L103.845 63.2952Z"
        fill="#202E39"
      />
      <path
        d="M105.293 63.2276L106.013 63.1976L106.006 63.0701L105.286 63.1151L105.293 63.2276Z"
        fill="#202E39"
      />
      <path
        d="M106.734 63.1751L107.454 63.1526L107.447 62.9875L106.726 63.0326L106.734 63.1751Z"
        fill="#202E39"
      />
      <path
        d="M108.181 63.1302L108.902 63.1152L108.895 62.9202L108.174 62.9577L108.181 63.1302Z"
        fill="#202E39"
      />
      <path
        d="M109.622 63.1003L110.35 63.0928L110.343 62.8677L109.615 62.8902L109.622 63.1003Z"
        fill="#202E39"
      />
      <path
        d="M111.07 63.0851L111.798 63.0776L111.791 62.8225L111.063 62.845L111.07 63.0851Z"
        fill="#202E39"
      />
      <path
        d="M112.518 63.0701H113.239V62.7925L112.518 62.8075V63.0701Z"
        fill="#202E39"
      />
      <path
        d="M113.966 63.0777H114.686V62.7776L113.959 62.7851L113.966 63.0777Z"
        fill="#202E39"
      />
      <path
        d="M115.407 63.0851L116.134 63.1001V62.77H115.414L115.407 63.0851Z"
        fill="#202E39"
      />
      <path
        d="M116.862 63.1152L117.582 63.1302L117.589 62.7776H116.862V63.1152Z"
        fill="#202E39"
      />
      <path
        d="M118.31 63.1526L119.037 63.1826L119.044 62.8L118.317 62.7925L118.31 63.1526Z"
        fill="#202E39"
      />
      <path
        d="M119.765 63.2051L120.485 63.2427L120.5 62.8375L119.772 62.8225L119.765 63.2051Z"
        fill="#202E39"
      />
      <path
        d="M121.213 63.2803L121.94 63.3253L121.955 62.8901L121.227 62.8601L121.213 63.2803Z"
        fill="#202E39"
      />
      <path
        d="M122.661 63.3703L123.388 63.4229L123.41 62.9577L122.682 62.9202L122.661 63.3703Z"
        fill="#202E39"
      />
      <path
        d="M124.109 63.4753L124.836 63.5278L124.865 63.0476L124.137 62.9951L124.109 63.4753Z"
        fill="#202E39"
      />
      <path
        d="M125.557 63.5878L126.277 63.6479L126.32 63.1677L125.592 63.1077L125.557 63.5878Z"
        fill="#202E39"
      />
      <path
        d="M127.005 63.7153L127.725 63.7903L127.768 63.3101L127.04 63.2351L127.005 63.7153Z"
        fill="#202E39"
      />
      <path
        d="M128.445 63.8729L129.166 63.9405L129.216 63.4828L128.495 63.3853L128.445 63.8729Z"
        fill="#202E39"
      />
      <path
        d="M129.886 64.0231L130.606 64.1056L130.656 63.708L129.936 63.5955L129.886 64.0231Z"
        fill="#202E39"
      />
      <path
        d="M131.327 64.1955L132.047 64.293L132.097 63.9554L131.377 63.8279L131.327 64.1955Z"
        fill="#202E39"
      />
      <path
        d="M132.768 64.3982L133.481 64.5182L133.531 64.2331L132.818 64.0906L132.768 64.3982Z"
        fill="#202E39"
      />
      <path
        d="M134.201 64.6382L134.915 64.7733L134.957 64.5557L134.244 64.3906L134.201 64.6382Z"
        fill="#202E39"
      />
      <path
        d="M135.628 64.9082L136.341 65.0508L136.37 64.9382L135.663 64.7432L135.628 64.9082Z"
        fill="#202E39"
      />
      <path
        d="M137.054 65.2085L137.233 65.2535L137.247 65.201L137.069 65.1484L137.054 65.2085Z"
        fill="#202E39"
      />
      <path
        d="M50.885 62.1699H20.5499C19.8069 62.1435 19.0841 62.428 18.54 62.9609C17.9959 63.4939 17.6748 64.2319 17.6469 65.0134L20.4643 97.8303C20.494 98.6105 20.816 99.3466 21.3599 99.8779C21.9038 100.409 22.6254 100.693 23.3673 100.666H50.885V62.1699Z"
        fill="#9FAFBC"
      />
      <path
        d="M26.2061 100.666H22.9536C22.2385 100.666 21.5527 100.368 21.0471 99.8357C20.5415 99.3039 20.2574 98.5825 20.2574 97.8304L17.6469 65.0435C17.6469 64.2906 17.9307 63.5685 18.4361 63.0354C18.9416 62.5024 19.6273 62.2019 20.343 62.2H23.4457C24.1614 62.2019 24.8472 62.5024 25.3526 63.0354C25.858 63.5685 26.1419 64.2906 26.1419 65.0435V99.3834L26.2061 100.666Z"
        fill="#C3CDD5"
      />
      <path
        d="M109.18 100.659L26.149 100.659V85.6683L109.18 85.6683V100.659Z"
        fill="#C3CDD5"
      />
      <path
        d="M107.832 85.6759H26.142V79.8163C26.142 79.8163 26.6841 77.9856 29.7083 77.9856H107.832V85.6759Z"
        fill="#C3CDD5"
      />
      <path
        d="M26.2133 83.5375C49.1804 83.5375 84.4726 83.7776 107.169 83.5375V83.5C84.5868 83.2899 48.9949 83.4024 26.2133 83.5375Z"
        fill="#677A89"
      />
      <path
        d="M26.142 79.8163C26.142 79.8163 26.6841 77.9856 29.7083 77.9856H107.832L26.142 79.8163Z"
        fill="#C3CDD5"
      />
      <path
        d="M78.9734 51.4034C76.4769 52.6864 72.3043 78.2106 72.3043 78.2106H103.381C103.381 78.2106 112.276 52.2888 109.687 51.4034C98.688 48.5374 78.9734 51.4034 78.9734 51.4034Z"
        fill="#009DE0"
      />
      <path
        d="M107.96 52.6489C107.96 52.6489 100.614 71.8933 101.348 78.0756H138.674C138.674 78.0756 141.291 54.7046 140.642 54.0219C139.993 53.3391 113.845 51.9586 107.96 52.6489Z"
        fill="#00819D"
      />
      <path
        d="M55.0505 52.6489C55.0505 52.6489 43.1461 75.3296 42.925 78.0756H76.2629C76.2629 78.0756 83.1317 60.2042 82.4755 55.3949C82.4755 55.3949 66.1417 52.6489 55.0505 52.6489Z"
        fill="#BED733"
      />
      <g opacity="0.5">
        <path
          d="M47.9892 66.9116L48.7025 66.8666V66.7991L47.9892 66.8666V66.9116Z"
          fill="#465967"
        />
        <path
          d="M49.3444 66.8216L50.0577 66.7841C50.069 66.7841 50.0799 66.7794 50.0879 66.7709C50.096 66.7625 50.1005 66.751 50.1005 66.7391C50.1005 66.7391 50.1005 66.6866 50.0577 66.6941L49.3444 66.7466V66.8216Z"
          fill="#465967"
        />
        <path
          d="M50.721 66.7538H51.4343C51.4424 66.7538 51.4505 66.7521 51.4579 66.7486C51.4654 66.7451 51.472 66.74 51.4774 66.7336C51.4828 66.7272 51.4869 66.7197 51.4893 66.7115C51.4917 66.7033 51.4924 66.6947 51.4914 66.6862C51.4914 66.6703 51.4854 66.6551 51.4747 66.6438C51.464 66.6325 51.4494 66.6262 51.4343 66.6262L50.721 66.6712C50.7072 66.6731 50.6944 66.6802 50.6852 66.6913C50.676 66.7023 50.671 66.7166 50.6711 66.7313C50.677 66.7391 50.6847 66.7452 50.6934 66.7492C50.7021 66.7531 50.7116 66.7547 50.721 66.7538Z"
          fill="#465967"
        />
        <path
          d="M52.0904 66.6942H52.8037C52.8137 66.6942 52.8236 66.6921 52.8327 66.6878C52.8419 66.6836 52.8501 66.6774 52.8568 66.6696C52.8635 66.6618 52.8686 66.6527 52.8718 66.6427C52.8749 66.6327 52.876 66.6221 52.875 66.6116C52.8732 66.5911 52.8642 66.572 52.8496 66.5581C52.8351 66.5442 52.8162 66.5365 52.7965 66.5366H52.0833C52.0662 66.5399 52.0506 66.549 52.039 66.5626C52.0274 66.5762 52.0204 66.5934 52.0191 66.6116C52.0181 66.6221 52.0192 66.6327 52.0223 66.6427C52.0255 66.6527 52.0306 66.6618 52.0373 66.6696C52.044 66.6774 52.0522 66.6836 52.0614 66.6878C52.0705 66.6921 52.0804 66.6942 52.0904 66.6942Z"
          fill="#465967"
        />
        <path
          d="M53.46 66.6564H54.1733C54.1851 66.6564 54.1969 66.6539 54.2078 66.6489C54.2186 66.6439 54.2284 66.6366 54.2365 66.6274C54.2445 66.6183 54.2507 66.6074 54.2545 66.5956C54.2584 66.5838 54.2598 66.5713 54.2589 66.5589C54.2589 66.5464 54.2565 66.534 54.2517 66.5226C54.247 66.5111 54.24 66.5009 54.2313 66.4924C54.2226 66.4839 54.2123 66.4775 54.2011 66.4734C54.1899 66.4693 54.178 66.4678 54.1661 66.4688H53.4529C53.432 66.4722 53.4128 66.483 53.3986 66.4993C53.3843 66.5157 53.3758 66.5367 53.3744 66.5589C53.3734 66.5713 53.3749 66.5838 53.3788 66.5956C53.3826 66.6074 53.3888 66.6183 53.3968 66.6274C53.4049 66.6366 53.4146 66.6439 53.4255 66.6489C53.4364 66.6539 53.4481 66.6564 53.46 66.6564Z"
          fill="#465967"
        />
        <path
          d="M54.8293 66.6264H55.5426C55.5585 66.6308 55.575 66.6313 55.5911 66.6279C55.6072 66.6245 55.6223 66.6173 55.6353 66.6068C55.6482 66.5962 55.6588 66.5827 55.666 66.5673C55.6732 66.5518 55.677 66.5348 55.677 66.5176C55.677 66.5003 55.6732 66.4833 55.666 66.4679C55.6588 66.4524 55.6482 66.4389 55.6353 66.4284C55.6223 66.4179 55.6072 66.4106 55.5911 66.4072C55.575 66.4038 55.5585 66.4044 55.5426 66.4088H54.8293C54.8165 66.4087 54.8039 66.4115 54.7921 66.4169C54.7804 66.4222 54.7698 66.4301 54.7611 66.4399C54.7524 66.4498 54.7457 66.4615 54.7415 66.4742C54.7373 66.4869 54.7356 66.5004 54.7366 66.5138C54.7356 66.5276 54.7373 66.5415 54.7414 66.5546C54.7456 66.5678 54.7521 66.5799 54.7607 66.5904C54.7694 66.6008 54.7799 66.6094 54.7916 66.6156C54.8034 66.6217 54.8162 66.6254 54.8293 66.6264Z"
          fill="#465967"
        />
        <path
          d="M56.1988 66.6041H56.9121C56.9442 66.6041 56.9751 66.5906 56.9978 66.5667C57.0206 66.5428 57.0333 66.5103 57.0333 66.4765C57.0333 66.4427 57.0206 66.4102 56.9978 66.3863C56.9751 66.3624 56.9442 66.349 56.9121 66.349H56.1988C56.182 66.3444 56.1643 66.344 56.1473 66.3477C56.1303 66.3514 56.1143 66.3592 56.1005 66.3704C56.0868 66.3817 56.0757 66.396 56.068 66.4125C56.0604 66.4289 56.0564 66.447 56.0564 66.4653C56.0564 66.4836 56.0604 66.5016 56.068 66.518C56.0757 66.5345 56.0868 66.5489 56.1005 66.5601C56.1143 66.5713 56.1303 66.5791 56.1473 66.5828C56.1643 66.5865 56.182 66.5861 56.1988 66.5815V66.6041Z"
          fill="#465967"
        />
        <path
          d="M57.5683 66.5963H58.2815C58.3156 66.5963 58.3482 66.582 58.3723 66.5567C58.3964 66.5314 58.4099 66.497 58.4099 66.4612C58.4099 66.4254 58.3964 66.3911 58.3723 66.3657C58.3482 66.3404 58.3156 66.3262 58.2815 66.3262H57.5683C57.5517 66.3261 57.5354 66.3297 57.5202 66.3365C57.505 66.3434 57.4913 66.3535 57.4799 66.3661C57.4685 66.3788 57.4598 66.3937 57.4541 66.4101C57.4485 66.4265 57.446 66.4438 57.447 66.4612C57.446 66.4786 57.4485 66.496 57.4541 66.5123C57.4598 66.5287 57.4685 66.5436 57.4799 66.5563C57.4913 66.5689 57.505 66.579 57.5202 66.5859C57.5354 66.5928 57.5517 66.5963 57.5683 66.5963Z"
          fill="#465967"
        />
        <path
          d="M58.9378 66.6041H59.651C59.6877 66.6041 59.7229 66.5893 59.7495 66.5628C59.776 66.5363 59.7918 66.5 59.7937 66.4615C59.7957 66.4416 59.7937 66.4214 59.7878 66.4024C59.7819 66.3833 59.7722 66.3657 59.7594 66.3509C59.7466 66.336 59.731 66.3241 59.7135 66.3161C59.6961 66.308 59.6772 66.3038 59.6582 66.304H58.9449C58.909 66.304 58.8745 66.319 58.8491 66.3457C58.8237 66.3725 58.8094 66.4087 58.8094 66.4465C58.8074 66.4851 58.8196 66.523 58.8435 66.5524C58.8674 66.5817 58.9012 66.6003 58.9378 66.6041Z"
          fill="#465967"
        />
        <path
          d="M60.3143 66.6266H61.0276C61.0626 66.6186 61.094 66.5982 61.1165 66.5689C61.139 66.5395 61.1512 66.503 61.1512 66.4653C61.1512 66.4276 61.139 66.391 61.1165 66.3617C61.094 66.3323 61.0626 66.3119 61.0276 66.304H60.3143C60.2947 66.304 60.2752 66.308 60.257 66.316C60.2388 66.3239 60.2223 66.3355 60.2084 66.3501C60.1945 66.3647 60.1835 66.3821 60.1759 66.4012C60.1684 66.4203 60.1645 66.4408 60.1645 66.4615C60.1645 66.504 60.18 66.5449 60.2079 66.5757C60.2358 66.6064 60.274 66.6246 60.3143 66.6266Z"
          fill="#465967"
        />
        <path
          d="M61.6838 66.6566H62.3971C62.4186 66.6576 62.4401 66.6541 62.4604 66.6464C62.4807 66.6386 62.4993 66.6267 62.5152 66.6114C62.5311 66.5961 62.544 66.5776 62.5531 66.557C62.5622 66.5364 62.5673 66.5142 62.5682 66.4915C62.5684 66.4463 62.5523 66.4026 62.5232 66.3692C62.4942 66.3359 62.4542 66.3152 62.4113 66.3115H61.6981C61.6772 66.3104 61.6563 66.3139 61.6368 66.3218C61.6173 66.3298 61.5996 66.3419 61.5849 66.3574C61.5701 66.373 61.5586 66.3916 61.551 66.4121C61.5435 66.4326 61.5401 66.4546 61.5412 66.4765C61.5381 66.4984 61.5395 66.5207 61.5452 66.542C61.551 66.5632 61.5609 66.583 61.5743 66.6C61.5878 66.617 61.6045 66.6308 61.6233 66.6406C61.6422 66.6503 61.6628 66.6558 61.6838 66.6566Z"
          fill="#465967"
        />
        <path
          d="M63.0604 66.7089H63.7736C63.7971 66.7099 63.8205 66.706 63.8425 66.6975C63.8645 66.689 63.8846 66.676 63.9019 66.6593C63.9191 66.6426 63.933 66.6224 63.9428 66.6C63.9527 66.5776 63.9582 66.5534 63.9591 66.5288C63.9591 66.4803 63.9413 66.4337 63.9094 66.3988C63.8775 66.3638 63.834 66.3432 63.7879 66.3412H63.0746C63.0525 66.3402 63.0304 66.3439 63.0097 66.3521C62.9889 66.3604 62.97 66.3729 62.954 66.389C62.938 66.4052 62.9253 66.4245 62.9166 66.446C62.9079 66.4674 62.9034 66.4905 62.9034 66.5138C62.9013 66.5612 62.9163 66.6076 62.9454 66.6438C62.9746 66.68 63.0156 66.7032 63.0604 66.7089Z"
          fill="#465967"
        />
        <path
          d="M64.4371 66.7766L65.1503 66.8141C65.1756 66.8162 65.2011 66.813 65.2252 66.8046C65.2493 66.7963 65.2716 66.783 65.2908 66.7656C65.31 66.7482 65.3258 66.7269 65.3372 66.7031C65.3486 66.6792 65.3553 66.6532 65.3571 66.6266C65.3591 66.5741 65.3416 66.5228 65.3084 66.4836C65.2751 66.4444 65.2287 66.4204 65.1788 66.4165H64.4656C64.4164 66.4165 64.3692 66.4371 64.3345 66.4736C64.2997 66.5102 64.2801 66.5598 64.2801 66.6116C64.2801 66.6633 64.2997 66.7129 64.3345 66.7495C64.3692 66.7861 64.4164 66.8066 64.4656 66.8066L64.4371 66.7766Z"
          fill="#465967"
        />
        <path
          d="M65.8136 66.8591L66.5269 66.9116C66.5537 66.9147 66.5808 66.9121 66.6066 66.9038C66.6325 66.8956 66.6564 66.882 66.6771 66.8638C66.6977 66.8455 66.7147 66.8231 66.7269 66.7978C66.7391 66.7725 66.7462 66.7448 66.748 66.7165C66.7499 66.688 66.7465 66.6592 66.7379 66.6321C66.7292 66.6049 66.7156 66.5798 66.6977 66.5582C66.6798 66.5365 66.658 66.5189 66.6336 66.5061C66.6092 66.4934 66.5826 66.4859 66.5554 66.484H65.8422C65.8163 66.4819 65.7903 66.4854 65.7657 66.4942C65.7412 66.503 65.7185 66.5169 65.6992 66.5351C65.6799 66.5534 65.6643 66.5755 65.6533 66.6002C65.6423 66.6249 65.6362 66.6518 65.6353 66.679C65.6415 66.7257 65.6625 66.7689 65.6948 66.8016C65.7272 66.8342 65.769 66.8545 65.8136 66.8591Z"
          fill="#465967"
        />
        <path
          d="M67.1759 66.9717L67.8892 67.0242C67.9179 67.0273 67.9468 67.0243 67.9744 67.0153C68.0019 67.0063 68.0274 66.9916 68.0494 66.972C68.0714 66.9523 68.0894 66.9283 68.1023 66.9011C68.1152 66.874 68.1228 66.8444 68.1246 66.8142C68.1335 66.7789 68.1338 66.7418 68.1255 66.7064C68.1172 66.671 68.1005 66.6383 68.0771 66.6114C68.0537 66.5845 68.0242 66.5643 67.9913 66.5525C67.9585 66.5407 67.9234 66.5378 67.8892 66.5441L67.1759 66.4991C67.1478 66.497 67.1196 66.5009 67.0929 66.5103C67.0662 66.5198 67.0415 66.5347 67.0203 66.5542C66.9991 66.5737 66.9817 66.5975 66.9693 66.6241C66.9568 66.6506 66.9495 66.6796 66.9477 66.7091C66.9447 66.7425 66.9484 66.7762 66.9585 66.8079C66.9686 66.8397 66.9849 66.8689 67.0064 66.8936C67.0279 66.9184 67.0541 66.9381 67.0833 66.9516C67.1125 66.965 67.144 66.9719 67.1759 66.9717Z"
          fill="#465967"
        />
        <path
          d="M68.5526 67.0841L69.2659 67.1516C69.3242 67.1559 69.3819 67.1365 69.427 67.0974C69.4721 67.0583 69.5012 67.0025 69.5084 66.9415C69.5113 66.9113 69.5085 66.8809 69.4999 66.8519C69.4914 66.8229 69.4774 66.7961 69.4587 66.773C69.4401 66.7498 69.4171 66.7309 69.3914 66.7173C69.3656 66.7038 69.3375 66.6958 69.3087 66.6939L68.5954 66.6264C68.5383 66.6241 68.4824 66.6443 68.4389 66.6832C68.3953 66.7221 68.3672 66.7768 68.3601 66.8365C68.3571 66.8661 68.3597 66.896 68.3679 66.9245C68.376 66.953 68.3893 66.9795 68.4072 67.0025C68.4252 67.0255 68.4472 67.0446 68.4722 67.0586C68.4971 67.0726 68.5245 67.0813 68.5526 67.0841Z"
          fill="#465967"
        />
        <path
          d="M69.9221 67.2191L70.6353 67.2941C70.6659 67.3024 70.6979 67.3038 70.7291 67.298C70.7602 67.2923 70.7898 67.2795 70.8159 67.2607C70.842 67.2419 70.8639 67.2174 70.8802 67.1889C70.8965 67.1604 70.9068 67.1285 70.9104 67.0955C70.914 67.0624 70.9109 67.0289 70.9011 66.9973C70.8914 66.9656 70.8753 66.9366 70.8539 66.912C70.8326 66.8874 70.8065 66.868 70.7774 66.855C70.7483 66.8419 70.7169 66.8356 70.6852 66.8364L69.972 66.7614C69.9437 66.7572 69.9149 66.7592 69.8874 66.7673C69.8599 66.7754 69.8342 66.7894 69.8121 66.8085C69.79 66.8275 69.7719 66.8511 69.7589 66.8779C69.7459 66.9047 69.7383 66.934 69.7366 66.964C69.7326 66.9936 69.7343 67.0237 69.7414 67.0526C69.7486 67.0816 69.7612 67.1087 69.7785 67.1324C69.7958 67.1562 69.8173 67.1761 69.842 67.191C69.8666 67.2058 69.8939 67.2154 69.9221 67.2191Z"
          fill="#465967"
        />
        <path
          d="M71.2915 67.3766L71.9691 67.4516C72.0141 67.4518 72.058 67.4365 72.0941 67.4081C72.1301 67.3796 72.1564 67.3396 72.1688 67.294C72.175 67.2366 72.1599 67.1788 72.1267 67.1327C72.0935 67.0866 72.0448 67.0557 71.9905 67.0464L71.2772 66.9489C71.2469 66.9406 71.2152 66.9392 71.1843 66.9447C71.1534 66.9503 71.124 66.9627 71.098 66.9811C71.072 66.9995 71.05 67.0236 71.0335 67.0516C71.017 67.0796 71.0064 67.1111 71.0023 67.1438C70.9982 67.1764 71.0008 67.2097 71.0099 67.2412C71.0189 67.2728 71.0343 67.302 71.0549 67.3268C71.0755 67.3517 71.1009 67.3717 71.1294 67.3854C71.1579 67.3992 71.1888 67.4064 71.2202 67.4066L71.2915 67.3766Z"
          fill="#465967"
        />
        <path
          d="M72.6539 67.5343L73.3671 67.6243C73.4153 67.6307 73.4639 67.6171 73.5026 67.5862C73.5413 67.5553 73.5669 67.5097 73.574 67.4592C73.5781 67.4098 73.5642 67.3605 73.5351 67.3215C73.5059 67.2825 73.4637 67.2566 73.4171 67.2492L72.7038 67.1291C72.6521 67.1223 72.5999 67.1363 72.5576 67.1683C72.5153 67.2003 72.486 67.2479 72.4756 67.3017C72.4716 67.3283 72.4726 67.3555 72.4787 67.3817C72.4848 67.4079 72.4958 67.4326 72.5111 67.4543C72.5264 67.4759 72.5456 67.4942 72.5677 67.5079C72.5898 67.5216 72.6142 67.5306 72.6396 67.5343H72.6539Z"
          fill="#465967"
        />
        <path
          d="M74.0162 67.7221L74.7294 67.8271C74.77 67.8336 74.8114 67.8231 74.8448 67.7979C74.8781 67.7726 74.9007 67.7346 74.9077 67.6921C74.9103 67.6509 74.8988 67.61 74.8753 67.577C74.8517 67.5439 74.8177 67.5209 74.7793 67.512L74.0661 67.3769C74.0227 67.3679 73.9776 67.377 73.9404 67.4021C73.9031 67.4273 73.8766 67.4667 73.8664 67.512C73.8598 67.5585 73.8701 67.6059 73.8952 67.6448C73.9203 67.6837 73.9584 67.7113 74.0019 67.7221H74.0162Z"
          fill="#465967"
        />
        <path
          d="M75.3785 67.9395L76.0561 68.067C76.0891 68.0737 76.1234 68.0664 76.1514 68.0467C76.1794 68.0271 76.199 67.9967 76.2059 67.962C76.2104 67.9274 76.2028 67.8923 76.1844 67.8632C76.166 67.8342 76.1381 67.8133 76.106 67.8044L75.3927 67.6544C75.3749 67.65 75.3564 67.6495 75.3384 67.6528C75.3204 67.6561 75.3032 67.6633 75.2878 67.6738C75.2725 67.6843 75.2594 67.698 75.2492 67.714C75.239 67.73 75.232 67.748 75.2287 67.7669C75.2245 67.7857 75.224 67.8051 75.2272 67.8241C75.2303 67.843 75.2371 67.8611 75.2471 67.8772C75.2571 67.8934 75.2701 67.9072 75.2853 67.9179C75.3006 67.9286 75.3177 67.9359 75.3357 67.9395H75.3785Z"
          fill="#465967"
        />
        <path
          d="M76.7336 68.2019L77.4041 68.3444C77.4295 68.3488 77.4557 68.3427 77.477 68.3273C77.4982 68.3119 77.513 68.2885 77.5182 68.2619C77.5213 68.2495 77.522 68.2366 77.52 68.2239C77.518 68.2113 77.5135 68.1993 77.5067 68.1886C77.5 68.178 77.4912 68.169 77.4808 68.1622C77.4705 68.1554 77.4589 68.151 77.4469 68.1493L76.7764 67.9693C76.7481 67.9622 76.7183 67.9669 76.6931 67.9822C76.6679 67.9976 76.6492 68.0224 76.6409 68.0518C76.6343 68.0836 76.6395 68.1168 76.6554 68.1447C76.6713 68.1726 76.6967 68.193 76.7265 68.2019H76.7336Z"
          fill="#465967"
        />
        <path
          d="M78.0818 68.4873L78.7594 68.6449C78.7667 68.6503 78.7754 68.6533 78.7844 68.6533C78.7933 68.6533 78.8021 68.6503 78.8093 68.6449C78.8137 68.6369 78.816 68.6278 78.816 68.6186C78.816 68.6094 78.8137 68.6003 78.8093 68.5923L78.146 68.3748C78.1032 68.3748 78.0676 68.3747 78.0604 68.4273C78.0571 68.4361 78.0556 68.4456 78.056 68.4551C78.0563 68.4646 78.0586 68.4739 78.0627 68.4824C78.0667 68.4909 78.0724 68.4984 78.0794 68.5044C78.0864 68.5104 78.0945 68.5148 78.1032 68.5173L78.0818 68.4873Z"
          fill="#465967"
        />
        <path
          d="M45.9278 70.5729C56.6268 69.9352 67.5539 70.1378 77.9747 72.4036C67.6681 69.1924 56.4484 69.7326 45.9278 70.5729Z"
          fill="#465967"
        />
        <path
          d="M49.6083 63.4004C59.9435 62.7402 70.5497 63.0178 80.6353 65.2311C70.6496 62.0724 59.7865 62.5301 49.6083 63.4004Z"
          fill="#465967"
        />
        <path
          d="M105.086 62.6348L105.764 62.5973L105.756 62.5298L105.086 62.5898V62.6348Z"
          fill="#465967"
        />
        <path
          d="M106.441 62.5599L107.119 62.5224L107.112 62.4324L106.434 62.4774L106.441 62.5599Z"
          fill="#465967"
        />
        <path
          d="M107.796 62.4926L108.474 62.4701L108.467 62.3425L107.789 62.3875L107.796 62.4926Z"
          fill="#465967"
        />
        <path
          d="M109.159 62.4399L109.836 62.4174L109.829 62.2673L109.152 62.3048L109.159 62.4399Z"
          fill="#465967"
        />
        <path
          d="M110.514 62.4023L111.199 62.3873L111.191 62.1997L110.507 62.2297L110.514 62.4023Z"
          fill="#465967"
        />
        <path
          d="M111.876 62.3725L112.554 62.3575L112.547 62.1399L111.869 62.1699L111.876 62.3725Z"
          fill="#465967"
        />
        <path
          d="M113.231 62.3501L113.916 62.3426L113.909 62.095L113.231 62.1175V62.3501Z"
          fill="#465967"
        />
        <path
          d="M114.601 62.3348H115.278V62.0647L114.594 62.0797L114.601 62.3348Z"
          fill="#465967"
        />
        <path
          d="M115.956 62.3351H116.641L116.634 62.0425L115.956 62.05V62.3351Z"
          fill="#465967"
        />
        <path
          d="M117.318 62.3348L117.996 62.3423L118.003 62.0347H117.318V62.3348Z"
          fill="#465967"
        />
        <path
          d="M118.681 62.3575L119.365 62.3725V62.0348L118.681 62.0273V62.3575Z"
          fill="#465967"
        />
        <path
          d="M120.05 62.3873L120.728 62.4023L120.735 62.0422L120.05 62.0347V62.3873Z"
          fill="#465967"
        />
        <path
          d="M121.405 62.425L122.09 62.455L122.104 62.0724L121.42 62.0574L121.405 62.425Z"
          fill="#465967"
        />
        <path
          d="M122.782 62.485L123.46 62.5151L123.481 62.1099L122.796 62.0874L122.782 62.485Z"
          fill="#465967"
        />
        <path
          d="M124.144 62.5522L124.829 62.5972L124.85 62.1546L124.166 62.1321L124.144 62.5522Z"
          fill="#465967"
        />
        <path
          d="M125.507 62.6425L126.191 62.6875L126.22 62.2223L125.535 62.1848L125.507 62.6425Z"
          fill="#465967"
        />
        <path
          d="M126.869 62.7402L127.554 62.7927L127.582 62.305L126.905 62.26L126.869 62.7402Z"
          fill="#465967"
        />
        <path
          d="M128.231 62.8449L128.916 62.9049L128.952 62.4173L128.267 62.3647L128.231 62.8449Z"
          fill="#465967"
        />
        <path
          d="M129.594 62.965L130.271 63.0326L130.321 62.5524L129.637 62.4849L129.594 62.965Z"
          fill="#465967"
        />
        <path
          d="M130.949 63.1079L131.634 63.1829L131.684 62.7102L130.999 62.6277L130.949 63.1079Z"
          fill="#465967"
        />
        <path
          d="M132.311 63.2505L132.989 63.3255L133.039 62.9129L132.361 62.8079L132.311 63.2505Z"
          fill="#465967"
        />
        <path
          d="M133.666 63.408L134.344 63.4981L134.394 63.1454L133.716 63.0254L133.666 63.408Z"
          fill="#465967"
        />
        <path
          d="M135.022 63.5953L135.699 63.7003L135.742 63.4002L135.071 63.2651L135.022 63.5953Z"
          fill="#465967"
        />
        <path
          d="M136.37 63.8055L137.047 63.933L137.09 63.6855L136.419 63.5354L136.37 63.8055Z"
          fill="#465967"
        />
        <path
          d="M137.718 64.0604L138.388 64.1804L138.424 64.0228L137.76 63.8428L137.718 64.0604Z"
          fill="#465967"
        />
        <path
          d="M139.066 64.3157L139.736 64.4658L139.75 64.4133L139.094 64.2107L139.066 64.3157Z"
          fill="#465967"
        />
        <path
          d="M103.609 66.1461C115.236 65.5309 127.026 65.7184 138.467 67.9992C127.261 64.7356 114.979 65.2983 103.609 66.1461Z"
          fill="#465967"
        />
        <path
          d="M89.0803 76.2977V75.5474C89.0788 75.534 89.0731 75.5216 89.064 75.512C89.0549 75.5025 89.0431 75.4965 89.0304 75.4949C89.0171 75.4949 89.0044 75.5004 88.9951 75.5103C88.9857 75.5201 88.9805 75.5335 88.9805 75.5474V76.2977C88.9822 76.3083 88.9874 76.3179 88.9953 76.3248C89.0031 76.3316 89.013 76.3353 89.0233 76.3352C89.0293 76.3375 89.0359 76.3383 89.0423 76.3376C89.0488 76.3369 89.055 76.3346 89.0605 76.331C89.0659 76.3274 89.0706 76.3225 89.074 76.3167C89.0775 76.311 89.0796 76.3044 89.0803 76.2977Z"
          fill="#465967"
        />
        <path
          d="M89.0946 74.857V74.1068C89.0956 74.0973 89.0947 74.0877 89.0919 74.0786C89.0891 74.0695 89.0846 74.0612 89.0785 74.0541C89.0724 74.047 89.065 74.0414 89.0567 74.0375C89.0484 74.0336 89.0394 74.0317 89.0304 74.0317C89.0131 74.0331 88.9967 74.0405 88.9838 74.0527C88.9709 74.0649 88.9622 74.0813 88.9591 74.0993V74.8495C88.959 74.8662 88.9647 74.8824 88.9753 74.8948C88.9858 74.9073 89.0004 74.9152 89.0161 74.9171C89.034 74.9193 89.052 74.9144 89.0665 74.9032C89.0811 74.8921 89.0911 74.8756 89.0946 74.857Z"
          fill="#465967"
        />
        <path
          d="M89.1587 73.4163L89.2087 72.7036C89.2108 72.6916 89.2103 72.6792 89.2073 72.6674C89.2043 72.6556 89.1989 72.6446 89.1914 72.6353C89.1839 72.6259 89.1745 72.6185 89.1639 72.6134C89.1533 72.6084 89.1418 72.6058 89.1302 72.6061C89.119 72.605 89.1076 72.6063 89.0969 72.6099C89.0861 72.6135 89.0761 72.6193 89.0675 72.627C89.0589 72.6347 89.0518 72.6441 89.0467 72.6546C89.0415 72.6652 89.0384 72.6767 89.0375 72.6886L89.0018 73.4389C89.0018 73.4497 89.0039 73.4604 89.0078 73.4704C89.0117 73.4805 89.0175 73.4895 89.0248 73.4972C89.0321 73.5049 89.0407 73.511 89.0503 73.5151C89.0598 73.5192 89.07 73.5214 89.0803 73.5214C89.1159 73.4989 89.1516 73.4614 89.1587 73.4163Z"
          fill="#465967"
        />
        <path
          d="M89.2871 71.9907L89.3656 71.2405C89.3698 71.2238 89.3703 71.2064 89.367 71.1895C89.3638 71.1726 89.3569 71.1567 89.3469 71.143C89.3369 71.1294 89.3241 71.1183 89.3094 71.1107C89.2947 71.1031 89.2785 71.0991 89.2622 71.0991C89.2458 71.0991 89.2296 71.1031 89.2149 71.1107C89.2002 71.1183 89.1874 71.1294 89.1774 71.143C89.1674 71.1567 89.1605 71.1726 89.1573 71.1895C89.154 71.2064 89.1545 71.2238 89.1587 71.2405L89.1017 71.9907C89.0997 72.0041 89.1003 72.0178 89.1036 72.031C89.1069 72.0441 89.1128 72.0563 89.1209 72.0669C89.1289 72.0775 89.139 72.0862 89.1504 72.0925C89.1618 72.0987 89.1744 72.1024 89.1873 72.1033C89.201 72.1033 89.2146 72.1004 89.2272 72.0946C89.2398 72.0889 89.2511 72.0805 89.2605 72.0699C89.2699 72.0594 89.2771 72.0469 89.2817 72.0333C89.2862 72.0196 89.2881 72.0052 89.2871 71.9907Z"
          fill="#465967"
        />
        <path
          d="M89.4369 70.5655L89.5368 69.8152C89.5409 69.7825 89.5328 69.7493 89.5142 69.7227C89.4956 69.6962 89.4679 69.6782 89.4369 69.6727C89.4214 69.6695 89.4054 69.6697 89.3899 69.6733C89.3745 69.677 89.3599 69.6839 89.3472 69.6938C89.3344 69.7037 89.3238 69.7163 89.3159 69.7307C89.308 69.7451 89.3031 69.7611 89.3014 69.7777L89.2158 70.528C89.2221 70.5538 89.2364 70.5767 89.2564 70.593C89.2764 70.6094 89.301 70.6182 89.3264 70.6182C89.3517 70.6182 89.3763 70.6094 89.3963 70.593C89.4164 70.5767 89.4306 70.5538 89.4369 70.528V70.5655Z"
          fill="#465967"
        />
        <path
          d="M89.6438 69.1397L89.7651 68.4344C89.7668 68.4 89.7566 68.3661 89.7363 68.3389C89.7161 68.3118 89.6871 68.2933 89.6549 68.2869C89.6227 68.2805 89.5894 68.2866 89.5611 68.3041C89.5328 68.3215 89.5115 68.3492 89.5011 68.3819L89.3941 69.0947C89.3911 69.112 89.3915 69.1298 89.3953 69.147C89.3992 69.1641 89.4063 69.1803 89.4164 69.1944C89.4264 69.2085 89.4392 69.2202 89.4537 69.2289C89.4683 69.2376 89.4845 69.243 89.5011 69.2447C89.5331 69.2493 89.5656 69.241 89.592 69.2215C89.6184 69.2021 89.6369 69.1729 89.6438 69.1397Z"
          fill="#465967"
        />
        <path
          d="M89.8934 67.7294L90.036 67.0316C90.0427 66.991 90.0347 66.9492 90.0136 66.9145C89.9925 66.8798 89.9598 66.8547 89.9219 66.844C89.8842 66.8373 89.8455 66.8459 89.8136 66.8683C89.7818 66.8906 89.7593 66.9249 89.7507 66.9641L89.6224 67.6768C89.6191 67.6956 89.6193 67.7148 89.6231 67.7334C89.6269 67.752 89.6341 67.7697 89.6444 67.7853C89.6547 67.801 89.6678 67.8144 89.6829 67.8248C89.6981 67.8351 89.7151 67.8422 89.7329 67.8457C89.7507 67.8491 89.769 67.8488 89.7867 67.8449C89.8044 67.8409 89.8211 67.8333 89.836 67.8225C89.8509 67.8117 89.8637 67.7979 89.8735 67.7819C89.8834 67.7659 89.8901 67.7481 89.8934 67.7294Z"
          fill="#465967"
        />
        <path
          d="M90.1859 66.334L90.3428 65.6362C90.3538 65.5925 90.348 65.5459 90.3266 65.5067C90.3053 65.4674 90.2701 65.4384 90.2287 65.4262C90.2086 65.4206 90.1876 65.4192 90.1669 65.4222C90.1463 65.4252 90.1264 65.4325 90.1085 65.4437C90.0906 65.4549 90.0749 65.4697 90.0625 65.4873C90.0501 65.5049 90.0411 65.5249 90.0361 65.5462L89.8935 66.2515C89.8848 66.2932 89.8919 66.3368 89.9131 66.3732C89.9344 66.4096 89.9683 66.4359 90.0076 66.4465C90.0451 66.4523 90.0834 66.4443 90.1159 66.4238C90.1484 66.4033 90.1731 66.3715 90.1859 66.334Z"
          fill="#465967"
        />
        <path
          d="M90.5139 64.9382L90.6922 64.248C90.7036 64.2003 90.6974 64.1498 90.6749 64.1067C90.6524 64.0635 90.6153 64.0309 90.5709 64.0154C90.5265 64.0037 90.4795 64.0104 90.4397 64.0341C90.3998 64.0579 90.3702 64.0968 90.3569 64.1429L90.1858 64.8407C90.1805 64.8638 90.1796 64.8879 90.1832 64.9114C90.1868 64.9349 90.1948 64.9574 90.2068 64.9777C90.2187 64.9979 90.2344 65.0154 90.2528 65.0293C90.2712 65.0431 90.2921 65.053 90.3141 65.0583C90.3557 65.0679 90.3991 65.061 90.436 65.0388C90.4729 65.0166 90.5007 64.9807 90.5139 64.9382Z"
          fill="#465967"
        />
        <path
          d="M90.8778 63.5431L91.0703 62.8529C91.0862 62.8021 91.0823 62.7469 91.0596 62.6991C91.0369 62.6513 90.9972 62.6149 90.9491 62.5978C90.9247 62.5897 90.8991 62.5868 90.8737 62.5892C90.8482 62.5916 90.8235 62.5994 90.801 62.6119C90.7784 62.6245 90.7584 62.6416 90.7422 62.6624C90.726 62.6832 90.7139 62.7071 90.7066 62.7328L90.5283 63.4306C90.5207 63.4547 90.5182 63.4803 90.5207 63.5055C90.5232 63.5308 90.5308 63.5552 90.5429 63.5772C90.555 63.5991 90.5714 63.6182 90.591 63.633C90.6106 63.6478 90.633 63.6581 90.6567 63.6632C90.7011 63.6774 90.7491 63.6728 90.7904 63.6504C90.8317 63.628 90.8631 63.5895 90.8778 63.5431Z"
          fill="#465967"
        />
        <path
          d="M91.2771 62.1848L91.484 61.5021C91.4989 61.4733 91.5076 61.4414 91.5095 61.4087C91.5114 61.376 91.5065 61.3432 91.4951 61.3127C91.4838 61.2821 91.4662 61.2546 91.4437 61.232C91.4211 61.2093 91.3942 61.1921 91.3646 61.1816C91.3351 61.1711 91.3038 61.1675 91.2728 61.171C91.2418 61.1745 91.212 61.1851 91.1853 61.2021C91.1586 61.219 91.1358 61.2419 91.1184 61.2691C91.101 61.2963 91.0894 61.3272 91.0845 61.3595L90.892 62.0498C90.8832 62.0763 90.8799 62.1045 90.8821 62.1325C90.8843 62.1605 90.892 62.1878 90.9048 62.2125C90.9175 62.2372 90.935 62.2588 90.9561 62.276C90.9773 62.2932 91.0016 62.3056 91.0275 62.3124C91.0767 62.3275 91.1295 62.3227 91.1757 62.2992C91.2218 62.2756 91.258 62.2349 91.2771 62.1848Z"
          fill="#465967"
        />
        <path
          d="M91.6979 60.8195L91.9048 60.1368C91.9148 60.1083 91.9193 60.0779 91.9178 60.0475C91.9164 60.0171 91.9091 59.9873 91.8965 59.96C91.8838 59.9327 91.866 59.9083 91.8441 59.8884C91.8223 59.8685 91.7968 59.8534 91.7692 59.8442C91.7425 59.8348 91.7143 59.831 91.6863 59.8331C91.6582 59.8352 91.6308 59.843 91.6056 59.8563C91.5804 59.8695 91.558 59.8879 91.5395 59.9102C91.5211 59.9326 91.5071 59.9586 91.4982 59.9867L91.2842 60.6695C91.2753 60.6975 91.2717 60.7272 91.2737 60.7567C91.2756 60.7863 91.2831 60.8151 91.2957 60.8416C91.3083 60.8681 91.3257 60.8917 91.347 60.9111C91.3683 60.9305 91.393 60.9453 91.4197 60.9546C91.4738 60.9724 91.5322 60.9684 91.5836 60.9434C91.635 60.9185 91.6757 60.8743 91.6979 60.8195Z"
          fill="#465967"
        />
        <path
          d="M92.1401 59.454L92.3612 58.7712C92.3711 58.7164 92.3619 58.6596 92.3353 58.6112C92.3086 58.5629 92.2663 58.5263 92.2161 58.5081C92.1659 58.4899 92.1111 58.4913 92.0618 58.512C92.0125 58.5328 91.9719 58.5716 91.9476 58.6212L91.7265 59.2964C91.7082 59.3542 91.7121 59.4171 91.7375 59.4718C91.7628 59.5265 91.8075 59.5686 91.862 59.589C91.9159 59.6087 91.975 59.6056 92.0269 59.5804C92.0787 59.5552 92.1193 59.5099 92.1401 59.454Z"
          fill="#465967"
        />
        <path
          d="M92.5754 58.096L92.7965 57.4208C92.813 57.365 92.809 57.3047 92.7852 57.2519C92.7614 57.199 92.7196 57.1576 92.6681 57.1357C92.6423 57.1262 92.615 57.1222 92.5878 57.1239C92.5606 57.1256 92.5339 57.1329 92.5094 57.1455C92.4849 57.158 92.463 57.1755 92.4449 57.197C92.4268 57.2185 92.413 57.2436 92.4042 57.2707L92.1617 57.946C92.1519 57.9741 92.1474 58.0039 92.1485 58.0338C92.1496 58.0637 92.1563 58.0931 92.1683 58.1203C92.1802 58.1474 92.1971 58.1719 92.218 58.1922C92.2389 58.2125 92.2634 58.2282 92.2901 58.2386C92.3168 58.2489 92.3451 58.2537 92.3736 58.2525C92.402 58.2513 92.4299 58.2442 92.4557 58.2317C92.4816 58.2191 92.5048 58.2014 92.5241 58.1794C92.5434 58.1574 92.5584 58.1316 92.5682 58.1035L92.5754 58.096Z"
          fill="#465967"
        />
        <path
          d="M93.0104 56.7378L93.2315 56.0476C93.2404 56.025 93.2447 56.0007 93.2441 55.9763C93.2434 55.9519 93.2379 55.9279 93.2278 55.9059C93.2177 55.884 93.2032 55.8645 93.1854 55.8488C93.1676 55.8331 93.1468 55.8216 93.1245 55.815C93.1034 55.8066 93.081 55.8026 93.0585 55.8033C93.0359 55.804 93.0137 55.8094 92.9932 55.8192C92.9727 55.8289 92.9541 55.8429 92.9387 55.8602C92.9233 55.8775 92.9113 55.8978 92.9034 55.92L92.6466 56.5953C92.6375 56.6204 92.6333 56.6471 92.6341 56.674C92.6349 56.7008 92.6408 56.7273 92.6514 56.7517C92.662 56.7761 92.6772 56.7981 92.6959 56.8163C92.7147 56.8346 92.7367 56.8487 92.7607 56.8579C92.7841 56.8687 92.8092 56.8744 92.8347 56.8746C92.8602 56.8747 92.8854 56.8693 92.9089 56.8588C92.9323 56.8482 92.9534 56.8327 92.9709 56.8132C92.9883 56.7937 93.0018 56.7706 93.0104 56.7453V56.7378Z"
          fill="#465967"
        />
        <path
          d="M93.4597 55.3572L93.6808 54.6745C93.6881 54.6394 93.6831 54.6027 93.6668 54.5711C93.6504 54.5395 93.6239 54.5151 93.5919 54.5024C93.5599 54.4897 93.5247 54.4894 93.4926 54.5017C93.4605 54.5141 93.4336 54.5381 93.4169 54.5694L93.1602 55.2447C93.152 55.2649 93.1478 55.2865 93.1477 55.3085C93.1476 55.3304 93.1516 55.3521 93.1596 55.3724C93.1676 55.3926 93.1793 55.411 93.1941 55.4264C93.2089 55.4419 93.2264 55.4541 93.2458 55.4623C93.2649 55.4708 93.2855 55.4753 93.3064 55.4754C93.3272 55.4755 93.3479 55.4713 93.3671 55.4629C93.3864 55.4545 93.4039 55.4422 93.4185 55.4266C93.4332 55.411 93.4448 55.3926 93.4526 55.3722L93.4597 55.3572Z"
          fill="#465967"
        />
        <path
          d="M93.9091 53.9921L94.1373 53.3093C94.1447 53.2795 94.1415 53.2478 94.1284 53.2202C94.1153 53.1926 94.0931 53.171 94.066 53.1593C94.0395 53.1514 94.0111 53.1539 93.9862 53.1663C93.9613 53.1788 93.9415 53.2002 93.9305 53.2268L93.6737 53.902C93.6669 53.9182 93.6633 53.9357 93.6632 53.9534C93.6631 53.9711 93.6666 53.9886 93.6733 54.0048C93.68 54.0211 93.6898 54.0357 93.7022 54.0477C93.7145 54.0597 93.7291 54.0689 93.745 54.0746C93.7764 54.0852 93.8103 54.0834 93.8405 54.0696C93.8707 54.0558 93.8951 54.0309 93.9091 53.9996V53.9921Z"
          fill="#465967"
        />
        <path
          d="M94.3513 52.6266L94.5581 51.9363C94.5627 51.9321 94.5663 51.9269 94.5688 51.9211C94.5713 51.9153 94.5726 51.9089 94.5726 51.9026C94.5726 51.8962 94.5713 51.8899 94.5688 51.884C94.5663 51.8782 94.5627 51.873 94.5581 51.8688C94.5535 51.8638 94.5479 51.8598 94.5418 51.8571C94.5357 51.8544 94.5291 51.853 94.5225 51.853C94.5158 51.853 94.5093 51.8544 94.5031 51.8571C94.497 51.8598 94.4915 51.8638 94.4868 51.8688L94.2229 52.529C94.2135 52.5509 94.2127 52.5759 94.2207 52.5984C94.2287 52.6209 94.2449 52.6391 94.2657 52.6491C94.2865 52.659 94.3103 52.6599 94.3317 52.6514C94.3531 52.643 94.3704 52.6259 94.3798 52.6041L94.3513 52.6266Z"
          fill="#465967"
        />
        <path
          d="M92.8962 77.8505L92.8392 77.1377H92.775L92.8534 77.858L92.8962 77.8505Z"
          fill="#465967"
        />
        <path
          d="M92.7964 76.4173L92.7821 75.697L92.6823 75.7045L92.7179 76.4248L92.7964 76.4173Z"
          fill="#465967"
        />
        <path
          d="M92.7821 74.9847L92.7964 74.2644H92.6609V74.9847H92.7821Z"
          fill="#465967"
        />
        <path
          d="M92.8319 73.5514L92.8818 72.8312L92.7107 72.8162L92.675 73.5364L92.8319 73.5514Z"
          fill="#465967"
        />
        <path
          d="M92.9461 72.1184L93.0245 71.4131L92.8177 71.3906L92.7606 72.1034L92.9461 72.1184Z"
          fill="#465967"
        />
        <path
          d="M93.1174 70.7078L93.2244 69.995L92.989 69.9575L92.8963 70.6703L93.1174 70.7078Z"
          fill="#465967"
        />
        <path
          d="M93.3384 69.2898L93.4668 68.577L93.21 68.532L93.0887 69.2447L93.3384 69.2898Z"
          fill="#465967"
        />
        <path
          d="M93.6095 67.8717L93.7593 67.174L93.474 67.1064L93.3385 67.8117L93.6095 67.8717Z"
          fill="#465967"
        />
        <path
          d="M93.9162 66.4763L94.0874 65.7786L93.7736 65.696L93.6238 66.4013L93.9162 66.4763Z"
          fill="#465967"
        />
        <path
          d="M94.2657 65.0886L94.444 64.3983L94.1087 64.3008L93.9375 64.9985L94.2657 65.0886Z"
          fill="#465967"
        />
        <path
          d="M94.6366 63.7081L94.8363 63.0178L94.4725 62.9053L94.2871 63.603L94.6366 63.7081Z"
          fill="#465967"
        />
        <path
          d="M95.0432 62.3346L95.2572 61.6519L94.8578 61.5168L94.6652 62.2146L95.0432 62.3346Z"
          fill="#465967"
        />
        <path
          d="M95.4711 60.9693L95.6851 60.2941L95.2785 60.144L95.0645 60.8343L95.4711 60.9693Z"
          fill="#465967"
        />
        <path
          d="M95.9062 59.6116L96.1273 58.9289L95.7208 58.7788L95.4925 59.4616L95.9062 59.6116Z"
          fill="#465967"
        />
        <path
          d="M96.3555 58.2535L96.5767 57.5708L96.1844 57.4282L95.9418 58.1035L96.3555 58.2535Z"
          fill="#465967"
        />
        <path
          d="M96.7906 56.888L97.0117 56.2128L96.6836 56.0852L96.434 56.7529L96.7906 56.888Z"
          fill="#465967"
        />
        <path
          d="M97.2329 55.53L97.454 54.8472L97.1829 54.7422L96.9333 55.4099L97.2329 55.53Z"
          fill="#465967"
        />
        <path
          d="M97.6823 54.1723L97.9034 53.4895L97.6966 53.407L97.4398 54.0747L97.6823 54.1723Z"
          fill="#465967"
        />
        <path
          d="M98.1173 52.8063L98.3313 52.1161L98.2243 52.071L97.9533 52.7388L98.1173 52.8063Z"
          fill="#465967"
        />
        <path
          d="M85.3428 77.6029V76.8901H85.2786L85.3 77.6029H85.3428Z"
          fill="#465967"
        />
        <path
          d="M85.3499 76.1775L85.3784 75.4648L85.2785 75.4573L85.2714 76.1775H85.3499Z"
          fill="#465967"
        />
        <path
          d="M85.4141 74.7444L85.4712 74.0317L85.3356 74.0242L85.3 74.7369L85.4141 74.7444Z"
          fill="#465967"
        />
        <path
          d="M85.5353 73.3188L85.6138 72.606L85.4426 72.5835L85.3784 73.3038L85.5353 73.3188Z"
          fill="#465967"
        />
        <path
          d="M85.6995 71.8932L85.7993 71.1804L85.5925 71.1504L85.5069 71.8707L85.6995 71.8932Z"
          fill="#465967"
        />
        <path
          d="M85.8991 70.4751L86.0204 69.7699L85.7779 69.7249L85.678 70.4376L85.8991 70.4751Z"
          fill="#465967"
        />
        <path
          d="M86.1417 69.0649L86.2701 68.3596L86.0062 68.3071L85.8921 69.0199L86.1417 69.0649Z"
          fill="#465967"
        />
        <path
          d="M86.4127 67.6542L86.5553 66.9489L86.27 66.8889L86.1345 67.5942L86.4127 67.6542Z"
          fill="#465967"
        />
        <path
          d="M86.7122 66.2512L86.8691 65.5535L86.5553 65.4709L86.4055 66.1762L86.7122 66.2512Z"
          fill="#465967"
        />
        <path
          d="M87.0332 64.8559L87.2043 64.1581L86.8691 64.0681L86.7051 64.7734L87.0332 64.8559Z"
          fill="#465967"
        />
        <path
          d="M87.3828 63.4679L87.5682 62.7776L87.1973 62.6726L87.0262 63.3704L87.3828 63.4679Z"
          fill="#465967"
        />
        <path
          d="M87.7535 62.0874L87.9532 61.3971L87.5467 61.2771L87.3684 61.9749L87.7535 62.0874Z"
          fill="#465967"
        />
        <path
          d="M88.1459 60.707L88.3385 60.0167L87.9248 59.8892L87.7322 60.5794L88.1459 60.707Z"
          fill="#465967"
        />
        <path
          d="M88.5381 59.3338L88.745 58.6511L88.3313 58.5085L88.1245 59.1988L88.5381 59.3338Z"
          fill="#465967"
        />
        <path
          d="M88.9519 57.9606L89.1516 57.2779L88.7593 57.1428L88.5382 57.8256L88.9519 57.9606Z"
          fill="#465967"
        />
        <path
          d="M89.3513 56.5877L89.551 55.8975L89.2229 55.7849L88.9875 56.4602L89.3513 56.5877Z"
          fill="#465967"
        />
        <path
          d="M89.7579 55.2151L89.9647 54.5323L89.6937 54.4348L89.4583 55.1101L89.7579 55.2151Z"
          fill="#465967"
        />
        <path
          d="M90.1715 53.8418L90.3855 53.159L90.1858 53.084L89.9361 53.7592L90.1715 53.8418Z"
          fill="#465967"
        />
        <path
          d="M90.5924 52.4765L90.7992 51.7863L90.7065 51.7488L90.4354 52.4165L90.5924 52.4765Z"
          fill="#465967"
        />
      </g>
      <path
        d="M153.574 100.404H110.614C109.899 100.404 109.213 100.105 108.707 99.5731C108.202 99.0412 107.918 98.3199 107.918 97.5677V64.7508C107.918 63.9987 108.202 63.2773 108.707 62.7454C109.213 62.2136 109.899 61.9148 110.614 61.9148H156.191C156.906 61.9148 157.592 62.2136 158.098 62.7454C158.603 63.2773 158.887 63.9987 158.887 64.7508L156.27 97.5677C156.27 98.3199 155.986 99.0412 155.48 99.5731C154.974 100.105 154.289 100.404 153.574 100.404Z"
        fill="#9FAFBC"
      />
      <path
        d="M110.357 100.666H109.865C109.511 100.666 109.16 100.593 108.833 100.45C108.506 100.308 108.209 100.099 107.958 99.8357C107.708 99.5724 107.509 99.2597 107.374 98.9157C107.238 98.5716 107.169 98.2028 107.169 97.8304V65.0435C107.169 64.2906 107.453 63.5685 107.958 63.0355C108.463 62.5024 109.149 62.2019 109.865 62.2H112.968C113.323 62.199 113.675 62.2718 114.004 62.4142C114.333 62.5567 114.632 62.766 114.884 63.0302C115.135 63.2943 115.335 63.6081 115.472 63.9536C115.608 64.2991 115.678 64.6694 115.678 65.0435L113.067 97.8604C113.058 98.6093 112.768 99.3241 112.261 99.8495C111.753 100.375 111.069 100.668 110.357 100.666Z"
        fill="#C3CDD5"
      />
    </g>
    <g clipPath="url(#clip2_2796_35463)">
      <g clipPath="url(#clip3_2796_35463)">
        <path
          d="M109.084 33.0829C111.378 34.9201 115.198 32.8716 115.198 32.8716C115.198 32.8716 113.337 35.6275 115.328 39.1091L115.71 39.7751C115.71 39.7751 115.924 40.1701 116.017 40.3998C116.11 40.6295 116.208 40.8591 116.208 40.8591V40.905C116.208 42.0212 116.347 45.7279 118.017 45.8243C118.701 47.1248 116.561 48.4792 115.365 48.8237C114.17 49.1682 112.499 49.5448 111.034 48.4976C109.452 47.3631 111.499 45.7049 111.499 45.7049C111.499 45.7049 112.862 46.3709 112.234 42.407C111.881 42.491 111.518 42.5341 111.155 42.5356C108.991 42.5356 108.293 38.5626 108.07 35.995C107.665 32.9313 109.084 33.0829 109.084 33.0829Z"
          fill="#C3AB98"
        />
        <path
          d="M117.106 37.6715C117.147 37.8414 117.236 38.0251 117.436 38.0251C117.772 37.9394 118.067 37.7386 118.267 37.4585C118.467 37.1784 118.56 36.8371 118.529 36.4956C118.529 35.7423 118.241 34.9753 117.529 34.9431C117.254 34.9431 117.064 35.306 117.064 35.6551C117.038 36.0972 117.038 36.5404 117.064 36.9825C117.059 37.2129 117.073 37.4433 117.106 37.6715Z"
          fill="#C3AB98"
        />
        <g opacity="0.3">
          <path
            d="M109.792 39.3113C109.326 39.3664 109.559 39.9956 109.559 39.9956C109.681 40.1942 109.851 40.3605 110.053 40.4804C110.254 40.6004 110.482 40.6705 110.717 40.6846C111.471 40.6846 112.397 39.4812 112.337 39.4812C112.276 39.4812 111.006 39.2516 110.773 39.1964C110.541 39.1413 110.252 39.3664 110.252 39.3664C110.111 39.2906 109.947 39.2709 109.792 39.3113Z"
            fill="#8E0D13"
          />
        </g>
        <path
          d="M109.792 39.3113C109.326 39.3664 109.559 39.9956 109.559 39.9956C109.559 39.9956 109.973 39.9956 110.555 39.9405C111.162 39.8425 111.758 39.6887 112.337 39.4812C112.281 39.4812 111.006 39.2516 110.773 39.1964C110.541 39.1413 110.252 39.3664 110.252 39.3664C110.111 39.2906 109.947 39.2709 109.792 39.3113Z"
          fill="#590409"
        />
        <g opacity="0.3">
          <path
            d="M110.303 38.264L109.284 38.0022C109.556 38.1983 109.855 38.3531 110.173 38.4615C110.378 38.4523 110.303 38.264 110.303 38.264Z"
            fill="black"
          />
        </g>
        <path
          d="M108.019 35.9719C108.184 36.0816 108.371 36.1537 108.568 36.1832C108.606 36.1453 108.658 36.1238 108.712 36.1235C108.768 36.1235 108.821 36.1453 108.86 36.184C108.899 36.2228 108.922 36.2754 108.922 36.3302C108.922 36.385 108.899 36.4376 108.86 36.4763C108.821 36.5151 108.768 36.5369 108.712 36.5369C108.657 36.5369 108.603 36.5151 108.564 36.4763C108.525 36.4376 108.503 36.385 108.503 36.3302C108.507 36.3021 108.519 36.2754 108.535 36.2521C108.34 36.2088 108.161 36.1116 108.019 35.9719Z"
          fill="#1C262F"
        />
        <path
          d="M111.28 35.9719C111.445 36.0816 111.633 36.1537 111.829 36.1832C111.868 36.1453 111.919 36.1238 111.974 36.1235C112.029 36.1235 112.082 36.1453 112.122 36.184C112.161 36.2228 112.183 36.2754 112.183 36.3302C112.183 36.385 112.161 36.4376 112.122 36.4763C112.082 36.5151 112.029 36.5369 111.974 36.5369C111.918 36.5369 111.865 36.5151 111.826 36.4763C111.786 36.4376 111.764 36.385 111.764 36.3302C111.769 36.3021 111.78 36.2754 111.797 36.2521C111.601 36.2088 111.422 36.1116 111.28 35.9719Z"
          fill="#1C262F"
        />
        <path
          d="M109.498 35.5081V35.1406C109.498 35.1406 107.637 34.4103 107.135 34.9248C107.95 34.9892 108.749 35.1862 109.498 35.5081Z"
          fill="#194C5E"
        />
        <path
          d="M110.931 35.3747C111.382 35.0768 111.894 34.8811 112.431 34.8016C112.968 34.7222 113.515 34.7611 114.035 34.9154C114.035 34.9154 112.9 33.9462 110.885 34.9154L110.931 35.3747Z"
          fill="#194C5E"
        />
        <path
          d="M117.059 36.9732C117.063 37.1994 117.083 37.4251 117.12 37.6484C117.157 37.8138 117.25 37.9929 117.441 37.9929C117.771 37.911 118.061 37.7151 118.258 37.4405C118.456 37.1658 118.547 36.8307 118.515 36.4956C118.515 35.7561 118.232 35.0028 117.534 34.9706C117.264 34.9936 117.068 35.3289 117.068 35.6734C117.04 36.1061 117.037 36.5401 117.059 36.9732ZM115.175 28.4345C115.188 28.1918 115.246 27.9535 115.347 27.7318C115.933 26.4778 117.794 26.0874 119.502 26.8637C121.209 27.6399 122.126 29.2843 121.544 30.5382C121.312 31.0107 120.909 31.3796 120.414 31.5717C120.479 31.6406 120.539 31.7141 120.595 31.783C123.387 35.2232 118.953 39.0907 117.464 39.6557C117.531 40.1868 117.716 40.6967 118.006 41.1486C118.297 41.6005 118.685 41.9832 119.143 42.2692C118.717 42.0323 118.346 41.7101 118.054 41.3233C117.761 40.9365 117.554 40.4937 117.445 40.0231C117.58 40.9923 117.776 43.8125 115.738 44.9883C115.738 44.9883 117.636 42.9398 115.449 39.3755C113.262 35.8112 115.119 32.945 115.119 32.945C115.119 32.945 111.397 34.9844 109.126 33.1517C109.126 33.1517 107.73 33.0001 108.131 36.0592C108.131 36.0592 105.804 34.153 105.935 32.0172C106.107 29.5093 109.131 26.7397 115.175 28.4345Z"
          fill="#194C5E"
        />
        <g opacity="0.2">
          <path
            d="M116.012 40.4411C115.547 41.1485 114.151 43.0271 112.369 43.4451L112.243 42.4162C113.951 42.035 115.114 40.8499 115.896 40.1426C115.896 40.1564 115.905 40.1656 116.012 40.4411Z"
            fill="black"
          />
        </g>
      </g>
      <path
        d="M56.7582 105.788L52.5684 107.009C52.5684 107.009 49.1589 106.204 48.7854 106.906L58.8342 111.754C59.1888 111.024 59.0139 108.216 59.0139 108.216L57.6236 107.543L56.7582 105.788Z"
        fill="#677A89"
      />
      <path
        d="M73.9238 109.179L70.6751 112.1C70.6751 112.1 67.2609 112.858 67.2372 113.649H78.3926C78.3926 112.839 77.0165 110.373 77.0165 110.373H75.4702L73.9238 109.179Z"
        fill="#677A89"
      />
      <path
        d="M58.3563 112.672L48.317 107.828L48.771 106.887L58.8103 111.731L58.3563 112.672Z"
        fill="black"
      />
      <path
        d="M78.3645 114.678H67.2139V113.635H78.3645V114.678Z"
        fill="black"
      />
      <path
        d="M53.8078 106.644L54.233 107.383"
        stroke="black"
        strokeWidth="0.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M54.6305 106.419L55.051 107.159"
        stroke="black"
        strokeWidth="0.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M55.4533 106.19L55.8738 106.934"
        stroke="black"
        strokeWidth="0.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M71.6448 111.229L72.3494 111.716"
        stroke="black"
        strokeWidth="0.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M72.2881 110.668L72.9927 111.155"
        stroke="black"
        strokeWidth="0.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M72.926 110.106L73.6306 110.593"
        stroke="black"
        strokeWidth="0.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M109.419 62.3066C109.419 62.3066 107.158 68.8585 102.519 69.3873C91.6428 70.6603 78.8466 75.3121 77.2388 76.4961C73.6638 77.6193 57.3256 103.85 57.3256 103.85C57.3256 103.85 59.69 108.24 64.4756 107.426L81.7312 81.6861L106.392 77.9422L109.419 62.3066Z"
        fill="#00819D"
      />
      <path
        d="M114.682 65.592L111.703 76.646C111.703 76.646 111.698 83.3271 101.744 83.1072C91.7897 82.8872 82.4359 81.4523 83.1641 82.2104C83.2067 82.2572 79.4756 106.573 79.4756 106.573H70.9637C70.9637 106.573 73.2052 76.4213 74.7752 74.2499C76.3451 72.0784 102.675 70.9739 102.675 70.9739L114.682 65.592Z"
        fill="#00A2C0"
      />
      <path
        d="M119.046 45.6227C120.607 45.8146 122.357 48.4307 122.172 50.8736C122.002 52.9327 120.437 58.2257 119.619 60.4674C118.375 63.8931 111.655 76.599 111.655 76.599C111.655 76.599 105.323 77.9234 100.623 69.5558C100.623 69.5277 102.959 66.1816 103.805 64.0382C104.609 62.0024 105.777 59.5174 105.73 59.3583C105.527 58.5532 105.391 57.733 105.323 56.906C105.323 56.906 103.555 56.321 103.644 55.8296C103.734 55.3382 106.141 47.8223 106.141 47.8223C106.141 47.8223 106.411 44.3685 110.998 44.6587C110.998 44.6587 111.773 44.6587 112.052 45.0331C112.412 46.4604 113.949 47.3028 119.046 45.6227Z"
        fill="#DAEE6B"
      />
      <path
        d="M115.996 53.1714L115.079 57.7624"
        stroke="#215B04"
        strokeWidth="0.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M105.271 56.9482C105.044 55.0762 105.744 51.468 107.688 49.0298"
        stroke="#215B04"
        strokeWidth="0.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M87.6281 55.0622L93.5013 52.9843L89.042 37.1428L83.1688 37.812L87.6281 55.0622Z"
        fill="#C3CDD5"
      />
      <path
        d="M87.7652 54.2384L92.9764 52.343L89.0184 38.1255L83.8072 38.8743L87.7652 54.2384Z"
        fill="#202E39"
      />
      <path
        d="M59.9691 106.56C59.5253 107.044 59.1086 107.552 58.7207 108.081L57.6236 107.538L57.0656 106.424L58.0114 104.838C58.5589 105.519 59.2212 106.102 59.9691 106.56Z"
        fill="#C3AB98"
      />
      <path
        d="M76.6523 106.582C76.4867 108.534 76.3922 109.746 76.5577 110.382H75.4653L74.198 109.39L73.8007 106.582C73.8007 106.582 76.6712 106.554 76.6523 106.582Z"
        fill="#C3AB98"
      />
      <path
        d="M99.3175 72.462L99.1236 70.473L111.419 69.5745L114.998 57.7156L120.309 58.4269C118.167 65.5731 115.58 73.6039 113.216 73.6085C111.868 73.6085 105.432 73.0329 101.795 72.7006L99.3175 72.462Z"
        fill="#C3AB98"
      />
      <path
        d="M96.6457 69.1018C96.6457 69.1018 96.031 68.6946 95.8418 68.6338C95.6527 68.573 94.792 68.4841 94.5981 68.4326C94.4043 68.3811 94.026 68.5075 93.7044 68.4326C93.3828 68.3577 93.0093 68.1143 93.0329 68.6806C93.0565 69.2469 96.1776 69.2141 96.3431 69.1486C96.3794 69.1262 96.4203 69.112 96.4628 69.1071C96.5054 69.1023 96.5485 69.1068 96.589 69.1205C96.589 69.1205 96.8065 69.186 96.6457 69.1018Z"
        fill="#C3AB98"
      />
      <path
        d="M101.237 72.4853C101.237 72.4853 98.0215 72.4198 97.5675 72.4292C97.1136 72.4385 95.397 72.4947 94.8154 72.4573C94.2096 72.38 93.6105 72.258 93.0231 72.0922C92.77 71.95 92.5455 71.7628 92.3611 71.54C92.3163 71.4288 92.3163 71.3049 92.3611 71.1937C92.3611 71.1937 91.8314 70.8427 91.8882 70.356C91.7425 70.2525 91.6202 70.1202 91.5288 69.9675C91.4626 69.7897 91.4011 69.6353 91.3869 69.5838C91.379 69.5235 91.3932 69.4624 91.4271 69.4117C91.461 69.361 91.5122 69.3241 91.5714 69.3077C91.7321 69.2796 92.2476 69.6961 92.7252 69.7242L93.7939 69.9161C93.8649 69.9161 94.1391 69.9488 94.1533 69.9161C94.1675 69.8833 94.1533 69.7897 93.879 69.7289L93.061 69.523L92.2618 69.3311C92.0868 69.303 91.1978 69.1673 91.0843 68.938C90.9708 68.7087 91.2025 68.5917 91.4768 68.5776C91.7511 68.5636 92.4746 68.6572 92.6306 68.6385C92.7867 68.6197 93.5764 68.7601 93.8128 68.8069C94.0493 68.8537 94.8532 68.9052 95.0613 68.938C95.5306 69.0137 96.0046 69.0575 96.4799 69.069C96.7211 69.0362 97.8371 69.9675 98.0783 70.1173C98.3194 70.2671 99.3078 70.5853 101.176 70.5853C103.044 70.5853 101.303 71.9706 101.237 72.4853Z"
        fill="#C3AB98"
      />
      <path
        d="M91.6566 46.5915C91.4958 45.6555 91.2405 45.5525 90.4177 44.8786C89.5948 44.2047 89.8975 43.8022 89.8975 43.8022L91.6566 44.8786C92.055 45.2438 92.4717 45.589 92.905 45.9129"
        fill="#C3AB98"
      />
      <path
        d="M91.6571 46.5915C91.4963 45.6555 91.2409 45.5525 90.4181 44.8786C89.5953 44.2047 89.898 43.8022 89.898 43.8022L91.6571 44.8786C92.0555 45.2438 92.4722 45.589 92.9055 45.9129C93.9364 46.7178 93.9506 47.5087 93.9506 47.5087C93.9506 47.5087 94.0593 48.1639 94.2248 48.7957C94.2955 49.0709 94.3839 49.3414 94.4897 49.6053C94.5653 49.7738 95.0808 50.8736 95.0808 50.8736C95.478 51.1076 93.1892 50.8736 93.1892 50.8736L92.5603 49.5164C92.5603 49.5164 92.5272 49.5164 92.513 49.5164L91.9928 47.6445C91.8184 47.3207 91.7032 46.969 91.6524 46.6055C91.6258 46.4466 91.5862 46.2901 91.5341 46.1375"
        fill="#C3AB98"
      />
      <path
        d="M94.2625 49.1375C94.377 49.4057 94.5081 49.6667 94.655 49.919L101.843 59.8825L103.734 56.0824C103.932 56.2741 104.161 56.4322 104.411 56.5504C104.831 56.747 105.309 56.9155 105.309 56.9155C105.309 56.9155 105.413 57.7251 105.484 58.2492C105.55 58.6236 105.725 59.3771 105.725 59.3771C105.681 59.5656 105.618 59.749 105.536 59.9246C105.281 60.5283 104.883 61.4877 104.709 61.8434C104.619 61.9557 104.534 62.0727 104.444 62.1897C102.699 64.656 100.774 63.2099 100.774 63.2099C100.774 63.2099 99.5493 62.0446 98.4097 60.6875C97.5251 59.4756 96.7346 58.199 96.0453 56.8687C94.8914 54.4725 93.208 50.8924 93.208 50.8924C93.0897 50.5321 92.9242 50.0079 92.7871 49.5259C93.3356 49.5072 93.6903 49.1375 94.2625 49.1375Z"
        fill="#C3AB98"
      />
      <path
        d="M91.9123 70.3325C92.1308 70.4566 92.3644 70.5525 92.6074 70.618C92.877 70.6648 93.4633 70.7397 93.662 70.7724C93.9126 70.7959 94.1599 70.8461 94.3997 70.9222"
        stroke="#624834"
        strokeWidth="0.1"
        strokeMiterlimit="10"
      />
      <path
        d="M92.3663 71.2219C92.5115 71.3168 92.6712 71.3879 92.8392 71.4325C93.1276 71.498 93.6194 71.6104 93.7566 71.6291L94.5321 71.732"
        stroke="#624834"
        strokeWidth="0.1"
        strokeMiterlimit="10"
      />
      <path
        d="M93.0329 68.6619C94.0182 68.7493 94.9984 68.8868 95.9695 69.0737"
        stroke="#624834"
        strokeWidth="0.1"
        strokeMiterlimit="10"
      />
    </g>
    <g clipPath="url(#clip4_2796_35463)">
      <path
        d="M153.573 100.403H110.613C109.898 100.403 109.213 100.105 108.707 99.5728C108.201 99.041 107.917 98.3196 107.917 97.5675V64.7506C107.917 63.9984 108.201 63.2771 108.707 62.7452C109.213 62.2133 109.898 61.9146 110.613 61.9146H156.191C156.906 61.9146 157.592 62.2133 158.097 62.7452C158.603 63.2771 158.887 63.9984 158.887 64.7506L156.269 97.5675C156.269 98.3196 155.985 99.041 155.48 99.5728C154.974 100.105 154.288 100.403 153.573 100.403Z"
        fill="#9FAFBC"
      />
      <path
        d="M110.357 100.666H109.865C109.51 100.666 109.16 100.593 108.833 100.45C108.506 100.308 108.208 100.099 107.958 99.8355C107.708 99.5721 107.509 99.2595 107.374 98.9154C107.238 98.5713 107.168 98.2025 107.168 97.8301V65.0432C107.168 64.2904 107.452 63.5683 107.958 63.0352C108.463 62.5022 109.149 62.2017 109.865 62.1997H112.967C113.323 62.1987 113.675 62.2715 114.004 62.414C114.333 62.5565 114.632 62.7658 114.883 63.0299C115.135 63.2941 115.335 63.6079 115.471 63.9533C115.607 64.2988 115.678 64.6692 115.678 65.0432L113.067 97.8601C113.058 98.6091 112.768 99.3238 112.26 99.8492C111.753 100.375 111.069 100.668 110.357 100.666Z"
        fill="#C3CDD5"
      />
    </g>
    <path
      d="M172.797 58.9644C174.834 62.3068 179.356 63.5873 180.031 68.583C180.706 73.5787 179.295 74.0597 179.934 75.9774C180.572 77.8951 181.21 80.3178 180.019 82.5061C178.827 84.6943 176.876 84.5681 177.46 87.2372C178.043 89.9064 173.922 92.6478 173.922 92.6478C173.922 92.6478 169.843 90.9585 168.639 87.2372C167.484 83.6844 168.883 82.8127 167.88 80.8228C166.877 78.8329 165.083 77.9673 165.843 76.0736C167.606 71.673 166.646 70.7172 166.931 68.8596C167.807 63.2146 170.907 61.7838 172.797 58.9644Z"
      fill="#5EA215"
    />
    <path
      d="M173.326 102.964C173.45 102.959 173.57 102.916 173.668 102.842C173.767 102.768 173.841 102.666 173.879 102.549C175.095 98.2147 174.779 90.8023 174.767 90.4897C174.753 90.3398 174.683 90.2005 174.57 90.0992C174.458 89.9979 174.311 89.942 174.159 89.9426C174.007 89.9562 173.866 90.0254 173.764 90.1367C173.662 90.248 173.605 90.3933 173.606 90.5438C173.606 90.622 173.928 98.0944 172.761 102.248C172.728 102.395 172.751 102.548 172.827 102.678C172.902 102.808 173.024 102.906 173.168 102.952C173.22 102.962 173.273 102.966 173.326 102.964Z"
      fill="#5EA215"
    />
    <path
      d="M175.679 94.2529C175.679 94.2529 191.933 76.8191 193.647 75.0156C195.362 73.2121 202.097 68.1443 204.784 68.006C204.784 68.006 200.924 65.3008 198.705 66.3047C196.486 67.3086 194.942 69.3646 193.331 69.8756C191.721 70.3866 190.724 70.1161 188.93 71.6791C187.137 73.2422 187.575 74.685 185.174 75.749C182.773 76.8131 181.964 77.7689 181.283 79.2598C181.283 79.2598 181.095 80.6546 177.363 86.7985C175.721 89.5278 175.679 94.2529 175.679 94.2529Z"
      fill="#5EA215"
    />
    <path
      d="M176.019 97.3067C176.019 97.3067 193.799 77.883 195.52 76.1156C197.24 74.3482 202.206 68.09 204.881 67.9457C204.881 67.9457 201.841 66.6051 199.653 67.6091C197.465 68.613 195.884 70.669 194.273 71.18C192.663 71.691 191.672 71.4205 189.878 72.9835C188.085 74.5466 188.517 75.9894 186.122 77.0534C183.727 78.1175 182.906 79.0734 182.225 80.5643L182.517 85.8786L180.694 83.1793C180.694 83.1793 177.539 87.7542 176.28 90.6578C175.022 93.5615 176.019 97.3067 176.019 97.3067Z"
      fill="#BED733"
    />
    <path
      d="M172.919 108.362C173.047 108.363 173.172 108.322 173.273 108.244C173.374 108.167 173.447 108.058 173.478 107.935C174.998 102.056 177.125 95.3108 177.125 95.2627C177.124 95.1354 177.081 95.0119 177.004 94.91C176.927 94.8081 176.819 94.733 176.696 94.6955C176.573 94.6581 176.441 94.6602 176.32 94.7017C176.198 94.7431 176.093 94.8217 176.019 94.9261C176.019 94.9922 173.879 101.743 172.372 107.653C172.353 107.725 172.348 107.801 172.359 107.875C172.37 107.95 172.396 108.021 172.435 108.085C172.474 108.15 172.525 108.206 172.587 108.25C172.648 108.295 172.717 108.327 172.791 108.344L172.919 108.362Z"
      fill="#BED733"
    />
    <path
      d="M169.26 87.2674C169.26 87.2674 153.625 75.1599 151.978 73.9336C150.33 72.7072 144.154 69.4428 141.942 69.6833C141.942 69.6833 144.726 66.9601 146.665 67.4891C148.604 68.0181 150.154 69.497 151.528 69.6954C152.902 69.8937 153.686 69.5451 155.37 70.6031C157.053 71.6612 156.883 72.8695 158.986 73.4286C161.09 73.9877 161.886 74.6309 162.634 75.7852C162.634 75.7852 162.974 76.8973 166.889 81.4181C168.585 83.414 169.26 87.2674 169.26 87.2674Z"
      fill="#5EA215"
    />
    <path
      d="M169.393 89.8102C169.393 89.8102 152.245 76.296 150.598 75.0756C148.95 73.8552 144.063 69.4006 141.85 69.6651C141.85 69.6651 144.136 68.1622 146.075 68.6912C148.014 69.2202 149.564 70.6991 150.938 70.8975C152.312 71.0959 153.102 70.7532 154.78 71.8052C156.458 72.8573 156.293 74.0716 158.427 74.6367C160.561 75.2018 161.327 75.8391 162.074 76.9873L162.561 81.3698L163.667 78.923C165.338 80.6521 166.88 82.4985 168.281 84.4478C169.685 86.636 169.393 89.8102 169.393 89.8102Z"
      fill="#5EA215"
    />
    <path
      d="M173.344 98.5392C173.424 98.5383 173.502 98.5219 173.575 98.4911C173.71 98.4247 173.816 98.311 173.871 98.1722C173.926 98.0333 173.927 97.8791 173.873 97.7396C171.831 93.1107 169.18 87.8745 169.156 87.8204C169.142 87.7264 169.106 87.6371 169.05 87.5598C168.994 87.4824 168.921 87.4192 168.836 87.3753C168.75 87.3314 168.656 87.3082 168.56 87.3074C168.464 87.3066 168.369 87.3284 168.283 87.3708C168.197 87.4133 168.123 87.4753 168.066 87.5518C168.009 87.6283 167.971 87.717 167.955 87.8107C167.939 87.9044 167.946 88.0005 167.976 88.091C168.005 88.1815 168.055 88.2639 168.123 88.3314C168.123 88.3855 170.785 93.5976 172.816 98.1965C172.863 98.2961 172.938 98.3808 173.031 98.4412C173.125 98.5017 173.233 98.5356 173.344 98.5392Z"
      fill="#5EA215"
    />
    <path
      d="M173.691 92.6657C173.691 92.6657 161.789 72.0517 160.518 69.9476C159.248 67.8435 153.832 61.387 151.254 60.6536C151.254 60.6536 155.625 58.85 157.546 60.3349C159.467 61.8198 160.518 64.1584 161.977 65.006C163.436 65.8536 164.457 65.8175 165.849 67.7593C167.241 69.7011 166.5 70.9695 168.591 72.5686C170.682 74.1678 171.265 75.2438 171.594 76.855C171.594 76.855 171.454 78.2557 173.715 85.0609C174.712 88.0487 173.691 92.6657 173.691 92.6657Z"
      fill="#5EA215"
    />
    <path
      d="M172.67 95.5634C172.67 95.5634 159.722 72.7191 158.451 70.597C157.181 68.4749 153.759 61.297 151.157 60.5635C151.157 60.5635 154.403 59.9203 156.324 61.3871C158.245 62.854 159.302 65.2106 160.755 66.0582C162.208 66.9059 163.235 66.8698 164.627 68.8115C166.019 70.7533 165.278 72.0218 167.369 73.6209C169.46 75.22 170.044 76.29 170.372 77.9012L168.895 83.0171L171.253 80.7808C171.253 80.7808 173.296 85.9388 173.867 89.0468C174.438 92.1549 172.67 95.5634 172.67 95.5634Z"
      fill="#BED733"
    />
    <path
      d="M173.326 107.04C173.48 107.029 173.624 106.961 173.729 106.849C173.834 106.738 173.892 106.591 173.891 106.439C173.74 100.343 173.192 93.291 173.186 93.2129C173.184 93.1378 173.166 93.0639 173.134 92.9959C173.102 92.9279 173.056 92.8672 172.999 92.8175C172.942 92.7679 172.875 92.7304 172.803 92.7074C172.73 92.6844 172.654 92.6764 172.578 92.6839C172.431 92.7029 172.296 92.7746 172.199 92.8853C172.101 92.9961 172.048 93.1382 172.05 93.285C172.05 93.3572 172.597 100.379 172.749 106.445C172.75 106.598 172.811 106.745 172.918 106.856C173.025 106.966 173.171 107.032 173.326 107.04Z"
      fill="#BED733"
    />
    <path
      d="M138.695 84.8325C138.695 84.8325 138.756 80.2576 143.796 77.7447C148.835 75.2319 150.762 77.2398 152.713 76.4342C154.664 75.6286 157.412 74.0295 160.755 77.7688C164.099 81.508 164.196 78.0633 167.071 80.7746C169.946 83.4859 169.648 92.4312 167.539 94.6556C167.539 94.6556 158.622 83.9427 153.424 82.2595C144.403 79.3618 138.695 84.8325 138.695 84.8325Z"
      fill="#5EA215"
    />
    <path
      d="M137.54 86.2031C137.54 86.2031 137.601 81.6282 142.641 79.1153C147.68 76.6024 149.607 78.6103 151.558 77.8048C153.509 76.9992 156.257 75.4001 159.6 79.1394C162.944 82.8787 163.041 79.434 165.922 82.1452C168.803 84.8565 170.031 93.0384 167.922 95.2567C167.922 95.2567 157.473 85.3073 152.269 83.6301C143.224 80.7385 137.54 86.2031 137.54 86.2031Z"
      fill="#BED733"
    />
    <path
      d="M173.63 104.497C173.699 104.499 173.767 104.487 173.831 104.461C173.902 104.436 173.967 104.397 174.023 104.347C174.079 104.297 174.124 104.236 174.156 104.168C174.188 104.1 174.206 104.027 174.208 103.952C174.211 103.877 174.198 103.803 174.171 103.733C172.828 100.126 168.092 93.4593 167.874 93.1768C167.781 93.0652 167.649 92.9923 167.505 92.9728C167.36 92.9533 167.214 92.9885 167.095 93.0715C166.975 93.1545 166.892 93.279 166.862 93.42C166.832 93.5611 166.856 93.7083 166.931 93.832C166.98 93.9042 171.794 100.655 173.089 104.124C173.129 104.234 173.203 104.329 173.3 104.395C173.397 104.462 173.512 104.498 173.63 104.497Z"
      fill="#BED733"
    />
    <path
      d="M201.033 100.283C201.402 99.3561 201.516 98.3494 201.363 97.3652C201.21 96.381 200.796 95.4544 200.164 94.6798C197.988 91.716 196.279 92.2751 195.386 91.2471C194.492 90.2191 193.368 88.4637 190.334 89.5939C187.301 90.7241 188.249 88.7342 185.775 89.4376C183.301 90.1409 180.846 95.3591 181.417 97.2527C181.417 97.2527 189.739 93.778 193.252 94.3732C194.955 94.6101 196.566 95.2788 197.93 96.3144C199.293 97.35 200.362 98.7171 201.033 100.283Z"
      fill="#5EA215"
    />
    <path
      d="M201.307 101.425C201.678 100.499 201.793 99.4923 201.641 98.5078C201.49 97.5234 201.076 96.5964 200.443 95.8219C198.261 92.8581 196.553 93.4172 195.659 92.3892C194.766 91.3612 193.647 89.6058 190.608 90.736C187.569 91.8662 188.529 89.8763 186.055 90.5797C183.581 91.283 180.463 95.5994 181.028 97.5111C181.028 97.5111 190.018 94.9381 193.532 95.5333C195.232 95.7677 196.842 96.4336 198.204 97.4661C199.567 98.4985 200.635 99.8623 201.307 101.425Z"
      fill="#BED733"
    />
    <path
      d="M175.156 101.323C175.301 101.321 175.442 101.268 175.551 101.172C177.302 99.5852 182.055 97.1685 182.104 97.1444C182.199 97.1294 182.288 97.0923 182.366 97.0363C182.444 96.9803 182.507 96.9069 182.55 96.8221C182.593 96.7374 182.616 96.6437 182.615 96.5487C182.615 96.4537 182.592 96.3601 182.548 96.2757C182.504 96.1913 182.44 96.1183 182.362 96.0628C182.285 96.0074 182.194 95.971 182.099 95.9566C182.004 95.9423 181.907 95.9503 181.816 95.9802C181.725 96.0101 181.642 96.0609 181.575 96.1285C181.38 96.2307 176.633 98.6413 174.767 100.337C174.661 100.443 174.598 100.584 174.592 100.732C174.585 100.881 174.634 101.027 174.73 101.142C174.785 101.2 174.85 101.246 174.924 101.277C174.997 101.308 175.076 101.324 175.156 101.323Z"
      fill="#BED733"
    />
    <path
      d="M167.326 111.915C167.326 114.981 168.597 117.644 170.451 118.955C171.235 119.514 175.125 119.43 175.976 118.744C176.681 118.153 177.263 117.433 177.691 116.622C178.448 115.167 178.834 113.551 178.815 111.915C178.815 107.545 176.244 107.19 173.071 107.19C169.898 107.19 167.326 107.545 167.326 111.915Z"
      fill="#005B75"
    />
    <g clipPath="url(#clip5_2796_35463)">
      <path
        d="M148.496 23.1327C152.15 23.1327 153.029 18.8726 153.029 18.8726V15.0385C153.029 15.0385 152.92 14.9485 153.501 14.3616H153.629C154.39 14.4278 154.711 15.2751 154.716 16.1177C154.752 16.5061 154.649 16.8946 154.425 17.2143C154.202 17.534 153.873 17.7642 153.497 17.8643C153.467 17.8693 153.437 17.8693 153.407 17.8643C153.407 17.8643 153.341 22.5979 153.407 24.6285C151.44 28.4655 147.188 27.8215 146.363 25.8918C146.272 25.679 146.223 25.4506 146.223 25.2107C146.223 24.335 146.284 22.3328 146.289 22.3328C146.9 22.8642 147.687 23.1492 148.496 23.1327Z"
        fill="#775C4D"
      />
      <path
        d="M147.986 18.7733C149.692 18.849 150.287 19.2751 150.292 20.0419C150.328 20.2995 150.272 20.5616 150.132 20.7811C149.993 21.0006 149.779 21.1631 149.531 21.2394C148.964 21.4761 148.383 21.0738 148 21.0738C147.617 21.0738 146.922 21.3436 146.553 21.1685C146.368 21.0494 146.215 20.8856 146.11 20.692C146.004 20.4985 145.948 20.2814 145.948 20.0608C145.948 19.72 146.284 18.6975 147.986 18.7733Z"
        fill="#775C4D"
      />
      <path
        d="M144.412 14.7356C144.455 14.8492 144.445 14.6504 144.445 14.6504L144.823 11.2375C144.823 11.2375 148.893 10.3382 151.913 11.1665C151.913 11.1665 152.414 11.0813 152.424 13.1025C152.433 15.1237 152.608 17.0692 152.424 17.997C152.239 18.9247 151.214 19.3555 151.214 19.3555C151.214 19.3555 147.607 17.2633 145.542 19.3555L144.568 18.4088C144.428 17.8452 144.358 17.2665 144.36 16.6858C144.322 15.848 144.384 15.3415 144.412 14.7356Z"
        fill="#775C4D"
      />
      <g opacity="0.3">
        <path
          d="M149.654 19.4597C149.654 20.0372 148.973 20.5058 148.123 20.5106C147.272 20.5153 146.587 20.1319 146.582 19.5639C147.092 19.6151 147.606 19.6262 148.118 19.597C148.992 19.5497 149.654 19.4597 149.654 19.4597Z"
          fill="black"
        />
      </g>
      <g opacity="0.3">
        <path
          d="M147.763 17.5236C147.688 16.9698 146.87 17.197 146.402 17.1023C146.842 17.2853 147.297 17.4264 147.763 17.5236Z"
          fill="black"
        />
      </g>
      <path
        d="M145.863 15.4219C145.701 15.4219 145.57 15.2905 145.57 15.1284C145.57 14.9664 145.701 14.835 145.863 14.835C146.025 14.835 146.156 14.9664 146.156 15.1284C146.156 15.2905 146.025 15.4219 145.863 15.4219Z"
        fill="#1C262F"
      />
      <path
        d="M149.37 15.4078C149.209 15.4078 149.077 15.2764 149.077 15.1143C149.077 14.9522 149.209 14.8208 149.37 14.8208C149.532 14.8208 149.663 14.9522 149.663 15.1143C149.663 15.2764 149.532 15.4078 149.37 15.4078Z"
        fill="#1C262F"
      />
      <path
        d="M146.638 13.5334C146.638 13.3488 145.362 13.4671 144.483 13.576C144.483 13.576 144.45 13.8269 144.483 13.8316C145.901 14.0635 146.591 14.1014 146.591 14.1014C146.627 13.9143 146.643 13.7239 146.638 13.5334Z"
        fill="#131C24"
      />
      <path
        d="M148.52 14.1014C149.611 14.0025 150.697 13.8445 151.771 13.6281C151.771 13.6281 148.463 13.1784 148.463 13.4908C148.458 13.6959 148.477 13.9008 148.52 14.1014Z"
        fill="#131C24"
      />
      <path
        d="M148.926 5.57629C155.293 5.54316 155.316 12.2458 153.62 14.3617C153.043 14.9486 153.147 15.1143 153.147 15.1143V19.0242C153.147 19.0242 152.169 23.1897 148.515 23.2039C147.691 23.2254 146.891 22.9326 146.274 22.3849C146.159 22.2849 146.05 22.1774 145.948 22.0631C144.672 20.6146 144.502 18.3141 144.502 18.3141L144.54 18.352L145.485 19.2987C147.555 17.2112 151.157 19.2751 151.157 19.2751C151.157 19.2751 152.235 18.8538 152.395 17.9355C152.556 17.0172 152.395 15.0954 152.395 13.112C152.395 11.1287 151.885 11.2186 151.885 11.2186C148.893 10.4045 144.908 11.2518 144.908 11.2518L144.511 14.9155L144.441 14.7404C143.925 13.4623 141.212 5.60943 148.926 5.57629ZM147.981 18.8017C146.317 18.7307 145.991 19.7484 145.996 20.0608C145.993 20.2758 146.046 20.4879 146.148 20.6769C146.251 20.8658 146.4 21.0252 146.582 21.14C146.941 21.3104 147.678 21.0501 148 21.0501C148.321 21.0501 148.945 21.443 149.498 21.2063C149.74 21.1322 149.947 20.9743 150.083 20.7609C150.219 20.5475 150.275 20.2926 150.24 20.0419C150.235 19.294 149.654 18.8774 147.99 18.8017H147.981Z"
        fill="#131C24"
      />
      <path
        d="M144.868 118.797L132.105 118.857L132.099 117.667L144.863 117.607L144.868 118.797Z"
        fill="black"
      />
      <path
        d="M147.241 117.513L160.005 117.453L160.01 118.643L147.247 118.703L147.241 117.513Z"
        fill="black"
      />
      <path
        d="M152.346 112.445L156.075 115.761C156.075 115.761 159.992 116.602 160.021 117.505L147.248 117.567C147.248 116.64 148.803 113.831 148.803 113.831H150.565L152.346 112.445Z"
        fill="#677A89"
      />
      <path
        d="M139.716 112.502L136.02 115.847C136.02 115.847 132.108 116.731 132.089 117.634L144.862 117.572C144.862 116.645 143.268 113.854 143.268 113.854H141.497L139.716 112.502Z"
        fill="#677A89"
      />
      <path
        d="M154.962 114.772L154.16 115.331"
        stroke="black"
        strokeWidth="0.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M154.227 114.136L153.421 114.691"
        stroke="black"
        strokeWidth="0.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M153.488 113.501L152.682 114.055"
        stroke="black"
        strokeWidth="0.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M137.119 114.858L137.926 115.407"
        stroke="black"
        strokeWidth="0.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M137.849 114.213L138.66 114.762"
        stroke="black"
        strokeWidth="0.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M138.583 113.573L139.39 114.117"
        stroke="black"
        strokeWidth="0.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M159.887 64.8774L157.127 91.0192L152.523 111.972L148.578 111.991L150.157 92.8684L148.678 69.6126L144.238 92.1373L143.269 112.015L139.63 112.034L137.441 91.4158L136.966 63.3245L159.887 64.8774Z"
        fill="#202E39"
      />
      <path
        d="M136.577 61.3559L136.289 64.3806L160.29 66.6836V61.8289L156.594 27.9033L155.591 27.3491L153.728 24.2002C152.243 25.0268 150.572 25.4657 148.87 25.476C147.67 25.476 146.744 25.6671 145.913 24.7927C145.222 24.0664 144.473 28.2904 144.142 27.8699C143.278 26.8283 142.956 28.2761 142.956 28.2761L136.577 61.3559Z"
        fill="#DAEE6B"
      />
      <path
        d="M152.83 25.4187C151.831 26.0614 150.696 26.4635 149.513 26.5928C148.331 26.7222 147.134 26.5755 146.019 26.1641"
        stroke="#215B04"
        strokeWidth="0.1"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M155.605 26.1545L162.599 29.5948C162.599 29.5948 165.508 34.7123 165.854 43.4518L166.459 43.5999L167.241 47.9767L167.02 48.144L162.402 51.6273L163.055 61.4371L155.932 62.3928L153.719 44.4313C153.05 42.1622 152.583 39.839 152.322 37.4885C151.842 33.4174 153.22 24.1906 153.69 24.1428C155.365 24.979 155.605 26.1545 155.605 26.1545Z"
        fill="#00528C"
      />
      <path
        d="M145.904 26.5273L140.753 62.0154L133.918 60.386L135.689 49.5059L131.268 45.7502L132.775 43.0266L133.255 43.4758L136.524 28.8113L142.731 26.212C142.731 26.212 144.997 24.7785 145.789 24.6542C145.976 24.6208 145.904 26.5273 145.904 26.5273Z"
        fill="#00528C"
      />
      <path
        d="M160.799 33.8284C160.799 33.8284 160.237 43.4996 160.861 47.2696L160.799 33.8284Z"
        fill="black"
      />
      <path
        d="M160.799 33.8284C160.799 33.8284 160.237 43.4996 160.861 47.2696"
        stroke="#00528C"
        strokeWidth="0.1"
        strokeMiterlimit="10"
      />
      <path
        d="M159.503 48.6409L161.192 52.7072L167.02 48.316L167.241 48.1488L166.458 43.7719L165.853 43.6238"
        fill="#00528C"
      />
      <path
        d="M165.925 43.4231C165.925 43.4231 159.599 48.1105 159.296 48.4116C158.994 48.7126 161.288 52.6307 161.288 52.6307"
        stroke="black"
        strokeWidth="0.1"
        strokeMiterlimit="10"
      />
      <path
        d="M155.951 26.4795L157.108 30.2256L154.424 29.614L152.389 31.8168"
        stroke="black"
        strokeWidth="0.1"
        strokeMiterlimit="10"
      />
      <path
        d="M142.419 26.3743L141.118 29.3893L142.999 28.9832L145.174 31.5396"
        stroke="black"
        strokeWidth="0.1"
        strokeMiterlimit="10"
      />
      <path
        d="M135.679 49.4771L138.113 33.2119"
        stroke="black"
        strokeWidth="0.1"
        strokeMiterlimit="10"
      />
      <path
        d="M133.25 43.4661L136.202 45.989"
        stroke="black"
        strokeWidth="0.1"
        strokeMiterlimit="10"
      />
      <path
        d="M142.889 112.039L142.894 113.883L141.497 113.893L140.124 112.856L140 112.048L142.889 112.039Z"
        fill="#775C4D"
      />
      <path
        d="M149.062 112.01L149.067 113.864L150.574 113.845L152.019 112.727L152.13 111.991L149.062 112.01Z"
        fill="#775C4D"
      />
      <path
        d="M156.143 69.8659C156.191 69.6126 156.527 68.7239 156.555 68.5328C156.714 68.0828 156.895 67.641 157.098 67.2092C157.223 66.9511 157.52 66.0194 157.616 65.7757C157.847 65.2551 158.039 64.7185 158.192 64.1702C158.226 63.8787 159.632 62.8514 159.892 62.6125C159.894 62.5855 159.894 62.5583 159.892 62.5312C159.988 62.579 161.812 63.1238 162.589 63.4248C162.534 63.581 162.489 63.7406 162.455 63.9026C162.335 64.433 161.893 66.459 161.677 67.1279C161.407 67.8116 161.086 68.4744 160.717 69.1109C160.475 69.3643 160.189 69.5716 159.872 69.7225C159.728 69.7434 159.582 69.7074 159.464 69.6222C159.316 69.7591 159.136 69.8579 158.94 69.9104C158.744 69.9628 158.539 69.9672 158.341 69.9232C158.178 70.0645 157.987 70.1688 157.78 70.229L157.299 70.2816C157.226 70.2745 157.157 70.2401 157.107 70.1853C157.058 70.1305 157.03 70.0592 157.031 69.9853C157.031 69.7894 157.683 69.3068 157.856 68.7621C157.856 68.7191 158.336 67.644 158.399 67.5675C158.461 67.4911 158.538 67.1757 158.48 67.1423C158.423 67.1088 158.332 67.1423 158.178 67.4099L157.698 68.3034L157.218 69.1826C157.131 69.3737 156.709 70.3772 156.411 70.4393C156.114 70.5014 156.08 70.1908 156.143 69.8659Z"
        fill="#775C4D"
      />
      <path
        d="M158.298 63.9935C158.017 64.2459 157.75 64.5122 157.496 64.7915C157.347 64.9874 157.016 65.9717 156.892 66.182C156.767 66.3922 156.81 66.8796 156.627 67.2284C156.445 67.5772 156.047 67.9499 156.719 68.0837C157.391 68.2175 158.269 64.5669 158.231 64.3758C158.217 64.3253 158.214 64.2726 158.221 64.2208C158.229 64.1691 158.247 64.1194 158.274 64.0747C158.274 64.0747 158.447 63.8359 158.298 63.9935Z"
        fill="#775C4D"
      />
      <path
        d="M163.021 62.1347L162.877 62.7272C162.735 63.0654 162.614 63.4116 162.513 63.7641C161.62 63.3818 159.863 62.6508 159.892 62.6221C160.031 62.4835 160.938 56.1906 161.49 52.2867C161.49 52.2867 166.713 48.3255 166.718 48.3399C167.014 48.9786 167.194 49.6645 167.25 50.3659L163.055 62.1251L163.021 62.1347Z"
        fill="#775C4D"
      />
      <path
        d="M134.57 56.1332L133.044 59.7933L130.485 59.5926L133.039 47.0784L135.679 49.2955L134.57 56.1332Z"
        fill="#775C4D"
      />
      <path
        d="M129.631 64.4568C129.586 64.7746 129.488 65.083 129.343 65.3694C129.343 65.3694 129.424 66.1244 129.108 66.4446L128.8 66.6404L128.637 65.2404L129.631 64.4568Z"
        fill="#775C4D"
      />
      <path
        d="M132.501 57.3995C132.914 58.0255 133.063 59.8269 133.14 60.1948C133.25 60.705 133.25 61.2327 133.14 61.7429C132.991 62.1586 132.516 63.1764 132.439 63.4584C132.362 63.7403 131.647 65.8475 131.647 65.8475C131.647 65.8475 130.768 66.0816 130.903 64.9922C130.964 64.4046 131.098 63.8269 131.301 63.272C131.301 63.272 131.085 62.9614 130.615 63.4488C129.995 64.0538 129.426 64.709 128.915 65.4079C128.767 65.7471 129.242 66.9942 128.959 67.7253C128.675 68.4564 128.315 68.0932 128.315 68.0932C128.315 68.0932 127.835 65.8762 127.763 65.5465C127.691 65.2168 129.074 62.4406 129.136 62.2255C129.199 62.0105 130.764 59.1006 130.816 58.8808C130.869 58.661 132.088 56.7783 132.501 57.3995Z"
        fill="#775C4D"
      />
      <path
        d="M129.626 64.1846L129.232 64.6863L128.887 65.1163L128.978 66.2488"
        fill="#624834"
      />
      <path
        d="M129.626 64.1846L129.232 64.6863L128.887 65.1163L128.978 66.2488"
        stroke="#624834"
        strokeWidth="0.1"
        strokeMiterlimit="10"
      />
      <path
        d="M129.338 65.0924L129.232 64.6863"
        stroke="#624834"
        strokeWidth="0.1"
        strokeMiterlimit="10"
      />
      <path
        d="M156.723 67.8066C157.103 66.851 157.616 65.4175 158.072 64.5049C157.527 65.5643 157.076 66.669 156.723 67.8066Z"
        fill="#624834"
      />
      <path
        d="M158.351 69.6127C159.109 68.8959 159.282 67.7444 159.791 66.8748C159.22 67.7062 159.109 68.8816 158.351 69.6127Z"
        fill="#624834"
      />
      <path
        d="M159.407 69.4073C159.725 69.0807 159.975 68.6941 160.141 68.2701C160.352 67.8677 160.525 67.4471 160.66 67.0134C160.463 67.4196 160.314 67.8544 160.108 68.2558C159.946 68.6785 159.708 69.0687 159.407 69.4073Z"
        fill="#624834"
      />
    </g>
    <defs>
      <clipPath id="clip0_2796_35463">
        <rect
          width="46.4583"
          height="98.6607"
          fill="white"
          transform="matrix(-1 0 0 1 46.4584 13.9287)"
        />
      </clipPath>
      <clipPath id="clip1_2796_35463">
        <rect
          width="141.233"
          height="63.1429"
          fill="white"
          transform="matrix(-1 0 0 1 158.887 50.1431)"
        />
      </clipPath>
      <clipPath id="clip2_2796_35463">
        <rect
          width="83.625"
          height="100.286"
          fill="white"
          transform="matrix(-1 0 0 1 129.154 18.5713)"
        />
      </clipPath>
      <clipPath id="clip3_2796_35463">
        <rect
          width="26.0167"
          height="33.8929"
          fill="white"
          transform="matrix(-1 0 0 1 129.154 23.2141)"
        />
      </clipPath>
      <clipPath id="clip4_2796_35463">
        <rect
          width="141.233"
          height="63.1429"
          fill="white"
          transform="matrix(-1 0 0 1 158.887 50.1428)"
        />
      </clipPath>
      <clipPath id="clip5_2796_35463">
        <rect
          width="59.4667"
          height="118.857"
          fill="white"
          transform="matrix(-1 0 0 1 178.4 0)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default Park;
