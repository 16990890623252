import { useSelector } from 'react-redux';

import { selectConfig } from '@mfe/shared/redux/config';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { ProductInstanceStatus } from '@mfe/shared/schema-types';

export const useShowAddonsShop = (): boolean => {
  const {
    user: { productInstanceStatus, hasErrorProductInstance },
  } = useSelector(selectUser);
  const { showAddonsShop } = useSelector(selectConfig);

  const isUserActive = productInstanceStatus === ProductInstanceStatus.Active;

  return showAddonsShop && isUserActive && !hasErrorProductInstance;
};
