import React from 'react';
import { StyleProp, ViewStyle, StyleSheet } from 'react-native';
import { BaseCard } from '../BaseCard';
import { SvgType } from '../Components';

export class SvgCard extends React.Component<SvgType> {
  override render() {
    const { style: styleProps, ...props } = this.props;
    const style: StyleProp<ViewStyle> = [styles.default];
    if (styleProps) style.push(styleProps);

    return <BaseCard.Svg style={style} {...props} />;
  }
}

const styles = StyleSheet.create({
  default: {
    borderRadius: 8,
    overflow: 'hidden',
  },
});
