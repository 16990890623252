import { BadgeDot, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import { Assessment } from '@mfe/shared/schema-types';

import DiagnosticRemedyCard from './diagnostics-remedy-card';
import { DiagnosticResultProps } from '.';
import styles from './styles.module.scss';

const DiagnosticResult = ({ name, isOK }: DiagnosticResultProps) => {
  const { t } = useTranslation('Network');
  return (
    <div className={styles['diagnosticResult']}>
      <Txt
        variant={isOK ? 'bodyLargeRegular' : 'bodyLargeBold'}
        color={isOK ? 'regular' : 'error'}
      >
        {t(`DiagnosticResults.${name}Diagnostic`)}
      </Txt>
      <BadgeDot
        type={isOK ? 'positive' : 'negative'}
        label={
          isOK
            ? t('DiagnosticResults.noIssues')
            : t('DiagnosticResults.issuesDetected')
        }
      />
    </div>
  );
};

const DiagnosticsList = ({
  title,
  assessments,
}: {
  title: string;
  assessments: Assessment[] | null;
}) => {
  return (
    <div className={styles['subSection']}>
      <Txt variant="heading6">{title}</Txt>
      <div className={styles['diagnosticsList']}>
        {assessments?.map(({ name, isOK }) => (
          <div className={styles['diagnosticsListItem']}>
            <DiagnosticResult name={name} isOK={isOK} />
            {isOK === false && <DiagnosticRemedyCard name={name} isOK={isOK} />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DiagnosticsList;
