import React, { useRef } from 'react';
import { useNavigate } from '@mfe/legacy/andromeda';
import { UsagePage } from '@mfe/features/usage';
import { PlanLink } from '../../utils/Navigation';
import { useMessageHandler } from '../useMessageHandler';

const Plan = (): JSX.Element => {
  const ref = useRef<any>();
  const goTo = useNavigate();

  const navigateToChangePlan = React.useCallback(() => {
    goTo(`${PlanLink.Plan}/${PlanLink.ChoosePlanFilter}`);
  }, [goTo]);

  const navigateToPage = (link: string) => {
    goTo(link);
  };

  useMessageHandler(ref);

  return (
    <UsagePage
      navigateToChangePlan={navigateToChangePlan}
      navigateToPage={navigateToPage}
    />
  );
};

export default Plan;
