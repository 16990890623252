import { TokenType, Locale } from '@mfe/shared/schema-types';
import { LogEventFunction } from '../../containers';

export type TokenInfo = {
  accessToken: string;
  accessTokenExpirationTime: string;
  refreshToken?: string;
  type?: TokenType;
  idToken?: string;
  locale?: Locale;
};

export enum GeneralEventTypes {
  GoToUrl = 'GoToUrl',
  SetCookie = 'SetCookie',
  GetCookie = 'GetCookie',
  OpenInNewTab = 'open-in-new-tab',
  Logout = 'Logout',
  GetAuthParams = 'GetAuthParams',
  SendAuthParams = 'SendAuthParams',
  LoginFromCode = 'LoginFromCode',
  LoginEvent = 'LoginEvent',
  Profile = 'profile',
  PushNotification = 'PushNotification',
  SetOrigin = 'SetOrigin',
  GetToken = 'GetToken',
  ChangeLanguage = 'ChangeLanguage',
  ScrollTop = 'scroll-top',
  CopyText = 'copy-text',
  VisitPlanUsage = 'visit-plan-usage',
  ChangedPlan = 'changed-plan',
  ScheduledInstall = 'scheduled-install',
}

export enum UsageEventTypes {
  //to MFE
  GetAuthParams = 'get-auth-params',
  LaunchDarklyConfig = 'mv-launchdarkly-config',
  RefetchPlanInfo = 'refetch-plan-info',
  // from MFE
  ChangePlan = 'change-plan',
  ChatWithUs = 'chat-with-us',
  FAQSpecificQuestion = 'faq-specific-question',
  InvalidToken = 'invalid-or-expired-token',
  NoTokenProvided = 'no-token-provided',
  VisitPlanUsage = 'visit-plan-usage',
  Loaded = 'loaded',
  OpenInNewTab = 'open-in-new-tab',
  Installation = 'Installation',
  SendToken = 'SendToken',
}

export enum BillingEventTypes {
  // from MFE
  Autopay = 'autopay',
  ChangeBillDate = 'change-bill-date',
  ChatWithUs = 'chat-with-us',
  FAQSpecificQuestion = 'faq-specific-question',
  InvalidToken = 'invalid-or-expired-token',
  NoTokenProvided = 'no-token-provided',
  OneTimePayment = 'one-time-payment',
  ViewStatement = 'view-statement',
  PayBill = 'paybill',
  BillingContext = 'billing-context',
  // to MFE
  Init = 'init',
  ViewStatementLoaded = 'view-statement-loaded',
  ClientConfig = 'client-config',
  Loaded = 'loaded',
  OpenInNewTab = 'open-in-new-tab',
}

export enum MicroFrontendDomains {
  Usage = 'MyViasat-TSUsage',
  Billing = 'MyViasat-ManageBilling',
  Overview = 'MyViasat-Overview',
  General = 'General',
  Profile = 'MyViasat-Profile',
}

export interface WindowMessageData {
  domain: MicroFrontendDomains;
  eventType: UsageEventTypes | BillingEventTypes | GeneralEventTypes;
  data?: any;
}

export interface UpdateStatusHandlerProps {
  accountReference: string;
  trackEvent: LogEventFunction;
  logger: (error: Error) => void;
  updateSubscription: any;
}

export interface MicroFrontendEventHandlerMethods {
  logout: () => void;
  goTo?: any;
  sendMFEmessage?: Function;
  maximizeWidget?: () => void;
  updatePushNotificationStatusProps?: UpdateStatusHandlerProps;
  copyText?: (text: string) => void;
  scrollTop?: () => void;
  setIsAuthenticated?: (isAuthenticated: boolean) => void;
  setRedirectUrl?: (redirectUrl: string) => void;
  setLoginUrl?: (loginUrl: string) => void;
  setLogoutUrl?: (logoutUrl: string) => void;
  setIsAuthMFELoaded?: (isAuthMFELoaded: boolean) => void;
  setCookieLoginFailed?: (cookieLoginFailed: boolean) => void;
  setToken?: (token: TokenInfo) => void;
  sendToken?: () => void;
  trackLoginEvent?: (type: string) => void;
  openInNewTab?: (link: string) => void;
}

export interface OverviewEventHandlerMethods
  extends MicroFrontendEventHandlerMethods {
  sendMVClientInfo?: () => void;
}

export interface UsageEventHandlerMethods
  extends MicroFrontendEventHandlerMethods {
  sendOverviewMFEmessage?: Function;
  sendMVClientInfo?: () => void;
  trackChangePlanEvent: Function;
}

export interface BillingEventHandlerMethods
  extends MicroFrontendEventHandlerMethods {
  token: TokenInfo | null | undefined;
  statementLinkEndLoading: (
    invoiceNumber?: string | null,
    popupMessage?: boolean
  ) => void;
  setBillingContext: Function;
}
