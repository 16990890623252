import React, { useLayoutEffect } from 'react';
import { Platform, UIManager, LayoutAnimation } from 'react-native';
import type { ExpandableProps } from './types';

export { ExpandableProps };

if (Platform.OS === 'android') {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
}

export const Expandable = ({
  trigger,
  children,
}: ExpandableProps): JSX.Element | null => {
  useLayoutEffect((): void => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }, [trigger]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return trigger ? <>{children}</> : null;
};
