import { Icon, Txt } from '@vst/beam';
import { Close } from '@vst/beam-icons/icons';
import { useTranslation } from 'react-i18next';
import styles from './TermsAndConditionsModal.module.scss';

interface TermsAndConditionsModalProps {
  setShowTermsModal: (value: boolean) => void;
}

const TermsAndConditionsModal = ({
  setShowTermsModal,
}: TermsAndConditionsModalProps): JSX.Element => {
  const { t } = useTranslation('StreamOnPromo');
  const onCloseClick = (event: React.MouseEvent) => {
    if (event) event.preventDefault();
    setShowTermsModal(false);
  };

  return (
    <div className={styles['textContainer']}>
      <Icon
        icon={Close}
        onClick={onCloseClick}
        className={styles['closeButton']}
        color="gray_800"
      />
      <Txt variant="heading5" color="regular">
        {t('TermsAndConditionsModal.title')}
      </Txt>
      <Txt variant="bodySmallRegular" color="regular">
        {t('TermsAndConditionsModal.body')}
      </Txt>
    </div>
  );
};

export default TermsAndConditionsModal;
