import { BadgeStatus } from '@mfe/shared/schema-types';
import { Badge, BadgeStateType } from '@vst/beam';
import { useTranslation } from 'react-i18next';

interface InvoiceStatusChipProps {
  badgeStatus: BadgeStatus;
}

const badgeStatusToBadgeState: Record<BadgeStatus, BadgeStateType> = {
  [BadgeStatus.Paid]: 'positive',
  [BadgeStatus.AwaitingPayment]: 'infoPrimary',
  [BadgeStatus.PastDue]: 'negative',
  [BadgeStatus.Pending]: 'infoSecondary',
  [BadgeStatus.NoPaymentDue]: 'infoSecondary',
};

export const InvoiceStatusChip = ({
  badgeStatus,
}: InvoiceStatusChipProps): JSX.Element | null => {
  const { t } = useTranslation('BillingStatement');
  return (
    <Badge
      size="small"
      theme="inherit"
      showIcon={false}
      ml="8px"
      emphasis="heavy"
      data-cy="invoiceStatus"
      label={t('badgeStatus', { context: badgeStatus })}
      state={badgeStatusToBadgeState[badgeStatus]}
    />
  );
};
