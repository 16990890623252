import styles from './styles.module.scss';
import sharedStyles from '../shared/styles.module.scss';

const PaymentLoading = (): JSX.Element => {
  return (
    <>
      <div className={sharedStyles['gridItem']}>
        <div className={styles['paymentItem_Long']} />
      </div>

      <div className={sharedStyles['gridItem']}>
        <div className={styles['paymentItem_Short']} />
      </div>

      <div className={sharedStyles['gridItem']}>
        <div className={styles['paymentItem_Short']} />
      </div>

      <div className={sharedStyles['gridItem']}>
        <div className={styles['paymentItem_Long']} />
      </div>

      <div className={sharedStyles['gridItem']}>
        <div className={styles['paymentItem_Long']} />
      </div>
    </>
  );
};

const PaymentHistoryLoading = (): JSX.Element | null => {
  return (
    <>
      <PaymentLoading />
      <PaymentLoading />
      <PaymentLoading />
      <PaymentLoading />
    </>
  );
};

export default PaymentHistoryLoading;
