import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = () => new URLSearchParams(useLocation().search);

export const loadScript = async (src: string, id: string): Promise<unknown> => {
  const existingScript = document.getElementById(id);
  if (!existingScript) {
    return new Promise(function (resolve, reject) {
      const s = document.createElement('script');
      s.src = src;
      s.id = id;
      s.onload = resolve;
      s.onerror = reject;
      document.head.appendChild(s);
    }).catch((error) => console.error(`Issue loading: ${src}\n${error}`));
  } else {
    return new Promise((resolve) => resolve('script already exists'));
  }
};

const SCRIPT_POLL_INTERVAL_MS = 1000;
const SCRIPT_LOAD_TIMEOUT_MS = 20000;
const NUM_POLLS_BEFORE_TIMEOUT =
  SCRIPT_LOAD_TIMEOUT_MS / SCRIPT_POLL_INTERVAL_MS;
export const usePollForScriptLoaded = (
  scriptName: string,
  isScriptLoaded: () => boolean,
  setError: (error: string | null) => void
): boolean => {
  const [scriptLoading, setScriptLoading] = useState(true);
  useEffect((): void => {
    if (isScriptLoaded()) {
      // script is already loaded
      setScriptLoading(false);
    } else {
      // poll for when scripts are loaded and run
      let pollsSoFar = 0;
      const intervalHandle = setInterval((): void => {
        if (isScriptLoaded()) {
          setScriptLoading(false);
          clearInterval(intervalHandle);
        } else if (pollsSoFar > NUM_POLLS_BEFORE_TIMEOUT) {
          setError(`Timeout while waiting for ${scriptName} script to load`);
          setScriptLoading(false);
          clearInterval(intervalHandle);
        } else {
          pollsSoFar++;
        }
      }, SCRIPT_POLL_INTERVAL_MS);
    }
  }, []);
  return scriptLoading;
};

export function useTimeout(callback: () => any, delay: number) {
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();
    timeoutRef.current = setTimeout(tick, delay);
    return () => clearTimeout(timeoutRef.current as NodeJS.Timeout);
  }, [delay]);

  return timeoutRef;
}
