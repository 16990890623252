import React, { useEffect, useState } from 'react';
import { View, SafeAreaView, ScrollView, Platform } from 'react-native';
import { Text, Button, Color } from '@mfe/legacy/andromeda';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { useTrackEventFunction } from '@mfe/legacy/mv/containers';
import { callPhone } from '@mfe/legacy/mv/utils';
import {
  ContactPhoneProps,
  PhoneButtonProps,
} from '@mfe/legacy/mv/utils/liveChat';

const styles = StyleSheet.create({
  callBtn: {
    marginBottom: 36,
    marginHorizontal: Platform.OS === 'web' ? 20 : undefined,
  },
  callTitle: {
    marginBottom: 16,
    textAlign: 'center',
  },
  mainView: {
    marginTop: 20,
    marginBottom: 40,
    marginHorizontal: 4,
  },
  phoneLink: {
    color: Color.teal600,
    textAlign: 'center',
  },
  showMore: {
    alignSelf: 'center',
  },
  showMoreText: {
    color: Color.teal500,
  },
  description: {
    marginTop: 16,
    textAlign: 'center',
  },
  callSection: {
    marginTop: 28,
  },
});

const PhoneButton = ({ referrer }: PhoneButtonProps): JSX.Element => {
  const { t } = useTranslation('Fix');
  const trackEvent = useTrackEventFunction();

  return (
    <Text
      small
      bold
      link
      fixed
      style={styles.phoneLink}
      onPress={(): void => {
        trackEvent('Care Call', 'started', referrer);
        callPhone(t('CallCustomerCareNumber'));
      }}
    >
      {t('CallCustomerCareNumber', { context: 'display' })}
    </Text>
  );
};

const ContactPhone = ({
  referrer,
  testID = 'CallComp',
}: ContactPhoneProps): JSX.Element => {
  const { t } = useTranslation('LiveChat');

  const isChatEnabled = false;

  const [showPhone, setShowPhone] = useState(false);

  useEffect(() => {
    if (!isChatEnabled) setShowPhone(true);
  }, [isChatEnabled]);

  return (
    <SafeAreaView>
      <ScrollView bounces={false} testID="contactPhone">
        <View style={styles.mainView} testID={testID}>
          {showPhone ? (
            <View style={styles.callSection}>
              <Text.H4 heavy fixed style={styles.callTitle}>
                {t('callTitle')}
              </Text.H4>
              <PhoneButton referrer={referrer} />
              <Text small fixed style={styles.description}>
                {t('phoneDescription')}
              </Text>
            </View>
          ) : (
            <Button tertiary onPress={(): void => setShowPhone(true)}>
              {t('MoreContactOptions')}
            </Button>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default ContactPhone;
