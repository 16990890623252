import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Badge, Button, Surface, Txt } from '@vst/beam';

import { Locale } from '@mfe/shared/schema-types';
import { EventTypes } from '@mfe/services/window-messages';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { Issuer, selectConfig } from '@mfe/shared/redux/config';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { postOverviewMessage } from '@mfe/to-be-migrated/redux/utils';
import { selectBillingInfo } from '@mfe/to-be-migrated/redux/billingInfo';
import { goToPayment } from '@mfe/features/billing';
import { getUnpaidInvoice } from '@mfe/shared/util';

import { InvoiceCurrentStatus, getBadgeProps, getPaymentDate } from './utils';

import { LoadingContent } from './loading-content';
import { BalanceCardError } from './balance-card-error';
import { PreinstallBalanceCard } from './preinstall-balance-card';

import styles from './balance-card.module.scss';
import { OverviewAutoPayMethod } from './autopay-method';

export const BalanceCard = () => {
  const { t } = useTranslation('Overview', { keyPrefix: 'balanceCard' });

  const dispatch = useDispatch();

  const {
    userInfo: { isPreInstall },
  } = useSelector(selectUserInfo);

  const {
    locale: { userLocale: locale },
  } = useSelector(selectLocale);

  const {
    overviewLoading,
    billingInfo: { invoices, totalBalance, billingAccount },
    errors,
  } = useSelector(selectBillingInfo);

  const invoice = React.useMemo(
    () => getUnpaidInvoice(locale, invoices) ?? invoices[0],
    [invoices, locale]
  );

  const badgeProps = React.useMemo(
    () =>
      getBadgeProps(
        {
          invoiceStatus: invoice?.invoiceStatus,
          invoiceSettlementStatus: invoice?.invoiceSettlementStatus,
        },
        t
      ),
    [invoice, t]
  );

  const { issuer } = useSelector(selectConfig);

  const isSalesforce = issuer === Issuer.Salesforce;

  const isPaymentButtonDisabled =
    (locale === Locale.PtBr && totalBalance.value === 0) || isSalesforce;

  const showPaymentDueDateInfo =
    badgeProps.status && badgeProps.status !== InvoiceCurrentStatus.PastDue;

  const showBadge = badgeProps.status && invoices.length > 0;

  const makeAPaymentHandler = () => {
    dispatch(postOverviewMessage({ eventType: EventTypes.PayBill }));
    goToPayment(dispatch, 'billing_page', invoice);
  };

  if (overviewLoading) {
    return <LoadingContent />;
  }

  if (isPreInstall || (!isPreInstall && invoices.length < 1)) {
    return <PreinstallBalanceCard />;
  }

  if (errors) {
    return <BalanceCardError />;
  }

  return (
    <div className={styles['balanceCard']} data-cy="balance-card">
      <Surface theme="dark" className={styles['content']}>
        <div className={styles['header']}>
          <div>
            <Txt variant="bodyLargeBold" data-cy="title">
              {t('title')}
            </Txt>
            <Txt variant="heading1" data-cy="balance-data">
              {totalBalance.text}
            </Txt>
          </div>
          {showBadge && (
            <Badge
              data-cy="badge"
              size="small"
              showIcon={false}
              emphasis="heavy"
              {...badgeProps}
            />
          )}
        </div>

        {showPaymentDueDateInfo && (
          <Txt variant="smallRegular" data-cy="dueDate-info">
            <Trans
              ns="Summary"
              i18nKey="overviewPage.paymentDate"
              values={getPaymentDate(
                {
                  invoiceStatus: invoice?.invoiceStatus,
                  invoiceSettlementStatus: invoice?.invoiceSettlementStatus,
                },
                billingAccount
              )}
            />
          </Txt>
        )}

        <div className={styles['autoPay']} data-cy="autoPay">
          <OverviewAutoPayMethod />
        </div>
      </Surface>

      <div className={styles['balanceFooter']} data-cy="card-footer">
        <Button
          data-cy="make-payment-button"
          variant="primary"
          theme="color"
          fluid
          disabled={isPaymentButtonDisabled}
          onClick={makeAPaymentHandler}
        >
          {t('makePaymentButton')}
        </Button>
      </div>
    </div>
  );
};
