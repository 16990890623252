import React from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { BaseCard, BaseCardType } from '../../BaseCard';
import { usePlanToNumPeople } from './utils';
import { People } from '../../../../assets';
import { Color } from '../../../../utils/Color';
import { PlanOverview } from './PlanOverview';
import { PlanDetails } from './PlanDetails';
import { PlanTranslation } from './types';
export * from './types';

export type PlanCardType = Omit<BaseCardType, 'children'> & {
  t: PlanTranslation;
  plan: /* Plan */ any;
  onChoose?: (plan: /* Plan */ any) => void;
  alwaysExpanded?: boolean;
  hidePeople?: boolean;
  peopleSpacing?: number;
};

export type State = {
  isExpanded: boolean;
};

export class PlanCard extends React.Component<PlanCardType, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  override componentDidMount(): void {
    if (this.props.alwaysExpanded) this.setState({ isExpanded: true });
  }

  override componentDidUpdate(prevProps: any): void {
    if (
      prevProps.alwaysExpanded !== this.props.alwaysExpanded &&
      this.props.alwaysExpanded
    )
      this.setState({ isExpanded: true });
  }

  override render() {
    const {
      t,
      plan,
      alwaysExpanded = false,
      hidePeople = false,
      peopleSpacing = 29,
      onChoose,
      style: styleProps,
      testID = '',
    } = this.props;

    const { isExpanded } = this.state;

    const style: StyleProp<ViewStyle> = [styles.wrapper];
    if (styleProps) style.push(styleProps);

    const handleToggle = (): void => {
      this.setState((previousState: State): State => {
        const { isExpanded } = previousState;
        return { isExpanded: !isExpanded };
      });
    };

    return (
      <View style={styles.planCardWrapper} testID={testID}>
        {!hidePeople && (
          <People
            height={83}
            // eslint-disable-next-line react-hooks/rules-of-hooks
            numPeople={usePlanToNumPeople(t, plan)}
            style={{ marginBottom: peopleSpacing }}
            testID={`${testID}-People`}
          />
        )}
        <BaseCard style={style}>
          <View style={styles.textContainer}>
            <PlanOverview
              {...{
                t,
                plan,
                alwaysExpanded,
                isExpanded,
                handleToggle,
                onChoose,
                testID,
              }}
            />
          </View>
          <PlanDetails {...{ isExpanded, onChoose, t, plan, testID }} />
        </BaseCard>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  planCardWrapper: {
    alignItems: 'center',
    paddingHorizontal: 4,
  },
  textContainer: {
    paddingVertical: 25,
    paddingHorizontal: 16,
  },
  wrapper: {
    width: 270,
    padding: 12,
    borderRadius: 12,
    shadowColor: Color.black,
    shadowOpacity: 0.15,
    shadowRadius: 8,
    shadowOffset: { width: 0, height: 2 },
    elevation: 4,
  },
});
