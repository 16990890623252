import { Container, NoAppointmentsAvailable } from '@mfe/shared/components';

import styles from './no-appointments.module.scss';
import { useTranslation } from 'react-i18next';
import { InlineLink } from '@vst/beam';

interface NoAppointmentsProps {
  navigateToPreviousPage: () => void;
}

export const NoAppointments = ({
  navigateToPreviousPage,
}: NoAppointmentsProps) => {
  const { t } = useTranslation('NewScheduleInstallation', {
    keyPrefix: 'NoAppointmentsAvailable',
  });

  const hrefLink = `tel:${t('phoneNumber')}`;

  const errorDescription = (
    <>
      {t('descriptionPartOne')}
      <InlineLink href={hrefLink}>{t('phoneNumber')}</InlineLink>
      {t('descriptionPartTwo')}
    </>
  );

  return (
    <Container>
      <NoAppointmentsAvailable
        className={styles['container']}
        navigateToPreviousPage={navigateToPreviousPage}
        description={errorDescription}
      />
    </Container>
  );
};
