import React from 'react';

import { Surface, Button } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import styles from './ErrorWithRefresh.module.scss';

interface ErrorWithRefreshProps {
  handleRefresh: () => void;
  handleGoBack: (e: React.MouseEvent<HTMLElement>) => void;
  children: React.ReactNode;
}

export const ErrorWithRefresh = ({
  children,
  handleRefresh,
  handleGoBack,
}: ErrorWithRefreshProps) => {
  const { t } = useTranslation('NewChangePlan');

  return (
    <Surface
      variant="secondary"
      className={styles['errorPage']}
      py={{ xs: '96px', md: undefined }}
      px={{ xs: '16px', md: '32px' }}
    >
      {children}

      <div className={styles['buttons']}>
        <Button
          fluid
          data-cy="change-plan-page-error-button-refresh"
          variant="secondary"
          onClick={handleRefresh}
        >
          {t('errorPage.buttons.refresh')}
        </Button>
        <Button
          fluid
          data-cy="change-plan-page-error-button-goback"
          variant="primary"
          onClick={handleGoBack}
        >
          {t('errorPage.buttons.goBack')}
        </Button>
      </div>
    </Surface>
  );
};
