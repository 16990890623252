import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Surface, Txt } from '@vst/beam';

import { selectBillingInfo } from '@mfe/to-be-migrated/redux/billingInfo';

import styles from './balance-card.module.scss';
import { OverviewAutoPayMethod } from './autopay-method';

export const PreinstallBalanceCard = () => {
  const { t } = useTranslation('Overview', { keyPrefix: 'balanceCard' });

  const {
    billingInfo: { totalBalance },
  } = useSelector(selectBillingInfo);

  return (
    <div className={styles['balanceCard']} data-cy="preinstall-balace-card">
      <Surface theme="dark" className={styles['content']}>
        <div>
          <Txt variant="bodyLargeBold">{t('title')}</Txt>
          <Txt variant="heading1">{totalBalance.text}</Txt>
        </div>
        <Txt variant="smallRegular">{t('preinstallAccount')}</Txt>
        <div className={styles['autoPay']}>
          <OverviewAutoPayMethod />
        </div>
      </Surface>
    </div>
  );
};
