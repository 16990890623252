import { Txt } from '@vst/beam';
import { useSelector } from 'react-redux';

import { selectingPendingInstallation } from '@mfe/to-be-migrated/redux/pendingInstallation';

import loadingStyles from './Loading.module.scss';

type Props = {
  loading: boolean;
  name: string;
};

export const PlanName = ({ loading, name }: Props) => {
  const { hasPendingInstallation } = useSelector(selectingPendingInstallation);

  if (loading) {
    return <div className={loadingStyles['planName']} />;
  }

  return name ? (
    <Txt
      variant={'bodySmallBold'}
      mt={hasPendingInstallation ? '24px' : undefined}
    >
      {name}
    </Txt>
  ) : null;
};
