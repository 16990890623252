import { Txt, StandAloneLink, Tooltip, Icon } from '@vst/beam';
import { Help } from '@vst/beam-icons/icons';
import { useTranslation } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectStreamOnPromo,
  setIsEdittingAddress,
} from '@mfe/to-be-migrated/redux/streamOnPromo';
import { Address } from '@mfe/shared/schema-types';

import styles from './StaticAddress.module.scss';
import { selectConfig } from '@mfe/shared/redux/config';

export const StaticAddress = ({ address }: { address: Address }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('ShippingAddress');
  const {
    offerDetails: { shouldDisplaySms },
  } = useSelector(selectStreamOnPromo);
  const { platform } = useSelector(selectConfig);
  const addressExists =
    address &&
    address.addressLines &&
    address.addressLines.length &&
    address.municipality &&
    address.region &&
    address.postalCode;
  return (
    <div
      className={
        shouldDisplaySms
          ? styles['staticContainer']
          : styles['staticContainerWithoutSms']
      }
      data-cy="addressContainer"
    >
      <Txt variant="bodyLargeBold" data-cy="addressTitle" mb="12px">
        {t('Address.Title')}
      </Txt>
      <div className={styles['subtitleContainer']}>
        <Txt
          variant="bodySmallRegular"
          data-cy="addressSubtitle"
          mb="12px"
          color="inherit"
        >
          {t('Address.Subtitle')}
        </Txt>
        <Tooltip
          style={{ width: 200 }}
          bodyText={t('Address.Tooltip')}
          position="top"
          beakPosition={platform === 'Web' ? 'start' : 'end'}
          color="inherit"
        >
          <Icon icon={Help} />
        </Tooltip>
      </div>
      {addressExists ? (
        <div style={{ color: 'black' }}>
          <Txt
            variant="bodyLargeRegular"
            data-cy="addressRegion"
            color="inherit"
          >
            {address.addressLines[0]}
          </Txt>
          <Txt variant="bodyLargeRegular" color="inherit">
            {address.addressLines[1]}
          </Txt>
          <div className={styles['staticLineContainer']}>
            <Txt
              variant="bodyLargeRegular"
              data-cy="municipality"
              color="inherit"
            >
              {address.municipality},
            </Txt>
            &nbsp;
            <Txt variant="bodyLargeRegular" data-cy="region" color="inherit">
              {address.region},
            </Txt>
            &nbsp;
            <Txt
              variant="bodyLargeRegular"
              data-cy="postalCode"
              color="inherit"
            >
              {address.postalCode}
            </Txt>
          </div>
        </div>
      ) : (
        <div className={styles['loadingContainer']}>
          <div className={styles['loading']} />
          <div className={styles['loading']} />
        </div>
      )}
      {addressExists && (
        <StandAloneLink
          variant="primary"
          linkSize="large"
          className={styles['changeAddress']}
          href=""
          onClick={(e) => {
            e.preventDefault();
            dispatch(setIsEdittingAddress(true));
          }}
          openInNewLocation={false}
          data-cy="staticAddress"
        >
          {t('Button.Change')}
        </StandAloneLink>
      )}
    </div>
  );
};
