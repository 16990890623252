import {
  BadgeStatus,
  BillingAccount,
  BillingStatus,
  Locale,
  ProductInstanceStatus,
} from '@mfe/shared/schema-types';
import { isSameDay } from 'date-fns';
import { getDate } from '@mfe/shared/util';

export const getBillingContext = (
  productInstanceStatus?: string | null,
  billingAccount?: BillingAccount | null,
  locale?: Locale | null,
  summaryInvoiceStatus?: BadgeStatus | null
) => {
  const balance = billingAccount?.balance?.value ?? 0;

  const variables = {
    context: billingAccount?.billingStatus,
    billDate: getDate(billingAccount?.billDate ?? undefined),
    dueDate: getDate(billingAccount?.dueDate ?? undefined),
    payDate: getDate(billingAccount?.payDate ?? undefined),
  };

  // new
  if (billingAccount?.billingStatus === BillingStatus.New) {
    if (productInstanceStatus === ProductInstanceStatus.Active)
      return { ...variables, context: 'NewFirstBillDate' };
    if (locale === Locale.PtBr && balance >= 0)
      return { ...variables, context: 'NewPaymentDueToday' };
    if (locale === Locale.ItIt && balance >= 0)
      return { ...variables, context: 'NewFirstBillNextCycle' };
  }

  // ready (autopay)
  if (
    billingAccount?.billingStatus === BillingStatus.Ready &&
    billingAccount?.payDate
  ) {
    return { ...variables, context: 'BillReadyAutopay' };
  }

  if (!billingAccount?.dueDate) return variables;

  // ready (not today)
  const today = new Date();
  const dueDate = getDate(billingAccount.dueDate);
  if (
    billingAccount?.billingStatus === BillingStatus.Ready &&
    !isSameDay(today, dueDate)
  ) {
    return { ...variables, context: 'BillReadyPaymentDue' };
  }

  if (locale === Locale.PtBr && summaryInvoiceStatus === BadgeStatus.PastDue)
    return { ...variables, context: 'LateInvoice' };

  // current / ready (today) / past
  return variables;
};
