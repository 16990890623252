import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Txt } from '@vst/beam';

import { Locale } from '@mfe/shared/schema-types';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';

import loadingStyles from './Loading.module.scss';

type Props = {
  loading: boolean;
  price: string;
};

export const Price = ({ loading, price }: Props) => {
  const { t } = useTranslation('Global');

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  if (userLocale === Locale.PtBr) {
    return null;
  }

  if (loading) {
    return <div className={loadingStyles['price']} />;
  }

  return price ? (
    <Txt variant={'heading2'}>
      {t('price', { price: Number(price), context: 'fixed' })}
    </Txt>
  ) : null;
};
