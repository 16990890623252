import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ComputerSvg from './ComputerSvg';
import { FlowEnd } from '../../components';
import {
  AnalyticsEventNames,
  selfDescribingEvent,
} from '@mfe/to-be-migrated/redux/analytics';
import { AnalyticsEventSource } from '../../lib/analytics/pixel-analytics-type';
import {
  selectStreamOnPromo,
  Pages,
} from '@mfe/to-be-migrated/redux/streamOnPromo';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';

export const OrderSummary = () => {
  const { offerDetails } = useSelector(selectStreamOnPromo);
  const {
    userInfo: { email },
  } = useSelector(selectUserInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      selfDescribingEvent({
        vendor: 'com.viasat.care',
        eventName: AnalyticsEventNames.AddonsOrderSuccessful,
        context: [
          {
            schema: 'addons_context',
            data: {
              product_name: offerDetails.name,
              product_type_id: offerDetails.productTypeId,
              product_family: offerDetails.productFamily,
              source: AnalyticsEventSource.OVERVIEW_PAGE,
              product_price: offerDetails.price,
              discount_amount: offerDetails.discountValue ?? 0,
              discount_duration: offerDetails.discountDuration ?? 0,
            },
          },
        ],
      })
    );
  }, [offerDetails, dispatch]);

  return (
    <FlowEnd
      namespace={'OrderConfirmation'}
      page={Pages.orderSummary}
      titleKey="Title.Success"
      descriptionKey="ConfirmationEmail"
      offerName={offerDetails.name}
      email={email}
    >
      <ComputerSvg />
    </FlowEnd>
  );
};
