import * as React from 'react';
import { requestBoleto } from '@mfe/to-be-migrated/redux/billingInfo';
import { postGeneralMessage } from '@mfe/to-be-migrated/redux/utils';
import { EventTypes } from '@mfe/services/window-messages';
import type { Invoice, Payment } from '@mfe/shared/schema-types';
import { Icon, SpinnerInline, Txt, useToast } from '@vst/beam';
import { FileCopy, AddToPhotos, Check } from '@vst/beam-icons/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';

interface Props {
  invoice?: Invoice | null;
  payment?: Payment | null;
  showLabel?: boolean;
}

export const Boleto = ({ invoice, payment, showLabel }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [copyClicked, setCopyClicked] = React.useState(false);
  const { addToast, removeOldestToast } = useToast();

  const onGet = () => {
    dispatch(requestBoleto(invoice));
  };
  const { t } = useTranslation();

  const barCode = payment?.isExpired
    ? undefined
    : payment?.barCode ?? undefined;

  const onCopy = () => {
    removeOldestToast();
    addToast({
      liteMode: true,
      theme: 'light', //this feels backwards but this is actually how it works on litemode
      variant: 'success',
      body: t('Alerts:Billing.codeCopied.title'),
      closable: false,
    });
    dispatch(
      postGeneralMessage({
        eventType: EventTypes.CopyText,
        data: payment?.barCode,
      })
    );
    setCopyClicked(true);
  };

  const showSpinner = invoice?.isBoletoLoading;

  return (
    <div className={styles['boletoContainer']} id="boleto">
      {showLabel && (
        <div className={styles['boletoLabel']}>
          {barCode ? (
            <Txt variant="smallItalic" color="subtle">
              {t('Summary:boleto')}
            </Txt>
          ) : (
            <Txt variant="smallItalic" color="subtle">
              {t('Summary:boletoExpiredMsg')}
            </Txt>
          )}
        </div>
      )}

      <div className={styles['boleto']}>
        <div className={styles['barCode']}>
          {barCode ? (
            <Txt variant="bodySmallItalic" color="regular">
              {barCode}
            </Txt>
          ) : (
            <Txt variant="bodySmallItalic" color="regular">
              {t('Summary:boletoExpired')}
            </Txt>
          )}
        </div>

        <button
          data-cy="request-boleto-button"
          className={styles['icon']}
          onClick={() => (barCode ? onCopy?.() : onGet?.())}
        >
          {showSpinner ? (
            <SpinnerInline />
          ) : (
            <Icon
              data-cy={barCode ? 'copy-icon' : 'get-icon'}
              color="gray_600"
              icon={barCode ? (copyClicked ? Check : FileCopy) : AddToPhotos}
            />
          )}
        </button>
      </div>

      <div className={styles['autoPayMethod']} id="autoPay" />
    </div>
  );
};
