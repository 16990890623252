import { useScreenResolution } from '@mfe/shared/util';
import { colors } from '@vst/beam';
import React, { useState } from 'react';

import styles from './styles.module.scss';
import { Icon } from './Icon';

interface ExpandButtonProps {
  showExpandedItem: number | null;
  testID?: string;
  index: number;
}

export interface ListProps {
  isFAQ?: boolean;
  isExpandable?: boolean;
  maxExpandedHeight?: string | number;
  items: RowProps[];
  listStyle?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  hiddenContentStyle?: React.CSSProperties;
  listClassName?: string;
  buttonClassName?: string;
  hiddenContentClassName?: string;
  testID?: string;
  payments?: boolean;
}

interface RowProps {
  visibleContent: React.ReactNode;
  hiddenContent?: React.ReactNode;
}

const ExpandButtons = ({
  showExpandedItem,
  testID,
  index,
}: ExpandButtonProps): JSX.Element => {
  return (
    <div
      data-cy={testID}
      className={`${showExpandedItem === index ? styles['rotate180'] : ''} ${
        styles['ease']
      }`}
    >
      <Icon size={24} icon={'expandMore'} color={'#677A89'} />
    </div>
  );
};

const validateExpandButton = (
  isExpandable: boolean | undefined,
  body: React.ReactNode | undefined
): boolean => {
  if (isExpandable && body) return true;
  return false;
};

export const ExpandableList = ({
  isFAQ,
  items,
  isExpandable,
  maxExpandedHeight = '95px',
  buttonStyle = {},
  listStyle = {},
  hiddenContentStyle = {},
  listClassName = '',
  buttonClassName = '',
  hiddenContentClassName = '',
  payments,
  testID,
}: ListProps): JSX.Element => {
  const [showHiddenContentIndex, setShowHiddenContentIndex] = useState<
    null | number
  >(null);

  const showHiddenContent = (index: number) => {
    if (showHiddenContentIndex === index)
      return setShowHiddenContentIndex(null);

    return setShowHiddenContentIndex(index);
  };

  const { isSmall, isExtraSmall } = useScreenResolution();
  const [mobileShowMore, setMobileShowMore] = useState<boolean>(false);
  const [desktopShowMore, setDesktopShowMore] = useState<boolean>(false);
  // const [questionsShowMore, setQuestionsShowMore] = useState<boolean>(false);
  const smallOrExtraSmall = isSmall || isExtraSmall;
  const mobileListFilter = (item: any, index: number) => {
    if (payments) return item;
    if (!(isSmall || isExtraSmall)) return item;
    if (index <= 2 && !mobileShowMore) return item;
    if (mobileShowMore) return item;
  };
  const desktopListFilter = (item: any, index: number) => {
    if (payments) return item;
    if (isSmall || isExtraSmall) return item;
    if (index <= 9 && !desktopShowMore) return item;
    if (desktopShowMore) return item;
  };
  const questionsListFilter = (item: any, index: number) => {
    return item;
  };

  const listSelector = testID ? `${testID}-list` : '';
  const buttonSelector = testID ? `${testID}-button` : '';
  const expandButtonSelector = testID ? `${testID}-expand-button` : '';
  const hiddenContentSelector = testID ? `${testID}-dropdown` : '';

  let maxNumber = 10;
  let listFilter = desktopListFilter;

  if (smallOrExtraSmall) {
    maxNumber = 3;
    listFilter = mobileListFilter;
  }

  if (isFAQ) {
    maxNumber = 10;
    listFilter = questionsListFilter;
  }

  return (
    <ul
      data-cy={listSelector}
      className={`${styles['transformHeight']} ${styles['list']} ${listClassName}`}
      style={{ ...listStyle }}
    >
      {items
        .filter(listFilter)
        .map(({ hiddenContent, visibleContent }, index, filteredItems) => (
          <li
            key={index}
            style={{
              borderBottom:
                items.length - 1 === index || filteredItems.length - 1 === index
                  ? 'none'
                  : `1px solid ${colors.gray[200]}`,
            }}
          >
            <button
              data-cy={buttonSelector}
              onClick={() => showHiddenContent(index)}
              className={
                payments
                  ? `${styles['listItemPayment']} ${buttonClassName}`
                  : `${styles['listItem']} ${buttonClassName}`
              }
              style={{
                ...buttonStyle,
                cursor: isExpandable ? 'pointer' : 'default',
              }}
            >
              <div style={{ width: '100%', display: 'block' }}>
                {visibleContent}
              </div>

              {validateExpandButton(isExpandable, hiddenContent) && (
                <ExpandButtons
                  testID={expandButtonSelector}
                  showExpandedItem={showHiddenContentIndex}
                  index={index}
                />
              )}
            </button>
            {validateExpandButton(isExpandable, hiddenContent) && (
              <div
                data-cy={hiddenContentSelector}
                className={`${styles['animateHeight']} ${hiddenContentClassName}`}
                style={{
                  overflow: 'hidden',
                  display: 'block',
                  ...{
                    maxHeight:
                      showHiddenContentIndex === index ? maxExpandedHeight : 0,
                    marginBottom:
                      showHiddenContentIndex === index && !payments ? 24 : 0,
                  },
                  ...hiddenContentStyle,
                }}
              >
                {hiddenContent}
              </div>
            )}
          </li>
        ))}
      {items.length > maxNumber && !payments && (
        <li style={{ margin: '20px 24px' }}>
          {smallOrExtraSmall && (
            <button
              className={styles['button']}
              onClick={() => setMobileShowMore(!mobileShowMore)}
            >
              {desktopShowMore ? 'Hide all' : 'See more'}
            </button>
          )}
          {!smallOrExtraSmall && (
            <button
              className={styles['button']}
              onClick={() => setDesktopShowMore(!desktopShowMore)}
            >
              {desktopShowMore ? 'Hide all' : 'See more'}
            </button>
          )}
        </li>
      )}
    </ul>
  );
};
