import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert } from '@vst/beam-icons/illustrative-icons';
import { Button, IllustrativeIcon, Surface, Txt } from '@vst/beam';

import { useScreenResolution } from '@mfe/shared/util';
import { selectCurrentUsage } from '@mfe/to-be-migrated/redux/plan';
import {
  AddDataBoostSection,
  DataRemaining,
  DaysLeft,
  UsageBar,
} from '@mfe/shared/components';

import styles from './Overview.module.scss';

type Props = {
  handleViewDetailedUsage: () => void;
};

export const DataUsage = ({ handleViewDetailedUsage }: Props) => {
  const { t } = useTranslation('Overview', { keyPrefix: 'dataUsage' });

  const { loading, errors } = useSelector(selectCurrentUsage);

  const { isExtraLarge, isLarge, isMedium } = useScreenResolution();
  const isDesktop = isExtraLarge || isLarge || isMedium;

  const content = errors ? (
    <Error />
  ) : (
    <div data-cy="usage-summary" className={styles['usageSummary']}>
      <div className={styles['horizontalGroup']}>
        <div className={styles['dataRemaining']}>
          <DataRemaining />
          <DaysLeft />
        </div>
        {isDesktop && (
          <Button variant="secondary" onClick={handleViewDetailedUsage}>
            {t('viewDetailedUsageButton')}
          </Button>
        )}
      </div>
      <UsageBar />
      <AddDataBoostSection />
      {!isDesktop && (
        <Button
          variant="secondary"
          onClick={handleViewDetailedUsage}
          fluid
          style={{ maxWidth: 400, alignSelf: 'center' }}
        >
          {t('viewDetailedUsageButton')}
        </Button>
      )}
    </div>
  );

  return (
    <Surface radius={isDesktop ? '16px' : '0px'}>
      {loading ? <Loading /> : content}
    </Surface>
  );
};

const Loading = () => {
  const { isExtraLarge, isLarge, isMedium } = useScreenResolution();
  const isDesktop = isExtraLarge || isLarge || isMedium;

  return (
    <div data-cy="usage-summary-loading" className={styles['usageSummary']}>
      {isDesktop ? (
        <>
          <div className={styles['horizontalGroup']}>
            <div className={styles['verticalGroup']}>
              <div className={styles['dataRemainingLoading']} />
              <div className={styles['renewalDateLoading']} />
            </div>
            <div className={styles['buttonLoading']} />
          </div>
          <div className={styles['sectionLoading']} />
          <div className={styles['sectionLoading']} />
        </>
      ) : (
        <>
          <div className={styles['verticalGroup']}>
            <div className={styles['dataRemainingLoading']} />
            <div className={styles['renewalDateLoading']} />
          </div>
          <div className={styles['sectionLoading']} />
          <div className={styles['sectionLoading']} />
          <div className={styles['buttonLoading']} />
        </>
      )}
    </div>
  );
};

const Error = () => {
  const { t } = useTranslation('Overview', { keyPrefix: 'dataUsage' });

  return (
    <div data-cy="usage-summary-error" className={styles['usageSummaryError']}>
      <IllustrativeIcon icon={Alert} color="teal_600" />
      <Txt variant="heading5" mt="32px" mb="16px">
        {t('error.heading')}
      </Txt>
      <Txt variant="bodyLargeRegular">{t('error.subheading')}</Txt>
    </div>
  );
};
