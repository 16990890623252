import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { StandardModal, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import StorageManager from '@mfe/legacy/mv/services/localStorage';
import styles from './index.module.scss';
import { useToken } from '@mfe/legacy/mv/views/Auth';

interface TermsOfServiceProps {
  setAcceptedLegal: (action: boolean) => void;
}

export const TERMS_AND_CONDITIONS = 'MyViasatT&C';
export const MY_VIASAT_PP = 'MyViasatPP';

interface SubsectionProps {
  title: string;
  body: string;
  subsection?: string[];
}

interface SectionProps {
  title: string;
  body?: string;
  subsections?: SubsectionProps[];
}

const Sections = ({ title, body, subsections }: SectionProps): JSX.Element => {
  return (
    <li>
      <Txt variant="heading6">{title}</Txt>
      {subsections ? (
        <ul className={styles['subsections-list']}>
          {subsections.map(
            (
              {
                title: subTitle,
                body: subBody,
                subsection: subSubsection,
              }: SubsectionProps,
              subsIndex
            ) => (
              <Subsection
                key={subsIndex}
                title={subTitle}
                body={subBody}
                subsection={subSubsection}
              />
            )
          )}
        </ul>
      ) : (
        <Txt
          style={{
            paddingLeft: 20,
            whiteSpace: 'pre-line',
            marginBottom: 0,
          }}
          variant="bodySmallRegular"
        >
          {body}
        </Txt>
      )}
    </li>
  );
};

const Subsection = ({
  body,
  title,
  subsection,
}: SubsectionProps): JSX.Element => {
  return (
    <li>
      <Txt variant="heading6">{title}</Txt>
      <Txt
        component="span"
        variant="bodySmallRegular"
        style={{ whiteSpace: 'pre-line', paddingLeft: 5 }}
      >
        {body}
      </Txt>

      {subsection?.length && (
        <ol className={styles['subsections-sub-list']}>
          {subsection.map((content, index) => {
            return <li key={index}>{content}</li>;
          })}
        </ol>
      )}
    </li>
  );
};

export const TermsOfService = ({
  setAcceptedLegal,
}: TermsOfServiceProps): JSX.Element | null => {
  const { logout } = useToken();

  const {
    user: { accountNumber },
  } = useSelector(selectUser);
  const { t } = useTranslation('Legal');
  const history = useHistory();

  const termsCookie = `${TERMS_AND_CONDITIONS}${accountNumber}`;
  const privacyCookie = `${MY_VIASAT_PP}${accountNumber}`;

  useEffect(() => {
    if (!accountNumber) return;

    const tcVal = Cookies.get(termsCookie);
    const ppVal = Cookies.get(privacyCookie);
    if (tcVal === t('terms.version') && ppVal === t('privacy.version')) {
      setAcceptedLegal(true);
      history.replace('/');
    }
  }, [accountNumber]);

  useEffect(() => {
    return () => {
      const body = document.body;

      if (body.classList.contains('global--limit-height')) {
        body.classList.remove('global--limit-height');
      }
    };
  }, []);

  const handleAccept = () => {
    StorageManager.set(termsCookie, t('terms.version'), {
      secure: true,
      expiresInDays: 999,
    });
    StorageManager.set(privacyCookie, t('privacy.version'), {
      secure: true,
      expiresInDays: 999,
    });
    setAcceptedLegal(true);
  };

  return (
    <StandardModal
      size="large"
      allowFullScreen={true}
      isOpen={true}
      header={t('terms.title')}
      actions={{
        primary: {
          label: t('Accept'),
          onClick: () => {
            handleAccept();
          },
        },
        secondary: {
          label: t('DeclineAndSignout'),
          onClick: () => logout(),
        },
      }}
    >
      <div>
        {Object.values(
          t('terms.body', {
            returnObjects: true,
          })
        ).map((key, index) => {
          if (Array.isArray(key)) {
            return (
              <ol key={index} className={styles['sections']}>
                {key.map(
                  (
                    { body, subsections, title }: SectionProps,
                    sectionIndex
                  ) => (
                    <Sections
                      key={sectionIndex}
                      body={body}
                      subsections={subsections}
                      title={title}
                    />
                  )
                )}
              </ol>
            );
          }

          return (
            <Txt variant="bodySmallRegular" key={index}>
              {key}
            </Txt>
          );
        })}

        <Txt variant="heading5" style={{ paddingBottom: 24, paddingTop: 24 }}>
          {t('privacy.title')}
        </Txt>
        <Txt variant="bodySmallRegular" style={{ whiteSpace: 'pre-line' }}>
          {t('privacy.body')}
        </Txt>
      </div>
    </StandardModal>
  );
};
