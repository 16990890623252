export const ANALYTICS_EVENT_NAME = {
  billBalanceDisplayed: 'Balance Displayed',
  billCycleDateDisplayed: 'Bill Cycle Date Displayed',
  billStatementDisplayed: 'Bill Statement Displayed',
  billStatementSelected: 'Bill Statement Selected',
  billStatementHistoryViewed: 'Bill Statement History Viewed',
  makePaymentSuccessful: 'Make Payment Successful',
  makePaymentFailed: 'Make Payment Failed',
  makePaymentSelected: 'Make Payment Selected',
  makePaymentSubmitted: 'Make Payment Submitted',
  elementLoaded: 'Payments Element Loaded',
  billCycleDateChangeSuccessful: 'Bill Cycle Date Change Successful',
  billCycleDateChangeFailed: 'Bill Cycle Date Change Failed',
  updatePaymentMethodSuccess: 'Payment Method Update Successful',
  updatePaymentMethodFailed: 'Payment Method Update Failed',
  updatePaymentMethodUpdateSelected: 'Payment Method Update Selected',
  updatePaymentMethodSubmitted: 'Payment Method Update Submitted',
  billCycleDateChangeSelected: 'Bill Cycle Date Change Selected',
  billCycleDateNewDateSelected: 'Bill Cycle Date New Date Selected',
  billCycleDateChangeSubmitted: 'Bill Cycle Date Change Submitted',
  pendingPaymentNotified: 'Payments Pending Payment Notified',
} as const;
