// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { CustomFields, theme, RCMethods, CreateRCMethodsArgs } from './common';

const retryFunc = (func: () => void) => {
  const isLoaded = setInterval(() => {
    if (window.RocketChat) {
      func();
      clearInterval(isLoaded);
    }
  });
};

export const createRCMethods = ({
  rocketChat: RocketChat,
}: CreateRCMethodsArgs): RCMethods => ({
  restartMobileWidget: () =>
    retryFunc(() =>
      RocketChat(function () {
        this.minimizeWidget();
        this.maximizeWidget();
      })
    ),
  maximizeWidget: () =>
    retryFunc(() =>
      RocketChat(function () {
        this.maximizeWidget();
      })
    ),
  setCustomFields: (fields: CustomFields[]) =>
    retryFunc(() =>
      RocketChat(function () {
        fields.map(({ name, value, disableOverwrite }) => {
          this.setCustomField(name, value, disableOverwrite ? false : true);
        });
      })
    ),
  setTheme: () =>
    retryFunc(() =>
      RocketChat(function () {
        this.setTheme(theme);
      })
    ),
  setGuestName: (name: string) =>
    retryFunc(() =>
      RocketChat(function () {
        this.setGuestName(name);
      })
    ),
  setGuestEmail: (email: string) =>
    retryFunc(() =>
      RocketChat(function () {
        this.setGuestEmail(email);
      })
    ),
  showPrint: () =>
    retryFunc(() =>
      RocketChat(function () {
        this.showPrint();
      })
    ),
  setRocketChatDataAfterLoad: () =>
    retryFunc(() =>
      RocketChat(function () {
        this.dropTriggerMessage();
        this.setDepartment('virtualassistant');
      })
    ),
});
