import { selectConfig } from '@mfe/shared/redux/config';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import styles from './ModalContainer.module.scss';

interface ModalProps {
  children: ReactNode;
  id: string;
}

export const ModalContainer = ({
  children,
  id,
}: ModalProps): JSX.Element | null => {
  const { platform } = useSelector(selectConfig);

  let cardStyle = '';
  if (platform === 'Web') {
    cardStyle = `card${id}`;
  } else cardStyle = `cardMobile${id}`;
  return (
    <div data-cy="modal" className={styles['modal']}>
      <div className={styles[`${cardStyle}`]}>{children}</div>
    </div>
  );
};
