import { useDispatch } from 'react-redux';
import { Surface, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import {
  AddOnsState,
  setVoiceFormValues,
} from '@mfe/to-be-migrated/redux/addOns';
import { CustomStandardModal } from '@mfe/shared/components';
import { useScreenResolution } from '@mfe/shared/util';

import styles from './styles.module.scss';

type SuggestedE911AddressModalProps = {
  isOpen: boolean;
  onClose: () => void;
  scrubbedE911Address: AddOnsState['scrubbedE911Address'];
  incrementStep: () => void;
};

export const SuggestedE911AddressModal = ({
  isOpen,
  onClose,
  scrubbedE911Address,
  incrementStep,
}: SuggestedE911AddressModalProps) => {
  const dispatch = useDispatch();

  const { isExtraSmall, isSmall } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;
  const { t } = useTranslation('ShopAddons');

  const {
    addressLines: [streetAddress = '', aptUnit = ''] = [],
    municipality: cityAddress = '',
    region: stateProvince = '',
    postalCode: zipCode = '',
  } = scrubbedE911Address.rawAddress || {};

  const handleOnClick = () => {
    incrementStep();
    dispatch(
      setVoiceFormValues({
        emergencyAddress: {
          streetAddress,
          aptUnit,
          cityAddress,
          stateProvince,
          zipCode,
        },
      })
    );
  };

  const style = isMobile
    ? { display: 'flex', order: 1, flexBasis: 'unset' }
    : { flexBasis: 'unset' };

  return (
    <div data-cy={'suggested-e911-address-modal'}>
      <CustomStandardModal
        isOpen={isOpen}
        onClose={onClose}
        size="medium"
        header={t('voice.emergencyScrubbedAddress.modal.title')}
        actions={{
          primary: {
            label: `${t(
              'voice.emergencyScrubbedAddress.suggestedModal.primaryButton.label'
            )}`,
            onClick: handleOnClick,
            minWidth: '200px',
            style,
          },
          secondary: {
            label: `${t(
              'voice.emergencyScrubbedAddress.suggestedModal.secondaryButton.label'
            )}`,
            onClick: onClose,
            minWidth: '200px',
            style,
          },
        }}
        surfaceProps={{ className: styles['modal-content'] }}
      >
        <>
          <Txt variant={'bodySmallRegular'} color={'regular'} mb={'24px'}>
            {t('voice.emergencyScrubbedAddress.suggestedModal.description')}
          </Txt>
          <Txt variant={'bodySmallBold'} color={'subtle'} mb={'24px'}>
            {t('voice.emergencyScrubbedAddress.suggestedModal.confirmPrompt')}
          </Txt>
          <Surface variant={'secondary'} px={'24px'} py={'24px'} radius={'8px'}>
            <Txt
              color={'subtle'}
              variant={'paragraphRegular'}
              component={'span'}
            >
              {scrubbedE911Address.formattedAddress?.formattedAddressLine1}
              <br />
              {scrubbedE911Address.formattedAddress?.formattedAddressLine2}
            </Txt>
          </Surface>
        </>
      </CustomStandardModal>
    </div>
  );
};
