import range from 'lodash/range';
import { getYear, format } from 'date-fns';

import { DateOfBirth } from './form';

const getSelectOptions = (arr: string[] | number[]) =>
  arr.map((el) => ({ value: `${el}`, label: `${el}` }));

const START_YEAR = 1900;
const currentYear = getYear(new Date());
const yearOptions = getSelectOptions(
  range(START_YEAR, currentYear + 1).reverse()
);

const monthFormat = 'MMMM';
const getMonthName = (monthNumber: number) =>
  format(new Date(currentYear, monthNumber, 1), monthFormat);
const getMonthOptions = (arr: number[]) =>
  arr.map((el) => ({ label: getMonthName(el), value: el }));
const monthOptions = getMonthOptions(range(0, 12));

const dayOptions = getSelectOptions(range(1, 32));

export const dateOptions = {
  YEARS: yearOptions,
  MONTHS: monthOptions,
  DAYS: dayOptions,
};

const defaultDateFormat = 'P';
export const formatDate = (
  dob: DateOfBirth,
  dateFormat: string = defaultDateFormat
) => {
  const year = Number(dob.year);
  const month = Number(dob.month);
  const day = Number(dob.day);

  return format(new Date(year, month, day), dateFormat);
};
