import React, { useRef } from 'react';
import { useNavigate } from '@mfe/legacy/andromeda';
import Overview from '@mfe/to-be-migrated/shared-platform-react/pages/Overview';
import { useSelector } from 'react-redux';
import {
  selectStreamOnPromo,
  Pages as StreamOnPages,
} from '@mfe/to-be-migrated/redux/streamOnPromo';
import { StreamOnNavigator } from '@mfe/features/stream-on/pages';
import { PlanLink, StackLink } from '../../utils/Navigation';
import { useMessageHandler } from '../useMessageHandler';

export const Home = (): JSX.Element => {
  const ref = useRef<any>();
  const goTo = useNavigate();
  const { currentPage: streamOnPage } = useSelector(selectStreamOnPromo);

  useMessageHandler(ref);

  const navigateToPage = (link: string) => {
    goTo(link);
  };

  return streamOnPage === StreamOnPages.login ? (
    <Overview
      navigateToPage={navigateToPage}
      navigateToACPPage={() => goTo(StackLink.ACP)}
      navigateToChangePlan={() =>
        goTo(`${PlanLink.Plan}/${PlanLink.ChoosePlanFilter}`)
      }
    />
  ) : (
    <StreamOnNavigator />
  );
};
