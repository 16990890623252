import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ButtonLink, Accordion, AccordionGroup, Txt } from '@vst/beam';
import useNavigate from '@mfe/services/navigation';
import { AddOnCard } from '@mfe/features/add-ons';
import { Storage } from '@mfe/shared/util';
import { selectAddOns } from '@mfe/to-be-migrated/redux/addOns';

import styles from './index.module.scss';

const AddonsFooter = () => {
  const { t } = useTranslation('ShopAddons');
  const { goTo } = useNavigate();

  const { currentAddOns } = useSelector(selectAddOns);

  if (!currentAddOns?.length) {
    return null;
  }

  return (
    <div className={styles['wrapper']}>
      <div className={styles['container']}>
        <AccordionGroup>
          <Accordion
            title={<Txt variant="labelLarge">{t('myAddons.title')}</Txt>}
          >
            <div className={styles['addons-wrapper']}>
              <div className={styles['addons']}>
                {currentAddOns?.map((addOnOffer, index) => {
                  return (
                    <div key={index} className={styles['addons__item']}>
                      <AddOnCard
                        addOnName={addOnOffer.name}
                        addOnDescription={addOnOffer.description}
                        addOnState={addOnOffer.state}
                      />
                    </div>
                  );
                })}
              </div>
              <div className={styles['manage-addons-link']}>
                <ButtonLink
                  data-cy="manage-my-addons-link"
                  linkSize="medium"
                  onClick={(e) => {
                    e.preventDefault();
                    Storage.setItem('showInternetPlan', true);
                    goTo('Profile');
                  }}
                  arrowPos={'forward'}
                  href="#"
                  variant={'primary'}
                  label={t('myAddons.manageAddonsButtonLabel')}
                />
              </div>
            </div>
          </Accordion>
        </AccordionGroup>
      </div>
    </div>
  );
};

export default AddonsFooter;
