import { ServiceUnavailableError } from './ServiceUnavailableError';
import { ApplicationNotFoundError } from './ApplicationNotFoundError';
import { ApplicationExpiredError } from './ApplicationExpiredError';
import { DuplicateSubscriberError } from './DuplicateSubscriberError';
import { NotEligibleError } from './NotEligibleError';
import { ApplicationNotCompleteError } from './ApplicationNotCompleteError';
import { ApplicationPendingError } from './ApplicationPendingError';
import { ApplicationGeneralError } from './ApplicationGeneralError';

export enum ErrorTypes {
  GENERAL_ERROR = 'GENERAL_ERROR',
  SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE',
  NV_UNAVAILABLE = 'NV_UNAVAILABLE',
  VIASAT_SERVICE_UNAVAILABLE = 'VIASAT_SERVICE_UNAVAILABLE',
  APPLICATION_NOT_FOUND = 'APPLICATION_NOT_FOUND',
  APPLICATION_EXPIRED = 'APPLICATION_EXPIRED',
  DUPLICATE_SUBSCRIBER_NLAD = 'DUPLICATE_SUBSCRIBER_NLAD',
  NOT_ELIGIBLE_ACP = 'NOT_ELIGIBLE_ACP',
  APPLICATION_NOT_COMPLETE = 'APPLICATION_NOT_COMPLETE',
  APPLICATION_PENDING = 'APPLICATION_PENDING',
}

interface ErrorOutcomeProps {
  errorType: ErrorTypes | null;
  canRetry: boolean;
  tryAgain: () => void;
  goBackToOverviewPage: () => void;
  closeModal: () => void;
  openNationalVerifierInNewTab: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
}

export const ErrorOutcome = (props: ErrorOutcomeProps): JSX.Element => {
  const {
    errorType,
    canRetry,
    tryAgain,
    goBackToOverviewPage,
    closeModal,
    openNationalVerifierInNewTab,
  } = props;

  switch (errorType) {
    case ErrorTypes.SERVICE_UNAVAILABLE:
    case ErrorTypes.NV_UNAVAILABLE:
    case ErrorTypes.VIASAT_SERVICE_UNAVAILABLE:
      return (
        <ServiceUnavailableError
          tryAgain={tryAgain}
          goBackToOverviewPage={goBackToOverviewPage}
          canRetry={canRetry}
        />
      );
    case ErrorTypes.APPLICATION_NOT_FOUND:
      return (
        <ApplicationNotFoundError
          closeModal={closeModal}
          goBackToOverviewPage={goBackToOverviewPage}
        />
      );
    case ErrorTypes.APPLICATION_EXPIRED:
      return (
        <ApplicationExpiredError
          openNationalVerifierInNewTab={openNationalVerifierInNewTab}
          goBackToOverviewPage={goBackToOverviewPage}
        />
      );
    case ErrorTypes.DUPLICATE_SUBSCRIBER_NLAD:
      return (
        <DuplicateSubscriberError
          goBackToOverviewPage={goBackToOverviewPage}
          closeModal={closeModal}
        />
      );
    case ErrorTypes.NOT_ELIGIBLE_ACP:
      return (
        <NotEligibleError
          goBackToOverviewPage={goBackToOverviewPage}
          openNationalVerifierInNewTab={openNationalVerifierInNewTab}
        />
      );
    case ErrorTypes.APPLICATION_NOT_COMPLETE:
      return (
        <ApplicationNotCompleteError
          goBackToOverviewPage={goBackToOverviewPage}
          openNationalVerifierInNewTab={openNationalVerifierInNewTab}
        />
      );
    case ErrorTypes.APPLICATION_PENDING:
      return (
        <ApplicationPendingError goBackToOverviewPage={goBackToOverviewPage} />
      );
    default:
      return (
        <ApplicationGeneralError
          goBackToOverviewPage={goBackToOverviewPage}
          closeModal={closeModal}
        />
      );
  }
};
