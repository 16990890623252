import { FC } from 'react';
import { Check } from '@vst/beam-icons/icons';
import { useTranslation } from 'react-i18next';
import { StandAloneLink, Surface, Txt } from '@vst/beam';

import styles from './styles.module.scss';

type StepProps = {
  step: number;
  title: string;
  isValid?: boolean;
  children: React.ReactNode;
  handleEdit: () => void;
};

export const Step: FC<StepProps> = ({
  step,
  title,
  isValid,
  children,
  handleEdit,
}) => {
  const { t } = useTranslation('ShopAddons');
  return (
    <Surface
      variant={'primary'}
      pb="16px"
      pt="40px"
      style={{ borderBottom: '1px solid lightgrey' }}
    >
      <Surface variant={'primary'} className={styles.sectionHeader}>
        {isValid ? (
          <div className={styles.validIcon}>
            <Check width={16} color="white" />
          </div>
        ) : (
          <div className={styles.stepIcon}>
            <Txt variant={'bodySmallRegular'} color={'inherit'}>
              {step}
            </Txt>
          </div>
        )}
        <div className={styles.sectionHeaderTitle}>
          <Txt variant={'labelLarge'} color={'subtle'}>
            {title}
          </Txt>
          {isValid && (
            <StandAloneLink
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
                handleEdit();
              }}
              href={'#'}
            >
              {t('voice.edit')}
            </StandAloneLink>
          )}
        </div>
      </Surface>
      <Surface
        variant={'primary'}
        pl="40px"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        {children}
      </Surface>
    </Surface>
  );
};
