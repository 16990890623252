import { getDaysInMonth, isFuture } from 'date-fns';

import { DateOfBirth } from './types';

const digitsRegExp = new RegExp(/\d/);
const applicationIdRegExp = new RegExp(/^\w[\d]{5}-[\d]{5}/);
const APPLICATION_ID_LENGTH = 12;

export const hasDigits = (value: string) => digitsRegExp.test(value);

export const isApplicationIdValid = (value: string) =>
  applicationIdRegExp.test(value) && value.length === APPLICATION_ID_LENGTH;

export const isDateInvalid = (date: DateOfBirth) => {
  const year = Number(date.year);
  const month = Number(date.month);
  const day = Number(date.day);

  const daysInMonth = getDaysInMonth(new Date(year, month));

  return day > daysInMonth;
};

export const isDateInTheFuture = (date: DateOfBirth) => {
  const year = Number(date.year);
  const month = Number(date.month);
  const day = Number(date.day);

  const inputDate = new Date(year, month, day);
  return isFuture(inputDate);
};
