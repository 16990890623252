import { useScreenResolution } from '@mfe/shared/util';
import { Button } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import styles from '../BodyContent.module.scss';

interface EditableFooterProps {
  cancelOnClick: () => void;
  disabled?: boolean;
  saveOnClick?: () => void;
}

const EditableFooter = ({
  cancelOnClick,
  disabled,
}: EditableFooterProps): JSX.Element => {
  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobileView = isSmall || isExtraSmall;
  const { t } = useTranslation('NewProfile');
  return (
    <div className={styles['editableFooter']}>
      <Button
        buttonSize="medium"
        variant="primary"
        mb={isMobileView ? '16px' : undefined}
        disabled={disabled}
        type="submit"
      >
        {t('saveChanges')}
      </Button>
      <Button
        buttonSize="medium"
        variant="secondary"
        mr={!isMobileView ? '16px' : undefined}
        onClick={cancelOnClick}
      >
        {t('cancel')}
      </Button>
    </div>
  );
};
export default EditableFooter;
