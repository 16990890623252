import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Logo, EmptyState, Surface } from '@vst/beam';
import { ViasatLogoDefault } from '@vst/beam-icons/logos/viasat';

import { useScreenResolution } from '@mfe/shared/util';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';
import { Footer } from '@mfe/shared/components';

import { useToken } from '../Auth';
import { Description } from './empty-state-description';

import styles from './index.module.scss';

export const ServerError = ({
  canRetry = false,
}: {
  canRetry?: boolean;
}): JSX.Element => {
  const { t } = useTranslation('Home');

  const dispatch = useDispatch();

  const { logout } = useToken();
  const { isSmall, isExtraSmall } = useScreenResolution();

  const isMobile = isSmall || isExtraSmall;

  const VIASAT_PAGE_LINK = t('errorContent.logoLink');

  const isButtonDisabled = canRetry ? false : true;

  const openNewTab = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();

    dispatch(openInNewTab(VIASAT_PAGE_LINK));
  };

  const retryHandler = () => {
    window.location.reload();
  };

  return (
    <Surface className={styles['page-container']} variant="secondary">
      <Surface className={styles['header']} variant="primary">
        <a href={VIASAT_PAGE_LINK} onClick={openNewTab}>
          <Logo logo={ViasatLogoDefault} alt="Viasat" size="112px" />
        </a>
      </Surface>
      <Surface variant="secondary" className={styles['empty-state']}>
        <EmptyState
          description={<Description />}
          actions={{
            secondary: {
              label: t('errorContent.buttons.signOut'),
              onClick: logout,
              variant: 'secondary',
              minWidth: isMobile ? undefined : '200px',
            },
            primary: {
              label: t('errorContent.buttons.refresh'),
              onClick: retryHandler,
              variant: 'primary',
              minWidth: isMobile ? undefined : '200px',
              disabled: isButtonDisabled,
            },
          }}
          actionsLayout={isMobile ? 'vertical' : 'horizontal'}
        />
      </Surface>
      <Footer />
    </Surface>
  );
};
