export * from './Context';
export * from './Navigation';
export * from './Navigation/useParams';
export * from './Color';
export * from './webSafeSvg';

export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

// This does not have to create valid uuid v4 strings but it mostly does. Just need it for some svgs
// see https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
export const generateUniqueId = (): string =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      // eslint-disable-next-line eqeqeq
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
