import { raygun as _raygun } from './raygun';
import { Raygun, UserInfo } from './types';
import env from '../../env';

export const raygun: Raygun =
  env.env === 'DEV_LOCAL'
    ? {
        setUser: (_: UserInfo) => {
          // no-op
        },
      }
    : _raygun;

export * from './types';
