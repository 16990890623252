import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CheckMark } from '@vst/beam-icons/illustrative-icons';
import { Button, IllustrativeIcon, StandAloneLink, Txt } from '@vst/beam';

import { selectNetwork } from '@mfe/to-be-migrated/redux/network/networkSlice';

import styles from './styles.module.scss';

interface DiagnosticSuccessProps {
  navigateToDiagnosticReport: (e: React.MouseEvent<HTMLElement>) => void;
  runDiagnosticHandler: (e: React.MouseEvent<HTMLElement>) => void;
}
export const DiagnosticSuccess = ({
  navigateToDiagnosticReport,
  runDiagnosticHandler,
}: DiagnosticSuccessProps) => {
  const { t } = useTranslation('Network', { keyPrefix: 'diagnostic' });

  const { runDate } = useSelector(selectNetwork);

  return (
    <div className={styles['content']}>
      <IllustrativeIcon
        data-cy="CheckMark-Icon"
        name="CheckMark"
        icon={CheckMark}
        color="success_400"
        width={95}
        height={95}
      />

      <div className={styles['titleAndDescription']}>
        <Txt variant="heading5" mb="8px" data-cy="run-diagnostic-title">
          {t('success.title')}
        </Txt>
        <Txt
          variant="bodySmallRegular"
          color="subtle"
          data-cy="run-diagnostic-description"
        >
          {t('success.description')}
        </Txt>
      </div>
      <Button
        variant="secondary"
        fluid
        data-cy="run-diagnostic-button"
        style={{ maxWidth: '400px' }}
        onClick={runDiagnosticHandler}
      >
        {t('buttons.runNewDiagnostic')}
      </Button>
      <div className={styles['dateAndLink']}>
        <Txt component="span" variant="smallRegular">
          {runDate}
        </Txt>
        <StandAloneLink
          variant="primary"
          linkSize="small"
          href=""
          onClick={navigateToDiagnosticReport}
          data-cy="go-to-full-report"
          underline
        >
          {t('linkToDiagnosticReport')}
        </StandAloneLink>
      </div>
    </div>
  );
};
