import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { TFunction } from 'i18next';
import {
  CountryCode,
  isValidNumber,
  isValidPhoneNumber,
  parsePhoneNumber,
  PhoneNumber,
} from 'libphonenumber-js';
import { EditableCardType } from './components/Body/Content/EditableComponents/EditableCard';
import { removeSymbols } from './utils';

enum AddressComponents {
  STREET1 = 'StreetAddressLine1',
  STREET2 = 'StreetAddressLine2',
  CITY = 'City',
  STATE = 'State/Province',
  ZIPCODE = 'Zip/PostalCode',
}

interface ValidateAddressProps {
  addressType: EditableCardType.Billing | EditableCardType.Shipping;
  type: AddressComponents;
  input: string;
}

const PO_BOX = 'PO BOX';
const ZIPCODE_REGEX = /^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/;
const STATE_REGEX = /^([^0-9]\s?)*$/;
const CITY_REGEX = STATE_REGEX;
const EMAIL_REGEX = /^.+@.+\.[a-zA-Z]+$/;

export function validateAddress({
  addressType,
  type,
  input,
}: ValidateAddressProps): boolean {
  const trimmedInput = input.trim();
  if (trimmedInput.length === 0) return false;
  switch (type) {
    case AddressComponents.STREET1:
      return Boolean(
        addressType === EditableCardType.Shipping &&
          input.toUpperCase().includes(PO_BOX)
      );
    case AddressComponents.CITY:
      return Boolean(!CITY_REGEX.test(trimmedInput));
    case AddressComponents.STATE:
      return Boolean(!STATE_REGEX.test(trimmedInput));
    case AddressComponents.ZIPCODE:
      return Boolean(!ZIPCODE_REGEX.test(trimmedInput));
    case AddressComponents.STREET2:
    default:
      break;
  }
  return false;
}

//return true if input is valid
export function validateEmail(input: string): boolean {
  const emailTrim = input.trim();
  if (
    !EMAIL_REGEX.test(emailTrim) ||
    emailTrim.length === 0 ||
    emailTrim.length > 62
  )
    return false;
  else return true;
}

//return true if input is valid
export function validatePhone(
  rawPhoneInput: string,
  isPrimary: boolean,
  t: TFunction
): boolean {
  if (!rawPhoneInput || rawPhoneInput === t('noPhone')) {
    return isPrimary ? false : true;
  }
  if (isPrimary && removeSymbols(rawPhoneInput).length < 2) {
    return false;
  }
  const removeSymbolsPhoneNumber = removeSymbols(rawPhoneInput);
  let googleLibFormattedPhone,
    extractedCountryCode,
    extractedPhoneNumberWithoutSymbols = '';

  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const numberProto = phoneUtil.parse(removeSymbolsPhoneNumber, 'ZZ');
    googleLibFormattedPhone = phoneUtil.format(
      numberProto,
      PhoneNumberFormat.INTERNATIONAL
    );
    extractedCountryCode = googleLibFormattedPhone.substring(
      0,
      googleLibFormattedPhone.indexOf(' ')
    );
    extractedPhoneNumberWithoutSymbols = removeSymbols(
      googleLibFormattedPhone.substring(
        googleLibFormattedPhone.indexOf(' ') + 1
      )
    );
  } catch (e) {
    if (isPrimary) {
      return false;
    }
    extractedCountryCode = rawPhoneInput.substring(
      0,
      rawPhoneInput.indexOf(' ')
    );
    extractedPhoneNumberWithoutSymbols = removeSymbols(
      rawPhoneInput.substring(rawPhoneInput.indexOf(' ') + 1)
    );
  }
  if (extractedCountryCode.length < 2) {
    return isPrimary ? false : true;
  }
  if (extractedCountryCode === '+1') {
    return extractedPhoneNumberWithoutSymbols.length === 10;
  }
  if (extractedPhoneNumberWithoutSymbols.length < 1) {
    return false;
  }

  return true;
}
