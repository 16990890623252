import styles from './styles.module.scss';
import { Icon, Surface, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import {
  GooglePlayStoreButton,
  AppleAppStoreButton,
} from '@mfe/shared/components';
import { ViasatLogomarkColor } from '@vst/beam-icons/logos/viasat';
import { useSelector } from 'react-redux';
import { selectConfig } from '@mfe/shared/redux/config';
import { Platform } from '@mfe/shared/schema-types';

type Variant = 'default' | 'blue';

interface AdProps {
  variant?: Variant;
}

const MobileAppAd = ({ variant = 'default' }: AdProps): JSX.Element | null => {
  const { t } = useTranslation('Network', { keyPrefix: 'advertisement' });
  const { platform } = useSelector(selectConfig);
  if (platform !== Platform.Web) return null;

  const androidAppUrl = t('androidUrl');
  const iosAppUrl = t('iosUrl');

  return (
    <div className={`${styles['container']} ${styles[variant]}`}>
      <Surface
        p={'8px'}
        radius="8px"
        width={40}
        height={40}
        className={styles['logo-container']}
      >
        <Icon icon={ViasatLogomarkColor} height={21} width={21} />
      </Surface>
      <div className={styles['sub-container']}>
        <div className={styles['sub-container']}>
          <div className={styles['text-container']}>
            <Txt variant="heading6">{t('title')}</Txt>
            <Txt variant="smallRegular">{t('body')}</Txt>
          </div>
        </div>
        <div className={styles['buttons']}>
          <div style={{ marginRight: '16px' }}>
            <GooglePlayStoreButton url={androidAppUrl} />
          </div>
          <AppleAppStoreButton url={iosAppUrl} />
        </div>
      </div>
    </div>
  );
};

export default MobileAppAd;
