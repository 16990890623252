import React, { useRef } from 'react';
import {
  View,
  TouchableOpacity,
  Platform,
  StyleSheet,
  TextStyle,
} from 'react-native';
import { Text, Color } from '@mfe/legacy/andromeda';
import { useTranslation } from 'react-i18next';
import { SectionValue, FiltersProps, FilterProps } from './types';

const styles = StyleSheet.create({
  filter: {
    marginLeft: 48,
    marginVertical: 12.5,
    paddingTop: 2,
  },
  filterSelected: {
    borderColor: Color.teal500,
    borderLeftWidth: 4,
    paddingLeft: 16,
  },
  filterNotSelected: {
    paddingLeft: 20,
  },
});

const Filter = ({ title, handler, selected }: FilterProps): JSX.Element => {
  const { t } = useTranslation('FAQ');
  const textRef = useRef<Text>(null);

  /* Define dynamic hover props without performance impact
   * by using Direct Manipulation over State.
   */
  const onHover = { color: Color.teal600 };
  const regular = { color: Color.gray800 };
  const setStyle = (styles: TextStyle): void => {
    if (textRef && textRef.current) {
      textRef.current.setNativeProps({
        style: styles,
      });
    }
  };

  const webHoverProps =
    Platform.OS === 'web'
      ? {
          onMouseEnter: (): void => setStyle(onHover),
          onMouseLeave: (): void => setStyle(regular),
        }
      : {};

  return (
    <TouchableOpacity
      {...webHoverProps}
      onPress={(): void => handler()}
      testID={`${title}-Filter${selected ? 'Selected' : ''}`}
    >
      <View
        style={[
          styles.filter,
          selected ? styles.filterSelected : styles.filterNotSelected,
        ]}
      >
        <Text
          ref={textRef}
          bold={selected}
          style={{ lineHeight: 22 }}
          testID={`${title}-FilterText`}
        >
          {title === 'All' ? t(title) : title}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export const Filters = ({
  faqSelection,
  handleSelect,
  faqs,
  ...otherProps
}: FiltersProps): JSX.Element => {
  const { sections } = faqs ?? { sections: [] };
  const { section: selectedSection } = faqSelection;

  let faqSections: { title: string }[] = [...sections];
  if (sections[0].title !== 'All')
    faqSections = [{ title: 'All' }, ...sections];

  return (
    <View style={{ marginBottom: 72 }} testID="filters">
      {faqSections.map(
        ({ title }): JSX.Element => (
          <Filter
            title={title}
            handler={(): void =>
              handleSelect({
                section: title as SectionValue,
                resetQuestion: true,
              })
            }
            selected={selectedSection === title}
            key={title}
            {...otherProps}
          />
        )
      )}
    </View>
  );
};
