import { useDispatch } from 'react-redux';
import { Button, Surface, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { OpenInNew, Phone } from '@vst/beam-icons/icons';

import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';
import { useScreenResolution } from '@mfe/shared/util';
import { setIsRebootModemModalOpen } from '@mfe/to-be-migrated/redux/network';

import { DiagnosticResultProps } from '.';
import styles from './styles.module.scss';

const ErrorButtons = ({ name }: { name: string }): JSX.Element | null => {
  const { t } = useTranslation('Network');
  const dispatch = useDispatch();

  const buttonType = name === 'DishAlignment' ? 'primary' : 'secondary';
  const { isSmall, isExtraSmall } = useScreenResolution();

  const isMobile = isSmall || isExtraSmall;

  switch (name) {
    case 'WifiModem':
      return (
        <Button
          buttonSize="small"
          style={{ whiteSpace: 'nowrap', minWidth: 180 }}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            dispatch(setIsRebootModemModalOpen(true));
          }}
        >
          {t('DiagnosticErrors.callToActions.rebootMyModem')}
        </Button>
      );
    case 'DishAlignment':
    case 'DishToModemConnection':
      if (!isMobile) return null;
      return (
        <Button
          icon={Phone}
          variant={buttonType}
          style={{ whiteSpace: 'nowrap', minWidth: 180 }}
          buttonSize="small"
          onClick={() =>
            dispatch(openInNewTab(`tel:${t('DiagnosticErrors.supportNumber')}`))
          }
        >
          {t('DiagnosticErrors.callToActions.callCustomerCare')}
        </Button>
      );
    case 'ViasatNetwork':
      if (!isMobile) return null;
      return (
        <Button
          variant="secondary"
          icon={Phone}
          buttonSize="small"
          style={{ whiteSpace: 'nowrap', minWidth: 180 }}
          onClick={() =>
            dispatch(openInNewTab(`tel:${t('DiagnosticErrors.outageNumber')}`))
          }
        >
          {t('DiagnosticErrors.callToActions.callOutageLine')}
        </Button>
      );
    case 'CurrentWeatherStatus':
      return (
        <Button
          icon={OpenInNew}
          iconPos="right"
          buttonSize="small"
          variant="secondary"
          style={{ whiteSpace: 'nowrap', minWidth: 180 }}
          onClick={() =>
            dispatch(openInNewTab(t('DiagnosticErrors.weatherOutageUrl')))
          }
        >
          {t('DiagnosticErrors.callToActions.learnMore')}
        </Button>
      );
    default:
      return null;
  }
};

const DiagnosticRemedyCard = ({ name }: DiagnosticResultProps) => {
  const { t } = useTranslation('Network');
  const showSupportNumber =
    name === 'DishToModemConnection' || name === 'DishAlignment';
  const showOutageNumber = name === 'ViasatNetwork';
  const testName = `diagnostics-help-${name}`;
  return (
    <Surface
      className={styles['diagnosticErrorBody']}
      variant="secondary"
      p="16px"
      radius="8px"
      data-cy={testName}
    >
      <div style={{ justifyItems: 'flex-start' }}>
        <Txt variant="bodySmallBold">{t(`DiagnosticErrors.${name}.title`)}</Txt>
        <Txt variant="bodySmallRegular" component="span">
          {t(`DiagnosticErrors.${name}.body`)}
        </Txt>
        <Txt
          variant="bodySmallBold"
          component="span"
          style={{ whiteSpace: 'nowrap' }}
        >
          {showSupportNumber && t(`DiagnosticErrors.supportDisplayNumber`)}
          {showOutageNumber && t(`DiagnosticErrors.outageDisplayNumber`)}
        </Txt>
      </div>

      <div className={styles['cta']}>
        <ErrorButtons name={name} />
      </div>
    </Surface>
  );
};

export default DiagnosticRemedyCard;
