import React from 'react';
import { Platform } from 'react-native';

enum ScrollBehavior {
  smooth = 'smooth',
  auto = 'auto',
}

export const ScrollTarget = React.createContext(
  Platform.OS === 'web'
    ? (x = 0, y = 0, animated = false) =>
        window.scrollTo({
          top: y,
          left: x,
          behavior: animated ? ScrollBehavior.smooth : ScrollBehavior.auto,
        })
    : () => console.error('No Ref Set!')
);
