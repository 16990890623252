import { useEffect } from 'react';
import { useNavigate } from '@mfe/legacy/andromeda';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { Platform } from '@mfe/shared/schema-types';
import {
  invoiceLoaded,
  setPopUpBlocked,
} from '@mfe/to-be-migrated/redux/billingInfo';
import {
  UsageEventTypes,
  microFrontendMessageHandler,
  GeneralEventTypes,
  WindowMessageData,
  UpdateStatusHandlerProps,
} from '../services/microFrontend';
import { useToken } from '../views/Auth';
import {
  useRocketChatContext,
  errorLogger,
  useTrackEventFunction,
} from '../containers';
import { UPDATE_NOTIFICATION_SUBSCRIPTION } from '../queries/profile';
import { PlanLink } from '../utils/Navigation';
import packageJson from '../myversion.json';
import { useGetPushNotificationStatus } from './Profile/utils';
import { getAuthVars } from './Auth/utils';
import { externalLink, useAccountStatus } from '../utils';
import { sendMessageToMobileApp } from '@mfe/shared/cross-platform-events/src';

export const useMessageHandler = (ref?: React.MutableRefObject<any>) => {
  const goTo = useNavigate();
  const pushNotificationStatus = useGetPushNotificationStatus();
  const { maximizeWidget } = useRocketChatContext();
  const { logout, token } = useToken();
  const { platform, env: mvEnv, mfeEnv } = getAuthVars();
  const dispatch = useDispatch();

  const { accountReference } = useAccountStatus();
  const trackEvent = useTrackEventFunction();
  const logger = errorLogger();
  const [updateSubscription] = useMutation(UPDATE_NOTIFICATION_SUBSCRIPTION);
  const pushNotificationProps: UpdateStatusHandlerProps = {
    accountReference,
    trackEvent,
    logger,
    updateSubscription,
  };

  const eventHandlerMethods = {
    logout,
    goTo,
    token,
    maximizeWidget,
    sendMVClientInfo: () => {
      ref?.current?.sendMFEmessage({}, GeneralEventTypes.SetOrigin);
      ref?.current?.sendMFEmessage(
        {
          platform,
          env: mvEnv,
          mfeEnv,
          version: packageJson.version,
        },
        UsageEventTypes.LaunchDarklyConfig
      );
      ref?.current?.sendMFEmessage(
        pushNotificationStatus,
        GeneralEventTypes.PushNotification
      );
    },
    sendToken: () => {
      ref?.current?.sendMFEmessage(
        { token: token?.accessToken },
        UsageEventTypes.SendToken
      );
    },
    sendMFEmessage: (message: any, type: string) =>
      ref?.current?.sendMFEmessage(message, type),
    statementLinkEndLoading: (
      invoiceNumber?: string | null,
      popupMessage?: boolean
    ) => {
      invoiceNumber && dispatch(invoiceLoaded(invoiceNumber));
      popupMessage && dispatch(setPopUpBlocked(popupMessage));
    },
    updatePushNotificationStatusProps: pushNotificationProps,
    trackChangePlanEvent: () => {
      goTo(PlanLink.ChoosePlanFilter, { append: true });
    },
    copyText: (text: string) => {
      window.navigator.clipboard.writeText(text);
    },
    scrollTop: () => {
      window?.scrollTo({ top: 0 });
    },
    openInNewTab: (link: string) => {
      if (platform === Platform.Web) {
        externalLink(link);
      } else {
        dispatch(
          sendMessageToMobileApp({
            type: 'open-in-new-tab',
            data: { url: link },
          })
        );
      }
    },
  };

  useEffect(() => {
    const handler = microFrontendMessageHandler(eventHandlerMethods);
    const handleMessage = (event: MessageEvent<WindowMessageData>) =>
      handler(event.data);

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
};
