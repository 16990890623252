import React from 'react';
import { Animated, View } from 'react-native';
import { DrawerContext } from '../../utils';
import { TransitionProps } from './types';
import { getTransitionSequence } from './utils';
export * from './types';

export class Transition extends React.Component<TransitionProps> {
  private root: View | undefined | null;

  setNativeProps(args: Record<string, any>): void | null | undefined {
    return this.root && this.root.setNativeProps(args);
  }

  override render(): JSX.Element {
    const {
      children,
      primary = false,
      secondary = false,
      style,
      ...props
    } = this.props;
    const sequence = getTransitionSequence(primary, secondary);

    return (
      <DrawerContext.Consumer>
        {(value: DrawerContext): JSX.Element => {
          const { primaryTransition, secondaryTransition } = value;
          const opacity =
            sequence === 'primary' ? primaryTransition : secondaryTransition;

          return (
            <Animated.View style={[{ opacity }, style]} {...props}>
              {children}
            </Animated.View>
          );
        }}
      </DrawerContext.Consumer>
    );
  }
}
