/* global __DEV__ */
import React, { useState, useEffect, useContext } from 'react';
import { Animated, Easing } from 'react-native';
import { useHistory } from 'react-router';
import {
  DrawerContainer,
  DrawerContent,
} from '../../Navigation/AnimateWebDrawer';
import { useDimensionContext } from '../DimensionContext';
import { Color } from '../../Color';

interface DrawerProvider {
  children: React.ReactNode;
}

type Routes = Record<string, JSX.Element> | null;

export interface DrawerContext {
  isVisible: boolean;
  isDrawerLocked: boolean;
  primaryTransition: Animated.Value;
  secondaryTransition: Animated.Value;
  currentRoute: string | undefined;
  webDrawerRoutes: Routes;
  urlPrefix?: string;
  setCloseIconColor(color?: Color | string): void;
  setDrawer(route?: string): void;
  setWebDrawerRoutes(input: Routes): void;
  lockDrawer(): void;
  unlockDrawer(): void;
  transitionDrawer(): void;
}

export const DrawerContext = React.createContext<DrawerContext>({
  isVisible: false,
  isDrawerLocked: false,
  primaryTransition: new Animated.Value(1),
  secondaryTransition: new Animated.Value(0),
  currentRoute: undefined,
  webDrawerRoutes: null,
  setCloseIconColor: (): void => {
    if (__DEV__)
      console.error('Remember to use WebDrawerProvider for accessing context.');
  },
  setDrawer: (): void => {
    if (__DEV__)
      console.error('Remember to use WebDrawerProvider for accessing context.');
  },
  setWebDrawerRoutes: (): void => {
    if (__DEV__)
      console.error('Remember to use WebDrawerProvider for accessing context.');
  },
  lockDrawer: (): void => {
    if (__DEV__)
      console.error('Remember to use WebDrawerProvider for accessing context.');
  },
  unlockDrawer: (): void => {
    if (__DEV__)
      console.error('Remember to use WebDrawerProvider for accessing context.');
  },
  transitionDrawer: (): void => {
    if (__DEV__)
      console.error('Remember to use WebDrawerProvider for accessing context.');
  },
});

export const WebDrawerProvider = ({
  children,
}: DrawerProvider): JSX.Element => {
  const { screenSize } = useDimensionContext();
  const history = useHistory();
  const [isVisible, setInContextVisibility] = useState<boolean>(false);
  const [currentRoute, setInContextCurrentRoute] = useState<
    string | undefined
  >();
  const [webDrawerRoutes, setInContextRoutes] = useState<Routes>(null);
  const [isDrawerLocked, setInContextLock] = useState<boolean>(false);
  const [primaryTransition] = useState<Animated.Value>(new Animated.Value(1));
  const [secondaryTransition] = useState<Animated.Value>(new Animated.Value(0));
  const [transitionDirection, setTransitionDirection] =
    useState<boolean>(false);
  const [closeIconColor, setCloseXIconColor] = useState<string | undefined>(
    undefined
  );

  const setDrawer = (route?: string): void => {
    setInContextVisibility(route !== undefined);
    setInContextCurrentRoute(
      // eslint-disable-next-line no-prototype-builtins
      route && webDrawerRoutes && webDrawerRoutes.hasOwnProperty(route)
        ? route
        : undefined
    );
    if (route === undefined) unlockDrawer();
  };

  const setCloseIconColor = (color?: Color | string): void => {
    setCloseXIconColor(color);
  };

  const setWebDrawerRoutes = (inputRoutes: Routes): void => {
    setInContextRoutes(inputRoutes);
  };

  const lockDrawer = (): void => setInContextLock(true);

  const unlockDrawer = (): void => setInContextLock(false);

  const transitionDrawer = (): void => {
    Animated.stagger(50, [
      Animated.timing(primaryTransition, {
        toValue: transitionDirection ? 1 : 0,
        duration: 300,
        easing: Easing.quad,
        useNativeDriver: true,
      }),
      Animated.timing(secondaryTransition, {
        toValue: transitionDirection ? 0 : 1,
        duration: 100,
        easing: Easing.quad,
        useNativeDriver: true,
      }),
    ]).start((): void => setTransitionDirection(!transitionDirection));
  };

  useEffect((): (() => void) => {
    if (history && history.location && history.location.state) {
      const { priorRoute, currentRoute, component, params } = history.location
        .state as any;
      if (isVisible && screenSize < 2) {
        history.push({
          pathname: currentRoute,
          state: { priorRoute, currentRoute, component, params },
        });
      } else if (isVisible && screenSize > 1) {
        history.push({
          pathname: priorRoute,
          state: { priorRoute, currentRoute, component, params },
        });
      }
    }
    const defaultDrawer = (): void => {
      setInContextVisibility(false);
      setInContextCurrentRoute(undefined);
    };
    window.addEventListener('popstate', defaultDrawer);
    return (): void => {
      window.removeEventListener('popstate', defaultDrawer);
    };
  }, [screenSize]);

  return (
    <DrawerContext.Provider
      value={{
        isVisible,
        isDrawerLocked,
        primaryTransition,
        secondaryTransition,
        currentRoute,
        webDrawerRoutes,
        setCloseIconColor,
        setDrawer,
        setWebDrawerRoutes,
        lockDrawer,
        unlockDrawer,
        transitionDrawer,
      }}
    >
      <DrawerContainer closeIconColor={closeIconColor}>
        <DrawerContent />
      </DrawerContainer>
      {/* Changed overflow from auto to undefined to enable sticky positioning in child elements
       */}
      <div style={{ overflow: isVisible ? 'scroll' : undefined }}>
        {children}
      </div>
    </DrawerContext.Provider>
  );
};

export const useWebDrawer = (): DrawerContext =>
  useContext<DrawerContext>(DrawerContext);
