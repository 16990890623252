import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Surface } from '@vst/beam';

import { Query } from '@mfe/shared/schema-types';
import { NetworkLink } from '@mfe/legacy/mv/utils/Navigation';
import {
  MobileProfileButton,
  PageAlert,
  wasAlertDismissed,
} from '@mfe/shared/components';
import { setModemStatus } from '@mfe/to-be-migrated/redux/network';
import { selectAlerts } from '@mfe/to-be-migrated/redux/alerts';
import { useScreenResolution } from '@mfe/shared/util';
import { GET_MODEM_STATUS } from '@mfe/to-be-migrated/redux/network/queries-and-mutations';

import RebootModemModal from '../reboot-modem-modal';
import MobileAppAd from './mobile-app-ad';
import SpeedTest from './speed-test';
import MobileHelp from './mobile-help';
import NetworkArticleCard from './article-card';
import { RebootModemCard } from './reboot-modem-card';
import { CommonQuestions } from './common-questions';
import { RunDiagnosticCard } from './run-diagnostic';

import styles from './styles.module.scss';

const NetworkHome = () => {
  const { isLarge, isExtraLarge } = useScreenResolution();
  const isMediumOrSmaller = !isLarge && !isExtraLarge;

  const { t } = useTranslation('Error');
  const dispatch = useDispatch();

  const { loading: loadingModemStatus } = useQuery<Query>(GET_MODEM_STATUS, {
    notifyOnNetworkStatusChange: true,
    variables: { refetchData: true },
    fetchPolicy: 'network-only',

    onCompleted: (data) => {
      if (data?.getModemStatus) {
        dispatch(setModemStatus(data.getModemStatus.isOnline));
      }
    },
  });

  const { alerts: allAlerts } = useSelector(selectAlerts);

  const alerts = allAlerts.filter(
    (alert) =>
      alert.type === 'global' ||
      alert.caption === t('Alerts:AccountStatus.error.caption') ||
      alert.caption === t('Alerts:AccountStatus.transitionError.caption')
  );

  return (
    <>
      <MobileProfileButton />
      <RebootModemModal
        redirectRoute={`${NetworkLink.Network}/${NetworkLink.Reboot}`}
      />

      <div data-cy="network-page-container" className={styles['layout']}>
        {!!alerts.length && (
          <Surface variant="secondary" className={styles['alertContainer']}>
            {alerts
              .filter((alert) => !wasAlertDismissed(alert))
              .map((alert, i) => (
                <PageAlert key={i} alert={alert} />
              ))}
          </Surface>
        )}

        <div className={styles['content']}>
          <div className={styles['runDiagnostics']}>
            <RunDiagnosticCard />
            <MobileHelp />
          </div>
          <div className={styles.commonQuestions}>
            <CommonQuestions />
          </div>
          <div className={styles['infoContainer']}>
            <div className={styles['helpCards']}>
              <SpeedTest />
              <RebootModemCard loadingModemStatus={loadingModemStatus} />
            </div>
            <NetworkArticleCard />
            {!isMediumOrSmaller && <MobileAppAd variant="blue" />}
          </div>
          {isMediumOrSmaller && <MobileAppAd variant="blue" />}
        </div>
      </div>
    </>
  );
};

export default NetworkHome;
