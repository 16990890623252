import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  IllustrativeIcon,
  InlineLink,
  StandAloneLink,
  Txt,
} from '@vst/beam';
import {
  TypesofWeather,
  WifiModem,
  GeneralSatellite,
  Alert,
  HomeSatellite,
} from '@vst/beam-icons/illustrative-icons';
import { OpenInNew, Phone } from '@vst/beam-icons/icons';
import { TFunction } from 'i18next';

import {
  selectNetwork,
  setIsRebootModemModalOpen,
} from '@mfe/to-be-migrated/redux/network/networkSlice';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';
import { Assessment, AssessmentName } from '@mfe/shared/schema-types';

import styles from './styles.module.scss';

interface DiagnosticIssuesProps {
  navigateToDiagnosticReport: (e: React.MouseEvent<HTMLElement>) => void;
  runDiagnosticHandler: (e: React.MouseEvent<HTMLElement>) => void;
  networkIssues: Assessment[];
}

export const NetworkIssues = ({
  navigateToDiagnosticReport,
  runDiagnosticHandler,
  networkIssues,
}: DiagnosticIssuesProps) => {
  const { t } = useTranslation('Network', { keyPrefix: 'diagnostic' });
  const dispatch = useDispatch();

  const phoneHref = `tel:${t('callCustomerSupportPhoneNumber')}`;

  const { runDate } = useSelector(selectNetwork);

  const openModal = () => dispatch(setIsRebootModemModalOpen(true));

  const currentIssue = getIssue(
    networkIssues,
    navigateToDiagnosticReport,
    openModal,
    t
  );

  const openLinkInNewTab = (e: React.MouseEvent<HTMLElement>, url: string) => {
    e.preventDefault();
    dispatch(openInNewTab(url));
  };

  const buttonIconPosition =
    currentIssue?.buttonText === t('buttons.callCare') ? 'left' : 'right';

  const openInNewLocation =
    currentIssue?.buttonText === t('buttons.callCare') ? false : true;

  if (!currentIssue) return null;

  return (
    <div className={styles['card']}>
      <div className={styles['content']}>
        <IllustrativeIcon
          data-cy={`${currentIssue.dataCy}-icon`}
          name="CheckMark"
          icon={currentIssue.cardIcon}
          color="alert_400"
          width={100}
          height={100}
        />
        <div className={styles['titleAndDescription']}>
          <Txt
            variant="heading5"
            mb="8px"
            data-cy={`${currentIssue.dataCy}-title`}
          >
            {currentIssue.title}
          </Txt>
          <Txt
            variant="bodySmallRegular"
            color="subtle"
            data-cy={`${currentIssue.dataCy}-description`}
          >
            {currentIssue.description}
          </Txt>
        </div>

        <div className={styles['buttons']}>
          {currentIssue.href ? (
            <InlineLink
              href={currentIssue.href}
              openInNewLocation={openInNewLocation}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                if (currentIssue.href === phoneHref) {
                  return;
                }
                openLinkInNewTab(e, currentIssue.href);
              }}
            >
              <Button
                variant="primary"
                fluid
                data-cy={`${currentIssue.dataCy}-first-button`}
                style={{ maxWidth: '400px' }}
                mb="16px"
                icon={currentIssue.buttonIcon}
                iconPos={buttonIconPosition}
              >
                {currentIssue.buttonText}
              </Button>
            </InlineLink>
          ) : (
            <Button
              variant="primary"
              fluid
              data-cy={`${currentIssue.dataCy}-first-button`}
              style={{ maxWidth: '400px' }}
              mb="16px"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                if (!currentIssue.buttonHandler) {
                  return;
                }
                currentIssue.buttonHandler(e);
              }}
            >
              {currentIssue.buttonText}
            </Button>
          )}
          <Button
            variant="secondary"
            fluid
            data-cy="run-new-diagnostic-button"
            style={{ maxWidth: '400px' }}
            onClick={runDiagnosticHandler}
          >
            {t('buttons.runNewDiagnostic')}
          </Button>
        </div>
        <div className={styles['dateAndLink']}>
          <Txt component="span" variant="smallRegular" data-cy="run-date">
            {runDate}
          </Txt>
          <StandAloneLink
            variant="primary"
            href=""
            data-cy="go-to-full-report"
            onClick={navigateToDiagnosticReport}
            linkSize="small"
            underline
          >
            {t('linkToDiagnosticReport')}
          </StandAloneLink>
        </div>
      </div>
    </div>
  );
};

const getIssue = (
  networkIssues: Assessment[],
  navigateToDiagnosticReport: (e: React.MouseEvent<HTMLElement>) => void,
  openModal: () => void,
  t: TFunction
) => {
  const phoneHref = `tel:${t('callCustomerSupportPhoneNumber')}`;

  if (networkIssues.length > 1) {
    return {
      title: t('multipleErrorsIssue.title'),
      description: t('multipleErrorsIssue.description'),
      buttonText: t('buttons.diagnosticReport'),
      buttonHandler: navigateToDiagnosticReport,
      cardIcon: Alert,
      dataCy: 'viasatNetwork-issue',
      buttonIcon: undefined,
      href: '',
    };
  }

  switch (networkIssues[0].name) {
    case AssessmentName.ServiceConfiguration:
      return {
        title: t('accountErrorIssue.title'),
        description: t('accountErrorIssue.description'),
        buttonText: t('buttons.callCare'),
        buttonHandler: null,
        cardIcon: Alert,
        dataCy: 'account-issue',
        buttonIcon: Phone,
        href: phoneHref,
      };

    case AssessmentName.WifiModem:
      return {
        title: t('modemIssue.title'),
        description: t('modemIssue.description'),
        buttonText: t('buttons.rebootModem'),
        buttonHandler: openModal,
        cardIcon: WifiModem,
        dataCy: 'modem-issue',
        buttonIcon: undefined,
        href: '',
      };

    case AssessmentName.DishAlignment:
      return {
        title: t('dishAlignmentIssue.title'),
        description: t('dishAlignmentIssue.description'),
        buttonText: t('buttons.callCare'),
        buttonHandler: null,
        cardIcon: HomeSatellite,
        dataCy: 'dish-alignment-issue',
        buttonIcon: Phone,
        href: phoneHref,
      };

    case AssessmentName.DishToModemConnection:
      return {
        title: t('dishToModemIssue.title'),
        description: t('dishToModemIssue.description'),
        buttonText: t('buttons.callCare'),
        buttonHandler: null,
        cardIcon: HomeSatellite,
        dataCy: 'dish-to-modem-issue',
        buttonIcon: Phone,
        href: phoneHref,
      };

    case AssessmentName.ViasatNetwork:
      return {
        title: t('viasatNetworkIssue.title'),
        description: t('viasatNetworkIssue.description'),
        buttonText: t('buttons.viasatNetworkIssues'),
        buttonHandler: null,
        cardIcon: GeneralSatellite,
        dataCy: 'viasatNetwork-issue',
        buttonIcon: OpenInNew,
        href: t('viasatNetworkIssue.link'),
      };

    case AssessmentName.CurrentWeatherStatus:
      return {
        title: t('weatherIssue.title'),
        description: t('weatherIssue.description'),
        buttonText: t('buttons.weatherIssues'),
        buttonHandler: null,
        cardIcon: TypesofWeather,
        dataCy: 'weather-issue',
        buttonIcon: OpenInNew,
        href: t('weatherIssue.link'),
      };
  }
};
