import { useTranslation } from 'react-i18next';

import { Surface, Spinner, Txt } from '@vst/beam';

import GradientCard from '../gradient-card';

import styles from './styles.module.scss';

export const Loading = () => {
  const { t } = useTranslation('UpdateBillCycle');

  return (
    <GradientCard>
      <Surface className={styles['content']} data-cy="spinner">
        <Spinner
          size="medium"
          label={
            <Txt variant="bodyLargeBold" color="subtle">
              {t('loading')}
            </Txt>
          }
        />
      </Surface>
    </GradientCard>
  );
};
