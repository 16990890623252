import { colors } from '@vst/beam';

type Props = {
  dataCy?: string;
  className?: string;
};

const Card = ({
  children,
  dataCy,
  className,
}: React.PropsWithChildren<Props>): JSX.Element => {
  return (
    <div
      style={{
        display: 'block',
        borderWidth: 0,
        borderColor: colors['gray'][200],
        borderStyle: 'solid',
        overflow: 'hidden',
      }}
      data-cy={dataCy}
      className={className}
    >
      {children}
    </div>
  );
};

export default Card;
