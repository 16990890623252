import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@vst/beam-icons/illustrative-icons';
import { IllustrativeIcon, Surface, Txt, Button, InlineLink } from '@vst/beam';

import { selectConfig } from '@mfe/shared/redux/config';
import { Platform } from '@mfe/shared/schema-types';
import { useScreenResolution } from '@mfe/shared/util';
import { setError } from '@mfe/to-be-migrated/redux/changePlan';

import { Container } from '@mfe/shared/components';
import styles from './SubmitError.module.scss';
import { NavigationHandlers } from '../../types';

export const SubmitError = ({
  navigateToOverview,
}: Pick<NavigationHandlers, 'navigateToOverview'>) => {
  const { t } = useTranslation('NewChangePlan');

  const { isExtraSmall, isSmall } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;
  const dispatch = useDispatch();
  const { platform } = useSelector(selectConfig);
  const hrefLink = `tel:${t('orderError.phoneNumber')}`;

  const goToOverview = () => {
    if (platform === Platform.Web) {
      dispatch(setError(null));
    }

    navigateToOverview();
  };

  return (
    <Container>
      <Surface
        className={styles['container']}
        radius={isMobile ? '0px' : '16px'}
        my={{ xs: undefined, sm: '72px' }}
      >
        <div data-cy="Alert-Icon">
          <IllustrativeIcon
            name="Alert"
            icon={Alert}
            color="alert_400"
            width={95}
            height={95}
          />
        </div>

        <Surface
          px={{ xs: '16px', sm: '24px', md: '40px' }}
          mt="48px"
          mb="40px"
        >
          <Txt data-cy="orderError-title" variant="heading4" mb="12px">
            {t('orderError.title')}
          </Txt>
          <Txt
            data-cy="orderError-description"
            variant="bodyLargeRegular"
            color="subtle"
          >
            {t('orderError.description')}{' '}
            {isMobile ? (
              <InlineLink
                className={styles['phoneNumber']}
                href={hrefLink}
                data-cy="orderError-phoneNumber-mobileView"
              >
                {t('orderError.phoneNumber')}
              </InlineLink>
            ) : (
              <Txt
                component="span"
                data-cy="orderError-phoneNumber-desktopView"
                style={{ fontWeight: 'bold' }}
              >
                {t('orderError.phoneNumber')}
              </Txt>
            )}
          </Txt>
          <Button
            className={styles['backToMyViasat']}
            data-cy="orderError-button"
            fluid
            onClick={goToOverview}
            mt="40px"
          >
            {t('orderError.button')}
          </Button>
        </Surface>
      </Surface>
    </Container>
  );
};
