import { StyleSheet } from 'react-native';
import { Color } from '@mfe/legacy/andromeda';

const styles = StyleSheet.create({
  defaultAccordion: {
    marginHorizontal: 72,
    marginBottom: 48,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  marginStyle: {
    marginBottom: 8,
    maxWidth: '100%',
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 10,
    shadowOpacity: 0.2,
    elevation: 5,
  },
  moreHelp: {
    color: Color.blue600,
  },
  rightContent: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    marginVertical: 72,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  sectionTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 32,
    marginBottom: 16,
    alignItems: 'center',
  },
  smallAccordion: {
    marginHorizontal: 32,
    marginBottom: 48,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  smallClosed: {
    paddingTop: 24,
    paddingBottom: 18,
    alignItems: 'flex-start',
  },
  smallQuestion: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  loading: {
    marginTop: 24,
    marginBottom: 28,
    alignSelf: 'baseline',
  },
  modalButtonStyle: {
    margin: 12,
    width: 232,
    height: 45,
    alignSelf: 'center',
  },
});

export default styles;
