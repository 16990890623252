import {
  IRawStandardModalProps,
  StandardModal as BeamStandardModal,
} from '@vst/beam';
import { useSelector } from 'react-redux';

import { selectConfig } from '@mfe/shared/redux/config';
import { Platform } from '@mfe/shared/schema-types';
import styles from './styles.module.scss';

type StandardModalProps = Omit<IRawStandardModalProps, 'ref'> &
  React.RefAttributes<HTMLElement>;

export const CustomStandardModal = (props: StandardModalProps) => {
  const { platform } = useSelector(selectConfig);

  // mobileModal class applies a bottom margin to the modal on mobile devices
  // to ensure the CTAs are visible alongside the mobile bottom navbar
  const className = `${props.surfaceProps?.className ?? ''} ${
    styles['mobileModal']
  }`;

  return platform === Platform.Web ? (
    <BeamStandardModal {...props} />
  ) : (
    <BeamStandardModal {...props} surfaceProps={{ className }} />
  );
};
