import { TextField } from '@vst/beam';
import { MutableRefObject, useMemo, useRef, useState } from 'react';
import { EditableCardType } from './EditableCard';
import { useTranslation } from 'react-i18next';
import { addressInputs } from '../../../../utils';
import { validateAddress } from '../../../../validation';

interface InputEdits {
  ref: MutableRefObject<any>;
  value: string;
  onChange(): void;
}

interface AddressValidationComponentProps {
  currentAddress: [string, string, string, string, string];
  addressType: EditableCardType.Billing | EditableCardType.Shipping;
  disabledInputs: boolean;
  errorArray: string[];
  matchAddress: string[];
}

interface AddressValidationComponentPayload {
  newAddress: {
    addressLines: string[];
    municipality: string;
    postalCode: string;
    region: string;
  };
  isValidAddress: boolean;
  newElements: JSX.Element[];
  disableSaveButton: boolean;
}

export const AddressValidationComponent = ({
  currentAddress,
  addressType,
  disabledInputs,
  errorArray,
  matchAddress,
}: AddressValidationComponentProps): AddressValidationComponentPayload => {
  const street1Ref: MutableRefObject<any> = useRef(null);
  const [street1Value, setStreet1Value] = useState(currentAddress[0]);
  const street1OnChange = () => {
    setStreet1Value(street1Ref.current.value);
  };

  const street2Ref: MutableRefObject<any> = useRef<any>(null);
  const [street2Value, setStreet2Value] = useState(currentAddress[1]);
  const street2OnChange = () => {
    setStreet2Value(street2Ref.current.value);
  };

  const cityRef: MutableRefObject<any> = useRef(null);
  const [cityValue, setCityValue] = useState(currentAddress[2]);
  const cityOnChange = () => {
    setCityValue(cityRef.current.value);
  };

  const stateRef: MutableRefObject<any> = useRef(null);
  const [stateValue, setStateValue] = useState(currentAddress[3]);
  const stateOnChange = () => {
    setStateValue(stateRef.current.value);
  };

  const zipcodeRef: MutableRefObject<any> = useRef(null);
  const [zipcodeValue, setZipcodeValue] = useState(currentAddress[4]);
  const zipcodeOnChange = () => {
    setZipcodeValue(zipcodeRef.current.value);
  };

  const inputEditsArray: InputEdits[] = [
    { ref: street1Ref, value: street1Value, onChange: street1OnChange },
    { ref: street2Ref, value: street2Value, onChange: street2OnChange },
    { ref: cityRef, value: cityValue, onChange: cityOnChange },
    { ref: stateRef, value: stateValue, onChange: stateOnChange },
    { ref: zipcodeRef, value: zipcodeValue, onChange: zipcodeOnChange },
  ];

  const disableSaveButton =
    street1Value.trim() === '' ||
    cityValue.trim() === '' ||
    stateValue.trim() === '' ||
    zipcodeValue.trim() === '';

  const { t } = useTranslation('NewProfile');

  const elements = addressInputs.map(
    ({ type, name, label, required }, index) => {
      const showErrorString = validateAddress({
        addressType: addressType,
        type: type,
        input: inputEditsArray[index].value,
      });
      return {
        element: (
          <TextField
            key={`address-input-${name}`}
            id={name}
            name={name}
            labelProps={{ labelText: t(label) }}
            placeholder={disabledInputs ? matchAddress[index] : undefined}
            ref={inputEditsArray[index].ref}
            required={required}
            fluid={true}
            disabled={disabledInputs}
            mb={name === 'Zip/PostalCodeInput' ? '24px' : '16px'}
            error={showErrorString ? errorArray[index] : ''}
            onChange={inputEditsArray[index].onChange}
            value={
              disabledInputs
                ? matchAddress[index]
                : inputEditsArray[index].value
            }
          />
        ),
        error: showErrorString,
      };
    }
  );

  const newAddress = useMemo(() => {
    const newAddressLines = [];
    newAddressLines.push(street1Value);
    newAddressLines.push(street2Value ?? '');

    return {
      addressLines: newAddressLines,
      municipality: cityValue,
      postalCode: zipcodeValue,
      region: stateValue,
    };
  }, [cityValue, stateValue, street1Value, street2Value, zipcodeValue]);

  const isValidAddress =
    !elements[0].error &&
    !elements[1].error &&
    !elements[2].error &&
    !elements[3].error &&
    !elements[4].error;
  const newElements = [
    elements[0].element,
    elements[1].element,
    elements[2].element,
    elements[3].element,
    elements[4].element,
  ];

  return { newAddress, isValidAddress, newElements, disableSaveButton };
};
