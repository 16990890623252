import {
  Checkbox,
  ConventionalNativeSelect,
  Form as BeamForm,
} from '@vst/beam';
import { EditableCardType } from './EditableCard';
import EditableFooter from './EditableFooter';
import styles from '../BodyContent.module.scss';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressValidationComponent } from './AddressValidationComponent';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { useDispatch, useSelector } from 'react-redux';
import { fetchScrubbedAddress } from '@mfe/to-be-migrated/redux/scrubbedAddress';
import { AddressInput, Locale } from '@mfe/shared/schema-types';
import { ModalType, ModalTypeEnum } from '@mfe/shared/components';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import {
  AnalyticsAction,
  Categories,
  structuredEvent,
} from '@mfe/to-be-migrated/redux/analytics';
import {
  findCountryCode,
  getCountriesForNativeSelect,
} from '../../../../utils';
import { useProfileAnalyticsContext } from '../../../../analytics-utils';
import {
  createAddressForValidation,
  updateAddressHandler,
} from '../../../../utils';
import { ProfileTab } from '../../../..';

interface EditableAddressProps {
  currentTab: ProfileTab;
  type: EditableCardType.Billing | EditableCardType.Shipping;
  setRestrictSwitchTabs: (restrict: boolean) => void;
  clearOnClick: (type: EditableCardType) => void;
  cancelOnClick: (cancel: boolean) => void;
  setShowModal: (type: ModalType) => void;
}

export enum AddressCheckboxType {
  Billing = 'Billing',
  Shipping = 'Shipping',
}

const EditableAddress = ({
  type,
  setRestrictSwitchTabs,
  clearOnClick,
  cancelOnClick,
  setShowModal,
}: EditableAddressProps): JSX.Element => {
  const countryRef: any = useRef(null);
  const { t } = useTranslation('NewProfile');
  const { userInfo } = useSelector(selectUserInfo);
  const { billing: currentBillingAddress, shipping: currentShippingAddress } =
    userInfo.address;
  const dispatch = useDispatch();
  const { displayContext, profileContext } = useProfileAnalyticsContext();
  const accountType = userInfo.accountType;

  const {
    locale: { userLocale, displayLanguage },
  } = useSelector(selectLocale);
  const isBrazil = userLocale === Locale.PtBr;

  const [isBillingCheckboxClicked, setBillingCheckbox] =
    useState<boolean>(false);
  const [isShippingCheckboxClicked, setShippingCheckbox] =
    useState<boolean>(false);

  const toggleBillingCheckbox = () =>
    setBillingCheckbox(!isBillingCheckboxClicked);
  const toggleShippingCheckbox = () =>
    setShippingCheckbox(!isShippingCheckboxClicked);

  const errorArray: string[] = [
    t('error_streetAddressInput'),
    t('error_streetAddress2Input'),
    t('error_cityInput'),
    t('error_stateProvinceInput'),
    t('error_zipcodeInput'),
  ];

  const countriesForNativeSelect = getCountriesForNativeSelect(
    displayLanguage
  ).filter((country: { label: string }) => country.label);

  const matchAddress = isBillingCheckboxClicked
    ? createAddressForValidation(currentShippingAddress)
    : createAddressForValidation(currentBillingAddress);

  const addressValidation = AddressValidationComponent({
    currentAddress:
      type === EditableCardType.Billing
        ? createAddressForValidation(currentBillingAddress)
        : createAddressForValidation(currentShippingAddress),
    addressType: type,
    disabledInputs:
      type === EditableCardType.Billing
        ? isBillingCheckboxClicked
        : isShippingCheckboxClicked,
    errorArray,
    matchAddress,
  });

  const resetFields = () => {
    setBillingCheckbox(false);
    setShippingCheckbox(false);
    setRestrictSwitchTabs(false);
    clearOnClick(EditableCardType.None);
    cancelOnClick(false);
  };

  const cancelClick = () => {
    dispatch(
      structuredEvent({
        category: Categories.Profile,
        action: AnalyticsAction.BUTTON_CLICKED,
        params: {
          property: 'cancel',
          context: [displayContext, profileContext],
        },
      })
    );
    resetFields();
  };

  const saveOnClick = () => {
    dispatch(
      structuredEvent({
        category: Categories.Profile,
        action: AnalyticsAction.BUTTON_CLICKED,
        params: {
          property: 'save_changes',
          context: [displayContext, profileContext],
        },
      })
    );

    if (isBillingCheckboxClicked || isShippingCheckboxClicked) {
      const checkbox: AddressCheckboxType = isBillingCheckboxClicked
        ? AddressCheckboxType.Billing
        : AddressCheckboxType.Shipping;
      updateAddressHandler(
        accountType,
        checkbox,
        resetFields,
        dispatch,
        currentBillingAddress,
        currentShippingAddress
      );
    }

    if (
      addressValidation.isValidAddress &&
      !isBillingCheckboxClicked &&
      !isShippingCheckboxClicked
    ) {
      const savedAddress = addressValidation.newAddress;
      const preScrubbedAddress: Omit<AddressInput, 'labels'> = {
        addressLines: savedAddress.addressLines,
        countryCode: findCountryCode(changeCountry.label),
        municipality: savedAddress.municipality,
        postalCode: savedAddress.postalCode,
        region: savedAddress.region,
      };
      dispatch(fetchScrubbedAddress(preScrubbedAddress));
      setShowModal(ModalTypeEnum.Address);
    }
  };

  const defaultCountryObj = getDefaultCountryObj(
    userLocale,
    countriesForNativeSelect
  );

  const [changeCountry, setChangeCountry] = useState<{
    label: string;
    value: string;
  }>(defaultCountryObj);

  const changeCountryOnClick = () => {
    const newCountry = countriesForNativeSelect.find(
      (country: { value: string }) => country.value === countryRef.current.value
    );
    setChangeCountry({
      label: newCountry?.label ?? '',
      value: newCountry?.value ?? '',
    });
  };

  const disabled =
    !isBillingCheckboxClicked &&
    !isShippingCheckboxClicked &&
    (!addressValidation.isValidAddress || addressValidation.disableSaveButton);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    saveOnClick();
  };

  return (
    <BeamForm
      id="edit-address-form"
      data-cy="edit-address-form"
      name="edit-address-form"
      validationMode="onSubmit"
      onSubmit={handleSubmit}
    >
      <div className={styles['editableCardMargin']}>
        {type === EditableCardType.Billing ? (
          !isBrazil && (
            <Checkbox
              id="sameAddressBilling"
              label={t('sameAsShippingAddress')}
              mb="24px"
              checked={isBillingCheckboxClicked}
              onClick={toggleBillingCheckbox}
            />
          )
        ) : (
          <Checkbox
            id="sameAddressShipping"
            label={t('sameAsBillingAddress')}
            mb="24px"
            checked={isShippingCheckboxClicked}
            onClick={toggleShippingCheckbox}
          />
        )}
        <ConventionalNativeSelect
          ref={countryRef}
          id="country"
          labelProps={{ labelText: t('Address.country') }}
          fluid={true}
          value={changeCountry.value}
          onChange={() => changeCountryOnClick()}
          options={countriesForNativeSelect}
          mb="24px"
        />
        {addressValidation.newElements}
        <EditableFooter
          disabled={disabled}
          cancelOnClick={cancelClick}
          saveOnClick={saveOnClick}
        />
      </div>
    </BeamForm>
  );
};

function getDefaultCountryObj(
  locale: Locale,
  countries: { label: string; value: string }[]
): { label: string; value: string } {
  const emptyState = { label: '', value: '' };
  switch (locale) {
    case Locale.EsMx:
      return countries.find((el) => el.label === 'Mexico') ?? emptyState;
    case Locale.PtBr:
      return countries.find((el) => el.label === 'Brazil') ?? emptyState;
    case Locale.ItIt:
      return countries.find((el) => el.label === 'Italy') ?? emptyState;
    case Locale.EnUs:
    default:
      return countries.find((el) => el.label === 'United States') ?? emptyState;
  }
}

export default EditableAddress;
