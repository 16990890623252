import { getCountries } from 'libphonenumber-js';
import { TFunction } from 'i18next';

import {
  AccountType,
  Address,
  AddressInput,
  ContactMethodLabels,
  EmailInput,
  Locale,
  PhoneGroup,
  PhoneInput,
  Role,
} from '@mfe/shared/schema-types';
import {
  updateContactInfo,
  updateContactInfoSMB,
} from '@mfe/to-be-migrated/redux/userInfo';
import { DisplayLanguage } from '@mfe/services/translations-service';

import { AddressCheckboxType } from './components/Body/Content/EditableComponents/EditableAddress';
import countries from './countries.json';

export enum PhoneUILabel {
  Landline = 'Landline',
  Mobile = 'Mobile',
  None = '',
}

function removeDuplicates(array: any[]) {
  return array.filter((item, index) => array.indexOf(item) === index);
}

export function createAddress(
  addressInput: Address | undefined | null,
  t: TFunction,
  locale = Locale.EnUs
): string {
  if (!addressInput || addressInput.addressLines.length === 0) {
    return t('Profile:noAddress');
  }
  const line1 = addressInput.addressLines[0] ?? '';
  const line2 = addressInput.addressLines[1] ?? '';

  let line3 = '';

  switch (locale) {
    case Locale.ItIt:
      line3 = `${addressInput.postalCode} ${addressInput.municipality} ${addressInput.region}`;
      break;
    default:
      line3 = `${addressInput.municipality}, ${addressInput.region} ${addressInput.postalCode}`;
      break;
  }

  let parsedString = '';
  if (line2 === '') {
    parsedString = line1 + '\n ' + line3;
  } else {
    parsedString = line1 + '\n ' + line2 + '\n ' + line3;
  }
  return parsedString;
}

export function createAddressForValidation(
  address: Address | undefined | null
): [string, string, string, string, string] {
  return [
    address?.addressLines[0] ?? '',
    address?.addressLines[1] ?? '',
    address?.municipality ?? '',
    address?.region ?? '',
    address?.postalCode ?? '',
  ];
}

enum AddressComponents {
  STREET1 = 'StreetAddressLine1',
  STREET2 = 'StreetAddressLine2',
  CITY = 'City',
  STATE = 'State/Province',
  ZIPCODE = 'Zip/PostalCode',
}

export function getCountryCode(country: string): string | null {
  const countryCode = countries.find((findCountry) => {
    return findCountry.name.common === country;
  });

  if (countryCode) {
    return countryCode.cca2;
  }

  // apparently sometimes vail will just return a countryCode as a country
  // so we need this check too
  const ccaFound = countries.find((findCountry) => {
    return findCountry.cca2 === country;
  });

  if (ccaFound) {
    return country;
  }

  return null;
}

export const addressInputs = [
  {
    type: AddressComponents.STREET1,
    name: `${AddressComponents.STREET1}Input`,
    label: 'Address.street',
    required: true,
  },
  {
    type: AddressComponents.STREET2,
    name: `${AddressComponents.STREET2}Input`,
    label: 'Address.street2',
    required: false,
  },
  {
    type: AddressComponents.CITY,
    name: `${AddressComponents.CITY}Input`,
    label: 'Address.city',
    required: true,
  },
  {
    type: AddressComponents.STATE,
    name: `${AddressComponents.STATE}Input`,
    label: 'Address.state',
    required: true,
  },
  {
    type: AddressComponents.ZIPCODE,
    name: `${AddressComponents.ZIPCODE}Input`,
    label: 'Address.zipCode',
    required: true,
  },
];

export function removeSymbols(rawPhoneInput: string): string {
  if (!rawPhoneInput) {
    return '';
  }
  const regEx = /[()\-A-z\s]/g;
  const newString = rawPhoneInput.replace(regEx, '');
  return newString;
}

export function parsePhoneNumberLabels(
  locale: Locale,
  isPrimaryUI: boolean,
  newLabelFromDropdown: PhoneUILabel | undefined
): ContactMethodLabels[] {
  let newLabels: ContactMethodLabels[] = [];
  if (isPrimaryUI) {
    newLabels = [ContactMethodLabels.Primary, ContactMethodLabels.Invoice];
  } else {
    newLabels = [ContactMethodLabels.Secondary];
  }
  if (newLabelFromDropdown === PhoneUILabel.Mobile) {
    newLabels.push(ContactMethodLabels.MobileNumber);

    if (locale === Locale.PtBr || locale === Locale.ItIt) {
      newLabels.push(ContactMethodLabels.WhatsApp);
    }
  }

  return removeDuplicates(newLabels);
}

export function updateAddressRedux(
  accountType: AccountType,
  dispatch: any,
  addresses: AddressInput[]
): void {
  if (accountType === AccountType.Residential) {
    dispatch(
      updateContactInfo({
        addresses,
      })
    );
  } else {
    dispatch(
      updateContactInfoSMB({
        role: Role.Customer,
        addresses,
      })
    );
  }
}

export function updateAddressHandler(
  accountType: AccountType,
  checkbox: AddressCheckboxType,
  resetFields: () => void,
  dispatch: any,
  currentBillingAddress?: Address | undefined | null,
  currentShippingAddress?: Address | undefined | null
): void {
  const newAddress =
    checkbox === AddressCheckboxType.Billing
      ? currentShippingAddress
      : currentBillingAddress;
  const addresses = [
    {
      addressLines: newAddress?.addressLines ?? [''],
      countryCode: newAddress?.countryCode ?? '',
      municipality: newAddress?.municipality ?? '',
      postalCode: newAddress?.postalCode ?? '',
      region: newAddress?.region ?? '',
      labels:
        checkbox === AddressCheckboxType.Billing
          ? [ContactMethodLabels.Invoice]
          : [ContactMethodLabels.Shipping],
    },
  ];
  updateAddressRedux(accountType, dispatch, addresses);
  resetFields();
}

export function updateEmailAndPhoneHandler(
  accountType: AccountType,
  resetFields: () => void,
  dispatch: any,
  email: EmailInput,
  oldEmail: string,
  phones: PhoneInput[],
  deleteSecondaryPhone: boolean
): void {
  let newEmail: EmailInput | undefined = email;
  let newPhoneInput: PhoneInput[] = phones;
  let newLabels: ContactMethodLabels[];
  if (email.email === oldEmail) {
    newEmail = undefined;
  }
  if (phones[0].phoneNumber === phones?.[1]?.phoneNumber) {
    newLabels = removeDuplicates([
      ...phones[0].labels,
      ...(phones?.[1]?.labels ?? []),
    ]);
    newPhoneInput = [{ phoneNumber: phones[0].phoneNumber, labels: newLabels }];
  }
  if (accountType === AccountType.Residential) {
    dispatch(
      updateContactInfo({
        email: newEmail,
        phones: newPhoneInput,
        deleteSecondaryPhone,
      })
    );
  } else {
    dispatch(
      updateContactInfoSMB({
        role: Role.AccountHolder,
        email: newEmail,
        phones: newPhoneInput,
        deleteSecondaryPhone,
      })
    );
  }
  resetFields();
}

export function parseIRALabelsIntoLandlineOrMobile(
  labels: Array<string | null> | undefined
): PhoneUILabel {
  if (!labels || labels.length === 0) {
    return PhoneUILabel.None;
  }
  const hasMobileLabel = labels.find((label) => {
    return label === ContactMethodLabels.MobileNumber;
  });

  return hasMobileLabel ? PhoneUILabel.Mobile : PhoneUILabel.Landline;
}

export const labelPropsEmail = (t: TFunction): any => {
  return {
    showStar: true,
    optionalText: '',
    labelText: t('emailAddress'),
  };
};

export const labelPropsPrimarySelect = (t: TFunction): any => {
  return {
    showStar: true,
    optionalText: '',
    labelText: t('primaryPhone'),
  };
};

export const labelPropsSecondarySelect = (t: TFunction): any => {
  return {
    showStar: true,
    optionalText: '',
    labelText: t('secondaryPhone'),
  };
};

export const landlineOption = (t: TFunction): any => {
  return [
    {
      value: '1',
      label: t('landlineDropdown'),
    },
  ];
};

export const mobileOption = (t: TFunction): any => {
  return [
    {
      value: '1',
      label: t('mobileDropdown'),
    },
  ];
};

export function doesPhoneExist(phone: PhoneGroup): {
  primary: boolean;
  secondary: boolean;
} {
  if (phone)
    return {
      primary: Boolean(phone.primary && phone.primary?.phoneNumber),
      secondary: Boolean(phone.secondary && phone.secondary?.phoneNumber),
    };
  else return { primary: false, secondary: false };
}

export function freezeSelect(
  otherDropdownValue: PhoneUILabel,
  primaryOrSecondary: 'primary' | 'secondary',
  phone: PhoneGroup
): boolean {
  let selectPlaceholder;
  if (otherDropdownValue === PhoneUILabel.Mobile) {
    return true;
  }
  if (primaryOrSecondary === 'primary') {
    selectPlaceholder = doesPhoneExist(phone).primary
      ? parseIRALabelsIntoLandlineOrMobile(phone.primary?.labels)
      : PhoneUILabel.Landline;
  } else {
    selectPlaceholder = doesPhoneExist(phone).secondary
      ? parseIRALabelsIntoLandlineOrMobile(phone.secondary?.labels)
      : PhoneUILabel.Landline;
  }
  return false;
}

export function getContactInformation(
  accountType: AccountType,
  residentialPhone: PhoneGroup,
  residentialEmail: string,
  accountHolderPhone: PhoneGroup,
  accountHolderEmail: string,
  t: TFunction
): {
  phone: PhoneGroup;
  primaryPhoneText: string;
  primaryLandlineOrMobile: PhoneUILabel;
  primaryLandlineOrMobileText: string;
  secondaryPhoneText: string;
  secondaryLandlineOrMobile: PhoneUILabel;
  secondaryLandlineOrMobileText: string;
  email: string;
} {
  let phone, email;
  if (accountType === AccountType.Residential) {
    phone = residentialPhone;
    email = residentialEmail;
  } else {
    phone = accountHolderPhone;
    email = accountHolderEmail;
  }
  const primaryPhoneText = doesPhoneExist(phone).primary
    ? (phone.primary?.phoneNumber as string)
    : t('noPhone');
  const primaryLandlineOrMobile = doesPhoneExist(phone).primary
    ? parseIRALabelsIntoLandlineOrMobile(phone.primary?.labels)
    : PhoneUILabel.Landline;
  const secondaryPhoneText =
    doesPhoneExist(phone).secondary &&
    phone.primary?.phoneNumber !== phone.secondary?.phoneNumber
      ? (phone.secondary?.phoneNumber as string)
      : t('noPhone');
  let secondaryLandlineOrMobile;
  if (
    phone.primary?.phoneNumber === phone.secondary?.phoneNumber &&
    parseIRALabelsIntoLandlineOrMobile(phone.primary?.labels) ===
      PhoneUILabel.Mobile
  ) {
    secondaryLandlineOrMobile = PhoneUILabel.Landline;
  } else {
    secondaryLandlineOrMobile = doesPhoneExist(phone).secondary
      ? parseIRALabelsIntoLandlineOrMobile(phone.secondary?.labels)
      : PhoneUILabel.Landline;
  }
  const primaryLandlineOrMobileText =
    primaryLandlineOrMobile === PhoneUILabel.Landline
      ? t('landlineDropdown')
      : t('mobileDropdown');
  const secondaryLandlineOrMobileText =
    secondaryLandlineOrMobile === PhoneUILabel.Landline
      ? t('landlineDropdown')
      : t('mobileDropdown');

  return {
    phone,
    primaryPhoneText,
    primaryLandlineOrMobile,
    primaryLandlineOrMobileText,
    secondaryPhoneText,
    secondaryLandlineOrMobile,
    secondaryLandlineOrMobileText,
    email,
  };
}

export interface CountryPhoneInput {
  flag?: JSX.Element;
  countryName?: string;
  countryCode?: string;
  phonePrefix?: string;
}

const getFieldFromLanguage = (
  language?: DisplayLanguage
): 'common' | 'ita' | 'por' => {
  switch (language) {
    case DisplayLanguage.Italian:
      return 'ita';
    case DisplayLanguage.Portuguese:
      return 'por';
    default:
      return 'common';
  }
};

export function getCountriesForNativeSelect(language?: DisplayLanguage): Array<{
  label: string;
  value: string;
}> {
  const allCountryCodes = getCountries();
  return allCountryCodes.map((country, index) => {
    let countryName = '';
    countries.forEach((findCountry) => {
      if (findCountry.cca2 === country) {
        countryName =
          findCountry.name[getFieldFromLanguage(language)] ??
          findCountry.name.common;
      }
    });
    return {
      label: countryName,
      value: String(index),
    };
  });
}

export function findCountryCode(countryName: string): string {
  const countryCode = countries.find(
    (country) => country.name.common === countryName
  );
  return countryCode?.cca2 ?? '';
}
